import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect, useState } from "react";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import moment from "moment";
import Loader from "../../../components/Loaders/Loader";
import { useNavigate } from "react-router-dom";
import CommonSearch from "../../../components/CustomComponents/Cards/CommonSearch";
import { TablePagination } from "@mui/material";
import NotFound from "../../../components/NotFound/NotFound";
import TitleHeader from "../../../components/CustomComponents/title";
import useAuth from "../../../hooks/useAuth";
const UserDomain = () => {
  const { t } = useTranslation();
  const [allDomain, setAllDomain] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { signOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    getAllDomain();
  }, []);

  useEffect(() => {
    paginationData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  const onChangeSearch = (e) => {
    setSearchData(e.target.value);
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllDomain = async () => {
    setReportLoading(true);
    try {
      const res = await api.get(`/seo/backlink-reports`);
      if (res.status === 200) {
        setAllDomain(res?.data?.data);
        setReportLoading(false);
      }
    } catch (error) {
      setReportLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const paginationData = async () => {
    try {
      setReportLoading(true);
      const res = await api.get(
        `/seo/backlink-reports?limit=${rowsPerPage}&page=${page + 1}`
      );
      if (res.status === 200) {
        setAllDomain(res.data.data);
        setReportLoading(false);
      }
    } catch (error) {
      setReportLoading(false);
    }
  };

  return (
    <>
      <TitleHeader title={t("Backlinks")} subHeading={t("Backlink_subhead")} />
      <CommonSearch onChange={onChangeSearch} />
      <>
        {reportLoading ? (
          <Loader />
        ) : (
          <>
            {/* <Paper> */}
            {allDomain?.count > 0 ? (
              <>
                {" "}
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("Report")}</TableCell>
                        <TableCell align="center">{t("Type")}</TableCell>
                        <TableCell align="center">{t("Report_Date")}</TableCell>
                        <TableCell align="center">{t("Backlinks")}</TableCell>
                        <TableCell align="center">
                          {t("Reffering_Domains")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allDomain?.results?.map((row) => (
                        <TableRow
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            navigate(`/user/backlink/${row?.reportId}`)
                          }
                        >
                          <TableCell component="th" scope="row">
                            {row.target}
                          </TableCell>
                          {/* <CheckBox /> */}
                          <TableCell align="center">{row?.mode}</TableCell>
                          <TableCell align="center">
                            {" "}
                            {moment(row?.created).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {kFormatter(row?.totalBackLinks)}
                          </TableCell>
                          <TableCell align="center">
                            {kFormatter(row?.totalDomains)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allDomain?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <>
                <NotFound text={t("No_Backlinks_Found")} />{" "}
              </>
            )}
            {/* </Paper> */}
          </>
        )}
      </>
    </>
  );
};

export default UserDomain;
