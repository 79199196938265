import React, { useContext } from "react";
import styled, { withTheme } from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import axios from "axios";
import { Grid, InputBase, Typography, Box } from "@mui/material";
import NavbarCountryDropdown from "./Components/NavbarCountryDropdown";
import { KeywordSearchContext } from "./context/KeywordSearchContext";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";

const baseURL = process.env.REACT_APP_BASE_URL;

const accessToken = window.localStorage.getItem("accessToken");

const api = axios.create({
  baseURL,
  responseType: "json",
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "x-csrfoken",
  headers: { Authorization: `Bearer ${accessToken}` },
});

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  height: 52px;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(2)};
    width: 250px;
  }
`;
const Navbar = ({ onDrawerToggle, callSERankingAPI, setLoading }) => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States of America",
    code: "us",
    capital: "Washington, D.C.",
    region: "NA",
    currency: {
      code: "USD",
      name: "United States dollar",
      symbol: "$",
    },
    language: {
      code: "en",
      iso639_2: "eng",
      name: "English",
      nativeName: "English",
    },
    flag: "//upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
  });
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const {
    // country,
    keywords,

    handleChangeKeywords,
    handleChangeKeywordData,
    loading,
  } = useContext(KeywordSearchContext);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleKeywordChange = (event) => {
    handleChangeKeywords(event.target.value);
  };

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setAnchorMenu(null);

    // handleChangeCountry(country);
    closeMenu();
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSearchClick = async () => {
    if (keywords !== "") {
      try {
        setLoading(true);
        const res = await api.post(`seo/analyze-keywords/`, {
          keywords: [keywords],
          source: selectedCountry?.code.toLowerCase(),
          filter: "keyword",
        });

        if (res.status === 200) {
          handleChangeKeywordData(res.data.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <React.Fragment>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={7} md={11} lg={11}>
          <Box
            sx={{
              border: "1px solid #c4c4c4",
              bgcolor: "white",
              borderRadius: "4px",
              display: "flex",
              "&:hover ": {
                border: "1px solid #54d2ff",
              },
            }}
          >
            <Input
              placeholder={t("SearchKeyword")}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSearchClick(false);
                }
              }}
              onChange={(event) => handleKeywordChange(event)}
            />

            <NavbarCountryDropdown
              onChange={handleCountryChange}
              closeMenu={closeMenu}
              toggleMenu={toggleMenu}
              anchorMenu={anchorMenu}
              selectedCountry={selectedCountry}
            />
          </Box>
        </Grid>

        <Grid item xs={2} sm={2} md={1} lg={1}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CommonButton
              label={t("Search")}
              loading={loading}
              onSubmit={() => {
                handleSearchClick();
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginTop: "60px",
        }}
      ></Typography>
    </React.Fragment>
  );
};

export default withTheme(Navbar);
