import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
  tooltipClasses,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState, useRef, useLayoutEffect } from "react";
import moment from "moment";
import { Box, styled } from "@mui/system";
import { api } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

export const ShowComments = ({ commentsDetails, getAllComments }) => {
  const [open, setOpen] = React.useState(null);
  const [deteleComment, setDeleteComment] = useState("");
  const elRef = useRef();

  useLayoutEffect(() => {
    if (elRef.current) {
      elRef.current.firstElementChild.style.margin = "0px";
    }
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  const deleteComment = async (commentId) => {
    try {
      const res = await api.delete(`/comments/${commentId}`);
      if (res.status === 200) {
        setOpen(null);
        toast.success("Successfully Deleted");
        getAllComments();
      }
    } catch (error) {}
  };
  return (
    <>
      {commentsDetails?.map((item) => {
        return (
          <>
            <Dialog
              // fullScreen={fullScreen}
              open={open !== null ? true : false}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {"Are you sure to Delete Comment?"}
              </DialogTitle>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Disagree
                </Button>
                {/* onClick={setDeleteComment} */}
                <Button onClick={() => deleteComment(deteleComment)} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <Box
              sx={{
                display: "flex",
                gap: "20px",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <Grid
                style={{
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "white",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#06BDFF",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography style={{ fontSize: "20px" }}>
                  {item?.user?.firstName?.charAt(0) || "U"}
                </Typography>
              </Grid>
              <Box
                flex={1}
                sx={
                  {
                    // display: "flex",
                    // flexDirection: "column",
                    // gap: "10px",
                  }
                }
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#1B2430",

                    color: "#495059",
                  }}
                >
                  {item?.user?.firstName || "User"}{" "}
                  {item?.user?.lastName || "Name"}
                </Typography>
                <Typography
                  sx={{
                    marginTop: "-12px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#495059",
                  }}
                  ref={elRef}
                  dangerouslySetInnerHTML={{
                    __html: item?.text,
                  }}
                >
                  {/* {item.text} */}
                </Typography>{" "}
                <Typography
                  onClick={(e) => {
                    setDeleteComment(item?.id);
                    setOpen(true);
                  }}
                  sx={{
                    width: "40px",
                    cursor: "pointer",
                    marginTop: "-12px",
                    fontWeight: "400",
                    fontSize: "13px",
                    color: "#8D9298",
                  }}
                >
                  Delete
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#8D9298", fontWeight: "400", fontSize: "12px" }}
              >
                {" "}
                {moment(item?.createdAt).fromNow()}
              </Typography>
            </Box>
          </>
        );
      })}
    </>
  );
};
