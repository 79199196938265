import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  iconClass: {
    fontSize: "70px",
    color: "#F8A92B",
  },
  makePrimaryUserTextHead: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#1B2430",
  },
  makePrimaryUserTex: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1B2430",
  },
  makePrimaryActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px",
  },
}));
