import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Zoom,
  Divider,
  Modal,
  TextField,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useStyles } from "../../styles/style";
import dayjs from "dayjs";
import useDateFormat from "../../../../../hooks/useDateFormat";
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "8px",
  transform: "translate(-50%, -50%)",
  maxHeight: "550px",
  // width: "500px",
  bgcolor: "background.paper",
  // maxHeight: "87vh",
  width: "500px",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const BulkUpdateModel = ({
  setEndValidDate,
  showBulkUpdateTime,
  handleUpdateDateAndTime,
  handleCloseBlukUpdateModel,
  changeHandlerBulkDateUpdate,
  setStartDate,
  endDate,
  repeat,
  setEndDateGreater,
}) => {
  const classes = useStyles();
  const [dateTime, setDateTime] = useState(null);
  const { t } = useTranslation();
  const [timeInterval, setTimeInterval] = useState(1440);
  const [ValidDate, setValidDate] = useState(true);
  // const [endDate, setEndDate] = useState(null);

  const { format } = useDateFormat();

  const timeIntervalChangeHandler = (e) => {
    setTimeInterval(e.target.value);
  };

  const submitBulkUpdate = () => {
    changeHandlerBulkDateUpdate(dateTime, timeInterval);
    handleCloseBlukUpdateModel();
  };
  function isValidDateFormat(dateString, format) {
    return dayjs(dateString, { format, strict: true }).isValid();
  }
  return (
    <>
      <Modal
        open={showBulkUpdateTime}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <Grid container>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                    marginBottom: "8px",
                    marginTop: "8px",
                  }}
                >
                  {t("Bulk_Date_Time")}
                </Typography>
              </Grid>
            </Grid>
            <Divider variant="middle" />

            <Grid container spacing={1}>
              <Grid item sm={12} md={12}>
                <Typography
                  sx={{
                    color: "#1B2430",
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    marginTop: "8px",
                  }}
                >
                  {t("Select_Date_Time")}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateTimePicker"]}>
                      <DateTimePicker
                        // minDateTime={dayjs(currentDate)}
                        value={dateTime !== null ? dayjs(dateTime) : null}
                        disablePast
                        classes={{
                          root: classes.root,
                        }}
                        format={`${format} hh:mm A`}
                        onChange={(e) => {
                          setDateTime(dayjs(e));

                          setStartDate(e);
                          const currentDate = new Date();
                          const selectedDateTime = new Date(e);
                          const format = "DD/MM/YYYY hh:mm A";
                          const isValid =
                            e !== "" &&
                            selectedDateTime >= currentDate &&
                            isValidDateFormat(e, format);

                          setValidDate(isValid);
                          if (
                            repeat !== "no" &&
                            endDate !== null &&
                            endDate !== ""
                          ) {
                            if (new Date(endDate) > new Date(e)) {
                              setEndDateGreater(false);
                            } else {
                              setEndDateGreater(true);
                            }
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%", background: "#FFFF" }}
                            {...params}
                            error={!ValidDate}
                            helperText={!ValidDate && "Invalid date"}
                          />
                        )}
                      />
                      <style>
                        {`
           .MuiDialogActions-root-geldvk {
            display: none;
          }
         
        `}
                      </style>
                    </DemoContainer>
                  </LocalizationProvider>
                  {ValidDate === false && (
                    <Typography className={classes.fieldError}>
                      {t("Invalid_date")}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#1B2430",
                  lineHeight: "20px",
                  marginTop: "8px",
                }}
              >
                {t("Post_interval")}
              </Typography>
              <Grid
                container
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <Grid item sm={12} md={12}>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      // error={intervalRequired}
                      sx={{ width: "100%" }}
                      type={"number"}
                      value={timeInterval}
                      onChange={timeIntervalChangeHandler}
                      placeholder="Please enter time interval"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>

          <Grid container>
            <Grid item spacing={2} xs={12} md={12}>
              <Box sx={{ float: "right" }}>
                <CommonButton
                  label="Cancel"
                  displayWhite="true"
                  onSubmit={handleCloseBlukUpdateModel}
                />
                &nbsp;&nbsp;
                <CommonButton
                  label="Update"
                  disabled={ValidDate === false}
                  onSubmit={submitBulkUpdate}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default BulkUpdateModel;
