
export const countries = [
  {
      "name": "Afghanistan",
      "code": "AF",
      "capital": "Kabul",
      "region": "AS",
      "currency": {
          "code": "AFN",
          "name": "Afghan afghani",
          "symbol": "؋"
      },
      "language": {
          "code": "ps",
          "name": "Pashto"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"
  },
  {
      "name": "Åland Islands",
      "code": "AX",
      "capital": "Mariehamn",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "sv",
          "name": "Swedish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/52/Flag_of_%C3%85land.svg"
  },
  {
      "name": "Albania",
      "code": "AL",
      "capital": "Tirana",
      "region": "EU",
      "currency": {
          "code": "ALL",
          "name": "Albanian lek",
          "symbol": "L"
      },
      "language": {
          "code": "sq",
          "name": "Albanian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/36/Flag_of_Albania.svg",
  },
  {
      "name": "Algeria",
      "code": "DZ",
      "capital": "Algiers",
      "region": "AF",
      "currency": {
          "code": "DZD",
          "name": "Algerian dinar",
          "symbol": "د.ج"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Algeria.svg"
  },
  {
      "name": "American Samoa",
      "code": "AS",
      "capital": "Pago Pago",
      "region": "OC",
      "currency": {
          "code": "USD",
          "name": "United State Dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg"
  },
  {
      "name": "Andorra",
      "code": "AD",
      "capital": "Andorra la Vella",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "ca",
          "name": "Catalan"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Andorra.svg"
  },
  {
      "name": "Angola",
      "code": "AO",
      "capital": "Luanda",
      "region": "AF",
      "currency": {
          "code": "AOA",
          "name": "Angolan kwanza",
          "symbol": "Kz"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9d/Flag_of_Angola.svg"
  },
  {
      "name": "Anguilla",
      "code": "AI",
      "capital": "The Valley",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Anguilla.svg"
  },
  {
      "name": "Antigua and Barbuda",
      "code": "AG",
      "capital": "Saint John's",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Antigua_and_Barbuda.svg"
  },
  {
      "name": "Argentina",
      "code": "AR",
      "capital": "Buenos Aires",
      "region": "SA",
      "currency": {
          "code": "ARS",
          "name": "Argentine peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg"
  },
  {
      "name": "Armenia",
      "code": "AM",
      "capital": "Yerevan",
      "region": "AS",
      "currency": {
          "code": "AMD",
          "name": "Armenian dram",
          "symbol": null
      },
      "language": {
          "code": "hy",
          "name": "Armenian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/2f/Flag_of_Armenia.svg"
  },
  {
      "name": "Aruba",
      "code": "AW",
      "capital": "Oranjestad",
      "region": "SA",
      "currency": {
          "code": "AWG",
          "name": "Aruban florin",
          "symbol": "ƒ"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Aruba.svg"
  },
  {
      "name": "Australia",
      "code": "AU",
      "capital": "Canberra",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_Australia_%28converted%29.svg"
  },
  {
      "name": "Austria",
      "code": "AT",
      "capital": "Vienna",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "de",
          "name": "German"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_Austria.svg"
  },
  {
      "name": "Azerbaijan",
      "code": "AZ",
      "capital": "Baku",
      "region": "AS",
      "currency": {
          "code": "AZN",
          "name": "Azerbaijani manat",
          "symbol": null
      },
      "language": {
          "code": "az",
          "name": "Azerbaijani"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Azerbaijan.svg"
  },
  {
      "name": "Bahamas",
      "code": "BS",
      "capital": "Nassau",
      "region": "NA",
      "currency": {
          "code": "BSD",
          "name": "Bahamian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/93/Flag_of_the_Bahamas.svg"
  },
  {
      "name": "Bahrain",
      "code": "BH",
      "capital": "Manama",
      "region": "AS",
      "currency": {
          "code": "BHD",
          "name": "Bahraini dinar",
          "symbol": ".د.ب"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Bahrain.svg"
  },
  {
      "name": "Bangladesh",
      "code": "BD",
      "capital": "Dhaka",
      "region": "AS",
      "currency": {
          "code": "BDT",
          "name": "Bangladeshi taka",
          "symbol": "৳"
      },
      "language": {
          "code": "bn",
          "name": "Bengali"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f9/Flag_of_Bangladesh.svg"
  },
  {
      "name": "Barbados",
      "code": "BB",
      "capital": "Bridgetown",
      "region": "NA",
      "currency": {
          "code": "BBD",
          "name": "Barbadian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/ef/Flag_of_Barbados.svg"
  },
  {
      "name": "Belarus",
      "code": "BY",
      "capital": "Minsk",
      "region": "EU",
      "currency": {
          "code": "BYN",
          "name": "New Belarusian ruble",
          "symbol": "Br"
      },
      "language": {
          "code": "be",
          "name": "Belarusian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/85/Flag_of_Belarus.svg"
  },
  {
      "name": "Belgium",
      "code": "BE",
      "capital": "Brussels",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg"
  },
  {
      "name": "Belize",
      "code": "BZ",
      "capital": "Belmopan",
      "region": "NA",
      "currency": {
          "code": "BZD",
          "name": "Belize dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/e7/Flag_of_Belize.svg"
  },
  {
      "name": "Benin",
      "code": "BJ",
      "capital": "Porto-Novo",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Benin.svg"
  },
  {
      "name": "Bermuda",
      "code": "BM",
      "capital": "Hamilton",
      "region": "NA",
      "currency": {
          "code": "BMD",
          "name": "Bermudian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bermuda.svg"
  },
  {
      "name": "Bhutan",
      "code": "BT",
      "capital": "Thimphu",
      "region": "AS",
      "currency": {
          "code": "BTN",
          "name": "Bhutanese ngultrum",
          "symbol": "Nu."
      },
      "language": {
          "code": "dz",
          "name": "Dzongkha"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/91/Flag_of_Bhutan.svg"
  },
  {
      "name": "Bolivia (Plurinational State of)",
      "code": "BO",
      "capital": "Sucre",
      "region": "SA",
      "currency": {
          "code": "BOB",
          "name": "Bolivian boliviano",
          "symbol": "Bs."
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/b3/Bandera_de_Bolivia_%28Estado%29.svg"
  },
  {
      "name": "Bonaire, Sint Eustatius and Saba",
      "code": "BQ",
      "capital": "Kralendijk",
      "region": "SA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg"
  },
  {
      "name": "Bosnia and Herzegovina",
      "code": "BA",
      "capital": "Sarajevo",
      "region": "EU",
      "currency": {
          "code": "BAM",
          "name": "Bosnia and Herzegovina convertible mark",
          "symbol": null
      },
      "language": {
          "code": "bs",
          "name": "Bosnian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bf/Flag_of_Bosnia_and_Herzegovina.svg"
  },
  {
      "name": "Botswana",
      "code": "BW",
      "capital": "Gaborone",
      "region": "AF",
      "currency": {
          "code": "BWP",
          "name": "Botswana pula",
          "symbol": "P"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Botswana.svg"
  },
  // {
  //     "name": "Bouvet Island",
  //     "code": "BV",
  //     "capital": "",
  //     "region": "AN",
  //     "currency": {
  //         "code": "NOK",
  //         "name": "Norwegian krone",
  //         "symbol": "kr"
  //     },
  //     "language": {
  //         "code": "no",
  //         "name": "Norwegian"
  //     },
  //     "flag": "https://restcountries.eu/data/bvt.svg"
  // },
  {
      "name": "Brazil",
      "code": "BR",
      "capital": "Brasília",
      "region": "SA",
      "currency": {
          "code": "BRL",
          "name": "Brazilian real",
          "symbol": "R$"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg"
  },
  {
      "name": "British Indian Ocean Territory",
      "code": "IO",
      "capital": "Diego Garcia",
      "region": "AF",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_the_Commissioner_of_the_British_Indian_Ocean_Territory.svg"
  },
  {
      "name": "United States Minor Outlying Islands",
      "code": "UM",
      "capital": "",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States Dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
  },
  {
      "name": "Virgin Islands (British)",
      "code": "VG",
      "capital": "Road Town",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/42/Flag_of_the_British_Virgin_Islands.svg"
  },
  {
      "name": "Virgin Islands (U.S.)",
      "code": "VI",
      "capital": "Charlotte Amalie",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "upload.wikimedia.org/wikipedia/commons/f/f8/Flag_of_the_United_States_Virgin_Islands.svg"
  },
  {
      "name": "Brunei Darussalam",
      "code": "BN",
      "capital": "Bandar Seri Begawan",
      "region": "AS",
      "currency": {
          "code": "BND",
          "name": "Brunei dollar",
          "symbol": "$"
      },
      "language": {
          "code": "ms",
          "name": "Malay"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Brunei.svg"
  },
  {
      "name": "Bulgaria",
      "code": "BG",
      "capital": "Sofia",
      "region": "EU",
      "currency": {
          "code": "BGN",
          "name": "Bulgarian lev",
          "symbol": "лв"
      },
      "language": {
          "code": "bg",
          "name": "Bulgarian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Bulgaria.svg"
  },
  {
      "name": "Burkina Faso",
      "code": "BF",
      "capital": "Ouagadougou",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Burkina_Faso.svg"
  },
  {
      "name": "Burundi",
      "code": "BI",
      "capital": "Bujumbura",
      "region": "AF",
      "currency": {
          "code": "BIF",
          "name": "Burundian franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/50/Flag_of_Burundi.svg"
  },
  {
      "name": "Cambodia",
      "code": "KH",
      "capital": "Phnom Penh",
      "region": "AS",
      "currency": {
          "code": "KHR",
          "name": "Cambodian riel",
          "symbol": "៛"
      },
      "language": {
          "code": "km",
          "name": "Khmer"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_Cambodia.svg"
  },
  {
      "name": "Cameroon",
      "code": "CM",
      "capital": "Yaoundé",
      "region": "AF",
      "currency": {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Cameroon.svg"
  },
  {
      "name": "Canada",
      "code": "CA",
      "capital": "Ottawa",
      "region": "NA",
      "currency": {
          "code": "CAD",
          "name": "Canadian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg"
  },
  {
      "name": "Cabo Verde",
      "code": "CV",
      "capital": "Praia",
      "region": "AF",
      "currency": {
          "code": "CVE",
          "name": "Cape Verdean escudo",
          "symbol": "Esc"
      },
      "language": {
          "code": "pt",
          "iso639_2": "por",
          "name": "Portuguese",
          "nativeName": "Português"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Cape_Verde.svg"
  },
  {
      "name": "Cayman Islands",
      "code": "KY",
      "capital": "George Town",
      "region": "NA",
      "demonym": "Caymanian",
      "currency": {
          "code": "KYD",
          "name": "Cayman Islands dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_the_Cayman_Islands.svg"
  },
  {
      "name": "Central African Republic",
      "code": "CF",
      "capital": "Bangui",
      "region": "AF",
      "currency": {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Central_African_Republic.svg"
  },
  {
      "name": "Chad",
      "code": "TD",
      "capital": "N'Djamena",
      "region": "AF",
      "currency": {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/4b/Flag_of_Chad.svg"
  },
  {
      "name": "Chile",
      "code": "CL",
      "capital": "Santiago",
      "region": "SA",
      "currency": {
          "code": "CLP",
          "name": "Chilean peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "iso639_2": "spa",
          "name": "Spanish",
          "nativeName": "Español"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg"
  },
  {
      "name": "China",
      "code": "CN",
      "capital": "Beijing",
      "region": "AS",
      "currency": {
          "code": "CNY",
          "name": "Chinese yuan",
          "symbol": "¥"
      },
      "language": {
          "code": "zh",
          "name": "Chinese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_the_People%27s_Republic_of_China.svg"
  },
  {
      "name": "Christmas Island",
      "code": "CX",
      "capital": "Flying Fish Cove",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/67/Flag_of_Christmas_Island.svg"
  },
  {
      "name": "Cocos (Keeling) Islands",
      "code": "CC",
      "capital": "West Island",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Cocos_%28Keeling%29_Islands.svg"
  },
  {
      "name": "Colombia",
      "code": "CO",
      "capital": "Bogotá",
      "region": "SA",
      "currency": {
          "code": "COP",
          "name": "Colombian peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg"
  },
  {
      "name": "Comoros",
      "code": "KM",
      "capital": "Moroni",
      "region": "AF",
      "currency": {
          "code": "KMF",
          "name": "Comorian franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/94/Flag_of_the_Comoros.svg"
  },
  {
      "name": "Congo",
      "code": "CG",
      "capital": "Brazzaville",
      "region": "AF",
      "currency": {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_the_Republic_of_the_Congo.svg"
  },
  {
      "name": "Congo (Democratic Republic of the)",
      "code": "CD",
      "capital": "Kinshasa",
      "region": "AF",
      "currency": {
          "code": "CDF",
          "name": "Congolese franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/6f/Flag_of_the_Democratic_Republic_of_the_Congo.svg"
  },
  {
      "name": "Cook Islands",
      "code": "CK",
      "capital": "Avarua",
      "region": "OC",
      "currency": {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/35/Flag_of_the_Cook_Islands.svg"
  },
  {
      "name": "Costa Rica",
      "code": "CR",
      "capital": "San José",
      "region": "NA",
      "currency": {
          "code": "CRC",
          "name": "Costa Rican colón",
          "symbol": "₡"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f2/Flag_of_Costa_Rica.svg"
  },
  {
      "name": "Croatia",
      "code": "HR",
      "capital": "Zagreb",
      "region": "EU",
      "currency": {
          "code": "HRK",
          "name": "Croatian kuna",
          "symbol": "kn"
      },
      "language": {
          "code": "hr",
          "name": "Croatian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Croatia.svg"
  },
  {
      "name": "Cuba",
      "code": "CU",
      "capital": "Havana",
      "region": "NA",
      "currency": {
          "code": "CUC",
          "name": "Cuban convertible peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bd/Flag_of_Cuba.svg"
  },
  {
      "name": "Curaçao",
      "code": "CW",
      "capital": "Willemstad",
      "region": "SA",
      "currency": {
          "code": "ANG",
          "name": "Netherlands Antillean guilder",
          "symbol": "ƒ"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_Cura%C3%A7ao.svg"
  },
  {
      "name": "Cyprus",
      "code": "CY",
      "capital": "Nicosia",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "tr",
          "name": "Turkish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Cyprus.svg"
  },
  {
      "name": "Czech Republic",
      "code": "CZ",
      "capital": "Prague",
      "region": "EU",
      "currency": {
          "code": "CZK",
          "name": "Czech koruna",
          "symbol": "Kč"
      },
      "language": {
          "code": "cs",
          "name": "Czech"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_Czech_Republic.svg"
  },
  {
      "name": "Denmark",
      "code": "DK",
      "capital": "Copenhagen",
      "region": "EU",
      "currency": {
          "code": "DKK",
          "name": "Danish krone",
          "symbol": "kr"
      },
      "language": {
          "code": "da",
          "name": "Danish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9c/Flag_of_Denmark.svg"
  },
  {
      "name": "Djibouti",
      "code": "DJ",
      "capital": "Djibouti",
      "region": "AF",
      "currency": {
          "code": "DJF",
          "name": "Djiboutian franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_Djibouti.svg"
  },
  {
      "name": "Dominica",
      "code": "DM",
      "capital": "Roseau",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/c4/Flag_of_Dominica.svg"
  },
  {
      "name": "Dominican Republic",
      "code": "DO",
      "capital": "Santo Domingo",
      "region": "NA",
      "currency": {
          "code": "DOP",
          "name": "Dominican peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg"
  },
  {
      "name": "Ecuador",
      "code": "EC",
      "capital": "Quito",
      "region": "SA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg"
  },
  {
      "name": "Egypt",
      "code": "EG",
      "capital": "Cairo",
      "region": "AF",
      "currency": {
          "code": "EGP",
          "name": "Egyptian pound",
          "symbol": "£"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Egypt.svg"
  },
  {
      "name": "El Salvador",
      "code": "SV",
      "capital": "San Salvador",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg"
  },
  {
      "name": "Equatorial Guinea",
      "code": "GQ",
      "capital": "Malabo",
      "region": "AF",
      "currency": {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "es",
          "iso639_2": "spa",
          "name": "Spanish",
          "nativeName": "Español"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/31/Flag_of_Equatorial_Guinea.svg"
  },
  {
      "name": "Eritrea",
      "code": "ER",
      "capital": "Asmara",
      "region": "AF",
      "currency": {
          "code": "ERN",
          "name": "Eritrean nakfa",
          "symbol": "Nfk"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/29/Flag_of_Eritrea.svg"
  },
  {
      "name": "Estonia",
      "code": "EE",
      "capital": "Tallinn",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "et",
          "name": "Estonian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/8f/Flag_of_Estonia.svg"
  },
  {
      "name": "Ethiopia",
      "code": "ET",
      "capital": "Addis Ababa",
      "region": "AF",
      "currency": {
          "code": "ETB",
          "name": "Ethiopian birr",
          "symbol": "Br"
      },
      "language": {
          "code": "am",
          "name": "Amharic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/71/Flag_of_Ethiopia.svg"
  },
  {
      "name": "Falkland Islands (Malvinas)",
      "code": "FK",
      "capital": "Stanley",
      "region": "SA",
      "currency": {
          "code": "FKP",
          "name": "Falkland Islands pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_Falkland_Islands.svg"
  },
  {
      "name": "Faroe Islands",
      "code": "FO",
      "capital": "Tórshavn",
      "region": "EU",
      "currency": {
          "code": "DKK",
          "name": "Danish krone",
          "symbol": "kr"
      },
      "language": {
          "code": "fo",
          "name": "Faroese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/3c/Flag_of_the_Faroe_Islands.svg"
  },
  {
      "name": "Fiji",
      "code": "FJ",
      "capital": "Suva",
      "region": "OC",
      "currency": {
          "code": "FJD",
          "name": "Fijian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Fiji.svg"
  },
  {
      "name": "Finland",
      "code": "FI",
      "capital": "Helsinki",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fi",
          "iso639_2": "fin",
          "name": "Finnish",
          "nativeName": "suomi"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Finland.svg"
  },
  {
      "name": "France",
      "code": "FR",
      "capital": "Paris",
      "region": "EU",
      "demonym": "French",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
  },
  // {
  //     "name": "French Guiana",
  //     "code": "GF",
  //     "capital": "Cayenne",
  //     "region": "SA",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": "https://restcountries.eu/data/guf.svg"
  // },
  {
      "name": "French Polynesia",
      "code": "PF",
      "capital": "Papeetē",
      "region": "OC",
      "currency": {
          "code": "XPF",
          "name": "CFP franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/db/Flag_of_French_Polynesia.svg"
  },
  {
      "name": "French Southern Territories",
      "code": "TF",
      "capital": "Port-aux-Français",
      "region": "AF",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/a7/Flag_of_the_French_Southern_and_Antarctic_Lands.svg"
  },
  {
      "name": "Gabon",
      "code": "GA",
      "capital": "Libreville",
      "region": "AF",
      "currency": {
          "code": "XAF",
          "name": "Central African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/04/Flag_of_Gabon.svg"
  },
  {
      "name": "Gambia",
      "code": "GM",
      "capital": "Banjul",
      "region": "AF",
      "currency": {
          "code": "GMD",
          "name": "Gambian dalasi",
          "symbol": "D"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_The_Gambia.svg"
  },
  {
      "name": "Georgia",
      "code": "GE",
      "capital": "Tbilisi",
      "region": "AS",
      "currency": {
          "code": "GEL",
          "name": "Georgian Lari",
          "symbol": "ლ"
      },
      "language": {
          "code": "ka",
          "name": "Georgian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Georgia.svg"
  },
  {
      "name": "Germany",
      "code": "DE",
      "capital": "Berlin",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "de",
          "name": "German"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/b/ba/Flag_of_Germany.svg"
  },
  {
      "name": "Ghana",
      "code": "GH",
      "capital": "Accra",
      "region": "AF",
      "currency": {
          "code": "GHS",
          "name": "Ghanaian cedi",
          "symbol": "₵"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Ghana.svg"
  },
  {
      "name": "Gibraltar",
      "code": "GI",
      "capital": "Gibraltar",
      "region": "EU",
      "currency": {
          "code": "GIP",
          "name": "Gibraltar pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/02/Flag_of_Gibraltar.svg"
  },
  {
      "name": "Greece",
      "code": "GR",
      "capital": "Athens",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "el",
          "name": "Greek (modern)"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Greece.svg"
  },
  {
      "name": "Greenland",
      "code": "GL",
      "capital": "Nuuk",
      "region": "NA",
      "currency": {
          "code": "DKK",
          "name": "Danish krone",
          "symbol": "kr"
      },
      "language": {
          "code": "kl",
          "name": "Kalaallisut"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_Greenland.svg"
  },
  {
      "name": "Grenada",
      "code": "GD",
      "capital": "St. George's",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Grenada.svg"
  },
  // {
  //     "name": "Guadeloupe",
  //     "code": "GP",
  //     "capital": "Basse-Terre",
  //     "region": "NA",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": "https://restcountries.eu/data/glp.svg"
  // },
  {
      "name": "Guam",
      "code": "GU",
      "capital": "Hagåtña",
      "region": "OC",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/07/Flag_of_Guam.svg"
  },
  {
      "name": "Guatemala",
      "code": "GT",
      "capital": "Guatemala City",
      "region": "NA",
      "currency": {
          "code": "GTQ",
          "name": "Guatemalan quetzal",
          "symbol": "Q"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg"
  },
  {
      "name": "Guernsey",
      "code": "GG",
      "capital": "St. Peter Port",
      "region": "EU",
      "currency": {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fa/Flag_of_Guernsey.svg"
  },
  {
      "name": "Guinea",
      "code": "GN",
      "capital": "Conakry",
      "region": "AF",
      "currency": {
          "code": "GNF",
          "name": "Guinean franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_Guinea.svg"
  },
  {
      "name": "Guinea-Bissau",
      "code": "GW",
      "capital": "Bissau",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Guinea-Bissau.svg"
  },
  {
      "name": "Guyana",
      "code": "GY",
      "capital": "Georgetown",
      "region": "SA",
      "currency": {
          "code": "GYD",
          "name": "Guyanese dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_Guyana.svg"
  },
  {
      "name": "Haiti",
      "code": "HT",
      "capital": "Port-au-Prince",
      "region": "Americas",
      "currency": {
          "code": "HTG",
          "name": "Haitian gourde",
          "symbol": "G"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Haiti.svg"
  },
  {
      "name": "Heard Island and McDonald Islands",
      "code": "HM",
      "capital": "",
      "region": "",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bb/Proposed_flag_of_Antarctica_%28Graham_Bartram%29.svg"
  },
  {
      "name": "Holy See",
      "code": "VA",
      "capital": "Rome",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_the_Vatican_City.svg"
  },
  {
      "name": "Honduras",
      "code": "HN",
      "capital": "Tegucigalpa",
      "region": "NA",
      "currency": {
          "code": "HNL",
          "name": "Honduran lempira",
          "symbol": "L"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/82/Flag_of_Honduras.svg"
  },
  {
      "name": "Hong Kong",
      "code": "HK",
      "capital": "City of Victoria",
      "region": "AS",
      "currency": {
          "code": "HKD",
          "name": "Hong Kong dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/5b/Flag_of_Hong_Kong.svg"
  },
  {
      "name": "Hungary",
      "code": "HU",
      "capital": "Budapest",
      "region": "EU",
      "currency": {
          "code": "HUF",
          "name": "Hungarian forint",
          "symbol": "Ft"
      },
      "language": {
          "code": "hu",
          "name": "Hungarian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/c1/Flag_of_Hungary.svg"
  },
  {
      "name": "Iceland",
      "code": "IS",
      "capital": "Reykjavík",
      "region": "EU",
      "currency": {
          "code": "ISK",
          "name": "Icelandic króna",
          "symbol": "kr"
      },
      "language": {
          "code": "is",
          "name": "Icelandic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Iceland.svg"
  },
  {
      "name": "India",
      "code": "IN",
      "capital": "New Delhi",
      "region": "AS",
      "currency": {
          "code": "INR",
          "name": "Indian rupee",
          "symbol": "₹"
      },
      "language": {
          "code": "hi",
          "name": "Hindi"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
  },
  {
      "name": "Indonesia",
      "code": "ID",
      "capital": "Jakarta",
      "region": "AS",
      "currency": {
          "code": "IDR",
          "name": "Indonesian rupiah",
          "symbol": "Rp"
      },
      "language": {
          "code": "id",
          "name": "Indonesian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Indonesia.svg"
  },
  // {
  //     "name": "Côte d'Ivoire",
  //     "code": "CI",
  //     "capital": "Yamoussoukro",
  //     "region": "AF",
  //     "currency": {
  //         "code": "XOF",
  //         "name": "West African CFA franc",
  //         "symbol": "Fr"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": "https://restcountries.eu/data/civ.svg"
  // },
  {
      "name": "Iran (Islamic Republic of)",
      "code": "IR",
      "capital": "Tehran",
      "region": "AS",
      "currency": {
          "code": "IRR",
          "name": "Iranian rial",
          "symbol": "﷼"
      },
      "language": {
          "code": "fa",
          "name": "Persian (Farsi)"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/ca/Flag_of_Iran.svg"
  },
  {
      "name": "Iraq",
      "code": "IQ",
      "capital": "Baghdad",
      "region": "AS",
      "currency": {
          "code": "IQD",
          "name": "Iraqi dinar",
          "symbol": "ع.د"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg"
  },
  {
      "name": "Ireland",
      "code": "IE",
      "capital": "Dublin",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "ga",
          "name": "Irish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/45/Flag_of_Ireland.svg"
  },
  {
      "name": "Isle of Man",
      "code": "IM",
      "capital": "Douglas",
      "region": "EU",
      "currency": {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_the_Isle_of_Man.svg"
  },
  {
      "name": "Israel",
      "code": "IL",
      "capital": "Jerusalem",
      "region": "AS",
      "currency": {
          "code": "ILS",
          "name": "Israeli new shekel",
          "symbol": "₪"
      },
      "language": {
          "code": "he",
          "name": "Hebrew (modern)"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d4/Flag_of_Israel.svg"
  },
  {
      "name": "Italy",
      "code": "IT",
      "capital": "Rome",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "it",
          "name": "Italian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/0/03/Flag_of_Italy.svg"
  },
  {
      "name": "Jamaica",
      "code": "JM",
      "capital": "Kingston",
      "region": "NA",
      "currency": {
          "code": "JMD",
          "name": "Jamaican dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/0a/Flag_of_Jamaica.svg"
  },
  {
      "name": "Japan",
      "code": "JP",
      "capital": "Tokyo",
      "region": "AS",
      "currency": {
          "code": "JPY",
          "name": "Japanese yen",
          "symbol": "¥"
      },
      "language": {
          "code": "ja",
          "name": "Japanese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/9/9e/Flag_of_Japan.svg"
  },
  {
      "name": "Jersey",
      "code": "JE",
      "capital": "Saint Helier",
      "region": "EU",
      "currency": {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "iso639_2": "eng",
          "name": "English",
          "nativeName": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/1c/Flag_of_Jersey.svg"
  },
  {
      "name": "Jordan",
      "code": "JO",
      "capital": "Amman",
      "region": "AS",
      "currency": {
          "code": "JOD",
          "name": "Jordanian dinar",
          "symbol": "د.ا"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/c0/Flag_of_Jordan.svg"
  },
  {
      "name": "Kazakhstan",
      "code": "KZ",
      "capital": "Astana",
      "region": "AS",
      "currency": {
          "code": "KZT",
          "name": "Kazakhstani tenge",
          "symbol": null
      },
      "language": {
          "code": "kk",
          "name": "Kazakh"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kazakhstan.svg"
  },
  {
      "name": "Kenya",
      "code": "KE",
      "capital": "Nairobi",
      "region": "AF",
      "currency": {
          "code": "KES",
          "name": "Kenyan shilling",
          "symbol": "Sh"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Kenya.svg"
  },
  {
      "name": "Kiribati",
      "code": "KI",
      "capital": "South Tarawa",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Kiribati.svg"
  },
  {
      "name": "Kuwait",
      "code": "KW",
      "capital": "Kuwait City",
      "region": "AS",
      "currency": {
          "code": "KWD",
          "name": "Kuwaiti dinar",
          "symbol": "د.ك"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg"
  },
  {
      "name": "Kyrgyzstan",
      "code": "KG",
      "capital": "Bishkek",
      "region": "AS",
      "currency": {
          "code": "KGS",
          "name": "Kyrgyzstani som",
          "symbol": "с"
      },
      "language": {
          "code": "ky",
          "name": "Kyrgyz"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/c7/Flag_of_Kyrgyzstan.svg"
  },
  {
      "name": "Lao People's Democratic Republic",
      "code": "LA",
      "capital": "Vientiane",
      "region": "AS",
      "currency": {
          "code": "LAK",
          "name": "Lao kip",
          "symbol": "₭"
      },
      "language": {
          "code": "lo",
          "name": "Lao"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg"
  },
  {
      "name": "Latvia",
      "code": "LV",
      "capital": "Riga",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "lv",
          "name": "Latvian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Latvia.svg"
  },
  {
      "name": "Lebanon",
      "code": "LB",
      "capital": "Beirut",
      "region": "AS",
      "currency": {
          "code": "LBP",
          "name": "Lebanese pound",
          "symbol": "ل.ل"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/59/Flag_of_Lebanon.svg"
  },
  {
      "name": "Lesotho",
      "code": "LS",
      "capital": "Maseru",
      "region": "AF",
      "currency": {
          "code": "LSL",
          "name": "Lesotho loti",
          "symbol": "L"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/4a/Flag_of_Lesotho.svg"
  },
  {
      "name": "Liberia",
      "code": "LR",
      "capital": "Monrovia",
      "region": "AF",
      "currency": {
          "code": "LRD",
          "name": "Liberian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/b8/Flag_of_Liberia.svg"
  },
  {
      "name": "Libya",
      "code": "LY",
      "capital": "Tripoli",
      "region": "AF",
      "currency": {
          "code": "LYD",
          "name": "Libyan dinar",
          "symbol": "ل.د"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/05/Flag_of_Libya.svg"
  },
  {
      "name": "Liechtenstein",
      "code": "LI",
      "capital": "Vaduz",
      "region": "EU",
      "currency": {
          "code": "CHF",
          "name": "Swiss franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "de",
          "name": "German"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/47/Flag_of_Liechtenstein.svg"
  },
  {
      "name": "Lithuania",
      "code": "LT",
      "capital": "Vilnius",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "lt",
          "name": "Lithuanian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Lithuania.svg"
  },
  {
      "name": "Luxembourg",
      "code": "LU",
      "capital": "Luxembourg",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/da/Flag_of_Luxembourg.svg"
  },
  {
      "name": "Macao",
      "code": "MO",
      "capital": "",
      "region": "AS",
      "currency": {
          "code": "MOP",
          "name": "Macanese pataca",
          "symbol": "P"
      },
      "language": {
          "code": "zh",
          "name": "Chinese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/63/Flag_of_Macau.svg"
  },
  {
      "name": "Macedonia (the former Yugoslav Republic of)",
      "code": "MK",
      "capital": "Skopje",
      "region": "EU",
      "currency": {
          "code": "MKD",
          "name": "Macedonian denar",
          "symbol": "ден"
      },
      "language": {
          "code": "mk",
          "name": "Macedonian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_North_Macedonia.sv"
  },
  {
      "name": "Madagascar",
      "code": "MG",
      "capital": "Antananarivo",
      "region": "AF",
      "currency": {
          "code": "MGA",
          "name": "Malagasy ariary",
          "symbol": "Ar"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Madagascar.svg"
  },
  {
      "name": "Malawi",
      "code": "MW",
      "capital": "Lilongwe",
      "region": "AF",
      "currency": {
          "code": "MWK",
          "name": "Malawian kwacha",
          "symbol": "MK"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d1/Flag_of_Malawi.svg"
  },
  {
      "name": "Malaysia",
      "code": "MY",
      "capital": "Kuala Lumpur",
      "region": "AS",
      "currency": {
          "code": "MYR",
          "name": "Malaysian ringgit",
          "symbol": "RM"
      },
      "language": {
          "code": null,
          "name": "Malaysian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/66/Flag_of_Malaysia.svg"
  },
  {
      "name": "Maldives",
      "code": "MV",
      "capital": "Malé",
      "region": "AS",
      "currency": {
          "code": "MVR",
          "name": "Maldivian rufiyaa",
          "symbol": ".ރ"
      },
      "language": {
          "code": "dv",
          "name": "Divehi"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/0f/Flag_of_Maldives.svg"
  },
  {
      "name": "Mali",
      "code": "ML",
      "capital": "Bamako",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/92/Flag_of_Mali.svg"
  },
  {
      "name": "Malta",
      "code": "MT",
      "capital": "Valletta",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "mt",
          "name": "Maltese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Malta.svg"
  },
  {
      "name": "Marshall Islands",
      "code": "MH",
      "capital": "Majuro",
      "region": "OC",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/2e/Flag_of_the_Marshall_Islands.sv"
  },
  // {
  //     "name": "Martinique",
  //     "code": "MQ",
  //     "capital": "Fort-de-France",
  //     "region": "Americas",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": ""
  // },
  {
      "name": "Mauritania",
      "code": "MR",
      "capital": "Nouakchott",
      "region": "AF",
      "currency": {
          "code": "MRO",
          "name": "Mauritanian ouguiya",
          "symbol": "UM"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/43/Flag_of_Mauritania.svg"
  },
  {
      "name": "Mauritius",
      "code": "MU",
      "capital": "Port Louis",
      "region": "AF",
      "currency": {
          "code": "MUR",
          "name": "Mauritian rupee",
          "symbol": "₨"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/77/Flag_of_Mauritius.svg"
  },
  // {
  //     "name": "Mayotte",
  //     "code": "YT",
  //     "capital": "Mamoudzou",
  //     "region": "AF",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": "https://restcountries.eu/data/myt.svg"
  // },
  {
      "name": "Mexico",
      "code": "MX",
      "capital": "Mexico City",
      "region": "NA",
      "currency": {
          "code": "MXN",
          "name": "Mexican peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.sv"
  },
  {
      "name": "Micronesia (Federated States of)",
      "code": "FM",
      "capital": "Palikir",
      "region": "OC",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/e4/Flag_of_the_Federated_States_of_Micronesia.svg"
  },
  {
      "name": "Moldova (Republic of)",
      "code": "MD",
      "capital": "Chișinău",
      "region": "EU",
      "currency": {
          "code": "MDL",
          "name": "Moldovan leu",
          "symbol": "L"
      },
      "language": {
          "code": "ro",
          "name": "Romanian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Moldova.svg"
  },
  {
      "name": "Monaco",
      "code": "MC",
      "capital": "Monaco",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/ea/Flag_of_Monaco.svg"
  },
  {
      "name": "Mongolia",
      "code": "MN",
      "capital": "Ulan Bator",
      "region": "AS",
      "currency": {
          "code": "MNT",
          "name": "Mongolian tögrög",
          "symbol": "₮"
      },
      "language": {
          "code": "mn",
          "name": "Mongolian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/4c/Flag_of_Mongolia.svg"
  },
  {
      "name": "Montenegro",
      "code": "ME",
      "capital": "Podgorica",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "sr",
          "name": "Serbian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Montenegro.svg"
  },
  {
      "name": "Montserrat",
      "code": "MS",
      "capital": "Plymouth",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Montserrat.svg"
  },
  {
      "name": "Morocco",
      "code": "MA",
      "capital": "Rabat",
      "region": "AF",
      "currency": {
          "code": "MAD",
          "name": "Moroccan dirham",
          "symbol": "د.م."
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/2c/Flag_of_Morocco.svg"
  },
  {
      "name": "Mozambique",
      "code": "MZ",
      "capital": "Maputo",
      "region": "AF",
      "currency": {
          "code": "MZN",
          "name": "Mozambican metical",
          "symbol": "MT"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Mozambique.svg"
  },
  {
      "name": "Myanmar",
      "code": "MM",
      "capital": "Naypyidaw",
      "region": "AS",
      "currency": {
          "code": "MMK",
          "name": "Burmese kyat",
          "symbol": "Ks"
      },
      "language": {
          "code": "my",
          "name": "Burmese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/8c/Flag_of_Myanmar.svg"
  },
  {
      "name": "Namibia",
      "code": "NA",
      "capital": "Windhoek",
      "region": "AF",
      "currency": {
          "code": "NAD",
          "name": "Namibian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Namibia.svg"
  },
  {
      "name": "Nauru",
      "code": "NR",
      "capital": "Yaren",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/30/Flag_of_Nauru.svg"
  },
  {
      "name": "Nepal",
      "code": "NP",
      "capital": "Kathmandu",
      "region": "AS",
      "currency": {
          "code": "NPR",
          "name": "Nepalese rupee",
          "symbol": "₨"
      },
      "language": {
          "code": "ne",
          "name": "Nepali"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9b/Flag_of_Nepal.svg"
  },
  {
      "name": "Netherlands",
      "code": "NL",
      "capital": "Amsterdam",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/20/Flag_of_the_Netherlands.svg"
  },
  {
      "name": "New Caledonia",
      "code": "NC",
      "capital": "Nouméa",
      "region": "OC",
      "currency": {
          "code": "XPF",
          "name": "CFP franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/0c/Drapeau_de_Nouvelle-Cal%C3%A9donie.png"
  },
  {
      "name": "New Zealand",
      "code": "NZ",
      "capital": "Wellington",
      "region": "OC",
      "currency": {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/3e/Flag_of_New_Zealand.svg"
  },
  {
      "name": "Nicaragua",
      "code": "NI",
      "capital": "Managua",
      "region": "NA",
      "currency": {
          "code": "NIO",
          "name": "Nicaraguan córdoba",
          "symbol": "C$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg"
  },
  {
      "name": "Niger",
      "code": "NE",
      "capital": "Niamey",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "https://restcountries.eu/data/ner.svg"
  },
  {
      "name": "Nigeria",
      "code": "NG",
      "capital": "Abuja",
      "region": "AF",
      "currency": {
          "code": "NGN",
          "name": "Nigerian naira",
          "symbol": "₦"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/79/Flag_of_Nigeria.svg"
  },
  {
      "name": "Niue",
      "code": "NU",
      "capital": "Alofi",
      "region": "OC",
      "currency": {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Niue.svg"
  },
  {
      "name": "Norfolk Island",
      "code": "NF",
      "capital": "Kingston",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Norfolk_Island.svg"
  },
  {
      "name": "Korea (Democratic People's Republic of)",
      "code": "KP",
      "capital": "Pyongyang",
      "region": "AS",
      "currency": {
          "code": "KPW",
          "name": "North Korean won",
          "symbol": "₩"
      },
      "language": {
          "code": "ko",
          "name": "Korean"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/51/Flag_of_North_Korea.svg"
  },
  {
      "name": "Northern Mariana Islands",
      "code": "MP",
      "capital": "Saipan",
      "region": "OC",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/e0/Flag_of_the_Northern_Mariana_Islands.svg"
  },
  {
      "name": "Norway",
      "code": "NO",
      "capital": "Oslo",
      "region": "EU",
      "currency": {
          "code": "NOK",
          "name": "Norwegian krone",
          "symbol": "kr"
      },
      "language": {
          "code": "no",
          "name": "Norwegian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg"
  },
  {
      "name": "Oman",
      "code": "OM",
      "capital": "Muscat",
      "region": "AS",
      "currency": {
          "code": "OMR",
          "name": "Omani rial",
          "symbol": "ر.ع."
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/dd/Flag_of_Oman.svg"
  },
  {
      "name": "Pakistan",
      "code": "PK",
      "capital": "Islamabad",
      "region": "AS",
      "currency": {
          "code": "PKR",
          "name": "Pakistani rupee",
          "symbol": "₨"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/32/Flag_of_Pakistan.svg"
  },
  {
      "name": "Palau",
      "code": "PW",
      "capital": "Ngerulmud",
      "region": "OC",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Palau.svg"
  },
  {
      "name": "Palestine, State of",
      "code": "PS",
      "capital": "Ramallah",
      "region": "AS",
      "currency": {
          "code": "ILS",
          "name": "Israeli new sheqel",
          "symbol": "₪"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/00/Flag_of_Palestine.svg"
  },
  {
      "name": "Panama",
      "code": "PA",
      "capital": "Panama City",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg"
  },
  {
      "name": "Papua New Guinea",
      "code": "PG",
      "capital": "Port Moresby",
      "region": "OC",
      "currency": {
          "code": "PGK",
          "name": "Papua New Guinean kina",
          "symbol": "K"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/e3/Flag_of_Papua_New_Guinea.svg"
  },
  {
      "name": "Paraguay",
      "code": "PY",
      "capital": "Asunción",
      "region": "SA",
      "currency": {
          "code": "PYG",
          "name": "Paraguayan guaraní",
          "symbol": "₲"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg"
  },
  {
      "name": "Peru",
      "code": "PE",
      "capital": "Lima",
      "region": "SA",
      "currency": {
          "code": "PEN",
          "name": "Peruvian sol",
          "symbol": "S/."
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg"
  },
  {
      "name": "Philippines",
      "code": "PH",
      "capital": "Manila",
      "region": "AS",
      "currency": {
          "code": "PHP",
          "name": "Philippine peso",
          "symbol": "₱"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/99/Flag_of_the_Philippines.svg"
  },
  {
      "name": "Pitcairn",
      "code": "PN",
      "capital": "Adamstown",
      "region": "OC",
      "currency": {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg"
  },
  {
      "name": "Poland",
      "code": "PL",
      "capital": "Warsaw",
      "region": "EU",
      "currency": {
          "code": "PLN",
          "name": "Polish złoty",
          "symbol": "zł"
      },
      "language": {
          "code": "pl",
          "name": "Polish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/1/12/Flag_of_Poland.svg"
  },
  {
      "name": "Portugal",
      "code": "PT",
      "capital": "Lisbon",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg"
  },
  {
      "name": "Puerto Rico",
      "code": "PR",
      "capital": "San Juan",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg"
  },
  {
      "name": "Qatar",
      "code": "QA",
      "capital": "Doha",
      "region": "AS",
      "currency": {
          "code": "QAR",
          "name": "Qatari riyal",
          "symbol": "ر.ق"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Qatar.svg"
  },
  // {
  //     "name": "Republic of Kosovo",
  //     "code": "XK",
  //     "capital": "Pristina",
  //     "region": "EU",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "sq",
  //         "name": "Albanian"
  //     },
  //     "flag": "https://restcountries.eu/data/kos.svg"
  // },
  // {
  //     "name": "Réunion",
  //     "code": "RE",
  //     "capital": "Saint-Denis",
  //     "region": "AF",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": "https://restcountries.eu/data/reu.svg"
  // },
  {
      "name": "Romania",
      "code": "RO",
      "capital": "Bucharest",
      "region": "EU",
      "currency": {
          "code": "RON",
          "name": "Romanian leu",
          "symbol": "lei"
      },
      "language": {
          "code": "ro",
          "name": "Romanian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/73/Flag_of_Romania.svg"
  },
  {
      "name": "Russian Federation",
      "code": "RU",
      "capital": "Moscow",
      "region": "EU",
      "currency": {
          "code": "RUB",
          "name": "Russian ruble",
          "symbol": "₽"
      },
      "language": {
          "code": "ru",
          "name": "Russian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/f/f3/Flag_of_Russia.svg"
  },
  {
      "name": "Rwanda",
      "code": "RW",
      "capital": "Kigali",
      "region": "AF",
      "currency": {
          "code": "RWF",
          "name": "Rwandan franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "rw",
          "name": "Kinyarwanda"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Rwanda.svg"
  },
  {
      "name": "Saint Barthélemy",
      "code": "BL",
      "capital": "Gustavia",
      "region": "NA",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
  },
  {
      "name": "Saint Helena, Ascension and Tristan da Cunha",
      "code": "SH",
      "capital": "Jamestown",
      "region": "AF",
      "currency": {
          "code": "SHP",
          "name": "Saint Helena pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
  },
  {
      "name": "Saint Kitts and Nevis",
      "code": "KN",
      "capital": "Basseterre",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Saint_Kitts_and_Nevis.svg"
  },
  {
      "name": "Saint Lucia",
      "code": "LC",
      "capital": "Castries",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_Saint_Lucia.svg"
  },
  {
      "name": "Saint Martin (French part)",
      "code": "MF",
      "capital": "Marigot",
      "region": "NA",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
  },
  // {
  //     "name": "Saint Pierre and Miquelon",
  //     "code": "PM",
  //     "capital": "Saint-Pierre",
  //     "region": "NA",
  //     "currency": {
  //         "code": "EUR",
  //         "name": "Euro",
  //         "symbol": "€"
  //     },
  //     "language": {
  //         "code": "fr",
  //         "name": "French"
  //     },
  //     "flag": "https://restcountries.eu/data/spm.svg"
  // },
  {
      "name": "Saint Vincent and the Grenadines",
      "code": "VC",
      "capital": "Kingstown",
      "region": "NA",
      "currency": {
          "code": "XCD",
          "name": "East Caribbean dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/6d/Flag_of_Saint_Vincent_and_the_Grenadines.svg"
  },
  {
      "name": "Samoa",
      "code": "WS",
      "capital": "Apia",
      "region": "OC",
      "currency": {
          "code": "WST",
          "name": "Samoan tālā",
          "symbol": "T"
      },
      "language": {
          "code": "sm",
          "name": "Samoan"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/87/Flag_of_American_Samoa.svg"
  },
  {
      "name": "San Marino",
      "code": "SM",
      "capital": "City of San Marino",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "it",
          "name": "Italian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/b1/Flag_of_San_Marino.svg"
  },
  {
      "name": "Sao Tome and Principe",
      "code": "ST",
      "capital": "São Tomé",
      "region": "AF",
      "currency": {
          "code": "STD",
          "name": "São Tomé and Príncipe dobra",
          "symbol": "Db"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/4f/Flag_of_Sao_Tome_and_Principe.svg"
  },
  {
      "name": "Saudi Arabia",
      "code": "SA",
      "capital": "Riyadh",
      "region": "AS",
      "currency": {
          "code": "SAR",
          "name": "Saudi riyal",
          "symbol": "ر.س"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "https://restcountries.eu/data/sau.svg"
  },
  {
      "name": "Senegal",
      "code": "SN",
      "capital": "Dakar",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fd/Flag_of_Senegal.svg"
  },
  {
      "name": "Serbia",
      "code": "RS",
      "capital": "Belgrade",
      "region": "EU",
      "currency": {
          "code": "RSD",
          "name": "Serbian dinar",
          "symbol": "дин."
      },
      "language": {
          "code": "sr",
          "name": "Serbian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/ff/Flag_of_Serbia.svg"
  },
  {
      "name": "Seychelles",
      "code": "SC",
      "capital": "Victoria",
      "region": "AF",
      "currency": {
          "code": "SCR",
          "name": "Seychellois rupee",
          "symbol": "₨"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Seychelles.svg"
  },
  {
      "name": "Sierra Leone",
      "code": "SL",
      "capital": "Freetown",
      "region": "AF",
      "currency": {
          "code": "SLL",
          "name": "Sierra Leonean leone",
          "symbol": "Le"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/17/Flag_of_Sierra_Leone.svg"
  },
  {
      "name": "Singapore",
      "code": "SG",
      "capital": "Singapore",
      "region": "AS",
      "currency": {
          "code": "SGD",
          "name": "Singapore dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Singapore.svg"
  },
  {
      "name": "Sint Maarten (Dutch part)",
      "code": "SX",
      "capital": "Philipsburg",
      "region": "Americas",
      "currency": {
          "code": "ANG",
          "name": "Netherlands Antillean guilder",
          "symbol": "ƒ"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d3/Flag_of_Sint_Maarten.svg"
  },
  {
      "name": "Slovakia",
      "code": "SK",
      "capital": "Bratislava",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "sk",
          "name": "Slovak"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/e6/Flag_of_Slovakia.svg"
  },
  {
      "name": "Slovenia",
      "code": "SI",
      "capital": "Ljubljana",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "sl",
          "name": "Slovene"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f0/Flag_of_Slovenia.svg"
  },
  {
      "name": "Solomon Islands",
      "code": "SB",
      "capital": "Honiara",
      "region": "OC",
      "currency": {
          "code": "SBD",
          "name": "Solomon Islands dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/74/Flag_of_the_Solomon_Islands.svg"
  },
  {
      "name": "Somalia",
      "code": "SO",
      "capital": "Mogadishu",
      "region": "AF",
      "currency": {
          "code": "SOS",
          "name": "Somali shilling",
          "symbol": "Sh"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_Somalia.svg"
  },
  {
      "name": "South Africa",
      "code": "ZA",
      "capital": "Pretoria",
      "region": "AF",
      "currency": {
          "code": "ZAR",
          "name": "South African rand",
          "symbol": "R"
      },
      "language": {
          "code": "en",
          "iso639_2": "eng",
          "name": "English",
          "nativeName": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/af/Flag_of_South_Africa.svg"
  },
  {
      "name": "South Georgia and the South Sandwich Islands",
      "code": "GS",
      "capital": "King Edward Point",
      "region": "NA",
      "currency": {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg"
  },
  {
      "name": "Korea (Republic of)",
      "code": "KR",
      "capital": "Seoul",
      "region": "AS",
      "currency": {
          "code": "KRW",
          "name": "South Korean won",
          "symbol": "₩"
      },
      "language": {
          "code": "ko",
          "name": "Korean"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/09/Flag_of_South_Korea.svg"
  },
  {
      "name": "South Sudan",
      "code": "SS",
      "capital": "Juba",
      "region": "AF",
      "currency": {
          "code": "SSP",
          "name": "South Sudanese pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/7/7a/Flag_of_South_Sudan.svg"
  },
  {
      "name": "Spain",
      "code": "ES",
      "capital": "Madrid",
      "region": "EU",
      "currency": {
          "code": "EUR",
          "name": "Euro",
          "symbol": "€"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/89/Bandera_de_Espa%C3%B1a.svg"
  },
  {
      "name": "Sri Lanka",
      "code": "LK",
      "capital": "Colombo",
      "region": "AS",
      "currency": {
          "code": "LKR",
          "name": "Sri Lankan rupee",
          "symbol": "Rs"
      },
      "language": {
          "code": "si",
          "iso639_2": "sin",
          "name": "Sinhalese",
          "nativeName": "සිංහල"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/11/Flag_of_Sri_Lanka.svg"
  },
  {
      "name": "Sudan",
      "code": "SD",
      "capital": "Khartoum",
      "region": "AF",
      "currency": {
          "code": "SDG",
          "name": "Sudanese pound",
          "symbol": "ج.س."
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/01/Flag_of_Sudan.svg"
  },
  {
      "name": "Suriname",
      "code": "SR",
      "capital": "Paramaribo",
      "region": "SA",
      "currency": {
          "code": "SRD",
          "name": "Surinamese dollar",
          "symbol": "$"
      },
      "language": {
          "code": "nl",
          "name": "Dutch"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/60/Flag_of_Suriname.svg"
  },
  {
      "name": "Svalbard and Jan Mayen",
      "code": "SJ",
      "capital": "Longyearbyen",
      "region": "EU",
      "currency": {
          "code": "NOK",
          "name": "Norwegian krone",
          "symbol": "kr"
      },
      "language": {
          "code": "no",
          "name": "Norwegian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Norway.svg"
  },
  {
      "name": "Swaziland",
      "code": "SZ",
      "capital": "Lobamba",
      "region": "AF",
      "currency": {
          "code": "SZL",
          "name": "Swazi lilangeni",
          "symbol": "L"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg"
  },
  {
      "name": "Sweden",
      "code": "SE",
      "capital": "Stockholm",
      "region": "EU",
      "currency": {
          "code": "SEK",
          "name": "Swedish krona",
          "symbol": "kr"
      },
      "language": {
          "code": "sv",
          "name": "Swedish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/4/4c/Flag_of_Sweden.svg"
  },
  {
      "name": "Switzerland",
      "code": "CH",
      "capital": "Bern",
      "region": "EU",
      "currency": {
          "code": "CHF",
          "name": "Swiss franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "de",
          "name": "German"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/f3/Flag_of_Switzerland.svg"
  },
  {
      "name": "Syrian Arab Republic",
      "code": "SY",
      "capital": "Damascus",
      "region": "AS",
      "currency": {
          "code": "SYP",
          "name": "Syrian pound",
          "symbol": "£"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg"
  },
  // {
  //     "name": "Taiwan",
  //     "code": "TW",
  //     "capital": "Taipei",
  //     "region": "AS",
  //     "currency": {
  //         "code": "TWD",
  //         "name": "New Taiwan dollar",
  //         "symbol": "$"
  //     },
  //     "language": {
  //         "code": "zh",
  //         "name": "Chinese"
  //     },
  //     "flag": "https://restcountries.eu/data/twn.svg"
  // },
  {
      "name": "Tajikistan",
      "code": "TJ",
      "capital": "Dushanbe",
      "region": "AS",
      "currency": {
          "code": "TJS",
          "name": "Tajikistani somoni",
          "symbol": "ЅМ"
      },
      "language": {
          "code": "tg",
          "name": "Tajik"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/d/d0/Flag_of_Tajikistan.svg"
  },
  {
      "name": "Tanzania, United Republic of",
      "code": "TZ",
      "capital": "Dodoma",
      "region": "AF",
      "currency": {
          "code": "TZS",
          "name": "Tanzanian shilling",
          "symbol": "Sh"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tanzania.svg"
  },
  {
      "name": "Thailand",
      "code": "TH",
      "capital": "Bangkok",
      "region": "AS",
      "currency": {
          "code": "THB",
          "name": "Thai baht",
          "symbol": "฿"
      },
      "language": {
          "code": "th",
          "name": "Thai"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/a9/Flag_of_Thailand.svg"
  },
  {
      "name": "Timor-Leste",
      "code": "TL",
      "capital": "Dili",
      "region": "AS",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "pt",
          "name": "Portuguese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/26/Flag_of_East_Timor.svg"
  },
  {
      "name": "Togo",
      "code": "TG",
      "capital": "Lomé",
      "region": "AF",
      "currency": {
          "code": "XOF",
          "name": "West African CFA franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/68/Flag_of_Togo.svg"
  },
  {
      "name": "Tokelau",
      "code": "TK",
      "capital": "Fakaofo",
      "region": "OC",
      "currency": {
          "code": "NZD",
          "name": "New Zealand dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/8e/Flag_of_Tokelau.svg"
  },
  {
      "name": "Tonga",
      "code": "TO",
      "capital": "Nuku'alofa",
      "region": "OC",
      "currency": {
          "code": "TOP",
          "name": "Tongan paʻanga",
          "symbol": "T$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Tonga.svg"
  },
  {
      "name": "Trinidad and Tobago",
      "code": "TT",
      "capital": "Port of Spain",
      "region": "SA",
      "currency": {
          "code": "TTD",
          "name": "Trinidad and Tobago dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/64/Flag_of_Trinidad_and_Tobago.svg"
  },
  {
      "name": "Tunisia",
      "code": "TN",
      "capital": "Tunis",
      "region": "AF",
      "currency": {
          "code": "TND",
          "name": "Tunisian dinar",
          "symbol": "د.ت"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/ce/Flag_of_Tunisia.svg"
  },
  {
      "name": "Turkey",
      "code": "TR",
      "capital": "Ankara",
      "region": "AS",
      "currency": {
          "code": "TRY",
          "name": "Turkish lira",
          "symbol": null
      },
      "language": {
          "code": "tr",
          "name": "Turkish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg"
  },
  {
      "name": "Turkmenistan",
      "code": "TM",
      "capital": "Ashgabat",
      "region": "AS",
      "currency": {
          "code": "TMT",
          "name": "Turkmenistan manat",
          "symbol": "m"
      },
      "language": {
          "code": "tk",
          "name": "Turkmen"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/1/1b/Flag_of_Turkmenistan.svg"
  },
  {
      "name": "Turks and Caicos Islands",
      "code": "TC",
      "capital": "Cockburn Town",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/a/a0/Flag_of_the_Turks_and_Caicos_Islands.svg"
  },
  {
      "name": "Tuvalu",
      "code": "TV",
      "capital": "Funafuti",
      "region": "OC",
      "currency": {
          "code": "AUD",
          "name": "Australian dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/3/38/Flag_of_Tuvalu.svg"
  },
  {
      "name": "Uganda",
      "code": "UG",
      "capital": "Kampala",
      "region": "AF",
      "currency": {
          "code": "UGX",
          "name": "Ugandan shilling",
          "symbol": "Sh"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/4e/Flag_of_Uganda.svg"
  },
  {
      "name": "Ukraine",
      "code": "UA",
      "capital": "Kiev",
      "region": "EU",
      "currency": {
          "code": "UAH",
          "name": "Ukrainian hryvnia",
          "symbol": "₴"
      },
      "language": {
          "code": "uk",
          "name": "Ukrainian"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg"
  },
  {
      "name": "United Arab Emirates",
      "code": "AE",
      "capital": "Abu Dhabi",
      "region": "AS",
      "currency": {
          "code": "AED",
          "name": "United Arab Emirates dirham",
          "symbol": "د.إ"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg"
  },
  {
      "name": "United Kingdom of Great Britain and Northern Ireland",
      "code": "GB",
      "capital": "London",
      "region": "EU",
      "currency": {
          "code": "GBP",
          "name": "British pound",
          "symbol": "£"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/a/ae/Flag_of_the_United_Kingdom.svg"
  },
  {
      "name": "United States of America",
      "code": "us",
      "capital": "Washington, D.C.",
      "region": "NA",
      "currency": {
          "code": "USD",
          "name": "United States dollar",
          "symbol": "$"
      },
      "language": {
          "code": "en",
          "iso639_2": "eng",
          "name": "English",
          "nativeName": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
  },
  {
      "name": "Uruguay",
      "code": "UY",
      "capital": "Montevideo",
      "region": "SA",
      "currency": {
          "code": "UYU",
          "name": "Uruguayan peso",
          "symbol": "$"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg"
  },
  {
      "name": "Uzbekistan",
      "code": "UZ",
      "capital": "Tashkent",
      "region": "AS",
      "currency": {
          "code": "UZS",
          "name": "Uzbekistani so'm",
          "symbol": null
      },
      "language": {
          "code": "uz",
          "name": "Uzbek"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/84/Flag_of_Uzbekistan.svg"
  },
  {
      "name": "Vanuatu",
      "code": "VU",
      "capital": "Port Vila",
      "region": "OC",
      "currency": {
          "code": "VUV",
          "name": "Vanuatu vatu",
          "symbol": "Vt"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/6e/Flag_of_Vanuatu_%28official%29.svg"
  },
  {
      "name": "Venezuela (Bolivarian Republic of)",
      "code": "VE",
      "capital": "Caracas",
      "region": "SA",
      "currency": {
          "code": "VEF",
          "name": "Venezuelan bolívar",
          "symbol": "Bs F"
      },
      "language": {
          "code": "es",
          "name": "Spanish"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Venezuela.svg"
  },
  {
      "name": "Viet Nam",
      "code": "VN",
      "capital": "Hanoi",
      "region": "AS",
      "currency": {
          "code": "VND",
          "name": "Vietnamese đồng",
          "symbol": "₫"
      },
      "language": {
          "code": "vi",
          "name": "Vietnamese"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Vietnam.svg"
  },
  {
      "name": "Wallis and Futuna",
      "code": "WF",
      "capital": "Mata-Utu",
      "region": "OC",
      "currency": {
          "code": "XPF",
          "name": "CFP franc",
          "symbol": "Fr"
      },
      "language": {
          "code": "fr",
          "name": "French"
      },
      "flag": "//upload.wikimedia.org/wikipedia/en/c/c3/Flag_of_France.svg"
  },
  // {
  //     "name": "Western Sahara",
  //     "code": "EH",
  //     "capital": "El Aaiún",
  //     "region": "AF",
  //     "currency": {
  //         "code": "MAD",
  //         "name": "Moroccan dirham",
  //         "symbol": "د.م."
  //     },
  //     "language": {
  //         "code": "es",
  //         "name": "Spanish"
  //     },
  //     "flag": "https://restcountries.eu/data/esh.svg"
  // },
  {
      "name": "Yemen",
      "code": "YE",
      "capital": "Sana'a",
      "region": "AS",
      "currency": {
          "code": "YER",
          "name": "Yemeni rial",
          "symbol": "﷼"
      },
      "language": {
          "code": "ar",
          "name": "Arabic"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/8/89/Flag_of_Yemen.svg"
  },
  {
      "name": "Zambia",
      "code": "ZM",
      "capital": "Lusaka",
      "region": "AF",
      "currency": {
          "code": "ZMW",
          "name": "Zambian kwacha",
          "symbol": "ZK"
      },
      "language": {
          "code": "en",
          "name": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/0/06/Flag_of_Zambia.svg"
  },
  {
      "name": "Zimbabwe",
      "code": "ZW",
      "capital": "Harare",
      "region": "AF",
      "currency": {
          "code": "BWP",
          "name": "Botswana pula",
          "symbol": "P"
      },
      "language": {
          "code": "en",
          "iso639_2": "eng",
          "name": "English",
          "nativeName": "English"
      },
      "flag": "//upload.wikimedia.org/wikipedia/commons/6/6a/Flag_of_Zimbabwe.svg"
  }
]
