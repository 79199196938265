import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";

const MySlider = styled(Slider)({
  color: "#06BDFF",
  height: 5, // Slider track height
  "& .MuiSlider-mark": {
    backgroundColor: "#06BDFF", // Color of the mark
    height: 8, // Make the mark taller
    width: 8, // Make the mark wider
    borderRadius: "50%", // Make the mark circular
  },
  /*  "& .MuiSlider-markActive": {
    opacity: 1, // Keep active mark fully visible
    backgroundColor: "red", // Active mark can have a different color
  }, */
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#06BDFF",
    // border: "2px solid currentColor",
  },
  "& .MuiSlider-track": {
    height: 4,
  },
  "& .MuiSlider-rail": {
    height: 4,
  },
});

const SliderComponent = ({ valuetext, lengthMarks, customLengthValue }) => {
  return (
    <MySlider
      aria-label="Always visible"
      defaultValue={customLengthValue}
      //  getAriaValueText={valuetext}
      onChange={valuetext}
      step={1}
      marks={lengthMarks}
      min={1}
      max={5}
      // valueLabelDisplay="on"
    />
  );
};

export default SliderComponent;
