import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import useAuth from "../../../../hooks/useAuth";
import { useLocation } from "react-router-dom";

import {
  Box,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Checkbox,
  Dialog,
  TablePagination,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";

import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import GoogleError from "../../../../components/CustomComponents/Errors/Reconnect";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../../../../styles/style";
const Paper = styled(MuiPaper)(spacing);

// const CustomWidthTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "grey",
//   },
// });

const EnhancedTableHead = (props) => {
  const { onRequestSort, getAllLocation, t } = props;

  const headCells = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "phone", alignment: "left", label: t("Phone_Number") },
    { id: "city", alignment: "left", label: t("Address") },
    /*   { id: "account", alignment: "left", label: t("Account") }, */
    /*  { id: "products", alignment: "center", label: t("TableProducts") },
    { id: "status", alignment: "left", label: t("TableStatus") },
    { id: "actions", alignment: "center", label: t("TableAction") } */
    ,
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell?.id}
            align={headCell?.alignment}
            padding={headCell?.disablePadding ? "none" : "normal"}
            //     sortDirection={orderBy === headCell?.id ? order : false}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const location = useLocation();
  const params = useParams();
  const { user } = useAuth();

  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { permissionsAcess } = useAuth();

  const [allLocations, setAllLocations] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [displayErrorLocation, setDisplayErrorLocation] = useState(null);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const { signOut } = useAuth();

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredPermissions?.forEach((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setViewPermission(true);
        }
      });
    }
  }, []);
  useEffect(async () => {
    await getAllLocation();
  }, []);
  useEffect(async () => {
    await getAllLocation();
  }, [rowsPerPage, page]);

  /* const getAllLocation = async () => {
    setDisplayError(false);
    setIsLoading(true);
    try {
      const res = await api.get(`/googleConnect/auth-location`, {
        params: {
          refreshToken: location?.state?.refreshToken,
          account: location?.state?.accountId,
        },
      });
      if (res.status === 200) {
        setAllLocations(res.data.data.locations);
        setIsLoading(false);
        setFilterWithName("");
      }
    } catch (error) {
      setIsLoading(false);
    }
  }; */
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const getAllLocation = async () => {
    // let temp = [...productFilter];
    setIsLoading(true);
    try {
      const res = await api.get(
        `/googleConnect/get-all-locations-refreshtoken?locationId=${params?.id}`
      );
      if (res.status === 200) {
        setDisplayError(false);

        setAllLocations(res.data.data);
        setIsLoading(false);
        // setFilterWithName("");
        /*  if (res.data.data.results.length > 0) {
            let defaultlocation = null;
            defaultlocation = res.data.data.results?.find(
              (item, index) => item.defaultLocation !== null
            );
            setDefaultvalueLocation(defaultlocation);
          } else {
            setDefaultvalueLocation(null);
          } */
      }
    } catch (error) {
      setDisplayError(true);

      setDisplayErrorLocation(error?.response?.data?.data);
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  const createSocialLink = async (data) => {
    try {
      setLoading(true);
      const res = await api.post(`/googleConnect/create/socialLinks`, {
        account: location?.state?.accountId,
        refreshToken: location?.state?.refreshToken,
        locations: selectedLocations,
      });

      if (res?.data?.status === true) {
        setLoading(false);
        setOpen(false);
        toast.success(res?.data?.message);
        navigate("/user/locations");
      }
    } catch (error) {
      setLoading(false);
      setOpen(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleBackButton = () => {
    navigate("/user/google_accounts", {
      state: {
        refreshToken: location?.state?.refreshToken,
      },
    });
  };

  const handleCloseModel = () => {
    setOpen(false);
  };

  const handleConnectWithGoogle = () => {
    fetchURL();
  };

  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data + "&state=" + params?.id;
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }
  const handleNavigationToDashboard = () => {
    navigate("/");
  };
  const handleSwitchAccounts = () => {
    navigate("/user/accounts");
  };
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Connect_With_Google_Text")}
          description={t("google_connect")}
          onConfirm={() => createSocialLink()}
          onCancel={handleCloseModel}
        />
      </Dialog>
      <Helmet title="Locations" />
      <TitleHeader
        title={t("Refresh_Google_Connection")}
        subHeading={t("Refresh_Google_subHeading")}
        googleButton={allLocations?.allSocialLinks?.length > 0 ? true : false}
        googleButtonName={t("Reconnect")}
        handleConnectWithGoogle={handleConnectWithGoogle}
        showBackButton={false}
        handleBackButton={handleBackButton}
        viewPermission={viewPermission}
        editPermission={editPermission}
        addPermission={addPermission}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/*  <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getSearchedLocation}
                placeholder={t("Filter_locations")}
                filterWithName={filterWithName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CommonButton
                isLoading={isLoading}
                onSubmit={getSearchedLocation}
                label={t("Search")}
                alignment={true}
              />
            </Grid>
          </Grid> */}

          {allLocations?.allSocialLinks?.length > 0 ? (
            <Grid container marginTop="1rem" spacing={6}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <CustomizedTableHead>
                      <CustomizedTableRow>
                        <CustomizedTableCell sx={{ width: "300px" }}>
                          {t("ID")}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ width: "300px" }}>
                          {t("LocationTableName")}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ width: "300px" }}>
                          {t("Phone_Number")}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ width: "300px" }}>
                          {t("Address")}
                        </CustomizedTableCell>
                        <CustomizedTableCell sx={{ width: "300px" }}>
                          {t("Email")}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    </CustomizedTableHead>

                    <TableBody>
                      {allLocations?.allSocialLinks?.length > 0 &&
                        allLocations?.allSocialLinks?.map((row, index) => {
                          return (
                            <CustomizedTableRow
                              // hover
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                              sx={{ width: "300px" }}
                            >
                              <CustomizedTableCell>
                                {row?.location?.id}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.location?.internalName}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.location?.telephone !== ""
                                  ? row?.location?.telephone
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.location?.addressLine1 !== ""
                                  ? row?.location?.addressLine1 +
                                    (row?.location?.city
                                      ? "," + row?.location?.city
                                      : "")
                                  : "--"}
                              </CustomizedTableCell>

                              <CustomizedTableCell align="left">
                                {row?.location?.businessEmail !== ""
                                  ? row?.location?.businessEmail
                                  : "--"}
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/*  <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allLocations?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Grid>
              {/*  <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                my={3}
                className={classes.buttonContainerAccount}
              >
                <CommonButton
                  onSubmit={() => handleBackButton()}
                  // type="submit"
                  displayWhite="true"
                  variant="contained"
                  // disabled={isSubmitting}

                  label={t("Cancel")}
                />
                <LoadingButton
                  type="submit"
                  loading={loading}
                  variant="contained"
                  color="primary"
                  disabled={selectedLocations?.length===0}
                  onClick={() => handleOpenModel()}
                  sx={{
                    height: "50px",
                    borderRadius: "8px",
                    marginLeft: "5px",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {t("Connect")}
                </LoadingButton>
              </Grid> */}
            </Grid>
          ) : (
            /*  <Typography variant="h3">{t("data_found")}</Typography> */
            <Box className={classes.locationbox}>
              <GoogleError
                text={
                  displayError
                    ? t("error_message_reconnect")
                    : t("success_message_reconnect")
                }
                subtext={
                  displayError
                    ? t("error_message_reconnect_subText1") +
                      displayErrorLocation?.name +
                      t("error_message_reconnect_subText2")
                    : t("error_message_reconnect_subText")
                }
                displayError={displayError}
              />

              {displayError === false ? (
                <CommonButton
                  onSubmit={handleNavigationToDashboard}
                  /* icon={
                  <img 
alt=""
                    // src={Googleicon}
                    className={classes.commonButton}
                    alt={"/google Icon"}
                  />
                } */
                  label={t("back_to_dashboard")}
                />
              ) : (
                displayError === true &&
                user?.CompanyUsers?.length > 1 && (
                  <CommonButton
                    onSubmit={handleSwitchAccounts}
                    /* icon={
                  <img 
alt=""
                    // src={Googleicon}
                    className={classes.commonButton}
                    alt={"/google Icon"}
                  />
                } */
                    label={t("Switch_Account")}
                  />
                )
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default MyLocations;
