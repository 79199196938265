import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Divider,
  Tooltip,
  tooltipClasses,
  Button,
  Box,
  Checkbox,
  Dialog,
  createFilterOptions,
  Menu,
  MenuItem,
  TablePagination,
  Paper as MuiPaper,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import Delete from "../../../components/Models/DeleteModal/Delete";
import { useTheme } from "@mui/material/styles";
import CreateLocationGroupModel from "./CreateLocationGrroupModel";
import { toast } from "react-toastify";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import { makeStyles } from "@mui/styles";
import useAuth from "../../../hooks/useAuth";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import TitleHeader from "../../../components/CustomComponents/title";
import Loader from "../../../components/Loaders/Loader";
import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import useDateFormat from "../../../hooks/useDateFormat";

const Paper = styled(MuiPaper)(spacing);
const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const GroupsIndex = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { signOut, permissionsAcess, user } = useAuth();
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [locationGroups, setLocationGroups] = useState(null);
  const [groupsPageNumber, setGroupsPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectAll, setSelectAll] = useState(null);
  const [deleteMultiple, setDeleteMultiple] = useState(false);
  const [openLocationGroupsModel, setOpenLocationGroupsModel] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupLocations, setGroupLocations] = useState([]);
  const [openCreateLocationGroupsModel, setOpenCreateLocationGroupsModel] =
    useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [title, setTitle] = useState("");
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openDeleteMultiModel, setOpenDeleteMultiModel] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  const [duplicateGroupError, setDuplicateGroupError] = useState(false);
  const [allGroups, setAllGroups] = useState(null);
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState(null);
  const [locationGroupData, setLocationGroupData] = useState(null);
  const [deletegroups, setDeleteGroups] = useState([]);
  const [selectAllGroups, setSelectAllGroups] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [filterWithName, setFilterWithName] = useState("");
  const companyData = JSON.parse(localStorage.getItem("company"));
  const { formatDate } = useDateFormat();

  const headCells = [
    // {
    //   padding: "0px",
    //   alignment: "left",
    //   label: (
    //     <Checkbox
    //       checked={selectAll}
    //       onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
    //     />
    //   ),
    // },

    { id: "ID", alignment: "left", label: t("ID") },
    { id: "ratings", alignment: "left", label: t("group_names") },
    { id: "updatedBy", alignment: "left", label: t("updated_at") },
    { id: "created_at", alignment: "left", label: t("created_at") },
    { id: "satisfaction", alignment: "left", label: t("Actions") },
  ];
  const filter = createFilterOptions();

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredPermissions?.forEach((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (locationGroups?.rows?.length === 0) {
      setGroupsPageNumber(groupsPageNumber - 1);
    }
  }, [locationGroups]);

  useEffect(() => {
    getAllLocationGroups();
  }, [groupsPageNumber, rowsPerPage]);

  useEffect(() => {
    getAllLocations();
  }, []);

  const getAllLocations = async () => {
    setRequestLoading(true);
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`);
      if (res.status === 200) {
        setAllLocation(res.data.data);
        setRequestLoading(false);
      }
    } catch (error) {
      setRequestLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const getAllLocationGroups = async () => {
    setGroupsLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(
        `groups?pageNumber=${
          groupsPageNumber + 1
        }&limit=${rowsPerPage}&search=${filterWithName}`
      );
      if (res.status === 200) {
        setLocationGroups(res?.data?.data);
        setGroupsLoading(false);
      }
    } catch (error) {
      setGroupsLoading(false);
      setLocationGroups(null);
    }
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    let dummyArr = [];
    if (e.target.checked) {
      for (var i = 0; i < locationGroups?.rows?.length; i++) {
        deletegroups.push(locationGroups?.rows[i]?.id);
        dummyArr?.push(locationGroups?.rows[i]?.id);
      }

      const uniqueGroupIds = new Set(deletegroups);
      setDeleteMultiple(true);
      setDeleteGroups([...uniqueGroupIds]);
    } else {
      setDeleteGroups([]);
      setDeleteMultiple(false);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setGroupsPageNumber(0);
  };
  const handleChangePage = (event, newPage) => {
    setGroupsPageNumber(newPage);
  };

  const handleCloseLocationGroupsModel = () => {
    setOpenLocationGroupsModel(false);
    setRowsPerPage(10);
    setGroupsPageNumber(0);
    setLocationGroups(null);
    setDeleteId(null);
    setDuplicateErrorMessage(null);
    setDuplicateGroupError(false);
    setSelectAll(false);
  };

  const handleOpenDeleteMultipleGroupModel = (value, id) => {
    setOpenDeleteMultiModel(true);
    setDeleteId(null);
    // setDeleteMultiple(false);
  };
  const handleCloseCreateLocationGroupsModel = () => {
    setOpenCreateLocationGroupsModel(false);
    setTitle("");
    setGroupName("");
    setGroupLocations([]);
    setDeleteId(null);
    setLocationGroupData(null);
    setDuplicateErrorMessage(null);
    setDuplicateGroupError(false);
    setSelectAll(false);
    getAllLocationGroups();
  };
  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };
  const handleOpenCreateLocationGroupsModel = (value, id) => {
    if (value !== undefined && id !== undefined) {
      setTitle(value);
      setDeleteId(id);
      setDeleteGroups([]);
    }

    setOpenCreateLocationGroupsModel(true);
    // setOpenLocationGroupsModel(false);
  };
  const handleTaskChecked = (e, index, taskId) => {
    if (e.target.checked) {
      deletegroups.push(taskId);
    } else {
      var groupIndex = deletegroups.indexOf(taskId);
      deletegroups.splice(groupIndex, 1);
    }
    setDeleteGroups([...deletegroups]);
    if (locationGroups?.rows?.length === deletegroups?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const getLocationGroupById = async () => {
    setGroupsLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.get(`groups/${deleteId}`);
      if (res.status === 200) {
        setLocationGroupData(res?.data?.data);
        setGroupsLoading(false);
      }
    } catch (error) {
      setGroupsLoading(false);
      setLocationGroups(null);
    }
  };

  const createLocationGroup = async () => {
    let locationIds = [];
    if (groupLocations?.length > 0) {
      groupLocations?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    setGroupsLoading(true);
    try {
      const res = await api.post(`groupLocations`, {
        name: groupName,
        locations: locationIds,
      });
      if (res.status === 200) {
        handleCloseCreateLocationGroupsModel();
        toast.success(res?.data?.message);
        getAllLocationGroups();
        // getAllLocationGroups();
        setGroupsLoading(false);
      }
    } catch (error) {
      setGroupsLoading(false);
      setLocationGroups(null);
      if (
        error?.response?.data?.data !== "" &&
        error?.response?.data?.data !== undefined &&
        error?.response?.data?.data === "Group Already Exists With this Name"
      ) {
        setDuplicateErrorMessage(error?.response?.data?.data);
        setDuplicateGroupError(true);
      } else {
        setDuplicateErrorMessage(null);
        setDuplicateGroupError(false);
      }
    }
  };
  const updateLocationGroup = async () => {
    let locationIds = [];
    if (groupLocations?.length > 0) {
      groupLocations?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    setGroupsLoading(true);
    try {
      // const res = await api.get(`/location/search`,);
      const res = await api.put(`groupLocations/${deleteId}`, {
        name: groupName,
        locations: locationIds,
      });
      if (res.status === 200) {
        handleCloseCreateLocationGroupsModel();
        toast.success(res?.data?.message);
        // getAllLocationGroups();

        setSelectAllGroups(false);
        getAllLocationGroups();
        setGroupsLoading(false);
      }
    } catch (error) {
      setGroupsLoading(false);
      setLocationGroups(null);
      if (
        error?.response?.data?.message !== "" &&
        error?.response?.data?.message !== undefined &&
        error?.response?.data?.message === "Group name Already exists"
      ) {
        setDuplicateErrorMessage(error?.response?.data?.message);
        setDuplicateGroupError(true);
      } else {
        setDuplicateErrorMessage(null);
        setDuplicateGroupError(false);
      }
    }
  };
  const handleDeleteMultiGroups = async () => {
    setGroupsLoading(true);
    try {
      const res = await api.delete(
        `/groups/${deletegroups?.length > 0 ? deletegroups?.toString() : ""}`
      );
      if (res.status === 200) {
        setOpenDeleteMultiModel(false);
        setDeleteGroups([]);
        toast.success(
          res.data.message ? res.data.message : "Group deleted successfully"
        );
        setGroupsLoading(false);
        setDeleteId(null);
        getAllLocationGroups();
        setSelectAllGroups(false);
        getAllLocationGroups();
      }
    } catch (error) {
      setGroupsLoading(false);
      setOpenDeleteMultiModel(false);
      setSelectAllGroups(false);

      toast.error(error?.response?.data?.message);
    }
  };
  const handleDeleteGroups = async () => {
    setGroupsLoading(true);
    try {
      const res = await api.delete(`/groups/${deleteId}`);
      if (res.status === 200) {
        setOpenDeleteModel(false);
        setDeleteGroups([]);
        toast.success(
          res.data.message ? res.data.message : "Group deleted successfully"
        );
        setGroupsLoading(false);
        setSelectAllGroups(false);
        getAllLocationGroups();
      }
    } catch (error) {
      setGroupsLoading(false);
      setOpenDeleteModel(false);
      setSelectAllGroups(false);

      toast.error(error?.response?.data?.message);
    }
  };

  const handleClick = (event, id, selectedData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedData);
    setDeleteMultiple(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
    setDeleteMultiple(false);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
    setSelectedRow(null);
    setDeleteMultiple(false);
  };

  const handleOpenDeleteGroupModel = (value, id) => {
    setOpenDeleteModel(true);
    setDeleteId(id);
    // setDeleteMultiple(false);
  };
  const handleCloseDeleteGroupModel = () => {
    setOpenDeleteModel(false);
    setDeleteId(null);
    setDeleteMultiple(false);
    setSelectAll(false);
  };

  const handleCloseDeleteMultipleGroupModel = () => {
    setOpenDeleteMultiModel(false);
    setDeleteId(null);
    setDeleteMultiple(false);
    setSelectAll(false);
    setDeleteGroups([]);
  };

  const handleDisplayDate = (item) => {
    let displayDate = "";
    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <>
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          onClose={handleCloseDeleteGroupModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_group")}
            description={t("Delete_group_description")}
            onConfirm={handleDeleteGroups}
            onCancel={handleCloseDeleteGroupModel}
            loading={groupsLoading}
          />
        </Dialog>
      )}
      {openDeleteMultiModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteMultiModel}
          onClose={handleCloseDeleteMultipleGroupModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_group")}
            description={t("Delete_group_description")}
            onConfirm={handleDeleteMultiGroups}
            onCancel={handleCloseDeleteMultipleGroupModel}
            loading={groupsLoading}
          />
        </Dialog>
      )}
      {openCreateLocationGroupsModel && (
        <CreateLocationGroupModel
          title={title}
          open={openCreateLocationGroupsModel}
          handleCloseNewRequestModel={handleCloseCreateLocationGroupsModel}
          allLocations={allLocation?.results}
          selectedLocation={groupLocations}
          setSelectedLocation={setGroupLocations}
          groupName={groupName}
          setGroupName={setGroupName}
          getsentimentalLocation={getAllLocations}
          requestLoading={requestLoading}
          locationGroupData={locationGroupData}
          getLocationGroupById={getLocationGroupById}
          groupId={deleteId}
          groupsLoading={groupsLoading}
          updateLocationGroup={updateLocationGroup}
          createLocationGroup={createLocationGroup}
          duplicateGroupError={duplicateGroupError}
          setDuplicateGroupError={setDuplicateGroupError}
          duplicateErrorMessage={duplicateErrorMessage}
        />
      )}
      <Box sx={{ marginTop: "1rem" }}>
        <TitleHeader
          showButton={false}
          title={t("manage_groups")}
          showBackButton={true}
          handleBackButton={handleBackButton}
        />
      </Box>

      <Grid container sx={{ marginBottom: "10px" }}>
        <Grid item xs={10} sm={10} md={10}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllLocationGroups}
            placeholder={t("filter_groups")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            isLoading={groupsLoading}
            onSubmit={() => {
              setGroupsPageNumber(0);
              getAllLocationGroups();
            }}
            label={t("Search")}
            alignment={true}
          />
        </Grid>
      </Grid>

      {groupsLoading ? (
        <Grid container justifyContent="center" sx={{ marginTop: "150px" }}>
          <Loader />
        </Grid>
      ) : locationGroups !== null && locationGroups?.rows?.length > 0 ? (
        <Grid container spacing={4} sx={{ paddingLeft: "16px" }}>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* {deletegroups?.length > 0 &&
                (deletePermission && viewPermission ? (
                  <Button
                    sx={{
                      color: "white",
                      backgroundColor: "#FC3652",
                      "&:hover": {
                        backgroundColor: "#ff3366",
                        cursor: "pointer",
                      },
                    }}
                    onClick={() =>
                      handleOpenDeleteMultipleGroupModel(
                        "delete",
                        selectedRow?.id
                      )
                    }
                  >
                    {t("Delete")}
                  </Button>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <Button
                        sx={{
                          backgroundColor: "#e0e0e0",
                          color: "white",
                        }}
                        disabled
                        onClick={() =>
                          handleOpenDeleteMultipleGroupModel(
                            "delete",
                            selectedRow?.id
                          )
                        }
                      >
                        {t("Delete")}
                      </Button>
                    </span>
                  </BootstrapTooltip>
                ))} */}

              {addPermission && viewPermission ? (
                <CommonButton
                  onSubmit={handleOpenCreateLocationGroupsModel}
                  customHeight={true}
                  label={t("create_groups")}
                  icon={<AddIcon sx={{ color: "#FFFF" }} />}
                />
              ) : (
                <BootstrapTooltip title={t("authorized_access")}>
                  <span>
                    <CommonButton
                      onSubmit={handleOpenCreateLocationGroupsModel}
                      customHeight={true}
                      label={t("create_groups")}
                      icon={<AddIcon sx={{ color: "#FFFF" }} />}
                      disabled={true}
                    />
                  </span>
                </BootstrapTooltip>
              )}
            </Box>
          </Grid>
          <Grid container marginTop={"1rem"}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <CustomizedTableHead sx={{ padding: "0px" }}>
                    <CustomizedTableRow>
                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          /*   sx={{
                          paddingRight: headCell?.padding
                            ? headCell?.padding
                            : "26px",
                          paddingLeft: headCell?.padding
                            ? headCell?.padding
                            : "16px",
                        }} */
                          key={headCell.id}
                          align={headCell.alignment}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {locationGroups?.rows?.map((row, index) => (
                      <CustomizedTableRow
                        sx={{ fontWeight: "700px" }}
                        tabIndex={-1}
                        key={index}
                      >
                        {/* <CustomizedTableCell sx={{ width: "80px" }}>
                          <Checkbox
                            checked={deletegroups.includes(row.id)}
                            onChange={(e) => {
                              handleTaskChecked(e, index, row.id, row);
                            }}
                            label=""
                          />
                        </CustomizedTableCell> */}

                        <CustomizedTableCell align="left">
                          {row?.id}
                        </CustomizedTableCell>

                        <CustomizedTableCell align="left">
                          {row?.name}
                        </CustomizedTableCell>
                        <CustomizedTableCell align="left">
                          {/* {companyData && companyData.dateFormat
                            ? handleDisplayDate(row?.updatedAt)
                            : moment(row?.updatedAt).format("MMM DD YYYY")} */}
                          {formatDate(row?.updatedAt)}
                        </CustomizedTableCell>
                        <CustomizedTableCell align="left">
                          {/* {companyData && companyData.dateFormat
                            ? handleDisplayDate(row?.createdAt)
                            : moment(row?.createdAt).format("MMM DD YYYY")} */}
                          {formatDate(row?.createdAt)}
                        </CustomizedTableCell>

                        <CustomizedTableCell padding="none" align="left">
                          <>
                            <>
                              <IconButton
                                onClick={(e) => handleClick(e, row?.id, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                {editPermission && viewPermission ? (
                                  <MenuItem
                                    onClick={() => {
                                      handleOpenCreateLocationGroupsModel(
                                        "edit",
                                        selectedRow?.id
                                      );
                                      closeMenu();
                                      handleClose();
                                      // setDeleteId(selectedRow?.id);
                                    }}
                                  >
                                    <Typography>{t("Edit")}</Typography>
                                  </MenuItem>
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <MenuItem
                                        disabled
                                        /*  onClick={() => {
                                               
                                              handleOpenCreateLocationGroupsModel(
                                                "edit",
                                                selectedRow?.id
                                              );
                                              closeMenu();
                                              handleClose();
                                              // setDeleteId(selectedRow?.id);
                                            }} */
                                      >
                                        <Typography>{t("Edit")}</Typography>
                                      </MenuItem>
                                    </span>
                                  </BootstrapTooltip>
                                )}
                                <Divider variant="middle" />
                                {deletePermission && viewPermission ? (
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();
                                      handleClose();
                                      handleOpenDeleteGroupModel(
                                        "delete",
                                        selectedRow?.id
                                      );
                                      // handleOpenModal(row?.id);
                                    }}
                                  >
                                    <Typography>{t("Delete")}</Typography>
                                  </MenuItem>
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <MenuItem
                                        disabled
                                        onClick={() => {
                                          closeMenu();
                                          handleClose();
                                          handleOpenDeleteGroupModel(
                                            "delete",
                                            selectedRow?.id
                                          );
                                          // handleOpenModal(row?.id);
                                        }}
                                      >
                                        <Typography>{t("Delete")}</Typography>
                                      </MenuItem>
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </Menu>
                            </>
                          </>
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={locationGroups?.count}
                rowsPerPage={rowsPerPage}
                page={groupsPageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          sx={{
            height: "320px",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Grid item sm={8} md={8} lg={8}>
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "20px",
                color: "#1b2430",
                marginTop: "30px",
                marginBottom: "15px",
              }}
            >
              {t("No_Groups_Found")}
            </Typography>
            <Typography
              sx={{
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                color: "#1B2430",

                marginBottom: "15px",
              }}
            >
              {t("no_group_found_description")}
            </Typography>
            {addPermission && viewPermission ? (
              <CommonButton
                onSubmit={handleOpenCreateLocationGroupsModel}
                customHeight={true}
                label={t("create_groups")}
                icon={<AddIcon sx={{ color: "#FFFF" }} />}
              />
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span>
                  <CommonButton
                    disabled={true}
                    onSubmit={handleOpenCreateLocationGroupsModel}
                    customHeight={true}
                    label={t("create_groups")}
                    icon={<AddIcon sx={{ color: "#FFFF" }} />}
                  />
                </span>
              </BootstrapTooltip>
            )}
          </Grid>
        </Grid>
      )}

      {/* </DialogActions> */}
    </>
  );
};

export default GroupsIndex;
