import React, { useEffect, useState } from "react";
import TitleHeader from "../../../components/CustomComponents/title";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  createFilterOptions,
  tooltipClasses,
} from "@mui/material";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../components/Loaders/Loader";
import NotFound from "../../../components/NotFound/NotFound";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import { MoreHoriz } from "@mui/icons-material";
import AccountManagementModal from "../../../components/Models/AccountManagement";
import { api } from "../../../contexts/JWTContext";
import { countriesData } from "../../../assets/countriesList";
import moment from "moment";
import styled from "styled-components";
import CommentModal from "../../../components/Models/AccountManagement/CommentModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ViewMoreFiltersModal from "../../../components/Models/AccountManagement/ViewMoreFiltersModal";
import { toast } from "react-toastify";
import LinearBuffer from "../../UserPages/LocationSourceReviews/Components/Models/LinearProgressLoading";
import { Link } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const EnhancedTableHead = (props) => {
  const { t } = props;

  const headCells = [
    { id: "id", alignment: "left", label: t("locID") },
    { id: "accountName", alignment: "left", label: t("AccountName") },
    { id: "locationName", alignment: "left", label: t("Location_Name") },
    { id: "startDate", alignment: "left", label: t("Start_date") },
    { id: "country", alignment: "left", label: t("country") },
    { id: "industry", alignment: "left", label: t("Industry") },
    { id: "percentage", alignment: "left", label: t("%") },
    { id: "rr", alignment: "left", label: t("RR.") },
    { id: "posts", alignment: "left", label: t("Posts") },
    { id: "dealValue", alignment: "left", label: t("Deal_Value") },
    { id: "accountOwner", alignment: "left", label: t("Account_Owner") },
    { id: "actions", alignment: "left", label: t("actionss") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells?.map((headCell) => (
          <CustomizedTableCell
            sx={{ fontWeight: "700" }}
            key={headCell?.id}
            align={headCell?.alignment}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

const AccountsManagement = () => {
  const initialState = {
    countriesObjects: [],
    ownersObjects: [],
    countries: [],
    companies: [],
    locations: [],
    industries: [],
    owners: [],
    reviews: null,
    post: null,
    percentages: [],
    deal: {
      max: 0,
      min: 0,
    },
  };

  const { t } = useTranslation();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const [anchorBulk, setAnchorBulk] = useState(null);
  const [isSorted, setIsSorted] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCSVReport, setIsLoadingCDVReport] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [panels, setPanels] = useState([]);
  const [section, setSection] = useState("locations");

  const [allAccounts, setAllAccounts] = useState([]);
  const [allLocation, setAllLocation] = useState(null);
  const [allIndustry, setAllIndustry] = useState([]);
  const [allOwners, setAllOwners] = useState([]);

  const [filterObj, setFilterObj] = useState({});
  const [filters, setFilters] = useState(initialState);
  const [filtersSelectAll, setFiltersSelectAll] = useState({
    countries: false,
    owners: false,
  });

  const getAccountListing = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/admin/accounts?`, {
        params: {
          search: search,
          pageNumber: page + 1,
          limit: rowsPerPage,
          ...filterObj,
        },
      });
      if (res.status === 200) {
        if (res.data.data?.rows?.length > 0) {
          setAllAccounts(res.data.data);
        } else {
          setAllAccounts(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllAccounts(null);
    }
  };

  const getAccountListingExcel = async () => {
    try {
      setIsLoadingCDVReport(true);
      const res = await api.get(`admin/accountsExport?`, {
        params: {
          search: search,
          pageNumber: page + 1,
          limit: rowsPerPage,
          ...filterObj,
        },
      });
      if (res.status === 200) {
        window.location = res.data.data.report;
        toast.success(t("ReportDownloadSuccessfully"));
        setIsLoadingCDVReport(false);
      }
    } catch (error) {
      setIsLoadingCDVReport(false);
    }
  };

  const getIndustryListing = async () => {
    try {
      const res = await api.get(`admin/locationIndustries`);
      if (res.status === 200) {
        setAllIndustry(res.data.data);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  const getAccountOwnerListing = async () => {
    try {
      const res = await api.get(`admin/locationOwners`);
      if (res.status === 200) {
        setAllOwners(res.data.data);
      }
    } catch (error) {
      //  setIsLoading(false);
    }
  };

  const getAllLocations = async () => {
    try {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    getAccountListing();
  }, [filterObj, page, rowsPerPage]);

  useEffect(() => {
    getAllLocations();
    getIndustryListing();
    getAccountOwnerListing();
  }, []);

  useEffect(() => {
    sorted();
  }, [isSorted]);

  const getCountryWiseLocations = (locationsArray) => {
    return countriesData
      ?.map((country) => {
        const locations = locationsArray
          ?.filter(
            (location) =>
              location.countryCode === country.code ||
              location.country === country.name
          )
          ?.map((location) => location);
        const companies = locations?.map((location) => location.company);
        return {
          countryName: country.name,
          countryCode: country.code,
          locationsArray: locations,
          companiesArray: companies,
        };
      })
      ?.filter((country) => country.locationsArray.length > 0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorBulk(null);
  };

  const handleClick = (event, selectedData) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(selectedData);
  };

  const getCountryName = (code, name) => {
    if (code !== null && code !== "") {
      return countriesData.find((item) => item?.code === code)?.name;
    } else {
      if (name !== null && name !== "") {
        return name;
      } else {
        return "-";
      }
    }
  };

  const searchFilter = (e) => {
    setSearch(e.target.value);
  };

  const resetHandler = () => {
    setFilters(initialState);
    setFiltersSelectAll({
      countries: false,
      owners: false,
    });
    setSearch("");
    setFilterObj({});
    setIsSorted("");
    setPage(0);
  };

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleOpenEditUser = (panel, section) => {
    setPanels(panel);
    setSection(section);
    setIsMoreModalOpen(true);
  };

  const handleSubmit = (obj) => {
    const data = { ...obj };
    const object = { ...filterObj };

    const listing = data.percentages?.map(({ name, ...rest }) => rest);

    const array = getCountryWiseLocations(allLocation?.results);
    const filteredCountries = array?.filter((x) =>
      data?.countries.includes(x.countryName)
    );
    const updatedArray = filteredCountries?.map((x) => {
      return {
        country: x.countryName,
        locations: x.locationsArray?.map((e) => e.id),
        companies: x.companiesArray?.map((e) => e.id),
      };
    });

    const filteredData = updatedArray?.filter((item) => {
      return item?.locations.every((location) =>
        data?.locations.includes(location)
      );
    });

    const locationsArray = filteredData?.map((item) => item?.locations).flat();
    const companiesArray = filteredData?.map((item) => item?.companies).flat();

    if (data?.countries?.length > 0) {
      data.companies = data?.companies?.filter(
        (element) => !companiesArray.includes(element)
      );
      data.locations = data?.locations?.filter(
        (element) => !locationsArray.includes(element)
      );
    }

    if (data?.countries?.length > 0) {
      const country = data?.countries?.map((name) => {
        return countriesData.find((item) => item?.name === name).code;
      });
      object.filterByCountry = country.join(",");
    } else {
      delete object.filterByCountry;
    }

    if (data?.locations?.length > 0) {
      object.filterByLocation = (data?.locations).join(",");
    } else {
      delete object.filterByLocation;
    }

    if (data?.companies?.length > 0) {
      object.filterByCompany = (data?.companies).join(",");
    } else {
      delete object.filterByCompany;
    }

    if (data?.industries?.length > 0) {
      object.filterByIndustry = (data?.industries).join(",");
    } else {
      delete object.filterByIndustry;
    }

    if (data?.owners?.length > 0) {
      object.accountOwnerName = (data?.owners).join(",");
    } else {
      delete object.accountOwnerName;
    }

    if (data?.reviews !== null) {
      object.reviews = data?.reviews;
    } else {
      delete object.reviews;
    }

    if (data?.percentages !== null && data?.percentages.length > 0) {
      object.profileCompletion = JSON.stringify(listing);
    } else {
      delete object.profileCompletion;
    }

    if (data?.post !== null) {
      object.googlePost = data?.post;
    } else {
      delete object.googlePost;
    }

    if (data?.deal.max === 0 && data?.deal.min === 0) {
      delete object.dealValueFrom;
      delete object.dealValueTo;
    } else {
      object.dealValueFrom = data?.deal.min;
      object.dealValueTo = data?.deal.max;
    }

    setPage(0);
    setFilters(obj);
    setFilterObj(object);
    setIsMoreModalOpen(false);
    setSection("locations");
    setPanels([]);
  };

  const sorted = () => {
    if (isSorted !== "") {
      const object = { ...filterObj };
      object.sortBy = "dealValue";
      object.sort = isSorted;
      setFilterObj(object);
    }
  };

  const splitAccountOwnerNames = (name) => {
    const names = name?.map((item) => item.accountOwnerName.toLowerCase());
    const nameList = Array.from(new Set(names?.join(",").split(",")));
    return nameList.map((item) => {
      return { accountOwnerName: item };
    });
  };

  return (
    <>
      <Box>
        {isLoadingCSVReport && (
          <>
            <Typography>{t("download_progress")}</Typography>
            <LinearBuffer />
          </>
        )}
      </Box>
      <TitleHeader
        showButton={false}
        title={t("account_settings")}
        reportsMenu={true}
        name={t("Export_CSV")}
        handleDownload={getAccountListingExcel}
      />
      <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
        <Grid item xs={12} sm={5} md={4} lg={4}>
          <SearchField
            filterByName={searchFilter}
            onSubmit={() => {}}
            placeholder={t("search_company_location_text")}
            filterWithName={search}
          />
        </Grid>
        <Grid item container spacing={2} xs={12} sm={6} md={4} lg={4}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              id="combo-box-demo"
              value={filters.ownersObjects}
              options={
                allOwners !== null && allOwners?.length > 0
                  ? splitAccountOwnerNames(allOwners)
                  : []
              }
              getOptionLabel={(option) => t(option?.accountOwnerName)}
              sx={{ bgcolor: "white" }}
              onChange={(event, value) => {
                if (
                  value.find(
                    (option) => option.accountOwnerName === "Select_All"
                  )
                ) {
                  if (filtersSelectAll.owners) {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      owners: false,
                    }));
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      ownersObjects: [],
                      owners: [],
                    }));
                  } else {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      owners: true,
                    }));
                    let owners = splitAccountOwnerNames(allOwners)?.map(
                      (item) => item
                    );
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      ownersObjects: owners,
                      owners: owners?.map((item) => item?.accountOwnerName),
                    }));
                  }
                } else {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    ownersObjects: value,
                    owners: value?.map((item) => item?.accountOwnerName),
                  }));
                  if (
                    value.length === splitAccountOwnerNames(allOwners).length
                  ) {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      owners: true,
                    }));
                  } else {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      owners: false,
                    }));
                  }
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.accountOwnerName === value.accountOwnerName
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (splitAccountOwnerNames(allOwners)?.length > 0) {
                  return [{ accountOwnerName: "Select_All" }, ...filtered];
                } else {
                  return filtered;
                }
              }}
              renderOption={(props, option) => {
                const isSelected = filters.ownersObjects.some(
                  (sel) => sel.accountOwnerName === option.accountOwnerName
                );
                return (
                  <li
                    style={{
                      textTransform: "capitalize",
                    }}
                    {...props}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={filtersSelectAll.owners || isSelected}
                    />
                    {t(option?.accountOwnerName)}
                  </li>
                );
              }}
              renderTags={(value, getTagProps) => {
                const MAX_DISPLAY_ITEMS = 2;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    <Stack
                      direction="column"
                      spacing={1}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flexWrap: "nowrap",
                      }}
                    >
                      {value
                        .slice(0, MAX_DISPLAY_ITEMS)
                        ?.map((option, index) => (
                          <Chip
                            style={{
                              background: "#FFF",
                              border: "1px solid #06BDFF",
                              borderRadius: "800px",
                              textTransform: "capitalize",
                            }}
                            key={index}
                            label={t(option?.accountOwnerName)}
                            {...getTagProps({ index })}
                          />
                        ))}
                      {value.length > MAX_DISPLAY_ITEMS && (
                        <Chip
                          style={{
                            background: "#FFF",
                            border: "1px solid #BBBDC1",
                            borderRadius: "800px",
                            cursor: "pointer",
                            fontWeight: 700,
                            width: "100px",
                          }}
                          onClick={() =>
                            handleOpenEditUser(["owners"], "others")
                          }
                          label={`+${value.length - MAX_DISPLAY_ITEMS} more`}
                        />
                      )}
                    </Stack>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("search_owners")}
                  {...params}
                  required
                  name="language"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Autocomplete
              multiple
              disablePortal
              disableCloseOnSelect
              id="combo-box-demo"
              value={filters.countriesObjects}
              options={
                allLocation !== null && allLocation?.results?.length > 0
                  ? getCountryWiseLocations(allLocation?.results)
                  : []
              }
              getOptionLabel={(option) => t(option?.countryName)}
              sx={{ bgcolor: "white" }}
              onChange={(event, value) => {
                if (
                  value.find((option) => option.countryName === "Select_All")
                ) {
                  if (filtersSelectAll.countries) {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      countries: false,
                    }));
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      countries: [],
                      countriesObjects: [],
                      companies: [],
                      locations: [],
                    }));
                  } else {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      countries: true,
                    }));
                    let countries = getCountryWiseLocations(
                      allLocation?.results
                    )?.map((item) => item);
                    const companies = countries
                      ?.map((item) =>
                        item?.companiesArray?.map((item) => item?.id)
                      )
                      .flat();
                    const locations = countries
                      ?.map((item) =>
                        item?.locationsArray?.map((item) => item?.id)
                      )
                      .flat();
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      countriesObjects: countries,
                      countries: countries?.map((item) => item?.countryName),
                      companies: Array.from(new Set(companies)),
                      locations: Array.from(new Set(locations)),
                    }));
                  }
                } else {
                  const companies = value
                    ?.map((item) =>
                      item?.companiesArray?.map((item) => item?.id)
                    )
                    .flat();
                  const locations = value
                    ?.map((item) =>
                      item?.locationsArray?.map((item) => item?.id)
                    )
                    .flat();
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    countriesObjects: value?.map((item) => item),
                    countries: value?.map((item) => item?.countryName),
                    companies: Array.from(new Set(companies)),
                    locations: Array.from(new Set(locations)),
                  }));
                  if (
                    value.length ===
                    getCountryWiseLocations(allLocation?.results).length
                  ) {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      countries: true,
                    }));
                  } else {
                    setFiltersSelectAll((prevFilters) => ({
                      ...prevFilters,
                      countries: false,
                    }));
                  }
                }
              }}
              isOptionEqualToValue={(option, value) =>
                option.countryName === value.countryName
              }
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return [{ countryName: "Select_All" }, ...filtered];
              }}
              renderOption={(props, option) => {
                const isSelected = filters.countriesObjects.some(
                  (sel) => sel.countryName === option.countryName
                );
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={filtersSelectAll.countries || isSelected}
                    />
                    {t(option?.countryName)}
                  </li>
                );
              }}
              renderTags={(value, getTagProps) => {
                const MAX_DISPLAY_ITEMS = 2;
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  >
                    <Stack
                      direction="column"
                      spacing={1}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flexWrap: "nowrap",
                      }}
                    >
                      {value
                        .slice(0, MAX_DISPLAY_ITEMS)
                        ?.map((option, index) => (
                          <Chip
                            style={{
                              background: "#FFF",
                              border: "1px solid #06BDFF",
                              borderRadius: "800px",
                            }}
                            key={index}
                            label={t(option?.countryName)}
                            {...getTagProps({ index })}
                          />
                        ))}
                      {value.length > MAX_DISPLAY_ITEMS && (
                        <Chip
                          style={{
                            background: "#FFF",
                            border: "1px solid #BBBDC1",
                            borderRadius: "800px",
                            cursor: "pointer",
                            fontWeight: 700,
                            width: "100px",
                          }}
                          onClick={() => {
                            const countries = value?.map(
                              (item) => item?.countryName
                            );
                            const array = [
                              "countries",
                              "company",
                              ...countries,
                            ];
                            handleOpenEditUser(array, "locations");
                          }}
                          label={`+${value.length - MAX_DISPLAY_ITEMS} more`}
                        />
                      )}
                    </Stack>
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("search_company")}
                  {...params}
                  required
                  name="language"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item spacing={2} container xs={12} sm={6} md={4} lg={4}>
          <Grid item xs={12} sm={4.2} md={4.2} lg={4.2}>
            <CommonButton
              alignment={true}
              onSubmit={() => handleSubmit(filters)}
              label={t("Apply_filters")}
              leftMargin={true}
              disabled={isLoading && allLocation === null}
            />
          </Grid>
          <Grid item xs={12} sm={3.3} md={3.3} lg={3.3}>
            <CommonButton
              leftMargin={true}
              alignment={true}
              displayBlack="true"
              icon={<RefreshIcon />}
              label={t("Reset")}
              onSubmit={resetHandler}
              disabled={isLoading && allLocation === null}
            />
          </Grid>
          <Grid item xs={12} sm={4.5} md={4.5} lg={4.5}>
            <Button
              onClick={() => {
                setIsMoreModalOpen(true);
              }}
              disabled={isLoading && allLocation === null}
              sx={{
                height: "52px",
                width: "100%",
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "14px",
                }}
              >
                {t("View_More_Filters")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      {!isLoading && allLocation !== null && (
        <Grid container sx={{ marginY: "1rem" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <CommonButton
              alignment="220px"
              type="submit"
              isLoading={false}
              disabled={false}
              onSubmit={handleClickMenu}
              label={
                isSorted === "ASC"
                  ? `${t("Deal_Value")}: ${t("LTOH")}`
                  : isSorted === "DESC"
                  ? `${t("Deal_Value")}: ${t("HTOL")}`
                  : t("Sort_By")
              }
              icon={<KeyboardArrowDownIcon />}
              leftMargin={true}
              customHeight={true}
              displayWhite={"true"}
              iconLocation={true}
              aria-owns={anchorBulk ? "simple-menu" : undefined}
              aria-haspopup="true"
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorBulk}
              open={Boolean(anchorBulk)}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  background: "#EBEBEC",
                },
              }}
            >
              <MenuItem
                sx={{
                  color: "#1B2430",
                  fontSize: "14px",
                  lineHeight: "20px",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#DBDBDC",
                  },
                }}
                onClick={(e) => {
                  setIsSorted("ASC");
                  handleClose();
                }}
              >
                {`${t("Deal_Value")}: ${t("LTOH")}`}
              </MenuItem>
              <Divider />
              <MenuItem
                sx={{
                  color: "#1B2430",
                  fontSize: "14px",
                  lineHeight: "20px",
                  cursor: "pointer",
                  "&:hover": {
                    background: "#DBDBDC",
                  },
                }}
                onClick={(e) => {
                  setIsSorted("DESC");
                  handleClose();
                }}
              >
                {`${t("Deal_Value")}: ${t("HTOL")}`}
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allAccounts?.rows?.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <EnhancedTableHead t={t} />
                  <TableBody>
                    {allAccounts?.rows &&
                      allAccounts?.rows?.length > 0 &&
                      allAccounts?.rows?.map((row, index) => {
                        return (
                          <CustomizedTableRow
                            tabIndex={-1}
                            key={`${row.id}-${index}`}
                            sx={{ paddingY: "8px" }}
                          >
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.id}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              <BootstrapTooltip
                                title={
                                  <Typography>
                                    ID:{" "}
                                    {row?.company === null
                                      ? "-"
                                      : row?.company?.id}
                                    <br />
                                    Name:{" "}
                                    {row?.company === null
                                      ? "-"
                                      : row?.company?.name}
                                  </Typography>
                                }
                              >
                                <Typography
                                  noWrap
                                  sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "6rem",
                                  }}
                                >
                                  {row?.company === null
                                    ? "-"
                                    : row?.company?.name}
                                </Typography>
                              </BootstrapTooltip>
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              <BootstrapTooltip
                                title={
                                  row?.addressLine1 === null
                                    ? "-"
                                    : `${row?.name} (${row?.addressLine1})`
                                }
                              >
                                <Typography
                                  noWrap
                                  sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "6rem",
                                  }}
                                >
                                  {row?.name}
                                </Typography>
                              </BootstrapTooltip>
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.subscriptionDate === null
                                ? "--"
                                : moment(row?.subscriptionDate).format(
                                    "DD/MM/YYYY"
                                  )}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {getCountryName(row?.countryCode, row?.country)}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.industry === null ? "-" : row?.industry}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.profileCompletition === null
                                ? "0%"
                                : `${row?.profileCompletition}%`}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.ruleLocation !== 0 ? "Yes" : "No"}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.postLocations !== 0 ? "Yes" : "No"}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.dealLink !== null ? (
                                <a
                                  href={`${
                                    row?.dealLink?.startsWith("http")
                                      ? row.dealLink
                                      : `https://${row?.dealLink}`
                                  }`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {row?.dealValue === null
                                    ? "0"
                                    : `€${row?.dealValue}`}
                                </a>
                              ) : (
                                <Typography>
                                  {row?.dealValue === null
                                    ? "0"
                                    : `€${row?.dealValue}`}
                                </Typography>
                              )}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{
                                cursor: "pointer",
                                textTransform: "capitalize",
                              }}
                            >
                              <BootstrapTooltip
                                title={
                                  <Typography
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {row?.accountOwnerName === null
                                      ? "-"
                                      : row?.accountOwnerName.replaceAll(
                                          ",",
                                          ", "
                                        )}
                                  </Typography>
                                }
                              >
                                <Typography
                                  noWrap
                                  sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    width: "6rem",
                                  }}
                                >
                                  {row?.accountOwnerName === null
                                    ? "-"
                                    : row?.accountOwnerName.replaceAll(
                                        ",",
                                        ", "
                                      )}
                                </Typography>
                              </BootstrapTooltip>
                            </CustomizedTableCell>
                            <CustomizedTableCell padding="none" align="left">
                              <Box mr={2}>
                                <IconButton
                                  aria-label="edit"
                                  size="large"
                                  onClick={(e) => handleClick(e, row)}
                                >
                                  <MoreHoriz sx={{ color: "#757575" }} />
                                </IconButton>

                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setIsModalOpen(true);
                                      handleClose();
                                    }}
                                  >
                                    <Typography>{t("Edit")}</Typography>
                                  </MenuItem>
                                  {/* <Divider variant="middle" />
                                  {selectedRow?.dealLink === null ? (
                                    <BootstrapTooltip title={t("NoDealLink")}>
                                      <span>
                                        <MenuItem disabled>
                                          <Typography>
                                            {t("Deal_Link")}
                                          </Typography>
                                        </MenuItem>
                                      </span>
                                    </BootstrapTooltip>
                                  ) : (
                                    <MenuItem
                                      onClick={() => {
                                        window.open(
                                          selectedRow?.dealLink,
                                          "_blank"
                                        );
                                        handleClose();
                                      }}
                                    >
                                      <Typography>{t("Deal_Link")}</Typography>
                                    </MenuItem>
                                  )} */}
                                  <Divider variant="middle" />
                                  {selectedRow?.comments === null ? (
                                    <BootstrapTooltip title={t("NoComment")}>
                                      <span>
                                        <MenuItem disabled>
                                          <Typography>
                                            {t("Comment")}
                                          </Typography>
                                        </MenuItem>
                                      </span>
                                    </BootstrapTooltip>
                                  ) : (
                                    <MenuItem
                                      onClick={() => {
                                        setIsCommentModalOpen(true);
                                        handleClose();
                                      }}
                                    >
                                      <Typography>{t("Comment")}</Typography>
                                    </MenuItem>
                                  )}
                                </Menu>
                              </Box>
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allAccounts?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            allAccounts === null && (
              <NotFound text={t("No_Account_Found_Text")} />
            )
          )}
        </>
      )}
      {isModalOpen && (
        <AccountManagementModal
          open={isModalOpen}
          close={() => setIsModalOpen(false)}
          data={selectedRow}
          getAccountListing={getAccountListing}
        />
      )}
      {isCommentModalOpen && (
        <CommentModal
          open={isCommentModalOpen}
          close={() => setIsCommentModalOpen(false)}
          text={selectedRow?.comments}
        />
      )}
      {isMoreModalOpen && (
        <ViewMoreFiltersModal
          open={isMoreModalOpen}
          close={() => {
            setIsMoreModalOpen(false);
            setSection("locations");
            setPanels([]);
          }}
          locations={allLocation}
          industries={allIndustry}
          owners={allOwners}
          handleSubmit={(data) => handleSubmit(data)}
          modalFilters={filters}
          setModalFilters={setFilters}
          initialState={initialState}
          sections={section}
          panels={panels}
          setFiltersSelectAll={setFiltersSelectAll}
        />
      )}
    </>
  );
};

export default AccountsManagement;
