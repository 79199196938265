import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Modal,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import { useNavigationType, useParams } from "react-router-dom";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";

import { useNavigate } from "react-router-dom";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import TitleHeader from "../../../../components/CustomComponents/title";
import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../components/NotFound/NotFound";
import styled from "styled-components/macro";
import LinkUserModel from "./LinkUserModel";

import AddCircleIcon from "@mui/icons-material/AddCircle";
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  Menu: {
    "&:hover": {
      backgroundColor: "gray !important",
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  primaryChip: {
    background: "#13CF8F",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    width: "80px",
    marginLeft: "-5px",
  },
  primaryChipTypo: {
    fontSize: "10px",
    fontWeight: "400",
    marginY: "auto",
    display: "flex",
    color: "#FFF",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#13CF8F",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Languages = [
  "English",
  "Dutch",
  "French",
  "German",
  "Spanish",
  "Italian",
  "Portugese",
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "100vh",
  width: "420px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  justifyContent: "space-around",
  // overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Users = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const { signOut } = useAuth();

  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  //const [allLocations, setAllLocations] = useState([]);
  //const [order, setOrder] = React.useState("asc");
  //const [orderBy, setOrderBy] = React.useState("firstName");
  const navigate = useNavigate();
  const [filterWithName, setFilterWithName] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  //const [deleteId, setDeleteId] = useState(null);
  //const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  //const [openResetModel, setOpenResetModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalEditUser, setOpenModalEditUser] = React.useState(false);
  const [openModalAddUser, setOpenModalAddUser] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const [fieldValue, setFieldValue] = useState(null);
  const [roleRequired, setRoleRequired] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [allRoles, setAllRoles] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);

  const Roles = [
    {
      name: t("Account_Manager"),
      value: "account-manager",
    },
    {
      name: t("Location_Manager"),
      value: "location-manager",
    },
  ];

  const headCells = [
    { id: "id", alignment: "left", label: t("AccountTableID") },
    { id: "firstName", alignment: "left", label: t("FirstName") },
    { id: "lastName", alignment: "left", label: t("LastName") },
    { id: "Contact", alignment: "left", label: t("UserTableEmail") },
    { id: "Telephone number", alignment: "left", label: t("UserTableRole") },
    { id: "actions", alignment: "center", label: t("Action") },
  ];
  useEffect(() => {
    getAllUsers();
  }, [page, rowsPerPage]);

  useEffect(() => {
    getAllRoles();
  }, []);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const getAllRoles = async () => {
    //  setIsLoading(true);
    try {
      const res = await api.get(
        `companyRoles?companyId=${id}&pageNumber=1&limit=1000`
      );
      if (res.status === 200) {
        setAllRoles(res?.data?.data);
        //     setIsLoading(false);
      }
    } catch (error) {
      //   setIsLoading(false);
      setAllRoles(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, id, selectedData) => {
    setSelectedRow(selectedData);
    setUpdateId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModalEditUser = () => {
    setOpenModalEditUser(false);
    getAllUsers();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      // setLoading(true);
      const res = await api.get(
        `/admin/users/wholeApplication?page=${
          page + 1
        }&limit=${rowsPerPage}&searchTerm=${filterWithName}&status=active`
      );
      if (res.status === 200) {
        // setAllCompanies(res.data.data);
        setUsers(res.data.data);

        // setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const handleSubmit = async () => {
    setLinkLoading(true);
    let locationIds = [];
    if (selectedLocations?.length > 0) {
      selectedLocations?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    try {
      const res = await api.post(`/companyUser`, {
        companyId: id,
        companyRoleId: fieldValue?.id,
        userId: selectedRow?.id,
        locationIds: locationIds,
        allLocationAccess: selectAllLocations,
      });
      if (res.status === 200) {
        setLinkLoading(false);
        handleCloseModal();
        toast.success(t("Linked_successfully"));
        navigate(`/admin/accounts/${id}`);
        // getAllUsers();
      }
    } catch (error) {
      setLinkLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseModal();
    }
  };

  const handleOpenLinkPage = () => {
    // navigate(`/admin/accounts/link-user/${id}`);
  };

  const handleOpenModel = () => {
    setOpenModalAddUser(true);
  };
  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModalAddUser(false);
    getAllUsers();
  };

  return (
    <>
      {openModalAddUser && (
        <LinkUserModel
          title={t("Link_user")}
          subTitle={t("fetch_emails_subheading")}
          open={openModalAddUser}
          handleCloseModal={handleCloseModal}
          companyId={id}
          allRoles={allRoles}
          setFieldValue={setFieldValue}
          setRoleRequired={setRoleRequired}
          handleSubmit={handleSubmit}
          setSelectedLocations={setSelectedLocations}
          selectedLocations={selectedLocations}
          fieldValue={fieldValue}
          linkLoading={linkLoading}
          selectAllLocations={selectAllLocations}
          setSelectAllLocations={setSelectAllLocations}
        />
      )}
      <Box sx={{ marginTop: "1rem" }}>
        <TitleHeader title={t("Link_users")} subHeading={t("AllUser")} />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={5.5} sm={6} md={10} lg={10}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllUsers}
            placeholder={t("FilterUser")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={6.5}
          sm={6}
          md={2}
          lg={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            alignment={true}
            onSubmit={getAllUsers}
            label={t("Search")}
          />

          {/*   <CommonButton
            alignment={true}
            onSubmit={handleOpen}
            label={t("AddNewUser")}
          />
          <CommonButton
            alignment={true}
            onSubmit={handleOpenLinkPage}
            label={t("Link_user")}
          /> */}
        </Grid>
        {/* <Grid item xs={2} sm={1} md={1}> */}
      </Grid>
      {/* </Grid> */}
      {/* </Paper> */}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {users?.results?.length > 0 ? (
            <>
              {" "}
              <TableContainer sx={{ marginTop: "2rem" }} component={Paper}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      {/*    <CustomizedTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                            indeterminate={
                          numSelected > 0 && numSelected < rowCount
                        }
                          checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      </CustomizedTableCell> */}

                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {users?.results?.length > 0 &&
                      users?.results?.map((row, index) => {
                        /*    const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`; */
                        return (
                          <CustomizedTableRow
                            // hover
                            tabIndex={-1}
                            key={`${row.id}-${index}`}
                          >
                            {/* <TableCell padding="checkbox" align="center">
                              <Checkbox
                                color="primary"
                                //  checked={isItemSelected}
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                              />
                            </TableCell> */}
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row.id}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ cursor: "pointer" }}
                              align="left"
                            >
                              {row.firstName !== null ? row.firstName : "--"}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ cursor: "pointer" }}
                              align="left"
                            >
                              {row.lastName !== null ? row.lastName : "--"}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="left">
                              {row.email}
                            </CustomizedTableCell>

                            <CustomizedTableCell align="left" width="200px">
                              {row?.CompanyUserRoles?.length > 0 &&
                              row?.CompanyUserRoles[0]?.isPrimary ? (
                                <Item className={classes.primaryChip}>
                                  <Typography
                                    className={classes.primaryChipTypo}
                                  >
                                    {t("Primary_text")}
                                  </Typography>
                                </Item>
                              ) : row?.CompanyUserRoles?.length > 0 ? (
                                (
                                  row?.CompanyUserRoles[0]?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                  row?.CompanyUserRoles[0]?.name.slice(1)
                                ).replaceAll("-", " ")
                              ) : (
                                "--"
                              )}
                            </CustomizedTableCell>
                            <CustomizedTableCell padding="none" align="center">
                              <BootstrapTooltip
                                title={
                                  parseInt(id) === row?.companyId
                                    ? t("default_user")
                                    : t("Link_User")
                                }
                              >
                                <span>
                                  <IconButton
                                    aria-label="edit"
                                    size="large"
                                    disabled={parseInt(id) === row?.companyId}
                                    onClick={() => {
                                      handleOpenModel();
                                      setSelectedRow(row);
                                    }}
                                  >
                                    <AddCircleIcon />
                                  </IconButton>
                                </span>
                              </BootstrapTooltip>

                              {/*   <IconButton onClick={(e) => handleClick(e, row.id, row)}
                    >
                      <MoreVertIcon />
                    </IconButton> */}

                              {/*    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleOpenDeleteModel(true);
                          closeMenu();
                          handleClose();
                          setDeleteId(selectedRow);
                        }}
                      >
                        <DeleteIcon
                          sx={{
                            color: "#545353",
                            paddingRight: "5px",
                          }}
                        />
                        <Typography>{t("Delete")}</Typography>
                      </MenuItem>
                      <Divider variant="middle" />
                      <MenuItem
                        onClick={() => {

                          closeMenu();
                          handleClose();
                          setOpenPrimaryModel(true);
                         updateUserHandler(selectedRow.id);
                          // handleOpenModal(row?.id);
                        }}
                      >
                        <PersonIcon
                          sx={{
                            color: "#545353",
                            paddingRight: "5px",
                          }}
                        />
                        <Typography>{t("Make_Primary_User")}</Typography>
                      </MenuItem>
                      <Divider variant="middle" />
                      <MenuItem
                        onClick={() => {

                          closeMenu();
                          handleClose();
                          setOpenResetModel(true);
                        }}
                      >
                        <PasswordIcon
                          sx={{
                            color: "#545353",
                            paddingRight: "5px",
                          }}
                        />
                        <Typography
                          loading={loading}
                          type="submit"
                          fullWidth
                          variant="contained"

                          // disabled={isSubmitting}
                        >
                          {t("Reset_password")}
                        </Typography>
                      </MenuItem>
                    </Menu> */}
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound text={t("User_Not_Found")} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Users;
