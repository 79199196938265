import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import Delete from "../../../../components/Models/DeleteModal/Delete";

import { useLocation } from "react-router-dom";

import {
  Box,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Checkbox,
  Dialog,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import { LoadingButton } from "@mui/lab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";

import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";

import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../../../../styles/style";
const Paper = styled(MuiPaper)(spacing);

// const CustomWidthTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "grey",
//   },
// });

function MyLocations() {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const [allLocations, setAllLocations] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(async () => {
    await getAllLocation();
  }, []);
  /*  useEffect(async () => {
    await getAllLocation();
  }, [rowsPerPage, page]); */

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const getAllLocation = async () => {
    setDisplayError(false);
    setIsLoading(true);
    const paramsWithReconnect = {
      refreshToken: location?.state?.refreshToken,
      account: location?.state?.accountId,
      state: "reconnect",
    };
    const paramsWithoutReconnect = {
      refreshToken: location?.state?.refreshToken,
      account: location?.state?.accountId,
    };
    try {
      const res = await api.get(`/googleConnect/auth-location`, {
        params:
          location?.state?.state !== null
            ? paramsWithReconnect
            : paramsWithoutReconnect,
      });
      if (res.status === 200) {
        setAllLocations(res.data.data.locations);
        setIsLoading(false);
        setFilterWithName("");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };
  const handleSelectSingleLocation = (e, index, location) => {
    let tempObj = {
      referenceId: "",
      placeId: "",
      phoneNumbers: "",
      storefrontAddress: "",
      latlng: "",
      websiteUri: "",
      name: "",
    };
    const str = location?.name.split("/");
    if (e.target.checked) {
      tempObj = {
        referenceId: str[1],
        placeId: location?.metadata?.placeId,
        phoneNumbers: location.phoneNumbers,
        storefrontAddress: location.storefrontAddress,
        latlng: location.latlng,
        websiteUri: location.websiteUri,
        name: location?.title,
      };
      selectedLocations?.push(tempObj);
    } else {
      /*   var locIndex = selectedLocations?.indexOf(
        (item) => item.referenceId === str[1]
      ); */
      const locIndex = selectedLocations.findIndex((object) => {
        return object.referenceId === str[1];
      });
      selectedLocations.splice(locIndex, 1);
    }
    setSelectedLocations([...selectedLocations]);
  };
  const handleSelectAllLocations = (e) => {
    setSelectAll(e.target.checked);
    let tempObj = {
      referenceId: "",
      placeId: "",
      phoneNumbers: "",
      storefrontAddress: "",
      latlng: "",
      websiteUri: "",
      name: "",
    };
    if (e.target.checked) {
      const enabledLocations = allLocations?.filter(
        (item) => item?.disable === false
      );
      for (var i = 0; i < enabledLocations.length; i++) {
        const str = enabledLocations[i].name.split("/");
        tempObj = {
          referenceId: str[1],
          placeId: enabledLocations[i]?.metadata?.placeId,
          phoneNumbers: enabledLocations[i]?.phoneNumbers,
          storefrontAddress: enabledLocations[i]?.storefrontAddress,
          latlng: enabledLocations[i]?.latlng,
          websiteUri: enabledLocations[i]?.websiteUri,
          name: enabledLocations[i]?.title,
        };
        selectedLocations.push(tempObj);
      }
      const ids = selectedLocations.map((o) => o.referenceId);
      const filtered = selectedLocations.filter(
        ({ referenceId }, index) => !ids.includes(referenceId, index + 1)
      );
      //   const uniquePosts = new Set(selectedLocations);
      setSelectedLocations([...filtered]);
    } else {
      setSelectedLocations([]);
    }
  };

  const createSocialLink = async (data) => {
    try {
      setLoading(true);
      const res = await api.post(`/googleConnect/create/socialLinks`, {
        account: location?.state?.accountId,
        refreshToken: location?.state?.refreshToken,
        locations: selectedLocations,
        email: location?.state?.email,
      });

      if (res?.data?.status === true) {
        setLoading(false);
        setOpen(false);
        toast.success(res?.data?.message);
        navigate("/user/locations");
      }
    } catch (error) {
      setLoading(false);
      setOpen(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const getSearchedLocation = () => {
    if (filterWithName.length > 0) {
      setIsLoading(true);
      const data = allLocations.filter((item) =>
        item?.title.toLowerCase().includes(filterWithName)
      );
      if (data?.length > 0) {
        setDisplayError(false);
        setAllLocations(data);
      } else {
        setAllLocations(data);
        setDisplayError(true);
      }

      setIsLoading(false);
    } else {
      getAllLocation();
    }
  };
  const handleBackButton = () => {
    navigate("/user/google_accounts", {
      state: {
        refreshToken: location?.state?.refreshToken,
      },
    });
  };

  const handleOpenModel = () => {
    setOpen(true);
  };
  const handleCloseModel = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Connect_With_Google_Text")}
          description={t("google_connect")}
          onConfirm={() => createSocialLink()}
          onCancel={handleCloseModel}
        />
      </Dialog>
      <Helmet title="Locations" />
      <TitleHeader
        title={`${t("Location_of")} ${location?.state.accountName}`}
        subHeading={""}
        googleButton={false}
        showBackButton={true}
        handleBackButton={handleBackButton}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={10} md={10}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getSearchedLocation}
                placeholder={t("Filter_locations")}
                filterWithName={filterWithName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={2}
              md={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CommonButton
                isLoading={isLoading}
                onSubmit={getSearchedLocation}
                label={t("Search")}
                alignment={true}
              />
            </Grid>
          </Grid>

          {allLocations.length > 0 ? (
            <Grid container marginTop="1rem" spacing={6}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <CustomizedTableHead>
                      <CustomizedTableRow>
                        <CustomizedTableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            // indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={selectAll}
                            onChange={(e) => handleSelectAllLocations(e)}
                            inputProps={{
                              "aria-label": "select all desserts",
                            }}
                          />
                        </CustomizedTableCell>

                        <CustomizedTableCell>
                          {t("LocationTableName")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Phone_Number")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Address")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Account")}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    </CustomizedTableHead>
                    <TableBody>
                      {allLocations?.length > 0 ? (
                        allLocations?.map((row, index) => {
                          const str = row?.name?.split("/");
                          const ind = selectedLocations.findIndex(
                            (item) => item.referenceId === str[1]
                          );
                          return (
                            <CustomizedTableRow
                              // hover
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                            >
                              <CustomizedTableCell
                                padding="checkbox"
                                align="left"
                              >
                                <Checkbox
                                  color="primary"
                                  disabled={row.disable}
                                  checked={
                                    selectedLocations[ind]?.referenceId ===
                                    str[1]
                                  }
                                  onChange={(e) =>
                                    handleSelectSingleLocation(e, index, row)
                                  }
                                  inputProps={{
                                    "aria-label": "select all desserts",
                                  }}
                                />
                              </CustomizedTableCell>

                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.title}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.phoneNumbers?.primaryPhone}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.storefrontAddress?.addressLines?.length >
                                0
                                  ? row?.storefrontAddress?.addressLines[0] +
                                    (row?.storefrontAddress?.administrativeArea
                                      ? "," +
                                        row?.storefrontAddress
                                          ?.administrativeArea
                                      : "") +
                                    (row?.storefrontAddress?.locality !==
                                      null &&
                                    row?.storefrontAddress?.locality !==
                                      undefined
                                      ? "," + row?.storefrontAddress?.locality
                                      : "")
                                  : ""}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                {row?.companyName}
                              </CustomizedTableCell>

                              {/* <TableCell align="left">{getSubscription}</TableCell> */}
                            </CustomizedTableRow>
                          );
                        })
                      ) : (
                        <Typography variant="h3">{t("data_found")}</Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/*  <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allCompanies?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-start"
                my={3}
                className={classes.buttonContainerAccount}
              >
                <CommonButton
                  onSubmit={() => handleBackButton()}
                  // type="submit"
                  displayWhite="true"
                  variant="contained"
                  // disabled={isSubmitting}

                  label={t("Cancel")}
                />
                <LoadingButton
                  type="submit"
                  loading={loading}
                  variant="contained"
                  color="primary"
                  disabled={selectedLocations?.length === 0}
                  onClick={() => handleOpenModel()}
                  sx={{
                    height: "50px",
                    borderRadius: "8px",
                    marginLeft: "5px",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {t("Connect")}
                </LoadingButton>
              </Grid>
            </Grid>
          ) : (
            <Box className={classes.locationBoxAccount}>
              <Grid container sx={{ padding: "20px" }}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                  <Typography className={classes.errorHeadingAccount}>
                    {t("No_Listing_Found")}
                  </Typography>
                </Grid>
                {!displayError && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    sx={{ marginTop: "10px" }}
                  >
                    <Typography className={classes.errorHeadingTextAccount}>
                      {t("Group_not_connected")}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              {!displayError && (
                <CommonButton
                  onSubmit={() => navigate("/user/locations")}
                  // type="submit"
                  displayWhite="true"
                  variant="contained"
                  // disabled={isSubmitting}

                  label={t("Cancel")}
                />
              )}
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default MyLocations;
