import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";
import { borderRadius, padding } from "polished";
import { fontSize } from "@mui/system";
export const useStyles = makeStyles((theme) => ({
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    height: "70vh",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "24px",
  },
  socialBoxUsers: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    display: "flex",
    padding: "12px",
    marginY: "2px",
    alignItems: "center",
    width: "100%",
    height: "60px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",

    "&:hover": {
      backgroundColor: "#f5fcff",
    },
  },
  reviewsubHeadBox: {
    paddingY: "16px",
    backgroundColor: "#E0F7FF",
    borderRadius: "8px",
  },
  reviewsubHeadTextError: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
    color: "#FF0000",
  },
  reviewsubHeadText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    marginLeft: "6px",
  },
  mainContainerUser: {
    background: "#FFD0D0",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
    marginTop: "13px",
  },
  errorTextUser: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#330404",
    padding: "6px 0px",
  },
  errorIconUser: {
    color: "#FF1616",
    marginTop: "5px",
  },
  titleHeading: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  modelIcon: {
    marginTop: "4px",
    marginLeft: "8px",
    cursor: "pointer",
  },
  modelTypographySubtitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#495059",
  },
  modelContainer: {
    overflowY: "auto",
    marginLeft: "-11px",
  },
  boxStyle: {
    display: "flex",
    alignItems: "center",
  },
  boxButtonContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "10px",
  },
  cancelButton: {
    color: "#212529",
    background: "#f9f9f9 linear-gradient(180deg,#fafafa,#f9f9f9) repeat-x",
    border: "1px solid #E0E0E0",
    "&:hover": {
      border: "1px solid #E0E0E0",
    },
    marginLeft: "10px",
  },
  okButton: {
    marginLeft: "10px",
  },
  addUserButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "24px",
  },
  addUserBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  addUserFieldLable: {
    fontWeight: 600,
    fontSize: "1.125rem",
  },
  quotionIcon: {
    color: "#1B2430",
    marginLeft: "8px",
    marginTop: "2px",
  },
  removeFileButton: {
    cursor: "pointer",
    width: "9px",
    height: "9px",
    marginLeft: "8px",
    marginTop: "2px",
  },
  deleteIcon: {
    color: "#FF1616",
  },
  selectedFileTypo: {
    cursor: "pointer",
    "&:hover": {
      color: "#06BDFF",
    },
  },
  fileBoxStyle: {
    display: "flex",
    paddingTop: "8px",
  },
  removeEmailTypography: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#ef5350",
  },
  sendEmailTypo: {
    marginLeft: "6px",
    marginTop: "3px",
  },
  sendEmailBox: {
    color: "#495059",
    padding: "8px 0px 10px 0px",
    display: "flex",
  },
  sendEmailCheckBox: {
    padding: "0px",
  },
  addUserGrid: {
    padding: "1.5rem",

    background: "white",
  },
  collapsibleBox: {
    marginTop: "12px",
  },
  collapsiblePermissionCheckbox: {
    marginTop: "4px",
  },
  createRole: {
    color: "#06BDFF",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: "3px",
  },
  fetchModelTitle: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  fetchModelIcon: {
    marginTop: "4px",
    marginLeft: "8px",
    cursor: "pointer",
  },
  fetchModelTypography: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#495059",
  },
  fetchModelButtonContainer: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    padding: "10px",
  },
  copyText: {
    height: "52px",
    // padding: "14px 20px",
    fontSize: "14px",
    marginLeft: "10px",
    borderRadius: "8px",
    color: "#FFFFFF",

    width: "90%",
    border: "none",
  },
  copyTextTypo: {
    fontSize: "12px",
    color: "#495059",
    lineHeight: "15px",
    paddingTop: "10px",
    paddingLeft: "10px",
    fontWeight: 500,
    textAlign: "center",
  },
  zeroPadding: {
    paddingTop: "0px",
  },
  makePrimaryUserTextHead: {
    fontWeight: 700,
    fontSize: "40px",
    lineHeight: "60px",
    textAlign: "center",
    color: "#1B2430",
  },
  makePrimaryUserTex: {
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textAlign: "center",
    color: "#1B2430",
  },
  makePrimaryActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px",
  },
  iconClass: {
    fontSize: "70px",
    color: "#F8A92B",
  },
  primaryChip: {
    background: "#13CF8F",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    width: "80px",
    marginLeft: "-5px",
  },
  primaryChipTypo: {
    fontSize: "10px",
    fontWeight: "400",
    marginY: "auto",
    display: "flex",
    color: "#FFF",
    alignItems: "center",
    justifyContent: "center",
  },

  activatedPermission: {
    color: "#13CF8F",
    marginTop: "10px",
    height: "20px",
  },
  permissionGridGranted: {
    padding: "8px",
    border: "1px solid #EBEBEC",
    borderRadius: "8px",
    marginBottom: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f5fcff",
    },
  },
  permissionGrid: {
    padding: "8px",
    border: "1px solid #EBEBEC",
    borderRadius: "8px",
    marginBottom: "8px",
  },
  permissionName: {
    color: "#1B2430",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "8px",
  },
  permissionNameBox: {
    display: "flex",
  },
  featureGrid: {
    marginTop: "12px",
    padding: "12px 8px",
  },
  permissionBoxStyle: {
    display: "flex",
    justifyContent: "space-between",
  },
  ActionButtonGrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
    marginBottom: "16px",
  },
  ActionButton: {
    width: "150px",
    height: "52px",
    // radius: "8px",
    border: "1px solid #E0E0E0",
    color: "#E0E0E0",
    padding: "14px, 20px, 14px, 20px",
    background: "#FFFF",
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    marginLeft: "8px",
    cursor: "pointer",
    "&:hover": {
      background: "#FFFF",
      border: "1px solid #E0E0E0",
    },
  },
  menuItemStyle: {
    color: "#1B2430",
    width: "150px",
    fontSize: "14px",
    lineHeight: "20px",
    cursor: "pointer",
    "&:hover": {
      background: "#EBEBEC",
    },
  },
  paperStyle: {
    padding: "40px",
    marginTop: "12px",
  },
  toast: {
    width: "100%",
    display: "flex",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  toastBox: {
    backgroundColor: "#FFF1D7",
    width: "100%",
    display: "flex",
    borderRadius: "4px",
    //   gap: "1rem",
  },
  toastBoxBorder: {
    backgroundColor: "#FAB431",
    width: "6px",
    borderRadius: "4px 0 0 4px",
  },
  toastBoxContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
  },
  toastBoxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
    gap: "1rem",
  },
  subscribeItemStyle: {
    background: "#FFF7E7",
    minWidth: "101px",
    padding: "4px 10px 4px 10px",
    margin: "0.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    gap: "4px",
    marginLeft: "0px",
  },
  subscribeItemTextStyle: {
    fontSize: "12px",
    fontWeight: "700",
    padding: "2px",
    lineHeight: "16px",
    color: "#FAB431",
  },
  unsubscribeItemStyle: {
    background: "#E3F9F2",
    minWidth: "101px",
    padding: "4px 10px 4px 10px",
    margin: "0.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    gap: "4px",
    marginLeft: "0px",
  },
  unsubscribeItemTextStyle: {
    fontSize: "12px",
    fontWeight: "700",
    padding: "2px",
    lineHeight: "16px",
    color: "#13CF8F",
  },
  crossIconstyle: {
    marginTop: "10px",
    marginRight: "12px",
  },
  closeIconStyle: {
    paddingTop: "0px",
    cursor: "pointer",
  },
  emailReportSubLabel: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "14px",
    color: "#1b2430",
  },
  iconClass: {
    fontSize: "70px",
    color: "#F8A92B",
  },
  makePrimaryUserTextHeadBulk: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#1B2430",
  },
  dialogBoxBulk: {
    display: "flex",
    justifyContent: "flex-end",
  },
  makePrimaryUserTexBulk: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1B2430",
  },
  makePrimaryActionsBulk: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px",
  },
  dialogBoxContentBulk: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
}));
