import React, { useState, useEffect } from "react";
import { Divider, Grid, Typography, Box } from "@mui/material";
import { useParams } from "react-router-dom";

import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import NotFound from "../../../../components/NotFound/NotFound";

const AccountsOverview = () => {
  const params = useParams();
  const { id } = params;
  const { t } = useTranslation();
  const [loadig, setLoading] = React.useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyUsers, setCompanyUsers] = useState(null);

  useEffect(() => {
    getAllCustomer();
  }, []);

  const getAllCustomer = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/admin/company/${id}`);
      if (res.status === 200) {
        setAllCompanies(res?.data.data);
        setCompanyUsers(res?.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loadig ? (
        <Loader />
      ) : (
        <>
          {companyUsers !== null ? (
            <>
              <Box sx={{ backgroundColor: "#ffff" }}>
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} md={3} sm={3}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#495059",
                        }}
                      >
                        {t("PrimaryUser")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9} sm={9}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#1B2430",
                        }}
                      >
                        {companyUsers.isPrimary
                          ? companyUsers.firstName + " " + companyUsers.lastName
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    alignItems: "center",
                    //   justifyContent: "space-evenly",
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} md={3} sm={3}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#495059",
                        }}
                      >
                        {t("EmailAddress")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9} sm={9}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#1B2430",
                        }}
                      >
                        {/* {companyUsers.email} */}
                        {companyUsers.email !== null
                          ? companyUsers.email
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    alignItems: "center",
                    //   justifyContent: "space-evenly",
                  }}
                >
                  <Grid container>
                    <Grid xs={6} md={3} sm={3}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          color: "#495059",
                        }}
                      >
                        {t("TelephoneNumber")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={9} sm={9}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "400",
                          lineHeight: "24px",
                          color: "#1B2430",
                        }}
                      >
                        {/* {allCompanies.telephone} */}
                        {allCompanies.telephone !== null
                          ? allCompanies.telephone
                          : "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Box>
            </>
          ) : (
            <NotFound text={t("No_Account_Found")} />
          )}
        </>
      )}
    </>
  );
};

export default AccountsOverview;
