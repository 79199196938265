import React from "react";
import { Skeleton } from "@mui/material";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import { TableBody } from "@mui/material";

const TableSkeletonLoader = ({ ...props }) => {
  return (
    <>
      <CustomizedTableHead>
        <CustomizedTableRow>
          <CustomizedTableCell>
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
          <CustomizedTableCell>
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
          <CustomizedTableCell>
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
          <CustomizedTableCell>
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
          <CustomizedTableCell>
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
        </CustomizedTableRow>
      </CustomizedTableHead>

      <TableBody>
        <CustomizedTableRow sx={{ fontWeight: "700px" }}>
          <CustomizedTableCell align="left" sx={{ width: "300px" }}>
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>

          <CustomizedTableCell sx={{ cursor: "pointer" }} align="left">
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>

          <CustomizedTableCell align="left">
            {" "}
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
          <CustomizedTableCell align="left">
            {" "}
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
          <CustomizedTableCell align="left">
            {" "}
            <Skeleton animation="wave" variant="text" />
          </CustomizedTableCell>
        </CustomizedTableRow>
      </TableBody>
    </>
  );
};

export default TableSkeletonLoader;
