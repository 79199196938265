import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
pink:"#f68897",
lightyellow: "#fdf2df",
    orange: "orange",
  gray:"#757575"
};

export const useStyles = makeStyles((theme) => ({
    paper_Box: {
        boxShadow: "none",
        border: "none",
        backgroundColor: "#f5f5f5",
        borderRadius: "8px",
        marginLeft: "1rem",
        marginRight: "2rem",
        padding:"1.5rem"
    },
    paper_style: {
        boxShadow:"none"
    },
    grid_container: {
        display: "flex",
        
        justifyContent: "end",
        alignItems: "end",
        color:"gray"
    },
    input_box: {
        display: "flex",
        alignItems:"center"
    },
    select_box: {
        width: "250px",
        height: "50px",
       marginRight:"10px"
    },
    main_box: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginLeft: "20px",
        alignItems:"center"
    },
    grid_container: {
        display:"flex"
    },
    Grid_item_1: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "1rem",
        marginBlock: "1rem",
        width:"100%"
    },
    secondary_box: {
        display: "flex",
        alignItems: "center",
        marginRight: "18px",
        gap: "0.5rem",
        float: "right",
       marginTop:"16px"
    },
    grid_item: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginInline: "1rem",
        marginBlock:"1rem"
    },
    box: {
        display: "flex",
        gap: "5px",
        alignItems: "center",
        gap:"0.5rem",
    },
    loading_Button: {
        borderRadius:"8px"
    },
    menu_item :{
  color: "grey"
    },
    grid_item_2 : {
  display: "flex",
  justifyContent:"flex-end",
  marginTop:"1rem"
}

}));










