import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Select,
  Checkbox,
  MenuItem,
  Button,
  Card,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Loader from "../../../../../../../../components/Loaders/Loader";
import { useStyles } from "../../../../../Styles/style";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const AttributesModal = ({
  allAttributes,
  item,
  setItemsArr,
  itemsArr,
  result,
  filteredItemsArr,
  handleChangeAttributeValue,
  index,
  setSelectedItem,
  selectedItem,
  handleChangeURL,
  handleChangeEnum,
  handleChangeSwitch,
  isLoading,
  removeAttribute,
  handleChangeRepeatedEnum,
  enumRequired,
  setEnumRequired,
  urlRequired,
  setUrlRequired,
  repeatedEnumRequired,
  setRepeatedEnumRequired,
  urlValidation,
  setUrlValidation,
  handleRepeatedEnumSwitch,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setSelectedItem(item);
  }, []);

  var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
  function validURLOther(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function validURL(string) {
    if (typeof string !== "string") {
      return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }

    if (
      localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }

    return false;
  }

  function handleDisplayName(name) {
    let text = "";
    if (name !== "" && name !== undefined) {
      const splittedText = name.split("/");

      const finalText = splittedText[1]?.replaceAll("_", " ");
      text =
        finalText[0]?.toUpperCase() + finalText?.substring(1)?.toLowerCase();
    }

    return text;
  }

  return (
    <>
      <>
        <Grid container spacing={2} sx={{ marginTop: "12px" }}>
          <Grid
            item
            xs={7}
            sm={7}
            md={item?.valueType === "BOOL" ? 10 : 6}
            lg={item?.valueType === "BOOL" ? 10 : 6}
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filteredItemsArr?.length > 0 ? filteredItemsArr : []}
              value={item}
              onChange={(e, value) => {
                handleChangeAttributeValue(value, index, item);

                /*   if (value?.valueType==="ENUM") {
              handleChangeEnum("free_wi_fi", item, index);
            } */
                /*  if (value?.valueType === "URL") {
              setUrlRequired(true);
              setEnumRequired(false);
              setRepeatedEnumRequired(false);
            } else if (value?.valueType === "ENUM") {
              setEnumRequired(true);
              setRepeatedEnumRequired(false);
              setUrlRequired(false);
            } else if (value?.valueType === "REPEATED_ENUM") {
              setRepeatedEnumRequired(true);
              setUrlRequired(false);
              setEnumRequired(false);
            } else {
              setRepeatedEnumRequired(false);
              setUrlRequired(false);
              setEnumRequired(false);
            } */
              }}
              getOptionLabel={(option) =>
                option?.displayName
                  ? option?.displayName
                  : handleDisplayName(option?.internalName)
              }
              fullWidth
              renderInput={(params) => (
                <TextField placeholder={t("select_attr")} {...params} />
              )}
            />
          </Grid>
          {item?.valueType !== "BOOL" && (
            <Grid item xs={4} sm={4} md={4} lg={4}>
              {result?.valueType === "URL" ? (
                <TextField
                  fullWidth
                  InputProps={{
                    inputProps: {
                      style: { marginLeft: "4px" },
                    },
                  }}
                  name="locationName"
                  placeholder={t("Enter_URl")}
                  required
                  value={
                    item?.uriValues?.length > 0 ? item?.uriValues[0]?.uri : ""
                  }
                  // onChange={(e) =>
                  //   handleChangeURL(e, item, index)}

                  onChange={(e) => {
                    handleChangeURL(e, item, index);
                    if (e.target.value.length > 0) {
                      setUrlRequired(false);

                      // Check for protocol, www occurrences, invalid characters, and query parameters
                      const occurrences = (
                        e.target.value.match(/https?/g) || []
                      ).length;
                      const wwwOccurrences =
                        e.target.value.split("www").length - 1;
                      const invalidCharacters = /[<>]/;
                      const containsQueryParams = e.target.value.includes("?");
                      const validity =
                        validURL(e.target.value) ||
                        validURLOther(e.target.value);

                      if (invalidCharacters.test(e.target.value)) {
                        setUrlValidation(true); // Invalid due to < or >
                      } else if (containsQueryParams) {
                        setUrlValidation(true); // Invalid due to presence of query parameters
                      } else if (occurrences === 2) {
                        setUrlValidation(true); // Invalid due to multiple http(s) occurrences
                      } else if (wwwOccurrences >= 2) {
                        setUrlValidation(true); // Invalid due to multiple "www" occurrences
                      } else if (!validity) {
                        setUrlValidation(true); // Invalid due to not matching URL pattern
                      } else {
                        setUrlValidation(false); // Valid URL
                      }
                    } else {
                      setUrlRequired(true);
                    }
                  }}
                  error={item?.errorRequired || item?.error}
                  helperText={
                    (item?.errorRequired && t("URL_is_Required")) ||
                    (item?.error && t("Invalid_Url"))
                  }
                />
              ) : result?.valueType === "ENUM" ? (
                <>
                  <TextField
                    style={{ width: "100%" }}
                    onChange={(e, value) => {
                      handleChangeEnum(e.target.value, item, index);
                      if (value === null) {
                        setEnumRequired(true);
                      } else {
                        setEnumRequired(false);
                      }
                    }}
                    select // tell TextField to render select
                    label={`${t("Select")} ${item?.displayName}`}
                    error={item?.enumRequired}
                    helperText={item?.enumRequired && t("Field_is_Required")}
                    value={
                      item?.values?.length > 0
                        ? item?.values[0] === false || item?.values[0] === true
                          ? ""
                          : item?.values[0]
                        : ""
                    }
                  >
                    {result?.valueMetadata?.length > 0 &&
                      result?.valueMetadata?.map((item, index) => (
                        <MenuItem key={index} value={item?.value}>
                          {item?.displayName}
                        </MenuItem>
                      ))}
                  </TextField>
                </>
              ) : (
                result?.valueType === "REPEATED_ENUM" && (
                  <>
                    <Card
                      variant="outlined"
                      sx={{ borderColor: "#bfbfbf", borderRadius: "4px" }}
                    >
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{ /*  border: "#1b2430" */ borderRadius: "8px" }}
                        >
                          <Typography
                          // sx={{ padding: "8px" }}
                          //  className={classes.accordianText}
                          >
                            {item?.displayName}
                          </Typography>
                          <Divider />
                        </AccordionSummary>

                        <AccordionDetails>
                          {result?.valueMetadata?.length > 0 &&
                            result?.valueMetadata?.map((itemOne, ind) => (
                              <Grid container key={ind}>
                                <Grid
                                  item
                                  xs={8}
                                  sm={8}
                                  lg={8}
                                  md={8}
                                  sx={{ paddingTop: "8px" }}
                                >
                                  <Typography
                                    className={
                                      item?.repeatedEnumValue?.setValues?.includes(
                                        itemOne
                                      ) === true ||
                                      item?.repeatedEnumValue?.unsetValues?.includes(
                                        itemOne
                                      ) === true
                                        ? classes.selectedAttributes
                                        : classes.unSelectedAttributes
                                    }
                                  >
                                    {itemOne?.displayName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4} sm={4} lg={4} md={4}>
                                  <Box sx={{ display: "flex" }}>
                                    <Switch
                                      className={
                                        item?.repeatedEnumValue?.setValues?.includes(
                                          itemOne
                                        ) === true
                                          ? classes.toggleUnChecked
                                          : classes.toggleChecked
                                      }
                                      checked={item?.repeatedEnumValue?.setValues?.includes(
                                        itemOne
                                      )}
                                      onChange={(e) =>
                                        handleRepeatedEnumSwitch(
                                          e,
                                          itemOne,
                                          index,
                                          ind
                                        )
                                      }
                                    />
                                    <Typography sx={{ paddingTop: "8px" }}>
                                      {" "}
                                      {item?.repeatedEnumValue?.setValues?.includes(
                                        itemOne
                                      ) === true
                                        ? t("Yes")
                                        : t("No")}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    </Card>
                  </>

                  /*   <Autocomplete
                multiple
                disablePortal
                id="combo-box-demo"
                options={
                  result?.valueMetadata?.length > 0 ? result?.valueMetadata : []
                }
                getOptionLabel={(option) =>
                  option?.displayName ? option?.displayName : option
                }
                className="Autocomplete-field"
                value={
                  item?.repeatedEnumValue?.unsetValues?.length > 0
                    ? item?.repeatedEnumValue?.unsetValues
                    : item?.values[0]===false || item?.values[0]===true
                    ? []
                    : item?.values
                }
                onChange={(e, value) => {
                  handleChangeRepeatedEnum(value, item, index);
                  if (value===null) {
                    setRepeatedEnumRequired(true);
                  } else {
                    setRepeatedEnumRequired(false);
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {`${option.displayName} `}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_Credit_Cards")}
                    {...params}
                    error={repeatedEnumRequired}
                    helperText={
                      repeatedEnumRequired && t("Select_Credit_Cards")
                    }
                    required
                    name="language"
                  />
                )}
              ></Autocomplete> */
                )
              )}
            </Grid>
          )}
          <Grid xs={1.5} sm={1.5} md={1} lg={1} sx={{ display: "flex" }}>
            {item?.valueType === "BOOL" && (
              <Box sx={{ display: "flex", paddingTop: "10px" }}>
                <Switch
                  className={
                    item?.values?.length > 0 && item?.values[0] === true
                      ? classes.toggleUnChecked
                      : classes.toggleChecked
                  }
                  checked={
                    item?.values?.length > 0 && item?.values[0] === true
                      ? true
                      : false
                  }
                  onChange={(e) => handleChangeSwitch(e, index)}
                />
                <Typography sx={{ paddingTop: "8px" }}>
                  {" "}
                  {item?.values?.length > 0 && item?.values[0] === true
                    ? t("Yes")
                    : t("No")}
                </Typography>
              </Box>
            )}
          </Grid>

          <Grid item xs={1} sm={1} md={1} lg={1} sx={{ textAlign: "end" }}>
            <IconButton onClick={() => removeAttribute(index)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default AttributesModal;
