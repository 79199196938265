import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  paper_Box: {
    boxShadow: "none",
    border: "none",
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    merginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: "2rem",
    padding: "1.5rem",
  },
  paper_style: {
    boxShadow: "none",
  },
  grid_container: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    color: "gray",
  },

  input_box: {
    display: "flex",
    alignItems: "center",
  },
  select_box: {
    width: "250px",
    height: "50px",
    marginRight: "10px",
  },
  select_box_2: {
    width: "200px",
    height: "50px",
  },
  grid_item: {
    display: "flex",
    justifyContent: "flex-end",
  },
  form_style: {
    border: "none",
  },
  text_field: {
    width: "100%",
    resize: "none",
    borderRadius: 4,
    backgroundColor: "#ffff",
    border: "none",
    outline: "none",
  },
  // CreteTemplate
  main_box: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginLeft: "20px",
  },
  grid_container: {
    display: "flex",
  },
  Grid_item_1: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginInline: "1rem",
    marginBlock: "1rem",
    width: "100%",
  },
  secondary_box: {
    display: "flex",
    alignItems: "center",
    marginRight: "18px",
    gap: "0.5rem",
    float: "right",
    marginTop: "16px",
  },
  //EditTemplatee
  grid_container: {
    display: "flex",
    flexDirection: "column",
  },
  grid_item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginInline: "1rem",
    marginBlock: "1rem",
  },
  box: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  box_2: {
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
  },
  loading_button: {
    borderRadius: "8px",
  },
  //Indexx
  table_style: {
    minWidth: 650,
  },

  grid_item: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  //SelectedTemplatee
  grid_container_selected: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
    marginInline: "10px",
  },
  arrow_back_icon: {
    color: "blue",
    cursor: "pointer",
    marginRight: "1rem",
  },
  typo_text: {
    cursor: "pointer",
  },
  paper_style_selected: {
    boxShadow: "none",
  },
  grid_container: {
    display: "flex",
    flexDirection: "column",
  },
  grid_item_selected: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginInline: "1rem",
    marginBlock: "1rem",
  },
  secondary_box_selected: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  text_area_selected: {
    width: 1200,
    height: 170,
    resize: "none",
    borderRadius: 4,
    marginLeft: 16,
    marginTop: 50,
    padding: "1rem",
    border: "gray",
  },
  //UpdateTemplateCardd
  paper_style: {
    boxShadow: "none",
    border: "none",
    maxWidth: 1200,
    backgroundColor: "#f5f5f5",
    borderRadius: "8px",
    marginLeft: "1rem",
    marginBottom: "2rem",
    padding: "1.5rem",
    marginRight: "1rem",
  },
  grid_container: {
    display: "flex",
    justifyContent: "end",
    alignItems: "end",
    color: "gray",
  },
  text_field: {
    width: "100%",
    resize: "none",
    borderRadius: 4,
    border: "none",
    outline: "none",
    backgroundColor: "#f5f5f5",
  },
  grid_item_1: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "1rem",
  },
  grid_item_2: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
  },
  secondary_box: {
    display: "flex",
    alignItems: "center",
  },
  select_box: {
    width: "200px",
    height: "50px",
  },
  form: {
    border: "none",
  },
  menu_item: {
    color: "grey",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  //Index
  table_style_index: {
    minWidth: 650,
  },
  icon_button: {
    height: "16px",
  },
  grid_container_index: {
    display: "flex",
    flexDirection: "column",
  },
  grid_item_index: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleHead: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },

  titleRequired: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.6964285714285714rem",
    lineHeight: "1.66",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px",
  },
  moreDescription: {
    fontSize: "14px",
    color: "#0638C1",
    fontWeight: "700",
  },
  reviewName: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  personalizeText: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  descriptionText: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  descLengthText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    paddingTop: "10px",
  },
  limitText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#FF0000",
    lineHeight: "15px",
    marginTop: "16px",
    marginBottom: "8px",
  },
  descRequired: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.6964285714285714rem",
    lineHeight: "1.66",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px",
  },
  titleRequiredEdit: {
    color: "#d32f2f",
    fontWeight: 400,
    fontSize: "0.6964285714285714rem",
    lineHeight: "1.66",
    textAlign: "left",
    marginTop: "3px",
    marginRight: "14px",
    marginBottom: 0,
    marginLeft: "14px",
  },
  titleEdit: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#1B2430",
    marginBottom: "8px",
    marginTop: "10px",
  },
  addMoreDescription: {
    fontSize: "14px",
    color: "#0638C1",
    fontWeight: "700",
  },
}));
