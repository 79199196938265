// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import moment from "moment";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import { makeStyles } from "@mui/styles";

// ** File Imports ================================================================
import Loader from "../../../components/Loaders/Loader";
import { api } from "../../../contexts/JWTContext";
import TitleHeader from "../../../components/CustomComponents/title";
import NotFound from "../../../components/NotFound/NotFound";
import Delete from "../../../components/Models/DeleteModal/Delete";
import ImportModel from "../../../components/Models/DeleteModal/Importmodal";

// ** Material ui Imports ================================================================
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableSortLabel,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  Menu,
  Box,
  MenuItem,
  Paper,
  TextField,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import { Dialog } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { handleSessionOut } from "../../../contexts/JWTContext";

// ** Other Imports ================================================================
const Divider = styled(MuiDivider)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
    // hover: "none",
  },
});
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  subHeadFetch: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2430",
    marginTop: "8px",
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
}));

const Index = () => {
  // ** Others ================================================================
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  // ** States ================================================================
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [allTemplate, setAllTemplate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [filterWithName, setFilterWithName] = useState("");
  const [open, setOpen] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [orderBy, setOrderBy] = React.useState("usage");
  const [order, setOrder] = React.useState("desc");
  const [allCompanies, setAllCompanies] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [companyId, setCompanyId] = useState(null);
  const [companyIds, setCompanyIds] = useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [allLocation, setAllLocation] = useState([]);

  useEffect(async () => {
    getAllCompanies();
  }, [companyId]);
  useEffect(() => {
    setLoading(true);
    getAllTemplate();
  }, [rowsPerPage, page, order, companyId]);

  // useEffect(() => {
  //   if (search===true) {
  //     const delayDebounceFn = setTimeout(() => {
  //       getAllTemplate();
  //     }, 1000);

  //     return () => clearTimeout(delayDebounceFn);
  //   }
  // }, [search]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllTemplate();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName, rowsPerPage, page, order, companyId]);

  // ** Handler Functions ================================================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleCompanyChange = (option) => {
    setCompanyIds(option);
    //  setLocationId(null);
    /*  if (allCompanies?.results?.locations?.length !== 0) {
      setSelectedCompany(option?.locations);
      setUsers(option?.users);

    } else {
      setSelectedCompany("no location found with this id");
      setUsers("no user found with this id");
    } */
  };
  const handleChangeCompany = (option) => {
    setCompanyId(option);

    if (option?.locations?.length > 0) {
      const googleConnectedLocations = option?.locations?.filter(
        (item) => item?.socialLink?.length > 0
      );
      setAllLocation(googleConnectedLocations);
    } else {
      setAllLocation([]);
    }
  };

  const handleLocationChange = (option) => {
    setCompanyId(option);
  };
  const getAllCompanies = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteTemplate(id);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleOpenDeleteModel = () => {
    setOpen(deleteTemplate);
    setAnchorEl(null);
  };
  const handleChangeCheckButton = (e) => {
    setChecked(e.target.checked);
  };

  const getAllTemplate = async () => {
    setLoading(true);

    try {
      const res = await api.get(
        `/template?limit=${rowsPerPage}&page=${page + 1}&sortBy=${
          orderBy ? orderBy : ""
        }&sortOrder=${order}&searchTerm=${filterWithName}&companyId=${
          companyId !== null ? companyId?.id : ""
        }`
      );

      if (res.status === 200) {
        setAllTemplate(res?.data?.data);

        setCount(res?.data?.data?.count);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const deleteTemplates = async (id) => {
    try {
      const res = await api.delete(`/template/${id}`);

      if (res.status === 200) {
        // setDeleteTemplate();
        // setDeleteComment(res.data.data);
        setFilterWithName("");
        setOpen(false);

        toast.success("Template Deleted Successfully");
        getAllTemplate();
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  /*  useEffect(() => {
     getAllTemplate();
   }, [filterWithName]); */

  const handleRequestSort = (event, property) => {
    if (order === "asc") {
      setOrder("desc");
    } else if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("asc");
    }
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const CreateNavigateButton = () => {
    navigate(`/admin/createtemplate`);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const handleDefaultTemplate = async () => {
    let companyIdss = [];
    // let companyIds = Array.prototype.map.call(value, (s) => s.id).toString();
    if (companyIds?.length > 0) {
      companyIds?.map((company) => {
        companyIdss.push(company?.id);
      });
    }
    try {
      setLoading(true);
      const res = await api.get(
        `template/scriptToRevistDefault?all=${checked}&companyId=${
          companyIdss ? companyIdss.toString() : ""
        }`
      );
      if (res.status === 200) {
        getAllTemplate();
        setChecked(true);
        setLoading(false);
        setOpenDefaultModel(false);
        setCompanyIds([]);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setLoading(false);
      setChecked(false);
      setOpenDefaultModel(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
      el,
      index,
    }));
    stabilizedThis.sort((a, b) => {
      const order = comparator(a.el, b.el);
      if (order !== 0) return order;
      return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
  }
  const headCells = [
    { id: null, alignment: "left", label: t("AccountTableID") },
    { id: "title", alignment: "left", label: t("NAME") },
    { id: "usage", alignment: "center", label: t("USAGE") },
    { id: null, alignment: "center", label: t("CREATEDBY") },
    { id: "updatedAt", alignment: "center", label: t("UPDATEDAT") },
    { id: "actions", alignment: "center", label: t("Action") },
  ];

  const handleDisplayDate = (item) => {
    let displayDate = "";
    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  return (
    <>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Template")}
            description={t("del_desc_temp")}
            onConfirm={() => deleteTemplates(deleteTemplate)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <ImportModel
            title={t("Default_temp_title")}
            // description={t("Are you sure you want to get default temlplate of all companies?")}
            description={
              <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Checkbox
                      sx={{ paddingLeft: "0px" }}
                      checked={checked}
                      onChange={(e) => handleChangeCheckButton(e)}
                      inputProps={{ True: "False" }}
                    />
                    <Typography className={classes.subHeadFetch}>
                      {t("All_Companies")}
                    </Typography>
                  </Box>
                  {}
                  {/*    {} */}
                  {!checked === true && (
                    <>
                      {/*    <Autocomplete
                        multiple
                        disablePortal
                        id="combo-box-demo"
                        options={allCompanies?.results}
                        getOptionLabel={(option) => option?.internalName}
                        className="Autocomplete-field"
                        defaultValue={companyIds}
                        onChange={(e, value) => {


                          handleCompanyChange(value);
                          setCompanyIdRequired(false);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {`${option?.internalName} `}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("Select_Company")}
                            {...params}
                            required
                            // label={t("TaskCompany")}
                            name="language"
                            error={companyIdRequired}
                          />
                        )}
                      ></Autocomplete> */}
                      <Autocomplete
                        multiple
                        disablePortal
                        id="combo-box-demo"
                        options={allCompanies?.results}
                        getOptionLabel={(option) => option?.name}
                        className="Autocomplete-field"
                        value={companyIds ? companyIds : []}
                        // value={selectedLocation}
                        onChange={(e, value) => {
                          handleCompanyChange(value);
                          setCompanyIdRequired(false);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {`${option?.name} `}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("Select_Company")}
                            {...params}
                            required
                            // label={t("TaskCompany")}
                            name="language"
                            error={companyIdRequired}
                          />
                        )}
                      ></Autocomplete>
                    </>
                  )}

                  {/* <Typography className={classes.subHeadFetch}>Also auto-reply on fetched reviews</Typography> */}
                </Grid>
              </Grid>
            }
            onConfirm={() => handleDefaultTemplate()}
            onCancel={handleCloseTemplateMenu}
          />
        </Dialog>
      )}
      <TitleHeader
        // showButton={true}
        title={t("Templates_Title")}
        subHeading={t("Sub_Title")}
        extraButton={true}
        extraButtonName={t("Import_Default_Template")}
        name={t("Create_Template")}
        CreateNavigateButton={CreateNavigateButton}
        handleDefaultTemplate={handleOpenTemplateMenu}
        count={count}
        addPermission={true}
        viewPermission={true}
      />
      <Grid container className="user_template_grid1">
        <Grid className="user_template_grid2" item></Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={5} lg={5.5}>
          <Autocomplete
            // multiple
            disablePortal
            id="combo-box-demo"
            options={
              allCompanies?.results?.length > 0 ? allCompanies?.results : []
            }
            getOptionLabel={(option) =>
              option?.name ? option?.name + "(" + option?.city + ")" : ""
            }
            className="Autocomplete-field"
            value={companyId !== null ? companyId : []}
            onChange={(e, value) => {
              handleChangeCompany(value);
            }}
            /*  renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.internalName} (${option.city})`}
              </li>
            )} */
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_Company")}
                {...params}
                required
                name="company"
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={8} sm={9} md={5} lg={5.5}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllTemplate}
            placeholder={t("Filter_templates")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          md={2}
          lg={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CommonButton
            // isLoading={isLoading}
            onSubmit={getAllTemplate}
            label={t("Search")}
            alignment={true}
          />
        </Grid>
      </Grid>

      {loading ? (
        <Loader />
      ) : (
        <>
          {allTemplate?.count > 0 ? (
            <>
              <TableContainer
                borderRadius="12"
                component={Paper}
                sx={{ marginTop: "1rem" }}
              >
                <Table className="table-style" aria-label="simple table">
                  <CustomizedTableHead variant="h1">
                    <CustomizedTableRow>
                      {headCells?.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            disabled={headCell.id === null ? true : false}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>
                  <TableBody>
                    {allTemplate?.results.length > 0 &&
                      stableSort(
                        allTemplate?.results,
                        getComparator(order, orderBy)
                      ).map((row) => (
                        <CustomizedTableRow>
                          {/* <CheckBox /> */}
                          <CustomizedTableCell sx={{ cursor: "pointer" }}>
                            {row.id}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center" hover>
                            <Typography component="th" scope="row">
                              {" "}
                              {row.title}
                            </Typography>
                            <Typography>{row.text}</Typography>
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {row?.usage || "0"}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {/* {row?.user?.firstName + " " + row?.user?.lastName} */}
                            {/* {(row?.user?.firstName !==null ? row?.user?.firstName : "--") + " " + (row?.user?.lastName !==null ? row?.user?.lastName : "--")} */}
                            {row?.user?.firstName !== null &&
                            row?.user?.lastName !== null
                              ? row?.user?.firstName + " " + row?.user?.lastName
                              : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {" "}
                            <Box mr={4}>
                              {companyData && companyData.dateFormat
                                ? handleDisplayDate(row?.updatedAt)
                                : moment(row?.updatedAt).format("MMM DD YYYY")}
                              {/* {moment(row?.updatedAt).format("MMM DD YYYY")} */}
                              {/* {handleDisplayDate(row?.updatedAt)} */}
                            </Box>
                          </CustomizedTableCell>

                          <CustomizedTableCell align="center">
                            {/* <IconButton aria-label="details" size="large">
                        <MoreVertIcon />
                      </IconButton> */}
                            <Box mr={2}>
                              <CustomWidthTooltip title={t("Edit_Template")}>
                                <IconButton
                                  className="icon-button"
                                  aria-label="edit"
                                  size="large"
                                  onClick={() =>
                                    navigate(`/admin/edittemplate/${row?.id}`)
                                  }
                                >
                                  <Edit />
                                </IconButton>
                              </CustomWidthTooltip>
                              {/* <CustomWidthTooltipOfDelete
                                title={t("Delete_Template")}
                              >
                                <Edit />
                              </IconButton>
                            </CustomWidthTooltip>
                            <CustomWidthTooltipOfDelete
                              title={t("Delete_Template")}
                            >
                              <IconButton
                                aria-label="delete"
                                size="large"
                                className="Icon-button"
                                // onClick={() => (setOpen(true)  setDeleteId(row.id))}
                                onClick={(e) => {
                                  setOpen(true);

                                    setDeleteTemplate(row?.id);
                                    // EditTemplate(row?.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </CustomWidthTooltipOfDelete> */}
                              <IconButton
                                onClick={(e) => handleClick(e, row?.id)}
                                variant="outlined"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    closeMenu();

                                    handleOpenDeleteModel();
                                    // setOpen();
                                    // setDeletePost(item?.id);
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography
                                    onClick={(e) => {
                                      setOpen(true);

                                      //  setDeleteTemplate(row?.id);
                                    }}
                                  >
                                    {t("Delete")}
                                  </Typography>
                                </MenuItem>
                              </Menu>
                            </Box>
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allTemplate?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound text={t("No_Template_Found")} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Index;
