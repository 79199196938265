import React from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  Box,
  TextField,
} from "@mui/material";
import "./postAiModel.css";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "5px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  limitFieldTextDescription: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "3px",
  },
}));

const ListingCustomBulkModal = ({
  title,
  length,
  subTitle,
  onCancel,
  placeHolder,
  description,
  setDescription,
  setTouchedValue,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>{title}</Typography>
              <Typography>{subTitle}</Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Divider />

      <Grid className={classes.mainHeadingContainer}>
        <Box className="box_delete">
          <Grid className={classes.mainKeyWordContainer}>
            <Typography className={classes.labelText}>{title}</Typography>

            <TextField
              rows={3}
              maxRows={6}
              fullWidth
              multiline
              disabled={description.length >= length ? true : false}
              style={{
                minHeight: "84px",
              }}
              onChange={(e) => {
                const str = e.target.value.substring(0, length);
                setTouchedValue(true);
                setDescription(str);
              }}
              placeholder={placeHolder}
              value={description}
              name="Description"
            />
            <Grid
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Typography className={classes.limitFieldTextDescription}>
                {description === "" || description?.length === 0
                  ? 0
                  : description?.length}
                /{length}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default ListingCustomBulkModal;
