import React from "react";
import styled from "styled-components/macro";

import { Typography } from "@mui/material";

import SidebarNavList from "./SidebarNavList";
import { useTranslation } from "react-i18next";

const Title = styled(Typography)`

  padding: ${(props) => props.theme.spacing(4)}
    ${(props) => props.theme.spacing(7)} ${(props) => props.theme.spacing(1)};
    text-transform: uppercase;
    display: block;
    color: #FFFFFF;
    line-height:20px

    font-size: 14px;
    font-weight: 700;
`;
// opacity: 1;
//  font-size: ${(props) => props.theme.typography.caption.fontSize};

const SidebarNavSection = (props) => {
  const { t } = useTranslation();

  const {
    title,
    pages,
    className,
    component: Component = "nav",
    ...rest
  } = props;

  return (
    <Component {...rest}>
      {title && <Title variant="subtitle2">{t(title)}</Title>}
      <SidebarNavList pages={pages} depth={0} />
    </Component>
  );
};

export default SidebarNavSection;
