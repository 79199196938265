import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { useTranslation } from "react-i18next";
import BouncingDotsLoader from "../../../../../../components/Loaders/bounsingLoader";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import EditModal from "../../Modal";
import { useStyles } from "../../../Styles/style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocationMap from "./map";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CardHeader from "../CardHeader";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const containerStyle = {
  width: "100%",
  height: "400px",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Locationmap({
  location,
  getLocationDetails,
  locationDetailsData,
  allAttributes,
  locationCategory,
  setLocationDetailsData,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  // const googleMapsApiKey = process.env.REACT_APP_MAP_KEY;

  const center = {
    lat: locationDetailsData?.result?.google?.latlng?.latitude,
    lng: locationDetailsData?.result?.google?.latlng?.longitude,
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <EditModal
          open={openDefaultModel}
          modalIndex={
            locationDetailsData !== null &&
            locationDetailsData?.result?.google?.metadata?.canHaveFoodMenus ===
              true
              ? 14
              : 13
          }
          locationCategory={locationCategory}
          onCancel={handleCloseTemplateMenu}
          getLocationDetails={getLocationDetails}
          location={location}
          locationDetailsData={locationDetailsData}
          allAttributes={allAttributes}
          setLocationDetailsData={setLocationDetailsData}
          getLocationDetailsListing={getLocationDetailsListing}
          lodgingCategories={lodgingCategories}
          categoryLoading={categoryLoading}
          centerData={centerData}
          displayMapError={displayMapError}
        />
      )}
      <Card variant="outlined" className={classes.listingCard}>
        <Grid container spacing={1}>
          <CardHeader
            title={t("Map")}
            editPermission={editPermission}
            viewPermission={viewPermission}
            handleOpenTemplateMenu={handleOpenTemplateMenu}
          />

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <>
              <LocationMap
                centerData={centerData}
                containerStyle={containerStyle}
                displayMapError={displayMapError}
                locationDetailsData={locationDetailsData}
                requestSend={true}
              />

              {/*  {locationDetailsData !== null ? (
                <Grid container>
                  {centerData?.lat !== "" && centerData?.lng !== "" && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <LoadScript googleMapsApiKey="AIzaSyDFJLhlAmlDBZS06eElolQSAL0BZA_X-qY">
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={centerData}
                          zoom={10}
                        >
                          <Marker position={centerData}></Marker>
                          <></>
                        </GoogleMap>
                      </LoadScript>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <NotFound text={t("No_Data_Found")} />
              )} */}
            </>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
export default Locationmap;
