import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "../../../Style/style";
import { useTranslation } from "react-i18next";

const NoDataReviewVelocity = ({ handleDisplayName, locations }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid container className={classes.tableContainer}>
        <Grid item xs={3} sm={3} md={3} lg={3} className={classes.mainGridCont}>
          <Typography className={classes.tableHeading}>{t("Name")}</Typography>
        </Grid>
        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Typography className={classes.tableHeading}>{t("count")}</Typography>
        </Grid>
        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Typography className={classes.tableHeading}>
            {t("previous_count")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Typography className={classes.tableHeading}>
            {t("%velocity")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Typography className={classes.tableHeading}>
            {t("total_count")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.tableContainer}>
        {locations?.map((item) => {
          return (
            <>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                sx={{
                  padding: "16px",
                  paddingBottom: "10px",
                }}
              >
                <Typography className={classes.ratingHeadingsLoc}>
                  {handleDisplayName(item?.id)}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2.25}
                sm={2.25}
                md={2.25}
                lg={2.25}
                className={classes.mainGridCont}
              >
                <Typography
                  className={classes.ratingHeadings}
                  sx={{
                    color: "#F8A92B",
                  }}
                >
                  {"0"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2.25}
                sm={2.25}
                md={2.25}
                lg={2.25}
                className={classes.mainGridCont}
              >
                <Typography
                  className={classes.ratingHeadings}
                  sx={{
                    color: "#F8A92B",
                  }}
                >
                  {"0"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2.25}
                sm={2.25}
                md={2.25}
                lg={2.25}
                className={classes.mainGridCont}
              >
                <Typography
                  className={classes.ratingHeadings}
                  sx={{
                    color: "#F8A92B",
                  }}
                >
                  {"0%"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2.25}
                sm={2.25}
                md={2.25}
                lg={2.25}
                className={classes.mainGridCont}
              >
                <Typography
                  className={classes.ratingHeadings}
                  sx={{
                    color: "#F8A92B",
                  }}
                >
                  {"0"}
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
    </>
  );
};

export default NoDataReviewVelocity;
