import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Stack,
  Dialog,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import data from "./data.json";
import styled from "styled-components/macro";
import Servicetags from "./Components/serviceTags";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
}));

function Locationservices() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openDefaultModel, setOpenDefaultModel] = useState(false);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <EditModal
          open={openDefaultModel}
          modalIndex={9}
          onCancel={handleCloseTemplateMenu}
        />
      )}
      <Card variant="outlined" className={classes.listingCard}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container sx={{ padding: "16px" }}>
              <Grid item xs={8} sm={8} md={9} lg={10}>
                <Typography className={classes.titleName}>
                  {t("Services")}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={3} lg={2}>
                <Button fullWidth>
                  <Typography
                    onClick={handleOpenTemplateMenu}
                    sx={{ fontWeight: 600 }}
                  >
                    {t("Edit")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={12}>
              <Servicetags />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
export default Locationservices;
