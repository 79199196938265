import * as React from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import {
  TablePagination,
  TableHead,
  TableCell,
  DialogActions,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  tableRow: {
    padding: "20px",
  },
}));

const CustomizedTableCell = styled((props) => <TableCell {...props} />)(() => ({
  paddingLeft: "16px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: "10px",
  height: "30px",
}));

export default CustomizedTableCell;
