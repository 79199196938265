import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Card, CardContent } from "@mui/material";

const ReviewsLineChart = ({ allReviews }) => {
  const labelData = [];
  allReviews?.npsSummaryOverTime?.dataPoints.forEach((element) => {
    labelData.push(element.label);
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "NPS SUMMARY",
      },
      tooltip: {
        backgroundColor: "	#FAF9F6",
        titleColor: "black",
        bodyColor: "black",
        padding: "10px",
      },
    },
  };
  const data = {
    labels: labelData,
    datasets: [
      {
        label: "NPS Score",
        data: allReviews.npsSummaryOverTime?.dataPoints.map(
          (element) => element.npsScore
        ),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  return (
    <>
      <Card>
        <CardContent>
          <Line options={options} data={data} />
        </CardContent>
      </Card>
    </>
  );
};

export default ReviewsLineChart;
