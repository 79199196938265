import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed: "#FF0000",
};

export const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },

  MainboxProduct: {
    padding: "24px",
  },

  mainContainerPost: {
    background: color?.lightPink,
    padding: "2px 16px",
    width: "80%",
    borderRadius: "7px",
    marginBottom: "10px",
    alignItems: "center",
    height: "auto",
    "&:hover ": {
      background: color?.babypink,
    },
  },
  errorTextPost: {
    fontWeight: 400,
    // fontSize: "0.8125rem",
    lineHeight: "20px",
    color: color?.maroon,
    fontSize: "14px",
    padding: "8px 5px",
  },
  errorIconPost: {
    color: color?.red,
    marginTop: "5px",
  },
  arrowIconPost: {
    color: color?.maroon,
    paddingTop: "5px",
    cursor: "pointer",
  },
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: color?.darkGray,
    backgroundColor: color?.darkGraphite,
  },

  UnsubscribeButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "117px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.darkBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  cancelButton: {
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 20px",
    width: "100px",
    height: "48px",
    background: color?.white,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.darkGrey,
    border: "1px solid #E0E0E0",
    "&:hover ": {
      background: "#c2cbcf",
      borderRadius: "8px",
      color: "white",
    },
  },
  errorText: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.darkGrey,
  },

  actionButtons: {
    padding: "20px 16px",
    gap: "16px",
    width: "100%",
    height: "100%",
    background: color?.white,
    boxShadow: "0px -1px 0px #E0E0E0",
  },
  errorGrid: {
    justifyContent: "center",
    textAlign: "center",
    padding: "10px 5px 0px 5px",
    cursor: "pointer",
  },

  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },

  mainContainer: {
    padding: "20px",
  },
  mainErrorContainer: {
    padding: "20px",
    width: "95%",
    margin: "10px 5px 5px 10px",
    background: color?.lightPink,
    borderRadius: "8px",
  },

  retryText: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.darkBlue,
  },
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: color?.white,
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainerPost: {
    padding: "20px 0px 10px 0px",
  },
  iconStylePost: {
    width: "20px",
    height: "18px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  postDialog: {
    padding: "20px",
    boxShadow: "0px -1px 0px #E0E0E0",
  },
  postLoadingButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "auto",
    height: "48px",
    color: color?.darkGrey,
    border: "1px solid #c4c4c4",
    borderRadius: "8px",
    marginRight: "10px",
  },
  PostAiButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "136px",
    height: "48px",
    borderRadius: "8px",
  },
  postSubmitButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "98px",
    height: "48px",
    borderRadius: "8px",
  },
  editPostBox: {
    marginY: "10px",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
  },

  lazyLoadBox: {
    display: "row",
    justifyContent: "center",
    gap: "8px",
    paddingY: "12px",
  },
  locationTypo: {
    fontSize: "14px",
    fontWeight: "700",
    color: color?.darkGrey,
    lineHeight: "20px",
    marginTop: "8px",
    marginBottom: "8px",
  },
  dateTimeTypo: {
    fontSize: "14px",
    fontWeight: "700",
    color: color?.darkGrey,
    lineHeight: "20px",
    marginBottom: "8px",
    marginTop: "8px",
  },

  lengthErrorTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkRed,
    lineHeight: "15px",
    marginBottom: "8px",
  },
  postEditBox: {
    bgcolor: color?.gray,
    width: "100%",
    borderRadius: "8px",
    marginY: "8px",
    ml: 2,
  },
  googleCTATypo: {
    fontSize: "18px",
    fontWeight: "700",
    color: color?.darkGrey,
    lineHeight: "24px",
    marginBottom: "8px",
    marginTop: "8px",

    width: "100%",
  },
  postButtonBox: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "8px",
    mt: 2,
  },
  postCardDesc: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkGrey,
    lineHeight: "20px",
    marginBottom: "8px",
    ml: 2,
  },
  textLimitTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: color?.darkRed,
    lineHeight: "15px",
    marginBottom: "8px",
  },
  IconButtonDeletePost: {
    color: "red",
    width: "20px",
    height: "18px",
    mt: 4,
  },
  postAIBox: {
    bottom: "10px",
    width: "100%",
    position: "absolute",
  },
  googleBox: {
    bgcolor: color?.gray,
    width: "100%",
    borderRadius: "8px",
    marginY: "8px",
  },
  bulkEdit: {
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "24px",
    marginTop: "8px",
    color: color?.darkGrey,
  },
  bulkEditSubHead: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.grey,
  },
  postLogCard: {
    border: "1px solid #E0E0E0",
    bgcolor: color?.white,
    justifyContent: "space-around",
    mt: 4,
    mb: 4,
    height: "auto",
  },
  postLogsContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  postLogsTypo: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: color?.grey,
  },
  postLogTimePost: {
    fontWeight: 700,
    fontSize: "24px",
    color: color?.darkGrey,
    lineHeight: "32px",
  },
  postLogsPostContainer: {
    paddingTop: "10px",
    paddingBottom: "20px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  postBackground: {
    border: "1px solid #E0E0E0",
    borderRadius: "8px",
    paddingX: "24px",
  },
  postCard: {
    maxWidth: "550px",
    textAlign: "center",
    bgcolor: "#f7f9fc",
    borderRadius: "8px",
  },
  postTypoMain: {
    fontSize: 14,
    textAlign: "center",
    mt: 4,
  },
  postMainDescriptionTypo: {
    fontWeight: "700",
    fontSize: "14px",
    color: color?.white,
    width: "1,532px",
    textAlign: "center",
    backgroundColor: color?.darkBlue,
    paddingY: "14px",
    // paddingX: "20px",
    borderRadius: "8px",
    marginTop: "24px",
    // marginX: "24px",
    cursor: "pointer",
  },
  editPostCard: {
    border: "3px solid blue",
    paddingX: "14px",
    borderRadius: "8px",
  },
  postStack: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "1rem",
    marginBottom: "24px",
    marginRight: "24px",
  },
  postcancelButton: {
    fontWeight: "700",
    size: "large",
    color: color?.darkGrey,
    borderRadius: "8px",
    padding: " 14px 20px",
    border: "1px solid #E0E0E0",
    hover: "none",
  },
}));
