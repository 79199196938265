import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Modal,
  TextField,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../../styles/style";
import { useTranslation } from "react-i18next";
import { WarningAmberOutlined } from "@mui/icons-material";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { languagesData } from "../../../../../assets/defaultLanguage";
import { isValidPhoneNumber } from "libphonenumber-js";

const BulkUpdateModel = ({
  handleCloseBlukUpdateModel,
  openEditBulkModel,
  email,
  setEmail,
  selectLanguage,
  setSelectLanguage,
  keywords,
  setKeywords,
  setLocationName,
  locationName,
  setPhoneNumber,
  phoneNumber,
  website,
  setWebsite,
  handleOpenFilterModel,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  submitBulkUpdate,
  bulkLoading,
  selectedLocationsBulkUpdate,
  handleConnectWithGoogle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [openConfirmModel, setOpenConfirmModel] = useState(false);
  const [duplicateKeyword, setDuplicateKeywords] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);
  const [validPhoneNumber, setValidPhoneNumber] = useState(false);

  const urlRegexWebsite =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  const removeBrandsTag = (index) => {
    setKeywords(keywords.filter((el, i) => i !== index));
  };
  const handleBrandsKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...keywords, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateKeywords(true);
      setTimeout(() => {
        setDuplicateKeywords(false);
      }, 2000);
    } else {
      for (let i = 0; i < keywords.length; i++) {
        if (filtered.includes(keywords[i])) {
          setDuplicateKeywords(true);
          setTimeout(() => {
            setDuplicateKeywords(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };

  const handleAddKeywords = (e) => {
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(keywords);
      const mergeResult = [...keywords, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateKeywords(true);
    } else {
      setDuplicateKeywords(false);
    }
  };

  const resetHandler = () => {
    setLocationName(null);
    setEmail(null);
    setSelectLanguage("");
    setPhoneNumber(null);
    setKeywords([]);
    setWebsite(null);
  };

  const handleOpenConfirmModel = () => {
    setOpenConfirmModel(true);
  };
  const handleCloseConfirmModel = () => {
    setOpenConfirmModel(false);
  };
  const validatePhoneNumber = (phone) => {
    return isValidPhoneNumber(phone);
  };

  return (
    <>
      {openConfirmModel && (
        <Dialog
          open={openConfirmModel}
          onClose={handleCloseConfirmModel}
          sx={{
            "& .MuiDialog-paper": {
              width: "550px",
              height: "320px",
            },
          }}
        >
          <DialogTitle>
            <Box className={classes.dialogBox}>
              <IconButton
                autoFocus
                onClick={handleCloseConfirmModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogBoxContent}>
            <WarningAmberOutlined className={classes.iconClass} />
            <Typography className={classes.makePrimaryUserTextHead}>
              {t("Bulk_data_update")}
            </Typography>
            <Typography className={classes.makePrimaryUserTex}>
              {t("Bulk_data_update_des")} {selectedLocationsBulkUpdate?.length}{" "}
              {selectedLocationsBulkUpdate?.length > 1
                ? t("Bulk_data_multi")
                : t("Bulk_data_single")}
            </Typography>
          </DialogContent>
          <DialogActions sx={{ padding: "20px" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CommonButton
                  displayWhite="true"
                  onSubmit={handleCloseConfirmModel}
                  label={t("Cancel")}
                />

                <CommonButton
                  onSubmit={submitBulkUpdate}
                  label={t("Yes")}
                  isLoading={bulkLoading}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <Modal
        open={openEditBulkModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container>
          <Grid
            item
            sx={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Box className={classes.styleMainModelContainerBulk}>
              <Grid container spacing={1}>
                <Grid item md={11.5}>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "20px",
                      lineHeight: "28px",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                  >
                    {t("Bulk_Edit")}
                  </Typography>
                </Grid>
                <Grid md={0.5}>
                  <IconButton
                    autoFocus
                    onClick={() => {
                      resetHandler();
                      handleCloseBlukUpdateModel();
                    }}
                    className="delete_button"
                    sx={{ paddingTop: "0px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider />

              <Box className={classes.contentContainer}>
                <Grid
                  container
                  sx={{
                    //  paddingTop: "10px",
                    paddingBottom: "15px",
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                      <Typography
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          fontWeight: "700",

                          lineHeight: "20px",
                          marginTop: "10px",
                        }}
                      >
                        {t("Location_Name")}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          disabled={bulkLoading}
                          type="text"
                          name="accountName"
                          placeholder={t("location_name_placeHolder")}
                          value={locationName}
                          fullWidth
                          onChange={(e) => {
                            setLocationName(e.target.value);
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#1B2430",
                            fontSize: "14px",
                            fontWeight: "700",

                            lineHeight: "20px",
                            marginTop: "15px",
                          }}
                        >
                          {t("brand_name")}
                        </Typography>
                        <Typography
                          className={classes.limitFieldTextDescription}
                        >
                          {keywords.length}/10
                        </Typography>
                      </Box>

                      <Grid className={classes.tagsInputContainer}>
                        <TextField
                          variant="standard"
                          type="text"
                          className={classes.tagsInput}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          placeholder={t("brand_name_input")}
                          autoComplete="none"
                          fullWidth
                          onKeyDown={handleBrandsKeyDown}
                          onChange={(e) => {
                            if (
                              e.target.value === "," ||
                              e.target.value === "." ||
                              e.target.value.trim() === ""
                            ) {
                              e.target.value = "";
                            } else if (
                              (e.target.value !== "" &&
                                e.target.value !== "," &&
                                e.target.value.includes(".")) ||
                              (e.target.value !== "." &&
                                e.target.value !== "" &&
                                e.target.value.includes(","))
                            ) {
                              handleAddKeywords(e);
                            }
                          }}
                          disabled={keywords.length === 10 || bulkLoading}
                        />
                        {keywords?.length > 0 &&
                          keywords.map((keywords, index) => (
                            <Grid className={classes.tagItem} key={index}>
                              <Box className={classes.keywordBox}>
                                <Typography className={classes.keywordTypo}>
                                  {keywords}
                                </Typography>{" "}
                                <CloseIcon
                                  className={classes.keywordIcon}
                                  onClick={() => removeBrandsTag(index)}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      <Typography
                        sx={{
                          padding: "4px",
                          color: "gray",
                          fontSize: "10px",
                        }}
                      >
                        {t("enter_keyword_limit_10")}
                      </Typography>
                      {duplicateKeyword && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          {t("brand_duplicated_error")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                      <Typography
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          fontWeight: "700",

                          lineHeight: "20px",
                          marginTop: "8px",
                        }}
                      >
                        {t("Telephone")}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          disabled={bulkLoading}
                          //  type="number"
                          placeholder="e.g. +123 123 1231232"
                          autoComplete="none"
                          value={phoneNumber} // Use phoneNumber state here
                          fullWidth
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setPhoneNumber(inputValue);
                            const isValid = validatePhoneNumber(inputValue);
                            if (isValid) {
                              setValidPhoneNumber(false);
                            } else {
                              setValidPhoneNumber(true);
                            }

                            if (inputValue.length > 0) {
                              /*  if (
                                inputValue.length < 12 ||
                                inputValue.length > 14
                              ) {
                                setValidPhoneNumber(true);
                              } else {
                                setValidPhoneNumber(false);
                              } */
                            } else {
                              setValidPhoneNumber(false);
                            }
                          }}
                          error={validPhoneNumber}
                          helperText={
                            validPhoneNumber && t("Invalid_phone_number")
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                      <Typography
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          fontWeight: "700",

                          lineHeight: "20px",
                          marginTop: "15px",
                        }}
                      >
                        {t("Website")}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          disabled={bulkLoading}
                          type="text"
                          name="website"
                          placeholder="https://obenan.com/"
                          value={website}
                          fullWidth
                          onChange={(e) => {
                            const url = e.target.value;
                            setWebsite(url);
                            if (url !== "") {
                              const isValidUrl = urlRegexWebsite.test(url);
                              setUrlValidation(!isValidUrl);
                            } else {
                              setUrlValidation(false);
                            }
                          }}
                          error={urlValidation}
                          helperText={urlValidation && t("valid_url")}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12}>
                      <Typography
                        sx={{
                          color: "#1B2430",
                          fontSize: "14px",
                          fontWeight: "700",

                          lineHeight: "20px",
                          marginTop: "15px",
                        }}
                      >
                        {t("Email_Address_Buisness")}
                      </Typography>
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          disabled={bulkLoading}
                          type="email"
                          name="businessEmail"
                          placeholder="e.g.  john@obenan.com"
                          value={email}
                          fullWidth
                          onChange={(e) => {
                            const email = e.target.value;
                            if (email.length > 0) {
                              const emailRegex =
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                              const isValid = emailRegex.test(email);
                              setEmail(email);
                              setEmailValidation(!isValid);
                            } else {
                              setEmail(email);
                              setEmailValidation(false);
                            }
                          }}
                          error={emailValidation}
                          helperText={emailValidation ? t("invalid_email") : ""}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#1B2430",
                        lineHeight: "20px",
                        marginTop: "15px",
                      }}
                    >
                      {t("Default_Review")}
                    </Typography>
                    <Grid
                      container
                      sx={{
                        marginBottom: "1rem",
                        width: "100%",
                      }}
                    >
                      <Grid item sm={12} md={12}>
                        <Box sx={{ width: "100%" }}>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={languagesData}
                            sx={{ width: "100%" }}
                            getOptionLabel={(option) => option?.language}
                            className="Autocomplete-field"
                            // value={locationId}
                            value={selectLanguage?.language}
                            onChange={(event, value) => {
                              setSelectLanguage(value);
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder={t("Select_Language")}
                                {...params}
                                required
                                // error={languageRequired}
                                name="language"
                              />
                            )}
                          ></Autocomplete>
                          {/*  <Select
                            required
                            defaultValue={"en"}
                            style={{ width: "100%" }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectLanguage}
                            // label="Select"
                            onChange={(e, value) => {
                              setSelectLanguage(e.target.value);
                            }}
                          >
                            {languagesData.map((item) => (
                              <MenuItem value={item.value}>
                                {item.language}
                              </MenuItem>
                            ))}
                          </Select> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Divider />
              <Grid container spacing={1} sx={{ paddingTop: "10px" }}>
                <Grid item spacing={2} xs={12} md={12} mt={2}>
                  <Box sx={{ float: "right" }}>
                    {/*   <CommonButton
                      displayWhite="true"
                      label={t("Cancel")}
                      customHeight={true}
                      onSubmit={handleCloseBlukUpdateModel}
                    /> */}
                    <CommonButton
                      label={t("Back")}
                      marginLeft={true}
                      customHeight={true}
                      displayWhite="true"
                      onSubmit={() => {
                        // handleOpenFilterModel();
                        handleCloseBlukUpdateModel();
                        resetHandler();
                      }}
                    />

                    <CommonButton
                      //  isLoading={bulkLoading}
                      label={t("Submit")}
                      customHeight={true}
                      marginLeft={true}
                      disabled={
                        validPhoneNumber ||
                        urlValidation ||
                        emailValidation ||
                        (keywords?.length === 0 &&
                          (locationName === null || locationName === "") &&
                          (phoneNumber === "" || phoneNumber === null) &&
                          (website === "" || website === null) &&
                          (selectLanguage === "" || selectLanguage === null) &&
                          (email === null || email === ""))
                      }
                      onSubmit={handleOpenConfirmModel}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default BulkUpdateModel;
