import { Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import Modal from "@mui/material/Modal";
import { ReactComponent as NoRepeat } from "../../../../assets/Icons/NoRepeat.svg";
import { ReactComponent as Clock } from "../../../../assets/Icons/Clock.svg";
import { ReactComponent as AddLocation } from "../../../../assets/Icons/AdddLoaction.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ReactComponent as CallNow } from "../../../../assets/Icons/CallNow.svg";
import { ReactComponent as Repeat } from "../../../../assets/Icons/Repeat.svg";
import { ReactComponent as VertIcon } from "../../../../assets/Icons/VertIcon.svg";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";

import {
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Checkbox,
  tooltipClasses,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { api } from "../../../../contexts/JWTContext";
import { Dialog } from "@material-ui/core";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import EditPosts from "../EditPosts/index";
import { useTranslation } from "react-i18next";
// import LazyLoad from 'react-lazyload';
import { LazyLoadImage } from "react-lazy-load-image-component";
import blurImage from "../../../../assets/placeholder.png";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useStyles } from "../styles/style";
import useDateFormat from "../../../../hooks/useDateFormat";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function AllPostsCard({
  item,
  getAllposts,
  handlePostChecked,
  index,
  deletePostsArr,
  deletePermission,
  editPermission,
  viewPermission,
  setPageNumber,
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [totalTimesPosted, setTotalTimesPosted] = useState(0);
  const [failedPostCount, setFailedPostCount] = useState(0);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [deletePost, setDeletePost] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadImg, setLoadImg] = useState(false);
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (item?.postLocations?.length > 0) {
      let timesPosted = 0;
      let failedTimes = 0;
      item?.postLocations?.forEach((item) => {
        timesPosted = timesPosted + item?.count;
        failedTimes = failedTimes + item?.failCount;
      });
      setTotalTimesPosted(timesPosted);
      setFailedPostCount(failedTimes);
    }
  });

  const handleOpenModal = (id) => {
    setOpenModal(id);
    setAnchorEl(null);
  };
  const handleOpenDeleteModel = (id) => {
    setDeletePost(id);
    setOpen(id);
    setAnchorEl(null);
  };

  const handleCloseModal = () => {
    // setAnchorEl(null);
    setOpenModal(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const closeMenu = () => {};

  const deletePosts = async (id) => {
    try {
      const res = await api.delete(`/post/${id}`);
      if (res.status === 200) {
        getAllposts();
        toast.success("Post Deleted Successfully");
        setOpen(false);
        closeMenu(false);
      }
    } catch (error) {}
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "99vh",
    width: "720px",
    bgcolor: "background.paper",
    border: "2px solid gray",
    borderRadius: "8px",
    justifyContent: "space-around",
    overflow: "scroll",
    boxShadow: 24,
    p: 4,
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Delete_Post")}
          description={t("del_desc")}
          onConfirm={() => deletePosts(deletePost)}
          onCancel={handleClose}
        />
      </Dialog>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card className={classes.allPostCard}>
            <Box className={classes.allPostCardBox1}>
              <Box
                sx={{
                  display: "flex",
                }}
              ></Box>
              <Box className={classes.allPostCardBox2}>
                <Modal
                  open={openModal}
                  onClose={handleCloseModal}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <EditPosts
                      item={item}
                      onCancel={handleCloseModal}
                      getAllposts={getAllposts}
                      setPageNumber={setPageNumber}
                    />
                  </Box>
                </Modal>
              </Box>
            </Box>

            <Grid container spacing={2} sx={{ padding: "16px" }}>
              {!isMobile && (
                <Grid sm={12} md={0.5} lg={0.5} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      float: "right",
                    }}
                  >
                    <Checkbox
                      checked={
                        deletePostsArr?.length > 0 &&
                        deletePostsArr?.includes(item.id)
                      }
                      onChange={(e) => {
                        handlePostChecked(e, index, item.id, item.isPaused);
                      }}
                      label=""
                    />
                  </Box>
                </Grid>
              )}
              {isMobile && (
                <Grid
                  sm={12}
                  md={1}
                  lg={1}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Checkbox
                    checked={
                      deletePostsArr?.length > 0 &&
                      deletePostsArr?.includes(item.id)
                    }
                    onChange={(e) => {
                      handlePostChecked(e, index, item.id, item.isPaused);
                    }}
                    label=""
                  />

                  <IconButton
                    onClick={handleClick}
                    variant="outlined"
                    color="inherit"
                  >
                    <VertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {editPermission && viewPermission ? (
                      <MenuItem
                        onClick={() => {
                          closeMenu();
                          handleOpenModal(item?.id);
                        }}
                      >
                        <Typography>{t("Edit")}</Typography>
                      </MenuItem>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <MenuItem
                            disabled
                            onClick={() => {
                              closeMenu();
                              handleOpenModal(item?.id);
                            }}
                          >
                            <Typography>{t("Edit")}</Typography>
                          </MenuItem>
                        </span>
                      </BootstrapTooltip>
                    )}
                    {deletePermission && viewPermission ? (
                      <MenuItem
                        onClick={() => {
                          closeMenu();
                          handleOpenDeleteModel(item?.id);
                        }}
                      >
                        <Typography>{t("Delete")}</Typography>
                      </MenuItem>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <MenuItem
                            disabled
                            onClick={() => {
                              closeMenu();
                              handleOpenDeleteModel(item?.id);
                            }}
                          >
                            <Typography>{t("Delete")}</Typography>
                          </MenuItem>
                        </span>
                      </BootstrapTooltip>
                    )}
                  </Menu>
                </Grid>
              )}
              <Grid
                item
                sm={12}
                md={4}
                lg={2.5}
                xs={12}
                sx={{
                  display: "row",
                  justifyContent: "center",
                  paddingX: "14px",
                  height: "100%",
                }}
              >
                <LazyLoadImage
                  loading="lazy"
                  beforeLoad={handleBeforeLOad}
                  afterLoad={handleAfterLOad}
                  src={
                    loadImg
                      ? blurImage
                      : item?.postPicture?.map((item) => item.url)
                  }
                  style={{
                    borderRadius: "8px",
                    imagerendering: "pixelated;",
                  }}
                  width={"100%"}
                  height={200}
                  PlaceholderSrc={blurImage}
                  alt="Image Alt"
                  effect="blur"
                />
              </Grid>
              <Grid item sm={12} md={7} lg={8.5} xs={12}>
                <CardContent
                  sx={{ width: "100%", padding: isMobile ? "16px" : "0px" }}
                >
                  <Box
                    key={item.id}
                    sx={{ marginBottom: "8px", display: "flex" }}
                  >
                    {item?.isPaused === true ? (
                      <Chip
                        key="paused"
                        sx={{
                          bgcolor: "#1B2430",
                          color: "#ffff",
                          borderRadius: "58px",
                        }}
                        label={t("Paused")}
                      />
                    ) : (item?.repeat === "no" &&
                        totalTimesPosted === 0 &&
                        !failedPostCount > 0) ||
                      (item?.repeat === "no" && item?.status === "pending") ? (
                      <Chip
                        key="havent_posted"
                        sx={{
                          bgcolor: "#f7c24a",
                          color: "#ffff",
                          borderRadius: "58px",
                        }}
                        label={t("Haven't_Posted_Yet")}
                      />
                    ) : item?.repeat === "no" && item.status === "done" ? (
                      <Chip
                        key="posted"
                        sx={{
                          bgcolor: "#3dc3ff",
                          color: "#ffff",
                          borderRadius: "58px",
                        }}
                        label={t("Posted")}
                      />
                    ) : item?.repeat !== "no" && totalTimesPosted === 0 ? (
                      <Chip
                        key="yet_posted"
                        sx={{
                          bgcolor: "#f7c24a",
                          color: "#ffff",
                          borderRadius: "58px",
                        }}
                        label={t("Yet_To_be_Posted")}
                      />
                    ) : item?.repeat !== "no" && totalTimesPosted > 0 ? (
                      <Chip
                        key="postedd"
                        sx={{
                          bgcolor: "#3dc3ff",
                          color: "#ffff",
                          borderRadius: "58px",
                        }}
                        label={`${t("Posted")} ${
                          totalTimesPosted ? totalTimesPosted : "0"
                        } ${t("times")}`}
                      />
                    ) : null}
                    {failedPostCount > 0 && (
                      <Chip
                        key="failed"
                        sx={{
                          bgcolor: "#FC3652",
                          color: "#FFFFFF",
                          borderRadius: "58px",
                          marginLeft: "10px",
                        }}
                        label={`${t("Failedd")} ${failedPostCount} ${t(
                          "times"
                        )}`}
                      />
                    )}
                  </Box>

                  <Typography
                    sx={{
                      fontWeight: "700",
                      color: "#1B2430",
                      fontSize: "20px",
                      lineHeight: "28px",
                    }}
                  >
                    {item?.title}
                  </Typography>

                  <Typography
                    sx={{
                      lineHeight: "24px",
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#1B2430",
                      wordBreak: "break-all",
                    }}
                  >
                    {item?.content}
                  </Typography>

                  <Grid container spacing={4} sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={7} md={4} lg={4}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AddLocation />

                        <Typography
                          sx={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#1B2430",
                            paddingX: "4px",
                          }}
                        >
                          {t("Location")}
                          <Typography
                            variant="caption"
                            display="block"
                            sx={{
                              lineHeight: "20px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#495059",
                              paddingX: "4px",
                            }}
                          >
                            {item.postLocations.length > 1
                              ? `${t("Multi")}`
                              : `${t("Single")}`}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={7} md={4} lg={4}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Clock />

                        <Typography
                          sx={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#1B2430",
                            paddingX: "4px",
                          }}
                        >
                          {/* {companyData !== null
                            ? handleDisplayDate(item?.startDate)
                            : moment(item?.startDate).format("MMMM Do YYYY")} */}
                          {formatDate(item?.startDate)}

                          <Typography
                            variant="caption"
                            display="block"
                            sx={{
                              lineHeight: "20px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#495059",
                              paddingX: "4px",
                            }}
                          >
                            {moment(item?.startDate).format("ddd, LT")}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={7} md={4} lg={4}>
                      {item?.repeat === "no" ? (
                        <>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <NoRepeat />
                            <Typography
                              variant="caption"
                              display="flex"
                              sx={{
                                lineHeight: "20px",
                                fontSize: "14px",
                                fontWeight: "400",
                                color: "#495059",
                                paddingX: "4px",
                              }}
                            >
                              {t("No_Repeat")}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Repeat />
                            <Typography
                              sx={{
                                lineHeight: "24px",
                                fontSize: "16px",
                                fontWeight: "400",
                                color: "#1B2430",
                                paddingX: "4px",
                              }}
                            >
                              {t("Repeat")}: {item?.repeat}
                              <Typography
                                variant="caption"
                                display="block"
                                sx={{
                                  lineHeight: "20px",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#495059",
                                  paddingX: "4px",
                                }}
                              >
                                {companyData !== null
                                  ? item?.endDate !== null
                                    ? `${t("Ends_on")} ${formatDate(
                                        item?.endDate
                                      )}`
                                    : " "
                                  : formatDate(item?.startDate)}
                              </Typography>
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <CallNow />

                        {/* <Chip
                    label={item?.postType}
                    color="secondary"
                    variant="contained"
                    icon={<EditIcon />}
                  /> */}
                        <Typography
                          sx={{
                            lineHeight: "24px",
                            fontSize: "16px",
                            fontWeight: "400",
                            color: "#1B2430",
                            paddingX: "4px",
                          }}
                        >
                          {`${t("Google_CTA")}: ${
                            item?.postType !== null && item?.postType === "BOOK"
                              ? `${t("Book")}`
                              : item?.postType !== null &&
                                item?.postType === "SHOP"
                              ? `${t("Shop")}`
                              : item?.postType !== null &&
                                item?.postType === "LEARN_MORE"
                              ? `${t("Learn_More")}`
                              : item?.postType !== null &&
                                item?.postType === "ORDER"
                              ? `${t("OrderOnline")}`
                              : item?.postType !== null &&
                                item?.postType === "SIGN_UP"
                              ? `${t("Sign_Up")}`
                              : item?.postType !== null &&
                                item?.postType === "CALL"
                              ? `${t("CallNow")}`
                              : ""
                          }`}

                          <Typography
                            variant="caption"
                            display="block"
                            sx={{
                              lineHeight: "20px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#495059",
                              paddingX: "4px",
                              wordBreak: "break-all",
                            }}
                          >
                            {item?.url ? item?.url : ""}
                          </Typography>
                        </Typography>
                      </Box>
                    </Grid>

                    {item.status === "failed" && (
                      <Grid item sm={12} lg={12} md={12} xs={12}>
                        <Grid item sm={12} lg={10} md={10} xs={12}>
                          <Grid
                            container
                            className={classes.mainContainerPost}
                            onClick={() =>
                              navigate(`/user/allposts/logs/${item.id}`)
                            }
                          >
                            <Grid item xs={1} sm={1} md={0.5} lg={0.5}>
                              <ErrorOutlineIcon
                                className={classes.errorIconPost}
                              />
                            </Grid>
                            <Grid item xs={10} sm={10} md={11} lg={10.5}>
                              <div
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  width: "100%",
                                  paddingLeft: "10px",
                                }}
                              >
                                <Typography className={classes.Post}>
                                  {item?.errorMessage}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={1} sm={1} md={0.5} lg={1}>
                              <KeyboardArrowRightIcon
                                className={classes.arrowIconPost}
                                fontSize={"large"}
                                onClick={() =>
                                  navigate(`/user/allposts/logs/${item.id}`)
                                }
                              />{" "}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Grid>
              {!isMobile && (
                <Grid sm={12} md={0.5} lg={0.5} xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      float: "right",
                      mt: 2,
                      marginRight: "8px",
                    }}
                  >
                    <IconButton
                      onClick={handleClick}
                      variant="outlined"
                      color="inherit"
                    >
                      <VertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {editPermission && viewPermission ? (
                        <MenuItem
                          onClick={() => {
                            closeMenu();
                            handleOpenModal(item?.id);
                          }}
                        >
                          <Typography>{t("Edit")}</Typography>
                        </MenuItem>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <MenuItem
                              disabled
                              onClick={() => {
                                closeMenu();
                                handleOpenModal(item?.id);
                              }}
                            >
                              <Typography>{t("Edit")}</Typography>
                            </MenuItem>
                          </span>
                        </BootstrapTooltip>
                      )}

                      {deletePermission && viewPermission ? (
                        <MenuItem
                          onClick={() => {
                            closeMenu();
                            handleOpenDeleteModel(item?.id);
                          }}
                        >
                          <Typography>{t("Delete")}</Typography>
                        </MenuItem>
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <MenuItem
                              disabled
                              onClick={() => {
                                closeMenu();
                                handleOpenDeleteModel(item?.id);
                              }}
                            >
                              <Typography>{t("Delete")}</Typography>
                            </MenuItem>
                          </span>
                        </BootstrapTooltip>
                      )}
                      <MenuItem
                        onClick={() => {
                          closeMenu();
                          navigate(`/user/allposts/logs/${item.id}`);
                        }}
                      >
                        <Typography>{t("Logs")}</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default AllPostsCard;
