import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Divider,
  styled,
  Button,
  Grid,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import { api } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
import ProfilePictureModal from "./Components/pictureModal";
import TitleHeader from "../../../../components/CustomComponents/title";
import PostCard from "./addPostCard";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/style";
import dayjs from "dayjs";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const AccountsOverview = () => {
  const location = useLocation();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState([]);
  const [openPictureModal, setOpenPictureModal] = useState(false);
  const [showEditAll, setShowEditAll] = useState(false);
  const [checkRequired, setCheckRequired] = useState(false);

  const [bulkStartDate, setBulkStartDate] = useState(null);
  const locationId = location.state?.location?.id;
  const [startDateRequired, setStartDateRequired] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [endDateGreater, setEndDateGreater] = useState(false);
  const [ValidDate, setValidDate] = useState(true);
  const [EndValidDate, setEndValidDate] = useState(true);
  const [openCropModal, setOpenCropModal] = useState(false);
  const [selectedPostData, setSelectedPostData] = useState(null);
  const [allLocation, setAllLocation] = useState([]);
  const [loctionLoadings, setLoctionLoadings] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [defaultLocationByDefault, setDefaultLocationByDefault] =
    useState(null);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    setOpenPictureModal(true);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (user) {
      setLoading(false);
    }
  }, [user]);
  useEffect(() => {
    getAllLocations();
  }, []);

  useEffect(() => {
    if (allLocation?.results?.length > 0) {
      const defaultLocationObj = allLocation?.results?.find(
        (item) => item?.defaultLocation !== null
      );
      if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
        setDefaultLocation(defaultLocationObj);

        setDefaultLocationByDefault(defaultLocationObj);
      } else {
        setDefaultLocation(allLocation?.results[0]);

        setDefaultLocationByDefault(allLocation?.results[0]);
      }
    }
  }, [allLocation]);

  // ** Change handler for all changes
  /*   const changeHandler = (index, itemName, value) => {
    if (index !== undefined) {
      tempPostData[index][`${itemName}`] = value;

      setPostData(tempPostData);
    } else {
      tempPostData?.map((item) => {
        item[`${itemName}`] = value;
      });
       
      setPostData(tempPostData);
    }
  };
 */
  const getAllLocations = async () => {
    try {
      setLoctionLoadings(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/googleLinkedlocations`);

      if (res.status === 200) {
        setAllLocation(res.data.data);
        setLoctionLoadings(false);
      }
    } catch (error) {
      setAllLocation([]);
      setLoctionLoadings(false);
    }
  };
  const changeHandler = (index, itemName, value, fromCustom) => {
    setPostData((prevPostData) => {
      const updatedPostData = [...prevPostData]; // Create a copy of the state array

      if (fromCustom) {
        if (index !== undefined) {
          updatedPostData[index] = {
            ...updatedPostData[index],
            customSchedule: {
              ...updatedPostData[index].customSchedule,
              [itemName]: value,
            },
          };
          setSelectedPostData(updatedPostData[index].customSchedule);
        } else {
          updatedPostData.forEach((item) => {
            item.customSchedule = {
              ...item.customSchedule,
              [itemName]: value,
            };
          });
        }
      } else {
        if (index !== undefined) {
          updatedPostData[index] = {
            ...updatedPostData[index],
            [itemName]: value,
          };
        } else {
          updatedPostData.forEach((item) => {
            item[itemName] = value;
          });
        }
      }
      return updatedPostData; // Return the updated state
    });
  };

  const changeHandlerBulkDateUpdate = (dateTime, timeInterval) => {
    let tempPostData = [...postData];
    let initialDate = dateTime;
    if (dateTime !== null) {
      setStartDateRequired(false);
    }
    tempPostData?.forEach((item) => {
      item[`startDate`] = initialDate;
      setBulkStartDate(dateTime);
      initialDate = dayjs(initialDate).add(timeInterval, "m").toDate();
    });

    setPostData(tempPostData);
  };
  const handleClickCloseWarning = (index) => {
    let tempPostData = [...postData];
    let temmpObj = tempPostData[index];
    temmpObj["errorUberAll"] = false;
    tempPostData[index] = temmpObj;
    setPostData(tempPostData);
  };
  const handleChangeDescription = (e, index) => {
    changeHandler(index, "content", e.target.value);
  };
  const handleChangeAIDescription = (e, index) => {
    changeHandler(index, "content", e);
  };
  const handleChangeAIDescriptionForBulk = (e, index) => {
    changeHandler(index, "content", e);
  };

  const handleChangeAIDescriptionForBulkTwo = (e, index) => {
    if (e.length > 0) {
      e.forEach((content, indexValue) => {
        let processedContent = content;

        if (processedContent.startsWith("\n")) {
          processedContent = processedContent.trimStart();
        }

        if (
          processedContent.startsWith('"') &&
          processedContent.endsWith('"')
        ) {
          processedContent = processedContent.slice(1, -1);
        }

        changeHandler(indexValue, "content", processedContent);
      });
    }
  };

  const handleChangeTitle = (e, index) => {
    changeHandler(index, "title", e.target.value);
  };

  const handleChangeStartDate = (e, index) => {
    setBulkStartDate(null);
    changeHandler(index, "startDate", e);
  };
  const handleChangeEndDate = (e, index) => {
    changeHandler(index, "endDate", e);
  };

  const handleChangeRepeatPost = (e, index) => {
    changeHandler(index, "repeat", e);
  };

  const handleChangeFrequency = (e, index) => {
    changeHandler(index, "frequency", e, true);
  };

  const handleChangeOccurance = (e, index) => {
    changeHandler(index, "occurance", parseInt(e), true);
  };
  const handleChangeCustomDates = (e, index) => {
    changeHandler(index, "dates", e, true);
  };

  const handleChangeCustomDays = (e, index) => {
    changeHandler(index, "days", e, true);
  };

  const handleChangeBusinessCta = (e, index) => {
    changeHandler(index, "postType", e.target.value);
    if (e.target.value === "CALL" && index) {
      let tempPostData = [...postData];
      let tempPostObj = tempPostData[index];
      tempPostObj["url"] = "";
      tempPostData[index] = tempPostObj;
      setPostData(tempPostData);
    }
  };

  const handleChangeButtonUrl = (e, index) => {
    changeHandler(index, "url", e.target.value);
  };
  const handleChangeLocation = (e, index, locations) => {
    // changeHandler(index, "locationId", e);
    if (e?.length > 0) {
      setLocationRequired(false);
    }
    if (locations?.length > 0) {
      const defaultLocationObj = locations?.find(
        (item) => item?.defaultLocation !== null
      );
      if (defaultLocationObj !== undefined && defaultLocationObj !== null) {
        handleMakeDefaultLocation(defaultLocationObj, index);
      } else {
        handleMakeDefaultLocation(locations[0], index);
      }
    }

    changeHandler(index, "locationIds", e);
    //  changeHandler(index, "content", "");
  };
  const handleChangeLocationData = (e, index) => {
    // changeHandler(index, "locationId", e);
    changeHandler(index, "locationData", e);
    // changeHandler(index, "content", "");
  };
  const handleMakeDefaultLocation = (e, index) => {
    changeHandler(index, "defaultLocation", e);
    // changeHandler(index, "content", "");
  };
  const handleChangeCheckbox = (e, index) => {
    changeHandler(index, "isUploadPicture", e.target.checked);
  };

  const handleChangeCheckboxUberAll = (e, index) => {
    changeHandler(index, "uploadUberAll", e.target.checked);
  };

  const handleDeletePost = (e, index) => {
    const filteredPosts = postData?.filter((post, indx) => {
      return indx !== index;
    });
    setPostData(filteredPosts);
  };

  const handleShowEditAll = () => {
    setShowEditAll(!showEditAll);
  };

  const handleClosePictureModal = () => {
    postData.sort(function (a, b) {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      // Function to check if a string starts with a number
      const startsWithNumber = (str) => /^\d/.test(str);

      if (startsWithNumber(nameA) && startsWithNumber(nameB)) {
        // Both names start with numbers, so compare them numerically
        return parseInt(nameA) - parseInt(nameB);
      } else if (!startsWithNumber(nameA) && !startsWithNumber(nameB)) {
        // Both names start with alphabets, so compare them lexicographically
        return nameA.localeCompare(nameB);
      } else if (startsWithNumber(nameA)) {
        return -1; // Image with a name starting with a number comes first
      } else {
        return 1; // Image with a name starting with an alphabet comes second
      }
    });

    setOpenPictureModal(false);
  };

  const handleCloseNavigate = () => {
    // alert("here");
    navigate(-1);
  };

  const handleProfilePictureUpload = async (formData) => {
    if (user) {
    }
  };

  const cancelHandler = () => {
    navigate("/user/allposts", {
      state: { locationId: location.state?.location },
    });
    // setDisplayAllPosts(true);
  };

  const addpostbutton = () => {
    // navigate("/user/allposts", {
    //   state: { row }
    // });
    navigate("/user/allposts");
  };
  const postsHandler = async () => {
    setSubmitted(true);
    let objjj = JSON.stringify(postData);

    const filteredArr = JSON.parse(objjj);
    let tempRequiredCount = 0;
    filteredArr.forEach((item) => {
      if (
        item?.startDate === null ||
        item?.locationIds === "" ||
        item?.locationIds?.length === 0
        // item?.content===""
      ) {
        tempRequiredCount++;
      }
      if (item?.postType !== "CALL") {
        if (item?.url === "") {
          tempRequiredCount++;
        }
      }
    });

    if (tempRequiredCount === 0) {
      setIsLoading(true);
      // if (!"title") {
      //   setTitleRequired(true);
      // }
      try {
        setIsLoading(true);

        let postArr = [];
        if (filteredArr?.length > 0) {
          filteredArr?.forEach((item) => {
            delete item?.defaultLocation;
            delete item["name"];
            delete item["errorUberAll"];
            postArr.push(item);
          });
        }

        const res = await api.post(`/post`, { data: postArr });

        if (res.status === 200 || res.status === 201) {
          toast.success(res?.data?.message);

          // navigate("/user/posts");
          // setDisplayAllPosts(true);

          setIsLoading(false);
          navigate("/user/allposts", {
            state: { locationId: location.state?.location },
          });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      setCheckRequired(!checkRequired);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            title={t("Add_Post")}
            subHeading={t("post_subHeading")}
          />
          <Paper className={classes.postBackground}>
            {postData.length === 0 && (
              <Grid container>
                <Grid sm={2} md={4}></Grid>
                <Grid xs={12} sm={8} md={4}>
                  <Box sx={{ justifyContent: "center", mt: 6 }}>
                    <Card className={classes.postCard} centered>
                      <CardContent>
                        <Typography variant="h3" sx={{ mt: 4 }}>
                          {t("No_more_posts")}
                        </Typography>

                        <Typography
                          className={classes.postTypoMain}
                          color="text.secondary"
                        >
                          {t("Post_subtext")}
                        </Typography>
                        <CardActions className={classes.postTypoMain}>
                          <CommonButton
                            label={t("Add_Posts_button")}
                            onSubmit={addpostbutton}
                          />
                        </CardActions>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
                <Grid sm={2} md={4}></Grid>
              </Grid>
            )}

            {postData?.length < 0 && (
              <Typography className={classes.noPost}>
                {t("No_more_posts")}
              </Typography>
            )}

            {postData?.length > 0 && (
              <>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#FFFFFF",
                    width: "1,532px",
                    textAlign: "center",
                    backgroundColor: "#0638C1",
                    paddingY: "14px",
                    // paddingX: "20px",
                    borderRadius: "8px",
                    marginTop: "24px",
                    // marginX: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleShowEditAll}
                >
                  {t("Post_Desc")}
                </Typography>
              </>
            )}

            {showEditAll && postData.length > 0 && (
              <Box
                // className={classes.editPostCard}
                sx={{
                  border: "3px solid blue",
                  paddingX: "14px",
                  borderRadius: "8px",
                }}
              >
                <Grid container sx={{ marginTop: "8px", marginBottom: "14px" }}>
                  <Grid item md={12} xs={12}>
                    <PostCard
                      postData={postData}
                      handleChangeStartDate={handleChangeStartDate}
                      handleChangeEndDate={handleChangeEndDate}
                      handleChangeRepeatPost={handleChangeRepeatPost}
                      handleChangeBusinessCta={handleChangeBusinessCta}
                      handleChangeCheckbox={handleChangeCheckbox}
                      handleChangeCheckboxUberAll={handleChangeCheckboxUberAll}
                      handleChangeButtonUrl={handleChangeButtonUrl}
                      handleChangeDescription={handleChangeDescription}
                      handleChangeAIDescription={handleChangeAIDescription}
                      handleChangeLocation={handleChangeLocation}
                      handleChangeTitle={handleChangeTitle}
                      handleDeletePost={handleDeletePost}
                      showEditAll={showEditAll}
                      bulkStartDate={bulkStartDate}
                      setShowEditAll={setShowEditAll}
                      startDateRequired={startDateRequired}
                      changeHandlerBulkDateUpdate={changeHandlerBulkDateUpdate}
                      handleChangeLocationData={handleChangeLocationData}
                      handleChangeAIDescriptionForBulk={
                        handleChangeAIDescriptionForBulk
                      }
                      handleChangeAIDescriptionForBulkTwo={
                        handleChangeAIDescriptionForBulkTwo
                      }
                      setLocationRequired={setLocationRequired}
                      locationRequired={locationRequired}
                      endDateGreater={endDateGreater}
                      setEndDateGreater={setEndDateGreater}
                      handleMakeDefaultLocation={handleMakeDefaultLocation}
                      setValidDate={setValidDate}
                      ValidDate={ValidDate}
                      setEndValidDate={setEndValidDate}
                      EndValidDate={EndValidDate}
                      setSelectedPostData={setSelectedPostData}
                      selectedPostData={selectedPostData}
                      handleChangeFrequency={handleChangeFrequency}
                      handleChangeOccurance={handleChangeOccurance}
                      handleChangeCustomDates={handleChangeCustomDates}
                      handleChangeCustomDays={handleChangeCustomDays}
                      allLocation={allLocation}
                      loctionLoadings={loctionLoadings}
                      defaultLocation={defaultLocation}
                      setDefaultLocation={setDefaultLocation}
                      defaultLocationByDefault={defaultLocationByDefault}
                      setDefaultLocationByDefault={setDefaultLocationByDefault}
                      submitted={submitted}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}

            {!openPictureModal &&
              postData?.map((data, index) => (
                <Stack direction="column">
                  <PostCard
                    index={index}
                    data={data}
                    postData={postData}
                    handleChangeStartDate={handleChangeStartDate}
                    handleChangeEndDate={handleChangeEndDate}
                    handleChangeRepeatPost={handleChangeRepeatPost}
                    handleChangeCheckbox={handleChangeCheckbox}
                    handleChangeCheckboxUberAll={handleChangeCheckboxUberAll}
                    handleChangeBusinessCta={handleChangeBusinessCta}
                    handleChangeButtonUrl={handleChangeButtonUrl}
                    handleChangeLocation={handleChangeLocation}
                    handleChangeDescription={handleChangeDescription}
                    handleChangeAIDescription={handleChangeAIDescription}
                    handleChangeTitle={handleChangeTitle}
                    handleDeletePost={handleDeletePost}
                    checkRequired={checkRequired}
                    setBulkStartDate={setBulkStartDate}
                    startDateRequired={startDateRequired}
                    setStartDateRequired={setStartDateRequired}
                    setLocationRequired={setLocationRequired}
                    locationRequired={locationRequired}
                    endDateGreater={endDateGreater}
                    setEndDateGreater={setEndDateGreater}
                    handleMakeDefaultLocation={handleMakeDefaultLocation}
                    setValidDate={setValidDate}
                    ValidDate={ValidDate}
                    setEndValidDate={setEndValidDate}
                    EndValidDate={EndValidDate}
                    handleClickCloseWarning={handleClickCloseWarning}
                    setSelectedPostData={setSelectedPostData}
                    selectedPostData={selectedPostData}
                    handleChangeFrequency={handleChangeFrequency}
                    handleChangeOccurance={handleChangeOccurance}
                    handleChangeCustomDates={handleChangeCustomDates}
                    handleChangeCustomDays={handleChangeCustomDays}
                    setPostData={setPostData}
                    allLocation={allLocation}
                    loctionLoadings={loctionLoadings}
                    defaultLocation={defaultLocation}
                    setDefaultLocation={setDefaultLocation}
                    defaultLocationByDefault={defaultLocationByDefault}
                    setDefaultLocationByDefault={setDefaultLocationByDefault}
                    submitted={submitted}
                  />
                  <Divider />
                </Stack>
              ))}
            <Stack direction="row" className={classes.postStack}>
              {postData?.length > 0 && (
                <Item>
                  <Button
                    className={classes.postcancelButton}
                    variant="outlined"
                    onClick={() => {
                      cancelHandler();
                    }}
                  >
                    {t("Cancel")}
                  </Button>
                  &nbsp; &nbsp;
                  <CommonButton
                    isLoading={isLoading}
                    label={t("Add_Posts")}
                    onSubmit={postsHandler}
                    disabled={postData.find(
                      (item) =>
                        item?.repeat === "custom" &&
                        (item?.customSchedule?.occurance <= 0 ||
                          (item?.customSchedule?.frequency === "monthly" &&
                            item?.customSchedule?.dates?.length === 0) ||
                          (item?.customSchedule?.frequency === "weekly" &&
                            item?.customSchedule?.days.length === 0))
                    )}
                    // disabled={
                    //   postData[0]?.content?.length >= 1500 ||
                    //   ValidDate === false ||
                    //   EndValidDate === false ||
                    //   endDateGreater
                    // }
                  />
                </Item>
              )}
            </Stack>
          </Paper>
          <ProfilePictureModal
            locationId={locationId}
            handleClose={handleClosePictureModal}
            open={openPictureModal}
            loading={loading}
            handlePictureUpload={handleProfilePictureUpload}
            setPostData={setPostData}
            handleCloseNavigate={handleCloseNavigate}
            postData={postData}
            openCropModal={openCropModal}
            setOpenCropModal={setOpenCropModal}

            // images={images}
            // setImages={setImages}
          />
        </>
      )}
    </>
  );
};

export default AccountsOverview;
