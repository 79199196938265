import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  DialogContent,
  Menu,
  MenuItem,
  Select,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LanguageChange } from "../../../../utils/LocalStorageHelpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import tick from "../../../../assets/images/tick.jpg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";

import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import moment from "moment";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import Dialog from "@mui/material/Dialog";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import TitleHeader from "../../../../components/CustomComponents/title";
import ExportLanguageModal from "../../UserReports/Modal/ExportLanguageModal";
import NotFound from "../../../../components/NotFound/NotFound";
import { languagesData } from "../../../../assets/defaultLanguage";
import useAuth from "../../../../hooks/useAuth";
import { useStyles } from "../styles/style";
import NoLocationFound from "../../../../components/CustomComponents/Errors/NoLocationFound";
import { languageOptions } from "../../../../assets/defaultLanguage";

const Paper = styled(MuiPaper)(spacing);

const MonthlyReportUser = () => {
  const classes = useStyles();

  const navigate = useNavigate();
  const { signOut, permissionsAcess, user } = useAuth();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [companyReports, setCompanyReports] = useState("");
  const companyDataId = JSON.parse(localStorage.getItem("company"));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [deleteReport, setDeleteReport] = useState([]);
  const [openRequest, setOpenRequest] = React.useState(false);

  const [selectedReport, setSelectedReport] = useState(null);
  const [selectLanguageFilter, setSelectLanguageFilter] = useState("en");
  const [openSubmit, setOpenSubmit] = useState(false);
  const [isExportError, setIsExportError] = useState();
  const [allLocation, setAllLocation] = useState("");
  // const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);
  //  const [deletePermission, setDeletePermission] = useState(false);
  const languageData = localStorage.getItem("i18nextLng");
  const stateData = JSON.parse(localStorage.getItem("user"));
  let selectedLanguage = "";
  let userLang = "";
  selectedLanguage = languageOptions[userLang];

  useEffect(() => {
    if (
      languageData !== undefined &&
      languageData !== undefined &&
      stateData !== undefined &&
      stateData !== null
    ) {
      getSelectedCustomer();
      if (languageData !== "en" || stateData?.language === null) {
        LanguageChange();
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/reports"
      );

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        /*   if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        } */
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    getAllLocations();
  }, []);
  useEffect(() => {
    getAllReports();
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (selectLanguageFilter !== "") {
      getAllReports();
    }
  }, [selectLanguageFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
      }
    } catch (error) {}
  };

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });

      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setAllLocation(res.data.data);
        } else {
          setAllLocation(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllLocation(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /*  const handleUpdatedReport = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/company/update`, {
        reportingEnabled: "requested",
      });
      getCompanyData();
      if (res.status === 200) {
        toast.success("Request sent successfully");

        //  setSelectAll(false);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Request sent failed");
    }
  }; */

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllReports = async (id) => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `company/${companyDataId?.id}/reports?limit=10&page=${
          page + 1
        }&language=${selectLanguageFilter}`
      );

      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setCompanyReports(res.data.data);
        } else {
          setCompanyReports(null);
        }

        setIsLoading(false);
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(error?.response?.data?.message);
        handleSignOut();
      } else {
        setCompanyReports(null);
        //  setExportErrorMessage(true);
      }
      setIsLoading(false);

      setIsExportError(error?.response?.data?.data);
    }
  };

  const closeMenu = () => {
    // setAnchorMenu(null);
  };

  const handleClose = () => {
    //  setOpenModal(false);
    // setOpenDeleteModal(false);
    setOpen(false);
    setAnchorEl(null);
  };
  const handleOpenModel = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleDownloadPdf = (row) => {
    window.location.assign(row?.fileObject?.location);
  };

  const handleOpenNewRequestModel = () => {
    setOpenRequest(true);
  };
  const handleCloseNewRequestModel = () => {
    setOpenRequest(false);
    //  setSelectedLocation([]);
  };

  const handleLanguageChange = (e) => {
    //
    setSelectLanguageFilter(e.target.value);
    // getCompanyData();
  };
  const onCloseSentReqModal = () => {
    setOpenSubmit(false);
    setOpenRequest(false);
    setSelectedReport(null);
    getAllReports();
  };

  // function handleDisplayLanguage(lang) {
  //   let langName = "";
  //   const langs = languagesData?.find((item) => item?.value === lang);
  //   if (langs !== undefined && langs !== null) {
  //     langName = langs?.language;
  //   }
  //   return langName;
  // }

  function displayMonth(monthNum) {
    let MonthName = "";
    if (monthNum === "1") {
      MonthName = "Jan";
    } else if (monthNum === "2") {
      MonthName = "Feb";
    } else if (monthNum === "3") {
      MonthName = "Mar";
    } else if (monthNum === "4") {
      MonthName = "Apr";
    } else if (monthNum === "5") {
      MonthName = "May";
    } else if (monthNum === "6") {
      MonthName = "Jun";
    } else if (monthNum === "7") {
      MonthName = "Jul";
    } else if (monthNum === "8") {
      MonthName = "Aug";
    } else if (monthNum === "9") {
      MonthName = "Sep";
    } else if (monthNum === "10") {
      MonthName = "Oct";
    } else if (monthNum === "11") {
      MonthName = "Nov";
    } else if (monthNum === "12") {
      MonthName = "Dec";
    }
    return MonthName;
  }
  return (
    <>
      {openRequest && (
        <ExportLanguageModal
          title={t("export_reports")}
          open={openRequest}
          handleCloseNewRequestModel={handleCloseNewRequestModel}
          reportsId={companyDataId?.id}
          setOpenRequest={setOpenRequest}
          openRequest={openRequest}
          companyReports={companyReports}
          getAllReports={getAllReports}
          setSelectLanguageFilter={setSelectLanguageFilter}
          onCloseSentReqModal={onCloseSentReqModal}
          openSubmit={openSubmit}
          setOpenSubmit={setOpenSubmit}
          selectedReport={selectedReport}
          setSelectedReport={setSelectedReport}
        />
      )}
      <TitleHeader
        title={t("Reports")}
        subHeading={t("Here_are_your_monthly_reports")}
        // extraButton={true}
        reportsExportButton={false} // Temporary disable button
        extraButtonName={t("export")}
        addPermission={addPermission}
        viewPermission={viewPermission}
        handleDefaultTemplate={handleOpenNewRequestModel}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allLocation !== null &&
          allLocation !== "" &&
          allLocation?.results?.length > 0 ? (
            <>
              {/*   {companyReports !== null &&
                companyReports !== "" &&
                companyReports?.results?.length > 0 && ( */}
              <Box className={classes.toast}>
                <Box className={classes.toastBox}>
                  <Box className={classes.toastBoxBorder}></Box>
                  <Box className={classes.toastBoxContent}>
                    <Box className={classes.toastBoxText}>
                      <InfoOutlinedIcon sx={{ color: "#06BDFF" }} />
                      <Box sx={{ display: "flex" }}>
                        <Typography>{t("reports_info")}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/*      )} */}
              <Grid item sm={12} md={12} lg={12}>
                <Select
                  required
                  style={{
                    width: "100%",

                    backgroundColor: "white",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectLanguageFilter}
                  onChange={handleLanguageChange}
                >
                  {languagesData?.map((item) => (
                    <MenuItem value={item.value}>{item.language}</MenuItem>
                  ))}
                </Select>
              </Grid>
              {deleteReport.length > 0 && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    className={classes.editMainGrid}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      sx={{
                        color: "white",
                        backgroundColor: "#FC3652",
                        "&:hover": {
                          backgroundColor: "#ff3366",
                          cursor: "pointer",
                        },
                      }}
                      // onClick={handleOpenDeleteModel}
                    >
                      {t("Delete")}
                    </Button>
                  </Grid>
                </Grid>
              )}
              {companyReports !== null &&
              companyReports !== "" &&
              companyReports?.results?.length > 0 ? (
                <>
                  <Grid container spacing={8} sx={{ marginTop: "1rem" }}>
                    <TableContainer
                      borderRadius="12"
                      component={Paper}
                      marginLeft="2rem"
                    >
                      <Table aria-label="simple table">
                        <CustomizedTableHead variant="h1">
                          <CustomizedTableRow>
                            {/* <CustomizedTableCell align="start">
                          <Checkbox
                            checked={selectAll}
                            onChange={(e) => handleChangeCheckButton(e)}
                          />
                        </CustomizedTableCell> */}
                            {/* <CustomizedTableCell align="start">
                          {t("IDD")}
                        </CustomizedTableCell> */}
                            <CustomizedTableCell>
                              {t("month_text_reports")}
                            </CustomizedTableCell>
                            <CustomizedTableCell>
                              {t("year")}
                            </CustomizedTableCell>
                            <CustomizedTableCell>
                              {t("Date_Created")}
                            </CustomizedTableCell>
                            <CustomizedTableCell>
                              {t("langs")}
                            </CustomizedTableCell>
                            {/*   <CustomizedTableCell>
                          {t("Date_sent")}
                        </CustomizedTableCell> */}

                            <CustomizedTableCell>
                              {t("Actionss")}
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        </CustomizedTableHead>
                        <TableBody>
                          {companyReports?.results?.map((row, index) => (
                            <CustomizedTableRow>
                              {/* <CustomizedTableCell hover>
                            <Checkbox
                              checked={deleteReport.includes(row.id)}
                              onChange={(e) => {
                                handleReportChecked(e, index, row.id);
                              }}
                              label=""
                            />
                          </CustomizedTableCell> */}
                              {/* <CustomizedTableCell hover align="start">
                            {" "}
                            {"12345"}
                          </CustomizedTableCell> */}
                              <CustomizedTableCell>
                                {displayMonth(row?.month)}
                              </CustomizedTableCell>
                              <CustomizedTableCell>
                                {moment(row?.year).format("YYYY")}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {moment(row?.createdAt).format("MMM DD YYYY")}
                              </CustomizedTableCell>
                              <CustomizedTableCell>
                                {/* {handleDisplayLanguage(row.language)} */}
                                {
                                  languagesData?.find(
                                    (item) => item?.value === row?.language
                                  )?.language
                                }
                              </CustomizedTableCell>

                              {/*   <CustomizedTableCell>
                            {moment(row?.sendAt).format("MMM DD YYYY")}
                          </CustomizedTableCell> */}

                              <CustomizedTableCell align="end">
                                <IconButton
                                  onClick={() => handleDownloadPdf(row)}
                                  variant="outlined"
                                >
                                  <FileDownloadOutlinedIcon />
                                </IconButton>
                                {/* <IconButton
                              onClick={(e) => handleClick(e, row?.id)}
                              variant="outlined"
                            >
                              <MoreHorizIcon />
                            </IconButton> */}
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();

                                      handleOpenModel();
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        color: "#545353",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <Typography
                                      aria-label="delete"
                                      size="large"
                                    >
                                      {t("Delete")}
                                    </Typography>
                                  </MenuItem>
                                </Menu>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={companyReports?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : companyReports === null ? (
                <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
                  <NotFound text={t("No_Report_Found")} />
                  {isExportError?.count > 0 ? (
                    <Typography
                      sx={{ textAlign: "center", marginTop: ".6rem" }}
                    >
                      {" "}
                      {t("export_message")}
                    </Typography>
                  ) : (
                    ""
                  )}
                </Box>
              ) : null}
            </>
          ) : allLocation === null ? (
            <Box className={classes.locationbox}>
              <NoLocationFound
                text={t("No_Location_Added")}
                subtext={t("add_location_text")}
              />
            </Box>
          ) : null}
        </>
      )}

      {openSubmit && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "10px",
              borderRadius: "8px",
            },
          }}
          open={openSubmit}
          onClose={onCloseSentReqModal}
        >
          <Box sx={{ textAlign: "right" }}>
            {" "}
            <IconButton
              onClick={() => onCloseSentReqModal()}
              aria-label="save"
              hover="none"
              sx={{
                hover: "none",
              }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <img alt="" src={tick} width="80px" height="80px" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "30px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {t("sent_req")}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "23px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${t("msg11")} ${moment(selectedReport?.month).format(
                    "MMM"
                  )} ${t("msg22")}`}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("delete_report")}
            description={t("confirm_del")}
            onConfirm={handleClose}
            onCancel={handleClose}
          />
        </Dialog>
      )}
    </>
  );
};

export default MonthlyReportUser;
