import React, { useState, useEffect } from "react";

import Divider from "@mui/material/Divider";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";
import Chip from "@mui/material/Chip";
import FilterModal from "../../../../components/Models/GoogleStatsFilterModel";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  MenuItem,
  Tooltip,
  tooltipClasses,
  TextField,
  Autocomplete,
  IconButton,
  Dialog,
  Button,
  Menu,
  Checkbox,
  createFilterOptions,
} from "@mui/material";
import styled from "styled-components/macro";
import { ReactComponent as AddGroup } from "../../../../assets/images/AddGroup.svg";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";

import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";

import { useTheme } from "@mui/material/styles";
import { useStyles } from "../styles/styles";

// import DatePickerComponent from "../EmotionAi/Components/RangePicker";
import RangePickerResults from "./RangePickerResults";

const Paper = styled(MuiPaper)(spacing);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    spacing: 4,
    textAlign: "justfied",
    backgroundColor: "#E0F7FF",
    color: "#1B2430",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "400",
    minWidth: 390,
  },
});

function FilterHeader({
  countries,
  handleChangeCountries,
  selectAllCountries,
  setSelectAllCountries,
  locationViewPermission,
  locationAddPermission,
  handleNavigationToGroups,
  allGroups,
  handleChangeGroups,
  allLocations,
  handleChangeLocation,
  location,
  setSelectedCountries,
  selectedCountries,
  selectedGroups,
  setSelectedGroups,
  handleChangeStartDate,
  startDate,
  previousYear,
  endDate,
  dateRange,
  oneYearAgoDate,
  setCallInsightsApis,
  handleChangeEndDate,
  setDateRange,
  handleOpenFilterModel,
  handleCloseFilterModel,
  openFilterModel,
  resetStart,
  resetEnd,
  setLocation,
  setCallApi,
  defaultLocation,
  checkGroupsExistence,
  checkCountriesExistence,
  resetHandler,
  selectAllLocations,
  setSelectAllLocations,
  selectAllGroups,
  setSelectAllGroups,
  oldCountries,
  oldGroup,
  getCountryWiseLocations,
  handleSubmitFilters,
  handleOpenGoogleModel,
  handleOpenGoogleRetryModel,
  setEmailBody,
  disabledStartDate,
  setDisabledStartDateSchedule,
  subscriptionDate
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const filter = createFilterOptions();
  const [autoSelectHeader, setAutoSelectHeader] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const handleChangeGroupsForLocations = (locations) => {
    if (locations?.length > 0 || selectedGroups?.length > 0) {
      const filteredArray2 = selectedGroups?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );
      setSelectAllGroups(false);
      setSelectedGroups(filteredArray2 || []);
      // setLocationError(false);
    } else {
      setSelectedGroups([]);
    }
  };
  const handleChangeCountriesForLocations = (locations) => {
    if (locations?.length > 0 || selectedCountries?.length > 0) {
      const filteredArray2 = selectedCountries?.filter((item) =>
        item?.locationsArray
          ? checkCountriesExistence(item?.locationsArray, locations)
          : true
      );
      setSelectedCountries(false);
      setSelectedCountries(filteredArray2 || []);
      // setLocationError(false);
    } else {
      setSelectedCountries([]);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3.5} sx={{ paddingTop: "8px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {" "}
              {allLocations?.length > 0 && (
                <>
                  <Autocomplete
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="combo-box-demo"
                    options={allLocations?.length > 0 ? allLocations : []}
                    getOptionDisabled={(option) => {
                      if (option?.id === 0) {
                        return false;
                      }

                      if (
                        option?.socialLink?.length > 0 &&
                        option?.status === "approved" &&
                        option?.socialLink?.find(
                          (item) =>
                            item?.type === "google" &&
                            item?.statusOfGoogleInsight === "Done"
                        ) !== undefined
                      ) {
                        return false;
                      }
                      return true;
                    }}
                    getOptionLabel={(option) =>
                      option?.internalName
                        ? option?.internalName +
                        " " +
                        ((option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null) ||
                          (option?.city !== "" && option?.city !== null)
                          ? "(" +
                          (option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                            ? option?.addressLine1 + "," + " "
                            : "") +
                          (option?.city ? option?.city : "") +
                          ")"
                          : "")
                        : ""
                    }
                    className="Autocomplete-field"
                    value={location ? location : []}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    onChange={(event, value) => {
                      setCallApi(false);

                      const googleLinkedLocations = allLocations?.filter(
                        (item) =>
                          item?.status === "approved" &&
                          item.socialLink.length > 0 &&
                          item.socialLink.some(
                            (link) =>
                              link?.type === "google" &&
                              link?.statusOfGoogleInsight === "Done"
                          )
                      );

                      if (value.find((option) => option.id === 0)) {
                        if (
                          googleLinkedLocations?.length === location?.length
                        ) {
                          handleChangeLocation([]);
                          setSelectAllLocations(false);
                        } else {
                          setSelectAllLocations(true);
                          handleChangeLocation(googleLinkedLocations);
                        }
                        handleChangeGroupsForLocations(googleLinkedLocations);
                        handleChangeCountriesForLocations(
                          googleLinkedLocations
                        );
                      } else {
                        const filteredLocations = value?.filter(
                          (item) =>
                            item?.status === "approved" &&
                            item.socialLink.length > 0 &&
                            item.socialLink.some(
                              (link) =>
                                link?.type === "google" &&
                                link?.statusOfGoogleInsight === "Done"
                            )
                        );
                        handleChangeLocation(filteredLocations);
                        handleChangeGroupsForLocations(filteredLocations);
                        handleChangeCountriesForLocations(filteredLocations);

                        if (
                          filteredLocations?.length ===
                          googleLinkedLocations?.length
                        ) {
                          setSelectAllLocations(true);
                        } else {
                          setSelectAllLocations(false);
                        }
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      return [
                        { id: 0, internalName: "Select All" },
                        ...filtered,
                      ];
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          disabled={
                            option.id === 0
                              ? false
                              : option?.socialLink?.length === 0 ||
                                (option?.socialLink?.length > 0 &&
                                  option?.socialLink?.find(
                                    (item) =>
                                      item?.type === "google" &&
                                      item?.statusOfGoogleInsight === "error"
                                  ) !== undefined)
                                ? true
                                : false
                          }
                          checked={
                            (option?.id === 0 && selectAllLocations) || selected
                          }
                        />

                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          {" "}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {" "}
                            <span
                              style={{
                                textAlign: "start",
                                /*   color:
                              option?.socialLink?.length === 0 ||
                              (option?.socialLink?.length > 0 &&
                                option?.socialLink?.find(
                                  (item) =>
                                    item?.statusOfGoogleInsight !== "Done"
                                ))
                                ? "#BBBDC1"
                                : "#1B2430", */
                              }}
                            >
                              {`${option?.internalName} ${option?.addressLine1 !== "" &&
                                option?.addressLine1 !== null &&
                                option?.addressLine1 !== undefined
                                ? "(" + option?.addressLine1 + "," + " "
                                : ""
                                } ${option?.city ? option?.city + ")" : ""}`}
                            </span>
                          </Grid>
                          <Grid
                            item={12}
                            sm={12}
                            md={12}
                            lg={12}
                            onClick={() => {
                              if (option?.socialLink?.length === 0) {
                                handleOpenGoogleModel();
                              } else if (
                                option?.socialLink?.length > 0 &&
                                option?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.statusOfGoogleInsight === "error"
                                ) !== undefined
                              ) {
                                handleOpenGoogleRetryModel(option?.id);
                              }
                            }}
                          >
                            <span
                              style={{
                                textAlign: "center",
                                color:
                                  option?.socialLink?.length === 0 &&
                                    option?.status === "approved"
                                    ? "#FC3652"
                                    : option?.status === "requested"
                                      ? "#99cc33"
                                      : option?.socialLink?.length > 0 &&
                                        option?.socialLink?.find(
                                          (item) =>
                                            item?.type === "google" &&
                                            item?.statusOfGoogleInsight ===
                                            "error"
                                        )
                                        ? "#F86F35"
                                        : option?.socialLink?.length > 0 &&
                                          option?.socialLink?.find(
                                            (item) =>
                                              item?.type === "google" &&
                                              item?.statusOfGoogleInsight ===
                                              "inProcess"
                                          ) !== undefined
                                          ? "#ffcc00"
                                          : option?.socialLink?.length > 0 &&
                                            option?.socialLink?.find(
                                              (item) =>
                                                item?.type === "google" &&
                                                item?.validToken === false
                                            ) !== undefined
                                            ? "#cc3300"
                                            : "",
                              }}
                            >
                              {option?.socialLink?.length === 0 &&
                                option?.status === "approved"
                                ? "• " + `${t("disconnected")}`
                                : ""}

                              {option?.status === "requested" &&
                                option?.socialLink?.length === 0
                                ? "• " + `${t("requested_state")}`
                                : ""}
                              {option?.socialLink?.length > 0 &&
                                option?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.statusOfGoogleInsight === "error"
                                ) !== undefined
                                ? "• " + `${t("Error")}`
                                : ""}
                              {option?.socialLink?.length > 0 &&
                                option?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.statusOfGoogleInsight === "inProcess"
                                ) !== undefined
                                ? "• " + `${t("inProgress")}`
                                : ""}
                              {option?.socialLink?.length > 0 &&
                                option?.socialLink?.find(
                                  (item) =>
                                    item?.type === "google" &&
                                    item?.validToken === false
                                ) !== undefined
                                ? "• " + `${t("token_expired_text")}`
                                : ""}
                            </span>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    renderTags={(value, getTagProps) => {
                      const MAX_DISPLAY_ITEMS = 2;
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "100%",
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              flexWrap: "nowrap",
                            }}
                          >
                            {value
                              .slice(0, MAX_DISPLAY_ITEMS)
                              .map((option, index) => (
                                <Chip
                                  style={{
                                    background: "#FFF",
                                    border: "1px solid #06BDFF",
                                    borderRadius: "800px",
                                    textAlign: "left",
                                    justifyContent: "space-between",
                                  }}
                                  key={index}
                                  label={`${option?.internalName} ${option?.addressLine1 !== "" &&
                                    option?.addressLine1 !== null &&
                                    option?.addressLine1 !== undefined
                                    ? "(" + option?.addressLine1 + "," + " "
                                    : ""
                                    } ${option?.city ? option?.city + ")" : ""}`}
                                  {...getTagProps({ index })}
                                />
                              ))}
                            {value.length > MAX_DISPLAY_ITEMS && (
                              <Chip
                                style={{
                                  background: "#FFF",
                                  border: "1px solid #BBBDC1",
                                  borderRadius: "800px",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  width: "100px",
                                }}
                                onClick={() => handleOpenFilterModel()}
                                label={`+${value.length - MAX_DISPLAY_ITEMS
                                  } more`}
                              />
                            )}
                          </Stack>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("SelectLocation")}
                        {...params}
                        required
                        name="language"
                      />
                    )}
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {" "}
              <Autocomplete
                multiple
                disablePortal
                disableCloseOnSelect
                id="combo-box-demo"
                options={
                  allGroups !== null && allGroups?.rows?.length > 0
                    ? allGroups?.rows
                    : []
                }
                getOptionLabel={(option) => (option?.name ? option?.name : "")}
                className="Autocomplete-field"
                value={selectedGroups ? selectedGroups : []}
                onChange={(e, value) => {
                  let arr = [];
                  allGroups?.rows?.map((item) => {
                    arr.push(item);
                  });

                  if (value.find((option) => option.id === 0)) {
                    if (selectedGroups?.length === arr?.length) {
                      setSelectedGroups([]);
                      setSelectAllGroups(false);
                    } else {
                      setSelectAllGroups(true);

                      handleChangeGroups(arr, true);
                    }
                  } else {
                    handleChangeGroups(value, true);
                    if (value?.length === arr?.length) {
                      setSelectAllGroups(true);
                    } else {
                      setSelectAllGroups(false);
                    }
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  if (allGroups?.rows?.length > 0) {
                    return [{ id: 0, name: "Select All" }, ...filtered];
                  } else {
                    return [];
                  }
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={
                        (option?.id === 0 && selectAllGroups) || selected
                      }
                      id="select-all-checkbox"
                    />

                    <Typography>{option?.name}</Typography>
                  </li>
                )}
                renderTags={(value, getTagProps) => {
                  const MAX_DISPLAY_ITEMS = 2;
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    >
                      <Stack
                        // direction="row"
                        spacing={1}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          flexWrap: "nowrap",
                          display: "inline",
                        }}
                      >
                        {value
                          .slice(0, MAX_DISPLAY_ITEMS)
                          .map((option, index) => (
                            <Chip
                              style={{
                                background: "#FFF",
                                border: "1px solid #06BDFF",
                                borderRadius: "800px",
                                textAlign: "left",
                                justifyContent: "space-between",
                              }}
                              key={index}
                              label={option?.name}
                              {...getTagProps({ index })}
                            />
                          ))}
                        {value.length > MAX_DISPLAY_ITEMS && (
                          <Chip
                            style={{
                              background: "#FFF",
                              border: "1px solid #BBBDC1",
                              borderRadius: "800px",
                              cursor: "pointer",
                              fontWeight: 700,
                              width: "100px",
                              marginTop: "4px",
                            }}
                            onClick={() => handleOpenFilterModel()}
                            label={`+${value.length - MAX_DISPLAY_ITEMS} more`}
                          />
                        )}
                      </Stack>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder={t("Select_group")}
                    {...params}
                    required
                    name="language"
                  />
                )}
              ></Autocomplete>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.5} sx={{ paddingTop: "8px" }}>
          {" "}
          <Autocomplete
            multiple
            disablePortal
            id="combo-box-demo"
            options={countries?.length > 0 ? countries : []}
            getOptionLabel={(option) => option?.countryName}
            className="Autocomplete-field"
            value={selectedCountries?.length > 0 ? selectedCountries : []}
            onChange={(event, value) => {
              // Check if 'All' option is clicked
              handleChangeCountries(value);
            }}
            isOptionEqualToValue={(option, value) =>
              option.countryName === value.countryName
            }
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (countries?.length > 0) {
                return [{ id: 0, countryName: "Select All" }, ...filtered];
              } else {
                return [];
              }
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={(option?.id === 0 && selectAllCountries) || selected}
                />

                <Typography sx={{ textTransform: "capitalize" }}>
                  {" "}
                  {option?.countryName}{" "}
                </Typography>
              </li>
            )}
            renderTags={(value, getTagProps) => {
              const MAX_DISPLAY_ITEMS_COUNTRY = 2;
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      flexWrap: "nowrap",
                    }}
                  >
                    {value
                      .slice(0, MAX_DISPLAY_ITEMS_COUNTRY)
                      .map((option, index) => (
                        <Chip
                          style={{
                            background: "#FFF",
                            border: "1px solid #06BDFF",
                            borderRadius: "800px",
                            textAlign: "left",
                            justifyContent: "space-between",
                          }}
                          key={index}
                          label={option?.countryName}
                          {...getTagProps({ index })}
                        />
                      ))}
                    {value.length > MAX_DISPLAY_ITEMS_COUNTRY && (
                      <Chip
                        style={{
                          background: "#FFF",
                          border: "1px solid #BBBDC1",
                          borderRadius: "800px",
                          cursor: "pointer",
                          fontWeight: 700,
                          width: "100px",
                        }}
                        onClick={() => handleOpenFilterModel()}
                        label={`+${value.length - MAX_DISPLAY_ITEMS_COUNTRY
                          } more`}
                      />
                    )}
                  </Stack>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                placeholder={t("selectCountry")}
                {...params}
                required
                name="language"
              //  sx={{ padding: "8px" }}
              />
            )}
          ></Autocomplete>
        </Grid>

        <Grid item xs={12} sm={12} md={0.5} lg={0.5}>
          <Typography className={classes.fieldLabell}>{t("Datee")}:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={2} sx={{ padding: "0px" }}>
          <RangePickerResults
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            startDate={startDate}
            endDate={endDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            // rangeValue={"between"}
            // fromSchedule={true}
            fromGoogleInsight={false}
            oneYearAgoDate={disabledStartDate}
            previousYear={previousYear}
            setCallInsightsApis={setCallInsightsApis}
            subscriptionDate={subscriptionDate}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3.5}
          className={classes.filterHeaderButtons}
        >
          <Grid
            container
            spacing={2}
            className={classes.filterHeaderButtonsTwo}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4.5}
              className={classes.filterHeaderButtonsFour}
            >
              <CommonButton
                //  displayBlack="true"
                //  icon={<RefreshIcon />}
                label={t("Apply_filters")}
                onSubmit={handleSubmitFilters}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              className={classes.filterHeaderButtonsFour}
            >
              <CommonButton
                displayBlack="true"
                icon={<RefreshIcon />}
                label={t("Reset")}
                onSubmit={resetHandler}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={4.5}
              className={classes.filterHeaderButtonsFour}
            >
              <Button
                onClick={() => {
                  handleOpenFilterModel();
                }}
                className={classes.viewMoreFilterButton}
              >
                <Typography className={classes.viewMoreFilterTypo}>
                  {t("View_More_Filters")}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {openFilterModel && (
        <FilterModal
          locations={location}
          allLocations={allLocations}
          handleChangeLocation={handleChangeLocation}
          startDate={startDate}
          endDate={endDate}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          selectedCountries={selectedCountries}
          selectAllCountries={selectAllCountries}
          setSelectAllCountries={setSelectAllCountries}
          //  resetHandler={resetHandler}
          openModalFilter={openFilterModel}
          handleCloseModalFilter={handleCloseFilterModel}
          allGroups={allGroups}
          setSelectedGroups={setSelectedGroups}
          selectedGroups={selectedGroups}
          handleChangeGroups={handleChangeGroups}
          selectAllLocations={selectAllLocations}
          setSelectAllLocations={setSelectAllLocations}
          setSelectAllGroups={setSelectAllGroups}
          selectAll={selectAllGroups}
          setCallApi={setCallApi}
          countries={countries}
          resetStart={resetStart}
          resetEnd={resetEnd}
          locationAddPermission={locationAddPermission}
          locationViewPermission={locationViewPermission}
          defaultLocation={defaultLocation}
          autoSelectHeader={autoSelectHeader}
          setAutoSelectHeader={setAutoSelectHeader}
          setLocation={setLocation}
          setSelectedCountries={setSelectedCountries}
          oldCountries={oldCountries}
          oldGroup={oldGroup}
          getCountryWiseLocations={getCountryWiseLocations}
          handleChangeCountries={handleChangeCountries}
          setDateRange={setDateRange}
          dateRange={dateRange}
          openFrom={"filterModel"}
          checkCountriesExistence={checkCountriesExistence}
          setEmailBody={setEmailBody}
          disabledStartDate={disabledStartDate}
          setDisabledStartDateSchedule={setDisabledStartDateSchedule}
        />
      )}
    </>
  );
}

export default FilterHeader;
