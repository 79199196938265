import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";
import { borderRadius } from "polished";
import { fontSize } from "@mui/system";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed:"#FF0000"
};

export const useStyles = makeStyles((theme) => ({

    title :{
        fontWeight: "700",
        fontSize:"20px",
    },
    allfieldss: {
        fontWeight: "700",
        fontSize:"20px"
    },
  mainBoxx :{
  marginTop:"8px",
      display: "flex",
    flexDirection:"row" 
    },
    taskTitle: {
        // margin: "8px",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "20px",
        color: "#1b2430",
        width: "438px",
        height:"20px"
    },
     inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
    },
    add_btn: {
        width: "100%",
        fontWeight: "600",
        borderRadius: "8px",
        fontSize: "1.25rem",
        lineHeight: "1.5",
        padding:"0.5rem 1rem"
    },
    title_field: {
        width: "100%",
        borderRadius:"8px"
    },
    description: {
        margin: "8px",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#1b2430",
        width: "438px",
        height:"20px"
    },
    locations: {
        marginTop: "8px",
        marginBottom: "8px",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#1b2430",
        width: "438px",
        height:"20px"
    },
    status: {
        marginTop: "8px",
        marginBottom: "8px",
        fontWeight: "700",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#1b2430",
        width: "438px",
        height:"20px"
    }
    



    
}));




