import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import UserDashboardLayout from "./layouts/UserDashboard";
import AdminDashboardLayout from "./layouts/AdminDashboard";
import { useRoutes, Navigate } from "react-router-dom";

// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";

// Auth components
import SignIn from "./pages/AuthPages/SignIn/index";
import SignUp from "./pages/AuthPages/Signup/index";
import ResetPassword from "./pages/AuthPages/ResetPassword/index";
import Page404 from "./pages/AuthPages/Page404/index";
import Page500 from "./pages/AuthPages/Page500/index";
import AccessDenied from "./pages/AuthPages/AccessDenied";
import SetPassword from "./pages/AuthPages/SetPassword/index";
// Components

// Protected routes

// Admin Dashboard components
import AdminReview from "./pages/AdminPages/AdminReview/Components/Reviews";
import AdminOverview from "./pages/AdminPages/AdminOverview/Components/AdminOverview";
import AdminProfile from "./pages/AdminPages/AdminProfile";
import AdminTask from "./pages/AdminPages/AdminTasks/index";
import AdminDomain from "./pages/AdminPages/AdminBackLink/Components/AdminDomain";
import LinkNewUser from "./pages/AdminPages/AdminAccounts/users/LinkNewUser";
import AdminSeo from "./pages/AdminPages/AdminSeo";
import DetailTask from "./pages/AdminPages/AdminTasks/Components/DetailTask";
import AdminAccounts from "./pages/AdminPages/AdminAccounts";
import AdminAccountsRequests from "./pages/AdminPages/AdminRequests/Accounts";
import AdminAccountsOverview from "./pages/AdminPages/AdminAccounts/banner";
import AdminAddLocation from "./pages/AdminPages/AdminLocation/AdminAddLocation";
import AdminMyLocation from "./pages/AdminPages/AdminLocation/AdminMyLocation";
import AdminLocationRequests from "./pages/AdminPages/AdminRequests/AdminLocationRequests";
import AdminAddressChangeRequests from "./pages/AdminPages/AdminRequests/Locations/AddressChangeRequests";
import AdminBackLink from "./pages/AdminPages/AdminBackLink";
import AdminUberAll from "./pages/AdminPages/UberAllActivations";
import AdminUberAllRequests from "./pages/AdminPages/AdminRequests/Listings";
import AdminBackLinksDetails from "./pages/AdminPages/AdminBacklinksDetails";
import AdminAnchorsDetails from "./pages/AdminPages/AdminAnchorsDetails";
import AdminAnchorFullReport from "./pages/AdminPages/AdminAnchorFullReport";
import AdminEditLocation from "./pages/AdminPages/AdminLocation/AdminEditLocation/index";
import AddAccount from "./pages/AdminPages/AdminAccounts/users/AddAccount";
import LinkUser from "./pages/AdminPages/AdminAccounts/users/LinkUser";
import CreateRole from "./pages/UserPages/UserAccounts/users/UserManagement/UserRoles/CreateRole";
import AddNewUser from "./pages/UserPages/UserAccounts/users/UserManagement/Users/AddNewUser";
import EditUser from "./pages/UserPages/UserAccounts/users/UserManagement/Users/EditUser";
// import AdminViewLocation from "./pages/AdminPages/AdminLocation/AdminViewLocation";

// User Dashboard components
import UserReviewRequest from "./pages/UserPages/UserReviewRequest/index";
import UserCampaignReviewRequest from "./pages/UserPages/UserReviewRequest/Components/Campaign/index";
import UserProfile from "./pages/UserPages/UserProfile";
import PrivateReviews from "./pages/UserPages/UserReviewRequest/Components/PrivateReviews";

import UserSeo from "./pages/UserPages/UserSeo";

import UserAccounts from "./pages/UserPages/UserAccounts";
import UserAccountsOverview from "./pages/UserPages/UserAccounts/banner";
import ReconnectLocation from "./pages/UserPages/UserLocation/ReconnectWithGoogle";
import UserAddLocation from "./pages/UserPages/UserLocation/UserAddLocation";
import UserMyLocation from "./pages/UserPages/UserLocation/UserMyLocation";
import UserEditLocation from "./pages/UserPages/UserLocation/UserEditLocation";
import UserViewLocation from "./pages/UserPages/UserLocation/UserViewLocation";
import LocationAccounts from "./pages/UserPages/UserLocation/ConnectWithGoogle/Accounts";
import FaceBookAccounts from "./pages/UserPages/UserLocation/ConnectWithFacebook/FacebookAccount";
import GoogleLocations from "./pages/UserPages/UserLocation/ConnectWithGoogle/locations";
import GoogleStats from "./pages/UserPages/GoogleStats";

import UserDetailTask from "./pages/UserPages/UserTasks/Components/DetailTask";
import AddAutoTaskRule from "./pages/UserPages/UserTasks/AutoTaskRules/Components/AddAutoTaskRule";
import AutoTaskRule from "./pages/UserPages/UserTasks/AutoTaskRules";
import useAuth from "./hooks/useAuth";

import UserTask from "./pages/UserPages/UserTasks/Components";
import UserDomain from "./pages/UserPages/UserBackLink/UserDomain";
import UserBackLink from "./pages/UserPages/UserBackLink";
import UserBacklinksDetails from "./pages/UserPages/UserBacklinksDetails";
import UserAnchorFullReport from "./pages/UserPages/UserAnchorFullReport";
import UserAnchorsDetails from "./pages/UserPages/UserAnchorsDetails";
import UserTemplate from "./pages/UserPages/UserTemplate";
import AdminTemplate from "./pages/AdminPages/AdminTemplate";
import UserCreateTemplate from "./pages/UserPages/UserTemplate/Components/CreateTemplate";
import AdminCreateTemplate from "./pages/AdminPages/AdminTemplate/Components/CreateTemplate";
import UserRules from "./pages/UserPages/UserRules";
import AdminRules from "./pages/AdminPages/AdminRules";
import UserPosts from "./pages/UserPages/UserPosts/AddPosts";
//import SentimentalAnalysis from "./pages/UserPages/SentimentalAnalysis";
import SentimentalAnalysis from "./pages/UserPages/EmotionAi";
//import SentimentalAnalysis from "./pages/UserPages/SentimentalAnalysis";
import SentimentalAnalysisRequests from "./pages/AdminPages/AdminRequests/EmotionAI";
import AllPost from "./pages/UserPages/UserPosts/AllPosts/index";
import AllAdminPost from "./pages/AdminPages/AdminPosts/AllPosts/index";
import AdminPostLogs from "./pages/AdminPages/AdminPosts/PostLogs/index";
import AdminPosts from "./pages/AdminPages/AdminPosts/AddPosts/index";
import UserGroups from "./pages/UserPages/UserGroups";
import Listings from "./pages/UserPages/UserListing";

import UserAddRule from "./pages/UserPages/UserRules/Components/AddRule";

import AdminAddRule from "./pages/AdminPages/AdminRules/Components/AddRule";
import AdminProductActivationRequests from "./pages/AdminPages/AdminRequests/ProductActivation";
import AdminEditTemplate from "./pages/AdminPages/AdminTemplate/Components/EditTemplate";
import EditTemplate from "./pages/UserPages/UserTemplate/Components/EditTemplate";
import SourceReviews from "./pages/UserPages/LocationSourceReviews";
import AdminSourceReviews from "./pages/AdminPages/LocationSourceReviews/SourceReviews";
/* import GoogleReviews from "./pages/UserPages/LocationGoogleReviews/GoogleReviews";
import AdminGoogleReviews from "./pages/AdminPages/LocationGoogleReviews/GoogleReviews"; */
import PostLogs from "./pages/UserPages/UserPosts/PostLogs/index";
import ReviewsSource from "./pages/UserPages/UserReviewSource";
import AdminReviewsSource from "./pages/AdminPages/AdminReviewSource";
import Subscribtion from "./pages/UserPages/UserLocation/UserAddLocation/Components/Subscribtion";
import ReviewRequestSurvey from "./pages/UserPages/UserReviewRequest/privateSurvey";
import QRCodeCampaign from "./pages/UserPages/UserReviewRequest/Components/Campaign/QRCodeCampaign";
//import UserReport from "./pages/UserPages/UserReports";
// import MonthlyReports from "./pages/AdminPages/AdminReports";
//import MonthlyReport from "./pages/AdminPages/AdminReports/MonthlyReports";
import MonthlyReportUser from "./pages/UserPages/UserReports/MonthlyReport";
import UserRegister from "./pages/AuthPages/UserRegister";
import UserFaqs from "./pages/UserPages/UserFaqs";
import AccountsManagement from "./pages/AdminPages/AccountsManagement";

const routes = (
  isLoggedIn,
  userRole,
  permissions,
  filteredLocationPermissions,
  filteredReviewPermissions
) => [
    //  {
    //     path: "/",
    //     element: <SignIn />,
    //   },

    // {
    //   path: "/user/edit_location",
    //   element: <UserDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <UserEditLocation />,
    //     },
    //   ],
    // },
    // {
    //   path: "/user/view_location/:id",
    //   element: <UserDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <UserViewLocation />,
    //     },
    //   ],
    // },

    // {
    //   path: "/overview/:id",
    //   element: <UserDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <ReviewsList />,
    //     },
    //   ],
    // },

    // {
    //   path: "/admin/edit_location",
    //   element: <AdminDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <AdminEditLocation />,
    //     },
    //   ],
    // },
    // {
    //   path: "/admin/view_location/:id",
    //   element: <AdminDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <AdminViewLocation />,
    //     },
    //   ],
    // },

    // {
    //   path: "listings",
    //   element: <UserDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <Cards />,
    //     },
    //   ],
    // },
    // {
    //   path: "reviews",
    //   element: <UserDashboardLayout />,
    //   children: [
    //     {
    //       path: "request",
    //       element: <Cards />,
    //     },
    //   ],
    // },

    // {
    //   path: "tools",
    //   element: <UserDashboardLayout />,

    //   children: [
    //     {
    //       path: "accounts",
    //       element: <Accounts />,
    //     },
    //     {
    //       path: "accounts/:id",
    //       element: <AccountsOverview />,
    //     },

    //     {
    //       path: "wifi-marketing-demo",
    //       element: <Cards />,
    //     },
    //     {
    //       path: "demo-accounts",
    //       element: <Cards />,
    //     },
    //     {
    //       path: "partner-insights",
    //       element: <Cards />,
    //     },
    //   ],
    // },
    // {
    //   path: "location",
    //   element: <UserDashboardLayout />,
    //   children: [
    //     {
    //       path: "",
    //       element: <MyLocation />,
    //     },
    //     {
    //       path: "add-locations",
    //       element: <AddLocation />,
    //     },
    //   ],
    // },
    {
      path: "/private-survey/:id",
      element: <ReviewRequestSurvey />,
    },
    {
      path: "/campaign-qr/:id",
      element: <QRCodeCampaign />,
    },
    {
      path: "/",
      element: isLoggedIn ? (
        userRole === "super-admin" ? (
          <AdminDashboardLayout />
        ) : (
          <UserDashboardLayout />
        )
      ) : (
        <Navigate to="/auth/sign-in" />
      ),
      children: [
        {
          path: "/",
          element:
            userRole === "super-admin" ? (
              <AdminOverview />
            ) : /*  <UserAccountsOverview /> */

              permissions !== undefined && permissions?.length > 0 ? (
                permissions?.some(
                  (item) => item?.featureName === "Listing technology"
                ) ? (
                  <Listings />
                ) : permissions?.some(
                  (item) => item?.featureName === "Emotion AI"
                ) ? (
                  <SentimentalAnalysis />
                ) : permissions?.some(
                  (item) => item?.featureName === "Review management"
                ) ? (
                  <SourceReviews />
                ) : permissions?.some((item) => item?.featureName === "Tasks") ? (
                  <UserTask />
                ) : permissions?.some((item) => item?.featureName === "Reports") ? (
                  <MonthlyReportUser />
                ) : permissions?.some(
                  (item) => item?.featureName === "Post management"
                ) ? (
                  <AllPost />
                ) : (
                  <UserMyLocation />
                )
              ) : (
                <Listings />
              ),
        },
      ],
    },

    {
      path: "user",
      element: isLoggedIn ? (
        <AuthGuard>
          <UserDashboardLayout />,
        </AuthGuard>
      ) : (
        <Navigate to="/auth/sign-in" />
      ),
      children: [
        {
          path: "/user/seo/keyword-search",
          element: <UserSeo />,
        },
        {
          path: "/user/accounts-overview",
          element: <UserAccountsOverview />,
        },
        {
          path: "/user/accounts-overview/create-role",
          element: <CreateRole />,
        },
        {
          path: "/user/accounts-overview/create-role/:id",
          element: <CreateRole />,
        },
        {
          path: "/user/accounts-overview/add-new-user",
          element: <AddNewUser />,
        },
        {
          path: "/user/accounts-overview/edit-user/:id",
          element: <EditUser />,
        },
        {
          path: "/user/accounts-overview/add-role/:id",
          element: <CreateRole />,
        },
        {
          path: "/user/accounts",
          element: <UserAccounts />,
        },
        {
          path: "accounts/:id",
          element: <UserAccountsOverview />,
        },

        {
          path: "/user/groups",
          element: filteredLocationPermissions?.some(
            (item) => item?.permissionName === "read"
          ) ? (
            <UserGroups />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/locations",
          element: filteredLocationPermissions?.some(
            (item) =>
              item?.permissionName === "read" ||
              item?.permissionName === "Account Management"
          ) ? (
            <UserMyLocation />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/add-location",
          element: filteredLocationPermissions?.some(
            (item) =>
              item?.permissionName === "create" ||
              item?.permissionName === "Account Management"
          ) ? (
            <UserAddLocation />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/token-expire/location/:id",
          element: <ReconnectLocation />,
        },
        {
          path: "/user/view_location/:id",
          element: <UserViewLocation />,
        },
        {
          path: "/user/google_accounts",
          element: <LocationAccounts />,
        },
        {
          path: "/user/review_request",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserReviewRequest />
          ) : (
            <AccessDenied />
          ),
        },

        {
          path: "/user/faqs",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserFaqs />
          ) : (
            <AccessDenied />
          ),
        },

        {
          path: "/user/private-review/:id",
          element: filteredReviewPermissions?.some(
            (item) => item?.permissionName === "read"
          ) ? (
            <PrivateReviews />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/review_request_campaign/",
          element: <UserCampaignReviewRequest />,
        },
        {
          path: "/user/review_request_campaign/:id",
          element: <UserCampaignReviewRequest />,
        },
        {
          path: "/user/review_request_campaign/duplicate/:id",
          element: <UserCampaignReviewRequest />,
        },

        {
          path: "/user/facebook_accounts",
          element: <FaceBookAccounts />,
        },
        {
          path: "/user/google_locations",
          element: <GoogleLocations />,
        },
        {
          path: "/user/edit_location",
          element: filteredLocationPermissions?.some(
            (item) =>
              item?.permissionName === "edit" ||
              item?.permissionName === "Account Management"
          ) ? (
            <UserEditLocation />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/profile",
          element: <UserProfile />,
        },
        {
          path: "/user/tasks",
          element: permissions?.some((item) => item?.featureName === "Tasks") ? (
            <UserTask />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/tasks/:id",
          element: permissions?.some((item) => item?.featureName === "Tasks") ? (
            <UserTask />
          ) : (
            <UserDetailTask />
          ),
        },
        {
          path: "/user/task-rules/add-rule",
          element: permissions?.some((item) => item?.featureName === "Tasks") ? (
            <AddAutoTaskRule />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/task-rules/add-rule/:id",
          element: permissions?.some((item) => item?.featureName === "Tasks") ? (
            <AddAutoTaskRule />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/task-rules",
          element: permissions?.some((item) => item?.featureName === "Tasks") ? (
            <AutoTaskRule />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/backlink",
          element: <UserDomain />,
        },
        {
          path: "/user/backlink/:id",
          element: <UserBackLink />,
        },
        {
          path: "/user/backlink/:id/backlinks",
          element: <UserBacklinksDetails />,
        },
        {
          path: "/user/backlink/anchors",
          element: <UserAnchorFullReport />,
        },
        {
          path: "/user/backlink/:id/anchors",
          element: <UserAnchorsDetails />,
        },
        {
          path: "/user/template",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserTemplate />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/createtemplate",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserCreateTemplate />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/edittemplate/:id",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <EditTemplate />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/rules",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserRules />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/addrule",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserAddRule />
          ) : (
            <AccessDenied />
          ),
        },

        {
          path: "/user/addrule/:id",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <UserAddRule />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/reviews",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <SourceReviews />
          ) : (
            <AccessDenied />
          ),
        },
        /*  {
          path: "/user/google/reviews",
          element: <GoogleReviews />,
        }, */
        {
          path: "/user/sentimentAnalysis",
          element: permissions?.some(
            (item) => item?.featureName === "Emotion AI"
          ) ? (
            <SentimentalAnalysis />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/google-stats",
          element: permissions?.some(
            (item) => item?.featureName === "Listing technology"
          ) ? (
            <GoogleStats />
          ) : (
            <AccessDenied />
          ),
        },

        // {
        //   path: "/user/fetchreview",
        //   element: <FetchReview />,
        // },
        // {
        //   path: "/user/reviewsource/:id",
        //   element: <UserReviewSource />,
        // },
        /*   {
          path: "/user/google/reviews/:id",
          element: <GoogleReviews />,
        }, */

        {
          path: "/user/posts",
          element: permissions?.some(
            (item) => item?.featureName === "Post management"
          ) ? (
            <UserPosts />
          ) : (
            <AccessDenied />
          ),
        },
        /*  {
          path: "/",
          element: <Listings />,
        }, */
        {
          path: "/user/subscribtion/:id",
          element: <Subscribtion />,
        },
        {
          path: "/user/allposts",
          element: permissions?.some(
            (item) => item?.featureName === "Post management"
          ) ? (
            <AllPost />
          ) : (
            <AccessDenied />
          ),
        },
        // {
        //   path: "/user/monthly-report",
        //   element: permissions?.some(
        //     (item) => item?.featureName === "Reports"
        //   ) ? (
        //     <MonthlyReportUser />
        //   ) : (
        //     <AccessDenied />
        //   ),
        // },
        {
          path: "/user/reviewSource",
          element: permissions?.some(
            (item) => item?.featureName === "Review management"
          ) ? (
            <ReviewsSource />
          ) : (
            <AccessDenied />
          ),
        },
        {
          path: "/user/allposts/logs/:id",
          element: permissions?.some(
            (item) => item?.featureName === "Post management"
          ) ? (
            <PostLogs />
          ) : (
            <AccessDenied />
          ),
        },
      ],
    },

    ,
    {
      path: "admin",
      element: isLoggedIn ? (
        <AdminGuard>
          <AdminDashboardLayout />,
        </AdminGuard>
      ) : (
        <Navigate to="/auth/sign-in" />
      ),
      children: [
        {
          path: "/admin/seo/keyword-search",
          element: <AdminSeo />,
        },

        {
          path: "/admin/accounts/add-user",
          element: <AddAccount />,
        },
        {
          path: "/admin/listings",
          element: <AdminUberAll />,
        },
        {
          path: "/admin/requests/listings",
          element: <AdminUberAllRequests />,
        },
        {
          path: "/admin/requests/products",
          element: <AdminProductActivationRequests />,
        },
        {
          path: "/admin/accounts/link-user/:id",
          element: <LinkUser />,
        },
        {
          path: "/admin/accounts/link-user",
          element: <LinkUser />,
        },
        {
          path: "/admin/accounts/link-newuser",
          element: <LinkNewUser />,
        },
        {
          path: "/admin/accounts/link-newuser/:id",
          element: <LinkNewUser />,
        },
        {
          path: "/admin/requests/emotionAI",
          element: <SentimentalAnalysisRequests />,
        },
        // {
        //   path: "/admin/monthly-report",
        //   element: <MonthlyReport />,
        // },
        {
          path: "/admin/tasks",
          element: <AdminTask />,
        },
        {
          path: "/admin/review",
          element: <AdminReview />,
        },
        {
          path: "/admin/tasks/:id",
          element: <DetailTask />,
        },
        {
          path: "/admin/accounts",
          element: <AdminAccounts />,
        },
        {
          path: "/admin/accounts-management",
          element: <AccountsManagement />,
        },
        {
          path: "/admin/requests/accounts",
          element: <AdminAccountsRequests />,
        },
        {
          path: "accounts/:id",
          element: <AdminAccountsOverview />,
        },
        {
          path: "/admin/locations",
          element: <AdminMyLocation />,
        },
        {
          path: "/admin/requests/locations",
          element: <AdminLocationRequests />,
        },
        {
          path: "/admin/locations/address-requests",
          element: <AdminAddressChangeRequests />,
        },
        {
          path: "/admin/add-location",
          element: <AdminAddLocation />,
        },
        {
          path: "/admin/view_location/:id",
          element: <UserViewLocation />,
        },
        {
          path: "/admin/edit_location",
          element: <AdminEditLocation />,
        },
        {
          path: "/admin/profile",
          element: <AdminProfile />,
        },
        {
          path: "/admin/backlink",
          element: <AdminDomain />,
        },
        {
          path: "/admin/backlink/:id",
          element: <AdminBackLink />,
        },
        {
          path: "/admin/backlink/:id/backlinks",
          element: <AdminBackLinksDetails />,
        },
        {
          path: "/admin/backlink/anchors",
          element: <AdminAnchorFullReport />,
        },
        {
          path: "/admin/backlink/:id/anchors",
          element: <AdminAnchorsDetails />,
        },
        {
          path: "/admin/posts",
          element: <AdminPosts />,
        },
        {
          path: "/admin/allposts",
          element: <AllAdminPost />,
        },
        {
          path: "/admin/allposts/logs/:id",
          element: <AdminPostLogs />,
        },
        {
          path: "/admin/template",
          element: <AdminTemplate />,
        },
        {
          path: "/admin/createtemplate",
          element: <AdminCreateTemplate />,
        },
        {
          path: "/admin/edittemplate/:id",
          element: <AdminEditTemplate />,
        },
        {
          path: "/admin/rules",
          element: <AdminRules />,
        },
        {
          path: "/admin/addrule",
          element: <AdminAddRule />,
        },

        {
          path: "/admin/addrule/:id",
          element: <AdminAddRule />,
        },
        {
          path: "/admin/reviews",
          element: <AdminSourceReviews />,
        },
        /*   {
          path: "/admin/google/reviews",
          element: <AdminGoogleReviews />,
        },
        {
          path: "/admin/google/reviews/:id",
          element: <AdminGoogleReviews />,
        }, */
        {
          path: "/admin/reviewSource",
          element: <AdminReviewsSource />,
        },
      ],
    },
    {
      path: "auth",
      element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/" />,
      children: [
        {
          path: "sign-in",
          element: <SignIn />,
        },
        {
          path: "sign-up",
          element: <SignUp />,
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "register",
          element: <UserRegister />,
        },
        {
          path: "register/:token",
          element: <UserRegister />,
        },
        {
          path: "set-password/:id",
          element: <SetPassword />,
        },
        {
          path: "404",
          element: <Page404 />,
        },
        {
          path: "500",
          element: <Page500 />,
        },
      ],
    },
    {
      path: "private",
      element: (
        <AuthGuard>
          <UserDashboardLayout />,
        </AuthGuard>
      ),
    },
    {
      path: "*",
      element: <AuthLayout />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ];
const Routes = () => {
  const { isAuthenticated, userRole, permissions, permissionsAcess } =
    useAuth();

  const filteredLocationPermissions = permissionsAcess?.filter(
    (item) => item?.route === "/location"
  );

  const filteredReviewPermissions = permissionsAcess?.filter(
    (item) => item?.route === "/review"
  );

  const routing = useRoutes(
    routes(
      isAuthenticated,
      userRole,
      permissions,
      filteredLocationPermissions,
      filteredReviewPermissions
    )
  );

  return <>{routing}</>;
};
export default Routes;
