import React, { useState, useEffect } from "react";
import data from "../data.json";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Truncate from "react-truncate";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../../../../Styles/style";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const AttributesItem = ({
  attributeItem,
  allAttributes,
  index,
  result,
  locationDetailsData,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [displayName, setDisplayName] = useState("");
  const [enumValue, setEnumValue] = useState("");
  const [repeatedEnumUnSetValue, setRepeatedEnumUnSetValue] = useState([]);
  const [repeatedEnumSetValue, setRepeatedEnumSetValue] = useState([]);

  useEffect(() => {
    if (result?.valueType === "ENUM") {
      const filteredValue = result?.valueMetadata?.find(
        (item) => item?.value === attributeItem?.values[0]
      );

      setEnumValue(filteredValue);
    }
    if (result?.valueType === "REPEATED_ENUM") {
      attributeItem?.repeatedEnumValue?.unsetValues?.forEach((item) => {
        result?.valueMetadata?.map((itemOne) => {
          if (item === itemOne?.value) {
            repeatedEnumUnSetValue.push(itemOne);
          }
        });
      });
      setRepeatedEnumUnSetValue([...repeatedEnumUnSetValue]);
      attributeItem?.repeatedEnumValue?.setValues?.forEach((item) => {
        result?.valueMetadata?.map((itemOne) => {
          if (item === itemOne?.value) {
            repeatedEnumSetValue.push(itemOne);
          }
        });
      });
      setRepeatedEnumSetValue([...repeatedEnumSetValue]);
    }
  }, [locationDetailsData, result]);
  return (
    <>
      <Grid item xs={6} sm={6} md={7} lg={7}>
        <Typography className={classes.headName}>
          {result !== null && result !== undefined ? result.displayName : "--"}
        </Typography>
      </Grid>
      <Grid item xs={6} sm={6} md={5} lg={5}>
        {attributeItem?.valueType === "BOOL" &&
        attributeItem?.values[0] === false ? (
          <Typography className={classes.headNameValue}> {t("No")}</Typography>
        ) : attributeItem?.valueType === "BOOL" &&
          attributeItem?.values[0] === true ? (
          <Typography className={classes.headNameValue}> {t("Yes")}</Typography>
        ) : attributeItem?.valueType === "ENUM" ? (
          <Typography className={classes.headNameValue}>
            {enumValue?.displayName}
          </Typography>
        ) : attributeItem?.valueType === "URL" ? (
          <Truncate
            lines={1}
            style={{ fontWeight: "400", fontSize: "16px", color: "#06BDFF" }}
            ellipsis={<span>...</span>}
          >
            <Typography
              style={{ fontWeight: "400", fontSize: "16px", color: "#06BDFF" }}
              // className={classes.headNameDescAttr}
            >
              {attributeItem?.uriValues?.length > 0 &&
                attributeItem?.uriValues[0]?.uri}
            </Typography>
          </Truncate>
        ) : (
          <div style={{ width: "100%" }}>
            {/*   <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                // minWidth: 350,
              }}
            >
              {repeatedEnumUnSetValue?.length > 0 &&
                repeatedEnumUnSetValue?.map((itemOne, index) => (
                  <Item className={classes.tagsItemBrand} key={index}>
                    <Typography className={classes.tagsTextBrands}>
                      {itemOne?.displayName
                        ? `${t("No")}` + " " + itemOne?.displayName
                        : itemOne}
                    </Typography>
                  </Item>
                ))}
            </Box> */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                // minWidth: 350,
              }}
            >
              {repeatedEnumSetValue?.length > 0 &&
                repeatedEnumSetValue?.map((itemOne, index) => (
                  <Item className={classes.tagsItemBrand} key={index}>
                    <Typography className={classes.tagsTextBrands}>
                      {itemOne?.displayName ? itemOne?.displayName : itemOne}
                    </Typography>
                  </Item>
                ))}
            </Box>
          </div>
        )}
      </Grid>
    </>
  );
};
export default AttributesItem;
