import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import GoogleMapInpuField from "../../../components/GoogleMapField";
// import Loader from "../../components/Loaders/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/styles";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../../hooks/useAuth";
import { LoadingButton } from "@mui/lab";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function SignUp() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [googlePlaceData, setGooglePlaceData] = useState({});
  const [placeId, setPlaceId] = useState("");
  const { signUp } = useAuth();
  const setPlaceIdFromGoogle = (place) => {
    setPlaceId(place.value.place_id);
  };
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [firstNameRequired, setFirstNameRequired] = useState(false);
  const [lastNameRequired, setLastNameRequired] = useState(false);
  const [emailRequired, setEmailRequired] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);
  const [confirmPasswordRequired, setConfirmPasswordRequired] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [addressLineRequired, setAddressLineRequired] = useState(false);
  const [postalCodeRequired, setPostalCodeRequired] = useState(false);
  const [cityRequired, setCityRequired] = useState(false);
  const [countryRequired, setCountryRequired] = useState(false);
  const [nameRequired, setNameRequired] = useState(false);

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    name: "",
    password: "",
    confirmPassword: "",
    addressLineExtra: "",
    addressLine: "",
    postalCode: "",
    city: "",
    country: "",
    telephone: "",
  });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}&libraries=places`;
    script.onload = () => {
      setScriptLoaded(true);
    };
    script.onerror = (error) => {
      console.error("Error loading Google Maps Places script:", error);
      // You might want to handle the error here, such as setting a flag to indicate that loading failed
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(async () => {
    if (placeId) {
      try {
        const res = await api.get(`/location/place-details/${placeId}`);

        if (res.status === 200) {
          setGooglePlaceData(res.data.data.googleData.result);
        }
      } catch (error) {}
    }
  }, [placeId]);

  useEffect(async () => {
    if (Object?.keys(googlePlaceData)?.length > 0) {
      setUserInfo({
        ...userInfo,
        name:
          googlePlaceData && googlePlaceData?.name ? googlePlaceData?.name : "",
        addressLine:
          googlePlaceData &&
          googlePlaceData?.vicinity &&
          googlePlaceData?.vicinity
            ? googlePlaceData?.vicinity
            : "",
        postalCode:
          googlePlaceData &&
          googlePlaceData?.address_components &&
          googlePlaceData?.address_components.filter(
            (address) => address.types[0] === "postal_code"
          )?.length > 0 &&
          googlePlaceData?.address_components.filter(
            (address) => address.types[0] === "postal_code"
          )[0]["long_name"]
            ? googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "postal_code"
              )[0]["long_name"]
            : "",
        city:
          googlePlaceData &&
          googlePlaceData?.address_components &&
          googlePlaceData?.address_components.filter(
            (address) => address.types[0] === "administrative_area_level_2"
          )?.length > 0 &&
          googlePlaceData?.address_components.filter(
            (address) => address.types[0] === "administrative_area_level_2"
          )[0]["long_name"]
            ? googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "administrative_area_level_2"
              )[0]["long_name"]
            : "",
        country:
          googlePlaceData &&
          googlePlaceData?.address_components &&
          googlePlaceData?.address_components.filter(
            (address) => address.types[0] === "country"
          )?.length > 0 &&
          googlePlaceData?.address_components.filter(
            (address) => address.types[0] === "country"
          )[0]["long_name"]
            ? googlePlaceData?.address_components.filter(
                (address) => address.types[0] === "country"
              )[0]["long_name"]
            : "",
        telephone:
          googlePlaceData && googlePlaceData?.international_phone_number
            ? googlePlaceData?.international_phone_number
            : "",
      });
    }
  }, [googlePlaceData]);

  const checkSubmit = async () => {
    if (userInfo?.firstName === "") {
      setFirstNameRequired(true);
    }
    if (userInfo?.lastName === "") {
      setLastNameRequired(true);
    }
    if (userInfo?.email === "") {
      setEmailRequired(true);
    }
    if (userInfo?.password === "") {
      setPasswordRequired(true);
    }
    if (userInfo?.confirmPassword === "") {
      setConfirmPasswordRequired(true);
    }
    if (userInfo?.name === "") {
      setNameRequired(true);
    }
    if (userInfo?.addressLine === "") {
      setAddressLineRequired(true);
    }
    if (userInfo?.postalCode === "") {
      setPostalCodeRequired(true);
    }
    if (userInfo?.city === "") {
      setCityRequired(true);
    }
    if (userInfo?.country === "") {
      setCountryRequired(true);
    }
    if (userInfo?.confirmPassword !== userInfo?.password) {
      setSamePassword(true);
    }
    if (
      userInfo?.firstName !== "" &&
      userInfo?.email !== "" &&
      userInfo?.password !== "" &&
      userInfo?.lastName !== "" &&
      userInfo?.confirmPassword !== "" &&
      userInfo?.name !== "" &&
      userInfo?.addressLine !== "" &&
      userInfo?.city !== "" &&
      userInfo?.country !== "" &&
      userInfo?.postalCode !== "" &&
      userInfo?.password === userInfo?.confirmPassword
    ) {
      try {
        const role = "company-admin";
        const isPrimary = true;
        const company = {
          name: userInfo?.name,
          addressLine: userInfo.addressLine,
          addressLineExtra: userInfo.addressLineExtra,
          postalCode: userInfo.postalCode,
          city: userInfo.city,
          country: userInfo.country,
          telephone: userInfo.telephone,
          placeId,
        };
        setLoading(true);
        const response = await signUp(
          userInfo.email,
          userInfo.password,
          userInfo.firstName,
          userInfo.lastName,
          company,
          role,
          isPrimary
        );

        const { message, status } = response.data;
        if (message !== "The company already exists with this name") {
          setMessage(message);
        }

        setIsSuccess(status);

        if (status) {
          toast.success(message, {
            onClose: () => {
              navigate("/auth/sign-in", {
                state: null,
              });
              setLoading(false);
            },
          });
          // ;
        } else {
          if (message === "The company already exists with this name") {
            setLoading(false);
            navigate("/auth/sign-in", {
              state: {
                email: userInfo?.email,
                message: message,
              },
            });
          } else {
            toast.error(message, {
              onClose: () => {
                navigate("/auth/sign-up");
                setLoading(false);
              },
            });
          }
        }
      } catch (error) {
        const message = error.message || "Something went wrong";

        //   setStatus({ success: false });
        // setErrors({ submit: message });
        //  setSubmitting(false);
      }
    }
  };

  return (
    <>
      {message.length !== 0 && (
        <Alert mt={2} mb={1} severity={isSuccess ? "success" : "error"}>
          {message}
        </Alert>
      )}

      {/* <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          /> */}
      <Typography className={classes.nameLabel}>{t("First_Name")}</Typography>
      <TextField
        disabled={loading}
        type="text"
        name="firstName"
        placeholder={t("First_Name")}
        value={userInfo?.firstName}
        error={firstNameRequired}
        fullWidth
        helperText={firstNameRequired && t("First_name_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            firstName: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setFirstNameRequired(false);
          } else {
            setFirstNameRequired(true);
          }
        }}
      />
      <Typography className={classes.nameLabel}>{t("Last_Name")}</Typography>
      <TextField
        disabled={loading}
        type="text"
        name="lastName"
        placeholder={t("Last_Name")}
        value={userInfo?.lastName}
        fullWidth
        error={lastNameRequired}
        helperText={lastNameRequired && t("Last_name_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            lastName: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setLastNameRequired(false);
          } else {
            setLastNameRequired(true);
          }
        }}
      />

      <Typography className={classes.nameLabel}>
        {t("Email_Address")}
      </Typography>
      <TextField
        disabled={loading}
        type="email"
        name="email"
        placeholder="Email address"
        defaultValue=""
        autoComplete="none"
        fullWidth
        value={userInfo?.email}
        error={emailRequired || validEmail}
        helperText={
          emailRequired
            ? t("Email_required_text")
            : validEmail
            ? t("valid_emaill")
            : ""
        }
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            email: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setEmailRequired(false);
            if (e.target.value.length > 0) {
              if (
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(e.target.value)
              ) {
                setValidEmail(false);
              } else {
                setValidEmail(true);
              }
            } else {
              setValidEmail(false);
            }
          } else {
            setEmailRequired(true);
            setValidEmail(false);
          }
        }}
      />

      <Typography className={classes.nameLabel}>{t("Password")}</Typography>
      <TextField
        disabled={loading}
        type="password"
        name="password"
        placeholder={t("Password")}
        defaultValue=""
        autoComplete="none"
        // inputProps={{
        //   autoComplete: "none",
        // }}
        value={userInfo?.password}
        error={passwordRequired || passwordLength}
        fullWidth
        helperText={
          passwordRequired
            ? t("confirm_password_validation")
            : passwordLength
            ? t("valid_pass")
            : ""
        }
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            password: e.target.value,
          });
          if (e.target.value?.length > 0) {
            if (e.target.value?.length < 5) {
              setPasswordLength(true);
            } else {
              setPasswordLength(false);
            }
            if (e.target.value?.length > 0) {
              if (
                userInfo?.confirmPassword?.length > 0 &&
                userInfo?.confirmPassword !== e.target.value
              ) {
                setSamePassword(true);
              } else {
                setSamePassword(false);
              }
            } else {
              setPasswordLength(false);
              setSamePassword(false);
            }
            setPasswordRequired(false);
          } else {
            setPasswordRequired(true);
          }
        }}
      />
      <Typography className={classes.nameLabel}>
        {t("Confirm_Password")}
      </Typography>
      <TextField
        disabled={loading}
        type="password"
        name="confirmPassword"
        placeholder={t("Confirm_Password")}
        value={userInfo?.confirmPassword}
        fullWidth
        error={confirmPasswordRequired || samePassword}
        helperText={
          confirmPasswordRequired
            ? t("confirm_password_validation2")
            : samePassword
            ? t("confirm_password_match_validation")
            : ""
        }
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            confirmPassword: e.target.value,
          });
          if (
            e.target.value?.length > 0 &&
            e.target.value !== userInfo?.password
          ) {
            setSamePassword(true);
            setConfirmPasswordRequired(false);
          } else {
            setSamePassword(false);
          }
          if (e.target.value?.length > 0) {
            setConfirmPasswordRequired(false);
          } else {
            setConfirmPasswordRequired(true);
          }
        }}
      />

      {scriptLoaded && (
        <Grid>
          <Box sx={{ bgcolor: "white", zIndex: 1 }}>
            <Typography className={classes.nameLabel}>
              {t("Search_Location_on_Google")}
            </Typography>
            <GoogleMapInpuField
              placeholder={t("Select_google_location")}
              setPlaceIdFromGoogle={setPlaceIdFromGoogle}
            />
          </Box>
        </Grid>
      )}
      <Typography className={classes.nameLabel}>{t("CompanyyName")}</Typography>
      <TextField
        disabled={loading}
        type="text"
        name="name"
        placeholder={t("CompanyyName")}
        value={userInfo?.name}
        error={nameRequired}
        fullWidth
        helperText={nameRequired && t("company_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            name: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setNameRequired(false);
          } else {
            setNameRequired(true);
          }
        }}
      />
      <Typography className={classes.nameLabel}>
        {t("Address_Linee")}
      </Typography>
      <TextField
        disabled={loading}
        type="text"
        name="addressLine"
        placeholder="Address Line"
        value={userInfo?.addressLine}
        error={addressLineRequired}
        fullWidth
        helperText={addressLineRequired && t("Addre_line_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            addressLine: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setAddressLineRequired(false);
          } else {
            setAddressLineRequired(true);
          }
        }}
      />
      <Typography className={classes.nameLabel}>
        {t("Address_Line_Extra")}
      </Typography>
      <TextField
        disabled={loading}
        type="text"
        name="addressLineExtra"
        placeholder={t("Address_Line_Extra")}
        fullWidth
        // helperText={touched.addressLineExtra && errors.addressLineExtra}
        value={userInfo?.addressLineExtra}
        //  helperText={confirmPasswordRequired && t("confirm_password_validation2")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            addressLineExtra: e.target.value,
          });
        }}
      />
      <Typography className={classes.nameLabel}>{t("Telephone")}</Typography>
      <TextField
        disabled={loading}
        name="telephone"
        type="number"
        placeholder={t("Telephone")}
        value={userInfo?.telephone}
        //  error={addressLineRequired}
        fullWidth
        // helperText={addressLineRequired && t("Addre_line_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            telephone: e.target.value,
          });
        }}
      />
      <Typography className={classes.nameLabel}>{t("Postal_Code")}</Typography>
      <TextField
        disabled={loading}
        name="postalCode"
        inputProps={{
          maxLength: 12,
        }}
        placeholder={t("Postal_Code")}
        value={userInfo?.postalCode}
        error={postalCodeRequired}
        fullWidth
        helperText={postalCodeRequired && t("postal_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            postalCode: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setPostalCodeRequired(false);
          } else {
            setPostalCodeRequired(true);
          }
        }}
      />
      <Typography className={classes.nameLabel}>{t("City")}</Typography>
      <TextField
        disabled={loading}
        type="text"
        name="city"
        placeholder={t("City")}
        value={userInfo?.city}
        error={cityRequired}
        fullWidth
        helperText={cityRequired && t("city_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            city: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setCityRequired(false);
          } else {
            setCityRequired(true);
          }
        }}
      />
      <Typography className={classes.nameLabel}>{t("Country")}</Typography>
      <TextField
        disabled={loading}
        type="text"
        name="country"
        placeholder={t("Country")}
        value={userInfo?.country}
        error={countryRequired}
        fullWidth
        helperText={countryRequired && t("country_req")}
        onChange={(e) => {
          setUserInfo({
            ...userInfo,
            country: e.target.value,
          });
          if (e.target.value?.length > 0) {
            setCountryRequired(false);
          } else {
            setCountryRequired(true);
          }
        }}
      />

      <LoadingButton
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        loading={loading}
        disabled={
          firstNameRequired ||
          lastNameRequired ||
          emailRequired ||
          validEmail ||
          samePassword ||
          passwordLength ||
          nameRequired ||
          addressLineRequired ||
          postalCodeRequired ||
          cityRequired ||
          countryRequired ||
          passwordLength
        }
        onClick={checkSubmit}
        className={classes.signupButton}
      >
        {t("Sign_up")}
      </LoadingButton>
      {/* <Button
            disabled={loading}
            component={Link}
            to="/auth/sign-in"
            variant="contained"
            fullWidth
            color="primary"
          >
            Sign-In
          </Button> */}
      <Typography
        className={classes.signupLabel}
        disabled={loading}
        component={Link}
        to="/auth/sign-in"
        variant="contained"
        fullWidth
        color="primary"
      >
        {t("Sign_up_text")}
      </Typography>
    </>
  );
}

export default SignUp;
