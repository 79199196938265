import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { Alert } from "@mui/material";
import Loader from "../../../../../../../../components/Loaders/Loader";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AttributesItem from "./attributeItem";
import { api } from "../../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../../Styles/style";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../../components/Models/ConfirmationModal";

const AttributesModal = ({
  onCancel,
  locationDetailsData,
  location,
  getLocationDetails,
  allAttributes,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationDetailsListing,
  handleClose,
  handleUpdateAttributes,
  cancelState,
  isLoading,
  itemsArr,
  setItemsArr,
  enumRequired,
  setEnumRequired,
  urlRequired,
  setUrlRequired,
  repeatedEnumRequired,
  setRepeatedEnumRequired,
  urlValidation,
  setUrlValidation,
  filteredItemsArr,
  handleChangeAttributeValue,
  setSelectedItem,
  selectedItem,
  handleChangeSwitch,
  removeAttribute,
  handleChangeURL,
  handleChangeEnum,
  handleChangeRepeatedEnum,
  handleRepeatedEnumSwitch,
  hasEmpty,
  handleAddNewData,
  url,
  enums,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateAttributes}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}
            //  loading={setIsLoading}
          />
        </Dialog>
      )}

      <Grid container>
        {isLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ padding: "18px", paddingTop: "50px", width: "550px" }}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              {/*    {displayConfirmationBox===true && (
                <Grid xs={12} sm={12} md={12} lg={12} sx={{ margin: "5px" }}>
                  <Alert
                    mt={2}
                    mb={1}
                    severity="error"
                    // sx={{ bgcolor: "#FFD0D0", color: "#330404" }}
                  >
                    Your current changes are not saved. Please save and
                    continue.
                  </Alert>
                </Grid>
              )} */}

              {itemsArr.length > 0 &&
                itemsArr?.map((item, index) => {
                  const result = allAttributes?.find(
                    (data) => data?.parent === item?.name
                  );
                  return (
                    <>
                      <AttributesItem
                        enumRequired={enumRequired}
                        setEnumRequired={setEnumRequired}
                        urlRequired={urlRequired}
                        setUrlRequired={setUrlRequired}
                        repeatedEnumRequired={repeatedEnumRequired}
                        setRepeatedEnumRequired={setRepeatedEnumRequired}
                        urlValidation={urlValidation}
                        setUrlValidation={setUrlValidation}
                        allAttributes={allAttributes}
                        result={result}
                        item={item}
                        index={index}
                        setItemsArr={setItemsArr}
                        itemsArr={itemsArr}
                        filteredItemsArr={filteredItemsArr}
                        handleChangeAttributeValue={handleChangeAttributeValue}
                        setSelectedItem={setSelectedItem}
                        selectedItem={selectedItem}
                        handleChangeSwitch={handleChangeSwitch}
                        removeAttribute={removeAttribute}
                        handleChangeURL={handleChangeURL}
                        handleChangeEnum={handleChangeEnum}
                        handleChangeRepeatedEnum={handleChangeRepeatedEnum}
                        handleRepeatedEnumSwitch={handleRepeatedEnumSwitch}
                      />
                    </>
                  );
                })}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mb={2}
                sx={{ marginTop: "10px" }}
              >
                <Button
                  autoFocus
                  disabled={hasEmpty || urlRequired || enumRequired}
                  onClick={handleAddNewData}
                  className={
                    hasEmpty || urlRequired || enumRequired
                      ? classes.addButtonDisabled
                      : classes.addButton
                  }
                >
                  <AddIcon
                    fontSize="medium"
                    className={
                      hasEmpty || urlRequired || enumRequired
                        ? classes.addIconDisabled
                        : classes.addIcon
                    }
                  />{" "}
                  <Typography
                    className={
                      hasEmpty || urlRequired || enumRequired
                        ? classes.addButtonDisabled
                        : classes.addButton
                    }
                  >
                    {t("Add_new_attribute")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AttributesModal;
