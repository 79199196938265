import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import TableComponent from "../AdminMyLocation/Components/Table";
import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Checkbox,
  Chip,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import { Edit } from "@mui/icons-material";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";

// import GoogleIcon from "@mui/icons-material/Google";

// import ReviewsIcon from "@mui/icons-material/Reviews";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../components/NotFound/NotFound";
import SubscriptionCard from "./Components/SubscriptionCard";

const Divider = styled(MuiDivider)(spacing);

const WhiteTextTypography = withStyles({
  root: {
    color: "#495059",
  },
})(Typography);
const HeadTextTypography = withStyles({
  root: {
    color: "#1B2430",
    fontWeight: "700",
    fontSize: "20px",
  },
})(Typography);

const Paper = styled(MuiPaper)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

// const accessToken = window.localStorage.getItem("accessToken");

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

async function fetchURL(id) {
  try {
    const res = await api.get(`google/auth-url`);
    if (res.data?.status === true) {
      window.location.href = res?.data.data + "&state=" + id;
    }
  } catch (error) {}
}
//  const handleNavigation = (data) => {
//     navigate(`/user/token-expire/location/${data?.id}`);
//   };

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, getAllLocation, t } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    getAllLocation();
  };
  // const handleConnectWithGoogle = () => {
  //   fetchURL(
  //     `${defaultValueLocation?.id}/${defaultValueLocation?.placeId}`,
  //     defaultValueLocation?.id
  //   );
  // };
  const headCells = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "customer", alignment: "left", label: t("TablePartner") },
    // { id: "products", alignment: "center", label: t("TableProducts") },
    { id: "state", alignment: "left", label: t("State") },

    { id: "actions", alignment: "center", label: t("TableAction") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {/*    <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            > */}
            {headCell.label}
            {/*  </TableSortLabel> */}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { signOut } = useAuth();
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const classes = useStyles();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [loading, setLoading] = React.useState(false);
  const [subscriptionId, setSubscriptionId] = React.useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmSubscription, setConfirmSubscription] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [subBoxOpen, setSubBoxOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [deleteLocation, setDeleteLocation] = useState(null);
  const [getLocationId, setGetLocationId] = React.useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [productFilter, setProductFilter] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [status, setStatus] = useState("All");
  const [locationStatus, setLocationStatus] = useState(null);
  const [locationSelectedSubscription, setLocationSelectedSubscription] =
    React.useState([]);

  useEffect(async () => {
    if (search?.length > 0) {
      setIsLoading(true);

      const params = new URLSearchParams(search);
      const myArray = params.get("state").split("/");
      try {
        const res = await api.get(`google/auth-code` + search);
        if (res.data?.status === true) {
          createSocialLink(res.data?.data);
        }
      } catch (error) {}
    }
  }, [search]);
  /*  useEffect(() => {
    getLocationFilter();
  }, []); */
  /*   useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getLocationFilter();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]); */

  const getLocationFilter = async () => {
    try {
      setIsLoading(true);
      // setLoading(true);
      const res = await api.get(`/location/search`);
      if (res.status === 200) {
        // setAllCompanies(usersArr);
        setLocationStatus(res.data.data);
        // setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  useEffect(async () => {
    await getAllLocation();
  }, [rowsPerPage, page, status]);
  useEffect(async () => {
    if (!(search?.length > 0)) {
      await getAllLocation();
    }
    // await getAllSubscriptions();
  }, []);
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };
  const createSocialLink = async (data) => {
    try {
      const res = await api.post(`/socialLink`, {
        type: "google",
        refreshToken: data?.refreshToken,
        accountId: data?.accountId,
        locationId: data?.locationId,
        referenceId: data?.referenceId,
      });

      if (res.status > true) {
        getAllLocation();
      }
    } catch (error) {}
  };
  /*   const getAllSubscriptions = async () => {
    try {
      // setIsLoading(true);
      const res = await api.get(`/subscriptions`);
      if (res.status === 200) {
        setAllSubscriptions(res?.data?.data);

        //  setIsLoading(false);
      }
    } catch (error) {
      // setIsLoading(false);
    }
  }; */
  const getAllLocation = async () => {
    //  let temp = [...productFilter];
    setIsLoading(true);
    try {
      const res = await api.get(
        `/location/search?limit=${rowsPerPage}&status=approved&page=${
          page + 1
        }${
          status === "true"
            ? "&filters[socialLink][validToken][$equals]=true"
            : status === "false"
            ? "&filters[socialLink][validToken][$equals]=false"
            : ""
        }`,

        {
          params: {
            searchTerm: filterWithName,
            // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
          },
        }
      );
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setIsLoading(false);
        //  setFilterWithName("");
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const productHandleChange = (selectedProduct) => {
    setProductFilter(selectedProduct);
  };
  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };
  const handleSubClickOpen = (localSub, id) => {
    setGetLocationId(id);

    let locationActiveSub = [];
    localSub.forEach((element, index) => {
      if (element.subscription.status === "active") {
        locationActiveSub.push(element);
      }
    });
    setLocationSelectedSubscription(locationActiveSub);
    setSubBoxOpen(true);
  };
  const handleChangeProductFilter = (data) => {
    // setGetSubscriptionId(data);
    if (productFilter.includes(data)) {
      const updatedSubscription = productFilter.filter((item) => item !== data);
      setProductFilter(updatedSubscription);
    } else {
      setProductFilter([...productFilter, data]);
    }
    // setGetSubscription(locationSelectedSubscription);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSubClose = () => {
    setSubBoxOpen(false);
    getAllLocation();
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };
  const handleOpenDeleteModel = (id) => {
    setDeleteLocation(id);
    setOpen(id);
    setAnchorEl(null);
  };
  const handleClick = (event, selectedData) => {
    setSelectedRow(selectedData);

    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleDeleteLocation = async () => {
    try {
      const res = await api.delete(
        `/location/${selectedRow?.id}?finalDelete=true`
      );
      if (res.status === 200) {
        setOpen(false);
        toast.success("Location deleted successfully");
        getAllLocation();
      }
    } catch (error) {}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies.length - page * rowsPerPage);

  return (
    <>
      <Helmet title="Locations" />
      <TitleHeader title={t("LocationTitle")} subHeading={t("LocationList")} />

      <Dialog open={subBoxOpen} onClose={handleSubClose}>
        <div sx={{ padding: "24px" }}>
          <DialogTitle sx={{ fontSize: "20px", fontWeight: "700" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <HeadTextTypography>
                Enable Products <br />
              </HeadTextTypography>
              <IconButton
                onClick={() => handleSubClose()}
                aria-label="save"
                hover="none"
                sx={{
                  hover: "none",
                  marginBottom: "15px",
                  // marginRight: "18px",
                }}
              >
                X
              </IconButton>
            </Stack>

            <WhiteTextTypography sx={{ fontSize: "14px", textC: "#495059" }}>
              Select the product you wish to activate for the account.
            </WhiteTextTypography>
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              <Stack direction="column" spacing={2}>
                {allSubscriptions?.results?.map((subscription, index) => {
                  const result = locationSelectedSubscription.find(
                    (data) => data?.subscription?.id === subscription.id
                  );
                  return (
                    <SubscriptionCard
                      subscription={subscription}
                      result={result}
                      getLocationId={getLocationId}
                      handleSubClose={handleSubClose}
                    />
                  );
                })}
              </Stack>
            </DialogContentText>
          </DialogContent>
        </div>

        {/* <DialogActions>
              <Button onClick={handleSubBoxCancel}>Cancel</Button>

              <LoadingButton
                variant="contained"
                color="primary"
                mr={5}
                loading={isLoading}
                onClick={() => {
                  handleSubClose();
                }}
              >
                Subscribe
              </LoadingButton>
            </DialogActions> */}
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={2.5} md={2.5}>
          <Select
            // defaultValue={data?.repeatPost === "No"}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={status}
            // defaultValue={data?.repeat}
            // label="Repeat"
            placeholder="Select Value"
            fullWidth
            sx={{ backgroundColor: "#ffff" }}
            onChange={(e) => {
              setStatus(e.target.value);
              setPage(0);
            }}
          >
            <MenuItem value="All">{t("All")}</MenuItem>
            <MenuItem value="true">{t("Valid Token")}</MenuItem>
            <MenuItem value="false">{t("Invalid Token")}</MenuItem>
          </Select>
        </Grid>
        {/* <Grid item xs={12} sm={2.5} md={2.5}>
          <Autocomplete
            style={{ backgroundColor: "white" }}
            multiple
            id="checkboxes-tags-demo"
            options={allSubscriptions?.results ? allSubscriptions?.results : []}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  onChange={(e) => {
                    handleChangeProductFilter(option?.id);
                  }}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder={t("SearchProduct")} />
            )}
          />
        </Grid> */}

        <Grid item xs={12} sm={8.5} md={8.5}>
          <SearchField
            filterWithName={filterWithName}
            filterByName={filterByName}
            onSubmit={getAllLocation}
            placeholder={t("FilterLocations")}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            //  isLoading={isLoading}
            onSubmit={getAllLocation}
            label={t("Search")}
            // width="100%"
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : allCompanies && allCompanies?.count > 0 ? (
        <Grid container marginTop="10px" spacing={6}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  t={t}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={allCompanies?.count}
                  getAllLocation={getAllLocation}
                />
                <TableBody>
                  {allCompanies?.results?.length > 0 ? (
                    /*   stableSort(
                        allCompanies?.results,
                        getComparator(order, orderBy)
                      )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                    allCompanies?.results.map((row, index) => {
                      const googleData = row?.socialLink?.find(
                        (item) => item.type === "google"
                      );

                      return (
                        //     <CustomizedTableRow
                        //       // hover
                        //       tabIndex={-1}
                        //       key={`${row.id}-${index}`}
                        //     >
                        //       <CustomizedTableCell>
                        //         {row.id}
                        //       </CustomizedTableCell>
                        //       <CustomizedTableCell align="left">
                        //         {row.name}
                        //       </CustomizedTableCell>
                        //       <CustomizedTableCell align="left">
                        //         {row?.company !== null
                        //           ? row?.company?.name
                        //           : "--"}
                        //       </CustomizedTableCell>
                        //       {/* <TableCell align="left">{getSubscription}</TableCell> */}
                        //       <CustomizedTableCell
                        //         padding="none"
                        //         align="center"
                        //       >
                        //         {row?.locationSubscription?.length === 0 ? (
                        //           ""
                        //         ) : (
                        //           <Box mr={2}>
                        //             {row?.locationSubscription.map(function (
                        //               item,
                        //               i
                        //             ) {
                        //               return (
                        //                 <>
                        //                   {item.status === "active" ? (
                        //                     <Chip
                        //                       sx={{
                        //                         padding: "5px",
                        //                         margin: "5px",
                        //                       }}
                        //                       label={item.subscription.name}
                        //                       m={1}
                        //                     />
                        //                   ) : null}
                        //                 </>
                        //               );
                        //             })}
                        //           </Box>
                        //         )}
                        //       </CustomizedTableCell>
                        //       {/*  <CustomizedTableCell align="left">
                        //   {row.status}
                        // </CustomizedTableCell> */}
                        //       <CustomizedTableCell
                        //         padding="none"
                        //         align="center"
                        //       >
                        //         <Box mr={2}>
                        //           <CustomWidthTooltip
                        //             title={t("Edit_location")}
                        //           >
                        //             <IconButton
                        //               aria-label="edit"
                        //               size="large"
                        //               onClick={() =>
                        //                 navigate(
                        //                   `/admin/edit_location?state=${row?.id}/`
                        //                 )
                        //               }
                        //             >
                        //               <Edit />
                        //             </IconButton>
                        //           </CustomWidthTooltip>

                        //           {/* <CustomWidthTooltip title="View Google Reviews">
                        //         <IconButton
                        //           aria-label="view-reviews"
                        //           size="large"
                        //           onClick={() => {
                        //             navigate(`/user/tasks/reviews/${row.id}`);
                        //           }}
                        //         >
                        //           <GoogleIcon />
                        //         </IconButton>
                        //       </CustomWidthTooltip> */}
                        //           {/* <CustomWidthTooltip title="View Reviews">
                        //         <IconButton
                        //           aria-label="view-reviews"
                        //           size="large"
                        //           onClick={() => {
                        //             if (googleData?.isConnected) {
                        //               navigate(`/user/tasks/reviews/${row.id}`);
                        //             } else {
                        //               toast.info(
                        //                 "please connect google account with location"
                        //               );
                        //             }

                        //           }}
                        //         >
                        //           <ReviewsIcon />
                        //         </IconButton>
                        //       </CustomWidthTooltip> */}
                        //           {/* <CustomWidthTooltip title="View location">
                        //         <IconButton
                        //           aria-label="view"
                        //           size="large"
                        //           onClick={() => {
                        //             let isLocation = false;
                        //             row?.locationSubscription.forEach(
                        //               (location, index) => {
                        //                 if (
                        //                   location.subscription.name ===
                        //                   "listing"
                        //                 ) {
                        //                   isLocation = true;
                        //                 }
                        //               }
                        //             );
                        //             if (isLocation) {
                        //               navigate(
                        //                 "/user/view_location/" + row.id,
                        //                 {
                        //                   state: { row },
                        //                 }
                        //               );
                        //             } else {
                        //               alert("you do not have Location");
                        //             }
                        //           }}
                        //         >
                        //           <LocationOnIcon />
                        //         </IconButton>
                        //       </CustomWidthTooltip> */}

                        //           {/* <CustomWidthTooltip title={t("Edit_Subscription")}>
                        //         <IconButton
                        //           aria-label="view"
                        //           size="large"
                        //           onClick={() => {
                        //             handleSubClickOpen(
                        //               row.locationSubscription,
                        //               row.id
                        //             );
                        //           }}
                        //         >
                        //           <SubscriptionsIcon />
                        //         </IconButton>
                        //       </CustomWidthTooltip> */}

                        //           {/* <CustomWidthTooltipOfDelete title="Delete location">
                        //         <IconButton
                        //           aria-label="delete"
                        //           size="large"
                        //           onClick={() => (
                        //             setOpen(true), setDeleteId(row.id)
                        //           )}
                        //         >
                        //           <DeleteIcon />
                        //         </IconButton>
                        //       </CustomWidthTooltipOfDelete> */}

                        //           {/* <CustomWidthTooltip title="Active/Inactive Status">
                        //         <IconButton aria-label="details" size="large">
                        //           <MoreVertIcon />
                        //         </IconButton>
                        //       </CustomWidthTooltip> */}
                        //           <IconButton
                        //             onClick={(e) => handleClick(e, row)}
                        //             variant="outlined"
                        //           >
                        //             <MoreVertIcon />
                        //           </IconButton>
                        //           <Menu
                        //             id="long-menu"
                        //             MenuListProps={{
                        //               "aria-labelledby": "long-button",
                        //             }}
                        //             anchorEl={anchorEl}
                        //             open={Boolean(anchorEl)}
                        //             onClose={handleClose}
                        //             anchorOrigin={{
                        //               vertical: "bottom",
                        //               horizontal: "right",
                        //             }}
                        //             transformOrigin={{
                        //               vertical: "top",
                        //               horizontal: "right",
                        //             }}
                        //           >
                        //             <MenuItem
                        //               onClick={() => {
                        //                 closeMenu();
                        //                 //  handleClose();
                        //                 handleOpenDeleteModel(selectedRow?.id);
                        //                 // handleOpenModal(row?.id);
                        //               }}
                        //             >
                        //               <DeleteIcon
                        //                 sx={{
                        //                   color: "#545353",
                        //                   paddingRight: "5px",
                        //                 }}
                        //               />
                        //               <Typography
                        //                 aria-label="delete"
                        //                 size="large"
                        //                 onClick={() => (
                        //                   setOpen(true),
                        //                   setDeleteId(selectedRow?.id)
                        //                 )}
                        //               >
                        //                 {t("Delete")}
                        //               </Typography>
                        //             </MenuItem>
                        //             {/*  <Divider variant="middle" />
                        //       <MenuItem
                        //         onClick={() => {
                        //           closeMenu();
                        //           if (googleData?.isConnected) {
                        //             navigate(`/user/tasks/reviews/${row.id}`);
                        //           } else {
                        //             toast.info(
                        //               "please connect google account with location"
                        //             );
                        //           }
                        //         }}
                        //       >
                        //         <ReviewsIcon
                        //           sx={{ color: "#545353", paddingRight: "5px" }}
                        //         />
                        //         <Typography
                        //           aria-label="view-reviews"
                        //           size="large"
                        //         >
                        //           View Reviews
                        //         </Typography>
                        //       </MenuItem> */}
                        //             <Divider variant="middle" />
                        //             <MenuItem
                        //               onClick={() => {
                        //                 handleSubClickOpen(
                        //                   selectedRow?.locationSubscription,
                        //                   selectedRow?.id
                        //                 );
                        //               }}
                        //             >
                        //               <SubscriptionsIcon
                        //                 sx={{
                        //                   color: "#545353",
                        //                   paddingRight: "5px",
                        //                 }}
                        //               />
                        //               <Typography
                        //                 aria-label="delete"
                        //                 size="large"
                        //               >
                        //                 {t("Edit_Subscription")}
                        //               </Typography>
                        //             </MenuItem>
                        //             {/*   <Divider variant="middle" />
                        //       <MenuItem
                        //         onClick={() => {
                        //           let isLocation = false;
                        //           selectedRow?.locationSubscription.forEach(
                        //             (location, index) => {
                        //               if (
                        //                 location.subscription.name === "listing"
                        //               ) {
                        //                 isLocation = true;
                        //               }
                        //             }
                        //           );
                        //           if (isLocation) {
                        //             navigate(
                        //               "/user/view_location/" + selectedRow.id,
                        //               {
                        //                 state: { row },
                        //               }
                        //             );
                        //           } else {
                        //             toast.warn("Please Subscribe listing");
                        //           }
                        //         }}
                        //       >
                        //         <LocationOnIcon
                        //           sx={{ color: "#545353", paddingRight: "5px" }}
                        //         />
                        //         <Typography aria-label="delete" size="large">
                        //           {t("View_Location")}
                        //         </Typography>
                        //       </MenuItem> */}
                        //           </Menu>
                        //           <CustomWidthTooltip
                        //             title={
                        //               row?.socialLink?.length > 0
                        //                 ? "Connected with google"
                        //                 : "Connect with google"
                        //             }
                        //           >
                        //             <IconButton
                        //               aria-label="edit"
                        //               size="large"
                        //               onClick={() =>
                        //                 navigate(
                        //                   `/admin/edit_location?state=${row?.id}/`,
                        //                   {
                        //                     state: {
                        //                       showProduct: true,
                        //                       displayOn: false,
                        //                     },
                        //                   }
                        //                 )
                        //               }
                        //             >
                        //               <GoogleIcon
                        //                 sx={{
                        //                   color:
                        //                     row?.socialLink?.length > 0 &&
                        //                     "#34A853",
                        //                 }}
                        //               />
                        //             </IconButton>
                        //           </CustomWidthTooltip>
                        //         </Box>
                        //       </CustomizedTableCell>
                        //     </CustomizedTableRow>
                        <>
                          <TableComponent
                            row={row}
                            index={index}
                            handleSubClickOpen={handleSubClickOpen}
                            // handleConnectWithGoogle={handleConnectWithGoogle}
                            handleClose={handleClose}
                            // handleNavigation={handleNavigation}
                            setOpen={setOpen}
                            setDeleteId={setDeleteId}
                            closeMenu={closeMenu}
                            selectedRow={selectedRow}
                            handleOpenDeleteModel={handleOpenDeleteModel}
                            handleClick={handleClick}
                            anchorEl={anchorEl}
                          />
                        </>
                      );
                    })
                  ) : (
                    <Typography variant="h3">no data found</Typography>
                  )}
                  {emptyRows > 0 && (
                    <CustomizedTableRow style={{ height: 53 * emptyRows }}>
                      <CustomizedTableCell colSpan={8} />
                    </CustomizedTableRow>
                  )}
                </TableBody>
                <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <Delete
                    title={t("location_Title_Delete")}
                    description={t("location_del_desc")}
                    onConfirm={() => {
                      handleDeleteLocation();
                    }}
                    onCancel={handleClose}
                  />
                </Dialog>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={allCompanies?.count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      ) : (
        <NotFound text={t("Location_found")} />
      )}
    </>
  );
}

/* function MyLocations() {


  return (
    <>
      <Helmet title="Locations" />

      <TitleHeader title={t("LocationTitle")} subHeading={t("LocationList")} />

      {isLoading ? (
        <Loader />
      ) : (
        <>


          {allCompanies && allCompanies?.count > 0 ? (
            <Grid container marginTop="1rem" spacing={6}>
              <Grid item xs={12}>
                <EnhancedTable
                  t={t}
                  allCompanies={allCompanies}
                  allSubscriptions={allSubscriptions}
                  getAllLocation={getAllLocation}
                  createSocialLink={createSocialLink}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  page={page}
                  setPage={setPage}
                />
              </Grid>
            </Grid>
          ) : (
            <NotFound text={t("No_Location_Found")} />
          )}
        </>
      )}


    </>
  );
} */

export default MyLocations;
