import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
  Dialog,
  CircularProgress,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import BouncingDotsLoader from "../../../../../../components/Loaders/smallBounceLoader";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import EditModal from "../../Modal";
import { useStyles } from "../../../Styles/style";
import CardHeader from "../CardHeader";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function Companydetails({
  locationDetailsData,
  location,
  locationCategory,
  locationFacebookCategory,
  getLocationDetails,
  facebookConnect,
  getLocationDetailsListing,
  getLocationById,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState(null);
  const [additionalCategoriesList, setAdditionalCategoriesList] = useState([]);
  useEffect(() => {
    if (locationCategory !== null) {
      if (
        locationCategory?.length > 0 &&
        locationCategory[0]?.google?.length > 0
      ) {
        const selectedCategory =
          locationCategory?.length > 0 &&
          locationCategory[0]?.google?.find(
            (item) =>
              item?.name ===
              locationDetailsData?.result?.google?.categories?.primaryCategory
                ?.name
          );
        setPrimaryCategory(selectedCategory);

        const selectedAdditionalCategory =
          locationCategory?.length > 0 &&
          locationCategory[0]?.google?.filter((item) =>
            locationDetailsData?.result?.google?.categories?.additionalCategories?.some(
              (additionalCategory) => item?.name === additionalCategory?.name
            )
          );

        setAdditionalCategoriesList(selectedAdditionalCategory);
      }
    }
  }, [locationCategory]);

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };

  return (
    <>
      {openDefaultModel && (
        <EditModal
          open={openDefaultModel}
          modalIndex={2}
          location={location}
          locationFacebookCategory={locationFacebookCategory}
          locationCategory={locationCategory}
          locationDetailsData={locationDetailsData}
          onCancel={handleCloseTemplateMenu}
          getLocationDetails={getLocationDetails}
          facebookConnect={facebookConnect}
          getLocationDetailsListing={getLocationDetailsListing}
          getLocationById={getLocationById}
          lodgingCategories={lodgingCategories}
          centerData={centerData}
          displayMapError={displayMapError}
          categoryLoading={categoryLoading}
        />
      )}
      <Card variant="outlined" className={classes.listingCard}>
        {isLoading ? (
          <BouncingDotsLoader />
        ) : (
          <>
            {locationDetailsData !== null ? (
              <Grid container spacing={1}>
                <CardHeader
                  title={t("Categories")}
                  editPermission={editPermission}
                  viewPermission={viewPermission}
                  handleOpenTemplateMenu={handleOpenTemplateMenu}
                />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container className={classes.categoryGrid}>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography className={classes.headName}>
                        {t("Primary_Category")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div style={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",

                            // minWidth: 350,
                          }}
                        >
                          {primaryCategory !== null ? (
                            <Item className={classes.tagsItemBrand}>
                              <Typography className={classes.tagsTextBrands}>
                                {primaryCategory?.displayName}
                              </Typography>
                            </Item>
                          ) : (
                            <Item className={classes.tagsItemBrand}>
                              <Typography className={classes.tagsTextBrands}>
                                {
                                  locationDetailsData?.result?.google
                                    ?.categories?.primaryCategory?.displayName
                                }
                              </Typography>
                            </Item>
                          )}
                        </Box>
                      </div>
                    </Grid>
                    {categoryLoading && (
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <CircularProgress
                          color="secondary"
                          style={{
                            width: "25px",
                            height: "25px",
                            color: "#06BDFF",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container className={classes.categoryGrid}>
                    <Grid
                      item
                      xs={5}
                      sm={5}
                      md={5}
                      lg={5}
                      sx={{ alignItems: "center" }}
                    >
                      <Typography className={classes.headName}>
                        {t("Additional_Categories")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                      <div style={{ width: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {additionalCategoriesList?.length > 0 ? (
                            additionalCategoriesList?.map((item) => {
                              return (
                                <Item className={classes.tagsItemBrand}>
                                  <Typography
                                    className={classes.tagsTextBrands}
                                  >
                                    {item?.displayName}
                                  </Typography>
                                </Item>
                              );
                            })
                          ) : locationDetailsData?.result?.google?.categories?.hasOwnProperty(
                              "additionalCategories"
                            ) ? (
                            locationDetailsData?.result?.google?.categories?.additionalCategories?.map(
                              (item) => {
                                return (
                                  <Item className={classes.tagsItemBrand}>
                                    <Typography
                                      className={classes.tagsTextBrands}
                                    >
                                      {item?.displayName}
                                    </Typography>
                                  </Item>
                                );
                              }
                            )
                          ) : (
                            <Typography className={classes.tagsTextBrands}>
                              --
                            </Typography>
                          )}
                        </Box>
                      </div>
                    </Grid>
                    {/*  {categoryLoading && (
                      <Grid item xs={1} sm={1} md={1} lg={1}>
                        <CircularProgress
                          color="secondary"
                          style={{
                            width: "25px",
                            height: "25px",
                            color: "#06BDFF",
                          }}
                        />
                      </Grid>
                    )} */}
                  </Grid>

                  {location?.enableFacebook === true && (
                    <>
                      <Divider />
                      <Grid container className={classes.categoryGrid}>
                        <Grid
                          item
                          xs={5}
                          sm={5}
                          md={5}
                          lg={5}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography className={classes.headName}>
                            {t("Facebook_Category")}
                          </Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={7}>
                          <div style={{ width: "100%" }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",

                                // minWidth: 350,
                              }}
                            >
                              {locationDetailsData?.result?.facebook
                                ?.category_list?.length > 0 ? (
                                <Item className={classes.tagsItemBrand}>
                                  <Typography
                                    className={classes.tagsTextBrands}
                                  >
                                    {(() => {
                                      for (
                                        let i = 0;
                                        i <
                                        locationDetailsData.result.facebook
                                          .category_list.length;
                                        i++
                                      ) {
                                        if (
                                          locationDetailsData.result.facebook
                                            .category_list[i] !== null
                                        ) {
                                          return locationDetailsData.result
                                            .facebook.category_list[i].name;
                                        }
                                      }
                                      return "--";
                                    })()}
                                  </Typography>
                                </Item>
                              ) : (
                                "--"
                              )}
                            </Box>
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            ) : (
              <NotFound text={t("No_Data_Found")} />
            )}
          </>
        )}
      </Card>
    </>
  );
}
export default Companydetails;
