import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import Grid from "@mui/material/Grid";
import {
  Divider,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  InputLabel,
  FormControlLabel,
  Switch,
  Autocomplete,
  Alert,
} from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import Loader from "../../../../components/Loaders/Loader";
import { Formik } from "formik";
import * as Yup from "yup";
import CountryList from "country-list";
import timezonesList from "./timezones.json";
import localesList from "./langauageList.json";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";

const AccountsDetails = ({ setSelectedTab }) => {
  const { getNames } = CountryList;
  const countryNames = getNames();
  const params = useParams();
  const { id } = params;
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyUsers, setcompanyUsers] = useState([]);
  const [allCompaniesData, setAllCompaniesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [setCompanyPartner] = useState([]);
  // const { email } = companyUsers;
  const {
    name,
    externalReference,
    addressLine,
    addressLineExtra,
    postalCode,
    city,
    country,
    telephone,
    website,
    locales,
    timeZone,
    email,
    dateFormat,
  } = allCompanies;

  let timezoneList = [];
  timezonesList.forEach((timezone) => {
    timezoneList.push(timezone.value);
  });
  let allCustomer = [];
  allCompaniesData?.results?.forEach((customer) => {
    allCustomer.push(customer);
  });
  let localeList = [];
  localesList.forEach((locale) => {
    localeList.push(locale.name);
  });
  useEffect(() => {
    getSelectedCustomer();
    getAllCustomer();
  }, []);

  const dateFormatList = [
    "05/19/2022",
    "5/19/2022",
    "May 19,2022",
    "May 19,2022 3:05PM",
    "Munday,May 19,2022 3:05PM",
    "Fri,May 19,2022 3:05PM",
  ];

  const getAllCustomer = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/admin/company`);

      if (res.status === 200) {
        setAllCompaniesData(res.data.data);

        setLoading(false);
      }
    } catch (error) {}
  };

  const getSelectedCustomer = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/admin/company/${id}`);
      if (res.status === 200) {
        setAllCompanies(res?.data.data);

        setcompanyUsers(res?.data.data.users[0]);
        setCompanyPartner(res?.data.data.partner);

        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Paper sx={{ marginTop: "12px" }}>
          <Formik
            enableReinitialize={true}
            initialValues={{
              companyName: name ? name : "",
              externalReference: externalReference ? externalReference : "",
              addressLine: addressLine ? addressLine : "",
              addressLineExtra: addressLineExtra ? addressLineExtra : "",
              postalCode: postalCode ? postalCode : "",
              city: city ? city : "",
              countryName: country ? country : "",
              telephone: telephone ? telephone : "",
              email: email ? email : "",
              website: website ? website : "",
              locales: locales ? locales : "",
              timeZone: timeZone ? timeZone : "",
              dateFormat: dateFormat ? dateFormat : "",
              // partnerId: companyPartner ? companyPartner : "",
            }}
            validationSchema={Yup.object().shape({
              companyName: Yup.string().required("Company name is required"),
              postalCode: Yup.string().required("Postal code is required"),
              telephone: Yup.string(),
              // .min(11),
              // .required("Phone number is required"),
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                // .required("Email is required")
                .matches(/\S+@\S+\.\S+/, `Must be a valid email`),
              // postalCode: Yup.number(),
              // accountExternalReference: Yup.string().max(255),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              setIsLoading(true);
              try {
                const res = await api.patch(`/admin/company/${id}`, {
                  name: values.companyName,
                  externalReference: values.externalReference,
                  addressLine: values.addressLine,
                  addressLineExtra: values.addressLineExtra,
                  postalCode: values.postalCode,
                  city: values.city,
                  country: values.countryName,
                  telephone: values.telephone,
                  email: values.email,
                  website: values.website,
                  locales: values.locales,
                  timeZone: values.timeZone,
                  dateFormat: values.dateFormat,
                  partnerId: values.partnerId,
                });
                if (res.status === 200) {
                  setIsLoading(false);
                  toast.success(
                    res.data.message
                      ? res.data.message
                      : "Account created successfully"
                  );
                  setTimeout(() => {
                    setMessage(setSelectedTab(0));
                  }, 3000);
                }
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
                setMessage(message);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                <Box sx={{ padding: "40px" }}>
                  {message && (
                    <Alert mt={2} mb={1} severity={status.error ? "" : "error"}>
                      {message}
                    </Alert>
                  )}
                  <Typography variant="h3">
                    {t("CompanyInformation")}
                  </Typography>
                  <Typography
                    sx={{
                      marginTop: "10px",
                      color: "rgba(51,51,51,.7)",
                      fontStyle: "italic",
                      fontWeight: 400,
                    }}
                  >
                    {/* {t("CompanyOverview")} */}
                  </Typography>
                </Box>

                <Divider />

                <Grid
                  container
                  sx={{
                    marginTop: "50px",
                    marginLeft: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <Grid xs={12} sm={3}>
                    <Typography variant="h3">
                      {t("GeneralInformation")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(51,51,51,.7)",
                        fontStyle: "italic",
                        fontWeight: 400,
                      }}
                    >
                      {/* {t("ChangeGenaralInformtion")} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("CompanyName")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.companyName && errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("AccountExternalReference")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="externalReference"
                      value={values.externalReference}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.externalReference && errors.externalReference
                      )}
                      helperText={
                        touched.externalReference && errors.externalReference
                      }
                    />
                  </Grid>
                </Grid>

                <Divider />

                <Grid
                  container
                  sx={{
                    marginTop: "50px",
                    marginLeft: "40px",
                    marginBottom: "50px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">
                      {t("AddressInformation")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("AddressLine")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="addressLine"
                      value={values.addressLine}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.addressLine && errors.addressLine)}
                      helperText={touched.addressLine && errors.addressLine}
                    />
                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("AddressLineExtra")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="addressLineExtra"
                      value={values.addressLineExtra}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.addressLineExtra && errors.addressLineExtra
                      )}
                      helperText={
                        touched.addressLineExtra && errors.addressLineExtra
                      }
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("PostalCode")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="postalCode"
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.postalCode && errors.postalCode)}
                      helperText={touched.postalCode && errors.postalCode}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("City")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Country")}
                    </InputLabel>
                    <Autocomplete
                      sx={{ width: "80%" }}
                      disablePortal
                      name="country"
                      value={values.countryName}
                      options={countryNames}
                      onChange={(e, value) => {
                        setFieldValue("countryName", value);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "40px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid itemxs={12} sm={3}>
                    <Typography variant="h3">
                      {t("ContactInformation")}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(51,51,51,.7)",
                        fontStyle: "italic",
                        fontWeight: 400,
                      }}
                    >
                      {/* {t("ChangeContactInfromation")} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("PhoneNumber")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="telephone"
                      value={values.telephone}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      // error={Boolean(touched.telephone && errors.telephone)}
                      // helperText={touched.telephone && errors.telephone}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Email")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Website")}
                    </InputLabel>
                    <TextField
                      disabled={isLoading}
                      sx={{ width: "80%" }}
                      variant="outlined"
                      name="website"
                      value={values.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.website && errors.website)}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "40px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("Localization")} </Typography>
                    <Typography
                      sx={{
                        marginTop: "10px",
                        color: "rgba(51,51,51,.7)",
                        fontStyle: "italic",
                        fontWeight: 400,
                      }}
                    >
                      {/* {t("ChangeLocalization")}. */}
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12} sm={3} sx={{ paddingBottom: "180px" }}>
                  <Typography variant="h3">{t("Localization")} </Typography>
                  <Typography variant="h6">
                    {t("ChangeLocalization")}.
                  </Typography>
                </Grid> */}
                  <Grid item xs={12} sm={9}>
                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Locales")}
                    </InputLabel>
                    <Autocomplete
                      sx={{ width: "80%" }}
                      disablePortal
                      name="locales"
                      value={values.locales}
                      options={localeList}
                      onChange={(e, value) => {
                        setFieldValue("locales", value);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.locales && errors.locales)}
                      renderInput={(params) => <TextField {...params} />}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("Timezone")}
                    </InputLabel>
                    <Autocomplete
                      sx={{ width: "80%" }}
                      disablePortal
                      name="timeZone"
                      options={timezoneList}
                      value={values.timeZone}
                      onChange={(e, value) => {
                        setFieldValue("timeZone", value);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.timeZone && errors.timeZone)}
                      renderInput={(params) => <TextField {...params} />}
                    />

                    <InputLabel
                      htmlFor="component-simple"
                      sx={{
                        marginTop: "10px",
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "rgba(0,0,0,0.87)",
                      }}
                    >
                      {t("DateFormat")}
                    </InputLabel>
                    <Autocomplete
                      sx={{ width: "80%" }}
                      disablePortal
                      name="dateFormat"
                      options={dateFormatList}
                      value={values.dateFormat}
                      onChange={(e, value) => {
                        setFieldValue("dateFormat", value);
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.dateFormat && errors.dateFormat)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>

                <Divider />

                {/*    <Grid
                  container
                  sx={{
                    marginTop: "20px",
                    marginLeft: "40px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("AdvanceSetting")} </Typography>

                  </Grid>


                  <Grid item xs={12} sm={3}>
                    <Typography variant="h3">{t("AccountSetting")} </Typography>
                    <Typography
                      sx={{
                        color: "rgba(51,51,51,.7)",
                        fontStyle: "italic",
                        fontWeight: 400,
                      }}
                    >

                      <FormControlLabel
                        control={<Switch defaultChecked />}
                        label={t("HighProfileCustomer")}
                      />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={9}></Grid>
                </Grid>
                <Divider /> */}
                <Grid
                  container
                  spacing={3}
                  //  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  my={3}
                  sx={{ paddingRight: "10px" }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    sx={{ display: "flex" }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CommonButton
                      displayWhite="true"
                      onSubmit={() => {
                        navigate(-1);
                      }}
                      disabled={isSubmitting}
                      label={t("Cancel")}
                    />
                    <CommonButton
                      loading={isLoading}
                      disabled={isSubmitting}
                      style={{
                        marginLeft: "25px",
                        padding: "10px",
                        textTransform: "none",
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      label={t("SaveSetting")}
                    />
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                </Grid>
                <Divider />
              </form>
            )}
          </Formik>
        </Paper>
      )}
    </>
  );
};

export default AccountsDetails;
