import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Autocomplete,
  TextField,
  IconButton,
  Divider,
  Select,
  MenuItem,
  createFilterOptions,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
//import EmotionAiFilterModal from "./../../Scheduler/FilterModel";
import FilterModal from "../../../../components/Models/GoogleStatsFilterModel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { languagesData } from "../../../../assets/defaultLanguage";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../EmotionAi/Style/style";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import LogoImageModal from "./UploadModels/CoverImageModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DatePickerComponent from "../Components/RangePickerResults";

const FetchModel = ({
  open,
  title,
  handleCloseNewRequestModel,
  handleDownloadGoogleStatsReport,
  exportReportLoading,
  setExportReportLoading,
  setDateRange,
  dateRange,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  allLocations,
  allGroups,
  startDate,
  endDate,
  setEmailBody,
  setEmailTitle,
  emailTitle,
  countries,
  defaultLocation,
  locationAddPermission,
  locationViewPermission,
  selectAll,
  setSelectAllLocations,
  selectAllLocations,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,
  dateRangeSchedule,
  setDateRangeSchedule,
  startDateSchedule,
  handleChangeStartDateSchedule,
  endDateSchedule,
  handleChangeEndDateSchedule,
  scheduleCountries,
  selectAllScheduleCountries,
  setSelectAllScheduleCountries,
  selectAllScheduleLocations,
  setSelectAllScheduleLocations,
  selectAllScheduleGroups,
  setSelectAllScheduleGroups,
  setCallApi,
  resetStart,
  resetEnd,
  setScheduleCountries,
  oldScheduleCountries,
  setOldScheduleCountries,
  getCountryWiseLocations,
  oldScheduleGroups,
  removeDuplicateLocaions,
  filterArrayById,
  setOldScheduleGroups,
  filterArrayByIdForGroups,
  checkCountriesExistence,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  disabledStartDate,
  setDisabledStartDateSchedule,
  setUploadedImages,
  setImages,
  uploadedImages,
  images,
  subscriptionDate,
  dateFormat,
  setDateFormat,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const i18nextLng = localStorage.getItem("i18nextLng");

  const [startDateError, setStartDateError] = useState(false);
  const [dateRangeError, setDateRangeError] = useState("");
  const [openModalFilter, setOpenFilterModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [selectedFile, setSelectedFile] = useState(null);
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);

  const [selectAllFiltersLocations, setSelectAllFiltersLocations] =
    useState(selectAllLocations);
  const [selectAllGroups, setSelectAllGroups] = useState(selectAll);

  useEffect(() => {
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
  }, [open]);

  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = (resetValue) => {
    setOpenFilterModel(false);
    if (resetValue !== true) {
      setSelectAllFiltersLocations(selectAllLocations);
      setSelectAllGroups(selectAll);
    }
  };
  const dateFormats = [
    { label: "D/M/YYYY" },
    { label: "YYYY-MM-DD" },
    { label: "DD/MM/YYYY" },
    { label: "MM/DD/YYYY" },
    { label: "DD.MM.YYYY" },
  ];

  const handleChangeCountries = (value) => {
    if (value?.length > 0) {
      if (value.find((option) => option.id === 0)) {
        // Check if all options are selected
        if (countries?.length === scheduleCountries?.length) {
          setScheduleCountries([]);
          setSelectAllScheduleCountries(false);
        } else {
          setScheduleCountries(true);
          setSelectAllScheduleCountries(countries);
          const googleLinkedLocations = allLocations?.filter(
            (item) =>
              item?.status === "approved" &&
              item.socialLink.length > 0 &&
              item.socialLink.some(
                (link) =>
                  link?.type === "google" &&
                  link?.statusOfGoogleInsight === "Done"
              )
          );
          setScheduleLocations(googleLinkedLocations);
        }
      } else {
        setScheduleCountries(value);

        handleChangeLocationsBySelectingCountries(value);
        if (value?.length === countries?.length) {
          setSelectAllScheduleCountries(true);
        } else {
          setSelectAllScheduleCountries(false);
        }
      }
    } else {
      setScheduleCountries([]);
      // setLocation([]);
    }
  };
  const isItemFoundInGroups = (locationId) => {
    let isFound = false;

    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        item?.groups?.map((itemTwo) => {
          if (itemTwo?.locationId === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeLocationsBySelectingCountries = (value) => {
    let newLocations = [...scheduleLocations];
    const results = oldScheduleCountries.filter(
      ({ countryName: id1 }) =>
        !value.some(({ countryName: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((itemOne) => {
        itemOne?.locationsArray?.map((locationItem) => {
          allLocations?.map((locItem) => {
            if (
              locationItem?.id === locItem?.id &&
              isItemFoundInGroups(locItem?.id) === false
            ) {
              newLocations?.push(locItem);
            } else {
              // setSelectAll(false);
              results?.map((item) => {
                item?.locationsArray?.map((locItemTwo) => {
                  scheduleLocations?.map((locationItem) => {
                    const filteredArray = filterArrayById(
                      value,
                      locationItem?.id
                    );
                    if (
                      locItemTwo?.id === locationItem?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInGroups(locationItem?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== locationItem?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((item) => {
        item?.locationsArray?.map((locItem) => {
          scheduleLocations?.map((locationItem) => {
            if (
              locItem?.id === locationItem?.id &&
              isItemFoundInGroups(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);

    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );

    setScheduleLocations(googleLinkedLocations);

    setOldScheduleCountries(value);
  };
  const isItemFoundInCountries = (locationId) => {
    let isFound = false;
    if (scheduleCountries?.length > 0) {
      scheduleCountries?.map((item) => {
        item?.locationsArray?.map((itemTwo) => {
          if (itemTwo?.id === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeGroups = (value, autoSelectValue) => {
    setScheduleGroups(value);

    let newLocations = [...scheduleLocations];
    const results = oldScheduleGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations?.push(locationItem);
            } else {
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  scheduleLocations?.map((location) => {
                    const filteredArray = filterArrayByIdForGroups(
                      value,
                      location?.id
                    );

                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInCountries(location?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          scheduleLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);
    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    setScheduleLocations(googleLinkedLocations);
    setOldScheduleGroups(value);
  };
  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);

    //  setReviewsChecked(false);
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              height: "550px",
            },
          }}
          open={open}
        >
          <Grid container sx={{ padding: "12px" }}>
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                  marginTop: "5px",
                }}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <IconButton
                autoFocus
                onClick={handleCloseNewRequestModel}
                className="delete_button"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
          <DialogContent
            dividers
            sx={{
              "& .MuiDialogContent-root": {
                padding: "12px",
              },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.emailReportLabel}>
                  {t("choose_a_formate_heading")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container sx={{ marginLeft: "-10px" }}>
                  <Grid item xs={3}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        onChange={handleChangePdfChecked}
                        checked={pdfChecked}
                      />
                      <Typography>{"PDF"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        onChange={handleChangeXlsChecked}
                        checked={xlsChecked}
                      />
                      <Typography>{"XLS"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.emailReportLabel}>
                  {t("upload_logo")}
                </Typography>

                <Box mt={3} ml={-3}>
                  <CommonButton
                    onSubmit={handleOpenLogoUploadModal}
                    label={t("Select_logo")}
                  />
                </Box>
                <Box>
                  {selectedFile ? (
                    <Box className={classes.fileBoxStyle}>
                      <Typography
                        // onClick={handleReopenModal}
                        className={classes.selectedFileTypo}
                      >
                        {selectedFile?.name}
                      </Typography>
                      <IconButton
                        className={classes.removeFileButton}
                        onClick={() => handleRemoveFile()}
                      >
                        <DeleteIcon
                          fontSize="small"
                          className={classes.deleteIcon}
                        />
                      </IconButton>
                    </Box>
                  ) : (
                    <p>{t("")}</p>
                  )}
                </Box>
              </Grid>

              {/*   <Grid
                item
                xs={12}
                sm={4.5}
                md={4.5}
                lg={4.5}
                sx={{ paddingTop: "0px" }}
              >
                <Typography className={classes.emailReportSubLabel}>
                  {t("date_range")}
                </Typography>

                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={dateRangeSchedule?.range}
                  onChange={(e) => {
                    handleDateRangChange(e, false);
                  }}
                  fullWidth
                  //  error={dateRangeError}
                  helperText={dateRangeError ? t("Field_req") : ""}
                >
                  <MenuItem value="month">{t("monthValue")}</MenuItem>
                  <MenuItem value="thisMonth"> {t("This_Month")}</MenuItem>
                  <MenuItem value="12months">{t("12months")}</MenuItem>
                  <MenuItem value="custom">{t("custom_text")}</MenuItem>
                </Select>
              </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                sx={{ paddingTop: "0px" }}
              >
                <Typography className={classes.emailReportSubLabel}>
                  {t("report_language")}
                </Typography>
                <Select
                  required
                  style={{ width: "100%" }}
                  key={languageSchedular?.value}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={languageSchedular?.value}
                  onChange={(e, value) => {
                    const userLang = languagesData?.find(
                      (item) => item?.value === e.target.value
                    );
                    handleChangeLanguageForScheduler(userLang);
                  }}
                >
                  {languagesData.map((item) => (
                    <MenuItem value={item.value}>{item.language}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={5}
                lg={5}
                sx={{ paddingTop: "0px" }}
              >
                <Typography className={classes.emailReportSubLabel}>
                  {t("date_format")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={dateFormats}
                  value={dateFormat}
                  onChange={(e, value) => {
                    setDateFormat(value);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField {...params} placeholder={t("date_format")} />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}
                sx={{ paddingTop: "0px" }}
              >
                <Box sx={{ marginTop: "25px" }}>
                  <CommonButton
                    icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                    onSubmit={handleOpenFilterModel}
                    label={t("Filters")}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                <Typography className={classes.emailReportSubLabel}>
                  {t("Select_Date_Range")}
                </Typography>
              </Grid>

              <DatePickerComponent
                handleChangeStartDate={handleChangeStartDateSchedule}
                handleChangeEndDate={handleChangeEndDateSchedule}
                startDate={
                  startDateSchedule !== null ? startDateSchedule : null
                }
                endDate={endDateSchedule !== null ? endDateSchedule : null}
                dateRange={dateRangeSchedule}
                setDateRange={setDateRangeSchedule}
                fromFilters={true}
                rangeValue={"between"}
                removePadding={false}
                oneYearAgoDate={disabledStartDate}
                fromGoogleInsight={true}
                subscriptionDate={subscriptionDate}
              />
            </Grid>
          </DialogContent>

          <DialogActions sx={{ marginRight: "12px" }}>
            <CommonButton
              label={t("Cancel")}
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
            />
            <CommonButton
              label={t("download")}
              icon={<FileDownloadOutlinedIcon />}
              onSubmit={handleDownloadGoogleStatsReport}
              disabled={
                pdfChecked === false && xlsChecked === false
                  ? true
                  : exportReportLoading === true
                  ? true
                  : false
              }
              isLoading={exportReportLoading}
            />
          </DialogActions>
        </Dialog>

        {openModalFilter && (
          <FilterModal
            locations={scheduleLocations}
            allLocations={allLocations}
            //  handleChangeLocation={handleChangeLocation}
            startDate={startDateSchedule}
            endDate={endDateSchedule}
            handleChangeStartDate={handleChangeStartDateSchedule}
            handleChangeEndDate={handleChangeEndDateSchedule}
            selectedCountries={scheduleCountries}
            selectAllCountries={selectAllScheduleCountries}
            setSelectAllCountries={setSelectAllScheduleCountries}
            //  resetHandler={resetHandler}
            openModalFilter={openModalFilter}
            handleCloseModalFilter={handleCloseFilterModel}
            allGroups={allGroups}
            setSelectedGroups={setScheduleGroups}
            selectedGroups={scheduleGroups}
            handleChangeGroups={handleChangeGroups}
            selectAllLocations={selectAllScheduleLocations}
            setSelectAllLocations={setSelectAllScheduleLocations}
            setSelectAllGroups={setSelectAllScheduleGroups}
            selectAll={selectAllScheduleGroups}
            setCallApi={setCallApi}
            countries={countries}
            resetStart={resetStart}
            resetEnd={resetEnd}
            locationAddPermission={true}
            locationViewPermission={true}
            defaultLocation={defaultLocation}
            setLocation={setScheduleLocations}
            setSelectedCountries={setScheduleCountries}
            oldCountries={oldScheduleCountries}
            oldGroup={oldScheduleGroups}
            getCountryWiseLocations={getCountryWiseLocations}
            handleChangeCountries={handleChangeCountries}
            setDateRange={setDateRangeSchedule}
            dateRange={dateRangeSchedule}
            openFrom={"download"}
            checkCountriesExistence={checkCountriesExistence}
            disabledStartDate={disabledStartDate}
            setDisabledStartDateSchedule={setDisabledStartDateSchedule}
            subscriptionDate={subscriptionDate}
          />
        )}
      </div>
      {openLogoUploadModal && (
        <LogoImageModal
          title={t("upload_logo")}
          handleClose={handleCloseLogoUploadModal}
          open={openLogoUploadModal}
          selectedImages={images}
          setSelectedImages={setImages}
          setSelectedFile={setSelectedFile}
          setUploadedImages={setUploadedImages}
          uploadedImages={uploadedImages}
        />
      )}
    </>
  );
};

export default FetchModel;
