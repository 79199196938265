import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Stack,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import CardHeader from "../CardHeader";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import styled from "styled-components/macro";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { LazyLoadImage } from "react-lazy-load-image-component";

import noImageGray from "../../../../../../assets/images/noImageGray.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
  titleName: {
    fontWeight: "700",
    fontSize: "20px",
    color: "#1B2430",
  },
  headName: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#1B2430",
    textTransform: "capitalize",
  },
  headNameDesc: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#06BDFF",
    display: "column",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  tagsText: {
    fontSize: "14px",
    fontWeight: "400",
    padding: "2px",
  },
  tagsItem: {
    background: "#EBEBEC",
    minWidth: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "700px",
    flexWrap: "nowrap",
  },
  viewMoreButton: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "start",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
function LocationProducts({
  locationDetailsData,
  location,
  getLocationDetails,
  singleLocation,
  googleConnect,
  getLocationById,
  locationCategory,
  setSingleLocationLoading,
  getLocationDetailsListing,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [images, setImages] = useState([]);
  const [logoImages, setLogoImages] = useState([]);
  const [sqaureImages, setSqaureImages] = useState([]);
  const [coverImages, setCoverImages] = useState([]);
  const [loadImg, setLoadImg] = useState(false);
  const [dataCount, setDataCount] = useState(3);

  const loadMoreData = () => {
    if (
      locationDetailsData?.result?.menus !== null &&
      locationDetailsData?.result?.menus?.length > 0
    ) {
      setDataCount(locationDetailsData?.result?.length);
    }
  };
  const loadLessData = () => {
    setDataCount(3);
  };

  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };

  return (
    <>
      {locationDetailsData !== null && (
        <>
          {openDefaultModel && (
            <EditModal
              open={openDefaultModel}
              modalIndex={11}
              location={location}
              locationDetailsData={locationDetailsData}
              onCancel={handleCloseTemplateMenu}
              getLocationDetails={getLocationDetails}
              setSingleLocationLoading={setSingleLocationLoading}
              getLocationById={getLocationById}
              singleLocation={singleLocation}
              locationCategory={locationCategory}
              getLocationDetailsListing={getLocationDetailsListing}
              lodgingCategories={lodgingCategories}
              categoryLoading={categoryLoading}
              centerData={centerData}
              displayMapError={displayMapError}
            />
          )}
          <Card
            variant="outlined"
            sx={{
              bgcolor: "#ffff",
              borderRadius: "8px",
              height: "auto",
            }}
          >
            <Grid
              container
              spacing={1}
              // sx={{ maxHeight: "250px", height: "250px", overflow: "auto" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <CardHeader
                  title={t("product")}
                  editPermission={editPermission}
                  viewPermission={viewPermission}
                  handleOpenTemplateMenu={handleOpenTemplateMenu}
                  displayViewButton={
                    companyData?.menuEnabled === false ? true : false
                  }
                />

                {locationDetailsData?.result?.menus !== null &&
                  locationDetailsData?.result?.menus?.length > 0 &&
                  locationDetailsData?.result?.menus[0]?.sections?.length > 0 &&
                  locationDetailsData?.result?.menus[0]?.sections
                    ?.slice(0, dataCount)
                    .map((item) => (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container sx={{ padding: "16px" }}>
                          <Grid item xs={9} sm={9} md={10} lg={9.5}>
                            <Typography className={classes.headName}>
                              {item?.labels?.length > 0 &&
                                item?.labels[0]?.displayName}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            xs={3}
                            sm={3}
                            md={3}
                            lg={2.5}
                            sx={{ textAlign: "end" }}
                          >
                            <Typography className={classes.headName}>
                              {`${item?.items?.length}  ${
                                item?.items?.length == 1 ||
                                item?.items?.length === 0
                                  ? t("item_text")
                                  : t("itemsText")
                              }`}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Divider />
                      </Grid>
                    ))}
              </Grid>

              {locationDetailsData?.result?.menus !== null &&
              locationDetailsData?.result?.menus?.length > 0 &&
              locationDetailsData?.result?.menus[0]?.sections?.length > 3 ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ alignItems: "start", padding: "12px" }}
                  >
                    {dataCount >= 3 ? (
                      <Button onClick={loadMoreData}>
                        <Typography className={classes.viewMoreButton}>
                          {t("View_More")}
                        </Typography>
                      </Button>
                    ) : (
                      <Button onClick={loadLessData}>
                        <Typography className={classes.viewMoreButton}>
                          {t("View_Less")}
                        </Typography>
                      </Button>
                    )}
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Card>
        </>
      )}
    </>
  );
}
export default LocationProducts;
