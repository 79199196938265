import React, { useState, useEffect } from "react";
import EditModal from "../../Modal";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import NotFound from "../../../../../../components/NotFound/NotFound";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import data from "./data.json";
import HourTags from "./Components/hoursTags";
import styled from "styled-components/macro";
import { useStyles } from "../../../Styles/style";
import CardHeader from "../CardHeader";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function LocationHours({
  locationDetailsData,
  location,
  getLocationDetails,
  setLocationDetailsData,
  getLocationDetailsListing,
  locationCategory,
  lodgingCategories,
  categoryLoading,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
  centerData,
  displayMapError,
  setOpeningHoursModel,
  openingHoursModel,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (openingHoursModel) {
      handleOpenTemplateMenu();
    }
  }, [openingHoursModel]);

  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    getLocationDetails(location);
    setOpeningHoursModel(false);
  };
  return (
    <>
      {openDefaultModel && (
        <EditModal
          open={openDefaultModel}
          modalIndex={4}
          onCancel={handleCloseTemplateMenu}
          location={location}
          locationDetailsData={locationDetailsData}
          getLocationDetails={getLocationDetails}
          locationCategory={locationCategory}
          setLocationDetailsData={setLocationDetailsData}
          getLocationDetailsListing={getLocationDetailsListing}
          lodgingCategories={lodgingCategories}
          categoryLoading={categoryLoading}
          centerData={centerData}
          displayMapError={displayMapError}
        />
      )}
      <Card variant="outlined" className={classes.listingCard}>
        {locationDetailsData !== null ? (
          <Grid container spacing={1}>
            <CardHeader
              title={t("Opening_Hourss")}
              editPermission={editPermission}
              viewPermission={viewPermission}
              handleOpenTemplateMenu={handleOpenTemplateMenu}
            />

            <Grid item xs={12} sm={12} md={12} lg={12}>
              {locationDetailsData?.result?.google?.openInfo?.status ===
                "OPEN" &&
              locationDetailsData?.result?.google?.regularHours !== null ? (
                <HourTags
                  locationDetailsData={locationDetailsData}
                  getLocationDetails={getLocationDetails}
                  location={location}
                />
              ) : locationDetailsData?.result?.google?.openInfo?.status ===
                  "OPEN" &&
                locationDetailsData?.result?.google?.regularHours === null ? (
                <Typography className={classes.openingHoursStatus}>
                  {t("with_no_main_hours")}
                </Typography>
              ) : locationDetailsData?.result?.google?.openInfo?.status ===
                "CLOSED_TEMPORARILY" ? (
                <Typography className={classes.openingHoursStatusTemp}>
                  {t("Temporarily_closed")}
                </Typography>
              ) : (
                <Typography className={classes.openingHoursPerm}>
                  {t("Permanently_closed")}
                </Typography>
              )}
            </Grid>
          </Grid>
        ) : (
          <NotFound text={t("No_Data_Found")} />
        )}
      </Card>
    </>
  );
}
export default LocationHours;
