import React from "react";
import LinearProgressBar from "./LinearProgressBar";
import { Card as MuiCard, CardContent, Typography, Paper } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";

const DomainCard = ({ title, progress, text, color }) => {
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  const Card = styled(MuiCard)(spacing);
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {progress && (
          <>
            <LinearProgressBar value={progress} color={color} />
            <Paper mt={4}>
              <Paper mt={2}>
                {progress && (
                  <Typography variant="body2">{progress}</Typography>
                )}
              </Paper>
            </Paper>
          </>
        )}
        {text && (
          <>
            <Paper mt={4}>
              <Paper mt={2}>
                {text && (
                  <Typography variant="body2">{kFormatter(text)}</Typography>
                )}
              </Paper>
            </Paper>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default DomainCard;
