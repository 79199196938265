import { configureStore } from "@reduxjs/toolkit";
import LocationReducer from "./slices/location";
import { ThemeReducer } from "./theme/index";
import InternalErrorReducer from "./slices/internalServerError";

export const store = configureStore({
  reducer: {
    location: LocationReducer,
    themeData: ThemeReducer,
    serverError: InternalErrorReducer,
  },
});
