import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AdminGuard({ children }) {
  const { isAuthenticated, userRole } = useAuth();
  if (!isAuthenticated || userRole !== "super-admin") {
    return <Navigate to="/auth/sign-in" />;
  }
  // if (false) {
  //   return <Navigate to="/auth/sign-in" />;
  // }
  return <React.Fragment>{children}</React.Fragment>;
}

export default AdminGuard;
