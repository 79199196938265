// ** React Imports ================================================================
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components/macro";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
// ** File Imports ================================================================

import TitleHeader from "../../../../../components/CustomComponents/title";
import { api } from "../../../../../contexts/JWTContext";
import Loader from "../../../../../components/Loaders/Loader";

// ** Material ui Imports ================================================================
import {
  Autocomplete,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  tooltipClasses,
  MenuItem,
  Select,
  createFilterOptions,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Box } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
// import "./styles/AddRule.css";
import { useStyles } from "../styles/style";
// ** Other Imports ================================================================

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
//const Alert = styled(MuiAlert)(spacing);

const Ratings = ["one", "two", "three", "four", "five"];

//const state = [{ label: "Present" }, { label: "Not Present" }];
const AddRule = () => {
  // ** States ================================================================
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [dataToEdit, setDataToEdit] = useState(null);
  const [users, setUsers] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(
    "Please check the following review and let me know your opinion."
  );
  const [selectedDueDate, setSelectedDueDate] = useState("7");
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationRequired, setLocationRequired] = useState(false);
  const [userRequired, setUserRequired] = useState(false);
  //const [dueDateRequired, setDueDateRequired] = useState(false)
  const [ratingRequired, setRatingRequired] = useState(false);
  const [descriptionRequired, setDescriptionRequired] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const [errorRules, setErrorRules] = useState([]);
  const [editData, setEditData] = useState(true);
  const [titleError, setTitleError] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [disableLocations, setDisableLocations] = useState(false);

  const filter = createFilterOptions();

  useEffect(() => {
    if (id !== undefined) {
      let arr = [];
      if (allLocations?.results?.length > 0 && selectedLocation?.length > 0) {
        allLocations?.results?.forEach((itemOne) => {
          selectedLocation?.forEach((itemTwo) => {
            if (itemOne?.id === itemTwo?.id) {
              arr?.push(itemTwo);
            }
          });
        });
        if (arr?.length > 0 && arr.length === selectedLocation?.length) {
          setDisableLocations(false);
        } else {
          setDisableLocations(true);
        }
      }
    }
  }, [allLocations, selectedLocation]);
  useEffect(() => {
    const getAllLocations = async () => {
      setIsLoading(true);
      try {
        const res = await api.get(`/location/search`, {
          params: {
            isLocationPage: false,
            // searchTerm: filterWithName,
            // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
          },
        });
        if (res.status === 200) {
          setIsLoading(true);
          setAllLocations(res.data.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    getAllLocations();
  }, []);
  useEffect(() => {
    if (id !== undefined) {
      getRuleById();
    }
  }, []);
  useEffect(() => {
    if (dataToEdit !== null) {
      let tempLocationArray = [];
      dataToEdit?.TaskRuleLocations?.map((locationItem) => {
        tempLocationArray.push(locationItem?.location);
      });
      setSelectedLocation(tempLocationArray);
      let tempUsersArray = [];
      dataToEdit?.TaskRuleUsers?.map((item) => {
        tempUsersArray.push(item?.user);
      });
      setSelectedUser(tempUsersArray);
      setTitle(dataToEdit?.title);
      setSelectedRating(dataToEdit?.ratingStar);
      setSelectedDueDate(dataToEdit?.dueDate);
      setDescription(dataToEdit?.description);
    }
  }, [dataToEdit]);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    selectedRating?.OneStar === true && setSelectedRating("one");
    selectedRating?.TwoStar === true && setSelectedRating("two");
    selectedRating?.ThreeStar === true && setSelectedRating("three");
    selectedRating?.FourStar === true && setSelectedRating("four");
    selectedRating?.FiveStar === true && setSelectedRating("five");
  }, [selectedRating]);

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      // setLoading(true);
      const res = await api.get(`user/search`);
      if (res.status === 200) {
        // setAllCompanies(res.data.data);
        setUsers(res.data.data);
        // setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const createRule = async () => {
    if (title === null) {
      setTitleError(true);
    }
    if (selectedUser?.length < 1) {
      setUserRequired(true);
    }
    if (selectedLocation?.length < 1) {
      setLocationRequired(true);
    }
    if (!selectedRating) {
      setRatingRequired(true);
    }
    if (!description) {
      setDescriptionRequired(true);
    }

    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });

    let locationIds = [];
    selectedLocation?.map((location) => {
      locationIds.push(location?.id);
    });
    // ================================================================
    const data = {
      title: title,
      ratingStar: selectedRating,
      description: description,
      dueDate: selectedDueDate,
      locationIds: locationIds,
      userIds: userIds,
    };

    if (
      data.title !== null &&
      description.length > 0 &&
      data.locationIds.length > 0 &&
      data.ratingStar.length > 0 &&
      data.userIds.length > 0 &&
      selectedRating
    ) {
      try {
        setIsLoading(true);
        const res = await api.post(`/taskRule`, data);
        if (res.status === 201) {
          setIsLoading(false);
          setErrors("");
          //   setMessage(res.data.message);
          toast.success("Rule added successfully");
          navigate("/user/task-rules");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setErrors(error?.response?.data?.message || "Something went wrong");
        setErrorRules(error?.response?.data?.data);
      }
    }
  };
  const updateRule = async () => {
    if (title === null) {
      setTitleError(true);
    }
    if (selectedUser?.length === 0) {
      setUserRequired(true);
    }
    if (selectedLocation.length < 1) {
      setLocationRequired(true);
    }
    if (!selectedRating) {
      setRatingRequired(true);
    }
    if (!description) {
      setDescriptionRequired(true);
    }

    let userIds = [];
    selectedUser?.map((item) => {
      userIds.push(item?.id);
    });

    let locationIds = [];
    selectedLocation?.map((location) => {
      locationIds.push(location?.id);
    });
    const data = {
      title: title,
      ratingStar: selectedRating,
      description: description,
      dueDate: selectedDueDate,
      locationIds: locationIds,
      userIds: userIds,
    };

    if (
      data.title !== null &&
      description.length > 0 &&
      data.locationIds.length > 0 &&
      data.ratingStar.length > 0 &&
      data.userIds.length > 0 &&
      selectedRating
    ) {
      try {
        setIsLoading(true);
        const res = await api.patch(`/taskRule/${id}`, data);
        if (res.status === 201) {
          setIsLoading(false);
          toast.success("Rule updated successfully");
          navigate("/user/task-rules");
          setErrors("");
          setIsLoading(false);
        }
        setIsLoading(false);
      } catch (error) {
        setErrors(error?.response?.data?.message);
        setErrorRules(error?.response?.data?.data);
      }
    }
  };
  const getRuleById = async () => {
    try {
      const res = await api.get(`/taskRule/${id}`);
      if (res.status === 200) {
        setIsLoading(true);
        setDataToEdit(res?.data?.data);
        setIsLoading(false);
      }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="add-rule-box1">
            <TitleHeader
              title={t("AutoRule_Task_Title")}
              subHeading={""}
              showButton={true}
              createButton={false}
              onClick={id !== undefined ? updateRule : createRule}
            />
          </Box>
          {errors && (
            <Grid container className={classes.mainContainer}>
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.errorText}>
                    {errors}
                  </Typography>
                  {errorRules.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {errorRules.map((item, index) => (
                        <Typography key={index} className={classes.errorText}>
                          {"•" + " " + item}
                        </Typography>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}

          <Paper borderRadius="12px">
            <Box className={classes.addRuleMainBox}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {editData ? (
                  <>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {t("Title")}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          // disabled={loading}
                          required
                          placeholder={t("Enter_Title")}
                          defaultValue={title}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setEditData(false);
                            }
                          }}
                          error={titleError}
                          onChange={(event) => {
                            if (event.target.value.length === 0) {
                              setTitleError(true);
                            } else {
                              setTitleError(false);
                            }
                            setTitle(event.target.value);
                          }}
                          fullWidth
                          variant="outlined"
                        />

                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setEditData(!editData);
                          }}
                          disabled={!title}
                        >
                          {" "}
                          <CheckIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {title}
                      </Typography>
                      <CustomWidthTooltip title={t("Edit_Title")}>
                        <IconButton
                          aria-label="edit"
                          sx={{ fontSize: "14px" }}
                          onClick={() => {
                            setEditData(!editData);
                          }}
                        >
                          {" "}
                          <Edit />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Grid>
                  </>
                )}
              </Box>
              <LoadingButton
                name="Save"
                variant="contained"
                color="primary"
                size="large"
                onClick={id !== undefined ? updateRule : createRule}
                loading={isLoading}
              >
                {id !== undefined ? <>{t("Update")} </> : <>{t("Save")}</>}
              </LoadingButton>
            </Box>

            <Box className={classes.Location}>
              <Typography variant="h4">{t("Locations")}</Typography>
            </Box>

            <Box className={classes.locationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Typography sx={{ padding: "8px" }}>
                  {t("Location_title")}
                </Typography>

                <Autocomplete
                  multiple
                  error={locationRequired}
                  disablePortal
                  id="combo-box-demo"
                  disableCloseOnSelect
                  disabled={disableLocations}
                  options={
                    allLocations?.results?.length > 0
                      ? allLocations?.results
                      : []
                  }
                  value={selectedLocation}
                  onChange={(event, value) => {
                    // Check if 'All' option is clicked

                    if (value.find((option) => option.id === 0)) {
                      // Check if all options are selected
                      if (
                        allLocations?.results.length ===
                        selectedLocation?.length
                      ) {
                        setSelectedLocation([]);
                        setSelectAll(false);
                      } else {
                        setSelectAll(true);
                        setSelectedLocation(allLocations?.results);
                        setLocationRequired(false);
                      }
                    } else {
                      setSelectedLocation(value);
                      setSelectAll(false);
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  // filterOptions={(options, params) => {
                  //   const filtered = createFilterOptions()(options, params);

                  //   return  [{ id: 0, name: 'Select All' }, ...filtered];

                  // }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, internalName: "Select All" }, ...filtered];
                  }}
                  defaultValue={selectedLocation}
                  getOptionLabel={(option) => {
                    if (option?.id === 0) {
                      return "Select All";
                    } else if (option?.internalName) {
                      return (
                        option?.internalName +
                        " " +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + ", "
                          : "") +
                        (option?.city ? option?.city : "")
                      );
                    } else {
                      return "";
                    }
                  }}
                  sx={{ minWidth: 300 }}
                  // value={locationId ? locationId : []}
                  // onChange={(e, value) => {
                  //   setLocationRequired(false);

                  //   setSelectedLocation(value);
                  // }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        id="select-all-checkbox"
                        checked={selectAll || selected}
                      />
                      {option.id === 0
                        ? "Select All"
                        : `${option?.internalName} ${
                            option.addressLine1 !== "" &&
                            option.addressLine1 !== null &&
                            option.addressLine1 !== undefined
                              ? option.addressLine1 + ", "
                              : ""
                          } ${option.city}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      name="language"
                      {...params}
                      error={locationRequired}
                      placeholder={t("Select_Location")}
                      //  defaultValue={{ selectedLocation }}
                    />
                  )}
                ></Autocomplete>
              </Paper>
            </Box>

            <Typography variant="h4" padding="1rem" marginLeft="1rem">
              {t("Conditions")}
            </Typography>
            <Box className={classes.Condition} borderColor="primary.main">
              <Typography variant="h6" marginBottom="2rem"></Typography>
              <Paper padding="3rem">
                <Grid
                  className={classes.Rating}
                  // sx={{ marginBottom: "1rem" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={6}
                  background="blue"
                >
                  <Grid item md={2}>
                    <Box marginLeft="3rem">
                      <Typography>{t("Rating")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Box>
                      <Autocomplete
                        error={ratingRequired}
                        onChange={(e, value) => {
                          setRatingRequired(false);
                          setSelectedRating(value);
                        }}
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) =>
                          option.charAt(0).toUpperCase() + option.slice(1)
                        }
                        options={Ratings}
                        sx={{ width: "100%" }}
                        defaultValue={selectedRating && selectedRating}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={ratingRequired}
                            placeholder={t("Select_Rating")}
                            defaultValue={{ selectedRating }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <Box className={classes.Reviews}>
                      <Typography>{t("Description")}</Typography>
                    </Box>
                  </Grid>

                  <Grid item md={8}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <TextField
                        multiline
                        required
                        placeholder={t("Enter Description")}
                        defaultValue={description}
                        error={descriptionRequired}
                        onChange={(event) => {
                          /*   if (event.target.value.length === 0) {
                            setTitleError(true);
                          } else {
                            setTitleError(false);
                          } */
                          setDescriptionRequired(false);
                          setDescription(event.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    </Box>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Paper>
              <Paper padding="1rem">
                <Box className={classes.autoReply}>
                  <Grid item md={2}>
                    <Box className={classes.Reviews}>
                      <Typography>{t("Assignee")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      {users?.results?.length > 0 && (
                        <Autocomplete
                          error={userRequired}
                          multiple
                          disablePortal
                          id="combo-box-demo"
                          options={users?.results}
                          disableCloseOnSelect
                          onChange={(e, value) => {
                            setUserRequired(false);
                            // handleChangeTemplate(value);
                            setSelectedUser(value);
                          }}
                          getOptionLabel={(option) =>
                            option?.firstName + " " + option?.lastName
                          }
                          defaultValue={selectedUser}
                          sx={{ minWidth: 300 }}
                          renderOption={(props, option, { selected }) => {
                            return (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option?.firstName + " " + option?.lastName}
                              </li>
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={userRequired}
                              placeholder={t("Select_User")}
                            />
                          )}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Box>
              </Paper>
              <Paper padding="1rem">
                <Box className={classes.autoReply}>
                  <Grid item md={2}>
                    <Box className={classes.Reviews}>
                      <Typography>{t("Due_Date")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Select
                        required
                        style={{ width: "100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedDueDate}
                        onChange={(e, value) => {
                          setSelectedDueDate(e.target.value);
                        }}
                      >
                        <MenuItem value={"3"}>{t("3-Days")}</MenuItem>
                        <MenuItem value={"7"}>{t("7-Days")}</MenuItem>
                      </Select>
                    </Box>
                  </Grid>
                  <Grid item md={2}></Grid>
                </Box>
              </Paper>
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};

export default AddRule;
