import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Modal,
  TextField,
  Divider,
  Tooltip,
  tooltipClasses,
  MenuItem,
  Menu,
  Dialog,
  DialogContent,
  useMediaQuery,
  Checkbox,
  Button,
  Chip,
} from "@mui/material";
import { useStyles } from "../../../../styles/style";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const PrimaryUserModal = ({
  openPrimaryModel,
  selectedRow,
  isLoading,
  handleClosePrimaryModel,
  updateUserHandler,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            maxWidth: "100%",
            width: "35%",
            height: "auto",
            // padding: "20px",
            borderRadius: "8px",
          },
        }}
        open={openPrimaryModel}
        // onClose={handleCloseNewRequestModel}
      >
        <DialogContent>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ textAlign: "center" }}
            >
              <WarningAmberIcon className={classes.iconClass} />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ paddingTop: "10px" }}
            >
              <Typography className={classes.makePrimaryUserTextHead}>
                {t("Make_Primary_User")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ paddingTop: "10px" }}
            >
              <Typography className={classes.makePrimaryUserTex}>
                {`${t("primary_User_Text1")} ${
                  selectedRow?.User?.firstName !== undefined &&
                  selectedRow?.User?.firstName !== null &&
                  selectedRow?.User?.firstName !== ""
                    ? selectedRow?.User?.firstName
                    : ""
                } ${
                  selectedRow?.User?.lastName !== undefined &&
                  selectedRow?.User?.lastName !== null &&
                  selectedRow?.User?.lastName !== ""
                    ? selectedRow?.User?.lastName
                    : ""
                } ${t("primary_User_Text12")} `}
              </Typography>
              <Typography className={classes.makePrimaryUserTex}>
                {` ${t("primary_User_Text2")} 
             `}
              </Typography>
              <Typography className={classes.makePrimaryUserTex}>
                {` ${t("primary_User_Text3")}
             `}
              </Typography>
            </Grid>
          </Grid>
          <Box className={classes.makePrimaryActions}>
            <CommonButton
              displayWhite="true"
              onSubmit={handleClosePrimaryModel}
              label={t("No_text")}
            />
            <CommonButton
              label={t("Yes")}
              isLoading={isLoading}
              disabled={isLoading}
              onSubmit={updateUserHandler}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrimaryUserModal;
