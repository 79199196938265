import { createSlice } from "@reduxjs/toolkit";

const serverErrorSlice = createSlice({
  name: "internalServerError",
  initialState: {
    internalError: false,
  },
  reducers: {
    errorHandler: (state, action) => {
      state.internalError = action.payload;
    },
  },
});

export const { errorHandler } = serverErrorSlice.actions;

export default serverErrorSlice.reducer;
