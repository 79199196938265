import {
  Divider,
  Stack,
  Typography,
  Box,
  Autocomplete,
  Grid,
  TextField,
  Checkbox,
  Button,
  Alert as MuiAlert,
  DialogActions,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import { spacing } from "@mui/system";
import LocationNotFound from "../../../components/CustomComponents/Errors/LocationNotFound";
import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { Dialog } from "@material-ui/core";
import Googleicon from "../../../assets/Icons/Group.svg";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import React, { useState, useEffect } from "react";
import TitleHeader from "../../../components/CustomComponents/title";
import SourceReviewsCard from "./Components/SourceReviewsCard";
import { api } from "../../../contexts/JWTContext";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Virtuoso } from "react-virtuoso";
import useAuth from "../../../hooks/useAuth";
import NotFound from "../../../components/NotFound/NotFound";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FiltersModel from "./Components/FiltersModel";
import FetchModel from "../../../components/Models/DeleteModal/FetchModel";
import WarningIcon from "@mui/icons-material/Warning";
import Iframe from "../../../components/Models/IframeModal/Iframe";
import QuestionMark from "../../../assets/Icons/Question.svg";
import NotConnected from "../../../components/CustomComponents/Errors/NotConnected";
import ReconnectCard from "../../../components/CustomComponents/Cards/ReconnectCard";
import { handleSessionOut } from "../../../contexts/JWTContext";
const Alert = styled(MuiAlert)(spacing);
const timePeriodList = [
  { name: "Yesterday", value: 0 },
  { name: "Past 7 days", value: 1 },
  { name: "This month", value: 2 },
  { name: "Past 30 days", value: 3 },
];
const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "87vh",
  width: "500px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  subHeadFetch: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2430",
    marginTop: "8px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
}));

function GoogleReviews() {
  const classes = useStyles();
  const { id } = useParams();
  const { user, signOut } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [openModalFilter, setOpenModalFilter] = React.useState(false);
  const [reviewsData, setReviewsData] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(false);
  const [dumpReviewsLoading, setDumpReviewsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [locationRequired, setLocationRequired] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [allLocationData, setAllLocationData] = useState([]);

  const [allGoogleLocation, setAllGoogleLocation] = useState([]);
  const [draftState, setDraftState] = useState(false);

  const [searchTerms, setSearchTerms] = useState("");
  const [page, setPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tags, setTags] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [sourceId, setsourceId] = useState([]);
  const [allTimePeriod, setAllTimePeriod] = useState({
    name: "All",
    value: 0,
    periodValue: "All",
  });
  const [ratingsValue, setRatingsValue] = useState([]);
  const [selectedReply, setSelectedReply] = useState({ name: "All", value: 2 });
  const [selectedTags, setSelectedTags] = useState(null);
  const [filterObj, setFilterObj] = useState({});
  const [allCompanies, setAllCompanies] = useState([]);
  const [allTemplate, setAllTemplate] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const [socialLink, setsociallink] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [defaultvaluelocation, setDefaultvalueLocation] = useState(null);
  const [expiredLocations, setExpiredLocation] = useState(null);
  const [displayReconnectButton, setDisplayReconnectButton] = useState(false);
  const [displayReconnectError, setDisplayReconnectError] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [callApi, setCallApi] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const today = new Date();
  const previousYear = new Date(
    today.getFullYear() - 1,
    today.getMonth(),
    today.getDate()
  );

  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (callApi === true) {
      getLocationGoogleReview(true);
    }
  }, [callApi]);

  useEffect(() => {
    getLocationGoogleReviewFirstTime(false);
  }, []);

  useEffect(() => {
    //  setIsLoading(true);
    getAllLocations();
    getAllUsers();
    getAllGroups();
    getAllGoogleLocation();
    getAllTemplate();
    getAllCompanies();
    getAllThirdPartySource();
    getAllTags();
    // getTasks();
    setSearchTerms("");
  }, []);
  useEffect(() => {
    if (allGoogleLocation?.results?.length > 0) {
      let arr = [];
      const expiredLocation = allGoogleLocation?.results?.forEach((item) => {
        item?.socialLink?.forEach((itemTwo) => {
          if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
            arr?.push(item);
          }
        });
      });

      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    }
  }, [allGoogleLocation]);
  {
  }
  const [filterObject, setFIlterObject] = useState({
    location: "",
    reviewSource: "",
    timePeriod: "",
    ratings: "",
    actions: "",
    status: "",
    tags: "",
  });
  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`user/search`);
      if (res.status === 200) {
        setUsers(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };
  {
  }
  const handleOpenEditUser = () => {
    setOpenModalFilter(true);
  };
  const handleChangeCheckButton = (e) => {
    setChecked(e.target.checked);
  };

  const starRatings = [
    { id: 1, name: t("1_star"), value: 1 },
    { id: 2, name: t("2_star"), value: 2 },
    { id: 3, name: t("3_star"), value: 3 },
    { id: 4, name: t("4_star"), value: 4 },
    { id: 5, name: t("5_star"), value: 5 },
  ];
  const haventReplied = [
    { name: t("Haven't_Replied"), value: 1 },
    { name: `${t("Replied")}`, value: 0 },
    { name: `${t("Drafted_Action")}`, value: "true" },
    { name: `${t("Alll")}`, value: 2 },
    /*  { name: `${t("Haven't_Drafted")}`, value: "false" }, */
  ];
  const handleOpenFetchReviewsMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseFetchReviewsMenu = () => {
    setOpenDefaultModel(false);
  };
  const handleApplyFilter = () => {
    if (callApi === true) {
      setCallApi(false);
    } else {
      setCallApi(true);
    }
  };
  const getAllGoogleLocation = async () => {
    try {
      const res = await api.get(`/location/googleLinkedlocations`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllGoogleLocation(res.data.data);
      }
    } catch (error) {}
  };
  const getAllLocations = async () => {
    try {
      setIsLoading(true);

      const res = await api.get(`/location/search`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);
        setAllLocationData(res.data.data);

        setIsLoading(false);
        let defaultlocation = null;
        if (res.data.data.results.length > 0) {
          defaultlocation = res.data.data.results?.find(
            (item, index) => item.defaultLocation !== null
          );
          setDefaultvalueLocation(defaultlocation.id);
        } else {
          setDefaultvalueLocation(null);
        }

        let socialLinktext = true;
        for (var i = 0; i < res.data.data?.results.length; i++) {
          if (res.data.data?.results[i].socialLink.length > 0) {
            socialLinktext = false;
          }
        }

        if (socialLinktext) {
          setsociallink(true);
        } else {
          setsociallink(false);
        }
      } else {
        toast.error("No location found ");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllGroups = async () => {
    try {
      const res = await api.get(`groups?pageNumber=1&limit=1000`);
      if (res.status === 200) {
        setAllGroups(res?.data?.data?.rows);
      }
    } catch (error) {
      setAllGroups([]);
    }
  };

  const getAllTags = async () => {
    try {
      setIsLoading(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/tags`);
      if (res.status === 200) {
        setTags(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllReviewSource(res.data.data);

        let tempSelected;
        res?.data.data?.results?.map((item) => {
          if (item?.name === "google") {
            tempSelected = item?.id;
          }
        });
        // let tempfilterObj = { ...filterObj }
        // tempfilterObj['filters[thirdPartyReviewSourcesId][$equals]'] = tempSelected || ""
        // setFilterObj(tempfilterObj)
        // setsourceId(tempSelected);
      }
    } catch (error) {}
  };
  const getAllTemplate = async () => {
    try {
      const res = await api.get(`/template`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllTemplate(res.data.data);
      }
    } catch (error) {}
  };

  const getLocationGoogleReview = async (NoPageChange) => {
    try {
      setReviewsLoading(true);
      const res = await api.get(
        `/review?limit=20&page=${pageNumber + 1}${
          draftState === true ? "&filters[isDraft][$equals]=true" : ""
        }${selectedGroup !== null ? `&groupId=${selectedGroup?.id}` : ""}`,
        { params: { ...filterObj } }
      );

      if (res.status < 400 && res.data.data?.results?.length > 0) {
        setReviewsData(res.data.data);
        //   setPageNumber(2);
        setReviewsLoading(false);
        setCallApi(false);
      } else {
        setReviewsData([]);
        setReviewsLoading(false);
        setCallApi(false);
      }
      setIsLoading(false);
    } catch (error) {
      setReviewsData([]);
      setReviewsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const refreshTags = async (locationId) => {
    await getLocationGoogleReview(true);
    await getAllTags();
  };

  const handleSourceChange = (value) => {
    let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"
    setPageNumber(0);
    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[thirdPartyReviewSourcesId][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setsourceId(value);
  };

  const fetchMoreData = async () => {
    if (reviewsData?.results?.length < reviewsData?.count) {
      try {
        const res = await api.get(
          `/review?limit=20&page=${pageNumber + 2}${
            draftState === true ? "&filters[isDraft][$equals]=true" : ""
          }${selectedGroup !== null ? `&groupId=${selectedGroup?.id}` : ""}`,
          { params: { ...filterObj } }
        );
        if (res.status === 200) {
          // setReviewsData((prev) => {});
          let data;
          let tempReviews = reviewsData?.results;
          data = { ...res.data.data };
          data["results"] = [...tempReviews, ...res.data.data?.results];
          setReviewsData(data);
          if (reviewsData?.results.length !== 0) {
            setPageNumber(pageNumber + 1);
          }
        } else {
          setReviewsData([]);
        }
      } catch (error) {
        setReviewsData([]);
      }
    }
  };

  const getLocationGoogleReviewFirstTime = async (newParams) => {
    try {
      setReviewsLoading(true);

      // Merge previous parameters with new parameters
      const mergedParams = { ...filterObj };

      const res = await api.get(
        `/review?limit=20&page=${pageNumber + 1}${
          draftState === true ? "&filters[isDraft][$equals]=true" : ""
        }${selectedGroup !== null ? `&groupId=${selectedGroup?.id}` : ""}`,
        { params: mergedParams }
      );

      if (res.status < 400 && res.data.data?.results?.length > 0) {
        setReviewsData(res.data.data);

        // setPageNumber(pageNumber + 1);
        setReviewsLoading(false);
        setCallApi(false);
      } else {
        setReviewsData([]);
        setReviewsLoading(false);
        setCallApi(false);
      }
      setIsLoading(false);
    } catch (error) {
      setReviewsData([]);
      setReviewsLoading(false);
      setCallApi(false);
    }
  };

  const addMoreReviewSource = () => {
    navigate("/admin/reviewSource");
  };
  const handleChangeRatings = (value) => {
    let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"

    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[ratingValue][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setRatingsValue(value);
  };

  const replyChangeHandler = (value) => {
    let tempfilterObj = {
      ...filterObj,
    };

    if (value?.name === "Drafted") {
      if (tempfilterObj.hasOwnProperty("haventReplied")) {
        delete tempfilterObj.haventReplied;
      }
      /*   let draftObj = [];

      draftObj[`filters[isDraft][$equals]`] = value?.value; */
      setDraftState(true);
    } else {
      tempfilterObj["haventReplied"] = value?.value;
      // tempfilterObj[`filters[isDraft][$equals]`] = "false";
      setDraftState(false);
    }
    setFilterObj(tempfilterObj);
    setSelectedReply(value);
  };
  const tagsChangeHandler = (value) => {
    let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"

    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[reviewTags][tagId][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setSelectedTags(value);

    if (value?.length > 0) {
      // const arr = value;

      const arrr = value;
      let arr = [];
      const expiredLocation = arrr?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });
      /*    const filteredArr = arr?.filter(
        (item) =>
          item?.socialLink?.length > 0 &&
          item?.socialLink[0]?.validToken===false
      ); */
      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    } else {
      getAllGoogleLocation();
    }
  };

  // const handleLocationChange = (value) => {
  //   let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"
  //   let tempfilterObj = { ...filterObj };
  //   tempfilterObj["filters[locationId][$equals]"] = ids || "";
  //   setFilterObj(tempfilterObj);
  //   setLocationId(value);
  // };
  const handleLocationChange = (value) => {
    let ids = Array.prototype.map.call(value, (s) => s.id).toString(); // "1,2,3"
    let tempfilterObj = { ...filterObj };
    tempfilterObj["filters[locationId][$equals]"] = ids || "";
    setFilterObj(tempfilterObj);
    setLocationId(value);

    if (value?.length > 0) {
      // const arr = value;

      const arrr = value;
      let arr = [];
      const expiredLocation = arrr?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google" && itemTwo?.validToken === false) {
              arr?.push(item);
            }
          });
        }
      });
      /*    const filteredArr = arr?.filter(
        (item) =>
          item?.socialLink?.length > 0 &&
          item?.socialLink[0]?.validToken===false
      ); */
      if (arr?.length === 0) {
        setDisplayReconnectError(false);
      } else if (arr?.length === 1) {
        setDisplayReconnectError(true);
        setExpiredLocation(arr[0]);
        setDisplayReconnectButton(true);
      } else if (arr?.length > 1) {
        setDisplayReconnectError(true);
        setDisplayReconnectButton(false);
      }
    } else {
      getAllGoogleLocation();
    }
  };
  const handleDefaultTemplate = async (message) => {
    try {
      setLoading(true);
      const res = await api.post("template/addDefault", {
        message: message,
      });

      if (res.status === 200) {
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleChangeTime = (value) => {
    let date;

    let tempfilterObj = { ...filterObj };
    if (value !== null) {
      setAllTimePeriod(value.periodValue);
      if (value?.periodValue === "All") {
        delete tempfilterObj["filters[date][$gte]"];
        delete tempfilterObj["filters[date][$between]"];
      } else {
        if (
          value.periodValue !== "week" &&
          value.periodValue !== "month" &&
          value.periodValue !== "custom"
        ) {
          delete tempfilterObj["filters[date][$between]"];
          if (value.periodValue === "Yesterday") {
            date = moment()
              .subtract(1, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "Today") {
            date = moment()
              .startOf("day")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "2days") {
            date = moment()
              .subtract(2, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "7days") {
            date = moment()
              .subtract(7, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "14days") {
            date = moment()
              .subtract(14, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "30days") {
            date = moment()
              .subtract(30, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "60days") {
            date = moment()
              .subtract(60, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "90days") {
            date = moment()
              .subtract(90, "d")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "6months") {
            date = moment()
              .subtract(6, "months")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          } else if (value.periodValue === "12months") {
            date = moment()
              .subtract(12, "months")
              .utc()
              .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          }
          tempfilterObj["filters[date][$gte]"] = `${date}` || "";
          setStartDate(date);
        } else if (value.periodValue === "custom") {
          delete tempfilterObj["filters[date][$gte]"];
          let todayDate = moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

          setStartDate(previousYear);
          setEndDate(today);
          let previousYearDate = moment(previousYear)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          let str = `${previousYearDate},${todayDate}`;
          tempfilterObj["filters[date][$between]"] = `${str}` || "";
        } else if (value.periodValue === "week") {
          delete tempfilterObj["filters[date][$gte]"];
          let today = moment();
          let mondayOfPreviousWeek = today
            .clone()
            .subtract(7, "days")
            .startOf("isoWeek")
            .toDate();
          let sundayOfPreviousWeek = today
            .clone()
            .subtract(7, "days")
            .endOf("isoWeek")
            .toDate();

          let startDateOfWeek = moment(mondayOfPreviousWeek)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          let endDateOfWeek = moment(sundayOfPreviousWeek)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

          let str = `${startDateOfWeek},${endDateOfWeek}`;
          tempfilterObj["filters[date][$between]"] = `${str}` || "";
          setStartDate(startDateOfWeek);
          setEndDate(endDateOfWeek);
        } else if (value.periodValue === "month") {
          delete tempfilterObj["filters[date][$gte]"];
          // Go back to the first day of the current month and then subtract a month to get the 1st day of the previous month
          let today = moment(); // Get the current date

          // Go to the 1st day of the current month and then subtract a month to get the 1st day of the previous month
          let firstDayOfPreviousMonth = today
            .clone()
            .subtract(1, "month")
            .startOf("month")
            .toDate();
          let endDayOfPreviousMonth = today
            .clone()
            .subtract(1, "month")
            .endOf("month")
            .toDate();
          let startDateOfMonth = moment(firstDayOfPreviousMonth)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          let endDateOfMonth = moment(endDayOfPreviousMonth)
            .utc()
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
          let str = `${startDateOfMonth},${endDateOfMonth}`;
          tempfilterObj["filters[date][$between]"] = `${str}` || "";
          setStartDate(startDateOfMonth);
          setEndDate(endDateOfMonth);
        }
      }
      setFilterObj(tempfilterObj);
      setAllTimePeriod(value);
    } else {
      setAllTimePeriod({ name: "All", value: 0, periodValue: "All" });

      setStartDate(null);
      setEndDate(null);
      delete tempfilterObj["filters[date][$gte]"];
      setFilterObj(tempfilterObj);
    }
  };
  const handleChangeStartDate = (e) => {
    if (e !== null && endDate !== null) {
      let tempfilterObj = { ...filterObj };

      setStartDate(e);

      let startDateOfMonth = dayjs(e).startOf("day");
      let formatedStartDate = startDateOfMonth.toISOString();

      let endDateOfMonth = dayjs(endDate).endOf("day");
      let formatedEndDate = endDateOfMonth.toISOString();

      let str = `${formatedStartDate},${formatedEndDate}`;
      tempfilterObj["filters[date][$between]"] = str || "";

      setFilterObj(tempfilterObj);
    }
  };
  const handleChangeEndDate = (e) => {
    let tempfilterObj = { ...filterObj };
    setEndDate(e);

    if (e !== null && startDate !== null) {
      let startDateOfMonth = dayjs(startDate).startOf("day");
      let formatedStartDate = startDateOfMonth.toISOString();

      let endDateOfMonth = dayjs(e).endOf("day");
      let formatedEndDate = endDateOfMonth.toISOString();

      let str = `${formatedStartDate},${formatedEndDate}`;
      tempfilterObj["filters[date][$between]"] = str || "";

      setFilterObj(tempfilterObj);
    }
  };

  const handleCloseModalFilter = () => {
    setOpenModalFilter(false);
  };
  const handleIgnoreButton = () => {
    setsociallink(false);
  };
  const resetHandler = () => {
    setFilterObj({});
    setDraftState(false);
    setsourceId([]);
    setPageNumber(0);
    setSelectedTags(null);
    setSelectedGroup(null);
    setSelectedReply({ name: "All", value: 2 });
    setRatingsValue([]);
    setAllTimePeriod({ name: "All", value: 0, periodValue: "All" });
    // setSelectedLocation([]);
    setCompanyId(null);
    setLocationId([]);
    setCallApi(true);
  };
  const handleGoogleLocationChange = (option) => {
    setLocationId(option);
  };
  const getOldReviewData = async () => {
    let locationIds = [];
    if (locationId.length > 0) {
      locationId?.map((location) => {
        locationIds.push(location?.id);
      });
    }
    try {
      setDumpReviewsLoading(true);
      const res = await api.get(
        `google/dumpReviews?locationIds=${
          locationIds ? locationIds : ""
        }&all=${checked}`
      );
      if (res.status === 200) {
        setDumpReviewsLoading(false);
        setOpenDefaultModel(false);
        setChecked(true);
        toast.success(res?.data?.message, {});
      }
    } catch (error) {
      setDumpReviewsLoading(false);
      setChecked(false);
      setOpenDefaultModel(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOpenTemplateMenu = () => {
    setOpenIframeModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };
  // const handleCompanyChange = (option) => {
  //   setCompanyId(option);
  //   setLocationId(null);

  //   let ids = option?.id;
  //   let tempfilterObj = { ...filterObj };
  //   tempfilterObj["companyId"] = ids || "";
  //   setFilterObj(tempfilterObj);

  //   if (allCompanies?.results?.locations?.length !== 0) {
  //     setAllLocation(option?.locations);
  //   } else {
  //     setAllLocation([]);
  //   }
  // };
  const handleCompanyChange = (option) => {
    setCompanyId(option);
    setLocationId(null);

    let ids = option?.id;
    let tempfilterObj = { ...filterObj };
    tempfilterObj["companyId"] = ids || "";
    setFilterObj(tempfilterObj);

    let arr = [];
    let groupArr = [];
    if (option !== null && option?.locations?.length !== 0) {
      const googleConnectedLocations = option.locations?.forEach((item) => {
        if (item?.socialLink?.length > 0) {
          item?.socialLink?.forEach((itemTwo) => {
            if (itemTwo?.type === "google") {
              arr?.push(item);
            }
          });
        }
      });

      setAllLocation({ results: arr });
    } else {
      setAllLocation(allLocationData);
    }

    if (option !== null && option?.groups?.length !== 0) {
      option.groups?.forEach((item) => {
        groupArr?.push(item);
      });

      setAllGroups(groupArr);
    } else {
      setAllGroups([]);
    }
  };

  const handleChangeGroups = (value) => {
    setPageNumber(0);
    setSelectedGroup(value);
  };
  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("Reviews_Tutorial")}
            // description={<iframe src="https://ask.obenan.com/f8yxuj4dh"
            // allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            // width="100%"
            // height="600px"
            // style="border: none; border-radius: 24px"
            // >
            // </iframe>}

            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f4tkmskma"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1200px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
          {/* <iframe src="https://ask.obenan.com/f8yxuj4dh"
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
            width="100%"
            height="600px"
            style="border: none; border-radius: 24px"
            >
            </iframe> */}
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseFetchReviewsMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <FetchModel
            title={t("Import_Google_Old_Reviews")}
            description={
              <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Checkbox
                      sx={{ paddingLeft: "0px" }}
                      checked={checked}
                      onChange={(e) => handleChangeCheckButton(e)}
                      inputProps={{ True: "False" }}
                    />
                    <Typography className={classes.subHeadFetch}>
                      All connected locations
                    </Typography>
                  </Box>
                  {!checked === true && (
                    <>
                      <Autocomplete
                        multiple
                        disablePortal
                        id="combo-box-demo"
                        options={
                          allGoogleLocation?.results?.length > 0
                            ? allGoogleLocation?.results
                            : []
                        }
                        getOptionLabel={(option) =>
                          option?.internalName
                            ? option?.internalName +
                              " " +
                              "(" +
                              (option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? option?.addressLine1 + "," + " "
                                : "") +
                              (option?.city ? option?.city : "") +
                              ")"
                            : ""
                        }
                        className="Autocomplete-field"
                        value={
                          selectedLocation?.length > 0 ? selectedLocation : []
                        }
                        // value={selectedLocation}
                        onChange={(e, value) => {
                          setSelectedLocation(value);
                          handleGoogleLocationChange(value);
                          setLocationRequired(false);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {`${option?.internalName} (${
                              option?.addressLine1 !== "" &&
                              option?.addressLine1 !== null &&
                              option?.addressLine1 !== undefined
                                ? option?.addressLine1 + "," + " "
                                : ""
                            } ${option.city})`}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("SelectLocation")}
                            {...params}
                            required
                            error={locationRequired}
                            name="language"
                          />
                        )}
                      ></Autocomplete>
                    </>
                  )}
                  {allLocation?.count > allGoogleLocation?.count > 0 && (
                    <>
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          background: "#FFF1D7",
                          padding: "10px",
                          borderRadius: "8px",
                          marginTop: "15px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          width: "99.9%",
                        }}
                      >
                        <Grid item xs={1} sm={1} lg={1} md={1}>
                          <WarningIcon sx={{ color: "#F8A92B" }} />
                        </Grid>
                        <Grid item xs={11} sm={11} lg={11} md={11}>
                          <Typography sx={{ color: "#33250B" }}>
                            {t("alert_review_text")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {/* <Typography className={classes.subHeadFetch}>Also auto-reply on fetched reviews</Typography> */}
                </Grid>
              </Grid>
            }

            // onConfirm={ () => getOldReviewData() }
            // onCancel={() => navigate("/user/add-location")}
          />
          <DialogActions>
            <Button
              autoFocus
              onClick={handleCloseFetchReviewsMenu}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>
            {allLocation?.count > allGoogleLocation?.count > 0 && (
              //   <>
              //     <Button
              //       onClick={() => navigate("/user/locations")}
              //       disabled
              //       className="delete_button"
              //       autoFocus
              //     >
              //       {t("Go to Location")}
              //     </Button>
              //   </>
              // ) : (
              <Button
                onClick={() => navigate("/admin/locations")}
                className="delete_button"
                autoFocus
              >
                {t("Go to Location")}
              </Button>
            )}
            <Button
              autoFocus
              /*   disabled={
                locationId?.length===0 || checked===false ? true : false
              } */
              onClick={() => getOldReviewData()}
              className="delete_button"
            >
              {t("Fetch_Reviews")}
            </Button>
          </DialogActions>

          {/* <Delete
            title={t("Import_Google_Old_Reviews")}
            description={t("reviws_desc")}
            onConfirm={() => getOldReviewData()}
            onCancel={handleCloseFetchReviewsMenu}
          /> */}
        </Dialog>
      )}
      {openModalFilter && (
        <FiltersModel
          openModalFilter={openModalFilter}
          handleCloseModalFilter={handleCloseModalFilter}
          allLocation={allLocation}
          handleLocationChange={handleLocationChange}
          allReviewSource={allReviewSource}
          handleSourceChange={handleSourceChange}
          starRatings={starRatings}
          handleChangeRatings={handleChangeRatings}
          replyChangeHandler={replyChangeHandler}
          haventReplied={haventReplied}
          tagsChangeHandler={tagsChangeHandler}
          handleChangeTime={handleChangeTime}
          tags={tags}
          filterObj={filterObj}
          sourceId={sourceId}
          locationId={locationId}
          selectedTags={selectedTags}
          selectedReply={selectedReply}
          ratingsValue={ratingsValue}
          resetHandler={resetHandler}
          allTimePeriod={allTimePeriod}
          handleChangeGroups={handleChangeGroups}
          allGroups={allGroups}
          selectedGroup={selectedGroup}
          setCallApi={setCallApi}
          allCompanies={allCompanies}
          handleCompanyChange={handleCompanyChange}
          companyId={companyId}
          startDate={startDate}
          handleChangeEndDate={handleChangeEndDate}
          endDate={endDate}
          handleChangeStartDate={handleChangeStartDate}
          setPageNumber={setPageNumber}
        />
      )}
      {allLocation?.results?.length <= 0 ? (
        <>
          <TitleHeader
            title={t("Reviews")}
            subHeading={t("subtitle_Reviews")}
            IframeButton={true}
            IframeButtonName={
              <Tooltip title={t("Help")}>
                <img alt="" src={QuestionMark} />
              </Tooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
            addPermission={true}
            viewPermission={true}
          />
        </>
      ) : socialLink ? (
        <>
          <TitleHeader
            title={t("Reviews")}
            subHeading={t("subtitle_Reviews")}
            IframeButton={true}
            IframeButtonName={
              <Tooltip title={t("Help")}>
                <img alt="" src={QuestionMark} />
              </Tooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
            addPermission={true}
            viewPermission={true}
          />
        </>
      ) : (
        <>
          <TitleHeader
            title={t("Reviews")}
            subHeading={t("subtitle_Reviews")}
            createButton="true"
            extraButton={false}
            extraButtonName={t("Import_Google_Old_Reviews")}
            handleDefaultTemplate={handleOpenFetchReviewsMenu}
            onClick={addMoreReviewSource}
            name={t("Add_Review_Source")}
            IframeButton={true}
            IframeButtonName={
              <Tooltip title={t("Help")}>
                <img alt="" src={QuestionMark} />
              </Tooltip>
            }
            handleDefaultIframe={handleOpenTemplateMenu}
            dumpReviewsLoading={dumpReviewsLoading}
            addPermission={true}
            viewPermission={true}
          />
        </>
      )}

      {allLocation?.results?.length <= 0 ? (
        <>
          <Box className={classes.locationbox}>
            <LocationNotFound
              text={t("No Location Added")}
              subtext={"Please add a location to get reviews from Google."}
            />
            <CommonButton
              onSubmit={() => navigate("/admin/add-location")}
              label={t("Add Location")}
            />
          </Box>
        </>
      ) : socialLink ? (
        <>
          <>
            <Box className={classes.locationbox}>
              <NotConnected
                text={t("Connect_With_Google")}
                subtext={t("Nolocation_subhead_reviews")}
              />
              <Grid container>
                <Grid item sm={1} md={1} lg={1}></Grid>
                <Grid item sm={10} md={10} lg={10}>
                  <Box sx={{ display: "flex" }}>
                    <CommonButton
                      onSubmit={handleIgnoreButton}
                      alignment={true}
                      label={t("Ignore_for_Now")}
                    />

                    <CommonButton
                      alignment={true}
                      onSubmit={() =>
                        navigate(
                          `/admin/edit_location?state=${defaultvaluelocation}/`,
                          {
                            state: {
                              showProduct: true,
                              displayOn: false,
                            },
                          }
                        )
                      }
                      icon={
                        <img
                          alt=""
                          src={Googleicon}
                          style={{ paddingRight: "8px" }}
                        />
                      }
                      label={t("Connect_With_Google")}
                    />
                  </Box>
                </Grid>
                <Grid item sm={1} md={1} lg={1}></Grid>
              </Grid>
            </Box>
          </>
        </>
      ) : (
        <>
          <Grid container spacing={2} sx={{ mb: "3%" }}>
            {!isLoading && (
              <Grid item xs={3} sm={3} md={2.5} lg={2.5}>
                <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  // sx={{ height: "100px" }}
                  options={
                    allCompanies?.results?.length > 0
                      ? allCompanies?.results
                      : []
                  }
                  getOptionLabel={(option) => option?.name}
                  className="Autocomplete-field"
                  value={companyId}
                  onChange={(e, value) => {
                    handleCompanyChange(value);
                    setCallApi(false);
                    // setCompanyIdRequired(false);
                  }}
                  /*  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} `}
                    </li>
                  )} */
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Company")}
                      {...params}
                      required
                      // label={t("TaskCompany")}
                      name="language"
                      //   error={companyIdRequired}
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={3} sm={3} md={2.5} lg={2.5}>
                <Autocomplete
                  multiple
                  error={locationRequired}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allLocation?.results?.length > 0 ? allLocation?.results : []
                  }
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  // sx={{ width: "66%", bgcolor: "white" }}
                  sx={{ bgcolor: "white" }}
                  value={locationId ? locationId : []}
                  onChange={(e, value) => {
                    handleLocationChange(value);
                    setLocationRequired(false);
                    setCallApi(false);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} (${
                        option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : ""
                      } ${option.city})`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={3} sm={3} md={1.5} lg={2.5}>
                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  value={sourceId ? sourceId : []}
                  options={
                    allReviewSource.results?.length > 0
                      ? allReviewSource?.results
                      : []
                  }
                  getOptionLabel={(option) => option?.name}
                  sx={{ bgcolor: "white" }}
                  onChange={(e, value) => {
                    // setAutocompleteValue(value);
                    handleSourceChange(value);
                    setLocationRequired(false);
                    setCallApi(false);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.name}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Review_Source")}
                      {...params}
                      required
                      error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}

            {!isLoading && (
              <Grid item xs={3} sm={3} md={2.5} lg={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allGroups !== null && allGroups?.length > 0 ? allGroups : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  className="Autocomplete-field"
                  value={selectedGroup}
                  onChange={(e, value) => {
                    handleChangeGroups(value);
                    setCallApi(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_group")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}

            {!isLoading && (
              <Grid
                item
                xs={12}
                sm={2}
                md={2}
                lg={1.5}
                sx={{ textAlign: "end" }}
              >
                <CommonButton
                  //  displayBlack="true"
                  //  icon={<RefreshIcon />}
                  label={t("Apply_filters")}
                  onSubmit={handleApplyFilter}
                />
              </Grid>
            )}
            {!isLoading && (
              <Grid item xs={12} sm={2} md={2} lg={1} sx={{ textAlign: "end" }}>
                <CommonButton
                  displayBlack="true"
                  // onSubmit={onCancel}
                  // disabled={isSubmitting}
                  icon={<RefreshIcon />}
                  label={t("Reset")}
                  onSubmit={resetHandler}
                />
              </Grid>
            )}
            {!isLoading && (
              <Grid
                item
                container
                spacing={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Box sx={{ ml: 4 }}>
                    <Button
                      sx={{ mt: 2 }}
                      onClick={() => {
                        handleOpenEditUser();
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          lineHeight: "20px",
                        }}
                      >
                        {t("View_More_Filters")}
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>

          {reviewsLoading ? (
            <Loader />
          ) : (
            <>
              {reviewsData?.results?.length > 0 ? (
                <>
                  {/* {displayReconnectError && (
            <Grid container sx={{ marginBottom: "5px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <ReconnectCard
                  location={expiredLocations}
                  displayReconnectButton={displayReconnectButton}
                />
              </Grid>
            </Grid>
          )} */}
                  <Stack
                    marginBottom="2rem"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography variant="h1">
                      {reviewsData?.count} &nbsp;
                      {t("Reviews")}
                    </Typography>
                  </Stack>

                  {/* <InfiniteScroll
                  dataLength={reviewsData?.totalReviewCount}
                  next={fetchMoreData}
                  hasMore={token !== null}
                  loader={<Loader />}
                > */}

                  <Virtuoso
                    data={reviewsData?.results}
                    endReached={fetchMoreData}
                    itemContent={(index) => (
                      <div className="scrollLeft">
                        <div>
                          <SourceReviewsCard
                            item={reviewsData?.results?.[index]}
                            allReviewSource={allReviewSource?.results}
                            refreshTags={refreshTags}
                            tags={tags}
                            getLocationGoogleReview={getLocationGoogleReview}
                            allTemplate={allTemplate}
                            users={users}
                            locations={allLocation?.results}
                            allData={reviewsData}
                            setReviewsData={setReviewsData}
                            index={index}
                          />
                        </div>
                        <Divider
                          variant="middle"
                          sx={{ marginBottom: "10px" }}
                        />
                      </div>
                    )}
                  />
                </>
              ) : (
                <NotFound text={t("No_Reviews_Yet")} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default GoogleReviews;
