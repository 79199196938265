import React, { useState, useEffect } from "react";
import { api } from "../../../../../contexts/JWTContext";
import moment from "moment";
import Grid from "@mui/material/Grid";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useParams } from "react-router-dom";
import DraftsIcon from "@mui/icons-material/Drafts";
import { CircularProgress } from "@mui/material";
import {
  Typography,
  TextField,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Modal,
  Divider,
  Select,
  FormControl,
  Autocomplete,
  Stack,
  Paper,
  Checkbox,
} from "@mui/material";
import GoogleLogo from "../../../../../assets/icon-google.svg";
import { toast } from "react-toastify";
import StarRatings from "react-star-ratings";
import { styled } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ReviewsCard from "../../../../../components/ReviewSource/ReviewsCard";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../../hooks/useAuth";
import Editors from "../../../../../components/CustomComponents/Editor/Editors";
import CancelButton from "../../../../../components/CustomComponents/Buttons/CancelButton";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Loader from "../../../../../components/Loaders/Loader";
import { useStyles } from "../../Style/style";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const baseURL = process.env.REACT_APP_BASE_URL;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "400px",
  maxHeight: "99vh",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const dummyText = ["google", "yelp", "data", "fakeReview", "Amstradem"];

function GoogleReviews({
  users,
  closeTicketModelHanlder,
  item,
  createTaskHandler,
  updateTaskHandler,
  isLoading,
  statusRequired,
  statusData,
  handleChangeStatus,
  setUserRequired,
  handleChangeUser,
  setSelectedDueDate,
  selectedDueDate,
  descriptionRequired,
  commentChangeHandler,
  addedComment,
  selectedUser,
  userRequired,
  selectedReviewItem,
  setStatusData,
  setAddedComment,
  setSelectedUser,
  reviewLocation,
  setReviewLocation,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useAuth();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const { id } = params;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  const getTaskById = async (taskID) => {
    setLoading(true);
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/tasks/${taskID}`);
      if (res.status === 200) {
        setStatusData(res?.data?.data?.status);
        setAddedComment(res?.data?.data?.description);
        let tempUsersArray = [];
        res?.data?.data?.taskUsers?.length > 0 &&
          res?.data?.data?.taskUsers?.map((item) => {
            tempUsersArray.push(item?.user);
          });
        setSelectedUser(tempUsersArray);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (item?.task?.id !== null) {
      getTaskById(item?.task?.id);
    }
  }, []);
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {loading ? (
        <Grid
          container
          sx={{
            height: "600px",
            justifyContent: "center",
            alignItems: "center",
            width: "582px",
          }}
        >
          <Loader />
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <>
            <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
              {item?.task === null ? t("Add_Task") : "Edit Task"}
            </Typography>
            {/* <Divider variant="middle" /> */}
            <hr />

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",
                    marginBottom: "8px",
                  }}
                >
                  {t("Status")}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    error={statusRequired}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statusData}
                    defaultValue={
                      item?.task !== null ? item?.task?.status : statusData
                    }
                    // label={"Title"}
                    onChange={handleChangeStatus}
                  >
                    <MenuItem value={"open"}>{t("Open")}</MenuItem>
                    <MenuItem value={"inprogress"}>{t("InProgress")}</MenuItem>
                    <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Typography className={classes.textAssignedto}>
                  {t("Assigned_To")}
                </Typography>

                {users?.results?.length > 0 && (
                  <Autocomplete
                    multiple
                    disablePortal
                    // defaultValue={}
                    value={selectedUser}
                    id="combo-box-demo"
                    options={users?.results ? users?.results : []}
                    getOptionLabel={(option) => option?.firstName}
                    sx={{ width: "100%" }}
                    // value={companyId}
                    onChange={(e, value) => {
                      if (value) {
                        setUserRequired(false);
                      } else {
                        setUserRequired(true);
                      }
                      setUserRequired(false);
                      handleChangeUser(value);
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.firstName + " " + option?.lastName}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // label={t("TaskCompany")}
                        name="language"
                        error={userRequired}
                        placeholder={t("Select_User")}
                      />
                    )}
                  ></Autocomplete>
                )}
                {userRequired && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#FF0000",
                      lineHeight: "20px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("req_field")}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {item?.task === null && (
              <Grid item sm={12} md={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",

                    marginTop: "8px",
                  }}
                >
                  {t("Due_Date")}
                </Typography>
                <Select
                  required
                  style={{ width: "100%" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedDueDate}
                  onChange={(e, value) => {
                    setSelectedDueDate(e.target.value);
                  }}
                >
                  <MenuItem value={"3"}>{t("3-Days")}</MenuItem>
                  <MenuItem value={"7"}>{t("7-Days")}</MenuItem>
                </Select>
              </Grid>
            )}

            <Grid item sm={12} md={12}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#1B2430",
                  lineHeight: "20px",

                  marginTop: "8px",
                }}
              >
                {t("Description")}
              </Typography>
              <Grid
                container
                sx={{
                  marginBottom: "1rem",
                }}
              >
                <Grid item md={12}>
                  <Editors
                    error={descriptionRequired}
                    onChange={commentChangeHandler}
                    value={addedComment}
                    defaultValue={item?.task?.description}
                  />
                  {descriptionRequired && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#FF0000",
                        lineHeight: "20px",
                        marginBottom: "8px",
                      }}
                    >
                      {t("Field_req")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>

          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{ marginX: "auto", marginTop: "16px" }}
            >
              <ReviewsCard
                closeTicketModelHanlder={closeTicketModelHanlder}
                item={selectedReviewItem}
                location={reviewLocation}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
            <Grid item md={3} lg={3}></Grid>
            <Grid item xs={12} sm={12} md={9} lg={9}>
              <Box sx={{ float: "right", display: "flex", gap: "8px" }}>
                <>
                  <CommonButton
                    aria-label="save"
                    size="large"
                    onSubmit={closeTicketModelHanlder}
                    label={t("Cancel")}
                    displayWhite="true"
                  />

                  <CommonButton
                    isLoading={isLoading}
                    aria-label="save"
                    size="large"
                    onSubmit={
                      item?.task === null
                        ? createTaskHandler
                        : updateTaskHandler
                    }
                    label={item?.task === null ? t("Add_Task") : "Update Task"}
                  />
                </>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default GoogleReviews;
