import React from "react";
import { Skeleton, Grid } from "@mui/material";
import { useStyles } from "../../Style/style";

const GridSkeletonLoader = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container>
        <Grid item xs={3} sm={3} md={3} lg={3} className={classes.mainGridCont}>
          <Skeleton animation="wave" variant="text" />
        </Grid>
        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Skeleton animation="wave" variant="text" />
        </Grid>
        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Skeleton animation="wave" variant="text" />
        </Grid>
        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Skeleton animation="wave" variant="text" />
        </Grid>

        <Grid
          item
          xs={2.25}
          sm={2.25}
          md={2.25}
          lg={2.25}
          className={classes.mainGridCont}
        >
          <Skeleton animation="wave" variant="text" />
        </Grid>
      </Grid>
      <Grid container>
        <>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            sx={{
              padding: "16px",
              paddingBottom: "10px",
            }}
          >
            <Skeleton animation="wave" variant="text" />
          </Grid>
          <Grid
            item
            xs={2.25}
            sm={2.25}
            md={2.25}
            lg={2.25}
            className={classes.mainGridCont}
          >
            <Skeleton animation="wave" variant="text" />
          </Grid>
          <Grid
            item
            xs={2.25}
            sm={2.25}
            md={2.25}
            lg={2.25}
            className={classes.mainGridCont}
          >
            <Skeleton animation="wave" variant="text" />
          </Grid>
          <Grid
            item
            xs={2.25}
            sm={2.25}
            md={2.25}
            lg={2.25}
            className={classes.mainGridCont}
          >
            <Skeleton animation="wave" variant="text" />
          </Grid>
          <Grid
            item
            xs={2.25}
            sm={2.25}
            md={2.25}
            lg={2.25}
            className={classes.mainGridCont}
          >
            <Skeleton animation="wave" variant="text" />
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default GridSkeletonLoader;
