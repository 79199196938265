import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
// import { Legend, CartesianGrid, XAxis, YAxis, LineChart, Line  } from 'recharts';
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Grid, Checkbox, Typography, Box } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../../UserListing/Styles/style";

import VisibilityIcon from "@mui/icons-material/Visibility";
import BouncingDotsLoader from "../../../../components/Loaders/smallBounceLoader";
import { WaveSpinner } from "../../../../components/Loaders/wavaloader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BuisnessConversions = ({
  googleInsightData,
  data,
  ratingLoading,
  metricsLoading,
  checkNumber,
  type,
  colors,
  kFormatter,
  totalValueConversion,
  dataSet3,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [buisnessPercentage, setBuisnessPercentage] = useState(0);
  const [bookingPercentage, setBookingPercentage] = useState(0);
  const [foodPercentage, setFoodPercentage] = useState(0);
  const [businessData, setBusinessData] = useState(null);
  const [foodOrderData, setFoodOderData] = useState(null);
  const [bookingData, setBookingData] = useState(null);

  useEffect(() => {
    if (googleInsightData !== null && googleInsightData?.length > 0) {
      const businessDummyData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_CONVERSATIONS"
      );
      setBusinessData(businessDummyData);
      if (businessDummyData !== undefined) {
        const businesssPercentage = (
          (businessDummyData?.metricValue / totalValueConversion) *
          100
        ).toFixed();

        setBuisnessPercentage(businesssPercentage);
      }
      const bookingsData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_BOOKINGS"
      );
      setBookingData(bookingsData);
      if (bookingsData !== undefined) {
        const bookingPer = (
          (bookingsData?.metricValue / totalValueConversion) *
          100
        ).toFixed();

        setBookingPercentage(bookingPer);
      }
      const bookingFoodData = googleInsightData?.find(
        (item) => item?.metricType === "BUSINESS_FOOD_ORDERS"
      );
      setFoodOderData(bookingFoodData);
      if (bookingFoodData !== undefined) {
        const bookingFoodPer = (
          (bookingFoodData?.metricValue / totalValueConversion) *
          100
        ).toFixed();

        setFoodPercentage(bookingFoodPer);
      }
    } else {
      setBusinessData(null);
      setBuisnessPercentage(0);
      setBookingData(null);
      setBookingPercentage(0);
      setFoodOderData(null);
      setFoodPercentage(0);
    }
  }, [totalValueConversion]);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <>
      <Grid container className={classes.resultsMainGrid}>
        <Box className={classes.toast}>
          <Box className={classes.toastBox}>
            <Box className={classes.toastBoxBorder}></Box>
            <Box className={classes.toastBoxContent}>
              <Box className={classes.toastBoxText}>
                <InfoOutlinedIcon sx={{ color: "#06BDFF" }} />
                <Box sx={{ display: "flex" }}>
                  <Typography>{t("food_info_box")}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container>
          <Grid xs={12} sm={12} md={12} lg={12}>
            {" "}
            <Typography className={classes.totalValueText}>
              {kFormatter(totalValueConversion)}
            </Typography>
            <Typography className={classes.totalValuesubText}>
              <VisibilityIcon
                fontSize="small"
                className={classes.visibilityIcon}
              />{" "}
              {t("Profile_subtext_conversion")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/*   <CustomDirectionCheckbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}{" "}
            {t("Buisness_Conversations")}{" "}
            <Typography className={classes.headingConversion}>
              {isNaN(buisnessPercentage) ? "0" : buisnessPercentage + "%"}
            </Typography>
            {/* <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("tooltipText4")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip> */}
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*  {graphData?.ACTIONS_DRIVING_DIRECTIONS} */}
                {businessData !== null
                  ? kFormatter(businessData?.metricValue)
                  : "0"}
              </Typography>
              {businessData?.BUSINESS_CONVERSATIONS_PERCENTAGE !== "NaN" &&
                businessData?.BUSINESS_CONVERSATIONS_PERCENTAGE !== "0.00" && (
                  <Box className={classes.percentageBox}>
                    {colors === "positive" ? (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ color: "#13CF8F" }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ color: "#FC3652" }}
                      />
                    )}

                    <Typography
                      className={
                        colors === "positive"
                          ? classes.chartSubHeadingPercentPositive
                          : classes.chartSubHeadingPercentNegative
                      }
                    >
                      {businessData !== null
                        ? checkNumber(
                            businessData?.BUSINESS_CONVERSATIONS_PERCENTAGE !==
                              "NaN"
                              ? businessData?.BUSINESS_CONVERSATIONS_PERCENTAGE
                              : "0"
                          ) + "%"
                        : "0"}
                    </Typography>
                  </Box>
                )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/* <Checkbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Buisness_Bookings")}{" "}
            <Typography className={classes.headingWeb}>
              {isNaN(bookingPercentage) ? "0" : bookingPercentage + "%"}
            </Typography>
            {/* <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("toolTipText3")}</h1>}
            >
              <IconButton>
                <InfoIcon className={classes.tooltipstyle} />
              </IconButton>
            </BootstrapTooltip> */}
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*   {graphData?.ACTIONS_WEBSITE} */}
                {bookingData !== null
                  ? kFormatter(bookingData?.metricValue)
                  : "0"}
              </Typography>
              {bookingData?.BUSINESS_BOOKINGS_PERCENTAGE !== "NaN" &&
                bookingData?.BUSINESS_BOOKINGS_PERCENTAGE !== "0.00" && (
                  <Box className={classes.percentageBox}>
                    {colors === "positive" ? (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ color: "#13CF8F" }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ color: "#FC3652" }}
                      />
                    )}

                    <Typography
                      className={
                        colors === "positive"
                          ? classes.chartSubHeadingPercentPositive
                          : classes.chartSubHeadingPercentNegative
                      }
                    >
                      {bookingData !== null
                        ? checkNumber(
                            bookingData?.BUSINESS_BOOKINGS_PERCENTAGE !== "NaN"
                              ? bookingData?.BUSINESS_BOOKINGS_PERCENTAGE
                              : "0"
                          ) + "%"
                        : "0"}
                    </Typography>
                  </Box>
                )}
            </Grid>
          )}
        </Grid>

        <Grid item xs={6} sm={6} md={4} lg={4}>
          <Typography className={classes.headingChartBox}>
            {/*  <Checkbox
                //  color="#13c552"
                //  checked={checked}
                //  onChange={(e) => handleChangeCheckButton(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: "0px", transform: "scale(0.7)" }}
              /> */}
            {t("Buisness_Food_order")}{" "}
            <Typography className={classes.headingMobileMap}>
              {isNaN(foodPercentage) ? "0" : foodPercentage + "%"}
            </Typography>
            {/* <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{t("tooltiptext5")}</h1>}
            >
              <IconButton>
                <InfoIcon
                  sx={{
                    fontSize: "large",
                    paddingBottom: "3px",

                    color: "#8D9298",
                  }}
                />
              </IconButton>
            </BootstrapTooltip> */}
          </Typography>

          {ratingLoading ? (
            <BouncingDotsLoader />
          ) : (
            <Grid className={classes.mainGridCharts}>
              <Typography className={classes.chartSubHeading}>
                {/*   {graphData?.ACTIONS_PHONE} */}
                {foodOrderData !== null
                  ? kFormatter(foodOrderData?.metricValue)
                  : "0"}
              </Typography>
              {foodOrderData?.BUSINESS_FOOD_ORDERS_PERCENTAGE !== "NaN" &&
                foodOrderData?.BUSINESS_FOOD_ORDERS_PERCENTAGE !== "0.00" && (
                  <Box className={classes.percentageBox}>
                    {colors === "positive" ? (
                      <ArrowUpwardIcon
                        fontSize="small"
                        sx={{ color: "#13CF8F" }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        fontSize="small"
                        sx={{ color: "#FC3652" }}
                      />
                    )}

                    <Typography
                      className={
                        colors === "positive"
                          ? classes.chartSubHeadingPercentPositive
                          : classes.chartSubHeadingPercentNegative
                      }
                    >
                      {foodOrderData !== null
                        ? checkNumber(
                            foodOrderData?.BUSINESS_FOOD_ORDERS_PERCENTAGE !==
                              "NaN"
                              ? foodOrderData?.BUSINESS_FOOD_ORDERS_PERCENTAGE
                              : "0"
                          ) + "%"
                        : "0"}
                    </Typography>
                  </Box>
                )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} sm={12} lg={12} className={classes.graphGrid}>
        {metricsLoading ? (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "300px",
              alignItems: "center",
              borderLeft: "1px solid #9d9d9d",
              borderBottom: "1px solid #9d9d9d",
              paddingX: "8px",
            }}
          >
            <WaveSpinner />

            {/*   <DominoSpinner /> */}
          </Box>
        ) : (
          <Line data={dataSet3} options={options} height={100} />
        )}
      </Grid>
    </>
  );
};

export default BuisnessConversions;
