import React, { useEffect, useState } from "react";
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Divider as MuiDivider,
    Tooltip,
    tooltipClasses,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Button,
    Paper,
    Box,
} from "@mui/material";
import Deletes from "./Deletes.css";
import { useTranslation } from "react-i18next";

const ImportModal = ({ title, description, onConfirm, onCancel }) => {
    const [open, setOpen] = useState(false);
    const [deletePost, setDeletePost] = useState(null);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div>
                <Box className="box_delete">
                    <DialogContentText
                        id="responsive-dialog-title"
                        sx={{}}
                        className="delete_dialog_content1"
                    >
                        {title}
                    </DialogContentText>
                    <DialogContentText
                        id="responsive-dialog-title"
                        className="delete_dialog_content2"
                    // sx={{ height: "150px" }}
                    >
                        {description}
                    </DialogContentText>
                    <DialogActions>
                        <Button autoFocus onClick={onCancel} sx={{ marginTop: "18px" }} className="delete_button">
                            {t("Cancel")}
                        </Button>

                        <Button onClick={onConfirm} sx={{ marginTop: "18px" }} className="delete_button" autoFocus>
                            {t("Confirm")}
                        </Button>
                    </DialogActions>
                </Box>

                {/* </DialogActions> */}
            </div>
        </>
    );
};

export default ImportModal;
