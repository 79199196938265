import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed:"#FF0000"
};

export const useStyles = makeStyles((theme) => ({
    addRuleMainBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "5px",
        marginLeft: "1.5rem",
        marginRight: "2rem",
        padding:"8px"
    },
    addRuleLocation: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: "1rem",
        marginRight: "2rem",
        padding: "1rem",
         
    },
   
    addRuleLocationTitle: {
        margin: "0 1rem",
    padding: "2rem",
    borderRadius: "10px",
    backgroundColor: "#E0E0E0",
        
    },
    Condition: {
        backgroundColor: "#E0E0E0",
        padding: "2rem",
        margin: "0 1rem",
        borderRadius:"10px"
    },
Reviews: {
    marginBottom: "1rem",
    marginLeft:"3rem"
    },
    Rating: {
  marginBottom:"1rem"
    },
    Auto_Reply: {
        marginTop: "2rem",
        padding: "1rem",
        display: "flex",
        justifyContent:"space-evenly",
        alignItems:"center"
    },
    mainContainer: {
    background: "#FDEDED",
    padding: "6px 16px",
    borderRadius: "7px",
    marginBottom: "10px",
  },
  errorText: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    lineHeight: "1.43",
    color: "#ef5350",
    padding: "6px 0px",
  },
  errorIcon: {
    color: "#ef5350",
    marginTop: "5px",
  },
  sourceImage: {
    width: "40px",
    height: "40px",
  },
  sourceName: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  subscribedText: {
    background: "#06BDFF",
    marginRight: "12px",
    color: "#FFFFFF",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "14px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "3px",
    gap: "4px",
    width: "67px",
    height: "20px",
    borderRadius: "800px",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    },
    locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  Auto_Reply: {
    marginTop: "2rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-evenly"
   } ,
Reviews: {
  marginBottom: "1rem",
  marginLeft:"3rem"
},
  Auto_Reply: {
    marginTop: "2rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-evenly"
  },
  Rating: {
    marginBottom:"1rem"
  },

  
}));
