import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const BrandTags = ({ locationDetailsData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.tagsBoxCategories}>
          {locationDetailsData?.result?.uberall?.brands?.length > 0
            ? locationDetailsData?.result?.uberall?.brands.map((brands) => (
                <Item className={classes.tagsItemBrand}>
                  <Typography className={classes.tagsTextBrands}>
                    {brands}
                  </Typography>
                </Item>
              ))
            : "--"}
        </Box>
      </Grid>
    </Grid>
  );
};

export default BrandTags;
