import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Paper,
  Button,
  Dialog,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useStyles } from "../../../../Styles/style";
import { useTranslation } from "react-i18next";
import RemoveIcon from "@mui/icons-material/Remove";
const Hours = ({ locationDetailsData, location, item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [timesArr, setTimesArr] = useState([]);

  useEffect(() => {
    handleDisplayData();
  }, [location, locationDetailsData]);

  const handleDisplayData = () => {
    const finalFilteredData = [];
    const arr2 = [];
    let periodsData = item?.periods;
    periodsData?.forEach((itemOne) => {
      let openTimes = [];
      let closeTimes = [];
      let mergedTimes = [];
      periodsData?.forEach((itemtwo) => {
        if (
          itemOne?.openDay === itemtwo?.openDay &&
          itemOne?.closeDay === itemtwo?.closeDay
        ) {
          openTimes?.push(itemtwo.openTime);
          closeTimes.push(itemtwo.closeTime);
          const obj = {
            openHours: itemtwo.openTime,
            closedHours: itemtwo.closeTime,
          };
          mergedTimes.push(obj);
        }
      });
      itemOne.mergedTimes = [...mergedTimes];
      finalFilteredData.push(itemOne);

      finalFilteredData.forEach((item) => {
        let isFound = false;
        arr2.forEach((item2) => {
          if (item.openDay === item2.openDay) {
            isFound = true;
          }
        });
        if (!isFound) {
          timesArr.push(item);
          arr2.push(item);
        }
      });
    });
    setTimesArr(arr2);
  };
  return (
    <>
      {timesArr?.length > 0 &&
        timesArr.map((periods, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} key={periods}>
            <Divider />

            <Grid container sx={{ paddingY: "16px" }}>
              <Grid item xs={6} sm={6} md={6} lg={6} sx={{ padding: "8px" }}>
                <Typography className={classes.accordianText} key={index}>
                  {periods?.openDay === "MONDAY"
                    ? t("monday")
                    : periods?.openDay === "TUESDAY"
                    ? t("tuesday")
                    : periods?.openDay === "WEDNESDAY"
                    ? t("wednesday")
                    : periods?.openDay === "THURSDAY"
                    ? t("thursday")
                    : periods?.openDay === "FRIDAY"
                    ? t("friday")
                    : periods?.openDay === "SATURDAY"
                    ? t("saturday")
                    : periods?.openDay === "SUNDAY"
                    ? t("sunday")
                    : periods?.openDay}
                </Typography>
              </Grid>

              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                lg={6}
                sx={{ textAlign: "end", padding: "8px" }}
              >
                {periods?.mergedTimes?.map((item, index) => (
                  <Typography key={index} className={classes.headName}>
                    {item?.openHours && item?.openHours?.hours
                      ? item?.openHours?.hours >= 10
                        ? item?.openHours?.hours
                        : "0" + item?.openHours?.hours
                      : "00"}
                    :
                    {item?.openHours && item?.openHours?.minutes
                      ? item?.openHours?.minutes >= 10
                        ? item?.openHours?.minutes
                        : "0" + item?.openHours?.minutes
                      : "00"}{" "}
                    -{" "}
                    {item?.closedHours && item?.closedHours?.hours
                      ? item?.closedHours?.hours >= 10
                        ? item?.closedHours?.hours
                        : "0" + item?.closedHours?.hours
                      : "00"}
                    :
                    {item?.closedHours && item?.closedHours?.minutes
                      ? item?.closedHours?.minutes >= 10
                        ? item?.closedHours?.minutes
                        : "0" + item?.closedHours?.minutes
                      : "00"}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
    </>
  );
};

export default Hours;
