export const sourcesList = [
  { reviewAgent: true, edit: false, delete: false, id: 69, name: "ubereats" },
  { reviewAgent: true, edit: true, delete: true, id: 68, name: "happycow" },
  { reviewAgent: true, edit: false, delete: true, id: 55, name: "tripadvisor" },
  { reviewAgent: true, edit: true, delete: true, id: 2, name: "yelp" },
  { reviewAgent: false, edit: false, delete: false, id: 1, name: "google" },
  { reviewAgent: false, edit: false, delete: false, id: 3, name: "agoda" },
  { reviewAgent: false, edit: false, delete: false, id: 4, name: "airbnb" },
  { reviewAgent: false, edit: false, delete: false, id: 5, name: "aliexpress" },
  { reviewAgent: false, edit: false, delete: false, id: 6, name: "alternativeto" },
  { reviewAgent: false, edit: false, delete: false, id: 7, name: "amazon" },
  { reviewAgent: false, edit: false, delete: false, id: 8, name: "angieslist" },
  { reviewAgent: false, edit: false, delete: false, id: 9, name: "apartmentratings" },
  { reviewAgent: false, edit: false, delete: false, id: 10, name: "avvo" },
  { reviewAgent: false, edit: false, delete: false, id: 11, name: "bbb" },
  { reviewAgent: false, edit: false, delete: false, id: 12, name: "bilbayt" },
  { reviewAgent: false, edit: false, delete: false, id: 13, name: "booking" },
  { reviewAgent: false, edit: false, delete: false, id: 14, name: "capterra" },
  { reviewAgent: false, edit: false, delete: false, id: 15, name: "cargurus" },
  { reviewAgent: false, edit: false, delete: false, id: 16, name: "cars" },
  { reviewAgent: false, edit: false, delete: false, id: 17, name: "citysearch" },
  { reviewAgent: false, edit: false, delete: false, id: 18, name: "classpass" },
  { reviewAgent: false, edit: false, delete: false, id: 19, name: "consumeraffairs" },
  { reviewAgent: false, edit: false, delete: false, id: 20, name: "creditkarma" },
  { reviewAgent: false, edit: false, delete: false, id: 21, name: "customerlobby" },
  { reviewAgent: false, edit: false, delete: false, id: 22, name: "dealerrater" },
  { reviewAgent: false, edit: false, delete: false, id: 23, name: "deliveroo" },
  { reviewAgent: false, edit: false, delete: false, id: 24, name: "ebay" },
  { reviewAgent: false, edit: false, delete: false, id: 25, name: "edmunds" },
  { reviewAgent: false, edit: false, delete: false, id: 26, name: "etsy" },
  { reviewAgent: false, edit: false, delete: false, id: 27, name: "expedia" },
  { reviewAgent: false, edit: false, delete: false, id: 29, name: "findlaw" },
  { reviewAgent: false, edit: false, delete: false, id: 31, name: "gartner" },
  { reviewAgent: false, edit: false, delete: false, id: 32, name: "greatschools" },
  { reviewAgent: false, edit: false, delete: false, id: 33, name: "healthgrades" },
  { reviewAgent: false, edit: false, delete: false, id: 34, name: "homeadvisor" },
  { reviewAgent: false, edit: false, delete: false, id: 35, name: "homestars" },
  { reviewAgent: false, edit: false, delete: false, id: 36, name: "hotels" },
  { reviewAgent: false, edit: false, delete: false, id: 37, name: "houzz" },
  { reviewAgent: false, edit: false, delete: false, id: 38, name: "indeed" },
  { reviewAgent: false, edit: false, delete: false, id: 39, name: "influenster" },
  { reviewAgent: false, edit: false, delete: false, id: 40, name: "lawyers" },
  { reviewAgent: false, edit: false, delete: false, id: 41, name: "lendingtree" },
  { reviewAgent: false, edit: false, delete: false, id: 42, name: "martindale" },
  { reviewAgent: false, edit: false, delete: false, id: 43, name: "niche" },
  { reviewAgent: false, edit: false, delete: false, id: 44, name: "opentable" },
  { reviewAgent: false, edit: false, delete: false, id: 45, name: "producthunt" },
  { reviewAgent: false, edit: false, delete: false, id: 46, name: "productreview" },
  { reviewAgent: false, edit: false, delete: false, id: 47, name: "realself" },
  { reviewAgent: false, edit: false, delete: false, id: 48, name: "reserveout" },
  { reviewAgent: false, edit: false, delete: false, id: 49, name: "sitejabber" },
  { reviewAgent: false, edit: false, delete: false, id: 50, name: "softwareadvice" },
  { reviewAgent: false, edit: false, delete: false, id: 51, name: "talabat" },
  { reviewAgent: false, edit: false, delete: false, id: 52, name: "thefork" },
  { reviewAgent: false, edit: false, delete: false, id: 53, name: "the_knot" },
  { reviewAgent: false, edit: false, delete: false, id: 54, name: "thumbtack" },
  { reviewAgent: false, edit: false, delete: false, id: 56, name: "trustedshops" },
  { reviewAgent: false, edit: false, delete: false, id: 57, name: "trustpilot" },
  { reviewAgent: false, edit: false, delete: false, id: 58, name: "trustradius" },
  { reviewAgent: false, edit: false, delete: false, id: 59, name: "vitals" },
  { reviewAgent: false, edit: false, delete: false, id: 60, name: "vrbo" },
  { reviewAgent: false, edit: false, delete: false, id: 61, name: "walmart" },
  { reviewAgent: false, edit: false, delete: false, id: 62, name: "wedding_wire" },
  { reviewAgent: false, edit: false, delete: false, id: 28, name: "facebook" },
  { reviewAgent: false, edit: false, delete: false, id: 65, name: "zocdoc" },
  { reviewAgent: false, edit: false, delete: false, id: 63, name: "yell" },
  { reviewAgent: false, edit: false, delete: false, id: 66, name: "zomato" },
  { reviewAgent: false, edit: false, delete: false, id: 64, name: "zillow" },
  { reviewAgent: false, edit: false, delete: false, id: 30, name: "foursquare" },
];
