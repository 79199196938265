import React from "react";
import { makeStyles } from "@mui/styles";
import { keyframes } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  bouncingLoader: {
    display: "flex",
    width: "100%",
    marginTop: "5px",
    justifyContent: "center",
  },
  divStyle1: {
    width: "8px",
    height: "8px",
    margin: "3px 6px",
    borderRadius: "50%",
    backgroundColor: "#a3a1a1",
    opacity: 1,
    animation: "$slideDown 0.6s infinite alternate",
  },
  divStyle2: {
    width: "8px",
    height: "8px",
    margin: "3px 6px",
    borderRadius: "50%",
    backgroundColor: "#a3a1a1",
    opacity: 1,
    animation: "$slideDown 0.6s infinite alternate",
    animationDelay: "0.2s",
  },
  divStyle3: {
    width: "8px",
    height: "8px",
    margin: "3px 6px",
    borderRadius: "50%",
    backgroundColor: "#a3a1a1",
    opacity: 1,
    animation: "$slideDown 0.6s infinite alternate",
    animationDelay: "0.4s",
  },
  "@keyframes slideDown": {
    to: {
      opacity: 0.1,
      transform: "translateY(-16px)",
    },
  },
}));
const BouncingDotsLoader = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.bouncingLoader}>
      <div className={classes.divStyle1}></div>
      <div className={classes.divStyle2}></div>
      <div className={classes.divStyle3}></div>
    </div>
  );
};

export default BouncingDotsLoader;
