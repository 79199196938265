import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import Deletes from "./Deletes.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import Facebook from "../../assets/images/Facebook-Logo.png";
import facebook from "../../assets/images/Facebook.svg";
import CommonButton from "../CustomComponents/Buttons/CommonButton";
import Link from "@mui/material/Link";

import { LazyLoadImage } from "react-lazy-load-image-component";
const Delete = ({
  title,
  description,
  onConfirm,
  onClose,
  loading,
  onCancel,
  indexValue,
  setSelectedTab,
  cancelState,
  touchedValue,
  setTouchedValue,
  getLocationDetails,
  location,
}) => {
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Box className="box_delete">
          <DialogTitle
            //  id="responsive-dialog-title"
            sx={{ padding: "0px" }}
            style={{ padding: "0px" }}
            // className="delete_dialog_content1"
          >
            <Grid container sx={{ justifyContent: "center", height: "65px" }}>
              <LazyLoadImage
                loading="lazy"
                src={Facebook}
                style={{
                  // maxHeight: 660,
                  // height: "412px",
                  // width: "80px",
                  height: "65px",
                  borderRadius: "8px",
                  padding: "3px",
                }}
                //  PlaceholderSrc={noImageGray}
                alt="Image Alt"
                effect="blur"
              />
            </Grid>
          </DialogTitle>
          <Divider />
          <DialogContentText
            id="responsive-dialog-title"
            sx={{ padding: "5px 14px 14px 15px" }}
            // className="delete_dialog_content2"
          >
            <Box sx={{ height: "180px" }}>
              <Grid container>
                <Grid
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  sx={{ padding: "0px 0px 2px 0px" }}
                >
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "20px",
                      fontWeight: 700,
                    }}
                  >
                    {t("Connect_facebook")}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  sx={{ padding: "0px 0px 5px 0px" }}
                >
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    {t("facebookModel_text5")}
                  </Typography>
                </Grid>
                <Grid
                  xs={12}
                  md={12}
                  sm={12}
                  lg={12}
                  sx={{
                    padding: "2px 0px 2px 0px",
                    background: "#EBEBEC",
                    width: "auto",
                    padding: "5px 3px 5px 3px",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: 700,
                    }}
                  >
                    {location?.internalName}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: 400,
                      paddingTop: "3px",
                    }}
                  >
                    {location?.addressLine1}{" "}
                    {location?.city !== null ? "," + location?.city : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Grid
              xs={12}
              md={12}
              sm={12}
              lg={12}
              sx={{ padding: "0px 0px 0px 0px", display: "flex" }}
            >
              <Typography
                sx={{
                  // color: "#1B2430",
                  fontSize: "12px",
                  fontWeight: 400,
                  color: "#a8a8a8",
                }}
              >
                {t("facebookModel_text1")}
                <Link
                  href="https://obenan.com/privacy-policy/"
                  target="_blank"
                  onClick={() => onCancel()}
                >
                  {" "}
                  {t("facebookModel_text2")}
                </Link>
              </Typography>
            </Grid>
          </DialogContentText>
          <Divider />

          <DialogActions>
            <CommonButton
              displayWhite="true"
              onSubmit={onCancel}
              // disabled={isSubmitting}
              label={t("Cancel")}
            />

            <CommonButton
              icon={
                <img
                  alt=""
                  src={facebook}
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: "3px",
                    color: "#0639c2",
                  }}
                  alt={"/google Icon"}
                />
              }
              variant="contained"
              onSubmit={onConfirm}
              label={t("Start")}
            />
          </DialogActions>
        </Box>
      </div>
    </>
  );
};

export default Delete;
