import React, { useEffect, useState } from "react";
import { Grid, Typography, Divider as MuiDivider } from "@mui/material";
import { useTranslation } from "react-i18next";

import TimePickers from "./timePickers";
import { useStyles } from "../../../../Styles/style";
import TriStateToggleButton from "./ThreeStateSwitch";

const Hours = ({
  periods,
  index,
  openTime,
  closeTime,
  handleChangeCloseTime,
  handleChangeOpenTime,
  handleChangeOpen,
  open,
  filteredPeriodsArr,
  setSelectedData,
  selectedData,
  getLocationDetails,
  handleAddHours,
  handleRemoveHours,
  openTimesError,
  setOpenTimesError,
  setCloseTimesError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={6} sm={1.5} md={1.5} lg={1.5} sx={{ paddingTop: "7px" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#1B2430",
            marginTop: "5px",
          }}
        >
          {periods?.openDay === "MONDAY"
            ? t("monday")
            : periods?.openDay === "TUESDAY"
            ? t("tuesday")
            : periods?.openDay === "WEDNESDAY"
            ? t("wednesday")
            : periods?.openDay === "THURSDAY"
            ? t("thursday")
            : periods?.openDay === "FRIDAY"
            ? t("friday")
            : periods?.openDay === "SATURDAY"
            ? t("saturday")
            : periods?.openDay === "SUNDAY"
            ? t("sunday")
            : periods?.openDay}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        sm={2}
        md={2}
        lg={2}
        sx={{ paddingTop: "7px", marginTop: "8px" }}
      >
        <Typography className={classes.switchTypo}>
          {" "}
          <TriStateToggleButton
            periods={periods}
            indexValue={index}
            handleChangeOpen={handleChangeOpen}
          />
          {/* <ThreeStateSwitchComponent
            periods={periods}
            indexValue={index}
            handleChangeOpen={handleChangeOpen}
          /> */}
          {/*  <Switch
            className={
              periods?.closed === true
                ? classes.toggleChecked
                : classes.toggleUnChecked
            }
            checked={periods?.closed === true ? false : true}
            onChange={(e) => handleChangeOpen(e, periods, index)}
          />
          {periods?.closed === false ? t("Open") : t("Closed")} */}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}>
        {periods?.closed === false &&
          periods?.mergedTimes?.map((item, ind) => (
            <Grid container key={index} sx={{ marginBottom: "10px" }}>
              <TimePickers
                periodsIndex={index}
                periods={periods}
                itemIndex={ind}
                item={item}
                handleChangeCloseTime={handleChangeCloseTime}
                handleChangeOpenTime={handleChangeOpenTime}
                handleChangeOpen={handleChangeOpen}
                open={open}
                filteredPeriodsArr={filteredPeriodsArr}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                getLocationDetails={getLocationDetails}
                handleAddHours={handleAddHours}
                handleRemoveHours={handleRemoveHours}
                openTimesError={openTimesError}
                setOpenTimesError={setOpenTimesError}
                setCloseTimesError={setCloseTimesError}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
export default Hours;
