import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  Grid,
} from "@mui/material";
import React, { useState, useRef, useLayoutEffect } from "react";
import moment from "moment";
import { Box } from "@mui/system";
import { api } from "../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/style";

export const ShowComments = ({ commentsDetails, getAllComments }) => {
  const elRef = useRef();
  const classes = useStyles();
  const { user } = useAuth();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(null);
  const [deteleComment, setDeleteComment] = useState("");

  useLayoutEffect(() => {
    if (elRef.current) {
      elRef.current.firstElementChild.style.margin = "0px";
      // set other style attributes
    }
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  const deleteComment = async (commentId) => {
    try {
      const res = await api.delete(`/comments/${commentId}`);
      if (res.status === 200) {
        // setCommentDelete(res.data.data);
        // setDeleteComment(res.data.data);
        setOpen(null);
        toast.success("Successfully Deleted");
        getAllComments();
      }
    } catch (error) {}
  };
  return (
    <>
      {commentsDetails?.map((item) => {
        return (
          <>
            <Dialog
              open={open !== null ? true : false}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogTitle id="responsive-dialog-title">
                {t("del_dialog")}
              </DialogTitle>

              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  {t("Disagree")}
                </Button>
                <Button onClick={() => deleteComment(deteleComment)} autoFocus>
                  {t(" Agree")}
                </Button>
              </DialogActions>
            </Dialog>
            <Box className={classes.commentBox}>
              <Grid
                style={{
                  fontWeight: "400",
                  fontSize: "20px",
                  color: "white",
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  backgroundColor: "#06BDFF",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography style={{ fontSize: "20px" }}>
                  {item?.user?.firstName?.charAt(0) || "U"}
                </Typography>
              </Grid>
              <Box flex={1}>
                <Typography
                  // className="firstname_user"
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: "#1B2430",
                  }}
                >
                  {item?.user?.firstName || "User"}{" "}
                  {item?.user?.lastName || "Name"}
                </Typography>
                <Typography
                  // className="html"
                  sx={{
                    marginTop: "-12px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#495059",
                  }}
                  ref={elRef}
                  dangerouslySetInnerHTML={{
                    __html: item?.text,
                  }}
                ></Typography>{" "}
                {item?.user?.id === user?.id && (
                  <Typography
                    onClick={(e) => {
                      setDeleteComment(item?.id);
                      setOpen(true);
                    }}
                    className={classes.deleteText}
                  >
                    {t("Delete")}
                  </Typography>
                )}
              </Box>
              <Typography
                sx={{ color: "#8D9298", fontWeight: "400", fontSize: "12px" }}
              >
                {" "}
                {moment(item?.createdAt).fromNow()}
              </Typography>
            </Box>
          </>
        );
      })}
    </>
  );
};
