import {
  Typography,
  Grid,
  TextField,
  Modal,
  CircularProgress,
  createFilterOptions,
  MenuItem,
  Select,
} from "@mui/material";

import React, { useState } from "react";
import { InputAdornment } from "@material-ui/core";
import { languagesData } from "../../../../../assets/defaultLanguage";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useTranslation } from "react-i18next";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Button, IconButton } from "@mui/material";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Autocomplete, Box } from "@mui/material";

import { makeStyles } from "@mui/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Divider, Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DatePickerComponent from "../../../../../components/CustomComponents/DateRangePicker/RangePicker";

import dayjs from "dayjs";
const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "95vh",
  width: "800px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  overflow: "auto",
  boxShadow: 24,
  padding: "0px 16px 16px 16px",
};

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainerHeader: {
    width: "100%",
    // textAlign: "center",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    // marginBottom: "6px",
    marginLeft: "10px",
    marginTop: "6px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    alignItems: "end",
    textAlign: "end",
    paddingTop: "8px",
  },
  whiteBackground: {
    background: "white",
    width: "98%",
  },
}));

const EmotionAiFilterModal = ({
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  scheduleReviewSource,
  setScheduleReviewSource,
  scheduleTags,
  setScheduleTags,
  scheduleRatingValue,
  setScheduleRatingValue,
  scheduleReviewerName,
  setScheduleReviewerName,
  scheduleReply,
  setScheduleReply,
  allLocations,
  allGroups,
  allReviewSource,
  starRatings,
  allTags,
  dateRange,
  setDateRange,
  handleResetScheduleFiltersModel,
  haventReplied,
  open,
  handleCloseFilterModel,
  handleChangeDateRange,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAllLocations, setSelectAllLocations] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  let timer = 0;

  const ratingValues = [
    {
      label: `${t("1_star")}`,
      value: 1,
    },
    {
      label: `${t("2_star")}`,
      value: 2,
    },
    {
      label: `${t("3_star")}`,
      value: 3,
    },
    {
      label: `${t("4_star")}`,
      value: 4,
    },
    {
      label: `${t("5_star")}`,
      value: 5,
    },
  ];
  const sentimentsValues = [
    {
      label: `${t("positive")}`,
      value: "Positive",
    },
    {
      label: `${t("negative")}`,
      value: "Negative",
    },
    {
      label: `${t("neutral")}`,
      value: "Neutral",
    },
  ];

  const handleChangeStartDate = (e) => {
    setStartDate(e);
  };
  const handleChangeEndDate = (e) => {
    setEndDate(e);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid item>
          <Box sx={styleEditUser}>
            <Grid container lg={12} className={classes.mainContainer}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.mainContainerHeader}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px",
                  }}
                >
                  <Typography className={classes.mainContainerHeading}>
                    {t("reviews_filters")}
                  </Typography>
                  <IconButton
                    autoFocus
                    onClick={handleCloseFilterModel}
                    className="delete_button"
                    sx={{ paddingTop: "0px" }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Divider my={6} />
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("Location")}
                </Typography>

                <Autocomplete
                  multiple
                  disablePortal
                  disableCloseOnSelect
                  id="combo-box-demo"
                  sx={{ width: "100%", padding: "8px" }}
                  options={allLocations?.length > 0 ? allLocations : []}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        ((option?.addressLine1 !== "" &&
                          option?.addressLine1 !== null) ||
                        (option?.city !== "" && option?.city !== null)
                          ? "(" +
                            (option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                              ? option?.addressLine1 + "," + " "
                              : "") +
                            (option?.city ? option?.city : "") +
                            ")"
                          : "")
                      : ""
                  }
                  className="Autocomplete-field"
                  value={scheduleLocations ? scheduleLocations : []}
                  onChange={(event, value) => {
                    if (value.find((option) => option.id === 0)) {
                      if (allLocations?.length === scheduleLocations?.length) {
                        setScheduleLocations([]);
                        setSelectAllLocations(false);
                      } else {
                        setSelectAllLocations(true);
                        setScheduleLocations(allLocations);
                      }
                    } else {
                      setScheduleLocations(value);
                      if (value?.length === allLocations?.length) {
                        setSelectAllLocations(true);
                      } else {
                        setSelectAllLocations(false);
                      }
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    return [{ id: 0, internalName: "Select All" }, ...filtered];
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.id === 0
                        ? `${option.internalName}`
                        : `${option.internalName} (${
                            option?.addressLine1 !== "" &&
                            option?.addressLine1 !== null &&
                            option?.addressLine1 !== undefined
                              ? option?.addressLine1
                              : ""
                          }${
                            option?.city !== "" &&
                            option?.city !== null &&
                            option?.city !== undefined
                              ? ", " + option.city
                              : ""
                          })`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              <Grid xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fieldLabel}>
                  {t("groups_label")}
                </Typography>

                <Autocomplete
                  disablePortal
                  // disableCloseOnSelect
                  id="combo-box-demo"
                  sx={{ width: "100%", padding: "8px" }}
                  options={
                    allGroups !== null && allGroups?.rows?.length > 0
                      ? allGroups?.rows
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  className="Autocomplete-field"
                  value={scheduleGroups}
                  onChange={(e, value) => {
                    setScheduleGroups(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_group")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.fieldLabel}>
                    {t("date_range")}
                  </Typography>
                  <Box sx={{ padding: "8px" }}>
                    {" "}
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={dateRange}
                      onChange={(e) => {
                        handleChangeDateRange(e);
                      }}
                      fullWidth
                      // error={dateRangeError}
                      //  helperText={dateRangeError ? t("Field_req") : ""}
                      //sx={{ marginTop: "3px" }}
                    >
                      <MenuItem value="All">{t("All")}</MenuItem>
                      <MenuItem value="Today">{t("today")}</MenuItem>
                      <MenuItem value="Yesterday">{t("yesterday")}</MenuItem>
                      <MenuItem value="2days">{t("2days")}</MenuItem>
                      <MenuItem value="7days">{t("7days")}</MenuItem>
                      <MenuItem value="week">{t("week")}</MenuItem>

                      <MenuItem value="14days">{t("14days")}</MenuItem>
                      <MenuItem value="30days">{t("30days")}</MenuItem>
                      <MenuItem value="month">{t("monthValue")}</MenuItem>
                      <MenuItem value="60days">{t("60days")}</MenuItem>
                      <MenuItem value="90days">{t("90days")}</MenuItem>
                      <MenuItem value="6months">{t("6months")}</MenuItem>
                      <MenuItem value="12months">{t("12months")}</MenuItem>
                      <MenuItem value="custom">{t("custom_text")}</MenuItem>
                    </Select>
                  </Box>{" "}
                </Grid>
                {dateRange === "custom" && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid
                      container
                      spacing={2}
                      sx={{ paddingLeft: "8px", paddingRight: "8px" }}
                    >
                      <DatePickerComponent
                        handleChangeStartDate={handleChangeStartDate}
                        handleChangeEndDate={handleChangeEndDate}
                        startDate={startDate !== null ? startDate : null}
                        endDate={endDate !== null ? endDate : null}
                        fromFilters={true}
                        rangeValue={"between"}
                      />
                      {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "20px",
                            color: "#1B2430",
                            marginBottom: "8px",
                          }}
                        >
                          {t("Fromm")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture
                            placeholder={t("From")}
                            className={classes.whiteBackground}
                            value={dayjs(startDate)}
                            format="DD/MM/YYYY"
                            minDate={dayjs("2005-01-01")}
                            // maxDate={dayjs().endOf("day")}
                            onChange={(e) => {
                              setStartDate(e);
                            }}
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                fullWidth
                                {...params}
                                sx={{ padding: "8px", background: "#FFFF" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "700",
                            lineHeight: "20px",
                            color: "#1B2430",
                            marginBottom: "8px",
                          }}
                        >
                          {t("Too")}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            disableFuture
                            className={classes.whiteBackground}
                            value={dayjs(endDate)}
                            format="DD/MM/YYYY"
                            minDate={dayjs("2005-01-01")}
                            //   maxDate={dayjs().endOf("day")}
                            onChange={(e) => {
                              setEndDate(e);

                              //   setEndDate(e);
                            }}
                            placeholder={t("To")}
                            renderInput={(params) => (
                              <TextField
                                autoComplete="off"
                                fullWidth
                                {...params}
                                //  error={dateRequired}
                                sx={{ padding: "8px", background: "#FFFF" }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid> */}
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography className={classes.fieldLabel}>
                    {t("Review_Source")}
                  </Typography>
                  <Autocomplete
                    multiple
                    disablePortal
                    disableCloseOnSelect
                    id="combo-box-demo"
                    sx={{ bgcolor: "white", padding: "8px" }}
                    value={scheduleReviewSource ? scheduleReviewSource : []}
                    options={
                      allReviewSource.results?.length > 0
                        ? allReviewSource?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.name ? option?.name : " "
                    }
                    onChange={(e, value) => {
                      setScheduleReviewSource(value);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />

                        <Typography sx={{ textTransform: "capitalize" }}>
                          {`${option.name} `}
                        </Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select Review Source")}
                        {...params}
                        required
                        // label={t("TaskLocation")}
                        //  error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography className={classes.fieldLabel}>
                    {t("Ratings")}
                  </Typography>
                  <Box sx={{ padding: "8px" }}>
                    {" "}
                    <Autocomplete
                      multiple
                      disablePortal
                      disableCloseOnSelect
                      id="combo-box-demo"
                      sx={{ bgcolor: "white" }}
                      options={starRatings}
                      className="Autocomplete-field"
                      getOptionLabel={(option) => option?.name}
                      value={
                        scheduleRatingValue?.length > 0
                          ? scheduleRatingValue
                          : []
                      }
                      onChange={(e, value) => {
                        setScheduleRatingValue(value);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {`${option?.name} `}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          placeholder={t("Select Rating")}
                          {...params}
                          required
                          // label={t("TaskLocation")}
                          // error={locationRequired}
                          name="language"
                        />
                      )}
                    ></Autocomplete>
                  </Box>{" "}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography className={classes.fieldLabel}>
                    {t("Review_by")}
                  </Typography>
                  <Box sx={{ padding: "8px" }}>
                    {" "}
                    <TextField
                      placeholder={t("Review_by")}
                      // className={classes.root}
                      // error={error}
                      fullWidth
                      id="outlined-name"
                      value={scheduleReviewerName}
                      // onSubmit={getLocationGoogleReview}
                      onKeyPress={(event) => {
                        // setError(false);
                        if (event.key === "Enter") {
                          setScheduleReviewerName(event.target.value);
                        }
                      }}
                      style={{ height: "52px" }}
                      sx={{
                        background: "#FFF",
                        padding: "0px",
                        height: "52px",
                      }}
                      onChange={(e) => {
                        setScheduleReviewerName(e.target.value);
                        //  handleChangeFilterWithName(e);
                      }}
                    />
                  </Box>{" "}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography className={classes.fieldLabel}>
                    {t("Actions")}
                  </Typography>
                  <Box sx={{ padding: "8px" }}>
                    {" "}
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ bgcolor: "white" }}
                      options={haventReplied}
                      getOptionLabel={(option) => option?.name}
                      value={scheduleReply}
                      onChange={(e, value) => {
                        setScheduleReply(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          placeholder={t("Select Action")}
                          {...params}
                          required
                          name="language"
                        />
                      )}
                    ></Autocomplete>
                  </Box>{" "}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>
                  <Typography className={classes.fieldLabel}>
                    {t("Tags")}
                  </Typography>
                  <Box sx={{ padding: "8px" }}>
                    {" "}
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id="checkboxes-tags-demo"
                      options={allTags.length > 0 ? allTags : []}
                      value={scheduleTags ? scheduleTags : []}
                      getOptionLabel={(option) => option?.name}
                      onChange={(e, value) => {
                        setScheduleTags(value);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {" "}
                            {option.name}
                          </Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          //  label={t("Select_Tags")}
                          placeholder={t("Select_Tags")}
                        />
                      )}
                    />
                  </Box>{" "}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.buttonContainer}
              >
                <CommonButton
                  displayBlack="true"
                  icon={<RefreshIcon />}
                  label={t("Reset")}
                  onSubmit={() => {
                    handleResetScheduleFiltersModel();
                    handleCloseFilterModel();
                  }}
                />
                &nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    handleCloseFilterModel();
                  }}
                  //  disabled={dateRequired}
                  sx={{
                    height: "52px",
                    width: "88px",
                    // padding: "14px 20px",
                    fontSize: "14px",
                    marginLeft: "4px",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    /*   background:
                              displayWhite === "true"
                                ? "#f9f9f9 linear-gradient(180deg,#fafafa,#f9f9f9) repeat-x"
                                : "" || displayBlack === "true"
                                ? "#1B2430"
                                : "", */

                    border: "none",
                  }}
                >
                  {t("Ok")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default EmotionAiFilterModal;
