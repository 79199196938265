import React, { useState } from "react";
import {
  Card,
  Paper,
  Grid,
  Typography,
  Autocomplete,
  Checkbox,
  TextField,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "../Styles/style";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ReactComponent as AddGroup } from "../../../../assets/images/AddGroup.svg";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import RefreshIcon from "@mui/icons-material/Refresh";

const FaqFilters = ({
  allLocation,
  isLoading,
  setLocationRequired,
  locationRequired,
  handleLocationChange,
  locationId,
  setCallApi,
  allGroups,
  handleChangeGroups,
  selectedGroup,
  locationViewPermission,
  locationAddPermission,
  BootstrapTooltip,
  handleNavigationToGroups,
  resetHandler,
  setLocationId,
}) => {
  const { t } = useTranslation();
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAllLocations, setSelectAllLocations] = useState(false);

  return (
    <Grid container mt={5}>
      {!isLoading && (
        <Grid item xs={4.5} md={4.5} lg={4.5}>
          <Autocomplete
            multiple
            disablePortal
            disableCloseOnSelect
            error={locationRequired}
            id="combo-box-demo"
            options={
              allLocation?.results?.length > 0 ? allLocation?.results : []
            }
            getOptionLabel={(option) =>
              option?.internalName
                ? option?.internalName +
                  " " +
                  ((option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null) ||
                  (option?.city !== "" && option?.city !== null)
                    ? "(" +
                      (option?.addressLine1 !== "" &&
                      option?.addressLine1 !== null &&
                      option?.addressLine1 !== undefined
                        ? option?.addressLine1 + "," + " "
                        : "") +
                      (option?.city ? option?.city : "") +
                      ")"
                    : "")
                : ""
            }
            sx={{ bgcolor: "white", width: "98%" }}
            value={locationId?.length ? locationId : []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => {
              handleLocationChange(value);

              if (value.find((option) => option.id === 0)) {
                if (allLocation?.results?.length === locationId?.length) {
                  setLocationId([]);
                  setSelectAllLocations(false);
                } else {
                  setSelectAllLocations(true);
                  setLocationId(allLocation?.results);
                }
              } else {
                setLocationId(value);
                if (value?.length === allLocation?.results?.length) {
                  setSelectAllLocations(true);
                } else {
                  setSelectAllLocations(false);
                }
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              return [{ id: 0, internalName: "Select All" }, ...filtered];
            }}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={(option?.id === 0 && selectAllLocations) || selected}
                />

                {`${option?.internalName} ${
                  option?.addressLine1 !== "" &&
                  option?.addressLine1 !== null &&
                  option?.addressLine1 !== undefined
                    ? "(" + option?.addressLine1 + "," + " "
                    : ""
                } ${option?.city ? option?.city + ")" : ""}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                placeholder={t("SelectLocation")}
                {...params}
                required
                error={locationRequired}
                name="language"
              />
            )}
          ></Autocomplete>
        </Grid>
      )}

      {!isLoading && (
        <Grid item xs={4.5} md={4.5} lg={4.5}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={
              allGroups !== null && allGroups?.rows?.length > 0
                ? allGroups?.rows
                : []
            }
            getOptionLabel={(option) => (option?.name ? option?.name : "")}
            className="Autocomplete-field"
            value={selectedGroup}
            onChange={(e, value) => {
              handleChangeGroups(value);
              setCallApi(true);
            }}
            sx={{ bgcolor: "white", width: "98%" }}
            renderInput={(params) => (
              <TextField
                placeholder={t("Select_group")}
                {...params}
                required
                name="language"
              />
            )}
          ></Autocomplete>
        </Grid>
      )}

      {!isLoading && (
        <Grid
          item
          container
          spacing={2}
          xs={3}
          md={3}
          lg={3}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid item>
            {locationViewPermission && locationAddPermission ? (
              <BootstrapTooltip title={t("manage_groups")}>
                <IconButton
                  onClick={() => handleNavigationToGroups()}
                  sx={{ paddingTop: "13px", cursor: "pointer" }}
                >
                  <AddGroup />
                </IconButton>
              </BootstrapTooltip>
            ) : (
              <BootstrapTooltip title={t("authorized_access")}>
                <span style={{ cursor: "pointer" }}>
                  <IconButton
                    disabled
                    onClick={() => handleNavigationToGroups()}
                    sx={{ paddingTop: "13px", cursor: "pointer" }}
                  >
                    <AddGroup />
                  </IconButton>
                </span>
              </BootstrapTooltip>
            )}
          </Grid>
          <Grid item>
            <CommonButton
              displayBlack="true"
              icon={<RefreshIcon />}
              label={t("Reset")}
              onSubmit={resetHandler}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FaqFilters;
