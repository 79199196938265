import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  addTaskTitle: {
    margin: "8px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1b2430",
    width: "438px",
    height: "20px",
  },
  addTaskMainBox: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    cursor: "pointer",
    marginRight: "0.5rem",
    color: "black",
    width: "18px",
    height: "18px",
  },
  title: {
    fontWeight: "700",
    fontSize: "20px",
  },
  addTaskField: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  addTaskTitleField: {
    width: "100%",
    borderRadius: "8px",
  },
  status: {
    marginTop: "8px",
    marginBottom: "8px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1b2430",
    width: "438px",
    height: "20px",
  },
  addTaskTypo: {
    marginTop: "8px",
    marginBottom: "8px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1b2430",
    width: "438px",
    height: "20px",
  },
  description: {
    margin: "8px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1b2430",
    width: "438px",
    height: "20px",
  },
  main_Box: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  editor: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  btn: {
    width: "100%",
    marginTop: "1rem",
    fontSize: "1.25rem",
    fontWeight: "600",
    borderRadius: "8px",
    lineHeight: "1.5",
    padding: "0.5 1 rem",
  },
  locationDetailsTask: {
    display: "flex",
    alignContent: "center",
    marginLeft: "16px",
  },
  addCommentBtn: {
    borderColor: "#3c98eb",
    fontWeight: "600",
    borderRadius: "0.3rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    padding: "0.5rem 1rem",
    marginLeft: "4px",
    height: "50",
  },
  commentSection: {
    borderRadius: "10px",
    backgroundColor: "#fafafa",
    marginTop: "2rem",
    padding: "1rem",
    minWidth: "200px",
  },
  firstnameBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  dateBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  buttonBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: alpha(theme.palette.common.white, 0.15),

    // marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    bgcolor: "background.paper",

    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainer: {
    padding: "20px 0px 10px 0px",
  },
  iconStyle: {
    width: "20px",
    height: "18px",
  },
  commentBox: {
    display: "flex",
    gap: "20px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  deleteText: {
    width: "40px",
    cursor: "pointer",
    marginTop: "-12px",
    fontWeight: "400",
    fontSize: "13px",
    color: "#8D9298",
  },
  descText: {
    marginTop: "8px",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    width: "438px",
    height: "20px",
  },
  editTaskBox: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  editTaskMainBox: {
    overflowY: "scroll",
    maxHeight: "55vh",
  },
  editMainGrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  toast: {
    width: "100%",
    display: "flex",
    paddingTop: "0 1.25rem",
    paddingBottom: "1rem",
  },
  toastBox: {
    backgroundColor: "#E8F2FF",
    width: "100%",
    display: "flex",
    borderRadius: "4px",
    //   gap: "1rem",
  },
  toastBoxBorder: {
    backgroundColor: "#06BDFF",
    width: "6px",
    borderRadius: "4px 0 0 4px",
  },
  toastBoxContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
  },
  toastBoxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
    gap: "1rem",
  },
}));
