import { Divider, Grid, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const CommentModal = (props) => {
  const { open, close, text } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal open={open}>
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid className={classes.styleCommentContainer}>
            <Grid
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.mainContainerHeading}>
                {t("Comment")}
              </Typography>

              <IconButton
                autoFocus
                onClick={() => close(false)}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Divider />
            <Grid className={classes.commentContainer}>
              {text !== null ? (
                <Grid container className={classes.commentBox}>
                  <Typography>{text}</Typography>
                </Grid>
              ) : (
                <Grid
                  container
                  className={classes.commentBox}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      lineHeight: "28px",
                      fontWeight: 700,
                      fontSize: "20px",
                      color: "#1b2430",
                    }}
                  >
                    {t("No_Comment_Found")}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CommentModal;
