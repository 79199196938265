import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PlaceholderImage from "../images/placeholder.jpg";

const LazyLoad = ({ src, height, width, alt, sx, placeholderSrc }) => {
  return (
    <LazyLoadImage
      effect="blur"
      src={src}
      style={sx}
      alt={alt}
      placeholderSrc={PlaceholderImage}
    />
  );
};

export default LazyLoad;
