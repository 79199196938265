// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
// ** File Imports ================================================================
import Loader from "../../../../components/Loaders/Loader";
import TitleHeader from "../../../../components/CustomComponents/title";
import UpdateTemplateCard from "./editTemplateCard";
import { api } from "../../../../contexts/JWTContext";
import { useTheme } from "@mui/material/styles";
import { useStyles } from "../styles/style";
// ** Material ui Imports ================================================================
import { Edit } from "@mui/icons-material";

import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import Autocomplete from "@mui/material/Autocomplete";

import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Divider as MuiDivider,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// ** Other Imports ================================================================

const Divider = styled(MuiDivider)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const EditTemplate = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // ** Others ================================================================
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const { signOut } = useAuth();
  const classes = useStyles();
  // ** States ================================================================
  const [dataArray, setDataArray] = useState([
    { text: "", language: "", isDeleted: false },
  ]);
  const [templateId, setTemplateId] = useState(null);
  const [title, setTile] = useState("");
  const [editData, setEditData] = useState(false);
  const [companyId, setCompanyId] = useState("");
  const [allCompanies, setAllCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  //const [loading, setLoading] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    getAllDefaultTemplate();
    // deleteTemplates();
  }, []);
  useEffect(async () => {
    getAllCompanies();
  }, []);
  const handleCompanyChange = (option) => {
    setCompanyId(option);
  };

  useEffect(() => {
    const getDataToEdit = async () => {
      setIsLoading(true);
      //   const data = {
      //     title: title || "Template",
      //     data: dataArray,
      //   };
      try {
        const res = await api.get(`/template/${id}`);

        if (res.status === 200) {
          setDataArray(res.data.data.templateDescription);
          setTile(res.data.data.title);
          setTemplateId(res.data.data.id);
          setCompanyId(res.data.data.company);

          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    };
    getDataToEdit();
  }, []);

  // ** Handler Functions ================================================================
  // ** Helper Function to remove th last word of on curly brace is removed ============================
  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
      return str;
    }

    return str.substring(0, lastIndexOfSpace);
  }
  const getAllDefaultTemplate = async () => {
    try {
      const res = await api.get(`/template/default`);
      if (res.status === 200) {
        setDefaultTemplate(res?.data?.data);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const deleteTemplate = (index) => {
    let tempArray = [...dataArray];

    tempArray.splice(index, 1); // 2nd parameter means remove one item only
    setDataArray(tempArray);

    // setDescription(e.target.value);
  };

  const changeHandler = (
    e,
    index,
    isLanguage,
    isReviewer,
    currentRef,
    deleteTemplate
  ) => {
    let tempArray = [...dataArray];
    let tempData = { ...dataArray[index] };

    // Checking language and converting to code ============================
    const { value } = e.target;
    let tempValueForDelete = value;

    // CHecking for one which selctebox was clicked ============================
    if (isLanguage === true) {
      tempData["language"] = value;
    } else {
      if (isReviewer === true) {
        let textBeforeCursorPosition = tempData["text"].substring(
          0,
          currentRef.current.selectionStart
        );
        let textAfterCursorPosition = tempData["text"].substring(
          currentRef.current.selectionStart,
          tempData["text"].length
        );

        tempData["text"] =
          textBeforeCursorPosition +
          " " +
          value +
          " " +
          textAfterCursorPosition;
        currentRef.current.selectionStart =
          currentRef.current.selectionStart + value.length + 2;
      } else {
        if (tempValueForDelete && tempValueForDelete?.slice(-1) === "}") {
          tempData["text"] = removeLastWord(tempValueForDelete);
        } else {
          tempData["text"] = value;
        }
      }
    }

    if (deleteTemplate === true) {
      tempArray[index]["isDeleted"] = true;
      setDataArray(tempArray);
    } else {
      tempArray.splice(index, 1, tempData);
      setDataArray(tempArray);
    }
  };

  const handleAdd = () => {
    const data = { text: "", language: "", isDeleted: false, id: null };
    setDataArray([...dataArray, data]);
  };

  const saveTemplateHandler = async () => {
    let tempRequiredCount = 0;

    // dataArray.map((item) => {
    //   if (item.text.length < 1 || item.language.length < 1) {
    //     tempRequiredCount++;
    //   }
    // });

    // CHecking for one english template ============================
    const result = dataArray.filter((item) => item.language === "en");
    if (result.length <= 0) {
      toast.warn(t("English_temp_msg"));
      return;
    }

    // CHecking if all the required fields are filled call api ============================
    if (tempRequiredCount === 0 && result.length > 0) {
      if (title?.length < 1) {
        setTitleRequired(true);
      }
      if (companyId === null) {
        setCompanyIdRequired(true);
      } else {
        setIsLoading(true);

        const data = {
          title: title,
          updateTemplateDescriptions: dataArray,
          companyId: companyId.id,
        };

        try {
          const res = await api.patch(`/template/${templateId}`, data);
          if (res.status === 200) {
            //  navigate("/user/template");
            toast.success("Templates updated successfully");
            // deleteTemplate(index);
            setIsLoading(false);
          }
        } catch (error) {
          // toast.error(error?.response?.data?.message);
          toast.error("Something went wrong");

          setIsLoading(false);
        }
      }
    }
  };

  const updateTemplateHandler = async () => {
    if (title.length < 1) {
      setTitleRequired(true);
    }

    if (title.length > 0) {
      const data = {
        title: title,
        data: dataArray,
        companyId: companyId?.id,
      };

      try {
        const res = await api.patch(`/template/${id}`, data);

        if (res.status === 200) {
          // navigate("/user/template");
          toast.success(`Successfully updated template ${id}`);
          setIsLoading(false);
        }
      } catch (error) {
        // toast.error(res.status.messaage);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            title={t("Update_Template")}
            subHeading={t("sub_Update_Template")}
            showButton={true}
          />

          <Paper style={{ height: "100%", boxShadow: "none" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                {editData ? (
                  <>
                    <Box sx={{ ml: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {t("Title")}{" "}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          id="standard-basic"
                          // label="Standard"
                          rows={1}
                          maxRows={10}
                          style={{
                            // border: "1px solid #E0E0E0",
                            fontFamily: "Segoe UI",
                            fontStyle: "Normal",
                            //  padding: "12px 12px 12px 12px",
                            width: "100%",

                            borderRadius: "8px",
                            //  minHeight: "30px",
                            color: "#1B2430",
                            fontWeight: "400",
                            fontSize: "16px",
                            //   padding: "15px",
                          }}
                          label={null}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setEditData(false);
                            }
                          }}
                          value={title}
                          error={titleRequired}
                          onChange={(event) => {
                            if (event.target.value.length === 0) {
                              setTitleRequired(true);
                            } else {
                              setTitleRequired(false);
                            }

                            setTile(event.target.value);
                          }}
                          multiline
                          fullWidth
                          /*   InputProps={{
                    disableUnderline: true,
                  }} */
                          inputProps={{ maxLength: 1000 }}
                          /*   InputProps={{
              inputComponent: TextareaAutosize,
            }} */
                        />

                        {/*  <TextareaAutosize
                            id="filled-multiline-static"
                            multiline
                            style={{
                              border: "1px solid #E0E0E0",
                              fontFamily: "Segoe UI",
                              fontStyle: "Normal",
                              padding: "12px 12px 12px 12px",
                              width: "100%",

                              borderRadius: "8px",
                              minHeight: "30px",
                              color: "#1B2430",
                              fontWeight: "400",
                              fontSize: "16px",
                              //   padding: "15px",
                            }}
                            error={titleRequired}
                            disabled={isLoading}
                            required
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                setEditData(false);
                              }
                            }}
                            value={title}
                            onChange={(event) => {
                              setTitleRequired(false);
                              setTile(event.target.value);
                            }}
                            //fullWidth
                            variant="outlined"
                          /> */}
                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setEditData(!editData);
                          }}
                          disabled={!title}
                        >
                          {" "}
                          <CheckIcon />
                        </IconButton>
                      </Box>
                      {titleRequired && (
                        <Typography
                          sx={{
                            color: "#d32f2f",
                            fontWeight: 400,
                            fontSize: "0.6964285714285714rem",
                            lineHeight: "1.66",
                            textAlign: "left",
                            marginTop: "3px",
                            marginRight: "14px",
                            marginBottom: 0,
                            marginLeft: "14px",
                          }}
                        >
                          {t("title_req")}
                        </Typography>
                      )}
                    </Box>
                    {/* </Box> */}
                  </>
                ) : (
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {title}
                      </Typography>
                      <CustomWidthTooltip title={t("Edit_Title")}>
                        <IconButton
                          aria-label="edit"
                          sx={{ fontSize: "14px" }}
                          onClick={() => {
                            setEditData(true);
                          }}
                        >
                          {" "}
                          <Edit />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Box>
                  </>
                )}
                <>
                  <Grid container sx={{ paddingLeft: "6px" }}>
                    {/*  <Grid item xs={12} sm={12} lg={0.5} md={0.5}></Grid> */}
                    <Grid item xs={12} sm={12} lg={11} md={11}>
                      {" "}
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {t("Company")}
                      </Typography>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={
                          allCompanies?.results?.length > 0
                            ? allCompanies?.results
                            : []
                        }
                        getOptionLabel={(option) => option?.name}
                        className="Autocomplete-field"
                        defaultValue={companyId}
                        onChange={(e, value) => {
                          handleCompanyChange(value);
                          if (value) {
                            setCompanyIdRequired(false);
                          } else {
                            setCompanyIdRequired(true);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ width: "100%" }}
                            placeholder={"Select_Company"}
                            {...params}
                            required
                            helperText={
                              companyIdRequired && "Company is required"
                            }
                            name="language"
                            error={companyIdRequired}
                          />
                        )}
                      ></Autocomplete>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              <Grid item xs={12} sm={12} lg={8} md={8}>
                <Grid container>
                  {dataArray
                    ?.filter((item) => item.isDeleted !== true)
                    .map((item, index) => {
                      return (
                        <>
                          <UpdateTemplateCard
                            item={item}
                            index={index}
                            deleteTemplate={deleteTemplate}
                            templateId={templateId}
                            changeHandler={changeHandler}
                            defaultTemplate={defaultTemplate}
                            dataArray={dataArray}
                          />
                        </>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} lg={6}></Grid>
                <Grid item md={0.6} lg={0.6}></Grid>
                <Grid item xs={12} sm={12} md={5.4} lg={5.4}>
                  <IconButton
                    onClick={handleAdd}
                    // loading={loading}
                    displayWhite="true"
                  >
                    <AddIcon sx={{ color: "#0638C1", fontWeight: "700" }} />{" "}
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#0638C1",
                        fontWeight: "700",
                      }}
                    >
                      {" "}
                      {t("Add_More_Description")}{" "}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Divider my={4} />
            </Grid>

            <Grid container sx={{ justifyContent: "flex-end" }}>
              <CommonButton
                displayWhite="true"
                // onSubmit={onCancel}
                onSubmit={() => {
                  navigate(-1);
                }}
                label={t("Cancel")}
              />

              <CommonButton
                label={t("Save_Template")}
                onSubmit={saveTemplateHandler}
                loading={isLoading}
              >
                {t("Save")}
              </CommonButton>
            </Grid>
            {/*   <Grid container>
              <Divider my={4} />

              <Grid container className="grid-container">
                <Grid item xs={12} md={12} className="grid-item">
                  <Box className="box">
                    {editData ? (
                      <TextField
                        error={titleRequired}
                        disabled={isLoading}
                        required
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            setEditData(false);
                            updateTemplateHandler();
                          }
                        }}
                        label="Title"
                        value={title}
                        onChange={(event) => {
                          setTitleRequired(false);
                          setTile(event.target.value);
                        }}
                        fullWidth
                        variant="outlined"
                      />
                    ) : (
                      <Typography variant="h3">{title} </Typography>
                    )}

                    <CustomWidthTooltip title="Create Template">
                      <IconButton
                        aria-label="edit"
                        size="large"
                        onClick={() => {
                          setEditData(true);
                        }}
                      >
                        {" "}
                        <Edit />
                      </IconButton>
                    </CustomWidthTooltip>
                  </Box>
                  <Box className="box-2">
                    <LoadingButton
                      className="loading-button"
                      name="Save"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleAdd}
                      // loading={loading}
                    >
                      {t("ADD")}
                    </LoadingButton>
                    <LoadingButton
                      className="loading-button"
                      name="Save"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={handleAdd}
                      // loading={loading}
                    >
                      update
                    </LoadingButton>

                   <LoadingButton
                      sx={{ borderRadius: "8px" }}
                      name="Save"
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={updateTemplateHandler}
                      loading={isLoading}
                    >
                      {t("Save")}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid> */}

            {/*    {}
            {dataArray?.map((item, index) => {
              return (
                <>
                  <UpdateTemplateCard
                    item={item}
                    index={index}
                    deleteTemplate={deleteTemplate}
                    templateId={templateId}
                    changeHandler={changeHandler}
                    defaultTemplate={defaultTemplate}

                    // languageRequired={languageRequired}
                  />
                </>
              );
            })} */}
          </Paper>
        </>
      )}
    </>
  );
};

export default EditTemplate;
