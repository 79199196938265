import axios from "axios";
const accessToken = window.localStorage.getItem("accessToken");

// let api = async () => {
//   awaaxios.create({
//     baseURL: "http://ec2-35-165-226-126.us-west-2.compute.amazonaws.com/",
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//       "Content-type": "application/json",
//     },
//     responseType: "json",
//   });
// };

axios.defaults.baseURL =
  "http://ec2-35-165-226-126.us-west-2.compute.amazonaws.com/api/v1/";
axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
axios.defaults.responseType = `json`;

export default axios;
