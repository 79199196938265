import React, { useState, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Zoom,
  Stack,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { LoadingButton } from "@mui/lab";
import { useStyles } from "../../../UserListing/Styles/style";
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  paddingY: theme.spacing(1),

  color: theme.palette.text.secondary,
}));

const defaultCrop = {
  aspect: 1 / 1,
  x: 0,
  y: 0,

  width: 250,
  height: 250,
};
const CropImgModal = ({
  open,
  setUploadImgData,
  onClose,
  setOpenCropModal,
  cropModalImage,
  dataURLtoFile,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(defaultCrop);
  const [cropperLoading, setCropperLoading] = useState(false);

  useEffect(() => {
    if (image) {
      const defaultCrop = {
        aspect: 1 / 1,
        x: 0,
        y: 0,

        width: 250,
        height: 250,
      };
      setCrop(defaultCrop);
    }
  }, [image]);

  function dataURLtoFile(dataURL, filename) {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleImgSubmit = async () => {
    setCropperLoading(true);

    // Create a canvas element
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Calculate scaling factors
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    // Set the canvas dimensions based on the specified range
    const targetSize = Math.min(720, Math.max(260, crop.width)); // Ensure it's within 250px to 720px
    canvas.width = targetSize;
    canvas.height = targetSize;

    // Draw the cropped image onto the canvas
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetSize,
      targetSize
    );

    // Get the cropped image as a data URL
    const croppedImageDataURL = canvas.toDataURL();

    try {
      // Convert the data URL to a file
      const croppedImageFileData = dataURLtoFile(
        croppedImageDataURL,
        `cropped-image-file${new Date()}`
      );

      // Update the state with the cropped image data
      let tempImages = [];
      tempImages.push({
        picture: croppedImageFileData,
        picturePreview: URL.createObjectURL(croppedImageFileData),
      });

      setUploadImgData(tempImages);
      setCropperLoading(false);
      setOpenCropModal(false);
    } catch (error) {
      console.error("Error handling cropped image:", error);
    }
  };

  const onImageLoaded = (img) => {
    setImage(img);
  };

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  return (
    <>
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              height: "100vh",
              maxWidth: "800px",

              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle
            sx={{
              color: "#1B2430",
              fontSize: "20px",
              marginBottom: "20px",
              fontWeight: "700",
            }}
            id="alert-dialog-slide-title"
          >
            {t("Crop_image_Logo")}
          </DialogTitle>
          <IconButton
            onClick={() => onClose()}
            aria-label="save"
            hover="none"
            sx={{
              hover: "none",
              marginBottom: "15px",
              marginRight: "22px",
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </Stack>

        <DialogContent>
          <Stack direction="column">
            <Item>
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ReactCrop
                    key={cropModalImage}
                    src={cropModalImage?.picturePreview}
                    onImageLoaded={onImageLoaded}
                    onChange={onCropChange}
                    aspectRatio={1 / 1}
                    keepSelection={true}
                    crop={crop}
                    minWidth={250}
                    minHeight={250}
                    style={{
                      borderRadius: "8px",
                    }}
                  ></ReactCrop>
                </Box>
              </>
            </Item>
          </Stack>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Grid
            container
            display="flex"
            justifyContent="flex-end"
            paddingTop="8px"
          >
            <CommonButton
              displayWhite="true"
              onSubmit={() => onClose()}
              label={t("Cancel")}
            />
            <LoadingButton
              name="Save "
              className={classes.cropperButton}
              variant="contained"
              size="large"
              onClick={handleImgSubmit}
              loading={cropperLoading}
            >
              {t("Ok")}
            </LoadingButton>
          </Grid>
        </DialogActions>
        <style>
          {`
       .MuiDialogActions-root {
        display: block;
      }
     
    `}
        </style>
      </Dialog>
    </>
  );
};

export default CropImgModal;
