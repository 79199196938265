import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Zoom,
  styled,
  Tooltip,
  tooltipClasses,
  Modal,
  TextField,
} from "@mui/material";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "550px",
  bgcolor: "background.paper",
  maxHeight: "87vh",
  width: "500px",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
};

const BulkUpdateModel = ({
  showBulkUpdateTime,
  handleCloseBlukUpdateModel,
  changeHandlerBulkDateUpdate,
}) => {
  const inputFileRef = React.useRef();
  const [translation] = useTranslation("translation");
  const [dateTime, setDateTime] = useState(null);
  const { t } = useTranslation();
  const [timeInterval, setTimeInterval] = useState(1440);

  const [startDateRequired, setStartDateRequired] = useState(false);
  const [intervalRequired, setIntervalRequired] = useState(false);

  const timeIntervalChangeHandler = (e) => {
    setTimeInterval(e.target.value);
    setIntervalRequired(false);
  };

  const submitBulkUpdate = () => {
    changeHandlerBulkDateUpdate(dateTime, timeInterval);
    handleCloseBlukUpdateModel();
  };

  return (
    <>
      <Modal
        open={showBulkUpdateTime}
        // onClose={handleCloseModalEditUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <>
            <Grid container>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    lineHeight: "28px",
                  }}
                >
                  {t("Bulk_Date_Time")}
                </Typography>
              </Grid>
            </Grid>
            {/* <Divider variant="middle" /> */}
            <hr />

            <Grid container spacing={1}>
              <Grid item sm={12} md={12}>
                <Typography
                  sx={{
                    color: "#1B2430",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "20px",
                    marginBottom: "8px",
                  }}
                >
                  {t("Select_Date_Time")}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      sx={{
                        border: "1px solid #E0E0E0",
                        width: "400px",
                        background: "blue",
                      }}
                      // label="Date&Time picker"
                      disablePast
                      inputFormat="dd/MM/yyyy, hh:mm aa"
                      value={dateTime}
                      inputFormat="dd/MM/yyyy, hh:mm aa"
                      onChange={(e) => {
                        setDateTime(e);
                        // startDateRequired(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "100%" }}
                          {...params}
                          // error={startDateRequired}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#1B2430",
                  lineHeight: "20px",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                Interval between each post (in minutes):
              </Typography>
              <Grid
                container
                sx={{
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <Grid item sm={12} md={12}>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      // error={intervalRequired}
                      sx={{ width: "100%" }}
                      type={"number"}
                      value={timeInterval}
                      onChange={timeIntervalChangeHandler}
                      placeholder="Please enter time interval"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </>

          <Grid container>
            <Grid
              item
              spacing={2}
              xs={12}
              md={12}
              sx={{
                marginX: "auto",
                marginTop: "16px",
              }}
            >
              <Box sx={{ float: "right" }}>
                <CommonButton
                  label="Cancel"
                  displayWhite="true"
                  onSubmit={handleCloseBlukUpdateModel}
                />
                &nbsp;&nbsp;
                <CommonButton label="Update" onSubmit={submitBulkUpdate} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default BulkUpdateModel;
