import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";

import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShareableLinkModel from "./ShareableLinkModel.js";

import Deletes from "./Deletes.css";
import useAuth from "../../../hooks/useAuth";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
const useStyles = makeStyles((theme) => ({
  titleHeading: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
    marginTop: "3px",
  },
  titleSubheading: {
    lineHeight: "20px",
    fontWeight: 400,
    fontSize: "14px",
    //  color: "#1b2430",
    marginTop: "3px",
  },
  stepsHeading: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#1B2430",
    lineHeight: "24px",
  },
  stepsTypo: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#1B2430",
    lineHeight: "24px",
  },
  stepsGrid: {
    marginBottom: "5px",
  },
  buttonsGrid: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "8px",
    alignItems: "center",
  },
}));

const Delete = ({ title, onCancel, loading, description, loginURL }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signIn, signOut, switchAccount } = useAuth();
  const navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [displayText, setDisplayText] = useState(false);
  const [openShareableLinkModel, setOpenShareableLinkModel] = useState(false);

  const handleLogin = async () => {
    setLoginLoading(true);
    try {
      const response = await signIn(loginURL?.email, loginURL?.oneTimePassword);

      if (
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500 ||
        response?.data?.status === false
      ) {
        throw new Error(response?.data?.message);
      }
      setLoginLoading(false);
    } catch (error) {
      const message = error.message || "Something went wrong";
      toast.error(error.message);
      setLoginLoading(false);
      handleSignOut();
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleOpenSharableLinkModel = () => {
    setOpenShareableLinkModel(true);
    //  onCancel();
  };

  const handleCloseSharableLinkModel = () => {
    setOpenShareableLinkModel(false);
    onCancel();
  };

  return (
    <>
      {openShareableLinkModel && (
        <ShareableLinkModel
          title={t("one_time_login")}
          open={openShareableLinkModel}
          handleCloseNewRequestModel={handleCloseSharableLinkModel}
          inviteLink={loginURL}
          subTitle={t("shareable_link_subheadForLogin")}
        />
      )}
      <DialogContentText
        id="responsive-dialog-title"
        sx={{}}
        className="delete_dialog_content1"
      >
        {title}
      </DialogContentText>

      <DialogContentText
        id="responsive-dialog-title"
        className="delete_dialog_content2"
      >
        {description}
      </DialogContentText>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "8px",
          alignItems: "center",
        }}
      >
        <Button
          autoFocus
          onClick={() => onCancel()}
          className="delete_button"
          sx={{ color: "#1B2430" }}
        >
          {t("Cancel")}
        </Button>

        <Button
          onClick={() => handleOpenSharableLinkModel()}
          className="delete_button"
          autoFocus
        >
          {t("Share_Link")}
          {/*   {t("Confirm")} */}
        </Button>
        <Button
          onClick={() => handleLogin()}
          className="delete_button"
          autoFocus
        >
          {loginLoading ? (
            <CircularProgress
              color="secondary"
              style={{ width: "20px", height: "20px" }}
            />
          ) : (
            t("Login_text")
          )}
          {/*   {t("Confirm")} */}
        </Button>
      </Box>

      {/* </DialogActions> */}
    </>
  );
};

export default Delete;
