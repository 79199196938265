import React from "react";
import { Card as MuiCard, CardContent, Typography, Box } from "@mui/material";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import Progress from "./Progress";

const ProgressCard = ({
  onClick,
  labels,
  dataSet,
  title,
  title1,
  title2,
  title3,
  hideTitle3,
  totalDomains,
  backLinksSaved,
  domainToHomepage,
  domainToInsidePage,
  domainToOtherPage,
}) => {
  const Card = styled(MuiCard)(spacing);
  function kFormatter(num) {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  }
  return (
    <Card onClick={onClick} className="progress_card">
      <CardContent>
        <Box className="progress_card_box1">
          <Box className="progress_card_box2">
            <Box>
              <Typography variant="h6" gutterBottom>
                {title}
              </Typography>
              <Typography variant="h2" gutterBottom>
                {kFormatter(totalDomains)}
              </Typography>
            </Box>
            <Progress labels={labels} dataSet={dataSet} />
          </Box>
          <Box className="progress_card_box3">
            <Box className="progress_card_box4">
              <Box className="progress_card_box5">
                <Typography variant="body1">{title1}</Typography>
                <Typography variant="h5">
                  {kFormatter(domainToHomepage)}
                </Typography>
              </Box>

              <Box className="progress_card_box6">
                <Typography variant="body1">{title2}</Typography>
                <Typography variant="h5">
                  {hideTitle3
                    ? kFormatter(backLinksSaved - domainToHomepage)
                    : kFormatter(domainToInsidePage)}
                </Typography>
              </Box>

              {title3 && (
                <Box className="progress_card_box7">
                  <Typography variant="body1">{title3}</Typography>
                  <Typography variant="h5">
                    {kFormatter(domainToOtherPage - domainToInsidePage)}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProgressCard;
