import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Button,
  TextField,
  createFilterOptions,
  Alert,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

import { api } from "../../../../contexts/JWTContext";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../components/Loaders/Loader";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseModal,
  companyId,
  allRoles,
  setFieldValue,
  setRoleRequired,
  handleSubmit,
  roleRequired,
  setSelectedLocations,
  selectedLocations,
  fieldValue,
  linkLoading,
  selectAllLocations,
  setSelectAllLocations,
}) => {
  const { t } = useTranslation();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyLocations, setCompanyLocations] = useState([]);
  const [manageLocation, setManageLocation] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [isLoading, setIsLoading] = useState(false);
  const filter = createFilterOptions();

  const manangeLocations = [
    { label: "All Locations" },
    { label: "Specific Locations" },
  ];

  useEffect(() => {
    if (open === true) {
      getSelectedCompany();
    }
  }, [open]);

  const getSelectedCompany = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/company/${companyId}`);
      if (res.status === 200) {
        setSelectedCompany(res.data.data);
        setCompanyLocations(res?.data?.data?.locations);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "450px" } }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                }}
              >
                {title}
              </Typography>
            </Box>
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid
                container
                spacing={2}
                sx={{
                  overflowY: "auto",
                  //  marginLeft: "-11px",
                }}
              >
                {" "}
                <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginTop: "8px",
                    }}
                    gutterBottom
                    component="div"
                  >
                    {t("Select_Role")}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={allRoles !== null ? allRoles?.rows : []}
                    getOptionLabel={(option) =>
                      (
                        option?.name.charAt(0).toUpperCase() +
                        option?.name.slice(1)
                      ).replaceAll("-", " ")
                    }
                    sx={{ width: "100%" }}
                    value={fieldValue}
                    //  onBlur={handleBlur}
                    onChange={(e, value) => {
                      setFieldValue(value);
                      setRoleRequired(false);

                      if (value?.name === "company-admin") {
                        let locationIds = [];
                        if (companyLocations?.length > 0) {
                          companyLocations?.map((item) => {
                            locationIds?.push(item);
                          });
                        }
                        setSelectAllLocations(true);
                        setSelectedLocations(locationIds);
                      } else {
                        setSelectAllLocations(false);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        name="role"
                        // disabled={isLoading}
                        error={Boolean(roleRequired)}
                        helperText={roleRequired && "Role is Required"}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                {fieldValue !== null && fieldValue?.name === "company-admin" && (
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    lg={12}
                    sx={{ margin: "10px 0px 10px 0px" }}
                  >
                    <Alert mt={2} mb={3} severity="warning">
                      {t("location_access_message")}
                    </Alert>
                  </Grid>
                )}
                {fieldValue !== null && fieldValue?.name !== "company-admin" && (
                  <>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      lg={12}
                      sx={{ bgcolor: "" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: "700",
                            marginTop: "8px",
                          }}
                          gutterBottom
                          component="div"
                        >
                          {t("location_access")}
                        </Typography>
                        <BootstrapTooltip
                          title={
                            <h1 style={{ fontSize: "12px" }}>
                              {t("location_access_tooltip")}
                            </h1>
                          }
                        >
                          <ErrorOutlineIcon
                            // fontSize="small"
                            sx={{
                              color: "#1B2430",
                              marginLeft: "8px",
                              marginTop: "8px",
                            }}
                          />
                        </BootstrapTooltip>
                      </Box>

                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={manangeLocations}
                        fullWidth
                        onChange={(e, value) => {
                          setManageLocation(value);

                          if (value?.label === "All Locations") {
                            let locationIds = [];
                            if (companyLocations?.length > 0) {
                              companyLocations?.map((item) => {
                                locationIds?.push(item);
                              });
                            }
                            setSelectAllLocations(true);
                            setSelectedLocations(locationIds);
                          } else {
                            setSelectAllLocations(false);
                          }
                        }}
                        value={manageLocation}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={t("locations")} />
                        )}
                      />
                    </Grid>
                    {manageLocation?.label === "Specific Locations" && (
                      <Grid
                        item
                        md={12}
                        sm={12}
                        xs={12}
                        lg={12}
                        sx={{ bgcolor: "" }}
                      >
                        {companyLocations?.length > 0 && (
                          <>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontWeight: "700",
                                marginTop: "8px",
                              }}
                              gutterBottom
                              component="div"
                            >
                              {t("Select_Locations")}
                            </Typography>
                            <Autocomplete
                              multiple
                              //  error={locationError}
                              disablePortal
                              id="combo-box-demo"
                              disableCloseOnSelect
                              options={
                                companyLocations?.length > 0
                                  ? companyLocations
                                  : []
                              }
                              value={selectedLocations}
                              onChange={(event, value) => {
                                // Check if 'All' option is clicked

                                if (value.find((option) => option.id === 0)) {
                                  // Check if all options are selected
                                  if (
                                    companyLocations?.length ===
                                    selectedLocations?.length
                                  ) {
                                    setSelectedLocations([]);
                                    setSelectAll(false);
                                  } else {
                                    setSelectAll(true);
                                    setSelectedLocations(companyLocations);
                                    //  setLocationRequired(false);
                                  }
                                } else {
                                  setSelectedLocations(value);
                                  if (
                                    value?.length === companyLocations?.length
                                  ) {
                                    setSelectAll(true);
                                  } else {
                                    setSelectAll(false);
                                  }
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                if (companyLocations?.length > 0) {
                                  return [
                                    { id: 0, internalName: "Select All" },
                                    ...filtered,
                                  ];
                                } else {
                                  return [];
                                }
                              }}
                              getOptionLabel={(option) =>
                                option?.internalName
                                  ? option?.internalName +
                                    " " +
                                    "(" +
                                    (option?.addressLine1 !== "" &&
                                    option?.addressLine1 !== null &&
                                    option?.addressLine1 !== undefined
                                      ? option?.addressLine1 + "," + " "
                                      : "") +
                                    (option?.city ? option?.city : "") +
                                    ")"
                                  : ""
                              }
                              sx={{ minWidth: 300 }}
                              // value={locationId ? locationId : []}
                              // onChange={(e, value) => {
                              //   setLocationRequired(false);

                              //   setSelectedLocation(value);
                              // }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    id="select-all-checkbox"
                                    checked={selectAll || selected}
                                    // checked={selected}
                                  />
                                  {`${option?.internalName} ${
                                    option?.addressLine1 !== "" &&
                                    option?.addressLine1 !== null &&
                                    option?.addressLine1 !== undefined
                                      ? "(" + option?.addressLine1 + "," + " "
                                      : ""
                                  } ${option?.city ? option?.city + ")" : ""}`}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  name="language"
                                  {...params}
                                  //  error={locationError}
                                  placeholder={t("Select_Location")}
                                  //  defaultValue={{ selectedLocation }}
                                  //  helperText={locationError ? t("location_req") : ""}
                                />
                              )}
                            ></Autocomplete>
                          </>
                        )}
                      </Grid>
                    )}
                  </>
                )}
              </Grid>
            )}
          </DialogContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
            }}
          >
            {/*   <Box sx={{ color: "#495059", marginLeft: "4px" }}>
              <Checkbox
                checked={isModalEmailsSent}
                onChange={handleChangeChecked}
              />
              {t("modal_email_sent_msg")}
            </Box> */}
            <Box sx={{ marginRight: "10px" }}>
              <CommonButton
                displayWhite="true"
                onSubmit={handleCloseModal}
                //  disabled={isSubmitting}
                label={t("Cancel")}
              />

              <CommonButton
                isloading={linkLoading}
                //  type="submit"
                disabled={
                  linkLoading || fieldValue === null
                    ? true
                    : fieldValue?.name !== "company-admin" &&
                      manageLocation?.label !== "All Locations" &&
                      selectedLocations?.length <= 0
                    ? true
                    : false
                }
                label={t("Link_user")}
                onSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
