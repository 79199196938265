import React from "react";
import data from "../data.json";
import {
  Box,
  Grid,
  Card,
  Paper as MuiPaper,
  Typography,
  Button,
  Stack,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { useStyles } from "../../../../Styles/style";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function Languagetags() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Grid container sx={{ padding: "16px" }}>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Typography className={classes.headName}>{t("Language")}</Typography>
        </Grid>
        <Grid item xs={7} sm={7} md={7} lg={7}>
          <Box className={classes.tagsBoxDetails}>
            {data.languagetype.map((languagetype) => (
              <Item className={classes.tagsItem} key={languagetype.id}>
                <Typography className={classes.tagsTextDetails}>
                  {languagetype.name}{" "}
                </Typography>
              </Item>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
export default Languagetags;
