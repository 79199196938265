import { makeStyles } from "@mui/styles";

const color = {
  darkGrey: "#1B2430",
  blue: "#06BDFF",
  grey: "#495059",
  white: "#FFFFFF",
  lightGrey: "#8D9298",
  darkPink: "#FC3652",
  lightGreen: "#13CF8F",
  darkBlue: "#0638C1",
  lightBlue: "#06BDFF",
  lightGray: "#E0E0E0",
  skyBlue: "#066DE8",
  mustard: "#F8A92B",
  gray: "#F6F6F7",
  graphite: "#545353",
  magenta: "#b02639",
  lightPink: "#FFD0D0",
  babypink: "#ea99a2",
  maroon: "#330404",
  red: "#FF1616",
  darkGray: "#cbd5e1",
  darkGraphite: "#f8fafc",
  darkRed: "#FF0000",
};

export const useStyles = makeStyles((theme) => ({
  box: {
    height: "100vh",
    width: "500px",
    bgcolor: "background.paper",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  cardbox: {
    maxHeight: "100vh",
    height: "auto",
    overflowY: "scroll",
    maxWidth: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },

  cardcontent: {
    marginBottom: "8px",
    // bgcolor: "white",
    display: "flex",
    padding: "8px",
    cursor: "pointer",

    alignItems: "center",
    justifyContent: "space-between",
  },
  titleName: {
    //   fontFamily: "Work Sans",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: color?.darkGrey,
  },
  subheadName: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.grey,
    textAlign: "center",
  },
  locationAddress: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: color.grey,
    // textAlign: "center",
  },
  subheadNavigateCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color.darkGrey,
  },
  subheadGoogleName: {
    fontWeight: 700,
    fontSize: "20px",
    color: color.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    // marginBottom: "16px",
  },
  headLocationName: {
    fontWeight: 700,
    fontSize: "18px",
    color: color.darkGrey,
    textTransform: "capitalize",
  },
  headRatingName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color.darkGrey,
  },
  subHeadRatingName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "10px",
    color: color.darkGrey,
  },
  subHeadLocationName: {
    fontWeight: 400,
    fontSize: "14px",
    color: color.grey,
  },

  fieldhead: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    color: color?.darkGrey,
    padding: "8px 8px 1px 8px",
  },
  nameContainer: {
    "&:hover ": {
      // background: "#F8F8F8",
      //  borderRadius: "8px",
      //  color: "white",
    },
  },
  iconContainer: {
    "&:hover ": {
      // background: "#F8F8F8",
      //   color: "#0300FF",
      //  borderRadius: "8px",
      //  color: "white",
    },
  },
  iconStyle: {
    color: color?.grey,
    marginRight: "5px",
  },
  editButton: {
    padding: "14px 20px",
    width: "140px",
    height: "48px",
    background: color?.blue,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  ratingContainer: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  commonButton: {
    marginRight: "5px",
  },

  cardcontent_map: {
    marginBottom: "8px",
    bgcolor: color?.white,
    display: "flex",
    padding: "14px",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "8px",
    cursor: "pointer",
  },
  titleName_map: {
    fontWeight: 600,
    fontSize: "16px",
    color: color?.white,
    lineHeight: "24px",
  },
  subheadName_map: {
    fontWeight: 400,
    color: color?.white,
    fontSize: "14px",
    lineHeight: "24px",
  },

  locationContainer_map: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    position: "relative",
    bottom: 100,
    borderRadius: "8px",
    padding: "20px",
  },

  heading: {
    fontWeight: 400,
    fontSize: "11px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "10px",
    color: color.grey,
  },

  chartSubHeading: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",
    color: color?.darkGrey,
  },
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: color?.lightGrey,
  },

  chartSubHeadingPercentNegative: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkPink,
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositive: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.lightGreen,
    // paddingTop: "15px",
  },

  locationDetailBox: {
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  percentageBox: {
    display: "flex",
    paddingTop: "10px",
  },
  mainGrid: {
    display: "flex",
    justifyContent: "space-between",
  },
  cardNavigationcontent: {
    paddingTop: "20px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "10px",
    height: "120px",
  },
  subheadNavigationCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color?.darkGrey,
    lineHeight: "24px",
  },
  LinkStyle: {
    textDecoration: "none",
  },
  linkText: {
    textTransform: "capitalize",
    textDecoration: "none",
    display: "flex",
    color: color?.darkBlue,
  },
  headLocationNameRating: {
    fontWeight: 700,
    fontSize: "18px",
    color: color?.darkGrey,
    textTransform: "capitalize",
  },

  editButtonMap: {
    padding: "14px 20px",
    width: "100%",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
  },
  containergraphconfig: {
    opacity: "0.4",
  },
  headingPieChart: {
    fontWeight: 400,
    fontSize: "14px",
    textDecoration: "none",
    lineHeight: "20px",
    marginTop: "2px",

    color: color.grey,
  },

  pieChartSubHeading: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",

    color: color?.darkGrey,
  },
  chartSubHeadingconfig: {
    fontWeight: 700,
    fontSize: "32px",
    textDecoration: "none",
    lineHeight: "40px",
    color: color?.lightGray,
  },

  locationPieChartDetailBox: {
    width: "100%",
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "20px",
  },
  subheadGoogleNamePie: {
    fontWeight: 700,
    fontSize: "20px",
    color: color?.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    // marginBottom: "16px",
  },
  pieChartDiv: {
    width: "80%",
    margin: "auto",
  },
  namePieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.skyBlue,
    marginRight: "5px",
    borderRadius: "50%",

    display: "inline-block",
  },
  categoryPieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.lightBlue,
    borderRadius: "50%",
    marginRight: "5px",
    display: "inline-block",
  },
  brandPieChart: {
    height: "12px",
    width: "12px",
    backgroundColor: color?.mustard,
    borderRadius: "50%",
    marginRight: "5px",
    display: "inline-block",
  },
  percentageBoxPie: {
    display: "flex",
    paddingTop: "10px",
    marginLeft: "10px",
  },
  chartSubHeadingPercentNegativePie: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.darkPink,
    //  paddingTop: "15px",
  },
  chartSubHeadingPercentPositivePie: {
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "none",
    lineHeight: "18px",
    color: color?.lightGreen,
    // paddingTop: "15px",
  },
  mainGridPieChart: {
    display: "flex",
  },
  cardReviews: {
    backgroundColor: color?.gray,
    borderRadius: "none",

    height: "auto",
  },

  headReviewsName: {
    fontWeight: 700,
    fontSize: "32px",
    color: color?.darkGrey,
  },
  subHeadReviewsName: {
    fontWeight: 400,
    // marginTop: "8px",
    lineHeight: "14px",
    fontSize: "14px",
    color: color.grey,
  },
  subHeadReviewsNameBox: {
    fontWeight: 700,
    // marginTop: "8px",
    lineHeight: "24px",
    fontSize: "18px",
    color: color?.darkGrey,
    marginTop: "10px",
    marginBottom: "4px",
  },
  headingAccount: {
    fontSize: "18px",
    fontWeight: 600,
  },
  cardContentAccount: {
    height: "auto",
    width: "100%",
    padding: "30px",
  },
  accountsContainer: {
    paddingTop: "10px",
    justifyContent: "center",
    alignItems: "center",
  },
  accountsDiv: {
    padding: "10px",
    display: "flex",
  },
  accountName: {
    fontSize: "13px",
    fontWeight: 600,
    color: color?.graphite,
    textTransform: "capitalize",
  },
  buttonContainerAccount: {
    padding: "10px",
  },
  locationBoxAccount: {
    marginTop: "100px",
    display: "row",
    alignItems: "center",
    textAlign: "center",
    width: "50%",
    margin: "auto",
  },
  inputRoot: {
    color: "inherit",
  },
  errorHeadingAccount: {
    fontSize: "16px",
    fontWeight: 700,
  },
  errorHeadingTextAccount: {
    fontSize: "14px",
    fontWeight: 600,
    color: color?.graphite,
  },

  MainBoxLocation: {
    padding: "24px",
  },

  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
  connectBoxLocation: {
    borderRadius: "8px",
    backgroundColor: color?.gray,
    padding: "24px",
    marginBottom: "8px",
  },

  UnsubscribeButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: color?.darkPink,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.magenta,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  subscribeButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "94px",
    height: "40px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  subHeadFetch: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1B2430",
    marginTop: "8px",
  },
  reconnectButtonLocation: {
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 14px",
    width: "114px",
    height: "48px",
    background: color?.lightBlue,
    borderRadius: "8px",
    fontStyle: "normal",
    fontWeight: 700,
    marginRight: "10px",
    fontSize: "14px",
    lineHeight: "20px",
    color: color?.white,
    "&:hover ": {
      background: color?.lightBlue,
      borderRadius: "8px",
      color: color?.white,
    },
  },
  MainboxProduct: {
    padding: "24px",
  },

  subscriptionProductContainer: {
    marginTop: "20px",
  },
  viewMoreButton: {
    fontWeight: 700,
    fontSize: "14px",
    textAlign: "start",
  },
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  styleMainModelContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "570px",
    width: "800px",
    background: "#FFFF",
    border: "2px solid gray",
    borderRadius: "8px",
    // overflow: "auto",
    //  boxShadow: 24,
    padding: "16px",
  },
  styleMainModelContainerBulk: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "575px",
    width: "820px",
    background: "#FFFF",
    //  border: "2px solid gray",
    borderRadius: "8px",
    // overflow: "auto",
    // boxShadow: 24,
    padding: "16px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "10px",
    height: "430px",
    overflowY: "auto",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
  subHeadingStyle: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    // variant="subtitle1"
    color: "#495059",
  },

  tagItem: {
    margin: "4px",
    marginBottom: "0px",
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
  },
  keywordBox: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
  },
  keywordTypo: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
    textTransform: "capitalize",
  },
  keywordIcon: {
    marginLeft: "6px",
    fontSize: "16px",
    color: "#1B2430",
    cursor: "pointer",
    "&:hover ": {
      color: "#06BDFF",
    },
  },
  tagsInput: {
    flexGrow: 1,
    padding: "4.5px 0",
    border: "none",
    outline: "none",
    overflow: "hidden",
  },
  tagsInputContainer: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    padding: "0.5em",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },
  limitFieldTextDescription: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "3px",
  },
  iconClass: {
    fontSize: "70px",
    color: "#F8A92B",
  },
  makePrimaryUserTextHead: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#1B2430",
  },
  dialogBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
  makePrimaryUserTex: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1B2430",
  },
  makePrimaryActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "17px",
  },
  dialogBoxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
  },
}));
