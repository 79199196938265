import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  MenuItem,
  Menu,
  Typography,
  Dialog,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Modal,
  Tooltip,
  tooltipClasses,
  Tabs,
  Tab,
  useMediaQuery,
} from "@mui/material";
import DeleteBulk from "../../../components/Models/DeleteModal/BulkDelete";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Comments from "./Components/Comments";
import { api } from "../../../contexts/JWTContext";
import AddTask from "./Components/AddTask";
import EditTask from "./Components/EditTask";
import TitleHeader from "../../../components/CustomComponents/title";
import Status from "../../../components/status/Status";
import { Edit } from "@mui/icons-material";
import NotFound from "../../../components/NotFound/NotFound";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import Delete from "../../../components/Models/DeleteModal/Delete";
import Checkbox from "@mui/material/Checkbox";
import useAuth from "../../../hooks/useAuth";
import { handleSessionOut } from "../../../contexts/JWTContext";
// import { Title } from 'chart.js';

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

// const TextField = styled(MuiTextField)(spacing);

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    bgcolor: "background.paper",

    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "scroll",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  editMainGrid: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
    backArrow: {
      height: "40px",
      color: "blue",
    },
    textField: {
      height: "40px",
      backgroundColor: "red",
    },
  },
}));

const style = {
  position: "absolute",

  right: "0%",

  width: "450px",

  height: "100vh",

  bgcolor: "background.paper",
  aspectRatio: "16 / 9",

  p: 4,
};
const stylee = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  right: "0%",
  width: "70%",
  bgcolor: "background.paper",
  aspectRatio: "16 / 9",
  p: 4,
};

const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "600px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const Tasks = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { signOut } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [title, setTitle] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);

  const [description, setDescription] = useState(null);
  const [getTask, setGetTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  // const [selectedUser, setSelectedUser] = useState();
  const [openModalEditTask, setOpenModalEditTask] = React.useState(false);
  const [openModalAddTask, setOpenModalAddTask] = React.useState(false);
  const [searchTerms, setSearchTerms] = useState("");
  const [page, setPage] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedTab, setSelectedTab] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [getCount, setGetCount] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [deleteTask, setDeleteTask] = useState(null);
  const [deleteTasks, setDeleteTasks] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getTasks();
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName, rowsPerPage, page]);

  useEffect(async () => {
    setIsLoading(true);
    getAllCompanies();
    // getTasks();
  }, [rowsPerPage, page]);
  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    {
    }
    if (e.target.checked) {
      for (var i = 0; i < getTask?.results.length; i++) {
        deleteTasks.push(getTask?.results[i].id);
      }

      const uniqueTasks = new Set(deleteTasks);
      {
      }
      setDeleteTasks([...uniqueTasks]);
    } else {
      setDeleteTasks([]);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseModal = (apicall) => {
    setOpenModalAddTask(false);
    if (apicall) {
      getTasks();
    }
  };
  const filterByName = (e) => {
    // setSearchTerms(e.target.value);
    setFilterWithName(e.target.value);
  };

  //  const handleOpenEditUser = () => {
  //   // setSelectedUser(event.target.value);

  //   setOpenModalEditTask(true);
  // };

  const handleOpenEditTask = (row) => {
    setOpenModalEditTask(true);
    setSelectedTask(row);
  };

  const handleCloseModalEditTask = (apicall) => {
    setOpenModalEditTask(null);
    setSelectedTask(null);
    if (apicall) {
      getTasks();
    }
  };

  const handleCompanyChange = (option) => {
    getAllCompanies();
    setCompanyId(option?.id);
    if (allCompanies.locations?.length !== 0) {
      setSelectedCompany(option.locations);
    } else {
      setSelectedCompany("no location found with this id");
    }
  };

  const handleLocationChange = (option) => {
    setLocationId(option?.id);
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };

  const createTask = async () => {
    try {
      const res = await api.post("/tasks", {
        title: title,
        description: description,
        companyId: companyId,
        locationId: locationId,
      });
      if (res.status === 201) {
        // setAllCompanies(res.data.data);

        toast.success("Task Added Successfully");
      }
    } catch (error) {
      toast.error("error");
    }
    ////
  };

  const getTasks = async () => {
    // alert("11");
    setIsLoading(true);
    try {
      const res = await api.get(
        `/tasks?limit=${rowsPerPage}&page=${
          page + 1
        }&searchTerm=${filterWithName}`
        // {
        //   params: {
        //     searchTerm: searchTerms,
        //   },
        // }
      );
      if (res.status === 200) {
        setGetTask(res.data.data);
        setGetCount(res.data.data);
        setIsLoading(false);
        setDeleteTasks([]);
        setSelectAll(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const handleDeleteSelectedTasks = async (deleteTaskIds) => {
    setLoading(true);
    try {
      const res = await api.patch(`tasks/removeTasks`, {
        all: false,
        taskIds: deleteTaskIds,
      });

      if (res.status === 200) {
        getTasks();
        toast.success("Task Deleted Successfully");
        setOpenModal(false);
        setOpenDeleteModal(false);
        setSelectAll(false);
        closeMenu(false);
        setDeleteTasks([]);
      }
      setLoading(false);
    } catch (error) {
      setDeleteTasks([]);
      toast.error("Tasks Delete Failed");
    }
  };
  const handleDeleteAllTasks = async () => {
    setLoading(true);
    try {
      const res = await api.patch(`tasks/removeTasks`, {
        all: true,
        // taskIds: deleteTaskIds,
      });

      if (res.status === 200) {
        getTasks();
        toast.success("Task Deleted Successfully");
        setOpenModal(false);
        setSelectAll(false);
        closeMenu(false);
        setDeleteTasks([]);
      }
      setLoading(false);
    } catch (error) {
      setDeleteTasks([]);
      toast.error("Tasks Delete Failed");
    }
  };
  const handleClick = (event, id, selectedData) => {
    setAnchorEl(event.currentTarget);
    setDeleteTask(id);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
    setOpenDeleteModal(false);
  };
  const handleOpen = () => setOpenModalAddTask(true);
  const handleDeleteModal = () => setOpenDeleteModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
    setOpen(false);
    setAnchorEl(null);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
    // setSelectedRow(null);
  };
  const handleOpenDeleteModel = () => {
    setOpen(deleteTask);
    setAnchorEl(null);
  };
  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };
  const handleDeleteTask = async (id) => {
    try {
      const res = await api.delete(`/tasks/${id}`);
      if (res.status === 200) {
        setOpen(false);
        toast.success("Task deleted successfully");
        getTasks();
      }
    } catch (error) {}
  };
  const handleTaskChecked = (e, index, taskId) => {
    if (e.target.checked) {
      deleteTasks.push(taskId);
      {
      }
    } else {
      var taskIndex = deleteTasks.indexOf(taskId);
      deleteTasks.splice(taskIndex, 1);
    }
    setDeleteTasks([...deleteTasks]);
    {
    }
  };

  return (
    <>
      <TitleHeader title={t("TasksTitle")} subHeading={t("TaskSubTitle")} />

      {/* <Paper sx={{ backgroundColor: "#F7F9FC" }}> */}
      <Grid container spacing={0.5}>
        <Grid item xs={7} md={9} lg={10} sm={7}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getTasks}
            placeholder={t("SearchTask")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={5}
          sm={5}
          md={3}
          lg={2}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CommonButton
            label={t("SearchButton")}
            onSubmit={getTasks}
            alignment={true}
          />
          <CommonButton
            label={t("Add")}
            onSubmit={handleOpen}
            alignment={true}
          />
        </Grid>
      </Grid>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {deleteTasks.length > 0 && (
            <Grid container>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className={classes.editMainGrid}
              >
                <CommonButton
                  // onSubmit={handleOpenModal}
                  onSubmit={handleDeleteModal}
                  label={"Delete"}
                />
              </Grid>
            </Grid>
            // <Card>
            //   <Tooltip title="Delete">
            //     <IconButton
            //       className={classes.iconStyle}
            //       aria-label="delete"
            //       size="large"
            //       // color="red"
            //       onClick={() => handleOpenModal()}
            //     >
            //       <DeleteIcon />
            //     </IconButton>
            //   </Tooltip>
            // </Card>
          )}
          {getTask?.results?.length > 0 ? (
            <>
              <Grid container spacing={8} sx={{ marginTop: "1rem" }}>
                <TableContainer
                  borderRadius="12"
                  component={Paper}
                  marginLeft="2rem"
                >
                  <Table
                    // sx={{
                    //   minWidth: 650,
                    // }}
                    aria-label="simple table"
                  >
                    <CustomizedTableHead>
                      <CustomizedTableRow>
                        <CustomizedTableCell>
                          {getTask?.results?.length > 0 && (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ display: "flex" }}
                            >
                              <Checkbox
                                checked={selectAll}
                                onChange={(e) => handleChangeCheckButton(e)}
                                //   inputProps={{ "aria-label": "controlled" }}
                              />
                            </Grid>
                          )}
                        </CustomizedTableCell>
                        <CustomizedTableCell>{t("ID")}</CustomizedTableCell>
                        <CustomizedTableCell>{t("Title")}</CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Location")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Company_Name")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>{t("Status")}</CustomizedTableCell>
                        <CustomizedTableCell>{t("Action")}</CustomizedTableCell>
                      </CustomizedTableRow>
                    </CustomizedTableHead>
                    <TableBody>
                      {getTask?.results?.map((row, index) => (
                        <CustomizedTableRow
                          style={{ height: 10 }}
                          sx={{ cursor: "pointer" }}
                          // onClick={() => {
                          //   handleOpenEditTask(row);
                          // }}
                        >
                          <CustomizedTableCell hover>
                            <Checkbox
                              checked={deleteTasks.includes(row.id)}
                              onChange={(e) => {
                                handleTaskChecked(e, index, row.id);
                              }}
                              //   inputProps={{ "aria-label": "controlled" }}
                              label=""
                            />
                          </CustomizedTableCell>
                          <CustomizedTableCell hover>
                            {" "}
                            {row.id}
                          </CustomizedTableCell>
                          <CustomizedTableCell sx={{ width: "300px" }}>
                            {row.title}
                          </CustomizedTableCell>
                          <CustomizedTableCell
                            align="center"
                            sx={{ width: "300px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              {" "}
                              {row?.location?.internalName !== undefined &&
                              row?.location?.internalName !== null ? (
                                <>
                                  <LocationOnIcon />{" "}
                                  {row?.location?.internalName}
                                </>
                              ) : (
                                "--"
                              )}
                            </Box>
                          </CustomizedTableCell>
                          <CustomizedTableCell align="left">
                            <Box
                              sx={{
                                display: "flex",
                                alignContent: "center",
                                // marginTop: "1rem",
                              }}
                            >
                              {" "}
                              <BusinessIcon
                                sx={{ marginRight: ".5rem", align: "center" }}
                              />{" "}
                              {row?.company?.name}
                            </Box>
                          </CustomizedTableCell>
                          <CustomizedTableCell align="left">
                            {" "}
                            <Status task={row} />
                          </CustomizedTableCell>
                          <CustomizedTableCell align="left">
                            <CustomWidthTooltip title={t("EditTask")}>
                              <IconButton
                                aria-label="edit"
                                size="large"
                                onClick={(e) => {
                                  // e.stopPropagation();
                                  handleOpenEditTask(row);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            </CustomWidthTooltip>
                            <IconButton
                              onClick={(e) => handleClick(e, row?.id)}
                              variant="outlined"
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              MenuListProps={{
                                "aria-labelledby": "long-button",
                              }}
                              anchorEl={anchorEl}
                              open={Boolean(anchorEl)}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  closeMenu();
                                  handleOpenDeleteModel();
                                  // handleOpenModal(row?.id);
                                }}
                              >
                                <DeleteIcon
                                  sx={{ color: "#545353", paddingRight: "5px" }}
                                />
                                <Typography
                                  aria-label="delete"
                                  size="large"
                                  onClick={(e) => {
                                    setOpen(true);
                                  }}
                                >
                                  {t("Delete")}
                                </Typography>
                              </MenuItem>
                            </Menu>
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={getTask?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound text={t("No_Task_Found")} />
            </>
          )}
        </>
      )}

      {openModalAddTask && !isMobile && (
        <Modal
          open={openModalAddTask}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={style}>
                {/* <Typography variant="h2" sx={{ textAlign: "center" }}>
                  {t("AddTask")}
                </Typography> */}
                <AddTask
                  task={getTask}
                  alignments={true}
                  onCancel={handleCloseModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModalAddTask && isMobile && (
        <Modal
          open={openModalAddTask}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={stylee}>
                {/* <Typography variant="h2" sx={{ textAlign: "center" }}>
                  {t("AddTask")}
                </Typography> */}
                <AddTask
                  task={getTask}
                  alignments={true}
                  onCancel={handleCloseModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModalEditTask && !isMobile && (
        <Modal
          open={openModalEditTask}
          onClose={handleCloseModalEditTask}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={style}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    marginY: "10px",
                  }}
                >
                  {/*  <ArrowBackIcon
                    sx={{
                      cursor: "pointer",
                      color: "black",
                      mr: "0.5rem",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      handleCloseModalEditTask();
                    }}
                  /> */}
                  <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                    {t("EditTask")}
                  </Typography>
                </Box>
                <Divider my={6} />
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <Tabs
                    sx={{ width: "100%", marginTop: "2px" }}
                    className={classes.Tabs}
                    value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                    centered
                  >
                    <Tab sx={{ width: "50%" }} label={t("Details")} />
                    <Tab sx={{ width: "50%" }} label={t("Comments")} />
                  </Tabs>
                  {selectedTab === 0 && (
                    <EditTask
                      onCancel={handleCloseModalEditTask}
                      selectedTask={selectedTask}
                      alignments={true}
                    />
                  )}
                  {selectedTab === 1 && (
                    <Comments
                      selectedTask={selectedTask}
                      onCancel={handleCloseModalEditTask}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModalEditTask && isMobile && (
        <Modal
          open={openModalEditTask}
          onClose={handleCloseModalEditTask}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={stylee}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    marginY: "10px",
                  }}
                >
                  {/*  <ArrowBackIcon
                    sx={{
                      cursor: "pointer",
                      color: "black",
                      mr: "0.5rem",
                      width: "18px",
                      height: "18px",
                    }}
                    onClick={() => {
                      handleCloseModalEditTask();
                    }}
                  /> */}
                  <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                    {t("EditTask")}
                  </Typography>
                </Box>
                <Divider my={6} />
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <Tabs
                    sx={{ width: "100%", marginTop: "2px" }}
                    className={classes.Tabs}
                    value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                    centered
                  >
                    <Tab sx={{ width: "50%" }} label={t("Details")} />
                    <Tab sx={{ width: "50%" }} label={t("Comments")} />
                  </Tabs>
                  {selectedTab === 0 && (
                    <EditTask
                      onCancel={handleCloseModalEditTask}
                      selectedTask={selectedTask}
                      alignments={true}
                    />
                  )}
                  {selectedTab === 1 && (
                    <Comments
                      selectedTask={selectedTask}
                      onCancel={handleCloseModalEditTask}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Task")}
            description={t("del_task_subtitle")}
            onConfirm={() => handleDeleteTask(deleteTask)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openModal}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Bulk_Task")}
            description={t("Delete_bulk_subhead")}
            loading={loading}
            onConfirm={handleDeleteAllTasks}
            // onConfirm={() => isLoading ?  <> <CircularProgress/> </> : handleDeleteBulkTasks(deleteTasks)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openDeleteModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDeleteModal}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DeleteBulk
            title={t("Delete")}
            description={t("Select_Delete_Task")}
            loading={loading}
            // loadingBulk={loadingBulk}
            getCount={getCount}
            onConfirm={handleOpenModal}
            onConfirmBulk={handleDeleteSelectedTasks}
            deleteTasksIds={deleteTasks}
            // onConfirm={() => handleDeleteBulkTasks(deleteTasks)}

            // onConfirm={() => isLoading ?  <> <CircularProgress/> </> : handleDeleteBulkTasks(deleteTasks)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {/* </Paper> */}
    </>
  );
};

export default Tasks;
