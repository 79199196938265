import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Card,
  Box,
  ThemeProvider,
  CardContent,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";

import StarIcon from "@mui/icons-material/Star";
import LinearProgress from "@mui/material/LinearProgress";
import Rating from "@mui/material/Rating";
import { spacing } from "@mui/system";

import ReviewsActions from "./ReviewsActions";
import ReviewsBarChart from "./ReviewsBarChart";
import ReviewsTable from "./ReviewsTable";
import { useParams } from "react-router-dom";

import ReviewsLinebarChart from "./ReviewsLinebarChart";
import ReviewsLineChart from "./ReviewsLineChart";
import Loader from "../../../../components/Loaders/Loader";
import { api } from "../../../../contexts/JWTContext";

// const accessToken = window.localStorage.getItem("accessToken");
// const config = {
//   headers: { Authorization: `Bearer ${accessToken}` },
// };

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFA500",
    },
  },
});

function Reviews() {
  let { id } = useParams();

  const [allReviews, setAllReviews] = useState([]);

  const [loading, setLoading] = useState(false);
  const [setIsError] = useState(false);

  const getAllReviews = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/location/review-dashboard/${id}`);
      if (res.status === 200) {
        setAllReviews(res.data.data);

        setLoading(false);
        // setIsError(true);
      }
    } catch (error) {
      setIsError(error);
      // setAllReviews(error);
    }
  };

  const normalise = (value) => {
    return (value / allReviews.reviewSummary.reviewCount) * 100;
  };
  return (
    <>
      <Helmet title="SaaS Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Factory Girl
          </Typography>
        </Grid>

        <Grid item>
          <ReviewsActions />
        </Grid>
      </Grid>
      <Divider my={6} />
      {loading !== false ? (
        <Loader />
      ) : (
        <>
          <Typography variant="h2">Platform overview</Typography>
          <Grid container spacing={6} sx={{ marginTop: "20px" }}>
            <Grid item xs={12} sm={6} lg={6}>
              <Card
                sx={{
                  height: "350px",
                }}
              >
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      marginTop: "10px",
                      color: "#808080",
                    }}
                  >
                    REVIEW SUMMARY
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "35px",
                      marginBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: "15px",
                        marginLeft: "15px",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "45px",
                          color: "#676767",
                          weight: "300",
                        }}
                      >
                        {allReviews.reviewSummary?.avgRating}
                      </Typography>
                    </Box>
                    <Box>
                      <Rating
                        name="half-rating"
                        size="small"
                        defaultValue={allReviews.reviewSummary?.avgRating}
                        precision={0.5}
                        readOnly
                      />
                      <Typography
                        sx={{ color: "#B6B6B4" }}
                      >{`${allReviews.reviewSummary?.reviewCount} Reviews`}</Typography>
                    </Box>
                  </Box>

                  {allReviews.reviewSummary?.ratings
                    .reverse()
                    .map((review, index) => {
                      return (
                        <>
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "15px",
                            }}
                          >
                            <Box
                              marginRight={2}
                              marginLeft={5}
                              sx={{
                                color: "#B6B6B4",
                                fontSize: "12px",
                              }}
                            >
                              {review.rating}
                            </Box>
                            <StarIcon
                              sx={{
                                fontSize: "15px",
                                marginRight: "10px",
                                color: "#B6B6B4",
                              }}
                            />
                            <Box sx={{ marginRight: "10px" }}>
                              <ThemeProvider theme={theme}>
                                <LinearProgress
                                  variant="determinate"
                                  value={normalise(review.reviewCount)}
                                  color="primary"
                                  sx={{
                                    backgroundColor: "#e5e5e2",
                                    width: "250px",
                                  }}
                                />
                              </ThemeProvider>
                            </Box>
                            <Box
                              sx={{
                                color: "#B6B6B4",
                                fontSize: "12px",
                              }}
                            >
                              {review.reviewCount}
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <Card
                sx={{
                  height: "350px",
                }}
              >
                <CardContent>
                  {allReviews ? (
                    <ReviewsTable allReviews={allReviews} />
                  ) : (
                    <div>...Loading</div>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Divider my={6} />

          <Grid container>
            <Grid item xs={12} sm={12} lg={12}>
              <ReviewsBarChart allReviews={allReviews} />
            </Grid>
          </Grid>

          <Divider my={6} />

          <Grid container spacing={6}>
            <Grid item xs={12} sm={6} lg={6}>
              <ReviewsLinebarChart allReviews={allReviews} />
            </Grid>

            <Grid item xs={12} sm={6} lg={6}>
              <ReviewsLineChart allReviews={allReviews} />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Reviews;
