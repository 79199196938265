import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
} from "@mui/material";
import Deletes from "./Deletes.css";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Delete = ({
  title,
  description,
  onConfirm,
  onCancel,
  loading,
  schedule,
}) => {
  const [open, setOpen] = useState(false);
  const [deletePost, setDeletePost] = useState(null);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div>
        <Box className="box_delete">
          <DialogContentText
            id="responsive-dialog-title"
            sx={{}}
            className="delete_dialog_content1"
          >
            {title}
          </DialogContentText>
          <DialogContentText
            id="responsive-dialog-title"
            className="delete_dialog_content2"
          >
            {description}
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "8px",
              alignItems: "center",
            }}
          >
            <Button
              autoFocus
              onClick={() => onCancel()}
              className="delete_button"
            >
              {schedule ? t("No_text") : t("Cancel")}
            </Button>

            <Button
              onClick={() => onConfirm()}
              className="delete_button"
              autoFocus
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: "20px", height: "20px" }}
                />
              ) : schedule ? (
                t("Yes")
              ) : (
                t("Confirm")
              )}
              {/*   {t("Confirm")} */}
            </Button>
          </Box>
        </Box>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default Delete;
