import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import logo from "../../../assets/Logo/logoblack.svg";
// import { ReactComponent as Logo } from "../../assets/Logo/Logo.svg";
import SetPasswordComponent from "./setPasswordForm";
import { useTranslation } from "react-i18next";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import NavbarLanguagesDropdown from "../../../components/navbar/NavbarLanguagesDropdown";
// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 256.67px;
//   height: 80.81px;
//   margin-bottom: 18px;
//   top: 83.32px;
//   left: 27.68px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 28px;
    height: 28px;
  }
`;

function SetPassword({ onDrawerToggle }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <img
        alt=""
        src={logo}
        alt="logo"
        style={{
          width: "256.67px",
          height: "100.81px",
          marginBottom: "18px",
          top: "83.32px",
          left: "27.68px",
        }}
      />
      <Wrapper>
        <Helmet title="New Password" />
        <Grid container alignItems="center" sx={{ padding: "3px" }}>
          <Grid item sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs />
          <Grid item>
            <NavbarLanguagesDropdown />
          </Grid>
        </Grid>
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          {t("Enter_New_Password")}
        </Typography>
        {/* <Typography component="h2" variant="body1" align="center">
          Enter your email to reset your password
        </Typography> */}

        <SetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SetPassword;
