import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useParams, useLocation } from "react-router-dom";
import { api } from "../../../contexts/JWTContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/Loaders/Loader";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const { setPassword, isLoading } = useAuth();
  const resetPassword = async (newPassword) => {
    try {
      setLoading(true);

      const res = await api.post(`/auth/reset-password/${id}`, {
        newPassword: newPassword,
      });

      if (res.status === 200) {
        toast.success(res?.data?.message, {});
        navigate("/auth/sign-in");
        // setAllCompanies(res.data.data);
      } else {
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <Formik
      initialValues={{
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .max(25)
          .required(t("confirm_password_validation2")),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (id?.length > 0) {
            setLoading(true);
            resetPassword(values?.password);
          } else {
            toast.error(t("Invalid_token"));
          }
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
          setLoading(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {/* {isLoading && <Loader />} */}
          <TextField
            type="password"
            name="password"
            label="Enter Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t("Reset_Password")}
          </LoadingButton>
          {/* <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Reset password
          </Button> */}
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
