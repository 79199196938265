import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { api } from "../../../contexts/JWTContext";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Alert as MuiAlert,
  Button,
  Typography,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import { useStyles } from "../styles/styles";
const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ResetPassword() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const resetPassword = async (email) => {
    try {
      setLoading(true);

      const res = await api.post(`/auth/forgot-password`, {
        email: email,
      });
      if (res.status === 200) {
        toast.success(res?.data?.message, {});
        navigate("/auth/sign-in");
        // setAllCompanies(res.data.data);
      } else {
      }
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required")
          .matches(/\S+@\S+\.\S+/, `Must be a valid email`),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          setLoading(true);
          await resetPassword(values.email);
        } catch (error) {
          const message =
            error?.response?.data?.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
          setLoading(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          {/* {isLoading && <Loader />} */}

          <TextField
            type="email"
            name="email"
            label={t("Email")}
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            {t("Reset_Password")}
          </LoadingButton>

          <Typography
            className={classes.signupLabel}
           
            /*   disabled={loading}
             */
            component={Link}
            to="/auth/sign-in"
            variant="contained"
            fullWidth
            color="primary"
          >
            {t("back_to_signIn")}
          </Typography>
        </form>
      )}
    </Formik>
  );
}

export default ResetPassword;
