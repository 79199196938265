import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { GraphicEq } from "@mui/icons-material";

const color = {
  pink: "#f68897",
  lightyellow: "#fdf2df",
  orange: "orange",
  gray: "#757575",
};

export const useStyles = makeStyles((theme) => ({
  fieldLabell: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    // textAlign: "center",
    // marginBottom: "6px",
    // marginLeft: "10px",
    marginTop: "12px",
  },
  filterHeaderButtons: {
    textAlign: "end",
  },
  filterHeaderButtonsTwo: {
    justifyContent: "space-between",
    display: "flex",
  },
  filterHeaderButtonsFour: {
    textAlign: "end",
  },
  viewMoreFilterButton: {
    height: "58px",
  },

  viewMoreFilterTypo: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  cardStatsContainer: {
    bgcolor: "#ffff",
    borderRadius: "8px",
    height: "auto",
    padding: "16px",
  },
  locationDetailBoxChart: {
    paddingTop: "0px",
    paddingLeft: "10px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  toast: {
    width: "100%",
    display: "flex",
    //  padding: "0 1.25rem",
    paddingBottom: "1rem",
  },
  toastBox: {
    backgroundColor: "#FFF1D7",
    width: "100%",
    display: "flex",
    borderRadius: "4px",
    //   gap: "1rem",
  },
  toastBoxBorder: {
    backgroundColor: "#FAB431",
    width: "6px",
    borderRadius: "4px 0 0 4px",
  },
  toastBoxContent: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 8px",
  },
  toastBoxText: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem 0",
    gap: "1rem",
    justifyContent: "space-between",
  },
  subheadNavigateCards: {
    fontSize: "16px",
    fontWeight: 700,
    color: color.darkGrey,
  },
  subheadGoogleName: {
    fontWeight: 700,
    fontSize: "20px",
    color: color.darkGrey,
    lineHeight: "28px",
    letterSpacing: "0.5px",
    marginBottom: "16px",
  },
  subheadGoogletext: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#495059",
    lineHeight: "18px",
  },
}));
