import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Button,
  TextField,
  IconButton,
} from "@mui/material";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../../../pages/UserPages/UserAccounts/styles/style";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const ShareableLinkModel = ({
  title,
  handleCloseNewRequestModel,
  open,
  inviteLink,
  subTitle,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [displayText, setDisplayText] = useState(false);

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "auto" } }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <Grid container sx={{ padding: "16px" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Box className={classes.boxStyle}>
                <Typography className={classes.fetchModelTitle}>
                  {title}
                </Typography>
                <BootstrapTooltip
                  title={
                    <h1 style={{ fontSize: "12px" }}>
                      {t("Shareable_Link_tooltip_forLogin")}
                    </h1>
                  }
                >
                  <Typography className={classes.fetchModelIcon}>
                    <InfoOutlinedIcon />
                  </Typography>
                </BootstrapTooltip>
              </Box>
              <Typography className={classes.fetchModelTypography}>
                {subTitle}
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1} sx={{ textAlign: "end" }}>
              <IconButton
                autoFocus
                onClick={handleCloseNewRequestModel}
                className={classes.zeroPadding}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <DialogContent sx={{ padding: "24px" }} dividers>
            <Grid container>
              <Grid item xs={10} md={10} sm={10} lg={10}>
                <TextField
                  fullWidth
                  value={`${process.env.REACT_APP_LOCAL_URL}/auth/sign-in?email=${inviteLink?.email}&password=${inviteLink?.oneTimePassword}`}
                />
              </Grid>
              <Grid item xs={2} md={2} sm={2} lg={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_LOCAL_URL}/auth/sign-in?email=${inviteLink?.email}&password=${inviteLink?.oneTimePassword}`
                    );
                    setDisplayText(true);
                    setTimeout(() => {
                      setDisplayText(false);
                      handleCloseNewRequestModel();
                    }, 3000);
                  }}
                  className={classes.copyText}
                >
                  {t("copy_text")}
                </Button>
                {displayText && (
                  <Typography className={classes.copyTextTypo}>
                    {t("Copied")}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default ShareableLinkModel;
