import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import NotFounds from "NotFounds/NotFound.css";

const NotFound = ({ text, hideIcon, noMargin, fromSuggestions }) => {
  return (
    <Box
      // className="Main_Box"
      sx={{
        marginTop: noMargin === false ? "0px" : "7rem",
        marginInline: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <SearchOutlinedIcon
          // className="Search_icons"
          sx={{
            width: "100px",
            height: "120px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Box>

      <Typography variant={fromSuggestions ? "h3" : "h2"}>{text}</Typography>
    </Box>
  );
};

export default NotFound;
