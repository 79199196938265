import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Modal,
  Dialog,
  useMediaQuery,
  MenuItem,
  Menu,
  IconButton,
  CircularProgress,
  Divider,
  DialogContent,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { ReactComponent as MakePrimaryUser } from "../../../../assets/Icons/makePrimary.svg";
import { ReactComponent as TransferOwnership } from "../../../../assets/Icons/transferOwnership.svg";
import { useParams } from "react-router-dom";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
import Checkbox from "@mui/material/Checkbox";
import ImportModel from "../../../../components/Models/DeleteModal/Importmodal";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import TitleHeader from "../../../../components/CustomComponents/title";
import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../components/NotFound/NotFound";
import styled from "styled-components/macro";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import OTPModel from "../../../../components/Models/DeleteModal/OtpModel";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import AddPrimaryUserModel from "../../../../components/Models/DeleteModal/AddPrimaryUserModel";
import AddPrimaryUserConfirmModel from "../../../../components/Models/DeleteModal/PrimaryUserConfirmModel";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#13CF8F",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  Menu: {
    "&:hover": {
      backgroundColor: "gray !important",
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  primaryChip: {
    background: "#13CF8F",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    width: "80px",
    marginLeft: "-5px",
  },
  primaryChipTypo: {
    fontSize: "10px",
    fontWeight: "400",
    marginY: "auto",
    display: "flex",
    color: "#FFF",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "100vh",
  width: "420px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  justifyContent: "space-around",

  boxShadow: 24,
  p: 4,
};

const Users = () => {
  let { id } = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const [filterWithName, setFilterWithName] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [roleValue, setRoleValue] = useState("All");

  //const [openModal, setOpenModal] = React.useState(false);
  //const [getid, setGetid] = useState([]);
  // const [openResetModel, setOpenResetModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const [count, setCount] = useState("");
  const [allTemplate, setAllTemplate] = useState(null);
  //const [locationId, setLocationId] = useState();
  //const [deleteId, setDeleteId] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalEditUser, setOpenModalEditUser] = React.useState(false);
  const [openModalAddUser, setOpenModalAddUser] = React.useState(false);
  const [companyId, setCompanyId] = useState([]);
  const [checked, setChecked] = React.useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [updateId, setUpdateId] = useState(null);
  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [roleRequired, setRoleRequired] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const Roles = ["account-manager", "location-manager"];
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [openOtpModel, setOpenOtpModel] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [openOneTimeLoginModel, setOpenOneTimeLoginModel] = useState(false);
  const [oneTimeLoginData, setOneTimeLoginData] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [userCompanies, setUserComapies] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [openLinkedConfirmationModel, setOpenLinkedConfirmationModel] =
    useState(false);
  const [openOwnershipModel, setOpenOwnershipModel] = useState(false);
  const [modelType, setModelType] = useState(null);
  const [openMakePrimaryUserModel, setOpenMakePrimaryUserModel] =
    useState(false);
  const [primaryUser, setPrimaryUser] = useState(null);

  const headCells = [
    { id: "id", alignment: "left", label: t("AccountTableID") },
    { id: "firstName", alignment: "left", label: t("First_Name") },
    { id: "lastName", alignment: "left", label: t("Last_Name") },
    { id: "Contact", alignment: "left", label: t("UserTableEmail") },
    { id: "Telephone number", alignment: "left", label: t("UserTableRole") },
    { id: "company", alignment: "left", label: t("Company") },
    { id: "actions", alignment: "left", label: t("Action") },
  ];

  useEffect(async () => {
    getAllCompanies();
  }, [companyId]);

  useEffect(() => {
    getAllUsers();
  }, [page, rowsPerPage, roleValue]);

  useEffect(() => {
    if (isFiltered) {
      const delayDebounceFn = setTimeout(() => {
        getAllUsers();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleCompanyChange = (option) => {
    setCompanyId(option);
    //  setLocationId(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectAll(null);
    setSelectedId([]);
    setIsFiltered(true);
  };

  const handleClick = (event, id, selectedData) => {
    setSelectedRow(selectedData);
    setUpdateId(id);
    setAnchorEl(event.currentTarget);
    setDeleteId(selectedData);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`);
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          const approvedCompanies = res?.data?.data?.results?.filter(
            (item) => item?.companyApproval === true
          );
          setAllCompanies(approvedCompanies);
        }
      }
    } catch (error) {}
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsFiltered(true);
  };

  const getAllUsers = async (searchValue) => {
    try {
      setIsLoading(true);
      // setLoading(true);
      const res = await api.get(
        `/admin/users/wholeApplication?searchTerm=${
          searchValue === false ? "" : filterWithName
        }&page=${page + 1}&limit=${rowsPerPage}${
          roleValue !== "All" ? "&role=" + roleValue : ""
        }`
      );
      if (res.status === 200) {
        // setAllCompanies(res.data.data);
        setUsers(res.data.data);

        // setLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const filterByName = (e) => {
    setPage(0);
    setFilterWithName(e.target.value);
    setIsFiltered(true);
  };

  const handleCloseOneTimePasswordModel = () => {
    setOpenOneTimeLoginModel(false);
    setOneTimeLoginData(null);
  };
  const handleOpenOneTimePasswordModel = () => {
    setOpenOneTimeLoginModel(true);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const handleSubmit = async () => {
    if (fieldValue === "") {
      setRoleRequired(true);
    } else {
      setRoleRequired(false);
      setIsLoading(true);
      try {
        const res = await api.post(`/companyUser`, {
          companyId: id,
          role: fieldValue,
          userId: selectedRow?.id,
        });
        if (res.status === 200) {
          setIsLoading(false);
          handleCloseModal();
          toast.success(res?.data?.message);
          navigate(`/admin/accounts/${id}`);
          // getAllUsers();
        }
      } catch (error) {
        setIsLoading(false);

        toast.error(error?.response?.data?.message);
        handleCloseModal();
      }
    }
  };

  const handleLinkUsers = async () => {
    const companyIds = [];
    if (companyId.length > 0) {
      companyId.map((item) => {
        companyIds.push(item?.id);
      });
    }

    setIsLoading(true);
    try {
      const res = await api.post(`/companyUser/bulkAdd`, {
        companyIds: companyIds,
        userIds: selectedId,
      });
      if (res.status === 200) {
        setIsLoading(false);
        handleCloseModal();
        toast.success(res?.data?.message);
        navigate(`/admin/accounts/link-newuser`);
        setOpenDefaultModel(false);
        setSelectedId("");
        // getAllUsers();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseModal();
    }
  };

  const handleOpenLinkPage = () => {
    // navigate(`/admin/accounts/link-user/${id}`);
  };

  const handleOpenModel = () => {
    setOpenModalAddUser(true);
  };
  const handleCloseModal = () => {
    setSelectedRow(null);
    setOpenModalAddUser(false);
    getAllUsers();
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
    // setSelectedRow(null);
  };

  const handleSelectSingleid = (e, index, id) => {
    if (e.target.checked) {
      selectedId?.push(id);
    } else {
      var userIndex = selectedId.indexOf(id);
      selectedId.splice(userIndex, 1);
    }
    setSelectedId([...selectedId]);
  };

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      const filteredData = users.results?.filter(
        (item) => item?.status === "active"
      );
      for (var i = 0; i < filteredData.length; i++) {
        selectedId.push(filteredData[i].id);
      }
      const uniquePosts = new Set(selectedId);
      setSelectedId([...uniquePosts]);
    } else {
      setSelectedId([]);
    }
  };
  const handleGenerateOTP = async () => {
    setOtpLoading(true);

    try {
      const res = await api.post(`/oneTimeLogin`, {
        email: selectedRow?.email,
        userId: selectedRow?.id,
      });
      if (res.status === 200 || res.status === 201) {
        setOtpLoading(false);

        setOneTimeLoginData(res?.data?.data);
        handleOpenOneTimePasswordModel();
        //  toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        //  getAllUsers();
      }
    } catch (error) {
      setOtpLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };
  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };
  const handleCloseLinedConfirmationModel = () => {
    setOpenLinkedConfirmationModel(false);
  };
  const handleOpenDeleteModel = async (bool, userId) => {
    //

    try {
      const res = await api.get(`user/userInCompanies/${userId}`);
      setDeleteLoading(true);
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          if (res?.data?.data?.length > 1) {
            setOpenLinkedConfirmationModel(true);
            setUserComapies(res?.data?.data);
            setOpenDeleteModel(false);
          } else {
            setOpenLinkedConfirmationModel(false);
            setUserComapies(res?.data?.data);
            setOpenDeleteModel(true);
          }

          setDeleteLoading(false);
        }
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const deleteUserHandler = async () => {
    if (deleteId !== null) {
      setIsLoading(true);
      try {
        const res = await api.delete(`/user/${deleteId?.id}`);
        //  setOpenDeleteModel(false);
        if (res.status === 200) {
          setIsLoading(false);
          setOpenDeleteModel(false);
          setOpenLinkedConfirmationModel(false);
          toast.success("Deleted successfully");
          getAllUsers();
        }
      } catch (error) {
        setIsLoading(false);
        setOpenDeleteModel(false);
        if (
          error?.response?.data?.message ===
            "No Permissions found, You need to log in again" ||
          error?.response?.data?.message ===
            "Your session has expired. Kindly log in again to continue accessing your account."
        ) {
          handleSessionOut(
            error?.response?.status,
            error?.response?.data?.message,
            handleSignOut,
            true
          );
        } else {
          handleSessionOut(
            error?.response?.status,
            error?.response?.data?.message,
            handleSignOut
          );
        }
      }
    }
  };
  const handleOpenChangeOwnershipModel = (keyValue) => {
    setOpenOwnershipModel(true);
    setModelType(keyValue);
  };
  const handleCloseChangeOwnershipModel = () => {
    setOpenOwnershipModel(false);
  };

  const handleChangeFilters = (e) => {
    setRoleValue(e.target.value);
  };
  const handleOpenMakePrimaryUserModel = () => {
    setOpenMakePrimaryUserModel(true);
  };
  const handleCloseMakePrimaryUserModel = () => {
    setOpenMakePrimaryUserModel(false);
  };

  const updateCompanyPrimaryUserHandler = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(
        `/company/${selectedRow?.companyId}/ownerTransfership`,
        {
          companyId: selectedRow?.companyId,
          userId: selectedRow?.id,
        }
      );
      if (res.status === 200) {
        setIsLoading(false);
        getAllUsers();
        handleCloseMakePrimaryUserModel();
        toast.success("Successfully Updated");
      }
    } catch (error) {
      setIsLoading(false);
      handleCloseMakePrimaryUserModel();
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {openMakePrimaryUserModel && (
        <AddPrimaryUserConfirmModel
          openPrimaryModel={openMakePrimaryUserModel}
          selectedRow={selectedRow}
          isLoading={isLoading}
          handleClosePrimaryModel={handleCloseMakePrimaryUserModel}
          updateUserHandler={updateCompanyPrimaryUserHandler}
          primaryUser={primaryUser}
          modelType={"primary"}
        />
      )}
      {openOwnershipModel && (
        <AddPrimaryUserModel
          title={
            modelType !== null &&
            (modelType === "ownership"
              ? t("Transfer_ownership")
              : t("Make_Primary_User"))
          }
          subTitle={t("Transfer_ownershipSubtext")}
          handleCloseModel={handleCloseChangeOwnershipModel}
          open={openOwnershipModel}
          selectedAccount={selectedRow?.companyId}
          selectedUser={selectedRow}
          fromAccounts={true}
          getAllData={getAllUsers}
          modelType={modelType}
        />
      )}
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Userr")}
            description={`${t("delete_user_des")} ${
              deleteId?.firstName !== null && deleteId?.lastName !== null
                ? deleteId?.firstName + " " + deleteId?.lastName
                : t("this_user_text")
            }?`}
            onConfirm={() => {
              setOpenDeleteModel(false);
              deleteUserHandler();
            }}
            onCancel={handleCloseDeleteModel}
          />
        </Dialog>
      )}
      {openLinkedConfirmationModel && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={openLinkedConfirmationModel}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "50px", color: "#F8A92B" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "25px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {` ${t("company_linked")} ${userCompanies?.length - 1} ${
                    userCompanies?.length - 1 === 1
                      ? t("company_linked_Text1")
                      : t("companies_linked_Text")
                  } `}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${
                    deleteId?.firstName !== null && deleteId?.lastName !== null
                      ? deleteId?.firstName + " " + deleteId?.lastName
                      : t("this_user_text2")
                  } ${t("company_linked_desText1")} ${
                    userCompanies?.length - 1
                  } ${
                    userCompanies?.length - 1 > 1
                      ? t("companies_linked_Text")
                      : t("company_linked_Text1")
                  } ${t("company_linked_desText2")} ${
                    userCompanies?.length - 1 === 1
                      ? t("company_linked_desText")
                      : t("company_linked_desText3")
                  } `}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "0px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${t("sepecific_account_deleteText")}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                //  sx={{ paddingTop: "10px" }}
              >
                {" "}
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${t("company_linked_desText4")} ${
                    deleteId?.firstName !== null && deleteId?.lastName !== null
                      ? deleteId?.firstName + " " + deleteId?.lastName
                      : t("this_user_text")
                  }?`}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "14px",
              }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={handleCloseLinedConfirmationModel}
                label={t("No_text")}
              />
              <CommonButton
                label={t("Yes")}
                isLoading={isLoading}
                disabled={isLoading}
                onSubmit={deleteUserHandler}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <ImportModel
            title={
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                {t("Link_user")}
              </Typography>
            }
            // description={t("Are you sure you want to get default temlplate of all companies?")}
            description={
              <Grid container>
                <Grid item sm={12} md={12} lg={12}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "700",
                        paddingBottom: "7px",
                      }}
                    >
                      {t("Select_Company")}
                    </Typography>
                  </Box>
                  {!checked === true && (
                    <>
                      <Autocomplete
                        multiple
                        disablePortal
                        id="combo-box-demo"
                        sx={{ height: "100px" }}
                        options={allCompanies?.length > 0 ? allCompanies : []}
                        getOptionLabel={(option) => option?.name}
                        className="Autocomplete-field"
                        defaultValue={companyId}
                        onChange={(e, value) => {
                          handleCompanyChange(value);
                          setCompanyIdRequired(false);
                        }}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {`${option?.name} `}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            placeholder={t("Select_Company")}
                            {...params}
                            required
                            // label={t("TaskCompany")}
                            name="language"
                            error={companyIdRequired}
                          />
                        )}
                      ></Autocomplete>
                    </>
                  )}

                  {/* <Typography className={classes.subHeadFetch}>Also auto-reply on fetched reviews</Typography> */}
                </Grid>
              </Grid>
            }
            onConfirm={() => handleLinkUsers()}
            onCancel={handleCloseTemplateMenu}
          />
        </Dialog>
      )}
      <Box sx={{ marginTop: "1rem" }}>
        <TitleHeader title={t("Users")} />
      </Box>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={6}
          md={selectedId?.length === 0 ? 6.5 : 4.5}
          lg={selectedId?.length === 0 ? 6.5 : 4.5}
        >
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllUsers}
            placeholder={t("FilterUser")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Select
            sx={{ backgroundColor: "#FFF" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={roleValue}
            label=""
            defaultValue={"domain"}
            onChange={handleChangeFilters}
            //  error={domainRequired}
          >
            <MenuItem value="All">{t("All")}</MenuItem>
            <MenuItem value="primaryUser">{t("PrimaryUsers")}</MenuItem>
            <MenuItem value="company-admin">{t("company_admin")}</MenuItem>
            <MenuItem value="custom">{t("custom_role")}</MenuItem>
          </Select>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={selectedId?.length === 0 ? 1.5 : 3.5}
          lg={selectedId?.length === 0 ? 1.5 : 3.5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            alignment={true}
            onSubmit={getAllUsers}
            label={t("Search")}
          />

          {selectedId?.length > 0 ? (
            <CommonButton
              alignment={true}
              onSubmit={handleOpenTemplateMenu}
              label={t("Link_user")}
            />
          ) : null}
        </Grid>
        {/* <Grid item xs={2} sm={1} md={1}> */}
        <Modal
          open={openModalAddUser}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={style}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Link_user")}
                </Typography>
                <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginTop: "8px",
                    }}
                    gutterBottom
                    component="div"
                  >
                    {t("Select_Role")}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Roles}
                    sx={{ width: "100%" }}
                    onChange={(e, value) => {
                      setFieldValue(value);
                      setRoleRequired(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        name="role"
                        // disabled={isLoading}
                        error={Boolean(roleRequired)}
                        helperText={roleRequired && "Role is Required"}
                      />
                    )}
                  ></Autocomplete>
                  {/* {touched.role && errors.role && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: 400,
                fontSize: "0.6964285714285714rem",
                lineHeight: "1.66",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: 0,
                marginLeft: "14px",
              }}
            >
              {t("Role_req")}
            </Typography>
          )} */}
                </Grid>

                <Grid
                  container
                  spacing={3}
                  //  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  my={3}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    sx={{ display: "flex" }}
                  ></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CommonButton
                      displayWhite="true"
                      onSubmit={handleCloseModal}
                      //  disabled={isSubmitting}
                      label={t("Cancel")}
                    />
                    {
                      <CommonButton
                        //  loading={isLoading}
                        //  type="submit"
                        disabled={isLoading}
                        label={t("Link_user")}
                        onSubmit={handleSubmit}
                      />
                    }
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      {/* </Grid> */}
      {/* </Paper> */}
      {openOneTimeLoginModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openOneTimeLoginModel}
          onClose={handleCloseOneTimePasswordModel}
          aria-labelledby="responsive-dialog-title"
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        >
          <OTPModel
            title={t("one_time_login")}
            description={t("Login_desc")}
            loginURL={oneTimeLoginData}
            //  description={`Are you sure you want to delete ${deleteId?.firstName} ${deleteId?.lastName} ?`}

            onCancel={handleCloseOneTimePasswordModel}
          />
        </Dialog>
      )}

      {/*   {openOtpModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openOtpModel}
          onClose={handleCloseOtpModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("one_time_login")}
            description={t("OtpDescription_text")}
            onConfirm={handleGenerateOTP}
            onCancel={handleCloseOtpModel}
            loading={otpLoading}
          />
        </Dialog>
      )} */}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {users?.results?.length > 0 ? (
            <>
              {" "}
              <TableContainer sx={{ marginTop: "2rem" }} component={Paper}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      <CustomizedTableCell>
                        <Checkbox
                          checked={selectAll}
                          onChange={(e) => handleChangeCheckButton(e)}

                          //   inputProps={{ "aria-label": "controlled" }}
                        />
                      </CustomizedTableCell>
                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {users?.results?.length > 0 &&
                      users?.results?.map((row, index) => {
                        const str = row?.name?.split("/");
                        return (
                          <CustomizedTableRow
                            // hover
                            tabIndex={-1}
                            key={`${row.id}-${index}`}
                          >
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              <Checkbox
                                checked={selectedId.includes(row.id)}
                                onChange={(e) =>
                                  handleSelectSingleid(e, index, row.id)
                                }
                                disabled={row?.status !== "active"}
                                //   inputProps={{ "aria-label": "controlled" }}
                              />
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ cursor: "pointer" }}>
                              {row?.id}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ cursor: "pointer" }}
                              align="left"
                            >
                              {row?.firstName !== null ? row?.firstName : "--"}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ cursor: "pointer" }}
                              align="left"
                            >
                              {row?.lastName !== null ? row?.lastName : "--"}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="left" width="120px">
                              {row?.email}
                            </CustomizedTableCell>

                            <CustomizedTableCell align="left" width="200px">
                              {row?.CompanyUserRoles?.length > 0 &&
                              row?.CompanyUserRoles[0]?.isPrimary ? (
                                <Item className={classes.primaryChip}>
                                  <Typography
                                    className={classes.primaryChipTypo}
                                  >
                                    {t("Primary_text")}
                                  </Typography>
                                </Item>
                              ) : row?.CompanyUserRoles?.length > 0 ? (
                                (
                                  row?.CompanyUserRoles[0]?.name
                                    .charAt(0)
                                    .toUpperCase() +
                                  row?.CompanyUserRoles[0]?.name.slice(1)
                                ).replaceAll("-", " ")
                              ) : (
                                "--"
                              )}
                            </CustomizedTableCell>

                            <CustomizedTableCell
                              padding="none"
                              align="left"
                              width="200px"
                            >
                              {row?.company !== null ? row?.company.name : "--"}
                              {/* <Checkbox
                                color="primary"
                                // indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={selectAll}
                                onChange={(e) => handleSelectAllId(e)}
                                inputProps={{
                                  "aria-label": "select all desserts",
                                }}
                              />
                              <CustomWidthTooltip title={"Link User"}>
                                <IconButton
                                  aria-label="edit"
                                  size="large"
                                  onClick={() => {
                                    handleOpenModel();
                                    setSelectedRow(row);

                                  }}
                                ></IconButton>
                              </CustomWidthTooltip>

                              <IconButton
                                onClick={(e) => handleClick(e, row.id, row)}
                              >
                                <MoreVertIcon />
                              </IconButton>

                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    handleOpenDeleteModel(true);
                                    closeMenu();
                                    handleClose();
                                    setDeleteId(selectedRow);
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography>{t("Delete")}</Typography>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem
                                  onClick={() => {

                                    closeMenu();
                                    handleClose();
                                    setOpenPrimaryModel(true);
                                    updateUserHandler(selectedRow.id);
                                    // handleOpenModal(row?.id);
                                  }}
                                >
                                  <PersonIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography>
                                    {t("Make_Primary_User")}
                                  </Typography>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem
                                  onClick={() => {

                                    closeMenu();
                                    handleClose();
                                    setOpenResetModel(true);
                                  }}
                                >
                                  <PasswordIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography
                                    loading={loading}
                                    type="submit"
                                    fullWidth
                                    variant="contained"

                                    // disabled={isSubmitting}
                                  >
                                    {t("Reset_password")}
                                  </Typography>
                                </MenuItem>
                              </Menu>*/}
                            </CustomizedTableCell>
                            <CustomizedTableCell>
                              {(otpLoading || deleteLoading) &&
                              selectedRow?.id === row?.id ? (
                                <CircularProgress
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginLeft: "8px",
                                  }}
                                />
                              ) : (
                                <IconButton
                                  onClick={(e) => handleClick(e, row.id, row)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              )}

                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                <MenuItem
                                  onClick={() => {
                                    closeMenu();
                                    handleClose();
                                    handleGenerateOTP();
                                  }}
                                >
                                  <PersonIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography>{t("one_time_login")}</Typography>
                                </MenuItem>
                                <Divider />
                                <MenuItem
                                  onClick={() => {
                                    setDeleteId(selectedRow);
                                    handleOpenDeleteModel(
                                      true,
                                      selectedRow?.id
                                    );
                                    closeMenu();
                                    handleClose();
                                    // setDeleteId(selectedRow?.id);
                                  }}
                                >
                                  <DeleteIcon
                                    sx={{
                                      color: "#545353",
                                      paddingRight: "5px",
                                    }}
                                  />
                                  <Typography>{t("Delete")}</Typography>
                                </MenuItem>
                                {selectedRow?.CompanyUserRoles?.length > 0 &&
                                selectedRow?.CompanyUserRoles[0]?.isPrimary ? (
                                  <>
                                    <Divider variant="middle" />
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenChangeOwnershipModel(
                                          "ownership"
                                        );
                                        handleClose();
                                      }}
                                    >
                                      <TransferOwnership
                                        style={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                      <Typography>
                                        {t("change_ownership")}
                                      </Typography>
                                    </MenuItem>
                                  </>
                                ) : (
                                  selectedRow?.status !== "pending" && (
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenMakePrimaryUserModel(
                                          "primary"
                                        );
                                        handleClose();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <MakePrimaryUser
                                        style={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                          width: "22px",
                                          height: "22px",
                                        }}
                                      />
                                      <Typography>
                                        {t("Make_Primary_User")}
                                      </Typography>
                                    </MenuItem>
                                  )
                                )}
                              </Menu>
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound text={t("User_Not_Found")} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Users;
