import * as React from "react";
import { styled } from "@mui/system";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  tooltipClasses,
  IconButton,
  Box,
  Checkbox,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "8px",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

/* const AccordionSummary = styled((props) => {
  return (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  );
})((props) => {
  const { theme } = props;

  return {
    // backgroundColor: alpha(theme.palette.primary.main, 0.7),
    backgroundColor: theme.palette.mode === "dark" ? "#fff" : "#fff",
    //  border: "1px solid #E0E0E0",
    borderRadius: "8px",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  };
}); */

/* const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
})); */

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const SmallCheckbox = styled(Checkbox)({
  // padding: "0px 12px 0px 0px",
  width: "12px",
  height: "12px",
  "& .MuiSvgIcon-root": {
    fontSize: "16px", // Adjust the size of the icon inside the checkbox
  },
});

export default function CollapsibleForm({
  title,
  ComponentToRender,
  CustomTitle,
  id,
  handleChange,
  expanded,
  fontAlignment,
  required,
  displayInfoIcon,
  countryNameStyling,
  displaySelectAll,
  modellocations,
  handleChangeAllCountryLocations,
  handleCheckForAllLocations,
  itemData,
  displayGroupSelectAll,
  handleSelectAllGroups,
  selectedGroupsModel,
  selectAllGroups,
  countriesArr,
  expandedPanels,
  setExpandedPanels,
  defaultPreventions,
  modelCountries,
  infoText,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/+$/, "");

  useEffect(() => {
    if (
      currentPath === "/user/sentimentAnalysis" ||
      currentPath === "/user/google-stats" ||
      currentPath === "/user/google-stats/" ||
      currentPath === "/user/locations" ||
      currentPath === "/"
    ) {
      if (
        (currentPath === "/user/google-stats" ||
          currentPath === "/user/google-stats/" ||
          currentPath === "/user/locations" ||
          currentPath === "/") &&
        id === "countries"
      ) {
        if (
          modelCountries !== null &&
          modelCountries !== undefined &&
          modelCountries?.length > 0
        ) {
          if (!expandedPanels.includes(id)) {
            expandedPanels.push(id);
          }
        } else {
          const index = expandedPanels?.indexOf(id);
          if (index > -1) {
            expandedPanels.splice(index, 1);
          }
        }
      }

      const result = handleDisplayDot(countriesArr, modellocations);

      if (id !== "countries") {
        if (result) {
          if (!expandedPanels.includes(id)) {
            expandedPanels.push(id);
          }
        } else {
          const index = expandedPanels?.indexOf(id);
          if (index > -1) {
            expandedPanels.splice(index, 1);
          }
        }
      }

      const uniqueData = [...new Set(expandedPanels)];
      if (setExpandedPanels !== undefined) {
        setExpandedPanels(uniqueData);
      }
    }
  }, [modellocations, countriesArr, modelCountries]);

  function handleDisplayDot(arr1, arr2) {
    if (arr1 !== undefined && arr2?.length > 0) {
      let name = arr1?.countryName;
      let countriesLocations = arr1?.locationsArray;
      const ids1 = new Set(countriesLocations.map((obj) => obj.id));

      for (let obj of arr2) {
        if (ids1.has(obj.id)) {
          return true;
        }
      }
    }

    return false;
  }

  return (
    <div>
      <Accordion
        expanded={expanded}
        onChange={(e, newExpanded) => handleChange(e, newExpanded, id)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id={id}
        >
          {CustomTitle ? (
            CustomTitle
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {countryNameStyling && (
                  <Box
                    sx={{
                      width: 6,
                      height: 6,
                      borderRadius: "50%",
                      backgroundColor: "#06BDFF",
                      marginTop: "8px",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: fontAlignment ? 400 : 700,
                    color: "#1B2430",
                    marginLeft: countryNameStyling ? "5px" : "0px",
                    textTransform: "capitalize",
                    //  paddingLeft: "4px",
                  }}
                >
                  {title}
                  {required ? (
                    <span style={{ color: "#FC3652", marginLeft: "4px" }}>
                      *
                    </span>
                  ) : (
                    ""
                  )}
                </Typography>
                {displayInfoIcon && (
                  <BootstrapTooltip
                    title={infoText ? infoText : t("pronoun_tooltipText")}
                  >
                    <IconButton
                      sx={{
                        marginLeft: "5px",
                        cursor: "pointer",
                        padding: "0px",
                      }}
                    >
                      <InfoOutlinedIcon
                        sx={{ color: "#1B2430", width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </BootstrapTooltip>
                )}
              </Box>

              {displaySelectAll && (
                <Box
                  sx={{
                    textAlign: "end",
                    marginTop: "2px",
                    marginRight: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SmallCheckbox
                    name="selectAll"
                    color="primary"
                    checked={handleCheckForAllLocations(
                      itemData?.locationsArray,
                      modellocations,
                      itemData?.countryCode
                    )}
                    onClick={(e) =>
                      handleChangeAllCountryLocations(
                        e,
                        itemData?.locationsArray,
                        itemData?.countryCode
                      )
                    }
                    sx={{ marginRight: "4px" }}
                    // disabled={defaultRole}
                    /*  checked={
                    arr?.permissions?.length ===
                    feature?.AppFeaturePermissions?.length
                  } */
                  />
                  {t("Select_All")}
                </Box>
              )}
              {displayGroupSelectAll && (
                <Box
                  sx={{
                    textAlign: "end",
                    marginTop: "2px",
                    marginRight: "4px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SmallCheckbox
                    name="selectAll"
                    color="primary"
                    checked={selectAllGroups}
                    onClick={(e) => {
                      if (defaultPreventions) {
                        e.stopPropagation();
                      }
                      handleSelectAllGroups(e);
                    }}
                  />
                  {t("Select_All")}
                </Box>
              )}
            </Box>
          )}
        </AccordionSummary>
        <AccordionDetails>{ComponentToRender}</AccordionDetails>
      </Accordion>
    </div>
  );
}
