import React, { useState } from "react";
import styled from "styled-components/macro";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import Delete from "../Models/DeleteModal/Delete";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Grid,
  Typography,
} from "@mui/material";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 30px;
    height: 30px;
  }
`;

function NavbarUserDropdown() {
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { signOut, userRole, user } = useAuth();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { permissions } = useAuth();
  const userManagementFound = permissions?.find(
    (item) => item?.featureName === "User Management"
  );

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleCloseLogoutModel = () => {
    setOpen(false);
  };
  const handleOpenLogoutModel = () => {
    setOpen(true);
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const handleProfile = async () => {
    userRole === "company-admin"
      ? navigate("/user/profile")
      : navigate("/admin/profile");
  };
  const handleAccountOverview = () => {
    if (user?.role === "super-admin") {
      navigate("/");
    } else {
      navigate("/user/accounts-overview");
    }
  };
  const handleBillingPage = () => {
    window.open =
      ("https://checkout.obenan.com/p/login/6oE7v7fYCcDufFS5kks", "_blank");
  };
  const handleSwitchAccounts = () => {
    navigate("/user/accounts");
  };

  return (
    <React.Fragment>
      <Tooltip title={t("Account")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : ""}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Grid
            // className="firstName"
            style={{
              fontWeight: "300",
              fontSize: "10px",
              color: "white",
              width: "35px",
              height: "35px",
              padding: "6px",
              borderRadius: "100%",
              backgroundColor: "#06BDFF",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Typography style={{ fontSize: "16px" }}>
              {user?.firstName !== null &&
              user?.firstName !== "" &&
              user?.lastName !== null &&
              user?.lastName !== ""
                ? `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`
                : ""}
            </Typography>
          </Grid>
          {/* <Power /> */}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {/* <MenuItem onClick={handleProfile}>Profile</MenuItem> */}

        {user?.role === "super-admin" && (
          <MenuItem
            onClick={() => {
              closeMenu();
              handleAccountOverview();
            }}
          >
            {t("Account_Overview")}
          </MenuItem>
        )}
        {user?.CompanyUsers?.length > 1 && (
          <MenuItem
            onClick={() => {
              closeMenu();
              handleSwitchAccounts();
            }}
          >
            {t("Switch_Account")}
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            closeMenu();
          }}
        >
          <a
            style={{ textDecoration: "none", color: "black" }}
            href="https://checkout.obenan.com/p/login/6oE7v7fYCcDufFS5kk"
            target="_blank"
            rel="noreferrer"
          >
            {t("Billing_Details")}
          </a>
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            handleOpenLogoutModel();
          }}
        >
          {t("Sign_out")}
        </MenuItem>
        {/* <MenuItem onClick={handleSignOut}>{t("Sign_out")}</MenuItem> */}
      </Menu>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseLogoutModel}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Sign_out")}
          description={t("logout_message")}
          onConfirm={() => handleSignOut()}
          onCancel={handleCloseLogoutModel}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
