import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { languagesData } from "../../../../assets/defaultLanguage";
import { api } from "../../../../contexts/JWTContext";
import ShowTemplates from "./createTemplateCard";
import TitleHeader from "../../../../components/CustomComponents/title/index";
import Loader from "../../../../components/Loaders/Loader";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { useMediaQuery } from "@mui/material";
import {
  Box,
  Grid,
  Paper,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Divider as MuiDivider,
  IconButton,
  Dialog,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import useAuth from "../../../../hooks/useAuth";
import { useTheme } from "@mui/material/styles";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles/style";

const Divider = styled(MuiDivider)(spacing);
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const CreateTemplate = () => {
  const navigate = useNavigate();
  const { permissionsAcess } = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataArray, setDataArray] = useState([{ text: "", language: "en" }]);
  const [title, setTile] = useState("");
  const [checkRequired, setCheckRequired] = useState(false);
  const [editData, setEditData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [titleRequired, setTitleRequired] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState([]);
  const [languagesDataArray, setLanguagesDataArray] = useState([]);
  const [viewPermission, setViewPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.forEach((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          //  setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          //  setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);
  useEffect(() => {
    getAllDefaultTemplate();
    // deleteTemplates();
  }, []);
  useEffect(() => {
    setLanguagesDataArray(languagesData);
  }, []);
  useEffect(() => {
    const results = languagesData?.filter(
      ({ value: id1 }) => !dataArray.some(({ language: id2 }) => id2 === id1)
    );
    setLanguagesDataArray(results);
  }, [dataArray]);

  // ** Handler Functions ================================================================
  // ** Helper Function to remove th last word of on curly brace is removed ============================
  function removeLastWord(str) {
    const lastIndexOfSpace = str.lastIndexOf(" ");

    if (lastIndexOfSpace === -1) {
      return str;
    }

    return str.substring(0, lastIndexOfSpace);
  }
  const getAllDefaultTemplate = async () => {
    try {
      const res = await api.get(`/template/default`);
      if (res.status === 200) {
        setDefaultTemplate(res?.data?.data);
      }
    } catch (error) {}
  };

  // ** Change handler for text language and personalize based on index
  const changeHandler = (e, index, isLanguage, isReviewer, currentRef) => {
    let tempArray = [...dataArray];
    let tempData = { ...dataArray[index] };

    // Checking language and converting to code ============================

    let tempValueForDelete =
      isLanguage !== true ? e?.target?.value : e !== undefined ? e.value : "";

    if (tempValueForDelete !== null && tempValueForDelete !== undefined) {
      if (isLanguage === true) {
        tempData["language"] = tempValueForDelete;
      } else {
        if (isReviewer === true) {
          let textBeforeCursorPosition = tempData["text"].substring(
            0,
            currentRef.current.selectionStart
          );
          let textAfterCursorPosition = tempData["text"].substring(
            currentRef.current.selectionStart,
            tempData["text"].length
          );

          tempData["text"] =
            textBeforeCursorPosition +
            " " +
            tempValueForDelete +
            " " +
            textAfterCursorPosition;
          currentRef.current.selectionStart =
            currentRef.current.selectionStart + tempValueForDelete.length + 2;
          // tempData["text"] = `${tempData["text"]} ${value}`;
        } else {
          if (tempValueForDelete && tempValueForDelete?.slice(-1) === "}") {
            tempData["text"] = removeLastWord(tempValueForDelete);
          } else {
            tempData["text"] = tempValueForDelete;
          }
        }
      }

      tempArray.splice(index, 1, tempData);
      setDataArray(tempArray);
    }
  };

  //** Delete Handler ============================
  const deleteTemplate = (index) => {
    //
    // let tempArray = [...dataArray];
    /*   tempArray.splice(index, 1); // 2nd parameter means remove one item only
     */
    const filtered = dataArray?.filter((data, indx) => {
      return indx !== index;
    });
    //

    setDataArray(filtered);
  };

  //** Add Handler ============================
  const handleAdd = () => {
    const data = { text: "", language: "" };
    setDataArray([...dataArray, data]);
  };

  //** Save all templates to database ============================
  const saveTemplateHandler = async () => {
    let tempRequiredCount = 0;

    dataArray.forEach((item) => {
      if (item.text.length < 1 || item.language.length < 1) {
        tempRequiredCount++;
      }
    });

    // CHecking for one english template ============================
    const result = dataArray.filter((item) => item.language === "en");
    if (result.length <= 0) {
      toast.warn(t("Language_selection_msg"));
      return;
    }

    // CHecking if all the required fields are filled call api ============================
    if (tempRequiredCount === 0 && result.length > 0) {
      if (title?.length < 1) {
        setTitleRequired(true);
      } else {
        setIsLoading(true);

        const data = {
          title: title,
          data: dataArray,
        };

        try {
          const res = await api.post(`/template`, data);
          if (res.status === 200) {
            navigate("/user/template");
            toast.success("Templates added successfuly");
            setIsLoading(false);
          }
        } catch (error) {
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      }
    } else {
      setCheckRequired(!checkRequired);
    }
  };
  const handleOpenTranslationMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTranslationMenu = () => {
    setOpenDefaultModel(false);
  };
  const translateDescription = async () => {
    let reqObject = {};

    // CHecking for one english template ============================
    const result = dataArray.filter((item) => item.language === "en");
    if (result.length <= 0 || result[0]?.text?.length === 0) {
      toast.warn(t("English_description_msg"));
      setIsLoading(false);
      setOpenDefaultModel(false);
      return;
    } else {
      reqObject["text"] = result[0]?.text;
      setIsLoading(false);
      setOpenDefaultModel(false);
    }
    setIsLoading(true);

    reqObject["language"] = "en";

    reqObject["languageArray"] = [];
    languagesData.forEach((item) => {
      const result = dataArray.filter(
        (filterItem) => filterItem.language === item?.value
      );

      if (result?.length === 0) {
        reqObject["languageArray"].push(item?.value);
      }
    });

    // CHecking if all the required fields are filled call api ============================

    try {
      const res = await api.patch(
        `/template/translateInAllLanguges`,
        reqObject
      );
      if (res.status === 200) {
        setDataArray(dataArray.concat(res?.data?.data));
        // let tempArray = [...dataArray, res?.data];
        toast.success("Translated successfully");

        setIsLoading(false);
        setOpenDefaultModel(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
      setOpenDefaultModel(false);
    }
  };

  return (
    <>
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTranslationMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Translate_descriptions")}
            description={t("Translation_modal")}
            onConfirm={translateDescription}
            onCancel={handleCloseTranslationMenu}
          />
        </Dialog>
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            title={t("Create_Template_head")}
            subHeading={t("sub_Update_Template")}
            showButton={true}
            isLoading={isLoading}
            createButton={true}
            name={t("Translate_lang_desc")}
            onClick={handleOpenTranslationMenu}
            viewPermission={viewPermission}
            addPermission={addPermission}
            // editData={editData}
          />

          <Paper style={{ height: "100%", boxShadow: "none", padding: "6px" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} lg={4} md={4}>
                <Box sx={{ ml: isMobile ? 0 : 2 }}>
                  {editData ? (
                    <>
                      <Grid sx={{ display: "flex", alignItems: "center" }}>
                        <Typography className={classes.titleHead}>
                          {" "}
                          {title}
                        </Typography>
                        <CustomWidthTooltip title={t("Edit_Title")}>
                          <IconButton
                            aria-label="edit"
                            sx={{ fontSize: "14px" }}
                            onClick={() => {
                              setEditData(!editData);
                            }}
                          >
                            {" "}
                            <Edit />
                          </IconButton>
                        </CustomWidthTooltip>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Box sx={{ ml: 2 }}>
                        <Typography className={classes.titleHead}>
                          {" "}
                          {t("Title")}{" "}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                          <TextField
                            id="standard-basic"
                            // label="Standard"
                            rows={1}
                            maxRows={10}
                            style={{
                              // border: "1px solid #E0E0E0",
                              fontFamily: "Segoe UI",
                              fontStyle: "Normal",
                              //  padding: "12px 12px 12px 12px",
                              width: "100%",

                              borderRadius: "8px",
                              //  minHeight: "30px",
                              color: "#1B2430",
                              fontWeight: "400",
                              fontSize: "16px",
                              //   padding: "15px",
                            }}
                            label={null}
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                setEditData(false);
                              }
                            }}
                            value={title}
                            error={titleRequired}
                            onChange={(event) => {
                              if (event.target.value.length === 0) {
                                setTitleRequired(true);
                              } else {
                                setTitleRequired(false);
                              }

                              setTile(event.target.value);
                            }}
                            multiline
                            fullWidth
                            /*   InputProps={{
                    disableUnderline: true,
                  }} */
                            inputProps={{ maxLength: 1000 }}
                            /*   InputProps={{
            inputComponent: TextareaAutosize,
          }} */
                          />
                          {/*  <TextareaAutosize
                            id="filled-multiline-static"
                            multiline
                            style={{
                              border: "1px solid #E0E0E0",
                              fontFamily: "Segoe UI",
                              fontStyle: "Normal",
                              padding: "12px 12px 12px 12px",
                              width: "100%",

                              borderRadius: "8px",
                              minHeight: "30px",
                              color: "#1B2430",
                              fontWeight: "400",
                              fontSize: "16px",
                              //   padding: "15px",
                            }}
                            error={titleRequired}
                            disabled={isLoading}
                            required
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                setEditData(false);
                              }
                            }}
                            value={title}
                            onChange={(event) => {
                              setTitleRequired(false);
                              setTile(event.target.value);
                            }}
                            //fullWidth
                            variant="outlined"
                          /> */}
                          <IconButton
                            aria-label="done"
                            size="large"
                            onClick={() => {
                              setEditData(!editData);
                            }}
                            disabled={!title}
                          >
                            {" "}
                            <CheckIcon />
                          </IconButton>
                        </Box>
                        {titleRequired && (
                          <Typography className={classes.titleRequired}>
                            {t("title_req")}
                          </Typography>
                        )}
                      </Box>
                    </>
                  )}

                  {/* <CustomWidthTooltip title="Edit Title">
                      <IconButton
                        aria-label="edit"
                        size="large"
                        onClick={() => {
                          setEditData(true);
                        }}
                      >
                        {" "}
                        <Edit />
                      </IconButton>
                    </CustomWidthTooltip> */}
                </Box>
              </Grid>

              <Grid item xs={12} sm={12} lg={8} md={8}>
                <Grid container>
                  {dataArray?.map((item, index) => {
                    return (
                      <>
                        <ShowTemplates
                          item={item}
                          index={index}
                          changeHandler={changeHandler}
                          deleteTemplate={deleteTemplate}
                          checkRequired={checkRequired}
                          defaultTemplate={defaultTemplate}
                          dataArray={dataArray}
                          languagesDataArray={languagesDataArray}

                          // languageRequired={languageRequired}
                        />
                      </>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={6} lg={6}></Grid>
                <Grid item md={0.6} lg={0.6}></Grid>
                <Grid item xs={12} sm={12} md={5.4} lg={5.4}>
                  <IconButton
                    onClick={handleAdd}
                    // loading={loading}
                    displayWhite="true"
                  >
                    <AddIcon sx={{ color: "#0638C1", fontWeight: "700" }} />{" "}
                    <Typography className={classes.moreDescription}>
                      {" "}
                      {t("Add_More_Description")}{" "}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Divider my={4} />

              {/*  <Grid spacing={2} container>
                <Grid item md={3}></Grid>
                <Grid item md={2.3}></Grid>
              </Grid> */}
            </Grid>

            <Grid container sx={{ justifyContent: "flex-end" }}>
              <CommonButton
                displayWhite="true"
                // onSubmit={onCancel}
                onSubmit={() => {
                  navigate(-1);
                }}
                label={t("Cancel")}
              />

              <CommonButton
                label={t("Save_Template")}
                onSubmit={saveTemplateHandler}
                loading={isLoading}
              >
                {t("Save")}
              </CommonButton>
            </Grid>

            {/*   <Box className="secondary_box"></Box> */}
          </Paper>
        </>
      )}
    </>
  );
};

export default CreateTemplate;
