import React, { useEffect, useState } from "react";
import {
  Grid,
  Autocomplete,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { languagesData } from "../../../../assets/defaultLanguage";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../components/Loaders/Loader";
import moment from "moment";
import { api } from "../../../../contexts/JWTContext";
import { useTranslation } from "react-i18next";

const FetchModel = ({
  title,
  open,
  handleCloseNewRequestModel,
  reportsId,

  setOpenRequest,
  openRequest,
  setSelectLanguageFilter,
  openSubmit,
  setOpenSubmit,
  selectedReport,
  setSelectedReport,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [companyReports, setCompanyReports] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [companyRequired, setCompanyRequired] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState("en");

  useEffect(() => {
    getAllReports();
  }, [openRequest]);

  //  useEffect(() => {
  //    if (selectLanguageFilter !== "") {
  //      getAllReports();
  //    }
  //  }, [selectLanguageFilter]);

  const getExportReports = async () => {
    if (selectedReport === null) {
      setCompanyRequired(true);
    } else {
      setCompanyRequired(false);
    }
    if (selectedReport !== null) {
      try {
        setLoading(true);
        const res = await api.patch(
          `company/${reportsId}/reports?month=${selectedReport?.month}&year=${selectedReport?.year}&language=${selectLanguage}&sendReport=true`
        );

        if (res.status === 200) {
          setLoading(false);
          // setSelectLanguageFilter(selectLanguage);
          setOpenSubmit(true);
          setOpenRequest(false);
          // getAllReports();
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const getAllReports = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`company/${reportsId}/reports?language=`);

      if (res.status === 200) {
        setCompanyReports(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          onClose={handleCloseNewRequestModel}
        >
          <DialogTitle
            sx={{
              lineHeight: "28px",
              fontWeight: 700,
              fontSize: "20px",
              color: "#1b2430",
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid
                container
                sx={{
                  overflowY: "scroll",
                }}
              >
                <Grid item sm={12} md={12} lg={12} mb={3}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                      marginBottom: "6px",
                    }}
                  >
                    {t("choose_lang")}
                  </Typography>
                  <Select
                    required
                    defaultValue={"en"}
                    style={{ width: "100%" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={languagesData?.language}
                    // label="Select"
                    onChange={(e) => {
                      setSelectLanguage(e.target.value);
                    }}
                  >
                    {languagesData.map((item) => (
                      <MenuItem value={item.value}>{item.language}</MenuItem>
                    ))}
                  </Select>
                </Grid>

                <Grid item sm={12} md={12} lg={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                      marginBottom: "6px",
                    }}
                  >
                    {t("select_report")}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    // defaultValue={}

                    value={selectedReport}
                    id="combo-box-demo"
                    options={
                      companyReports?.results?.length > 0
                        ? companyReports?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      moment(option?.month).format("MMM") + " " + option?.year
                    }
                    sx={{ width: "100%" }}
                    // value={companyId}
                    onChange={(e, value) => {
                      setSelectedReport(value);
                      if (value === null) {
                        setCompanyRequired(true);
                      } else setCompanyRequired(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        // label={t("TaskCompany")}
                        name="language"
                        error={companyRequired}
                        placeholder={t("select_report")}
                        helperText={companyRequired && t("select_reportss")}
                      />
                    )}
                  ></Autocomplete>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions>
            {/* <Button
              autoFocus
              onClick={() => {
                handleCloseNewRequestModel();
              }}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button> */}

            <CommonButton
              displayWhite="true"
              onSubmit={() => {
                handleCloseNewRequestModel();
              }}
              label={t("Cancel")}
            />

            {/* <Button
              autoFocus
              onClick={() => getExportReports()}
              className="delete_button"
            >
              {loading ? (
                <CircularProgress
                  color="secondary"
                  style={{ width: "25px", height: "25px", color: "#06BDFF" }}
                />
              ) : (
                t("Submit")
              )}
            </Button> */}

            <CommonButton
              isLoading={loading}
              type="submit"
              label={t("export")}
              onSubmit={() => getExportReports()}
              disabled={selectedReport === null}
            />
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
