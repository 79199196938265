import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Zoom,
  Stack,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import PreviewImageModal from "./PreviewImageModal/PreviewImageModal";

import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import Delete from "../../../../../components/Models/DeleteModal/Delete";
import PictureModalCard from "./PictureModalCard";
import Compressor from "compressorjs";

const useStyles = makeStyles((theme) => ({
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#cbd5e1",
    backgroundColor: "#f8fafc",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ProfilePictureModal = ({
  locationId,
  open,
  handleClose,
  setPostData,
  handleCloseNavigate,
  postData,
  remove,
  bulkPostImage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputFileRef = React.useRef();
  const [dragActive, setDragActive] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openPreviewImageModal, setOpenPreviewImageModal] = useState(false);
  const [indexValueArr, setIndexValueArr] = useState(0);
  const [displayButtonGrid, setDisplayButtonGrid] = useState(false);
  const [openOptimizeImageModel, setOpenOptimizeImageModel] = useState(false);
  const [newIndexValue, setNewIndexValue] = useState(null);
  const [resizeLoading, setResizeLoading] = useState(false);

  useEffect(() => {
    if (remove === true) {
      setSelectedImages([]);
      setIsUploading(false);
    }
  }, [remove]);

  const handleDelete = (index) => {
    if (selectedImages?.length === 1) {
      handleClosePreviewImage();
    }
    const deleteImage = selectedImages?.filter((item, indx) => indx !== index);

    const findDeleted = selectedImages?.find((item, indx) => indx === index);
    if (findDeleted) {
      const filterDelete = postData?.filter(
        (item) => item["postPictures"][0]["key"] !== findDeleted.key
      );
      setPostData(filterDelete);
    }

    setSelectedImages([...deleteImage]);

    // Check if there are no more selected images after deletion and update isUploading state accordingly
    if (deleteImage.length >= 0) {
      setIsUploading(false);
    }
  };
  const handleOpenPreviewImage = (images, displayGrid, value) => {
    setOpenPreviewImageModal(true);
    setDisplayButtonGrid(displayGrid);
    if (value !== undefined) {
      setIndexValueArr(value);
    } else {
      setIndexValueArr(null);
    }
  };

  const handleClosePreviewImage = () => {
    setOpenPreviewImageModal(false);
  };

  const handleImageError = (index, key) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];

    if (key !== undefined && key === "uberAll") {
      temmpObj["errorUberAll"] = true;
      temmpArray[index] = temmpObj;
    } else {
      temmpObj["error"] = true;
      temmpArray[index] = temmpObj;
    }
    setSelectedImages(temmpArray);
  };
  const handleClickCloseWarning = (index) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];
    temmpObj["errorUberAll"] = false;
    temmpArray[index] = temmpObj;
    setSelectedImages(temmpArray);
  };
  const afterUploadSelectedChange = (indexNum, keyNum) => {
    const tempArray = [...selectedImages];
    const tempObj = selectedImages[indexNum];
    tempObj["key"] = keyNum;
    tempArray.splice(indexNum, 1, tempObj);
    setSelectedImages(tempArray);
  };
  const onFileChangeCapture = (e) => {
    let tempImages = [];

    for (const [key, value] of Object.entries(e?.target?.files)) {
      new Compressor(value, {
        quality: 0.8,
        success: (compressedResult) => {
          tempImages.push({
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
          });

          // Sort the images based on your criteria
          tempImages.sort(function (a, b) {
            const nameA = a.picture.name.toLowerCase();
            const nameB = b.picture.name.toLowerCase();

            // Function to check if a string starts with a number
            const startsWithNumber = (str) => /^\d/.test(str);

            if (startsWithNumber(nameA) && startsWithNumber(nameB)) {
              // Both names start with numbers, so compare them numerically
              return parseInt(nameA) - parseInt(nameB);
            } else if (!startsWithNumber(nameA) && !startsWithNumber(nameB)) {
              // Both names start with alphabets, so compare them lexicographically
              return nameA.localeCompare(nameB);
            } else if (startsWithNumber(nameA)) {
              return -1; // Image with a name starting with a number comes first
            } else {
              return 1; // Image with a name starting with an alphabet comes second
            }
          });

          setSelectedImages([...tempImages]);
        },
      });
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let tempImages = [];

    for (const [value] of Object.entries(e?.dataTransfer?.files)) {
      new Compressor(value, {
        quality: 0.8,
        success: (compressedResult) => {
          tempImages.push({
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
          });

          // Sort the images based on your criteria
          tempImages.sort(function (a, b) {
            var nameA = a.picture.name.toLowerCase();
            var nameB = b.picture.name.toLowerCase();

            // Function to check if a string starts with a number
            const startsWithNumber = (str) => /^\d/.test(str);

            if (startsWithNumber(nameA) && startsWithNumber(nameB)) {
              // Both names start with numbers, so compare them numerically
              return parseInt(nameA) - parseInt(nameB);
            } else if (!startsWithNumber(nameA) && !startsWithNumber(nameB)) {
              // Both names start with alphabets, so compare them lexicographically
              return nameA.localeCompare(nameB);
            } else if (startsWithNumber(nameA)) {
              return -1; // Image with a name starting with a number comes first
            } else {
              return 1; // Image with a name starting with an alphabet comes second
            }
          });

          setSelectedImages([...tempImages]);
        },
      });
    }
  };
  const handleOptimize = () => {
    setResizeLoading(true);

    // if (newIndexValue >= 0 && newIndexValue < selectedImages.length) {
    const fileToOptimize = selectedImages[newIndexValue];
    const tempImages = [...selectedImages];
    new Compressor(fileToOptimize?.picture, {
      quality: 0.8,
      maxWidth: 2900,
      maxHeight: 2900,
      success: (compressedResult) => {
        tempImages[newIndexValue] = {
          picture: compressedResult,
          picturePreview: URL.createObjectURL(compressedResult),
        };
        setSelectedImages(tempImages); // Update state after compression
        setResizeLoading(false);
        handleCloseOptimizeImageModel();
      },
      error: (err) => {
        setResizeLoading(false);
        handleCloseOptimizeImageModel();
      },
    });
    // }
  };

  const handleOpenOptimizeImageModel = (indValue) => {
    setNewIndexValue(indValue);
    setOpenOptimizeImageModel(true);
  };
  const handleCloseOptimizeImageModel = () => {
    setOpenOptimizeImageModel(false);
  };

  return (
    <>
      {openOptimizeImageModel && (
        <Dialog
          open={openOptimizeImageModel}
          onClose={handleCloseOptimizeImageModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("edit_image_tooltipText")}
            description={t("edit_image_tooltipText_subHead")}
            onConfirm={handleOptimize}
            loading={resizeLoading}
            onCancel={handleCloseOptimizeImageModel}
          />
        </Dialog>
      )}
      {openPreviewImageModal && (
        <PreviewImageModal
          handleClickOpen={handleOpenPreviewImage}
          open={openPreviewImageModal}
          handleClose={handleClosePreviewImage}
          selectedImages={selectedImages}
          handleDeleteImage={handleDelete}
          title={"Image Preview"}
          displayButtonGrid={displayButtonGrid}
          indexOfItem={indexValueArr}
          handleDeleteImagePreview={handleDelete}
        />
      )}
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleCloseNavigate}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {selectedImages?.length > 0 ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogTitle
                sx={{
                  color: "#1B2430",
                  fontSize: "20px",
                  marginBottom: "20px",
                  fontWeight: "700",
                }}
                id="alert-dialog-slide-title"
              >
                {t("Uploading_Images")}
              </DialogTitle>
              <IconButton
                onClick={() => {
                  handleCloseNavigate();
                  setSelectedImages([]);
                  setIsUploading(false);
                }}
                aria-label="save"
                hover="none"
                sx={{
                  hover: "none",
                  marginBottom: "15px",
                  marginRight: "22px",
                }}
              >
                X
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack direction="column">
                <Item>
                  {selectedImages && (
                    <>
                      {selectedImages?.map((img, index) => {
                        return (
                          <PictureModalCard
                            img={img}
                            handleDelete={handleDelete}
                            locationId={locationId}
                            setPostData={setPostData}
                            handleClose={handleClose}
                            index={index}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            postData={postData}
                            afterUploadSelectedChange={(key) =>
                              afterUploadSelectedChange(index, key)
                            }
                            handleImageError={handleImageError}
                            handleClickCloseWarning={handleClickCloseWarning}
                            handleOpenPreviewImage={handleOpenPreviewImage}
                            selectedImages={selectedImages}
                            setSelectedImages={setSelectedImages}
                            // handleOptimize={handleOptimize}
                            handleOpenOptimizeImageModel={
                              handleOpenOptimizeImageModel
                            }
                          />
                        );
                      })}
                    </>
                  )}
                </Item>

                <Divider />
                <Item
                  sx={{
                    paddingTop: "24px",
                    display: "flex",
                    justifyContent: "flex-end",
                    flex: 1,
                  }}
                >
                  {selectedImages?.length > 0 && (
                    <>
                      <Button
                        sx={{
                          fontWeight: "700",
                          size: "large",
                          color: "#1B2430",
                          borderRadius: "8px",
                          padding: " 14px 20px",
                          border: "1px solid #E0E0E0",
                          hover: "none",
                        }}
                        variant="outlined"
                        // color="#1B2430"

                        onClick={() => {
                          handleCloseNavigate();
                          setSelectedImages([]);
                          setIsUploading(false);
                        }}
                      >
                        {t("Cancel")}
                      </Button>
                      &nbsp; &nbsp;
                      <CommonButton
                        disabled={
                          selectedImages.find((item) => item?.error === true)
                            ? true
                            : postData?.length === selectedImages?.length ||
                              !isUploading
                            ? false
                            : true
                        }
                        onSubmit={() => {
                          if (postData?.length === selectedImages?.length) {
                            handleClose();
                          } else {
                            setIsUploading(true);
                          }
                        }}
                        label={
                          postData?.length === selectedImages?.length
                            ? t("Submit")
                            : t("Upload")
                        }
                      />
                    </>
                  )}
                </Item>
              </Stack>
            </DialogContent>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                sx={{
                  fontWeight: "700",
                  color: "#1B2430",
                  fontSize: "20px",
                  letterSpacing: "0.5px",
                  lineHeight: "28px",
                }}
              >
                {t("Uploading_Images")}
              </DialogTitle>
              <IconButton
                onClick={() => {
                  handleCloseNavigate();
                }}
                aria-label="save"
                hover="none"
                sx={{ hover: "none", marginRight: "22px" }}
              >
                X{/* <Cancel /> */}
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack direction="column">
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                    }}
                  >
                    <form
                      className={classes.formUpload}
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <label className={classes.lableFileUpload} for="file">
                        <AddAPhotoOutlinedIcon
                          sx={{
                            height: "79.38px",
                            width: "91.67px",
                            cursor: "pointer",
                            color: "#8D9298",
                          }}
                        />
                        <input
                          id="file"
                          type="file"
                          accept=".jpg, .png, .jpeg"
                          multiple={true}
                          ref={inputFileRef}
                          onChange={onFileChangeCapture}
                          style={{ display: "none" }}
                        />
                      </label>
                      {dragActive && (
                        <div
                          className={classes.dragFile}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        marginTop: "26px",
                        fontWeight: "700",
                        color: "#495059",
                      }}
                    >
                      {t("Uploading_Images_Text")}
                    </Typography>
                    {bulkPostImage && (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          textAlign: "center",
                          padding: "0px 20px",
                        }}
                      >
                        {t("bulk_post_edit_warning")}
                      </Typography>
                    )}
                  </Box>
                </Item>
              </Stack>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Grid container display="flex" justifyContent="space-between"></Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilePictureModal;
