import React, { useState, useEffect } from "react";
// import { AppBar, Tab, Tabs } from "@material-ui/core";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Divider, Grid, Typography, Box, Avatar } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
import Overview from "../overview/OverView";
import Details from "../accountDetails/Details";
import ActiveProducts from "../activeProducts/ActiveProducts";
import Users from "../users/Users";
import ProductActivation from "../accountDetails/ProductActivation/index";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import TitleHeader from "../../../../components/CustomComponents/title/index";

const useStyles = makeStyles((theme) => ({
  Tabs: {
    textTransform: "none",
    marginTop: "30px",
  },
  Link: {
    fontSize: "20px",
    marginLeft: "5px",
    color: "blue",
    textDecoration: "none",
  },
  backArrow: {
    height: "40px",
    color: "blue",
  },
}));

const AccountsBanner = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const { id } = params;
  const { signOut, user } = useAuth();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = React.useState(false);
  const [allCompanies, setAllCompanies] = useState([]);

  useEffect(() => {
    getAllCustomer();
  }, []);

  // const accessToken = window.localStorage.getItem("accessToken");

  const getAllCustomer = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/company/${id}`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  return (
    <>
      <TitleHeader showButton={true} />

      <Grid container sx={{ marginTop: "30px" }} alignItems="center">
        {/*  <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          sx={{ height: "60px", width: "60px" }}
        /> */}
        <Grid item>
          <Typography
            fontWeight={700}
            fontSize="24px"
            lineHeight="32px"
            sx={{ textTransform: "capitalize" }}
          >
            {allCompanies.name}
          </Typography>
          <Typography
            fontWeight={400}
            fontSize="14px"
            lineHeight="20px"
            // variant="subtitle1"
            color="#495059"
            sx={{ marginBottom: "0.5rem" }}
          >
            {t("sub_title")}
          </Typography>
        </Grid>
      </Grid>
      <Tabs
        className={classes.Tabs}
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
      >
        {/*  <Tab label={t("Overview")} /> */}
        <Tab label={t("AccountDetails")} />
        {/*   <Tab label={t("ActiveProducts")} /> */}
        <Tab label={t("Users")} />

        <Tab label={t("ProductActivation")} />

        {/* <Tab label={t("Files")} /> */}
      </Tabs>
      <Divider />
      {/*  {selectedTab === 0 && <Overview />} */}
      {selectedTab === 0 && <Details setSelectedTab={setSelectedTab} />}
      {/*    {selectedTab === 2 && <ActiveProducts params={params} />} */}
      {selectedTab === 1 && <Users params={params} />}
      {selectedTab === 2 && (
        <ProductActivation companyName={allCompanies.name} />
      )}
    </>
  );
};

export default AccountsBanner;
