import React, { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import { useStyles } from "../../../../../Styles/style";

const StyledToggleButton = styled(ToggleButton)(({ selectedstate }) => ({
  width: "50px",
  height: "20px",
  borderRadius: "30px",
  padding: "0",
  display: "flex",
  alignItems: "center",
  justifyContent:
    selectedstate === false
      ? "flex-start"
      : selectedstate === null
      ? "center"
      : "flex-end",
  backgroundColor:
    selectedstate === false
      ? "#8D9298"
      : selectedstate === null
      ? "#EBEBEC"
      : "#06BDFF",
  transition: "background-color 0.3s ease, justify-content 0.3s ease",
  "&:hover": {
    backgroundColor:
      selectedstate === false
        ? "#8D9298"
        : selectedstate === null
        ? "#EBEBEC"
        : "#05A0E1",
  },
  "& .MuiToggleButton-label": {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  },
}));

export default function TriStateToggleButton({
  periods,
  indexValue,
  handleChangeOpen,
}) {
  const [state, setState] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const handleToggle = (e) => {
    // false -> mid -> true
    if (state === false) {
      setState(null);
      handleChangeOpen(null, { ...periods, closed: null }, indexValue);
    } else if (state === null) {
      setState(true);
      handleChangeOpen(true, { ...periods, closed: false }, indexValue);
    } else {
      setState(false);
      handleChangeOpen(false, { ...periods, closed: true }, indexValue);
    }
  };

  useEffect(() => {
    if (periods.closed === null) {
      setState(null);
    } else {
      setState(!periods.closed);
    }
  }, [periods?.closed]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <ToggleButtonGroup
          exclusive
          aria-label="tristate toggle button"
          className="flex"
        >
          <StyledToggleButton
            selectedstate={state}
            value={state}
            onClick={handleToggle}
          >
            <div className="MuiToggleButton-label" />
          </StyledToggleButton>
        </ToggleButtonGroup>
        <Typography className={classes.toggleText}>
          {periods?.closed === false
            ? t("Open")
            : periods?.closed === null
            ? t("Undecided_text")
            : t("Closed")}
        </Typography>
      </Box>
    </>
  );
}
