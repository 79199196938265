import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { api } from "../../contexts/JWTContext";
import { toast } from "react-toastify";
import { languageOptions } from "../../assets/defaultLanguage";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 30px;
    height: 30px;
  }
`;

const Flag = styled.img`
  border-radius: 100%;
  width: 30px;
  height: 30px;
`;

function NavbarLanguagesDropdown() {
  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { t } = useTranslation();
  const [userLanguage, setUserLanguage] = React.useState();
  const stateData = JSON.parse(localStorage.getItem("user"));
  const userSelectedLng = localStorage.getItem("i18nextLng");

  let userLang = "";

  useEffect(() => {
    if (
      userSelectedLng !== null &&
      userSelectedLng !== undefined &&
      stateData !== null &&
      stateData !== undefined
    ) {
      const defaultLanguage = Object.keys(languageOptions).find((item) => {
        if (userSelectedLng === "en") {
          if (stateData?.language === null || stateData?.language === "") {
            setUserLanguage(languageOptions["en"]);
            localStorage.setItem("i18nextLng", "en");
            i18n.languages = ["en"];
            i18n.language = "en";
            i18n.translator.language = "en";
          } else {
            if (
              languageOptions[`${item}`]?.name?.toLowerCase() ==
              stateData?.language?.toLowerCase()
            ) {
              userLang = item;
              //   i18n.options.fallbackLng[0] = userLang;
            }
            setUserLanguage(languageOptions[userLang]);
            localStorage.setItem("i18nextLng", userLang);
            i18n.languages = [userLang];
            i18n.language = userLang;
            i18n.translator.language = userLang;
          }
        } else {
          setUserLanguage(languageOptions[userSelectedLng]);
          localStorage.setItem("i18nextLng", userSelectedLng);
          i18n.languages = [userSelectedLng];
          i18n.language = userSelectedLng;
          i18n.translator.language = userSelectedLng;
        }
      });
    }
  }, []);

  let selectedLanguage = "";
  selectedLanguage = languageOptions[userLang];
  const { user } = useAuth();
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleLanguageChange = async (language, languageKey) => {
    try {
      i18n.changeLanguage(languageKey);
      i18n.languages = [languageKey];
      i18n.language = languageKey;
      i18n.translator.language = languageKey;
      setUserLanguage(languageOptions[languageKey]);

      let locationIds = [];
      if (
        stateData.hasOwnProperty("userLocation") &&
        stateData.userLocation.length > 0
      ) {
        stateData.userLocation.map((item) => {
          if (item?.location !== null) {
            locationIds.push(item?.location.id);
          }
        });
      }
      const res = await api.patch(`/user/${user.id}`, {
        firstName: stateData.firstName,
        lastName: stateData.lastName,
        email: stateData.email,
        language: language.name,
        role: stateData.role,
        locations: locationIds,

        // companyId: id.id,
      });
      if (res.status === 200) {
        //  window.location.reload();
        closeMenu();
        localStorage.setItem("user", JSON.stringify(res.data.data));
        localStorage.setItem("i18nextLng", languageKey);
        const languageChangeEvent = new Event("languageChange");
        window.dispatchEvent(languageChangeEvent);
        /*  localStorage.setItem() */
        toast.success("Language updated successfully");
      }
    } catch (error) {
      toast.error("Something went wrong");
      closeMenu();
    }
  };

  return (
    <React.Fragment>
      <Tooltip title={t("Languages")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : ""}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          sx={{ fontSize: "8px" }}
        >
          <Flag src={userLanguage?.icon} alt={userLanguage?.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() =>
              handleLanguageChange(languageOptions[`${language}`], language)
            }
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
