import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
// import { Autocomplete, Stack, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { handleSessionOut } from "../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import useAuth from "../../../../../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import TableComponent from "./Table";
// import GoogleIcon from "@mui/icons-material/Google";
import { useLocation } from "react-router-dom";
// import { withStyles } from "@material-ui/core/styles";
import {
  // Box,
  // Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
import Loader from "../../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CustomizedTableRow from "../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../../components/CustomComponents/table/tableCell";
import { spacing } from "@mui/system";
import Delete from "../../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../contexts/JWTContext";
import NotFound from "../../../../../components/NotFound/NotFound";
import SearchField from "../../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";

const Paper = styled(MuiPaper)(spacing);

// const accessToken = window.localStorage.getItem("accessToken");

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

const EnhancedTableHead = (props) => {
  const { order, orderBy, getAllLocation, t } = props;

  const headCells = [
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "CompanyyName", alignment: "left", label: t("CompanyyName") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "current_address", alignment: "left", label: t("current_address") },
    { id: "previous_address", alignment: "left", label: t("previous_address") },
    { id: "status", alignment: "left", label: t("status") },
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [loading, setLoading] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [deleteLocation, setDeleteLocation] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modelStatus, setModelStatus] = useState(null);
  const [pageLoad, setPageLoad] = useState(0);

  useEffect(async () => {
    await getAllLocation();
    setPageLoad(1);
  }, [rowsPerPage, page]);

  useEffect(() => {
    if (pageLoad > 0) {
      const delayDebounceFn = setTimeout(() => {
        getAllLocation();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName]);

  const getAllLocation = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `locationLogs/changeAddress?limit=${rowsPerPage}&page=${page + 1}${
          filterWithName !== "" ? "&search=" + filterWithName : ""
        }`
      );
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setIsLoading(false);
        //  setFilterWithName("");
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
    setModelStatus(null);
  };
  const handleOpenDeleteModel = (id, statusValue) => {
    setDeleteLocation(id);
    setOpen(id);
    setAnchorEl(null);
    setModelStatus(statusValue);
  };
  const handleClick = (event, selectedData) => {
    setSelectedRow(selectedData);

    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleUpdateStatus = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(
        `/locationLogs/changeAddress/${selectedRow?.id}`,
        {
          status: modelStatus === "done" ? "accepted" : "rejected",
        }
      );
      if (res.status === 200) {
        setIsLoading(false);
        toast.success(res?.data?.message);
        handleClose();
        getAllLocation();
      }
    } catch (error) {
      setIsLoading(false);
      handleClose();
      toast.error(error?.response?.data?.message);
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies?.resultArr?.length - page * rowsPerPage);

  return (
    <>
      <Helmet title="Locations" />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "50vh",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <Grid container spacing={2} mt={5} mb={5}>
            <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getAllLocation}
                placeholder={t("Filter_Location_Name")}
                filterWithName={filterWithName}
              />
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
              <CommonButton
                alignment={true}
                onSubmit={getAllLocation}
                label={t("Search")}
                leftMargin={true}
              />
            </Grid>
          </Grid>

          {allCompanies?.resultArr?.length > 0 ? (
            <Grid container marginTop="10px" spacing={6}>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      t={t}
                      order={order}
                      orderBy={orderBy}
                      rowCount={allCompanies?.Count}
                      getAllLocation={getAllLocation}
                    />
                    <TableBody>
                      {allCompanies?.resultArr?.length > 0 &&
                        allCompanies?.resultArr.map((row, index) => {
                          return (
                            <>
                              <TableComponent
                                row={row}
                                index={index}
                                handleClose={handleClose}
                                setOpen={setOpen}
                                setDeleteId={setDeleteId}
                                closeMenu={closeMenu}
                                selectedRow={selectedRow}
                                handleOpenDeleteModel={handleOpenDeleteModel}
                                handleClick={handleClick}
                                anchorEl={anchorEl}
                              />
                            </>
                          );
                        })}
                      {/*  {emptyRows > 0 && (
                    <CustomizedTableRow style={{ height: 53 * emptyRows }}>
                      <CustomizedTableCell colSpan={8} />
                    </CustomizedTableRow>
                  )} */}
                    </TableBody>
                    <Dialog
                      fullScreen={fullScreen}
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                    >
                      <Delete
                        title={
                          (modelStatus !== null) & (modelStatus === "done")
                            ? t("update_status")
                            : t("update_status_reject")
                        }
                        description={
                          modelStatus !== null && modelStatus === "done"
                            ? t("update_status_des")
                            : t("update_status_des_reject")
                        }
                        onConfirm={handleUpdateStatus}
                        onCancel={handleClose}
                      />
                    </Dialog>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={allCompanies?.Count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container sx={{ justifyContent: "center", height: "300px" }}>
              <NotFound text={t("Location_found")} />
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default MyLocations;
