import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  cardbox: {
    backgroundColor: "#F6F6F7",
    borderRadius: "none",
    height: "auto",
  },
  containerSpacing: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  mainContainer: {
    padding: "24px 14px 24px 14px",
  },
  counters: {
    color: "#06BDFF",
    fontSize: "25px",
    fontWeight: 700,
  },
  counterText: {
    color: "#495059",
  },
  viewMoreFilterTypo: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  faqModelTitle: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "20px",
    color: "#1b2430",
  },
  faqModelTitleHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 22px 8px 22px",
  },
  faqAskModelTitle: {
    lineHeight: "28px",
    fontWeight: 700,
    fontSize: "15px",
    color: "#1b2430",
  },
  typoOwner: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "20px",
  },
  typoDate: {
    lineHeight: "28px",
    fontSize: "11px",
    color: "#495059",
    marginLeft: "10px",
  },
  questionTypo: {
    lineHeight: "28px",
    fontSize: "14px",
    color: "#1B2430",
  },
  actionsClass: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "8px",
    marginRight: "16px",
  },
  actionsClassSubmit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "16px",
    marginTop: "10px",
  },
  textLimitTypo: {
    fontSize: "12px",
    fontWeight: "400",
    color: "red",
    lineHeight: "15px",
    marginBottom: "8px",
  },
  limitFieldTextAskQ: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "30px",
    // textAlign: "right",
    // marginLeft: "auto",
    // marginRight: "17px",
  },

  tickAnswerIcon: {
    color: "#06BDFF",
    cursor: "pointer",
  },

  textAreaStyle: {
    border: "1px solid #c4c4c4",
    fontFamily: "Segoe UI",
    fontStyle: "Normal",
    padding: "12px 12px 12px 12px",
    lineHeight: "24px",
    width: "100%",
    borderRadius: "4px",
    color: "#495059",
    fontWeight: "400",
    fontSize: "16px",
    wordSpacing: "normal",

    "&:hover ": {
      border: "1px solid #54d2ff",
    },
  },
  centerGrid: {
    textAlign: "center",
    cursor: "pointer",
  },
  centerGridFilter: {
    textAlign: "center",
    border: "1px solid #06BDFF",
    borderRadius: "5px",
    background: "#E0F7FF",
    padding: "8px",
  },
  typoOwnerActions: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    cursor: "pointer",
    color: "#06BDFF",
  },
  typoOwnerActionsDisabled: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    cursor: "pointer",
    color: "#495059",
  },
}));
