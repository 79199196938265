import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import RequestedState from "../../../assets/images/requested.png";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  locationsubhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    marginTop: "8px",
    height: "250px",
  },
  Imagesection2: {
    marginTop: "8px",
    height: "250px",
  },
}));

const LocationRequested = ({ location }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.locationbox}>
      <img alt="" className={classes.Imagesection} src={RequestedState} />
      <Typography className={classes.locationhead}>
        {t("uberAll_button_requested")}!
      </Typography>
      <Typography className={classes.locationsubhead}>
        {location?.name + t("Requeste_state_subheading")}
      </Typography>
    </Box>
  );
};

export default LocationRequested;
