import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Divider as MuiDivider,
  Modal,
  Tooltip,
  tooltipClasses,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
// import TaskCard from "./TaskCard";
// import Loader from "../../../components/Loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import AddTask from "./AddTask";

import EditTask from "./EditTask";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Edit } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import DeleteBulk from "../../../../components/Models/DeleteModal/BulkDelete";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import Checkbox from "@mui/material/Checkbox";
// import { Title } from 'chart.js';
import Title from "../../../../components/CustomComponents/title/index.js";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Status from "../../../../components/status/Status";
import NotFound from "../../../../components/NotFound/NotFound";
import Comments from "./Comments";
import useAuth from "../../../../hooks/useAuth";
import { useStyles } from "../styles/style";
import { useNavigate } from "react-router-dom";
import LocationNotFound from "../../../../components/CustomComponents/Errors/NoLocationFound";
import Iframe from "../../../../components/Models/IframeModal/Iframe.js";
import { languageOptions } from "../../../../assets/defaultLanguage/index.js";
import { LanguageChange } from "../../../../utils/LocalStorageHelpers.js";
import { checkLocalStorageData } from "../../../../utils/LocalStorageHelpers.js";
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);

const style = {
  position: "absolute",
  right: "0%",
  width: "450px",
  height: "100vh",
  bgcolor: "background.paper",
  aspectRatio: "16 / 9",
  p: 4,
};
const stylee = {
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  position: "absolute",
  right: "0%",
  width: "70%",
  bgcolor: "background.paper",
  aspectRatio: "16 / 9",
  p: 4,
};

const Tasks = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const isDataValid = checkLocalStorageData();
  const { signOut, permissionsAcess } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getTask, setGetTask] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [getCount, setGetCount] = useState([]);
  const [deleteTask, setDeleteTask] = useState(null);
  const [openModalEditTask, setOpenModalEditTask] = React.useState(false);
  const [openModalAddTask, setOpenModalAddTask] = React.useState(false);
  const [filterWithName, setFilterWithName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteTasks, setDeleteTasks] = useState([]);
  const [selectAll, setSelectAll] = useState(null);
  const [allLocation, setAllLocation] = useState([]);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [openIframeModel, setOpenIframeModel] = useState(false);
  const languageData = localStorage.getItem("i18nextLng");
  const stateData = JSON.parse(localStorage.getItem("user"));
  const [search, setSearch] = useState(false);
  let selectedLanguage = "";
  let userLang = "";
  selectedLanguage = languageOptions[userLang];

  useEffect(() => {
    if (isDataValid) {
      if (languageData !== "en" || stateData.language === null) {
        LanguageChange();
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);
  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/tasks"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(async () => {
    if (isDataValid) {
      await getAllLocations();
      await getDetailTask();
    }
  }, []);

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          isLocationPage: false,
        },
      });

      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setAllLocation(res.data.data);
        } else {
          setAllLocation(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllLocation(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // getDetailTask();
      if (search && isDataValid) {
        getTasks();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  useEffect(async () => {
    if (isDataValid) {
      setIsLoading(true);
      getTasks();
    }
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteTask(id);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { t } = useTranslation();
  const handleOpenModal = () => {
    setOpenModal(true);
    setOpenDeleteModal(false);
  };
  const handleOpen = () => setOpenModalAddTask(true);
  const handleDeleteModal = () => setOpenDeleteModal(true);
  const handleClose = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseModal = (apicall) => {
    setOpenModalAddTask(false);
    if (apicall) {
      getTasks();
    }
  };
  const filterByName = (e) => {
    setSearch(true);
    setFilterWithName(e.target.value);
  };
  const handleDeleteTask = async (id) => {
    try {
      const res = await api.delete(`/tasks/${id}`);
      if (res.status === 200) {
        setOpen(false);
        toast.success("Task deleted successfully");
        getTasks();
      }
    } catch (error) {}
  };

  const handleOpenDeleteModel = () => {
    setOpen(deleteTask);
    setAnchorEl(null);
  };

  const closeMenu = () => {
    // setAnchorMenu(null);
  };

  const handleOpenEditTask = (row) => {
    setOpenModalEditTask(true);
    setSelectedTask(row);
  };

  const handleCloseModalEditTask = (apicall) => {
    setOpenModalEditTask(null);
    setSelectedTask(null);
    if (apicall) {
      getTasks();
    }
  };
  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };
  const handleDeleteSelectedTasks = async (deleteTaskIds) => {
    setLoading(true);
    try {
      const res = await api.patch(`tasks/removeTasks`, {
        all: false,
        taskIds: deleteTaskIds,
      });

      if (res.status === 200) {
        getTasks();
        toast.success("Task Deleted Successfully");
        setOpenModal(false);
        setOpenDeleteModal(false);
        setSelectAll(false);
        closeMenu(false);
        setDeleteTasks([]);
      }
      setLoading(false);
    } catch (error) {
      setDeleteTasks([]);
      toast.error("Tasks Delete Failed");
    }
  };
  const handleDeleteAllTasks = async () => {
    setLoading(true);
    try {
      const res = await api.patch(`tasks/removeTasks`, {
        all: true,
        // taskIds: deleteTaskIds,
      });

      if (res.status === 200) {
        getTasks();
        toast.success("Task Deleted Successfully");
        setOpenModal(false);
        setSelectAll(false);
        closeMenu(false);
        setDeleteTasks([]);
      }
      setLoading(false);
    } catch (error) {
      setDeleteTasks([]);
      toast.error("Tasks Delete Failed");
    }
  };

  const getTasks = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `tasks?limit=${rowsPerPage}&page=${
          page + 1
        }&searchTerm=${filterWithName}`
        // {
        //   params: {
        //     searchTerm: searchTerms,
        //   },
        // }
      );

      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setGetTask(res.data.data);
        } else {
          setGetTask(null);
        }
        setGetTask(res.data.data);
        setGetCount(res.data.data);
        setSearch(false);
        setIsLoading(false);
        setDeleteTasks([]);
        setSelectAll(false);
      }
    } catch (error) {
      setGetTask(null);
      setIsLoading(false);
      handleSessionOut(
        error?.response?.status,
        error?.response?.data?.message,
        handleSignOut
      );
    }
  };

  const getDetailTask = async () => {
    if (location?.search?.length > 0) {
      let taskId = location?.search.split("=");
      if (taskId[1]) {
        try {
          const res = await api.get(`/tasks/${taskId[1]}`);

          if (res.status === 200) {
            handleOpenEditTask(res.data.data);
            setIsLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    }
  };
  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);

    if (e.target.checked) {
      for (var i = 0; i < getTask?.results.length; i++) {
        deleteTasks.push(getTask?.results[i].id);
      }

      const uniqueTasks = new Set(deleteTasks);

      setDeleteTasks([...uniqueTasks]);
    } else {
      setDeleteTasks([]);
    }
  };
  const handleTaskChecked = (e, index, taskId) => {
    if (e.target.checked) {
      deleteTasks.push(taskId);
    } else {
      var taskIndex = deleteTasks.indexOf(taskId);
      deleteTasks.splice(taskIndex, 1);
    }
    setDeleteTasks([...deleteTasks]);
  };

  const handleOpenTemplateMenu = () => {
    setOpenIframeModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenIframeModel(false);
  };

  return (
    <>
      {openIframeModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openIframeModel}
          onClose={handleCloseTemplateMenu}
        >
          <Iframe
            title={t("Task_Tutorial")}
            onCancel={handleCloseTemplateMenu}
            iframe={
              <iframe
                title="iframe"
                loading="lazy"
                src="https://ask.obenan.com/f4tkmskma"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="1200px"
                height="500px"
                onLoad={() => {}}
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
              />
            }
          />
        </Dialog>
      )}
      <Title
        title={t("Tasks")}
        subHeading={t("TaskSubTitle")}
        // IframeButton={true}
        // IframeButtonName={
        //   <Tooltip title={t("Help")}>
        //     img alt="" src={QuestionMark} />
        //   </Tooltip>
        // }
        // handleDefaultIframe={handleOpenTemplateMenu}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allLocation?.results?.length > 0 ? (
            <>
              <Grid container spacing={0.5}>
                <Grid item xs={7} md={9} lg={9} sm={7}>
                  <SearchField
                    filterByName={filterByName}
                    onSubmit={getTasks}
                    placeholder={t("SearchTask")}
                    filterWithName={filterWithName}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={3}
                  lg={3}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CommonButton
                    label={t("SearchButton")}
                    onSubmit={getTasks}
                    alignment={true}
                  />

                  {addPermission && viewPermission ? (
                    allLocation?.results?.length > 0 && (
                      <CommonButton
                        label={t("Add")}
                        onSubmit={handleOpen}
                        alignment={true}
                      />
                    )
                  ) : (
                    <BootstrapTooltip title={t("authorized_access")}>
                      <span>
                        <CommonButton
                          label={t("Add")}
                          alignment={true}
                          disabled={true}
                        />
                      </span>
                    </BootstrapTooltip>
                  )}
                </Grid>
              </Grid>

              {deleteTasks.length > 0 &&
                (viewPermission && deletePermission ? (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.editMainGrid}
                    >
                      <Button
                        sx={{
                          color: "white",
                          backgroundColor: "#FC3652",
                          "&:hover": {
                            backgroundColor: "#ff3366",
                            cursor: "pointer",
                          },
                        }}
                        onClick={handleDeleteModal}
                      >
                        {t("Delete")}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.editMainGrid}
                    >
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button
                            sx={{
                              backgroundColor: "#e0e0e0",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#e0e0e0",
                                cursor: "pointer",
                              },
                            }}
                            disabled
                            onClick={handleOpenDeleteModel}
                          >
                            {t("Delete")}
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    </Grid>
                  </Grid>
                ))}

              {getTask?.results?.length > 0 ? (
                <>
                  <Grid container spacing={8} sx={{ marginTop: "1rem" }}>
                    <TableContainer
                      borderRadius="12"
                      component={Paper}
                      marginLeft="2rem"
                    >
                      <Table
                        sx={
                          {
                            // minWidth: 650,
                          }
                        }
                        aria-label="simple table"
                      >
                        <CustomizedTableHead variant="h1">
                          <CustomizedTableRow>
                            <CustomizedTableCell>
                              {getTask?.results?.length > 0 && (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  sx={{ display: "flex" }}
                                >
                                  <Checkbox
                                    checked={selectAll}
                                    onChange={(e) => handleChangeCheckButton(e)}
                                    //   inputProps={{ "aria-label": "controlled" }}
                                  />
                                </Grid>
                              )}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              sx={{ width: "100px" }}
                              align="start"
                            >
                              {t("ID")}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ width: "300px" }}>
                              {t("Title")}
                            </CustomizedTableCell>
                            <CustomizedTableCell sx={{ width: "300px" }}>
                              {t("Location")}
                            </CustomizedTableCell>
                            <CustomizedTableCell>
                              {t("Company_Name")}
                            </CustomizedTableCell>
                            <CustomizedTableCell align="center">
                              {t("Status")}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              align="center"
                              sx={{ width: "100px" }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignitems: "center",
                                }}
                              >
                                {t("Action")}
                                {/* {deleteTasks.length > 0 && (
                              <Card>
                                <Tooltip title="Delete">
                                  <IconButton
                                    className={classes.iconStyle}
                                    aria-label="delete"
                                    size="large"
                                    // color="red"
                                    onClick={() => handleOpenModal()}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Card>
                            )} */}
                              </Box>
                            </CustomizedTableCell>
                          </CustomizedTableRow>
                        </CustomizedTableHead>
                        <TableBody>
                          {getTask?.results?.map((row, index) => (
                            <CustomizedTableRow
                              style={{ height: 10 }}
                              sx={{ cursor: "pointer" }}
                              // onClick={() => {
                              //   handleOpenEditTask(row);
                              // }}
                            >
                              <CustomizedTableCell hover>
                                <Checkbox
                                  checked={deleteTasks.includes(row.id)}
                                  onChange={(e) => {
                                    handleTaskChecked(e, index, row.id);
                                  }}
                                  //   inputProps={{ "aria-label": "controlled" }}
                                  label=""
                                />
                              </CustomizedTableCell>
                              <CustomizedTableCell hover align="start">
                                {" "}
                                {row.id}
                              </CustomizedTableCell>
                              <CustomizedTableCell sx={{ width: "300px" }}>
                                {row.title}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "300px" }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                  }}
                                >
                                  {" "}
                                  {row?.location?.internalName !== undefined &&
                                  row?.location?.internalName !== null ? (
                                    <>
                                      <LocationOnIcon />{" "}
                                      {row?.location?.internalName}
                                    </>
                                  ) : (
                                    "--"
                                  )}
                                </Box>
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    // marginTop: "1rem",
                                  }}
                                >
                                  {" "}
                                  <BusinessIcon
                                    sx={{
                                      marginRight: ".5rem",
                                      align: "center",
                                    }}
                                  />{" "}
                                  {row?.company?.name}
                                </Box>
                              </CustomizedTableCell>
                              <CustomizedTableCell align="center">
                                {" "}
                                <Status task={row} />
                              </CustomizedTableCell>
                              <CustomizedTableCell align="start">
                                {viewPermission && editPermission ? (
                                  <BootstrapTooltip title={t("EditTask")}>
                                    <IconButton
                                      aria-label="edit"
                                      size="large"
                                      onClick={(e) => {
                                        // e.stopPropagation();
                                        handleOpenEditTask(row);
                                      }}
                                    >
                                      <Edit />
                                    </IconButton>
                                  </BootstrapTooltip>
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <IconButton variant="outlined" disabled>
                                        <Edit disabled />
                                      </IconButton>
                                    </span>
                                  </BootstrapTooltip>
                                )}

                                <IconButton
                                  onClick={(e) => handleClick(e, row?.id)}
                                  variant="outlined"
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  {viewPermission && deletePermission ? (
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenDeleteModel();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <DeleteIcon
                                        sx={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      <Typography
                                        aria-label="delete"
                                        size="large"
                                        onClick={(e) => {
                                          setOpen(true);
                                        }}
                                      >
                                        {t("Delete")}
                                      </Typography>
                                    </MenuItem>
                                  ) : (
                                    <BootstrapTooltip
                                      title={t("authorized_access")}
                                    >
                                      <span>
                                        <MenuItem disabled>
                                          <DeleteIcon
                                            disabled
                                            sx={{
                                              color: "#545353",
                                              paddingRight: "5px",
                                            }}
                                          />
                                          <Typography
                                            aria-label="delete"
                                            size="large"
                                          >
                                            {t("Delete")}
                                          </Typography>
                                        </MenuItem>
                                      </span>
                                    </BootstrapTooltip>
                                  )}
                                </Menu>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          ))}
                        </TableBody>
                        <Dialog
                          //fullScreen={fullScreen}
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <Delete
                            title={t("Delete_Task")}
                            description={t("del_task_subtitle")}
                            onConfirm={() => handleDeleteTask(deleteTask)}
                            onCancel={handleClose}
                          />
                        </Dialog>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={getTask?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                getTask === null && (
                  <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
                    <NotFound text={t("No_Task_Found")} />
                  </Box>
                )
              )}
            </>
          ) : (
            allLocation === null && (
              <Box className={classes.locationbox}>
                <LocationNotFound
                  text={t("No_Location_Added")}
                  subtext={t("add_location_text")}
                />
              </Box>
            )
          )}
        </>
      )}

      {/* <Divider my={6} /> */}

      {openModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openModal}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Bulk_Task")}
            description={t("Delete_bulk_subhead")}
            loading={loading}
            onConfirm={handleDeleteAllTasks}
            // onConfirm={() => isLoading ?  <> <CircularProgress/> </> : handleDeleteBulkTasks(deleteTasks)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openDeleteModal && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDeleteModal}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DeleteBulk
            title={t("Delete")}
            description={t("Select_Delete_Task")}
            loading={loading}
            // loadingBulk={loadingBulk}
            getCount={getCount}
            onConfirm={handleOpenModal}
            onConfirmBulk={handleDeleteSelectedTasks}
            deleteTasksIds={deleteTasks}
            // onConfirm={() => handleDeleteBulkTasks(deleteTasks)}

            // onConfirm={() => isLoading ?  <> <CircularProgress/> </> : handleDeleteBulkTasks(deleteTasks)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openModalAddTask && !isMobile && (
        <Modal
          open={openModalAddTask}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={style}>
                {/* <Typography variant="h2">{t("AddTask")}</Typography> */}

                <AddTask
                  alignments={true}
                  task={getTask}
                  onCancel={handleCloseModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModalAddTask && isMobile && (
        <Modal
          open={openModalAddTask}
          //   onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={stylee}>
                {/* <Typography variant="h2">{t("AddTask")}</Typography> */}

                <AddTask
                  alignments={false}
                  task={getTask}
                  onCancel={handleCloseModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModalEditTask && !isMobile && (
        <Modal
          open={openModalEditTask}
          //  onClose={handleCloseModalEditTask}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={style}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    alignItems: "center",
                    marginY: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                    {t("EditTask")}
                  </Typography>
                </Box>
                <Divider my={6} />
                <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                  <Tabs
                    sx={{ width: "100%", marginTop: "2px" }}
                    className={classes.Tabs}
                    value={selectedTab}
                    onChange={handleChange}
                    variant="scrollable"
                    centered
                  >
                    <Tab sx={{ width: "50%" }} label={t("Details")} />
                    <Tab sx={{ width: "50%" }} label={t("Comments")} />
                  </Tabs>
                  {selectedTab === 0 && (
                    <EditTask
                      onCancel={handleCloseModalEditTask}
                      selectedTaskId={selectedTask}
                      alignments={true}
                    />
                  )}
                  {selectedTab === 1 && (
                    <Comments
                      selectedTask={selectedTask}
                      onCancel={handleCloseModalEditTask}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Modal>
      )}
      {openModalEditTask && isMobile && (
        <Modal
          open={openModalEditTask}
          onClose={handleCloseModalEditTask}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Box sx={stylee}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  alignItems: "center",
                  marginY: "10px",
                }}
              >
                <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                  {t("EditTask")}
                </Typography>
              </Box>
              <Divider my={6} />
              <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                <Tabs
                  sx={{ width: "100%", marginTop: "2px" }}
                  className={classes.Tabs}
                  value={selectedTab}
                  onChange={handleChange}
                  variant="scrollable"
                  centered
                >
                  <Tab sx={{ width: "50%" }} label={t("Details")} />
                  <Tab sx={{ width: "50%" }} label={t("Comments")} />
                </Tabs>
                {selectedTab === 0 && (
                  <EditTask
                    onCancel={handleCloseModalEditTask}
                    selectedTask={selectedTask}
                    alignments={false}
                  />
                )}
                {selectedTab === 1 && <Comments selectedTask={selectedTask} />}
              </Box>
            </Box>
          </Grid>
        </Modal>
      )}
      {/* </Paper> */}
    </>
  );
};

export default Tasks;
