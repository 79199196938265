import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import {
  Box,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  Checkbox,
  MenuItem,
  Menu,
  Button,
} from "@mui/material";
import Loader from "../../../../components/Loaders/Loader";
import Dialog from "@mui/material/Dialog";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../../components/CustomComponents/title";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { spacing } from "@mui/system";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import NotFound from "../../../../components/NotFound/NotFound";
import { ReactComponent as AiLogoGrey } from "../../../../assets/images/AIlogogrey.svg";
import EmotionAiRequestModel from "../../../../components/Models/DeleteModal/AdminEmotionAiModel";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  modalCompanyTitle: {
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "700",
    color: "rgba(0,0,0,0.87)",
  },
  modalCircularProgress: {
    width: "25px",
    height: "25px",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  buttonAccept: {
    color: "white",
    backgroundColor: "#06BDFF",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    "&:hover": { backgroundColor: "#0638C1", color: "white" },
  },
  buttonReject: {
    color: "white",
    backgroundColor: "#FC3652",
    fontSize: "14px",
    borderRadius: "8px",
    padding: "14px",
    marginLeft: "8px",
    "&:hover": { backgroundColor: "#e37685", color: "white" },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    getAllLocation,
    t,
    selectedLocationData,
    requestedLocations,
    handleChangeCheckButton,
    selectAll,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
    getAllLocation();
  };

  const headCells = [
    {
      alignment: "left",
      label: (
        <Checkbox
          checked={selectAll}
          onChange={(e) => handleChangeCheckButton(e)} //   inputProps={{ "aria-label": "controlled" }}
        />
      ),
    },
    { id: "id", alignment: "left", label: t("TableID") },
    { id: "name", alignment: "left", label: t("LocationTableName") },
    { id: "name", alignment: "left", label: t("CompanyyName") },
    { id: "country", alignment: "left", label: t("country") },
    { id: "customer", alignment: "left", label: t("Address") },
    { id: "actions", alignment: "center", label: t("TableAction") },
  ];

  return (
    <CustomizedTableHead>
      <CustomizedTableRow>
        {headCells.map((headCell) => (
          <CustomizedTableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </CustomizedTableCell>
        ))}
      </CustomizedTableRow>
    </CustomizedTableHead>
  );
};

function MyLocations() {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const theme = useTheme();
  const navigate = useNavigate();
  const { search } = useLocation();
  const classes = useStyles();
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openRequest, setOpenRequest] = React.useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [requestedLocations, setRequestedLocations] = useState(null);
  const [allLocation, setAllLocation] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [filterWithCountry, setFilterWithCountry] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openRejectModel, setOpenRejectModel] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [locationLoading, setLocationLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [bulkButton, setBulkButton] = useState(false);
  const [selectedLocationData, setselectedLocationData] = useState([]);
  const [firstPageLoad, setFirstPageLoad] = useState(0);

  // useEffect(async () => {
  //   await getAllLocation();
  // }, [rowsPerPage, page]);

  useEffect(() => {
    if (firstPageLoad > 0) {
      const delayDebounceFn = setTimeout(() => {
        getAllLocation();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName, rowsPerPage, page]);

  useEffect(() => {
    if (selectedLocationData?.length !== requestedLocations?.results?.length) {
      setSelectAll(false);
    } else {
      //  setSelectAll(true);
    }
  }, [selectedLocationData]);

  useEffect(() => {
    getAllLocation();
    getAllLocationsForNewRequest();
    getAllCompanies();
    setFirstPageLoad(1);
  }, []);

  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    setBulkButton(true);
    let dummyArr = [];
    if (e.target.checked) {
      for (var i = 0; i < requestedLocations?.results?.length; i++) {
        selectedLocationData?.push(requestedLocations?.results[i]?.id);
        dummyArr?.push(requestedLocations?.results[i]?.id);
      }

      const uniqueTasks = new Set(selectedLocationData);
      {
      }
      setselectedLocationData([...uniqueTasks]);
      // dummyArr([...uniqueTasks]);
    } else {
      setselectedLocationData([]);
      // dummyArr([]);
    }

    if (selectedLocationData?.length === dummyArr?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  };
  const handleTaskChecked = (e, index, locationId) => {
    setBulkButton(true);
    if (allLocation?.length === selectedLocationData?.length) {
      setselectedLocationData([]);
      setSelectAll(false);
    } else {
      setSelectAll(true);
      setselectedLocationData(allLocation);
    }
    if (e.target.checked) {
      selectedLocationData.push(locationId);
      {
      }
    } else {
      var locationIndex = selectedLocationData.indexOf(locationId);
      selectedLocationData.splice(locationIndex, 1);
    }

    setselectedLocationData([...selectedLocationData]);
  };
  const handleCloseTemplateModal = () => {
    setOpenDefaultModel(false);
    setLocationId([]);
  };
  const handleCloseModal = () => {
    setOpenModel(false);
  };
  const handleCloseRejectModal = () => {
    setOpenRejectModel(false);
  };
  const handleOpenDeleteModel = () => {
    setOpen(false);
    locationId?.push(selectedData);
    setOpenDefaultModel(true);
  };
  const handleOpenBulkAcceptModel = () => {
    setOpen(false);
    setOpenModel(true);
  };
  const handleOpenBulkRejectModel = () => {
    setOpenRejectModel(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event, id, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedData(data);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };
  const getAllLocation = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `/sentimentalAnalysis/requested/locations?limit=${rowsPerPage}&pageNumber=${
          page + 1
        }${filterWithName !== "" ? "&search=" + filterWithName : ""}`
      );
      if (res.status === 200) {
        if (res?.data?.data?.results?.length > 0) {
          setRequestedLocations(res?.data?.data);
        } else {
          setRequestedLocations(null);
        }

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setRequestedLocations(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/admin/company`, {
        /*  params: {
          limit: 100,
        }, */
      });
      if (res.status === 200) {
        setAllCompanies(res.data.data);
      }
    } catch (error) {}
  };
  const getAllLocationsForNewRequest = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location/search`);
      if (res.status === 200) {
        setAllLocation(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleOpenConfirmBox = (id) => {
    locationId?.push(id);
    // setSelectedData(id);
    setOpen(true);
  };

  const handleCloseConfirmBox = () => {
    setOpen(false);
    setLocationId([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies.length - page * rowsPerPage);

  const handleRequestForEmotionAI = async () => {
    setRequestLoading(true);
    let locationIds = [];

    if (locationId?.length > 0) {
      locationId?.map((item) => {
        locationIds.push(item?.id);
      });
    }

    try {
      const res = await api.patch(`/sentimentalAnalysis/location/script`, {
        locationIds: locationIds,
      });
      if (res.status === 200) {
        setRequestLoading(false);
        setOpen(false);
        setCompanyId(null);
        setAllLocation([]);
        setLocationId([]);

        getAllLocation();
        getAllCompanies();
        setOpenRequest(false);
        toast.success(res?.data.message);
        setFilterWithName("");
      }
    } catch (error) {
      setOpen(false);
      setLocationId([]);
      setAllLocation([]);
      setCompanyId(null);
      setCompanyId(null);
      setOpenRequest(false);
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };

  const handleRequestForEmotionAIBulk = async () => {
    setRequestLoading(true);

    try {
      const res = await api.patch(`/sentimentalAnalysis/location/script`, {
        locationIds:
          selectedLocationData?.length > 0 ? selectedLocationData : [],
      });
      if (res.status === 200) {
        setselectedLocationData([]);
        setRequestLoading(false);
        setOpenModel(false);
        setCompanyId(null);
        setAllLocation([]);
        setLocationId([]);

        getAllLocation();
        getAllCompanies();
        setOpenRequest(false);
        toast.success(res?.data.message);
        setFilterWithName("");
      }
    } catch (error) {
      setOpenModel(false);
      setLocationId([]);
      setAllLocation([]);
      setCompanyId(null);
      setCompanyId(null);
      setOpenRequest(false);
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };

  const handleRejectForEmotionAIBulk = async () => {
    setRequestLoading(true);

    try {
      const res = await api.patch(
        `/sentimentalAnalysis/location/rejectRequestForSentimentalAnalysis`,
        {
          locationIds:
            selectedLocationData?.length > 0 ? selectedLocationData : [],
        }
      );
      if (res.status === 200) {
        setselectedLocationData([]);
        setRequestLoading(false);
        setOpenRejectModel(false);
        setCompanyId(null);
        setAllLocation([]);
        setLocationId([]);
        getAllLocation();
        getAllCompanies();
        setOpenRequest(false);
        toast.success(res?.data.message);
        setFilterWithName("");
      }
    } catch (error) {
      setOpenRejectModel(false);
      setLocationId([]);
      setAllLocation([]);
      setCompanyId(null);
      setCompanyId(null);
      setOpenRequest(false);
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };

  const handleRequestForEmotionAIForNewRequest = async () => {
    setRequestLoading(true);
    try {
      const res = await api.get(
        `/sentimentalAnalysis/location/script/${locationId?.id}`
      );
      if (res.status === 200) {
        setRequestLoading(false);
        setLocationId(null);
        setOpenRequest(false);
        toast.success(res?.data.message);
        setFilterWithName("");
      }
    } catch (error) {
      setLocationId(null);
      setOpenRequest(false);
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };

  const handleOpenNewRequestModel = () => {
    setOpenRequest(true);
  };
  const handleCloseNewRequestModel = () => {
    setOpenRequest(false);
    setLocationId([]);
    setAllLocation([]);
    setCompanyId(null);
  };
  const handleLocationChange = (option) => {
    setLocationId(option);
  };

  const getCompanyLocations = async (companyData) => {
    setLocationLoading(true);
    try {
      const res = await api.get(
        `sentimentalAnalysis/location/allLocationWithStatus/${companyData?.id}`
      );

      if (res.status === 200) {
        setLocationLoading(false);
        setAllLocation(res?.data?.message);
      }
    } catch (error) {
      setLocationLoading(false);
    }
  };
  const handleChangeCompany = async (option) => {
    setLocationId([]);
    setCompanyId(option);
    getCompanyLocations(option);
  };

  const handleRejectRequest = async () => {
    try {
      /*   const res = await api.delete(
        `sentimentalAnalysis/location/rejectRequestForSentimentalAnalysis/${selectedData}`
      ); */
      setRequestLoading(true);
      let locationIds = [];

      if (locationId?.length > 0) {
        locationId?.map((item) => {
          locationIds.push(item?.id);
        });
      }
      const res = await api.patch(
        `/sentimentalAnalysis/location/rejectRequestForSentimentalAnalysis`,
        {
          locationIds: locationIds?.length > 0 ? locationIds : [],
        }
      );

      if (res.status === 200) {
        setSelectedData(null);
        getAllLocation();
        setLocationId([]);
        setOpenDefaultModel(false);
        setRequestLoading(false);
        setOpen(false);
        getAllCompanies();
        setBulkButton(false);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      setOpenDefaultModel(false);
      toast.error(error?.response?.data?.message);
      setRequestLoading(false);
    }
  };
  const filterByCountry = (e) => {
    setFilterWithCountry(e.target.value);
  };

  return (
    <>
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTemplateModal}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Reject_title")}
            loading={requestLoading}
            description={t("Reject_des")}
            onConfirm={handleRejectRequest}
            onCancel={handleCloseTemplateModal}
          />
        </Dialog>
      )}
      {openModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openModel}
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Bulk_Emotion_AI_Request")}
            description={t("bulk_emotion_subhead")}
            loading={requestLoading}
            onConfirm={handleRequestForEmotionAIBulk}
            onCancel={handleCloseModal}
          />
        </Dialog>
      )}
      {openRejectModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openRejectModel}
          onClose={handleCloseRejectModal}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Bulk_Emotion_AI_Reject")}
            description={t("bulk_emotion_reject_subhead")}
            loading={requestLoading}
            onConfirm={handleRejectForEmotionAIBulk}
            onCancel={handleCloseRejectModal}
          />
        </Dialog>
      )}
      <EmotionAiRequestModel
        title={t("new_req_des")}
        open={openRequest}
        locationLoading={locationLoading}
        handleCloseNewRequestModel={handleCloseNewRequestModel}
        requestLoading={requestLoading}
        allCompanies={allCompanies}
        setLocationId={setLocationId}
        locationId={locationId}
        handleChangeCompany={handleChangeCompany}
        companyId={companyId}
        handleLocationChange={handleLocationChange}
        allLocation={allLocation}
        handleRequestForEmotionAI={handleRequestForEmotionAI}
      />

      <Helmet title="Locations" />
      <TitleHeader
        title={t("location_emotion_AI_header")}
        //  subHeading={t("LocationList")}
        extraButton={true}
        extraButtonName={t("new_request")}
        handleDefaultTemplate={handleOpenNewRequestModel}
        addPermission={true}
        viewPermission={true}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={10.5} sm={10.5} md={10.5} lg={10.5}>
              <SearchField
                filterByName={filterByName}
                onSubmit={getAllLocation}
                placeholder={t("Filter_Location_Name")}
                filterWithName={filterWithName}
              />
            </Grid>
            <Grid item xs={1.5} sm={1.5} md={1.5} lg={1.5}>
              <CommonButton
                alignment={true}
                onSubmit={getAllLocation}
                label={t("Search")}
                leftMargin={true}
              />
            </Grid>
          </Grid>
          {bulkButton === true && (
            <>
              {selectedLocationData?.length > 0 && (
                <Grid container sx={{ marginTop: "16px" }}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      className={classes.buttonAccept}
                      onClick={handleOpenBulkAcceptModel}
                    >
                      {t("accept_button")}
                    </Button>
                    <Button
                      className={classes.buttonReject}
                      onClick={handleOpenBulkRejectModel}
                    >
                      {t("Reject")}
                    </Button>
                  </Grid>
                </Grid>
              )}
            </>
          )}
          {requestedLocations !== null && requestedLocations?.count > 0 ? (
            <Grid container marginTop="16px">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table
                    aria-labelledby="tableTitle"
                    size={"medium"}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      t={t}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={requestedLocations?.count}
                      getAllLocation={getAllLocation}
                      handleChangeCheckButton={handleChangeCheckButton}
                      selectAll={selectAll}
                      selectedLocationData={selectedLocationData}
                      requestedLocations={requestedLocations}
                    />
                    <TableBody>
                      {requestedLocations?.results?.length > 0 ? (
                        requestedLocations?.results?.map((row, index) => {
                          const googleData = row?.socialLink?.find(
                            (item) => item.type === "google"
                          );

                          return (
                            <CustomizedTableRow
                              // hover
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                            >
                              <CustomizedTableCell hover>
                                <Checkbox
                                  checked={selectedLocationData.includes(
                                    row.id
                                  )}
                                  onChange={(e) => {
                                    handleTaskChecked(e, index, row.id, row);
                                  }}
                                  //   inputProps={{ "aria-label": "controlled" }}
                                  label=""
                                />
                              </CustomizedTableCell>
                              <CustomizedTableCell>
                                {row.id}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "250px" }}
                              >
                                {row.name}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {row?.company !== null
                                  ? row?.company?.name
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {row?.company !== null
                                  ? row?.company?.country
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                align="left"
                                sx={{ width: "250px" }}
                              >
                                {row?.addressLine1 !== null
                                  ? row?.addressLine1
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                padding="none"
                                align="center"
                              >
                                <Box mr={2}>
                                  <CustomWidthTooltip
                                    title={t("Contact_Support")}
                                  >
                                    <IconButton
                                      onClick={() => handleOpenConfirmBox(row)}
                                      aria-label="edit"
                                      sx={{ width: "40px", height: "40px" }}
                                    >
                                      {" "}
                                      <AiLogoGrey />
                                    </IconButton>
                                  </CustomWidthTooltip>

                                  <IconButton
                                    onClick={(e) =>
                                      handleClick(e, row?.id, row)
                                    }
                                    variant="outlined"
                                  >
                                    <MoreHorizIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                      "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => {
                                        closeMenu();
                                        handleOpenDeleteModel();
                                        // handleOpenModal(row?.id);
                                      }}
                                    >
                                      <DeleteIcon
                                        sx={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      <Typography
                                        aria-label="delete"
                                        size="large"
                                        onClick={(e) => {
                                          setOpen(true);
                                        }}
                                      >
                                        {t("Reject")}
                                      </Typography>
                                    </MenuItem>
                                  </Menu>
                                </Box>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          );
                        })
                      ) : (
                        <Typography variant="h3">no data found</Typography>
                      )}
                      {emptyRows > 0 && (
                        <CustomizedTableRow style={{ height: 53 * emptyRows }}>
                          <CustomizedTableCell colSpan={8} />
                        </CustomizedTableRow>
                      )}
                    </TableBody>
                    {open && (
                      <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleCloseConfirmBox}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <Delete
                          title={t("Emotion_AI_Request")}
                          description={t("Emotion_Ai_des")}
                          onConfirm={handleRequestForEmotionAI}
                          loading={requestLoading}
                          onCancel={handleCloseConfirmBox}
                        />
                      </Dialog>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={requestedLocations?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          ) : (
            <NotFound text={t("No_Emotion_Ai_Found")} />
          )}
        </>
      )}
    </>
  );
}

export default MyLocations;
