import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Modal,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Dialog,
  useMediaQuery,
  Divider,
  CircularProgress,
  DialogContent,
  Select,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate, useParams } from "react-router-dom";
// import Box from '@mui/material/Box';
// import Modal from '@mui/material/Modal';
import Delete from "../../../../components/Models/DeleteModal/Delete";
import OTPModel from "../../../../components/Models/DeleteModal/OtpModel";
import { alpha } from "@material-ui/core/styles";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/material/styles";
import { toast } from "react-toastify";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import TitleHeader from "../../../../components/CustomComponents/title";
import Loader from "../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import SearchField from "../../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import NotFound from "../../../../components/NotFound/NotFound";
import styled from "styled-components/macro";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AddPrimaryUserModel from "../../../../components/Models/DeleteModal/AddPrimaryUserModel";
import AddPrimaryUserConfirmModel from "../../../../components/Models/DeleteModal/PrimaryUserConfirmModel";
import { ReactComponent as MakePrimaryUser } from "../../../../assets/Icons/makePrimary.svg";
import { ReactComponent as TransferOwnership } from "../../../../assets/Icons/transferOwnership.svg";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    border: "1px solid black",
    padding: "7px",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),

    marginRight: theme.spacing(0),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      // marginLeft: theme.spacing(3),
      width: "100%",
    },
  },
  Menu: {
    "&:hover": {
      backgroundColor: "gray !important",
    },
  },
  searchIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
  },
  closeIcon: {
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "96%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  primaryChip: {
    background: "#13CF8F",
    minWidth: "101px",
    padding: "6px",
    margin: "0.3rem",
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "800px",
    width: "80px",
    marginLeft: "-5px",
  },
  primaryChipTypo: {
    fontSize: "10px",
    fontWeight: "400",
    marginY: "auto",
    display: "flex",
    color: "#FFF",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#13CF8F",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Languages = [
  "English",
  "Dutch",
  "French",
  "German",
  "Spanish",
  "Italian",
  "Portugese",
];

const Roles = ["account-manager", "location-manager"];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // height: "auto",
  // width: "auto",
  // maxheight: "auto",
  // width: "500px",
  maxHeight: "100vh",
  width: "420px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  justifyContent: "space-around",
  // overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "100vh",
  width: "420px",
  // height: "550px",
  // width: "500px",
  // height: "auto",
  // width: "auto",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  // overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const Users = ({ setSelectedTab, params, companyName }) => {
  const classes = useStyles();
  const { signOut } = useAuth();
  const { id } = useParams();
  const { t } = useTranslation();
  const [openPrimaryModel, setOpenPrimaryModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("firstName");
  const navigate = useNavigate();
  const [filterWithName, setFilterWithName] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteId, setDeleteId] = useState(null);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openLinkModel, setOpenLinkModel] = useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openResetModel, setOpenResetModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModalEditUser, setOpenModalEditUser] = React.useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [updateId, setUpdateId] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const [openOtpModel, setOpenOtpModel] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [openOneTimeLoginModel, setOpenOneTimeLoginModel] = useState(false);
  const [oneTimeLoginData, setOneTimeLoginData] = useState(null);
  const [userCompanies, setUserComapies] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openLinkedConfirmationModel, setOpenLinkedConfirmationModel] =
    useState(false);
  const [openOwnershipModel, setOpenOwnershipModel] = useState(false);
  const [roleValue, setRoleValue] = useState("All");
  const [modelType, setModelType] = useState(null);
  const [search, setSearch] = useState(false);
  const [openMakePrimaryUserModel, setOpenMakePrimaryUserModel] =
    useState(false);
  const [primaryUser, setPrimaryUser] = useState(null);

  const headCells = [
    { id: "id", alignment: "left", label: t("AccountTableID") },
    { id: "firstName", alignment: "left", label: t("AccountTableName") },
    { id: "Contact", alignment: "left", label: t("UserTableEmail") },
    { id: "Telephone number", alignment: "left", label: t("UserTableRole") },
    { id: "actions", alignment: "center", label: t("Action") },
  ];
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  /*   useEffect(() => {
    getAllLocations();
  }, []); */
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        getAllUsers();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  useEffect(() => {
    getAllUsers();
  }, [page, rowsPerPage, roleValue]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // setSelectedRow(null);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
    // setSelectedRow(null);
  };
  const resetPassword = async (row) => {
    try {
      setLoading(true);

      const res = await api.post(`auth/forgot-password`, {
        email: row.email,
      });

      if (res.status === 200) {
        toast.success(res?.data?.message, {});
        navigate("/auth/sign-in");
        // setAllCompanies(res.data.data);
      } else {
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleClick = (event, id, selectedData) => {
    setSelectedRow(selectedData);
    setUpdateId(id);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDeleteModel = () => {
    setOpenDeleteModel(false);
  };
  const handleOpenDeleteModel = async (bool, userId) => {
    //

    try {
      const res = await api.get(`user/userInCompanies/${userId}`);
      setDeleteLoading(true);
      if (res.status === 200) {
        if (res?.data?.data?.length > 0) {
          if (res?.data?.data?.length > 1) {
            setOpenLinkedConfirmationModel(true);
            setUserComapies(res?.data?.data);
            setOpenDeleteModel(false);
          } else {
            setOpenLinkedConfirmationModel(false);
            setUserComapies(res?.data?.data);
            setOpenDeleteModel(true);
          }

          setDeleteLoading(false);
        }
      }
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleCloseOtpModel = () => {
    setOpenOtpModel(false);
  };
  const handleOpenOtpModel = () => {
    setOpenOtpModel(true);
  };
  const handleCloseOneTimePasswordModel = () => {
    setOpenOneTimeLoginModel(false);
    setOneTimeLoginData(null);
  };
  const handleOpenOneTimePasswordModel = () => {
    setOpenOneTimeLoginModel(true);
  };
  const handleCloseLinkModel = () => {
    setOpenLinkModel(false);
  };
  const handleOpenLinkModel = (data) => {
    setDeleteId(data?.id);
    setOpenLinkModel(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClosePrimaryModel = () => {
    setOpenPrimaryModel(false);
  };
  const handleUnlinkAccount = async () => {
    try {
      const res = await api.patch(`/companyUser/unLink`, {
        companyId: parseInt(id),
        userId: deleteId,
      });
      if (res.status === 200) {
        setIsLoading(false);
        handleCloseLinkModel();
        toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        getAllUsers();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseLinkModel();
    }
  };

  const handleGenerateOTP = async () => {
    setOtpLoading(true);

    try {
      const res = await api.post(`/oneTimeLogin`, {
        email: selectedRow?.email,
        userId: selectedRow?.id,
      });
      if (res.status === 200 || res.status === 201) {
        setOtpLoading(false);
        handleCloseOtpModel();
        setOneTimeLoginData(res?.data?.data);
        handleOpenOneTimePasswordModel();
        //  toast.success(res?.data?.message);
        //  navigate(`/admin/accounts/${id}`);
        //  getAllUsers();
      }
    } catch (error) {
      setOtpLoading(false);

      toast.error(error?.response?.data?.message);
      handleCloseOtpModel();
    }
  };

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      // setLoading(true);
      const res = await api.get(
        `/admin/users/${id}?limit=${rowsPerPage}&pageNumber=${page + 1}`,
        {
          params: {
            searchTerm: filterWithName,
            sortBy: orderBy,
            sortOrder: order,
            role: roleValue !== "All" ? roleValue : "",
          },
        }
      );
      if (res.status === 200) {
        // setAllCompanies(res.data.data);
        setUsers(res.data.data);
        const primaryUserData = res?.data?.data?.rows?.find(
          (item) =>
            item?.CompanyUserRoles?.length > 0 &&
            item?.CompanyUserRoles[0]?.isPrimary === true &&
            item?.CompanyUserRoles[0]?.linkedUser === false
        );
        // setLoading(false);
        if (primaryUserData !== undefined) {
          setPrimaryUser(primaryUserData);
        } else {
          setPrimaryUser(null);
        }
        // setLoading(false);
        setIsLoading(false);
        setSearch(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllLocations = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/location`);
      if (res.status === 200) {
        setIsLoading(true);
        setAllLocations(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const deleteUserHandler = async () => {
    if (deleteId !== null) {
      setIsLoading(true);
      try {
        const res = await api.delete(`/user/${deleteId?.id}`);
        //  setOpenDeleteModel(false);
        if (res.status === 200) {
          setIsLoading(false);
          setOpenDeleteModel(false);
          setOpenLinkedConfirmationModel(false);
          toast.success(t("Successfully_Deleted"));
          getAllUsers();
        }
      } catch (error) {
        setIsLoading(false);
        setOpenDeleteModel(false);
        if (
          error?.response?.data?.message ===
            "No Permissions found, You need to log in again" ||
          error?.response?.data?.message ===
            "Your session has expired. Kindly log in again to continue accessing your account."
        ) {
          handleSessionOut(
            error?.response?.status,
            error?.response?.data?.message,
            handleSignOut,
            true
          );
        } else {
          handleSessionOut(
            error?.response?.status,
            error?.response?.data?.message,
            handleSignOut
          );
        }
      }
    }
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
    setSearch(true);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  const handleCloseResetModel = () => {
    setOpenResetModel(false);
  };
  const handleOpen = () => setOpenModal(true);
  const handleOpenLinkUser = () => {
    navigate(`/admin/accounts/link-user/${id}`);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    getAllUsers();
  };
  const handleOpenEditUser = () => {
    // setSelectedUser(event.target.value);

    setOpenModalEditUser(true);
  };
  const handleCloseModalEditUser = () => {
    setOpenModalEditUser(false);
    getAllUsers();
  };
  const updateUserHandler = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/user/${updateId}`, {
        role: "company-admin",
      });
      if (res.status === 200) {
        setIsLoading(false);
        toast.success("Successfully Updated");
        getAllUsers();
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };
  const handleCloseLinedConfirmationModel = () => {
    setOpenLinkedConfirmationModel(false);
  };

  const handleOpenChangeOwnershipModel = (keyValue) => {
    setModelType(keyValue);
    setOpenOwnershipModel(true);
  };
  const handleCloseChangeOwnershipModel = () => {
    setOpenOwnershipModel(false);
    handleClose();
  };
  const handleChangeFilters = (e) => {
    setRoleValue(e.target.value);
  };

  const handleOpenMakePrimaryUserModel = () => {
    setOpenMakePrimaryUserModel(true);
  };
  const handleCloseMakePrimaryUserModel = () => {
    setOpenMakePrimaryUserModel(false);
  };

  const updateCompanyPrimaryUserHandler = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/company/${id}/ownerTransfership`, {
        companyId: parseInt(id),
        userId: selectedRow?.id,
      });
      if (res.status === 200) {
        setIsLoading(false);
        getAllUsers(false);
        handleCloseMakePrimaryUserModel();
        toast.success("Successfully Updated");
      }
    } catch (error) {
      setIsLoading(false);
      handleCloseMakePrimaryUserModel();
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      {openMakePrimaryUserModel && (
        <AddPrimaryUserConfirmModel
          openPrimaryModel={openMakePrimaryUserModel}
          selectedRow={selectedRow}
          isLoading={isLoading}
          handleClosePrimaryModel={handleCloseMakePrimaryUserModel}
          updateUserHandler={updateCompanyPrimaryUserHandler}
          primaryUser={primaryUser}
          modelType={"primary"}
        />
      )}
      {openOwnershipModel && (
        <AddPrimaryUserModel
          title={
            modelType !== null &&
            (modelType === "ownership"
              ? t("Transfer_ownership")
              : t("Make_Primary_User"))
          }
          subTitle={t("Transfer_ownershipSubtext")}
          handleCloseModel={handleCloseChangeOwnershipModel}
          open={openOwnershipModel}
          selectedAccount={id}
          selectedUser={selectedRow}
          fromAccounts={true}
          getAllData={getAllUsers}
          modelType={modelType}
        />
      )}
      {openDeleteModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDeleteModel}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Userr")}
            description={`${t("delete_user_des")} ${
              deleteId?.firstName !== null && deleteId?.lastName !== null
                ? deleteId?.firstName + " " + deleteId?.lastName
                : t("this_user_text")
            }?`}
            onConfirm={() => {
              setOpenDeleteModel(false);
              deleteUserHandler();
            }}
            onCancel={handleCloseDeleteModel}
          />
        </Dialog>
      )}
      {openLinkedConfirmationModel && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "30%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={openLinkedConfirmationModel}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "50px", color: "#F8A92B" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "25px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {` ${t("company_linked")} ${userCompanies?.length - 1} ${
                    userCompanies?.length - 1 === 1
                      ? t("company_linked_Text1")
                      : t("companies_linked_Text")
                  } `}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${
                    deleteId?.firstName !== null && deleteId?.lastName !== null
                      ? deleteId?.firstName + " " + deleteId?.lastName
                      : t("this_user_text2")
                  } ${t("company_linked_desText1")} ${
                    userCompanies?.length - 1
                  } ${
                    userCompanies?.length - 1 > 1
                      ? t("companies_linked_Text")
                      : t("company_linked_Text1")
                  } ${t("company_linked_desText2")} ${
                    userCompanies?.length - 1 > 1
                      ? t("company_linked_desText3")
                      : t("company_linked_desText")
                  }`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "0px" }}
              >
                {" "}
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${t("sepecific_account_deleteText")}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "0px" }}
              >
                {" "}
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "30px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {`${t("company_linked_desText4")} ${
                    deleteId?.firstName !== null && deleteId?.lastName !== null
                      ? deleteId?.firstName + " " + deleteId?.lastName
                      : t("this_user_text")
                  }?`}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "14px",
              }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={handleCloseLinedConfirmationModel}
                label={t("No_text")}
              />
              <CommonButton
                label={t("Yes")}
                isLoading={isLoading}
                disabled={isLoading}
                onSubmit={deleteUserHandler}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {openOneTimeLoginModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openOneTimeLoginModel}
          onClose={handleCloseOneTimePasswordModel}
          aria-labelledby="responsive-dialog-title"
          sx={{ "& .MuiDialog-paper": { width: "100%" } }}
        >
          <OTPModel
            title={t("one_time_login")}
            description={t("Login_desc")}
            loginURL={oneTimeLoginData}
            //  description={`Are you sure you want to delete ${deleteId?.firstName} ${deleteId?.lastName} ?`}

            onCancel={handleCloseOneTimePasswordModel}
          />
        </Dialog>
      )}
      {openResetModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openResetModel}
          onClose={handleCloseResetModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Reset_password")}
            description={`${t("reset_password_user")} ${
              selectedRow?.firstName
            } ${selectedRow?.lastName}?  `}
            onConfirm={() => {
              setOpenResetModel(false);
              resetPassword(selectedRow);
            }}
            onCancel={handleCloseResetModel}
          />
        </Dialog>
      )}
      {openPrimaryModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openPrimaryModel}
          onClose={handleClosePrimaryModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Make_Primary_User")}
            description={`Are you sure you want to make ${selectedRow?.firstName} ${selectedRow?.lastName} primary user?`}
            onConfirm={() => {
              setOpenPrimaryModel(false);
              updateUserHandler(selectedRow?.id);
            }}
            onCancel={handleClosePrimaryModel}
          />
        </Dialog>
      )}
      {openLinkModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openLinkModel}
          onClose={handleCloseLinkModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Unlink_User")}
            description={`${t("Unlink_User_Description")} ${companyName}?`}
            onConfirm={() => {
              setOpenLinkModel(false);
              handleUnlinkAccount();
            }}
            onCancel={handleCloseLinkModel}
          />
        </Dialog>
      )}
      {openOtpModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openOtpModel}
          onClose={handleCloseOtpModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("one_time_login")}
            description={t("OtpDescription_text")}
            onConfirm={handleGenerateOTP}
            onCancel={handleCloseOtpModel}
            loading={otpLoading}
          />
        </Dialog>
      )}
      <Box sx={{ marginTop: "1rem" }}>
        <TitleHeader title={t("User")} subHeading={t("AllUser")} />
      </Box>
      {/* <Typography marginTop="1rem" variant="h2">
        {t("User")}
      </Typography>
      <Typography variant="h6">{t("AllUser")}</Typography>
      <br /> */}
      {/* <Paper> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4.5} lg={4.5}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllUsers}
            placeholder={t("FilterUser")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Select
            sx={{ backgroundColor: "#FFF" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            fullWidth
            value={roleValue}
            label=""
            defaultValue={"domain"}
            onChange={handleChangeFilters}
            //  error={domainRequired}
          >
            <MenuItem value="All">{t("All")}</MenuItem>

            <MenuItem value="primaryUser">{t("PrimaryUsers")}</MenuItem>
            <MenuItem value="company-admin">{t("company_admin")}</MenuItem>
            <MenuItem value="custom">{t("custom_role")}</MenuItem>
          </Select>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3.5}
          lg={3.5}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            alignment={true}
            onSubmit={getAllUsers}
            label={t("Search")}
          />
          {/* <CommonButton
            alignment={true}
            onSubmit={handleOpen}
            label={t("AddNewUser")}
          /> */}
          <CommonButton
            alignment={true}
            onSubmit={handleOpenLinkUser}
            label={t("Link_user")}
          />
        </Grid>
        {/* <Grid item xs={2} sm={1} md={1}> */}
        <Modal
          open={openModal}
          // onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container>
            <Grid item>
              <Box sx={style}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    textAlign: "center",
                  }}
                >
                  {t("Add_New_User")}
                </Typography>
                <AddUser
                  locations={allLocations}
                  setSelectedTab={setSelectedTab}
                  Language={Languages}
                  Role={Roles}
                  id={params}
                  onCancel={handleCloseModal}
                />
              </Box>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
      {/* </Grid> */}
      {/* </Paper> */}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {users?.rows?.length > 0 ? (
            <>
              {" "}
              <TableContainer sx={{ marginTop: "2rem" }} component={Paper}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {
                      users?.rows?.length > 0 &&
                        users?.rows?.map((row, index) => {
                          return (
                            <CustomizedTableRow
                              // hover
                              tabIndex={-1}
                              key={`${row.id}-${index}`}
                            >
                              <CustomizedTableCell sx={{ cursor: "pointer" }}>
                                {row.id}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                sx={{ cursor: "pointer" }}
                                align="left"
                              >
                                {row.firstName !== null
                                  ? row.firstName +
                                    " " +
                                    (row.lastName !== null ? row.lastName : "")
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="left">
                                {row.email}
                              </CustomizedTableCell>

                              <CustomizedTableCell align="left">
                                {row?.CompanyUserRoles?.length > 0 &&
                                row?.CompanyUserRoles[0]?.isPrimary === true &&
                                row?.CompanyUserRoles[0]?.linkedUser ==
                                  false ? (
                                  <Item className={classes.primaryChip}>
                                    <Typography
                                      className={classes.primaryChipTypo}
                                    >
                                      {t("Primary_text")}
                                    </Typography>
                                  </Item>
                                ) : row?.CompanyUserRoles[0]?.name !== null ? (
                                  (
                                    row?.CompanyUserRoles[0]?.name
                                      .charAt(0)
                                      .toUpperCase() +
                                    row?.CompanyUserRoles[0]?.name?.slice(1)
                                  ).replaceAll("-", " ")
                                ) : (
                                  "--"
                                )}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                                padding="none"
                                align="center"
                              >
                                <>
                                  {/* <CustomWidthTooltip title={t("EditUser")}>
                                      <IconButton
                                        aria-label="edit"
                                        size="large"
                                        onClick={() => {
                                          handleOpenEditUser();
                                          setSelectedUser(row);
                                        }}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </CustomWidthTooltip> */}

                                  {row?.CompanyUserRoles?.length > 0 &&
                                    row?.CompanyUserRoles[0]?.linkedUser && (
                                      <CustomWidthTooltip
                                        title={t("Un_link_Account")}
                                      >
                                        <IconButton
                                          aria-label="edit"
                                          size="large"
                                          onClick={() => {
                                            handleOpenLinkModel(row);
                                          }}
                                        >
                                          <LinkOffIcon
                                          /*  sx={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                        }} */
                                          />
                                        </IconButton>
                                      </CustomWidthTooltip>
                                    )}
                                  {row?.CompanyUserRoles?.length > 0 &&
                                    row?.CompanyUserRoles[0]?.linkedUser ==
                                      false && (
                                      <>
                                        {otpLoading &&
                                        selectedRow?.id === row?.id ? (
                                          <CircularProgress
                                            style={{
                                              width: "20px",
                                              height: "20px",
                                              marginLeft: "8px",
                                            }}
                                          />
                                        ) : (
                                          <IconButton
                                            onClick={(e) =>
                                              handleClick(e, row.id, row)
                                            }
                                          >
                                            <MoreVertIcon />
                                          </IconButton>
                                        )}

                                        <Menu
                                          anchorEl={anchorEl}
                                          open={Boolean(anchorEl)}
                                          onClose={handleClose}
                                        >
                                          <MenuItem
                                            onClick={() => {
                                              setDeleteId(selectedRow);
                                              handleOpenDeleteModel(
                                                true,
                                                selectedRow?.id
                                              );
                                              closeMenu();
                                              handleClose();
                                            }}
                                          >
                                            <DeleteIcon
                                              sx={{
                                                color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography>
                                              {t("Delete")}
                                            </Typography>
                                          </MenuItem>
                                          {/* <Divider variant="middle" />
                                          <MenuItem
                                            onClick={() => {
                                              closeMenu();
                                              handleClose();
                                              setOpenPrimaryModel(true);
                                               updateUserHandler(selectedRow.id);
                                            handleOpenModal(row?.id);
                                            }}
                                          >
                                            <PersonIcon
                                              sx={{
                                                color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography>
                                              {t("Make_Primary_User")}
                                            </Typography>
                                          </MenuItem> */}
                                          <Divider variant="middle" />
                                          <MenuItem
                                            onClick={() => {
                                              closeMenu();
                                              handleClose();
                                              setOpenResetModel(true);
                                            }}
                                          >
                                            <PasswordIcon
                                              sx={{
                                                color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography
                                              loading={loading}
                                              type="submit"
                                              fullWidth
                                              variant="contained"

                                              // disabled={isSubmitting}
                                            >
                                              {t("Reset_password")}
                                            </Typography>
                                          </MenuItem>
                                          <Divider variant="middle" />
                                          <MenuItem
                                            onClick={() => {
                                              closeMenu();
                                              handleClose();
                                              handleGenerateOTP();
                                            }}
                                          >
                                            <PersonIcon
                                              sx={{
                                                color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography>
                                              {t("one_time_login")}
                                            </Typography>
                                          </MenuItem>

                                          {selectedRow?.CompanyUserRoles
                                            ?.length > 0 &&
                                            (selectedRow?.CompanyUserRoles[0]
                                              ?.isPrimary ? (
                                              <>
                                                <Divider variant="middle" />
                                                <MenuItem
                                                  onClick={() => {
                                                    closeMenu();
                                                    handleOpenChangeOwnershipModel(
                                                      "ownership"
                                                    );
                                                    handleClose();
                                                  }}
                                                >
                                                  <TransferOwnership
                                                    style={{
                                                      color: "#545353",
                                                      paddingRight: "5px",
                                                      width: "22px",
                                                      height: "22px",
                                                    }}
                                                  />
                                                  <Typography>
                                                    {t("change_ownership")}
                                                  </Typography>
                                                </MenuItem>
                                              </>
                                            ) : (
                                              selectedRow?.status !==
                                                "pending" && (
                                                <>
                                                  {" "}
                                                  <Divider variant="middle" />
                                                  <MenuItem
                                                    onClick={() => {
                                                      closeMenu();
                                                      handleOpenMakePrimaryUserModel(
                                                        "primary"
                                                      );
                                                      handleClose();
                                                      // handleOpenModal(row?.id);
                                                    }}
                                                  >
                                                    <MakePrimaryUser
                                                      style={{
                                                        color: "#545353",
                                                        paddingRight: "5px",
                                                        width: "22px",
                                                        height: "22px",
                                                      }}
                                                    />
                                                    <Typography>
                                                      {t("Make_Primary_User")}
                                                    </Typography>
                                                  </MenuItem>
                                                </>
                                              )
                                            ))}
                                        </Menu>
                                      </>
                                    )}
                                </>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          );
                        })
                      // ) : !isLoading ? (
                      //   <Typography variant="h3" sx={{ textAlign: "center" }}>
                      //     No User Found
                      //   </Typography>
                      // ) : (
                      //   <></>

                      // style={{ height: 53 * emptyRows }}
                    }
                    {emptyRows > 0 && (
                      <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                    )}
                    <Modal
                      open={openModalEditUser}
                      // onClose={handleCloseModalEditUser}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Grid container>
                        <Grid item>
                          <Box sx={styleEditUser}>
                            <Typography
                              sx={{
                                fontWeight: 700,
                                fontSize: "20px",
                                textAlign: "center",
                              }}
                            >
                              {t("EditUser")}
                            </Typography>

                            <EditUser
                              locations={allLocations}
                              user={selectedUser}
                              Role={Roles}
                              Language={Languages}
                              onCancel={handleCloseModalEditUser}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Modal>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <NotFound text={t("User_Not_Found")} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Users;
