import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { api } from "../../contexts/JWTContext";
import { toast } from "react-toastify";
import { languageOptions } from "../../assets/defaultLanguage";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 30px;
    height: 30px;
  }
`;

const Flag = styled.img`
  border-radius: 100%;
  width: 30px;
  height: 30px;
`;

function NavbarLanguages() {
  const { i18n } = useTranslation();

  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { t } = useTranslation();
  const [userLanguage, setUserLanguage] = React.useState();
  const stateData = JSON.parse(localStorage.getItem("user"));
  const languageData = localStorage.getItem("i18nextLng");

  let userLang = "";

  useEffect(() => {
    if (languageData === "" || languageData === null || languageData === "en") {
      setUserLanguage(languageOptions["en"]);
      localStorage.setItem("i18nextLng", "en");
      i18n.languages = ["en"];
      i18n.language = "en";
      i18n.translator.language = "en";
      i18n.changeLanguage("en");
    } else {
      setUserLanguage(languageOptions[languageData]);
      localStorage.setItem("i18nextLng", languageData);
      i18n.languages = [languageData];
      i18n.language = languageData;
      i18n.translator.language = languageData;
    }
  }, []);

  const { user } = useAuth();
  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleLanguageChange = async (language, languageKey) => {
    localStorage.setItem("i18nextLng", languageKey);
    closeMenu();
    i18n.changeLanguage(languageKey);
    i18n.languages = [languageKey];
    i18n.language = languageKey;
    i18n.translator.language = languageKey;
    setUserLanguage(languageOptions[languageKey]);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("Languages")}>
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : ""}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
          sx={{ fontSize: "8px" }}
        >
          <Flag src={userLanguage?.icon} alt={userLanguage?.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language) => (
          <MenuItem
            key={language}
            onClick={() =>
              handleLanguageChange(languageOptions[`${language}`], language)
            }
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguages;
