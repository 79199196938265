import React, { useEffect, useState, useRef } from "react";
import { api } from "../../../../contexts/JWTContext.js";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Autocomplete,
  TextField,
  createFilterOptions,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import LogoImageModal from "./UploadModels/CoverImageModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { languagesData } from "../../../../assets/defaultLanguage/index.js";
import dayjs from "dayjs";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton.js";
import Loader from "../../../../components/Loaders/Loader";
import Collapsible from "../../../../components/CustomComponents/Collapsible.js";
import { useLocation, useNavigate } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
//import EmotionAiFilterModal from "./../../Scheduler/FilterModel";
import FilterModal from "../../../../components/Models/GoogleStatsFilterModel";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../EmotionAi/Style/style.js";
import DatePickerComponent from "../Components/RangePickerResults";

const FetchModel = ({
  open,
  title,
  handleCloseNewRequestModel,
  others,
  setOthers,
  companyUser,
  setCompanyUser,
  recipientError,
  setRecipientError,
  recipientEmailError,
  setRecipientEmailError,
  recipientEmail,
  setRecipientEmail,
  recipientValue,
  setRecipientValue,
  setSelectedCompanyUsers,
  selectedCompanyUsers,
  totalEmailsArr,
  isModalEmailsSent,
  handleAddNewUserEmailsChange,
  addNewUserEmials,
  handleResetOthers,
  handleButtonClick,
  handleFileUpload,
  selectedFile,
  fileInputRef,
  handleReopenModal,
  handleRemoveFile,
  exportReportLoading,
  setEmailSubject,
  emailSubject,
  emailBody,
  setEmailBody,
  emailTitle,
  setEmailTitle,
  handleEmailGoogleStatsReport,
  setExportReportLoading,
  setDateRange,
  dateRange,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  allLocations,
  allGroups,
  startDate,
  endDate,
  countries,
  defaultLocation,
  locationAddPermission,
  locationViewPermission,
  selectAll,
  setSelectAllLocations,
  selectAllLocations,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,
  dateRangeSchedule,
  setDateRangeSchedule,
  startDateSchedule,
  handleChangeStartDateSchedule,
  endDateSchedule,
  handleChangeEndDateSchedule,
  scheduleCountries,
  selectAllScheduleCountries,
  setSelectAllScheduleCountries,
  selectAllScheduleLocations,
  setSelectAllScheduleLocations,
  selectAllScheduleGroups,
  setSelectAllScheduleGroups,
  setCallApi,
  resetStart,
  resetEnd,
  setScheduleCountries,
  oldScheduleCountries,
  setOldScheduleCountries,
  getCountryWiseLocations,
  oldScheduleGroups,
  removeDuplicateLocaions,
  filterArrayById,
  setOldScheduleGroups,
  filterArrayByIdForGroups,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  disabledStartDate,
  setDisabledStartDateSchedule,
  setUploadedImages,
  setImages,
  uploadedImages,
  images,
  subscriptionDate,
  dateFormat,
  setDateFormat,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const textArea = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const currentPath = location.pathname.replace(/\/+$/, "");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [expandedPanels, setExpandedPanels] = useState(["configureReport"]);
  const [dateRangeError, setDateRangeError] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [selectAllUsers, setSelectAllUsers] = useState(false);
  const [openModalFilter, setOpenFilterModel] = useState(false);
  const [selectAllFeatures, setSelectAllFeatures] = useState(false);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);
  const [selectedFileForImage, setSelectedFileForImage] = useState(null);

  const [selectAllFiltersLocations, setSelectAllFiltersLocations] =
    useState(selectAllLocations);
  const [selectAllFiltersGroups, setSelectAllFiltersGroups] =
    useState(selectAll);
  const [selectedFileImage, setSelectedImageFile] = useState(null);

  useEffect(() => {
    getAllUsers();
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
  }, [open]);

  const dateFormats = [
    { label: "D/M/YYYY" },
    { label: "YYYY-MM-DD" },
    { label: "DD/MM/YYYY" },
    { label: "MM/DD/YYYY" },
    { label: "DD.MM.YYYY" },
  ];

  const handleChangeCompanyUser = (e) => {
    setCompanyUser(e.target.checked);
    if (e.target.checked === false) {
      setSelectedCompanyUsers([]);
    }
  };
  const handleChangeOthers = (e) => {
    setOthers(e.target.checked);
    if (e.target.checked === false) {
      handleResetOthers();
    }
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyUserRoles?companyId=${companyData?.id}&pageNumber=1&limit=500`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };
  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleOpenFilterModel = () => {
    setOpenFilterModel(true);
  };
  const handleCloseFilterModel = (resetValue) => {
    setOpenFilterModel(false);
    if (resetValue !== true) {
      setSelectAllFiltersLocations(selectAllLocations);
      setSelectAllFiltersGroups(selectAll);
    }
  };
  const handleChangeCountries = (value) => {
    if (value?.length > 0) {
      if (value.find((option) => option.id === 0)) {
        // Check if all options are selected
        if (countries?.length === scheduleCountries?.length) {
          setScheduleCountries([]);
          setSelectAllScheduleCountries(false);
        } else {
          setScheduleCountries(true);
          setSelectAllScheduleCountries(countries);
          const googleLinkedLocations = allLocations?.filter(
            (item) =>
              item?.status === "approved" &&
              item.socialLink.length > 0 &&
              item.socialLink.some(
                (link) =>
                  link?.type === "google" &&
                  link?.statusOfGoogleInsight === "Done"
              )
          );
          setScheduleLocations(googleLinkedLocations);
        }
      } else {
        setScheduleCountries(value);

        handleChangeLocationsBySelectingCountries(value);
        if (value?.length === countries?.length) {
          setSelectAllScheduleCountries(true);
        } else {
          setSelectAllScheduleCountries(false);
        }
      }
    } else {
      setScheduleCountries([]);
      // setLocation([]);
    }
  };
  const isItemFoundInGroups = (locationId) => {
    let isFound = false;

    if (scheduleGroups?.length > 0) {
      scheduleGroups?.map((item) => {
        item?.groups?.map((itemTwo) => {
          if (itemTwo?.locationId === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeLocationsBySelectingCountries = (value) => {
    let newLocations = [...scheduleLocations];
    const results = oldScheduleCountries.filter(
      ({ countryName: id1 }) =>
        !value.some(({ countryName: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((itemOne) => {
        itemOne?.locationsArray?.map((locationItem) => {
          allLocations?.map((locItem) => {
            if (
              locationItem?.id === locItem?.id &&
              isItemFoundInGroups(locItem?.id) === false
            ) {
              newLocations?.push(locItem);
            } else {
              // setSelectAll(false);
              results?.map((item) => {
                item?.locationsArray?.map((locItemTwo) => {
                  scheduleLocations?.map((locationItem) => {
                    const filteredArray = filterArrayById(
                      value,
                      locationItem?.id
                    );
                    if (
                      locItemTwo?.id === locationItem?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInGroups(locationItem?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== locationItem?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((item) => {
        item?.locationsArray?.map((locItem) => {
          scheduleLocations?.map((locationItem) => {
            if (
              locItem?.id === locationItem?.id &&
              isItemFoundInGroups(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);

    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );

    setScheduleLocations(googleLinkedLocations);

    setOldScheduleCountries(value);
  };
  const isItemFoundInCountries = (locationId) => {
    let isFound = false;
    if (scheduleCountries?.length > 0) {
      scheduleCountries?.map((item) => {
        item?.locationsArray?.map((itemTwo) => {
          if (itemTwo?.id === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeGroups = (value, autoSelectValue) => {
    setScheduleGroups(value);

    let newLocations = [...scheduleLocations];
    const results = oldScheduleGroups.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations?.push(locationItem);
            } else {
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  scheduleLocations?.map((location) => {
                    const filteredArray = filterArrayByIdForGroups(
                      value,
                      location?.id
                    );

                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInCountries(location?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          scheduleLocations?.map((locationItem) => {
            if (
              groupItem?.locationId === locationItem?.id &&
              isItemFoundInCountries(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);
    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    setScheduleLocations(googleLinkedLocations);
    setOldScheduleGroups(value);
  };

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveFileImage = () => {
    setSelectedImageFile(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              height: "550px",
            },
          }}
          open={open}
        >
          <DialogTitle className={classes.emailReportTitle}>
            {title}
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container>
                <Box sx={{ marginTop: "12px", width: "100%" }}>
                  <Collapsible
                    title={t("configure_report")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureReport")}
                    id={"configureReport"}
                    ComponentToRender={
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container sx={{ marginLeft: "-10px" }}>
                              <Grid item xs={3}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    onChange={handleChangePdfChecked}
                                    checked={pdfChecked}
                                  />
                                  <Typography>{"PDF"}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={3}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Checkbox
                                    onChange={handleChangeXlsChecked}
                                    checked={xlsChecked}
                                  />
                                  <Typography>{"XLS"}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6}></Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.emailReportLabel}>
                              {t("upload_logo")}
                            </Typography>

                            <Box mt={3} ml={-3}>
                              <CommonButton
                                onSubmit={handleOpenLogoUploadModal}
                                label={t("Select_logo")}
                              />
                            </Box>
                            <Box>
                              {selectedFileImage ? (
                                <Box className={classes.fileBoxStyle}>
                                  <Typography
                                    // onClick={handleReopenModal}
                                    className={classes.selectedFileTypo}
                                  >
                                    {selectedFileImage?.name}
                                  </Typography>
                                  <IconButton
                                    className={classes.removeFileButton}
                                    onClick={() => handleRemoveFileImage()}
                                  >
                                    <DeleteIcon
                                      fontSize="small"
                                      className={classes.deleteIcon}
                                    />
                                  </IconButton>
                                </Box>
                              ) : (
                                <p>{t("")}</p>
                              )}
                            </Box>
                          </Grid>
                          {/*   <Grid
                            item
                            xs={12}
                            sm={4.5}
                            md={4.5}
                            lg={4.5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_range")}
                            </Typography>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={dateRangeSchedule?.range}
                              onChange={(e) => {
                                handleDateRangChange(e, false);
                              }}
                              fullWidth
                              //  error={dateRangeError}
                              helperText={dateRangeError ? t("Field_req") : ""}
                            >
                              <MenuItem value="month">
                                {t("monthValue")}
                              </MenuItem>
                              <MenuItem value="thisMonth">
                                {" "}
                                {t("This_Month")}
                              </MenuItem>
                              <MenuItem value="12months">
                                {t("12months")}
                              </MenuItem>
                              <MenuItem value="custom">
                                {t("custom_text")}
                              </MenuItem>
                            </Select>
                          </Grid> */}

                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_language")}
                            </Typography>
                            <Select
                              required
                              style={{ width: "100%" }}
                              key={languageSchedular?.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={languageSchedular?.value}
                              onChange={(e, value) => {
                                const userLang = languagesData?.find(
                                  (item) => item?.value === e.target.value
                                );
                                handleChangeLanguageForScheduler(userLang);
                              }}
                            >
                              {languagesData.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.language}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_format")}
                            </Typography>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dateFormats}
                              value={dateFormat}
                              onChange={(e, value) => {
                                setDateFormat(value);
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t("date_format")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={2}
                            lg={2}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ marginTop: "25px" }}>
                              <CommonButton
                                icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                                onSubmit={handleOpenFilterModel}
                                label={t("Filters")}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={12} sm={12} md={12} lg={12} mt={3}>
                            <Typography className={classes.emailReportSubLabel}>
                              {t("Select_Date_Range")}
                            </Typography>
                          </Grid>

                          <DatePickerComponent
                            handleChangeStartDate={
                              handleChangeStartDateSchedule
                            }
                            handleChangeEndDate={handleChangeEndDateSchedule}
                            startDate={
                              startDateSchedule !== null
                                ? startDateSchedule
                                : null
                            }
                            endDate={
                              endDateSchedule !== null ? endDateSchedule : null
                            }
                            dateRange={dateRangeSchedule}
                            setDateRange={setDateRangeSchedule}
                            fromFilters={true}
                            rangeValue={"between"}
                            removePadding={false}
                            fromGoogleInsight={true}
                            setEmailBody={setEmailBody}
                            oneYearAgoDate={disabledStartDate}
                            subscriptionDate={subscriptionDate}
                          />
                        </Grid>
                        {/* {dateRange === "custom" &&
                          currentPath !== "/user/reviews" && (
                            <Grid
                              container
                              spacing={2}
                              sx={{ marginTop: "8px" }}
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Fromm")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    value={
                                      startDateRangeSchedule !== null
                                        ? dayjs(startDateRangeSchedule)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    disableFuture
                                    onChange={(e) => {
                                      const newDate = e
                                        ? dayjs(e).startOf("day").toDate()
                                        : null;
                                      handleChangeRangeStartDateSchedule(
                                        newDate
                                      );
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    maxDate={dayjs().endOf("day")}
                                    renderInput={(params) => (
                                      <TextField
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                        error={startDateError}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                sx={{ paddingTop: "0px" }}
                              >
                                <Typography
                                  className={classes.emailReportSubLabel}
                                >
                                  {t("Too")}
                                </Typography>

                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    disableFuture
                                    value={
                                      endDateRangeSchedule !== null
                                        ? dayjs(endDateRangeSchedule)
                                        : null
                                    }
                                    format="DD/MM/YYYY"
                                    onChange={(e) => {
                                      handleChangeRangeEndDateSchedule(e);
                                    }}
                                    className={classes.whiteBackground}
                                    minDate={dayjs("2005-01-01")}
                                    renderInput={(params) => (
                                      <TextField
                                        error
                                        autoComplete="off"
                                        fullWidth
                                        {...params}
                                        sx={{ background: "#FFFF" }}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          )} */}
                      </Grid>
                    }
                  />
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                  <Collapsible
                    title={t("configure_emaill")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureEmail")}
                    id={"configureEmail"}
                    ComponentToRender={
                      <Grid container>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("select_user_type")}
                          </Typography>
                          <Grid container sx={{ marginLeft: "-10px" }}>
                            <Grid item xs={3}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeCompanyUser}
                                  checked={companyUser}
                                />
                                <Typography>{t("company_users")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeOthers}
                                  checked={others}
                                />
                                <Typography>{t("others")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                          {companyUser && users?.length > 0 && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("recipients")}
                              </Typography>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="tags-outlined"
                                options={users?.length > 0 ? users : []}
                                getOptionLabel={(option) =>
                                  option?.User?.firstName !== null &&
                                  option?.User?.lastName !== null
                                    ? option?.User?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                      option?.User?.firstName.slice(1) +
                                      " " +
                                      (option?.User?.lastName !== null &&
                                      option?.User?.lastName !== ""
                                        ? option?.User?.lastName
                                            .charAt(0)
                                            .toUpperCase() +
                                          option?.User?.lastName.slice(1) +
                                          " " +
                                          "(" +
                                          option?.User?.email +
                                          ")"
                                        : "")
                                    : option?.User?.email
                                }
                                onChange={(event, value) => {
                                  if (value.find((option) => option.id === 0)) {
                                    if (
                                      users?.length ===
                                      selectedCompanyUsers?.length
                                    ) {
                                      setSelectedCompanyUsers([]);
                                      setSelectAllUsers(false);
                                    } else {
                                      setSelectAllUsers(true);
                                      setSelectedCompanyUsers(users);
                                    }
                                  } else {
                                    setSelectedCompanyUsers(value);
                                    if (value?.length === users?.length) {
                                      setSelectAllUsers(true);
                                    } else {
                                      setSelectAllUsers(false);
                                    }
                                  }
                                }}
                                value={selectedCompanyUsers}
                                sx={{ minWidth: 300 }}
                                renderOption={(props, option, { selected }) => {
                                  if (option.id === 0) {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selectAll}
                                        />
                                        {option?.firstName}
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selected || selectAllUsers}
                                        />
                                        {option?.User?.firstName !== null &&
                                        option?.User?.lastName !== null
                                          ? option?.User?.firstName
                                              .charAt(0)
                                              .toUpperCase() +
                                            option?.User?.firstName.slice(1) +
                                            " " +
                                            (option?.User?.lastName !== null &&
                                            option?.User?.lastName !== ""
                                              ? option?.User?.lastName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                option?.User?.lastName.slice(
                                                  1
                                                ) +
                                                " " +
                                                "(" +
                                                option?.User?.email +
                                                ")"
                                              : "")
                                          : option?.User?.email}
                                      </li>
                                    );
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (users?.length > 0) {
                                    return [
                                      { id: 0, firstName: t("Select_All") },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_user")}
                                  />
                                )}
                              />
                            </>
                          )}
                          {others && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Email_Address")}
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                multiline
                                rows={5}
                                maxRows={10}
                                ref={textArea}
                                variant="outlined"
                                placeholder={t("Enter_recipients_email_here")}
                                sx={{ marginBottom: "12px" }}
                                value={addNewUserEmials}
                                fullWidth
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddNewUserEmailsChange(
                                      event.target.value + ","
                                    );
                                  }
                                }}
                                onChange={(e) =>
                                  handleAddNewUserEmailsChange(e.target.value)
                                }
                              />
                              <Box className={classes.reviewsubHeadBox}>
                                {totalEmailsArr?.length > 5000 ? (
                                  <Typography
                                    className={classes.reviewsubHeadTextError}
                                  >
                                    {t("max_upload_text")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.reviewsubHeadText}
                                  >
                                    {t("send_email_text_emotionAi") +
                                      " " +
                                      totalEmailsArr?.length +
                                      " " +
                                      t("email_addresses")}
                                  </Typography>
                                )}
                              </Box>

                              <Box>
                                <input
                                  type="file"
                                  id="file-email"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={handleFileUpload}
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                />

                                <Box mt={3} ml={-3}>
                                  <CommonButton
                                    onSubmit={handleButtonClick}
                                    label={t("Browse_File")}
                                  />
                                </Box>

                                <Box>
                                  {selectedFile ? (
                                    <Box className={classes.fileBoxStyle}>
                                      <Typography
                                        onClick={handleReopenModal}
                                        className={classes.selectedFileTypo}
                                      >
                                        {selectedFile}
                                      </Typography>
                                      <IconButton
                                        className={classes.removeFileButton}
                                        onClick={() => handleRemoveFile()}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          className={classes.deleteIcon}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <p>{t("")}</p>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("subjectss")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_subject")}
                            defaultValue={emailSubject}
                            fullWidth
                            onChange={(e) => {
                              setEmailSubject(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("body")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_body_text")}
                            value={emailBody}
                            fullWidth
                            onChange={(e) => {
                              setEmailBody(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>
              </Grid>
            )}
          </DialogContent>

          <DialogActions sx={{ marginRight: "12px" }}>
            <CommonButton
              label={t("Cancel")}
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
            />
            <CommonButton
              label={t("send")}
              onSubmit={handleEmailGoogleStatsReport}
              disabled={
                !(companyUser && selectedCompanyUsers.length > 0) &&
                !(others && addNewUserEmials.includes(","))
              }
              /*  disabled={
                pdfChecked === false && xlsChecked === false
                  ? true
                  : companyUser === false && others === false
                  ? true
                  : selectedCompanyUsers?.length===0 &&
                    totalEmailsArr?.length===0
                  ? true
                  : fromHeader &&
                    sectionFormat === "specificReport" &&
                    selectedFeatures?.length===0
                  ? true
                  : exportReportLoading === true
                  ? true
                  : emailSubject === ""
                  ? true
                  : false
              } */
              isLoading={exportReportLoading}
            />
          </DialogActions>
          {openModalFilter && (
            <FilterModal
              locations={scheduleLocations}
              allLocations={allLocations}
              //  handleChangeLocation={handleChangeLocation}
              startDate={startDateSchedule}
              endDate={endDateSchedule}
              handleChangeStartDate={handleChangeStartDateSchedule}
              handleChangeEndDate={handleChangeEndDateSchedule}
              selectedCountries={scheduleCountries}
              selectAllCountries={selectAllScheduleCountries}
              setSelectAllCountries={setSelectAllScheduleCountries}
              //  resetHandler={resetHandler}
              openModalFilter={openModalFilter}
              handleCloseModalFilter={handleCloseFilterModel}
              allGroups={allGroups}
              setSelectedGroups={setScheduleGroups}
              selectedGroups={scheduleGroups}
              handleChangeGroups={handleChangeGroups}
              selectAllLocations={selectAllScheduleLocations}
              setSelectAllLocations={setSelectAllScheduleLocations}
              setSelectAllGroups={setSelectAllScheduleGroups}
              selectAll={selectAllScheduleGroups}
              setCallApi={setCallApi}
              countries={countries}
              resetStart={resetStart}
              resetEnd={resetEnd}
              locationAddPermission={true}
              locationViewPermission={true}
              defaultLocation={defaultLocation}
              setLocation={setScheduleLocations}
              setSelectedCountries={setScheduleCountries}
              oldCountries={oldScheduleCountries}
              oldGroup={oldScheduleGroups}
              getCountryWiseLocations={getCountryWiseLocations}
              handleChangeCountries={handleChangeCountries}
              setDateRange={setDateRangeSchedule}
              dateRange={dateRangeSchedule}
              openFrom={"download"}
              setEmailBody={setEmailBody}
              disabledStartDate={disabledStartDate}
              setDisabledStartDateSchedule={setDisabledStartDateSchedule}
              subscriptionDate={subscriptionDate}
            />
          )}
        </Dialog>
      </div>
      {openLogoUploadModal && (
        <LogoImageModal
          title={t("upload_logo")}
          handleClose={handleCloseLogoUploadModal}
          open={openLogoUploadModal}
          selectedImages={images}
          setSelectedImages={setImages}
          setSelectedFile={setSelectedImageFile}
          setUploadedImages={setUploadedImages}
          uploadedImages={uploadedImages}
        />
      )}
    </>
  );
};

export default FetchModel;
