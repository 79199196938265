import {
  IconButton,
  Typography,
  Grid,
  Box,
  Divider,
  Modal,
  TextField,
  Alert,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { makeStyles } from "@mui/styles";
import { useMemo, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import RemoveIcon from "@mui/icons-material/Remove";
import DatePickerComponent from "./DatePickerComponent";

const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90vh",
  width: "800px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  overflow: "auto",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#1B2430",
    backgroundColor: "#f5f5f5",

    "& .MuiPickersLayout-root-fgPIAm ": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiPickersLayout-root": {
      backgroundColor: " #f5f5f5",
    },
    "& .MuiList-root": {
      width: "100%",
      textAlign: "-webkit-center",
    },

    "& .MuiList-root-fLOhBn": {
      width: "100%",
      textAlign: "-webkit-center",
    },
    "& .MuiDialogActions-root": {
      display: "none",
    },
  },
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainerHeader: {
    width: "100%",
    justifyContent: "space-between",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
    alignItems: "end",
    textAlign: "end",
    padding: "8px",
  },
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  toggleChecked: {
    "& .MuiSwitch-thumb": {
      color: "#EBEBEC",
      //  transform:'translateX(25px) !important'
    },
  },
  toggleUnChecked: {
    "& .MuiSwitch-thumb": {
      color: "#06BDFF",
      //  transform:'translateX(25px) !important'
    },
  },
  switchTypo: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#1B2430",
    paddingLeft: "0px",
  },
}));

const SpecialHoursDateRangePickerModal = ({
  closeModal,
  handleSave,
  setIsLoading,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [displayInfoMessage, setDisplayInfoMessage] = useState(false);
  const [dates, setDates] = useState({
    startDate: dayjs(),
    endDate: dayjs(),
  });
  const [dateRange, setDateRange] = useState({
    startDate: new Date(dates?.startDate),
    endDate: new Date(dates?.endDate),
  });

  const isButtonDisabled = useMemo(() => {
    if (opened) {
      return (
        startTime === null ||
        endTime === null ||
        dayjs(dates.endDate).isBefore(dayjs(dates.startDate))
      );
    } else {
      return dayjs(dates.endDate).isBefore(dayjs(dates.startDate));
    }
  }, [opened, startTime, endTime, dates]);

  const handleChangeStartDate = (newDate) => {
    setDates((prev) => ({ ...prev, startDate: dayjs(newDate) }));
    setDisplayInfoMessage(false);
  };
  const handleChangeEndDate = (newDate) => {
    setDates((prev) => ({ ...prev, endDate: dayjs(newDate) }));
    setDisplayInfoMessage(false);
  };

  const onSubmit = () => {
    if (handleCheckRangeLimit() === true) {
      setDisplayInfoMessage(false);
      setIsLoading(true);
      handleSave(dates, !opened, startTime, endTime);
    } else {
      setDisplayInfoMessage(true);
    }
  };

  const handleChangeOpen = (e) => {
    setOpened(e.target.checked);
    if (e.target.checked == false) {
      setStartTime(null);
      setEndTime(null);
    }
  };
  const handleChangeStartTime = (e) => {
    setStartTime(e);
    setDisplayInfoMessage(false);
  };
  const handleChangeEndTime = (e) => {
    setEndTime(e);
    setDisplayInfoMessage(false);
  };
  const handleCheckRangeLimit = () => {
    if (dates?.startDate && dates?.endDate) {
      const start = new Date(dates.startDate);
      const end = new Date(dates.endDate);
      const timeDifference = end.getTime() - start.getTime();
      const dayDifference = timeDifference / (1000 * 3600 * 24);
      console.log(dayDifference, "dayDifference");
      if (dayDifference >= 90) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "700px",
          height: "500px",
          borderRadius: "4px",
        },
      }}
      fullScreen
      open={true}
    >
      <Grid container sx={{ padding: "12px" }}>
        <Grid
          xs={12}
          sm={11.5}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.mainContainerHeading}>
            {t("add_date_range")}
          </Typography>
        </Grid>
        <Grid xs={0.5} sm={0.5} md={0.5} lg={0.5}>
          <IconButton onClick={() => closeModal()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{ padding: "12px", height: "400px", overflow: "auto" }}>
        {" "}
        <Grid container>
          <DatePickerComponent
            handleChangeStartDate={handleChangeStartDate}
            handleChangeEndDate={handleChangeEndDate}
            startDate={dates.startDate}
            endDate={dates.endDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            fromFilters={true}
            removePadding={false}
            rangeValue={"between"}
          />

          <Grid item xs={12} sm={12} md={12} lg={12} mt={3} mb={3}>
            <Typography className={classes.switchTypo}>
              {" "}
              <Switch
                className={
                  opened === false
                    ? classes.toggleChecked
                    : classes.toggleUnChecked
                }
                checked={opened}
                onChange={(e) => handleChangeOpen(e)}
              />
              {opened === true ? t("Open") : t("Closed")}
            </Typography>
          </Grid>

          {opened === true && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid container>
                <Grid item xs={5} sm={5} md={5} lg={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <DemoContainer components={["TimePicker"]}> */}
                    <TimePicker
                      sx={{ width: "100%" }}
                      ampm={false}
                      /*  classes={{
                            root: classes.root,
                          }} */
                      label={t("select_openTime")}
                      value={startTime !== null ? dayjs(startTime) : null}
                      onChange={(e) => {
                        handleChangeStartTime(e);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} style={{ width: "100%" }} />
                      )}
                    />
                    <style>
                      {`
           .MuiDialogActions-root {
            display: none;
          }
         
        `}
                    </style>
                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  sx={{ textAlign: "center" }}
                >
                  <RemoveIcon sx={{ marginTop: "15px" }} />
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5.5}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <DemoContainer components={["TimePicker"]}> */}
                    <TimePicker
                      sx={{ width: "100%" }}
                      ampm={false}
                      /*  classes={{
                            root: classes.root,
                          }} */
                      label={t("select_closeTime")}
                      value={endTime !== null ? dayjs(endTime) : null}
                      // minTime={new Date(item?.openTimesValueMerged)}
                      onChange={(e) => {
                        handleChangeEndTime(e);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} style={{ width: "100%" }} />
                      )}
                    />
                    <style>
                      {`
           .MuiDialogActions-root {
            display: none;
          }
         
        `}
                    </style>
                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          )}

          {displayInfoMessage && (
            <Box mt={2}>
              <Alert severity="info">
                <Typography>{t("infoTip")}</Typography>
              </Alert>
            </Box>
          )}
        </Grid>
      </Box>

      <Divider />
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.buttonContainer}
        >
          <CommonButton
            onSubmit={closeModal}
            label={t("Cancel")}
            leftMargin={true}
            customHeight={true}
            displayWhite={"true"}
          />
          &nbsp;&nbsp;
          <CommonButton
            disabled={isButtonDisabled}
            onSubmit={onSubmit}
            label={t("Save")}
            leftMargin={true}
            customHeight={true}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SpecialHoursDateRangePickerModal;
