import { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import useDebounce from "../../hooks/useDebounce";
import { api } from "../../contexts/JWTContext";
import { useTranslation } from "react-i18next";

const CompanyAutoComplete = (props) => {
  const { selectedCompanyId, onCompanySelect } = props;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [companies, setCompanies] = useState({
    count: 0,
    rows: [],
  });
  const { t } = useTranslation();

  const listRef = useRef(null);
  const scrollTopRef = useRef(0);

  const getAllCompanies = async () => {
    setLoading(true);
    try {
      const res = await api.get(
        `/company/list?limit=50&pageNumber=${currentPage}&search=${searchParam}`
      );
      setLoading(false);
      if (res.status === 200) {
        if (companies.rows.length > 0 && searchParam === "") {
          const mergedRows = [...companies.rows, ...res.data.data.rows];
          setCompanies({
            ...res.data.data,
            rows: mergedRows,
          });
        } else {
          setCompanies(res.data.data);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleInfiniteScroll = (event) => {
    const listboxNode = event.currentTarget;

    if (
      listboxNode.scrollTop + listboxNode.clientHeight >=
        listboxNode.scrollHeight &&
      !loading
    ) {
      scrollTopRef.current = listRef.current.scrollTop;
      const totalPages = Math.ceil(companies.count / 50);
      if (currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  const handleInputChange = (value) => {
    setSearchParam(value);
    setCurrentPage(1);
  };

  const debouncedInputChange = useDebounce(handleInputChange, 500);

  useEffect(() => {
    getAllCompanies();
  }, [currentPage, searchParam]);

  useEffect(() => {
    if (listRef.current && !loading) {
      if (currentPage > 1) {
        listRef.current.scrollTop = scrollTopRef.current;
      }
    }
  }, [companies, loading, scrollTopRef.current]);

  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={companies?.rows?.length > 0 ? companies?.rows : []}
        getOptionLabel={(option) => option?.name}
        className="Autocomplete-field"
        value={selectedCompanyId ?? null}
        inputValue={inputValue}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue);
          debouncedInputChange(newInputValue);
        }}
        onChange={(e, value) => {
          onCompanySelect(value);
          setInputValue(value?.name || "");
        }}
        ListboxComponent={useCallback(
          (props) => (
            <ul {...props} ref={listRef} onScroll={handleInfiniteScroll} />
          ),
          [companies]
        )}
        renderInput={(params) => (
          <TextField
            placeholder={t("Select_Company")}
            {...params}
            required
            name="language"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            onBlur={() => {
              if (inputValue === "" && !selectedCompanyId) {
                handleInputChange("");
              }
            }}
          />
        )}
      />
    </>
  );
};

export default CompanyAutoComplete;
