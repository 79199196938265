import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Dialog,
  Tooltip,
  tooltipClasses,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import TitleHeader from "../../../components/CustomComponents/title";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NotFound from "../../../components/NotFound/NotFound";
import styled from "styled-components/macro";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import Loader from "../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import LoginIcon from "@mui/icons-material/Login";
import Delete from "../../../components/Models/DeleteModal/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useAuth from "../../../hooks/useAuth";
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const AccountsOverView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("name");
  const [allCompanies, setAllCompanies] = useState([]);
  const [partnerFilter, setPartnerFilter] = useState([]);
  const [filterWithName, setFilterWithName] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [partners, setPartners] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [open, setOpen] = React.useState(false);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openLoginModel, setOpenLoginModel] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [currentCompany, setCurrentCompany] = useState(companyData);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { signOut, switchAccount, permissionsAcess } = useAuth();
  const [statusValue, setStatusValue] = useState("all");
  const [stateValue, setStateValue] = useState("all");
  const [companyId, setCompanyId] = useState("");
  const [country, setCountry] = useState("");

  useEffect(() => {
    if (isFiltered) {
      const delayDebounceFn = setTimeout(() => {
        getAllCustomer();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [filterWithName]);

  useEffect(() => {
    getAllCustomer();
  }, [page, rowsPerPage, stateValue, statusValue]);

  const headCells = [
    { id: "id", alignment: "left", label: t("AccountTableID") },
    { id: "name", alignment: "left", label: t("CompanyyName") },
    { id: "country", alignment: "left", label: t("country") },

    /* {
      id: "Telephone number",
      alignment: "left",
      label: t("AccountTableTelephoneNumber"),
    },
    { id: "role", alignment: "left", label: t("UserTableRole") },
    { id: "status", alignment: "left", label: t("TableStatus") }, */
    { id: "approved", alignment: "left", label: t("Status") },
    { id: "state", alignment: "left", label: t("State") },
    { id: "actions", alignment: "center", label: t("Actions") },
  ];
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const getAllCustomer = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/companyUser?limit=${rowsPerPage}&page=${page + 1}${
          stateValue !== "all" ? `&state=${stateValue}` : ""
        }${
          statusValue !== "all"
            ? `&status=${statusValue === "Not_Approved" ? false : true}`
            : ""
        }${filterWithName !== "" ? `&search=${filterWithName}` : ""}`
      );

      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setPartners(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setAllCompanies([]);
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const makeDefaultCompany = async () => {
    try {
      setIsLoading(true);
      const res = await api.patch(`/companyUser/makeDefault`, {
        companyId: selectedRow !== null && selectedRow?.companyId,
      });
      if (res.status === 200) {
        getAllCustomer();
        setIsLoading(false);
        toast.success(res?.data?.message);
      }
    } catch (error) {
      setIsLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };
  const getSelectedCustomer = async () => {
    try {
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        setCurrentCompany(res?.data.data);
        localStorage.setItem("company", JSON.stringify(res?.data.data));
      }
    } catch (error) {
      //  setLoading(false);
    }
  };

  const handleSwitchCompany = async (selectedId) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/companyUser/login?companyId=${selectedId}`);
      if (res.status === 200) {
        localStorage.setItem("accessToken", res?.data?.data?.token);
        localStorage.setItem("user", JSON.stringify(res?.data?.data?.user));

        const seenFeatureNames = new Set();
        const updatedCompanyFeatureArray =
          res?.data?.data?.permissions.rolePermissions?.map((item) => {
            const featureName = item.featureName;
            if (seenFeatureNames.has(featureName)) {
              return null;
            }
            seenFeatureNames.add(featureName);
            switch (featureName) {
              case "Listing technology":
                return {
                  ...item,
                  name: "Listing_Technology",
                };
              case "Emotion AI":
                return {
                  ...item,
                  name: "Emotion_AI",
                };
              case "Review management":
                return {
                  ...item,
                  name: "Review_Management",
                };
              case "Tasks":
                return {
                  ...item,
                  name: "Tasks",
                };
              case "Reports":
                return {
                  ...item,
                  name: "Reports",
                };
              case "Post management":
                return {
                  ...item,
                  name: "Post_Management",
                };
              case "Account Management":
                return {
                  ...item,
                  name: "account_settings",
                };
              default:
                return item;
            }
          });

        const filteredCompanyFeatureArray =
          updatedCompanyFeatureArray?.filter(Boolean); // Remove null values

        localStorage.setItem(
          "rolePermissions",
          JSON.stringify(filteredCompanyFeatureArray)
        );

        switchAccount(
          res?.data?.data?.user,
          res?.data?.data?.user?.role,
          filteredCompanyFeatureArray,
          res?.data?.data?.permissions?.rolePermissions
        );

        getAllCustomer();
        getSelectedCustomer();
        setIsLoading(false);
        toast.success(t("Account_Switched_Successfully"));
        navigate("/");
      }
    } catch (error) {
      if (
        error?.response?.data?.message === "No Access Found for this Company"
      ) {
        getAllCustomer();
      }
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const filterByName = (e) => {
    setPage(0);
    setFilterWithName(e.target.value);
    setIsFiltered(true);
  };

  const handleOpenLoginModel = (id) => {
    setSelectedCompanyId(id);
    setOpenLoginModel(true);
  };
  const handleCloseLoginModel = () => {
    setOpenLoginModel(false);
  };

  const handleCloseDefaultCompanyModel = () => {
    setOpenDefaultModel(false);
  };

  const FilterData = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/company`, {
        params: {
          searchTerm: filterWithName,
        },
      });
      if (res.status >= 200) {
        setAllCompanies(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {}
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setIsFiltered(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setIsFiltered(true);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allCompanies?.count - page * rowsPerPage);
  return (
    <>
      <TitleHeader
        showButton={false}
        title={t("Switch_Account")}
        subHeading={t("ViewCustomers")}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl style={{ background: "white" }} fullWidth>
            <InputLabel id="demo-simple-select-label">{t("Status")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={statusValue}
              onChange={(e) => {
                setPage(0);
                setStatusValue(e.target.value);
                setIsFiltered(true);
              }}
              label={t("Status")}
              sx={{ background: "white", width: "100%" }}
            >
              <MenuItem value="all">{t("All")}</MenuItem>
              <MenuItem value="approved">{t("approved")}</MenuItem>
              <MenuItem value="Not_Approved">{t("Not_Approved")}</MenuItem>

              {/* <MenuItem value="yearly">{t("YearlyText")}</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={3}>
          <FormControl style={{ background: "white" }} fullWidth>
            <InputLabel id="demo-simple-select-label">{t("State")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stateValue}
              onChange={(e) => {
                setPage(0);
                setStateValue(e.target.value);
                setIsFiltered(true);
              }}
              label={t("State")}
              sx={{ background: "white", width: "100%" }}
            >
              <MenuItem value="all">{t("All")}</MenuItem>
              <MenuItem value="active">{t("Active")}</MenuItem>
              {/*   <MenuItem value="in-active">{t("Inactive")}</MenuItem> */}
              <MenuItem value="churn">{t("Churned_text")}</MenuItem>

              {/* <MenuItem value="yearly">{t("YearlyText")}</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={3} md={3} lg={4}>
          <SearchField
            filterByName={filterByName}
            // onSubmit={FilterData}
            onSubmit={getAllCustomer}
            placeholder={t("Filter_Company_Name")}
            filterWithName={filterWithName}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={2}>
          <Box sx={{ justifyContent: "flex-end", display: "flex" }}>
            <CommonButton
              alignment={true}
              // onSubmit={FilterData}
              onSubmit={getAllCustomer}
              label={t("Search_Company")}
            />
          </Box>
        </Grid>
      </Grid>

      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allCompanies?.results?.length > 0 ? (
            <>
              <TableContainer component={Paper} sx={{ marginTop: "2rem" }}>
                <Table aria-label="simple table">
                  <CustomizedTableHead>
                    <CustomizedTableRow>
                      {headCells.map((headCell) => (
                        <CustomizedTableCell
                          align={headCell.alignment}
                          key={headCell.id}
                        >
                          {headCell.label}
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>

                  <TableBody>
                    {allCompanies?.results &&
                      allCompanies?.results?.length > 0 &&
                      allCompanies?.results?.map((row, index) => {
                        return (
                          <TableRow tabIndex={-1} key={`${row.id}-${index}`}>
                            <CustomizedTableCell>
                              {row?.companyId}
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              align="left"
                              sx={{ width: "350px" }}
                            >
                              <div style={{ display: "flex" }}>
                                <Typography>{row?.company?.name} </Typography>
                                {row?.companyId === user?.companyId && (
                                  <Typography
                                    sx={{ color: "#06BDFF", marginLeft: "8px" }}
                                  >
                                    {`(${t("TableStatus")})`}
                                  </Typography>
                                )}
                              </div>
                            </CustomizedTableCell>
                            <CustomizedTableCell
                              align="left"
                              sx={{ width: "300px" }}
                            >
                              <Typography>{row?.company?.country} </Typography>
                            </CustomizedTableCell>

                            {/*  <CustomizedTableCell align="left">
                              {row?.company?.telephone}
                            </CustomizedTableCell>

                            <CustomizedTableCell align="left">
                              {row?.role === "company-admin"
                                ? "Company Admin"
                                : row?.role === "location-manager"
                                ? "Location Manager"
                                : row?.role === "account-manager"
                                ? "Account Manager"
                                : (
                                    row?.role.charAt(0).toUpperCase() +
                                    row?.role.slice(1)
                                  ).replaceAll("-", " ")}
                            </CustomizedTableCell>
                            <CustomizedTableCell>
                              {row?.companyId===user?.companyId ? (
                                <Chip
                                  sx={{
                                    padding: "5px",
                                    margin: "5px",
                                  }}
                                  label={"Default"}
                                  m={1}
                                />
                              ) : null}
                            </CustomizedTableCell> */}

                            <CustomizedTableCell
                              align="left"
                              sx={{
                                cursor: "pointer",
                                color:
                                  row?.company !== null &&
                                  row?.company?.companyApproval === true
                                    ? "#13CF8F"
                                    : "#FC3652",
                                width: "300px",
                              }}
                            >
                              <FiberManualRecordIcon
                                sx={{
                                  fontSize: 10,
                                  color:
                                    row?.company !== null &&
                                    row?.company?.companyApproval === true
                                      ? "#13CF8F"
                                      : "#FC3652",
                                  paddingRight: "3px",
                                }}
                              />
                              {row?.company !== null &&
                              row?.company?.companyApproval === true
                                ? t("approved")
                                : t("Not_Approved")}
                            </CustomizedTableCell>

                            <CustomizedTableCell
                              sx={{
                                cursor: "pointer",
                                color:
                                  row?.company?.state === "churn"
                                    ? "#F8A92B"
                                    : "#13CF8F",
                                width: "150px",
                              }}
                            >
                              <FiberManualRecordIcon
                                sx={{
                                  fontSize: 10,
                                  color:
                                    row?.company?.state === "churn"
                                      ? "#F8A92B"
                                      : "#13CF8F",
                                  paddingRight: "3px",
                                }}
                              />
                              {row?.company?.state === "churn"
                                ? t("Churned_text")
                                : row?.company?.state === "active"
                                ? t("Active")
                                : ""}
                            </CustomizedTableCell>

                            <CustomizedTableCell padding="none" align="center">
                              {currentCompany !== null &&
                              currentCompany?.id === row?.companyId ? (
                                <CustomWidthTooltip
                                  title={t("Current_Account")}
                                >
                                  <IconButton aria-label="edit" size="large">
                                    <CheckCircleIcon color="primary" />
                                  </IconButton>
                                </CustomWidthTooltip>
                              ) : (
                                <CustomWidthTooltip
                                  title={
                                    row?.company !== null &&
                                    row?.company?.state === "churn"
                                      ? t("churnText")
                                      : row?.company !== null &&
                                        row?.company?.companyApproval === false
                                      ? t("approval_tooltip")
                                      : t("Switch_Account")
                                  }
                                >
                                  <span>
                                    <IconButton
                                      disabled={
                                        (row?.company !== null &&
                                          row?.company?.state === "churn") ||
                                        (row?.company !== null &&
                                          row?.company?.companyApproval ==
                                            false)
                                      }
                                      onClick={() =>
                                        handleOpenLoginModel(row?.companyId)
                                      }
                                      aria-label="edit"
                                      size="large"
                                    >
                                      <LoginIcon
                                        sx={{
                                          color:
                                            (row?.company !== null &&
                                              row?.company?.state ===
                                                "churn") ||
                                            (row?.company !== null &&
                                              row?.company?.companyApproval ==
                                                false)
                                              ? "#AAAAAA"
                                              : "#757575",
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                </CustomWidthTooltip>
                              )}
                            </CustomizedTableCell>
                          </TableRow>
                        );
                      })}

                    {emptyRows > 0 && (
                      <TableRow>{/* <TableCell colSpan={8} /> */}</TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allCompanies?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <NotFound text="No Account Found" />
          )}
        </>
      )}

      {openLoginModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openLoginModel}
          onClose={handleCloseLoginModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Switch_Account")}
            description={t("Switch_account_modal")}
            onConfirm={() => {
              setOpenLoginModel(false);
              handleSwitchCompany(selectedCompanyId);
            }}
            onCancel={handleCloseLoginModel}
          />
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseDefaultCompanyModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Default_Company")}
            description={t("Default_comapny_subhead")}
            onConfirm={() => {
              setOpenDefaultModel(false);
              makeDefaultCompany();
            }}
            onCancel={handleCloseDefaultCompanyModel}
          />
        </Dialog>
      )}
    </>
  );
};

export default AccountsOverView;
