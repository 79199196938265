import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useAuth from "../../../../hooks/useAuth";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Alert as Button, TextField } from "@mui/material";

const BarChart = () => {
  const navigate = useNavigate();
  const { changePassword } = useAuth();
  const { t } = useTranslation();
  return (
    <>
      <Grid>
        <Grid sx={{ padding: "10px", bgcolor: "white" }}>
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              submit: true,
            }}
            validationSchema={Yup.object().shape({
              oldPassword: Yup.string()
                .min(5, "Must be at least 05 characters")
                .max(255)
                .required("Required"),
              newPassword: Yup.string()
                .min(5, "Must be at least 05 characters")
                .max(255)
                .required("Required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                changePassword(values.oldPassword, values.newPassword);
                toast.success("Updated Successfully", {
                  onClose: () => {
                    navigate(-1);
                  },
                });
              } catch (error) {
                const message = error.message || "Something went wrong";
                setStatus({ success: true });
                setErrors({ submit: message });
                toast.error(t("Error"));
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form noValidate onSubmit={handleSubmit}>

                <Grid container>
                  <Grid item xs={3} sx={{ bgcolor: "" }}>
                    <Typography variant="h4" p={7}>
                      {t("Password")}
                      <Grid wrap="nowrap" sx={{ mt: 2 }}>
                        <Grid item xs>
                          <Typography
                            sx={{
                              color: "rgba(51,51,51,.7)",
                              fontStyle: "italic",
                              fontWeight: 400,
                            }}
                          >
                            {t("UpdatePassword")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  </Grid>
                  <Grid item xs={9} p={5} sx={{ bgcolor: "" }}>
                    <Typography variant="subtitle1">
                      {t("CurrentPassword")}*
                    </Typography>
                    <TextField
                      type="password"
                      name="oldPassword"
                      value={values.oldPassword}
                      error={Boolean(touched.oldPassword && errors.oldPassword)}
                      fullWidth
                      helperText={touched.oldPassword && errors.oldPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      {t("NewPassword")}*
                    </Typography>
                    <TextField
                      type="password"
                      name="newPassword"
                      value={values.newPassword}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      fullWidth
                      helperText={touched.newPassword && errors.newPassword}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>

                <Divider variant="middle" />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  my={3}
                >
                  <Button
                    className="security_form_button1"

                  >
                    Cancel
                  </Button>
                  <Button
                    className="security_form_button2"
                    sx={{ ml: 4 }}
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Save Settings
                  </Button>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default BarChart;
