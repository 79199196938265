import { toast } from "react-toastify";
import { api } from "../../../../contexts/JWTContext";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import TitleHeader from "../../../../components/CustomComponents/title";

import CardContent from "@mui/material/CardContent";

import Card from "@mui/material/Card";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { useParams } from "react-router-dom";
import { useStyles } from "../styles/style";
import CustomizedTableRow from "../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../components/CustomComponents/table/tableCell";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Divider as MuiDivider,
  Paper,
} from "@mui/material";

import Loader from "../../../../components/Loaders/Loader";

const AllPost = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [postData, setPostData] = useState(null);
  const [totalTimesPosted, setTotalTimesPosted] = useState(0);
  const [totalTimesFailed, setTotalTimesFailed] = useState(0);
  const [successPer, setSuccessPer] = useState(0);
  const [successTimes, setSuccessTimes] = useState(0);
  const [maxCount, setMaxCount] = useState(0);
  const [meanTimesValue, setMeanTimesValue] = useState(0);

  const headCells = [
    { id: null, alignment: "left", label: `${t("Locations")}` },
    { id: "firstName", alignment: "left", label: `${t("Date_time")}` },
    { id: null, alignment: "left", label: `${t("Count")}` },
    { id: null, alignment: "left", label: `${t("Post_Status")}` },
    { id: null, alignment: "left", label: `${t("Report")}` },
  ];

  useEffect(() => {
    const meanValue =
      totalTimesPosted /
      (maxCount *
        (postData !== null ? postData.results[0]?.postLocations?.length : 1) -
        totalTimesPosted);

    setMeanTimesValue(meanValue);
  }, [totalTimesPosted, maxCount]);
  useEffect(() => {
    if (params.id) {
      getPostById(params.id);
    }
  }, []);

  useEffect(() => {
    if (postData !== null) {
      if (postData.results[0]?.postLocations.length > 0) {
        let timesPosted = 0;
        let failedTimes = 0;
        let successRate = 0;
        postData.results[0]?.postLocations.forEach((item) => {
          timesPosted = timesPosted + item?.count + item?.failCount;
          failedTimes = failedTimes + item?.failCount;
          successRate = successRate + item?.count;
        });
        setTotalTimesPosted(timesPosted);
        setTotalTimesFailed(failedTimes);
        setSuccessTimes(successRate);
        let max = 0;
        max = postData.results[0]?.postLocations.reduce(
          (a, b) => Math.max(a, b.count),
          -Infinity
        );
        setMaxCount(max);
      }
    }
  }, [postData]);

  useEffect(() => {
    let successPercentage = 0;
    successPercentage = ((successTimes / totalTimesPosted) * 100).toFixed();
    setSuccessPer(successPercentage);
  }, [totalTimesPosted, successTimes]);

  const getPostById = async (postId) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/post/${postId}`);

      if (res.status === 200 || res.status === 201) {
        setPostData(res.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleErrorMessage = (message) => {
    const splitMessage = message.split(",");
    if (splitMessage.length > 0) {
      return `${splitMessage[1]} , ${splitMessage[2]}`;
    }
    return null;
  };

  return (
    <>
      <Box className="add-rule-box1">
        <TitleHeader
          title={t("Post_Logs")}
          subHeading={""}
          createButton={false}
          showButton={true}
          // disableButton={locationId ? false : true}
          name={t("Add_Bulk_Post")}
        />
      </Box>

      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Grid item sm={12} md={4} lg={4} xs={12}>
              <Card className={classes.postLogCard}>
                <CardContent sx={{ width: "100%", height: "100%" }}>
                  <Grid container>
                    <Grid
                      container
                      sx={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#495059",
                          }}
                        >
                          {t("Total_Posts")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#495059",
                          }}
                        >
                          {t("Success_Percentage")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: "#1B2430",
                            lineHeight: "32px",
                          }}
                        >
                          {totalTimesPosted ? totalTimesPosted : "0"}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "24px",
                            color: "#1B2430",
                            lineHeight: "32px",
                          }}
                        >
                          {isNaN(successPer) ? "0" : successPer + "%"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      sx={{
                        paddingTop: "10px",
                        paddingBottom: "20px",
                        paddingLeft: "16px",
                        paddingRight: "16px",
                      }}
                    >
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#495059",
                          }}
                        >
                          {t("Successful_Posts")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography className={classes.postLogsTypo}>
                          {t("Failed_Posts")}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography className={classes.postLogTimePost}>
                          {successTimes ? successTimes : "0"}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} xs={6}>
                        <Typography className={classes.postLogTimePost}>
                          {
                            /* maxCount *
                            (postData !== null
                              ? postData.results[0]?.postLocations?.length
                              : 1) -
                            totalTimesPosted */ totalTimesFailed
                              ? totalTimesFailed
                              : "0"
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={8} lg={8} xs={12}>
              <Card
                sx={{
                  border: "1px solid #E0E0E0",
                  bgcolor: "#FFFFFF",
                  justifyContent: "space-around",
                  mt: 4,
                  mb: 4,
                  height: "auto",
                }}
              >
                <Box sx={{ marginTop: "1rem" }}>
                  {/*   {users?.results?.length > 0 ? ( */}
                  <>
                    {" "}
                    {postData !== null && (
                      <TableContainer component={Paper}>
                        <Table
                          aria-label="simple table"
                          // size={"medium"}
                          // aria-label="enhanced table"
                        >
                          <CustomizedTableHead>
                            <CustomizedTableRow>
                              {headCells?.map((headCell) => (
                                <CustomizedTableCell
                                  key={headCell.id}
                                  align={headCell.alignment}
                                  padding={
                                    headCell.disablePadding ? "none" : "normal"
                                  }
                                  /*   sortDirection={
                                orderBy === headCell.id ? order : false
                              } */
                                >
                                  {headCell.label}
                                </CustomizedTableCell>
                              ))}
                            </CustomizedTableRow>
                          </CustomizedTableHead>

                          <TableBody>
                            {postData.results[0]?.postLocations.length > 0 ? (
                              postData.results[0]?.postLocations.map(
                                (row, index) => {
                                  return (
                                    <CustomizedTableRow
                                      // style={{ height: 60, background: "blue" }}
                                      // hover
                                      tabIndex={-1}
                                      key={`${row.id}-${index}`}
                                    >
                                      <CustomizedTableCell
                                        sx={{ width: "250px" }}
                                      >
                                        {row.location !== null
                                          ? row.option?.internalName
                                          : "--"}
                                        {/* {row.id} */}
                                      </CustomizedTableCell>
                                      <CustomizedTableCell
                                        sx={{
                                          cursor: "pointer",
                                          width: "150px",
                                        }}
                                        align="center"
                                      >
                                        {/* {moment(row.createdAt).format("")} */}
                                        {postData?.results[0]?.startDate !==
                                        null
                                          ? moment(
                                              postData?.results[0]?.startDate
                                            ).format("MMM DD,YYYY • LT")
                                          : "--"}
                                      </CustomizedTableCell>
                                      <CustomizedTableCell
                                        align="left"
                                        sx={{
                                          cursor: "pointer",
                                          width: "100px",
                                        }}
                                      >
                                        {row.count !== null ? row.count : "--"}
                                      </CustomizedTableCell>

                                      <CustomizedTableCell
                                        align="left"
                                        sx={{
                                          width: "200px",
                                          textTransform: "capitalize",
                                          color:
                                            row.status === "done"
                                              ? "#13CF8F"
                                              : row.status === "failed"
                                              ? "#FC3652"
                                              : row.status === "repeate"
                                              ? "#4A48FC"
                                              : "#F29339",
                                        }}
                                      >
                                        {row.status === `${t("done")}`
                                          ? "•" + " " + `${t("Successful")}`
                                          : row.status === `${t("failed")}`
                                          ? "•" + " " + `${t("Failed")}`
                                          : row.status === `${t("repeate")}`
                                          ? "•" + " " + `${t("Repeat")}`
                                          : "•" + " " + row.status}
                                      </CustomizedTableCell>
                                      <CustomizedTableCell align="left">
                                        {row.errorMessage !== null
                                          ? handleErrorMessage(row.errorMessage)
                                          : "--"}
                                      </CustomizedTableCell>
                                    </CustomizedTableRow>
                                  );
                                }
                              )
                            ) : (
                              <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                sx={{
                                  justifyContent: "center",
                                  textAlign: "center",
                                  alignItem: "center",
                                }}
                              >
                                {t("No_Location_Found")}
                              </Grid>
                            )}
                            {/* style={{ height: 53 * emptyRows }} */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {/*   <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={users?.count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  /> */}
                  </>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default AllPost;
