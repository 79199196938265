import React, { useEffect, useState } from "react";
import { useStyles } from "../../../styles/style";
import { Grid, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "@mui/material/styles";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CreateRoleFeature from "./CreateRoleFeature";
import EditRoleFeature from "./EditRoleFeature";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const CreateRoleDetails = ({
  theme,
  allLocations,
  checkboxes,
  setCheckboxes,
  selectAllChecked,
  setSelectAllChecked,
  expanded,
  setExpanded,
  singleRole,
  setSingleRole,
  companyFeatures,
  setPermissionsArr,
  permissionsArr,
  updateRole,
  createRole,
  setRoleName,
  roleName,
  displayRoleError,
  setDisplayRoleError,
  defaultRole,
}) => {
  const params = useParams();

  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(false);
  const [roleNameError, setRoleNameError] = useState(false);

  useEffect(() => {
    const permissionFound = permissionsArr?.filter(
      (item) => item?.permissions?.length > 0
    );
    if (permissionFound?.length <= 0) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [permissionsArr]);

  const handleChangeRoleName = (e) => {
    const value = e.target.value;
    setRoleName(value);
    if (value === "") {
      setRoleNameError(true);
    } else {
      setRoleNameError(false);
      setDisplayRoleError(false);
    }
  };

  const onCancelCreateRole = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 2, page: 0 },
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid>
          <Grid sx={{ padding: "1.5rem", bgcolor: "white" }}>
            <Typography sx={{ fontWeight: 600, fontSize: "1.125rem" }} mb={2}>
              {t("role_name")}
            </Typography>
            <TextField
              fullWidth
              placeholder={t("role_placeHolder")}
              value={roleName}
              disabled={defaultRole}
              onChange={handleChangeRoleName}
              error={roleNameError || displayRoleError}
              helperText={
                roleNameError
                  ? t("role_name_required")
                  : displayRoleError
                  ? t("role_exists")
                  : ""
              }
            />

            <EditRoleFeature
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
              selectAllChecked={selectAllChecked}
              setSelectAllChecked={setSelectAllChecked}
              expanded={expanded}
              setExpanded={setExpanded}
              companyFeatures={companyFeatures}
              setPermissionsArr={setPermissionsArr}
              permissionsArr={permissionsArr}
              singleRole={singleRole}
              defaultRole={defaultRole}
            />

            {/*   <CreateRoleFeature
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                selectAllChecked={selectAllChecked}
                setSelectAllChecked={setSelectAllChecked}
                expanded={expanded}
                setExpanded={setExpanded}
                companyFeatures={companyFeatures}
                setPermissionsArr={setPermissionsArr}
                permissionsArr={permissionsArr}
              />  */}
          </Grid>
        </Grid>
      </ThemeProvider>
      <Grid container className={classes.buttonContainer}>
        <CommonButton
          displayWhite="true"
          onSubmit={onCancelCreateRole}
          label={t("Cancel")}
        />

        <CommonButton
          label={t("Save")}
          disabled={
            defaultRole
              ? true
              : disableButton
              ? true
              : displayRoleError
              ? true
              : roleName === ""
              ? true
              : roleNameError
              ? true
              : false
          }
          onSubmit={params?.id ? updateRole : createRole}
          // loading={isLoading}
        />
      </Grid>
    </>
  );
};

export default CreateRoleDetails;
