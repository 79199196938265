import React from "react";
import Box from "@mui/material/Box";
import { Typography, Grid } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import NoLocation from "../../assets/images/nolocationfull.svg"
import AccessDeniedImg from "../../../assets/images/AccessDenied.png";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainer: {
    padding: "20px 0px 10px 0px",
  },
  iconStyle: {
    width: "20px",
    height: "18px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  locationsubhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    marginTop: "8px",
    height: "300px",
  },
  Imagesection2: {
    marginTop: "8px",
    height: "250px",
  },
}));
const AccessDenied = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box className={classes.locationbox}>
      <img alt="" className={classes.Imagesection} src={AccessDeniedImg} />
      <Typography className={classes.locationhead}>
        {t("Permission_Denied")}
      </Typography>
      <Typography className={classes.locationsubhead}>
        {t("permission_subheading")}
      </Typography>
      <Box sx={{ marginTop: "15px" }}>
        <CommonButton
          onSubmit={() => navigate("/")}
          label={t("Back_to_Home_Screen")}
        />
      </Box>
    </Box>
  );
};

export default AccessDenied;
