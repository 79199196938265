import React, { useState } from "react";
import { Grid, Typography, Box, TextField } from "@mui/material";
import { useStyles } from "../../../../Styles/style";

const DescriptionField = ({
  data,
  rows,
  label,
  length,
  errorText,
  description,
  setDescription,
  setTouchedValue,
  placeHolder,
}) => {
  const classes = useStyles();

  const handleUpdateDescription = (e) => {
    const str = e.target.value.substring(0, length);
    setTouchedValue(true);
    const updatedItems = description.map((item) =>
      item.id === data.id ? { ...item, content: str } : item
    );
    setDescription(updatedItems);
  };

  return (
    <Grid item md={12} sm={12} xs={12} lg={12} sx={{ marginTop: "12px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.fieldTextDescription}>
          {label}
        </Typography>
        <Typography className={classes.limitFieldTextDescription}>
          {data.content === "" || data.content?.length === 0
            ? 0
            : data.content?.length}
          /{length}
        </Typography>
      </Box>

      {data.content?.length > length && (
        <Typography className={classes.textLimitTypo}>{errorText}</Typography>
      )}
      <TextField
        id="standard-basic"
        rows={rows}
        maxRows={20}
        multiline
        type="text"
        name="description"
        fullWidth
        placeholder={placeHolder}
        value={data.content}
        onChange={handleUpdateDescription}
      />
    </Grid>
  );
};

export default DescriptionField;
