import {
  Divider,
  Typography,
  Box,
  Autocomplete,
  Grid,
  TextField,
  Checkbox,
  Modal,
} from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const styleEditUser = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "95vh",
  width: "800px",
  bgcolor: "background.paper",
  border: "2px solid gray",
  borderRadius: "8px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  textFieldTitle: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginBottom: "8px",
  },
  modalCircularProgress: {
    width: "25px",
    height: "25px",
  },
  whiteBackground: {
    background: "white",
    width: "98%",
  },
}));

const FiltersModel = ({
  openModalFilter,
  handleCloseModalFilter,
  allLocation,
  handleLocationChange,
  allReviewSource,
  handleSourceChange,
  handleChangeRatings,
  starRatings,
  replyChangeHandler,
  haventReplied,
  tagsChangeHandler,
  handleChangeTime,
  tags,
  sourceId,
  locationId,
  selectedTags,
  selectedReply,
  allTimePeriod,
  ratingsValue,
  resetHandler,
  handleChangeGroups,
  allGroups,
  selectedGroup,
  setCallApi,
  setPageNumber,
  allCompanies,
  handleCompanyChange,
  companyId,
  startDate,
  handleChangeEndDate,
  endDate,
  handleChangeStartDate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [locationRequired, setLocationRequired] = useState(false);
  const timePeriodList = [
    { name: `${t("All")}`, value: 0, periodValue: "All" },
    { name: `${t("today")}`, value: 1, periodValue: "Today" },
    { name: `${t("yesterday")}`, value: 2, periodValue: "Yesterday" },
    { name: `${t("2days")}`, value: 3, periodValue: "2days" },
    { name: `${t("7days")}`, value: 4, periodValue: "7days" },
    { name: `${t("week")}`, value: 5, periodValue: "week" },
    { name: `${t("monthValue")}`, value: 6, periodValue: "month" },
    { name: `${t("14days")}`, value: 7, periodValue: "14days" },
    { name: `${t("30days")}`, value: 8, periodValue: "30days" },
    { name: `${t("60days")}`, value: 9, periodValue: "60days" },
    { name: `${t("90days")}`, value: 10, periodValue: "90days" },
    { name: `${t("6months")}`, value: 11, periodValue: "6months" },
    { name: `${t("12months")}`, value: 12, periodValue: "12months" },
    { name: `${t("custom_text")}`, value: 12, periodValue: "custom" },
  ];

  return (
    <Modal
      open={openModalFilter}
      // onClose={handleCloseModalEditUser}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid item>
          <Box sx={styleEditUser}>
            <Box
              sx={{
                marginY: "10px",
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <ArrowBackIcon
                  sx={{
                    cursor: "pointer",
                    color: "black",
                    mr: "0.5rem",
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => {
                    handleCloseModalEditUser();
                  }}
                /> */}
              <Typography sx={{ fontWeight: 700, fontSize: "20px" }}>
                {t("Filters")}
              </Typography>
            </Box>
            <Divider my={6} />
            <Grid
              container
              spacing={1}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "3%",
                mt: "3%",
              }}
            >
              {/* <Typography
                sx={{
                  color: "#8D9298",
                  marginY: "16px",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "20px",
                  ml: 1,
                }}
              >
                {t("Locations")}
              </Typography> */}
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#1B2430",
                    marginBottom: "8px",
                  }}
                >
                  {t("Select_Company")}
                </Typography>
                <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  // sx={{ height: "100px" }}
                  options={
                    allCompanies?.results?.length > 0
                      ? allCompanies?.results
                      : []
                  }
                  getOptionLabel={(option) => option?.name}
                  className="Autocomplete-field"
                  value={companyId}
                  onChange={(e, value) => {
                    handleCompanyChange(value);
                    setCallApi(false);
                    // setCompanyIdRequired(false);
                  }}
                  /*  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} `}
                    </li>
                  )} */
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Company")}
                      {...params}
                      required
                      // label={t("TaskCompany")}
                      name="language"
                      //   error={companyIdRequired}
                    />
                  )}
                ></Autocomplete>
              </Grid>
              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#1B2430",
                    marginBottom: "8px",
                  }}
                >
                  {t("Location")}
                </Typography>

                <Autocomplete
                  multiple
                  error={locationRequired}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allLocation?.results?.length > 0 ? allLocation?.results : []
                  }
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName + "(" + option?.city + ")"
                      : ""
                  }
                  // sx={{ width: "66%", bgcolor: "white" }}
                  sx={{ bgcolor: "white" }}
                  value={locationId ? locationId : []}
                  onChange={(e, value) => {
                    handleLocationChange(value);
                    setLocationRequired(false);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} (${option.city})`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              <Grid item md={12}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#1B2430",
                    marginBottom: "8px",
                  }}
                >
                  {t("group")}
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allGroups !== null && allGroups?.length > 0 ? allGroups : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name : ""
                  }
                  className="Autocomplete-field"
                  value={selectedGroup}
                  onChange={(e, value) => {
                    handleChangeGroups(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_group")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>

              <Typography
                sx={{
                  color: "#8D9298",
                  marginY: "16px",
                  fontWeight: "700",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                {t("Review_Information")}
              </Typography>
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "3%",
                }}
              >
                {/* {sourceId?.name === "google" && (
          <Grid item md={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              sx={{ bgcolor: "white" }}
              options={haventReplied}
              getOptionLabel={(option) => option}
              value={selectedReply}
              onChange={(e, value) => {

                replyChangeHandler(value);
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={t("Haven't Replied")}
                  {...params}
                  required
                  // label={t("TaskLocation")}
                  // error={locationRequired}
                  name="language"
                />
              )}
            ></Autocomplete>
          </Grid>
        )} */}

                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      color: "#1B2430",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Review_Source")}
                  </Typography>

                  <Autocomplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    // defaultValue={sourceId && sourceId}
                    sx={{ bgcolor: "white" }}
                    value={sourceId ? sourceId : []}
                    options={
                      allReviewSource.results?.length > 0
                        ? allReviewSource?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.name ? option?.name : ""
                    }
                    // sx={{ width: "33%", bgcolor: "white" }}

                    onChange={(e, value) => {
                      handleSourceChange(value);
                      setLocationRequired(false);
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />

                        <Typography sx={{ textTransform: "capitalize" }}>
                          {" "}
                          {option?.name}{" "}
                        </Typography>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select Review Source")}
                        {...params}
                        required
                        // label={t("TaskLocation")}
                        error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      color: "#1B2430",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Time_Period")}
                  </Typography>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ bgcolor: "white" }}
                    options={timePeriodList}
                    getOptionLabel={(option) => option?.name}
                    value={allTimePeriod}
                    onChange={(e, value) => {
                      setCallApi(false);
                      handleChangeTime(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select Time Period")}
                        {...params}
                        required
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>
                {/* <Grid item md={4}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    lineHeight: "20px",
                    color: "#1B2430",
                    marginBottom: "8px",
                  }}
                >
                  Time Period
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  sx={{ bgcolor: "white" }}
                  options={haventReplied}
                  getOptionLabel={(option) => option?.internalName}
                  // value={sourceId}
                  // onChange={(e, value) => {


                  //   handleSourceChange(value);
                  //   setLocationRequired(false);
                  // }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Time Period")}
                      {...params}
                      required
                      // label={t("TaskLocation")}
                      error={locationRequired}
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid> */}
                <Grid item md={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      color: "#1B2430",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Ratings")}
                  </Typography>

                  <Autocomplete
                    multiple
                    disablePortal
                    id="combo-box-demo"
                    sx={{ bgcolor: "white" }}
                    options={starRatings}
                    getOptionLabel={(option) => option?.name}
                    value={ratingsValue ? ratingsValue : []}
                    onChange={(e, value) => {
                      handleChangeRatings(value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select Rating")}
                        {...params}
                        required
                        // label={t("TaskLocation")}
                        error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                {/* <Grid item md={2}>
          {tags?.results.length > 0 && (
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={tags?.results && tags?.results}
              disableCloseOnSelect
              getOptionLabel={(option) => option?.internalName}
              onChange={(e, value) => {
                tagsChangeHandler(value);
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.internalName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Tags"
                  placeholder="Select Tags"
                />
              )}
            />
          )}
        </Grid> */}
              </Grid>
              {allTimePeriod?.periodValue === "custom" && (
                <Grid
                  container
                  spacing={2}
                  lg={12}
                  sx={{ display: "flex", marginBottom: "8px" }}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "20px",
                        color: "#1B2430",
                        marginBottom: "8px",
                      }}
                    >
                      {t("Fromm")}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        placeholder={t("From")}
                        className={classes.whiteBackground}
                        value={dayjs(startDate)}
                        format="DD/MM/YYYY"
                        minDate={dayjs("2005-01-01")}
                        maxDate={dayjs().endOf("day")}
                        onChange={(e) => {
                          handleChangeStartDate(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            fullWidth
                            {...params}
                            sx={{ padding: "8px", background: "#FFFF" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "700",
                        lineHeight: "20px",
                        color: "#1B2430",
                        marginBottom: "8px",
                      }}
                    >
                      {t("Too")}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        disableFuture
                        className={classes.whiteBackground}
                        value={dayjs(endDate)}
                        format="DD/MM/YYYY"
                        minDate={dayjs("2005-01-01")}
                        maxDate={dayjs().endOf("day")}
                        onChange={(e) => {
                          handleChangeEndDate(e);

                          //   setEndDate(e);
                        }}
                        placeholder={t("To")}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            fullWidth
                            {...params}
                            //  error={dateRequired}
                            sx={{ padding: "8px", background: "#FFFF" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "3%",
                }}
              >
                <Grid item md={6}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      color: "#1B2430",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Actions")}
                  </Typography>

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ bgcolor: "white" }}
                    options={haventReplied}
                    getOptionLabel={(option) => option?.name}
                    value={selectedReply}
                    onChange={(e, value) => {
                      replyChangeHandler(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        placeholder={t("Select Action")}
                        {...params}
                        required
                        // label={t("TaskLocation")}
                        // error={locationRequired}
                        name="language"
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                <Grid item md={6}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "700",
                      lineHeight: "20px",
                      color: "#1B2430",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Tags")}
                  </Typography>
                  {tags?.results.length > 0 && (
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      options={tags?.results && tags?.results}
                      disableCloseOnSelect
                      value={selectedTags ? selectedTags : []}
                      getOptionLabel={(option) => option?.name}
                      onChange={(e, value) => {
                        tagsChangeHandler(value);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {" "}
                            {option?.name}
                          </Typography>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Select_Tags")}
                          placeholder={t("Select_Tags")}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                <Box sx={{ justifyContent: "flex", float: "right" }}>
                  <CommonButton
                    displayBlack="true"
                    // onSubmit={onCancel}
                    // disabled={isSubmitting}
                    icon={<RefreshIcon />}
                    label={t("Reset")}
                    onSubmit={() => {
                      resetHandler();
                      handleCloseModalFilter();
                    }}
                  />
                  &nbsp;&nbsp;
                  <CommonButton
                    type="submit"
                    onSubmit={() => {
                      setCallApi(true);
                      setPageNumber(0);
                      handleCloseModalFilter();
                    }}
                    label={t("Apply_filters")}
                    sx={{ ml: 2 }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FiltersModel;
