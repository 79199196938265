import React, { useState } from "react";

import styled, { withTheme } from "styled-components/macro";
import { api } from "../../../../contexts/JWTContext";

import * as Yup from "yup";

import { Formik } from "formik";

//Start for select time zone*******************************************

//End for select time zone*********************************************

import { spacing } from "@mui/system";

import { Grid } from "@mui/material";
import { Alert as MuiAlert } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CommonForm from "../../../../components/CustomComponents/forms/CommonForm";
const Alert = styled(MuiAlert)(spacing);

const AddUser = ({ onCancel, id, Language, Role, theme, locations }) => {
  // const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState([]);

  const { t } = useTranslation();

  const defaultRole = {
    name: "Account Manager",
    value: "account-manager",
  };
  {
  }
  return (
    <>
      <Grid>
        <Grid sx={{ padding: "10px", bgcolor: "white" }}>
          <Formik
            // enableReinitialize={true}
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              language: "",
              role: defaultRole,
              locations: "",
              submit: false,
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string()
                .max(255)
                .required(`${t("First_name_req")}`),
              lastName: Yup.string()
                .max(255)
                .required(`${t("Last_name_req")}`),

              email: Yup.string()
                .email(`${t("valid_email")}`)
                .max(255)
                .required(`${t("email_req")}`)
                .matches(/\S+@\S+\.\S+/, `${t("valid_email")}`),
              password: Yup.string()
                .min(5, `${t("valid_pass")}`)
                .max(255),
              //  .required(`${t("Pass_req")}`),
              //  language: Yup.string().required("Language is required"),
              role: Yup.object().required(`${t("role_req")}`),
              // locations: Yup.string().required("Location is required"),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              setIsLoading(true);

              try {
                let locationIds = [];
                selectedLocation?.map((location) => {
                  locationIds.push(location?.id);
                });
                const res = await api.post(`/user/addUser`, {
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  password: values.password,
                  language: values.language,
                  role: values.role.value,
                  locations: locationIds,
                  companyId: id.id,
                });
                if (res.status === 200) {
                  // setMessage(res.data.message);
                  toast.success("Added new user successfully", {
                    onClose: () => {
                      onCancel();
                    },
                  });

                  setIsLoading(false);
                  // setTimeout(() => {
                  //   setMessage("");
                  // }, 10000);
                }
              } catch (error) {
                toast.error(error?.response?.data?.message);
                // const message = error.message || "Something went wrong";
                // setStatus({ success: false });
                // setErrors({ submit: message });
                setIsLoading(false);
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <CommonForm
                errors={errors}
                showPassword="true"
                handleBlur={handleBlur}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                onCancel={onCancel}
                touched={touched}
                values={values}
                Language={Language}
                Role={Role}
                isLoading={isLoading}
                label={t("AddNewUser")}
                locations={locations}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

export default withTheme(AddUser);
