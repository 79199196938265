import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
} from "@mui/material";
import DialogContent from '@mui/material/DialogContent';
import Deletes from "./Iframe.css";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';

const Iframe = ({ title,  onCancel, iframe  }) => {
  return (
    <>
      <div>
        <Box className="box_iframe">
            <Box sx={{display:"flex", justifyContent:"space-between"}}>
          <DialogContentText
            id="responsive-dialog-title"
            sx={{}}
            className="delete_dialog_content1"
          >
            {title}
            
          </DialogContentText>
          <DialogActions>
            <IconButton autoFocus onClick={onCancel} className="delete_button">
             <CloseIcon/>
            </IconButton>
          </DialogActions>
          </Box>
          <Box
        
          >
            {iframe}
          </Box>
      
        </Box>

      </div>
    </>
  )
}

export default Iframe