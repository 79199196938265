import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

// Load GA and GTM IDs from environment variables
const GA_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

if (GA_TRACKING_ID) {
  ReactGA.initialize(GA_TRACKING_ID);
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
} else {
  console.error("Google Analytics Tracking ID is missing");
}

if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID });
} else {
  console.error("Google Tag Manager ID is missing");
}

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
