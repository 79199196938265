import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  tooltipClasses,
  Tooltip,
} from "@mui/material";
// import { ReactComponent as GoogleIcon } from "../../../../assets/google.png";
import { api } from "../../../../contexts/JWTContext";
import Dialog from "@mui/material/Dialog";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import Delete from "../../../../components/Models/DeleteModal/Delete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../styles/style";
import facebookLogo from "../../../../assets/facebook.png";
import styled from "styled-components/macro";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const AccountsCard = ({
  socialLink,
  id,
  placeId,
  getLocationById,
  setIsLoading,
  location,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selected, setSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [defaultValueLocation, setDefaultvalueLocation] = useState(null);
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    socialLink?.map((link) => {
      if (link?.type === "facebook" && link?.isConnected === true) {
        setSelected(true);
        setSelectedName(link?.type);
      }
    });
  }, []);

  useEffect(() => {
    socialLink?.map((link) => {
      if (link?.type === "facebook" && link?.isConnected === true) {
        setSelected(true);
        setSelectedName(link?.type);
      }
    });
  }, [location?.state?.showProduct === true]);
  // useEffect(() => {
  //   socialLink?.map((link) => {
  //     if (link?.isConnected===true) {
  //       setSelected(true);
  //       setSelectedName(link?.type);
  //     }
  //   });
  // }, [location?.state?.showProduct===true]);
  async function fetchURL() {
    try {
      const res = await api.get(`googleConnect/auth-url`);
      if (res.data?.status === true) {
        window.location.href = res?.data.data /* + "&state=" + id */;
      }
    } catch (error) {}

    // if (response?.data?.url) {
    //   window.location.href = response.data.url;
    // }
  }
  async function disconnectSocialLink() {
    setIsLoading(true);
    const selectedLink = socialLink?.find((link) => link?.type === "facebook");

    try {
      const res = await api.delete(`/socialLink/${selectedLink?.id}`);

      setOpen(false);
      if (res.status === 200) {
        setIsLoading(false);
        getLocationById(id);
        toast.success(t("Disconnected_Successfully"));
      }
    } catch (error) {
      setOpen(false);
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
    }
  }

  const handleOpenConfirmBox = () => {
    setOpen(true);
  };
  const handleCloseConfirmBox = () => {
    setOpen(false);
  };
  const handleConnectWithFacebook = async () => {
    try {
      const res = await api.get(`facebookConnect/auth-url?locationId=${id}`);

      if (res.data?.status === true) {
        window.location.href = res?.data.data;
      }
    } catch (error) {}
  };

  return (
    <>
      <Grid item sm={12} md={12} lg={12}>
        <Box className={classes.connectBoxLocation}>
          <Grid container>
            <Grid item sm={1} md={1} lg={1}>
              <img
                alt=""
                style={{ width: 50, height: 50 }}
                src={facebookLogo}
              />
            </Grid>
            <Grid item sm={7} md={7} lg={7}>
              <Typography
                sx={{
                  color: "#1B2430",
                  fontSize: "18px",
                  fontWeight: 700,
                }}
              >
                {selected ? t("facebook_connected") : t("facebook_connect")}
              </Typography>
              <Typography
                sx={{
                  color: "#495059",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {t("facebook_access_dec")}
              </Typography>
            </Grid>
            <Grid item sm={4} md={4} lg={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {selected ? (
                  <>
                    {/*   <Button
                      onClick={handleConnectWithFacebook}
                      className={classes.reconnectButtonLocation}
                    >
                      {t("Reconnect")}
                    </Button> */}
                    {deletePermission && viewPermission ? (
                      <Button
                        onClick={() => handleOpenConfirmBox()}
                        className={classes.UnsubscribeButtonLocation}
                      >
                        {t("Disconnect")}
                      </Button>
                    ) : (
                      <BootstrapTooltip title={t("authorized_access")}>
                        <span>
                          <Button
                            onClick={() => handleOpenConfirmBox()}
                            className={classes.UnsubscribeButtonLocation}
                          >
                            {t("Disconnect")}
                          </Button>
                        </span>
                      </BootstrapTooltip>
                    )}
                  </>
                ) : addPermission && viewPermission ? (
                  <Button
                    onClick={handleConnectWithFacebook}
                    className={classes.subscribeButtonLocation}
                  >
                    {t("Connect")}
                  </Button>
                ) : (
                  <BootstrapTooltip title={t("authorized_access")}>
                    <span>
                      <Button
                        disabled
                        onClick={handleConnectWithFacebook}
                        className={classes.subscribeButtonLocation}
                      >
                        {t("Connect")}
                      </Button>
                    </span>
                  </BootstrapTooltip>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseConfirmBox}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Disconnect_title")}
          description={t("Disconnect_sub")}
          onConfirm={disconnectSocialLink}
          onCancel={handleCloseConfirmBox}
        />
      </Dialog>
    </>
  );
};

export default AccountsCard;
