import React, { useState, useEffect } from "react";
import TitleHeader from "../../../components/CustomComponents/title";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import NotFound from "../../../components/NotFound/NotFound";
import { handleSessionOut } from "../../../contexts/JWTContext";
import { Typography, Grid, TextField, Autocomplete } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Divider from "@mui/material/Divider";
import useAuth from "../../../hooks/useAuth";
import { api } from "../../../contexts/JWTContext";
import { toast } from "react-toastify";
import Loader from "../../../components/Loaders/Loader";
import Container from "../../../components/ReviewSource";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [allLocation, setAllLocation] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState();

  const [companyId, setCompanyId] = useState(null);

  const [mostlyUsedSource, setMostlyUsedSource] = useState([]);
  const [otherSource, setOtherSource] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [color, setColor] = React.useState(0);
  const [reviewData, setReviewData] = useState({});
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  useEffect(() => {
    getAllCompanies();
    getAllThirdPartySource();
    //  getAllLocations();
  }, []);

  const onClickOpenModel = () => {};

  useEffect(() => {
    if (locationId) {
      getReviews();
    }
  }, [locationId, companyId]);

  const handleLocationChange = (option) => {
    setLocationId(option);
  };

  /*  const getAllLocations = async () => {
    try {
      setIsLoading(true);
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);

        const defaultLoc = res.data.data.results.find(
          (item) => item.defaultLocation !== null
        );

        if (defaultLoc) {
          setDefaultLocation(defaultLoc);
          setLocationId(defaultLoc);
        }
        //
        setIsLoading(false);


      }
    } catch (error) {}
  }; */

  const getReviews = async () => {
    try {
      setIsLoading(true);

      const res = await api.get(
        `/reviewSource/location/${locationId?.id}?companyId=${companyId?.id}`
      );
      if (res.status === 200 || res.status === 201) {
        setReviewData(res.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllReviewSource(res.data.data);
        const checkTrue = res?.data?.data?.results?.filter(
          (item) => item?.mostly_used === true
        );
        const checkFalse = res?.data?.data?.results?.filter(
          (item) => item?.mostly_used === null
        );
        setMostlyUsedSource(checkTrue);
        setOtherSource(checkFalse);
      }
    } catch (error) {
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllCompanies = async () => {
    try {
      const res = await api.get(`/company`, {});
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        if (res.data.data?.results?.length > 0) {
          setCompanyId(res.data.data?.results[0]);
          if (res.data.data?.results[0]?.locations?.length > 0) {
            setAllLocation(res.data.data?.results[0]?.locations);
            const defaultLoc = res.data.data?.results[0]?.locations.find(
              (item) => item.id === res.data.data?.results[0]?.defaultLocationId
            );
            if (defaultLoc) {
              setLocationId(defaultLoc);
            } else {
              setLocationId(res.data.data?.results[0]?.locations[0]);
            }
          }
        }
      }
    } catch (error) {}
  };

  const handleChangeCompany = (option) => {
    setCompanyId(option);

    if (option?.locations?.length > 0) {
      setAllLocation(option?.locations);
      const defaultLoc = option?.locations.find(
        (item) => item.id === option.defaultLocationId
      );
      if (defaultLoc) {
        setLocationId(defaultLoc);
      } else {
        setLocationId(option?.locations[0]);
      }
    } else {
      setAllLocation([]);
      setLocationId(null);
    }
  };

  return (
    <>
      <TitleHeader
        title={t("Review_Source")}
        // createButton={true}
        // name="Add"
      />

      <>
        {isLoading ? (
          <Loader />
        ) : (
          // allReviewSource.results?.length > 0 &&
          <>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography
                  sx={{
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "rgba(0,0,0,0.87)",
                  }}
                >
                  {t("Select_Company")}
                </Typography>
                <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allCompanies?.results?.length > 0
                      ? allCompanies?.results
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.name ? option?.name + "(" + option?.city + ")" : ""
                  }
                  className="Autocomplete-field"
                  value={companyId}
                  onChange={(e, value) => {
                    handleChangeCompany(value);
                  }}
                  /*  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} (${option.city})`}
                    </li>
                  )} */
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Company")}
                      {...params}
                      required
                      name="company"
                    />
                  )}
                ></Autocomplete>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography
                  sx={{
                    marginTop: "10px",
                    fontSize: "14px",
                    fontWeight: "700",
                    color: "rgba(0,0,0,0.87)",
                  }}
                >
                  {t("Select_Location")}
                </Typography>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allLocation?.length > 0 ? allLocation : []}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  sx={{ width: "100%", bgcolor: "white" }}
                  //  value={locationId}
                  defaultValue={locationId}
                  onChange={(e, value) => {
                    handleLocationChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: "28px" }} my={4} />
            {locationId || defaultLocation ? (
              <>
                <Grid container>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      marginTop: "28px",
                      fontSize: "24px",
                      lineHeight: "32px",
                      marginBottom: "28px",
                    }}
                  >
                    {t("Review_Sites")}
                  </Typography>

                  <Grid container spacing={8}>
                    {mostlyUsedSource &&
                      mostlyUsedSource?.map((data, index) => {
                        {
                        }
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Container
                              onClickOpenModel={onClickOpenModel}
                              reviewData={reviewData}
                              thirdPartReviewData={data}
                              reviewId={reviewId}
                              locationId={locationId}
                              // onSubmit={handleOpen}
                              color={color}
                              selectedLocation={locationId}
                              setIsLoading={setIsLoading}
                              getReviews={getReviews}
                              allReviewSource={allReviewSource}
                              addPermission={false}
                              viewPermission={false}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "8px" }} my={4} />

                <Grid container>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      marginTop: "28px",
                      fontSize: "24px",
                      lineHeight: "32px",
                      marginBottom: "28px",
                    }}
                  >
                    {t("Other_review_sites")}
                  </Typography>

                  <Grid container spacing={8}>
                    {otherSource &&
                      otherSource?.map((data, index) => {
                        return (
                          <Grid item xs={12} sm={6} md={6} lg={4}>
                            <Container
                              onClickOpenModel={onClickOpenModel}
                              reviewData={reviewData}
                              thirdPartReviewData={data}
                              reviewId={reviewId}
                              //  onSubmit={handleOpen}
                              color={color}
                              selectedLocation={locationId}
                              locationId={locationId}
                              setIsLoading={setIsLoading}
                              getReviews={getReviews}
                              allReviewSource={allReviewSource}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <NotFound hideIcon="true" text={t("NoLocation")} />
              </>
            )}
          </>

          // <TableContainer
          //   sx={{ marginTop: "10px" }}
          //   borderRadius="12"
          //   component={Paper}
          // >
          //   <Table
          //     sx={{
          //       minWidth: 650,
          //     }}
          //     aria-label="simple table"
          //   >
          //     <TableHead variant="h1">
          //       <TableRow>
          //         <TableCell align="left">{t("Id")}</TableCell>
          //         <TableCell align="center">{t("Url_PlaceId")}</TableCell>
          //         <TableCell align="center">{t("Source")}</TableCell>
          //         <TableCell align="center">{t("Status")}</TableCell>
          //         <TableCell align="center">{t("Action")}</TableCell>
          //       </TableRow>
          //     </TableHead>
          //     <TableBody>
          //       {reviewData.results?.length > 0 &&
          //         reviewData.results?.map((row) => (
          //           <TableRow>
          //             <TableCell align="left" hover>
          //               <Typography component="th" scope="row">
          //                 {row.id}
          //               </Typography>
          //             </TableCell>

          //             <TableCell align="center">
          //               <Typography scope="row">
          //                 {row.url ? row.url : row.placeId}
          //               </Typography>
          //             </TableCell>
          //             <TableCell align="center">
          //               <Typography scope="row">
          //                 {row.thirdPartyReviewSource?.name}
          //               </Typography>
          //             </TableCell>
          //             <TableCell align="center">
          //               {row.isActive === true ? "Active" : "InActive"}
          //             </TableCell>
          //             <TableCell align="center">
          //               <IconButton
          //                 aria-label="delete"
          //             b    size="large"
          //                 style={{ height: "16px" }}
          //                 onClick={() => {
          //                   setOpen(true);
          //                   setDeleteId(row.id);
          //                 }}
          //                 // onClick={(e) => {
          //                 //   setOpen(true);

          //                 //   setDeleteTemplate(row?.id);
          //                 //   // EditTemplate(row?.id);
          //                 // }}
          //               >
          //                 <DeleteIcon />
          //               </IconButton>
          //               <IconButton
          //                 aria-label="edit"
          //                 size="large"
          //                 onClick={async () => {
          //                   setDataReviewSource(row);
          //                   setTimeout(function () {
          //                     setOpenModalAddReview(true);
          //                   }, 500);
          //                 }}
          //               >
          //                 <Edit />
          //               </IconButton>
          //             </TableCell>
          //           </TableRow>
          //         ))}
          //     </TableBody>
          //   </Table>
          // </TableContainer>
        )}
      </>
    </>
  );
};

export default Index;
