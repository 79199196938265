import { CHANGE_THEME } from "./types";
const initialState = {
  activeMode: "light",
};

// Use the initialState as a default value

export const ThemeReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_THEME:
      return {
        ...state,
        activeMode: payload,
      };

    default:
      return state;
  }
};
