import React, { useEffect, useState } from "react";
import { Card, Paper, Grid, Typography, Divider, Box } from "@mui/material";
import { useStyles } from "../Styles/style";
import { useTranslation } from "react-i18next";
import FaqFilters from "./FaqFilters";
import FaqTable from "./FaqTable";
import NotFound from "../../../../components/NotFound/NotFound";
import Loader from "../../../../components/Loaders/Loader";

const UserFaqsMain = ({
  allLocation,
  isLoading,
  setLocationRequired,
  locationRequired,
  handleLocationChange,
  locationId,
  setCallApi,
  allGroups,
  handleChangeGroups,
  selectedGroup,
  locationViewPermission,
  viewPermission,
  addPermission,
  editPermission,
  deletePermission,
  locationAddPermission,
  locationEditPermission,
  locationDeletePermission,
  BootstrapTooltip,
  handleNavigationToGroups,
  resetHandler,
  setLocationId,
  faqsData,
  faqsLoading,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  handleGetFaqsTableData,
  handleDisplayLocationName,
  modelQuestionText,
  setModelQuestionText,
  handleDisplayNames,
  companyData,
  userData,
  filtersCounterValue,
  setFilterCounterValue,
  noQuestions,
  setIsTotalQFilterClicked,
  setIsNoOwnerAnswerClicked,
  setWithoutAnswerClicked,
  setIsPerEntityClicked,
  isTotalQFilterClicked,
  isNoOwnerAnswerClicked,
  withoutAnswerClicked,
  isPerEntityClicked,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [withoutAnsQuestions, setWithoutAnsQuestions] = useState();
  const [withoutOwnerAnswer, setWithoutOwnerAnswer] = useState();

  useEffect(() => {
    if (faqsData && faqsData.rows.length > 0) {
      const zeroAnswerCountRows = faqsData.rows.filter(
        (row) => row && row.totalAnswerCount === 0
      );
      const withoutAns = zeroAnswerCountRows.length;

      setWithoutAnsQuestions(withoutAns);

      const withoutOwnerAnswerRows = faqsData.rows.filter(
        (row) => row && row.ownerAnswer === 0
      );
      const withoutOwnAns = withoutOwnerAnswerRows.length;

      setWithoutOwnerAnswer(withoutOwnAns);
    }
  }, [faqsData]);

  return (
    <>
      {/* {faqsData === null && faqsLoading ? (
        <Loader />
      ) : ( */}

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Card className={classes.cardbox}>
            <Paper variant="outlined">
              <Grid container className={classes.mainContainer}>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={
                        !isTotalQFilterClicked
                          ? classes.centerGrid
                          : classes.centerGridFilter
                      }
                      onClick={() => {
                        setFilterCounterValue("");
                        setIsTotalQFilterClicked(true);
                        setPage(0);
                        setIsNoOwnerAnswerClicked(false);
                        setWithoutAnswerClicked(false);
                        setIsPerEntityClicked(false);
                      }}
                    >
                      <Typography className={classes.counters}>
                        {noQuestions ? 0 : faqsData?.count}
                      </Typography>
                      <Typography className={classes.counterText}>
                        {t("Total_Questions")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={
                        !isNoOwnerAnswerClicked
                          ? classes.centerGrid
                          : classes.centerGridFilter
                      }
                      onClick={() => {
                        setFilterCounterValue("ownerAnswer");
                        setPage(0);
                        setIsNoOwnerAnswerClicked(true);
                        setIsTotalQFilterClicked(false);
                        setWithoutAnswerClicked(false);
                        setIsPerEntityClicked(false);
                      }}
                    >
                      <Typography className={classes.counters}>
                        {noQuestions ? 0 : faqsData?.withoutOwnerAnswerCount}
                      </Typography>
                      <Typography className={classes.counterText}>
                        {t("Questions_without_Owner_Answers")}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      className={
                        !withoutAnswerClicked
                          ? classes.centerGrid
                          : classes.centerGridFilter
                      }
                      onClick={() => {
                        setFilterCounterValue("noAnswer");
                        setPage(0);
                        setWithoutAnswerClicked(true);
                        setIsNoOwnerAnswerClicked(false);
                        setIsTotalQFilterClicked(false);
                        setIsPerEntityClicked(false);
                      }}
                    >
                      <Typography className={classes.counters}>
                        {noQuestions ? 0 : faqsData?.withNoAnswerCount}
                      </Typography>
                      <Typography className={classes.counterText}>
                        {t("Questions_without_any_Answers")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Card>
          <FaqFilters
            allLocation={allLocation}
            isLoading={isLoading}
            setLocationRequired={setLocationRequired}
            locationRequired={locationRequired}
            handleLocationChange={handleLocationChange}
            locationId={locationId}
            setCallApi={setCallApi}
            allGroups={allGroups}
            handleChangeGroups={handleChangeGroups}
            selectedGroup={selectedGroup}
            locationViewPermission={locationViewPermission}
            locationAddPermission={locationAddPermission}
            BootstrapTooltip={BootstrapTooltip}
            handleNavigationToGroups={handleNavigationToGroups}
            resetHandler={resetHandler}
            setLocationId={setLocationId}
          />
          <Box mt={5} mb={2}>
            <Divider variant="middle" />
          </Box>
          {/* {faqsData?.rows?.length > 0 ? ( */}
          <FaqTable
            isLoading={faqsLoading}
            viewPermission={viewPermission}
            deletePermission={deletePermission}
            editPermission={editPermission}
            addPermission={addPermission}
            BootstrapTooltip={BootstrapTooltip}
            faqsData={faqsData}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            handleGetFaqsTableData={handleGetFaqsTableData}
            handleDisplayLocationName={handleDisplayLocationName}
            modelQuestionText={modelQuestionText}
            setModelQuestionText={setModelQuestionText}
            handleDisplayNames={handleDisplayNames}
            companyData={companyData}
            userData={userData}
            filtersCounterValue={filtersCounterValue}
            isTotalQFilterClicked={isTotalQFilterClicked}
            isNoOwnerAnswerClicked={isNoOwnerAnswerClicked}
            withoutAnswerClicked={withoutAnswerClicked}
            noQuestions={noQuestions}
          />
        </>
      )}
      {/* ) : (
            <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
              <NotFound text={t("No_faqs_found")} />
            </Box>
            ) */}

      {/* } */}

      {/* )
      
      } */}
    </>
  );
};
export default UserFaqsMain;
