import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Zoom,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import LinearProgress from "@mui/material/LinearProgress";
import { api } from "../../../../../contexts/JWTContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../../styles/style";
import SettingsIcon from "@mui/icons-material/Settings";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const ProfilePictureModal = ({
  img,
  handleDelete,
  setPostData,
  locationId,
  handleClose,
  index,
  isUploading,
  setIsUploading,
  postData,
  afterUploadSelectedChange,
  handleImageError,
  handleClickCloseWarning,
  handleOpenPreviewImage,
  selectedImages,
  setSelectedImages,
  cropImageName,
  handleOptimize,
  handleOpenOptimizeImageModel,
  setIndexValueResize,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isUploaded, setIsUploaded] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageArea, setImageArea] = useState(null);
  const [heightError, setHeightError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [cropImageError, setCropImageError] = useState(false);

  useEffect(async () => {
    if (isUploading) {
      await uploadImages();
    }
  }, [isUploading]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const uploadImages = async () => {
    try {
      //let tempImages = [];
      let formData = new FormData();

      // selectedImages.forEach((item) => {
      formData.append("photos", img.picture);
      // });

      setIsLoading(true);
      const res = await api.post(`/google/post/${locationId}`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
        },
      });
      if (res.status === 200) {
        let tempArray = [];
        res.data.filesArray?.forEach((item) => {
          tempArray.push({
            name: img?.picture?.name,
            locationIds: [],
            content: "",
            startDate: null,
            endDate: null,
            repeat: "no",
            postType: "CALL",
            customSchedule: {
              frequency: "",
              occurance: 1,
              days: [],
              dates: [],
            },

            isUploadPicture: true,
            uploadUberAll: false,
            url: "",
            title: "",
            errorUberAll: img?.hasOwnProperty("errorUberAll") ? true : false,
            postPictures: [
              {
                url: item?.location,
                key: item?.key,
              },
            ],
          });

          img["key"] = item?.key;

          //  afterUploadSelectedChange(index, item?.key);
        });
        tempArray.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          // Check if both names start with numbers
          const startsWithNumberA = /^\d/.test(nameA);
          const startsWithNumberB = /^\d/.test(nameB);

          // Compare based on whether names start with numbers or not
          if (startsWithNumberA && !startsWithNumberB) {
            return -1; // A should come first
          } else if (!startsWithNumberA && startsWithNumberB) {
            return 1; // B should come first
          } else {
            // Both start with numbers or both don't, so compare them as strings
            return nameA.localeCompare(nameB);
          }
        });

        let tempPostData;
        setPostData((state) => {
          tempPostData = [...state, ...tempArray]; // Merge existing state with tempArray
          return tempPostData;
        });

        // Sort tempPostData based on a specific property (e.g., 'name')

        tempPostData.sort(function (a, b) {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          const startsWithNumberA = /^\d/.test(nameA);
          const startsWithNumberB = /^\d/.test(nameB);

          if (startsWithNumberA && !startsWithNumberB) {
            return -1;
          } else if (!startsWithNumberA && startsWithNumberB) {
            return 1;
          } else {
            const numA = parseInt(nameA.match(/\d+/));
            const numB = parseInt(nameB.match(/\d+/));

            if (!isNaN(numA) && !isNaN(numB)) {
              return numA - numB;
            } else if (!isNaN(numA)) {
              return -1;
            } else if (!isNaN(numB)) {
              return 1;
            } else {
              return nameA.localeCompare(nameB);
            }
          }
        });

        setIsUploaded(true);
        setIsLoading(false);

        // handleClose();
      }
    } catch (error) {
      setIsUploaded(false);

      setIsLoading(false);
    }
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Item
          sx={{
            gap: "1rem",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: "80px",
              height: "80px",
              marginY: "10px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenPreviewImage(selectedImages, true, index);
            }}
            onLoad={(data) => {
              data?.target?.naturalHeight &&
                data?.target?.naturalWidth &&
                setImageArea({
                  height: data?.target?.naturalHeight,
                  width: data?.target?.naturalWidth,
                });
              if (
                data?.target?.naturalHeight < 250 ||
                data?.target?.naturalWidth < 250
              ) {
                handleImageError(index);
              }
              if (
                data?.target?.naturalWidth < 480 ||
                data?.target?.naturalWidth > 3000
              ) {
                handleImageError(index, "uberAll");
                setWidthError(true);
              }
              if (
                data?.target?.naturalHeight < 480 ||
                data?.target?.naturalHeight > 3000
              ) {
                handleImageError(index, "uberAll");
                setHeightError(true);
              }

              if (
                data?.target?.naturalWidth > 3000 ||
                data?.target?.naturalHeight > 3000
              ) {
                handleImageError(index, "uberAll");
                setCropImageError(true);
              } else {
                setCropImageError(false);
              }

              if (img?.picture?.size < 10000) {
                handleImageError(index);
              }
              if (img?.picture?.size < 10000 || img?.picture?.size > 5000000) {
                handleImageError(index, "uberAll");
                setSizeError(true);
              }
            }}
            alt="Remy Sharp"
            src={img?.picturePreview}
          />
        </Item>
        <Item
          sx={{
            paddingLeft: "20px",
            flex: "auto",
            gap: "1rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#1B2430",
                  fontWeight: "400",
                }}
              >
                {img?.picture?.name?.slice(0, 30)}
                {img?.picture?.name?.length > 30 ? "..." : ""}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "gray",
                  fontWeight: "400",
                }}
              >
                {formatBytes(img?.picture?.size)}{" "}
                {imageArea && `(${imageArea?.width}px/${imageArea?.height}px)`}
              </Typography>
            </Box>
          </Box>
          {img?.errorUberAll === true && (
            <BootstrapTooltip
              title={
                <h1 style={{ fontSize: "12px" }}>
                  {`${heightError ? t("uberAll_Height_error") : ""} ${
                    widthError ? t("uberAll_Width_error") : ""
                  } ${sizeError ? t("uberAll_size_error") : ""}`}
                </h1>
              }
            >
              <Grid container className={classes.mainContainerUberAll}>
                <Grid
                  item
                  xs={0.7}
                  sm={0.7}
                  md={0.7}
                  lg={0.7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <ErrorOutlineIcon className={classes.errorIconUberAll} />
                </Grid>
                <Grid item xs={11} sm={11} md={11} lg={11}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    sx={{ display: "flex" }}
                  >
                    <Typography className={classes.errorTextUberAll}>
                      {t("uberAll_error")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={0.3}
                  sm={0.3}
                  md={0.3}
                  lg={0.3}
                  sx={{
                    textAlign: "end",
                  }}
                >
                  <CloseIcon
                    fontSize="small"
                    onClick={() => handleClickCloseWarning(index)}
                    className={classes.closeIconUberAll}
                  />
                </Grid>
              </Grid>
            </BootstrapTooltip>
          )}
        </Item>
        <Item
          sx={{
            width: "180px",
            gap: "1rem",
          }}
        >
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : isUploaded === null ? (
            <Grid>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FC3652",
                  fontWeight: "400",
                }}
              >
                {imageArea?.width < 250 || imageArea?.height < 250
                  ? "Min: 250px/250px w/h"
                  : ""}
                {img?.picture?.size < 10000 ? "  Min size: 10 KB" : ""}
              </Typography>
            </Grid>
          ) : (
            <Typography
              sx={{
                // alignItems: "start",

                fontSize: "14px",
                color: isUploaded ? "#13CF8F" : "#FC3652",
                fontWeight: "400",
              }}
            >
              {isUploaded ? t("Uploaded_successfully") : t("Uploaded_failed")}
            </Typography>
          )}
        </Item>

        <Item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isUploaded === false ? (
            <CustomWidthTooltipOfDelete title="retry">
              <IconButton
                sx={{
                  color: "#8D9298",
                  width: "20px",
                  height: "18px",
                }}
                disabled={isLoading ? true : false}
                aria-label="retry"
                size="large"
                // color="#8D9298"
                onClick={() => uploadImages()}
              >
                <ReplayIcon />
              </IconButton>
            </CustomWidthTooltipOfDelete>
          ) : (
            <>
              {cropImageError === true && (
                <BootstrapTooltip title={t("edit_image_tooltipText")}>
                  <IconButton
                    onClick={() => handleOpenOptimizeImageModel(index)}
                  >
                    <SettingsIcon />
                  </IconButton>
                </BootstrapTooltip>
              )}
              <CustomWidthTooltipOfDelete
                // color="#8D9298"
                title="Delete"
              >
                <IconButton
                  sx={{
                    color: "#8D9298",
                    width: "20px",
                    height: "18px",
                  }}
                  disabled={isLoading ? true : false}
                  aria-label="delete"
                  size="large"
                  // color="red"
                  onClick={() => {
                    handleDelete(index);
                    // Optionally, you can update the key for the image after deletion here:
                    // For example, if you have a new key value:
                    // const newKey = "your-new-key-value";
                    // updateKeyForImage(newKey);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </CustomWidthTooltipOfDelete>
            </>
          )}
        </Item>
      </Stack>
    </>
  );
};

export default ProfilePictureModal;
