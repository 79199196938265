import React from "react";
import { makeStyles } from "@mui/styles";

import TextField from "@mui/material/TextField";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { IconButton, Paper } from "@mui/material";
import { height } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "white",
    },
    input: {
      color: "white",
      height: "48px",
    },
  },
}));

const SearchField = ({
  filterByName,
  onSubmit,
  placeholder,
  error,
  setError,
  filterWithName,
}) => {
  const classes = useStyles();
  return (
    <>
      <TextField
        component={Paper}
        placeholder={placeholder}
        className={classes.root}
        error={error}
        fullWidth
        autoComplete="off"
        id="outlined-name"
        value={filterWithName}
        onSubmit={onSubmit}
        InputProps={{
          className: classes.input,

          startAdornment: (
            <IconButton>
              <SearchOutlinedIcon />
            </IconButton>
          ),
        }}
        onKeyPress={(event) => {
          // setError(false);
          if (event.key === "Enter") {
            onSubmit();
          }
        }}
        onChange={filterByName}
      />
    </>
  );
};

export default SearchField;
