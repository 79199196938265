import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../../../contexts/JWTContext";
import Loader from "../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useMediaQuery from "@mui/material/useMediaQuery";
import Editors from "../../../../components/CustomComponents/Editor/Editors";
import BusinessIcon from "@mui/icons-material/Business";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import Status from "../../../../components/status/Status";
import useAuth from "../../../../hooks/useAuth";
import TitleHeader from "../../../../components/CustomComponents/title";
import { useStyles } from "../styles/style";

//   search: {
//     position: "relative",
//     display: "flex",
//     alignItems: "center",
//     border: "1px solid black",
//     padding: "3px",
//     borderRadius: theme.shape.borderRadius,
//     backgroundColor: alpha(theme.palette.common.white, 0.15),

//     marginRight: theme.spacing(0),
//     marginLeft: 0,
//     width: "300px",
//     [theme.breakpoints.up("sm")]: {
//       // marginLeft: theme.spacing(3),
//       width: "300px",
//     },
//   },
//   searchIcon: {
//     height: "100%",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//   },
//   closeIcon: {
//     height: "100%",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   inputRoot: {
//     color: "inherit",
//   },
//   inputInput: {
//     padding: theme.spacing(1, 1, 1, 0),
//     paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
//     transition: theme.transitions.create("width"),
//     width: "96%",
//     [theme.breakpoints.up("md")]: {
//       width: "100%",
//     },
//     backArrow: {
//       height: "40px",
//       fontSize: "20px",
//       marginLeft: "5px",
//       color: "blue",
//       textDecoration: "none",
//       border: "2px solid red",
//     },
//     textField: {
//       height: "10px",
//       backgroundColor: "red",
//       border: "2px  solid red",
//       marginLeft: "10px",
//     },
//   },
// }));
const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

const DetailTask = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useAuth();
  const { t } = useTranslation();
  const params = useParams();
  const { id } = params;
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(null);
  const [getSelectedTask, setGetSelectedTask] = useState({});
  const [addComment, setAddComment] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [commentsDetails, setCommentsDetails] = useState([]);

  useEffect(() => {
    getSelectedTasks();
    getAllComments();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const handleClose = () => {
    setOpen(null);
  };

  const getSelectedTasks = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/tasks/${id}`);
      if (res.status === 200) {
        setGetSelectedTask(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const getAllComments = async () => {
    try {
      setButtonLoader(true);
      const res = await api.get(`/comments/task/${id}`);
      if (res.status === 200) {
        setCommentsDetails(res?.data?.data?.results);
        // setGetComment(res.data.data);
        setButtonLoader(false);
      }
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      const res = await api.delete(`/comments/${commentId}`);
      if (res.status === 200) {
        getAllComments();

        // setCommentDelete(res.data.data);
        // setDeleteComment(res.data.data);
        setOpen(null);
      }
    } catch (error) {}
  };

  const postComment = async () => {
    try {
      setButtonLoader(true);
      const res = await api.post("/comments", {
        text: addComment,
        taskId: id,
      });
      if (res.status === 201) {
        // setGetSelectedTask(res.data.data);
        // getSelectedTasks();
        setAddComment("");
        getAllComments();
        setButtonLoader(false);
      }
    } catch (error) {
      setButtonLoader(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            showButton={true}
            title="Task Details"
            subHeading="You can add your comments "
          />

          <Grid container sx={{ marginBottom: "20px" }} alignItems="center">
            <Grid item></Grid>
          </Grid>

          {/* <Divider spacing={5} /> */}

          <Card>
            <CardContent>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography ml={4} gutterBottom variant="h3" component="h2">
                  {getSelectedTask?.title}
                </Typography>
                <Status task={getSelectedTask} />

                {/* <Chip sx={{ ml: "15px" }} label="In progress" color="warning" /> */}
              </Grid>

              <Typography
                ml={4}
                mb={4}
                color="textSecondary"
                component="p"
                dangerouslySetInnerHTML={{
                  __html: getSelectedTask?.description,
                }}
              >
                {/* {getSelectedTask?.description} */}
              </Typography>

              <Grid
                className="company_grid"
                spacing={8}
                sx={{ display: "flex", alignContent: "center", ml: 4 }}
              >
                <BusinessIcon sx={{ mr: "10px" }} />
                <Typography mb={2} color="textSecondary" component="p">
                  {getSelectedTask?.company?.name}
                </Typography>
              </Grid>
              <Grid className={classes.locationDetailsTask} spacing={8}>
                <LocationOnIcon sx={{ mr: "5px" }} />
                <Typography mb={2} color="textSecondary" component="p">
                  {getSelectedTask?.location?.internalName}
                </Typography>
              </Grid>

              <Editors
                value={addComment}
                onChange={(e) => {
                  setAddComment(e);
                }}
              />

              <LoadingButton
                className={classes.addCommentBtn}
                onClick={() => {
                  postComment();
                }}
                variant="contained"
                loading={buttonLoader}
                style={{
                  color: "primary",
                }}
              >
                {t("Add_comment")}
              </LoadingButton>

              {commentsDetails &&
                commentsDetails.map((comment) => {
                  return (
                    <>
                      {open === comment?.id && (
                        <Dialog
                          fullScreen={fullScreen}
                          open={open !== null ? true : false}
                          onClose={handleClose}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <DialogTitle id="responsive-dialog-title">
                            {"Are you sure to Delete Comment?"}
                          </DialogTitle>

                          <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                              Disagree
                            </Button>
                            {/* onClick={setDeleteComment} */}
                            <Button
                              onClick={() => deleteComment(comment?.id)}
                              autoFocus
                            >
                              Agree
                            </Button>
                          </DialogActions>
                        </Dialog>
                      )}
                      <Grid
                        className={classes.commentSection}
                        // sx={commentSection}
                        // style={{ minWidth: "200px" }}
                      >
                        <Box className={classes.firstnameBox}>
                          <Box>
                            <Typography variant="h5" sx={{ marginTop: "10px" }}>
                              {comment.user.firstName}
                            </Typography>
                            <Typography
                              color="textSecondary"
                              sx={{ marginTop: "5px" }}
                              dangerouslySetInnerHTML={{
                                __html: comment.text,
                              }}
                            ></Typography>
                          </Box>
                          <Box className={classes.dateBox}>
                            <Typography>
                              {moment(getSelectedTask?.createdAt).fromNow()}
                            </Typography>
                            {comment?.user?.id === user?.id && (
                              <CustomWidthTooltipOfDelete title="Delete Comment">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  // setDeleteId(row.id)
                                  //setDeleteComment(id)
                                  onClick={(e) => {
                                    setOpen(comment?.id);
                                    // deleteComment(id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </CustomWidthTooltipOfDelete>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  );
                })}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default DetailTask;
