import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Tooltip,
  tooltipClasses,
  DialogTitle,
  TextField,
  DialogActions,
  DialogContentText,
  Button,
  Paper,
  Box,
  Checkbox,
  DialogContent,
  Dialog,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import Loader from "../../Loaders/Loader";
import { api } from "../../../contexts/JWTContext";
import SearchField from "../../CustomComponents/textfields/searchfield/SearchField";
import FetchModels from "./FetchModels.css";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useTranslation } from "react-i18next";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import CloseIcon from "@mui/icons-material/Close";
import PrimaryUserModal from "./PrimaryUserConfirmModel";
import { toast } from "react-toastify";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  root: {
    "& .MuiInputBase-root": {
      borderRadius: "800px",
    },
    "& .MuiFilledInput-root": {
      background: "white",
      borderRadius: "800px",
    },
    input: {
      color: "white",
      // height: "48px",
      borderRadius: "800px",
    },
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const FetchModel = ({
  title,
  open,
  subTitle,
  handleCloseModel,
  selectedAccount,
  fromAccounts,
  getAllData,
  modelType,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [deletePost, setDeletePost] = useState(null);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();
  const [filterWithName, setFilterWithName] = useState("");
  const [allLocationsModel, setAllLocationsModel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openPrimaryModel, setOpenPrimaryModel] = useState(false);
  const [primaryUser, setPrimaryUser] = useState(null);

  useEffect(() => {
    if (open) {
      getAllUsers();
    }
  }, [open]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search) {
        getAllUsers();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterWithName]);

  const getAllUsers = async () => {
    try {
      setIsLoading(true);
      // setLoading(true);
      const res = await api.get(
        `admin/users/${selectedAccount}?limit=1000&page=1&searchTerm=${filterWithName}`
      );
      if (res.status === 200) {
        // setAllCompanies(res.data.data);

        const filtered = res.data.data?.rows?.filter(
          (user) => user?.status === "active"
        );
        setUsers(filtered);
        const primaryUserData = res?.data?.data?.rows?.find(
          (item) =>
            item?.CompanyUserRoles?.length > 0 &&
            item?.CompanyUserRoles[0]?.isPrimary === true &&
            item?.CompanyUserRoles[0]?.linkedUser === false
        );
        // setLoading(false);
        if (primaryUserData !== undefined) {
          setPrimaryUser(primaryUserData);
        } else {
          setPrimaryUser(null);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const filterByName = (e) => {
    setFilterWithName(e.target.value);
    setSearch(true);
  };

  const handleChangeUser = (e, row) => {
    if (e.target.checked) {
      setSelectedUser(row);
    } else {
      setSelectedUser(null);
    }
  };

  const updateUserHandler = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/user/ownerTransfership`, {
        companyId: parseInt(selectedAccount),
        userId: selectedUser?.id,
      });
      if (res.status === 200) {
        setIsLoading(false);
        toast.error(t("Primary_user_toast"));
        handleClosePrimaryModel();
        handleCloseModel();
        getAllUsers();
      }
    } catch (error) {
      setIsLoading(false);
      handleClosePrimaryModel();
      handleCloseModel();

      toast.error(error?.response?.data?.message);
    }
  };

  const updateCompanyPrimaryUserHandler = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(
        `/company/${selectedAccount}/ownerTransfership`,
        {
          companyId: parseInt(selectedAccount),
          userId: selectedUser?.id,
        }
      );
      if (res.status === 200) {
        setIsLoading(false);
        getAllData(false);
        handleClosePrimaryModel();
        handleCloseModel();

        toast.success("Successfully Updated");
      }
    } catch (error) {
      setIsLoading(false);
      handleClosePrimaryModel();
      handleCloseModel();

      toast.error(error?.response?.data?.message);
    }
  };
  const handleClosePrimaryModel = () => {
    setOpenPrimaryModel(false);
  };
  const handleOpenPrimaryModel = () => {
    setOpenPrimaryModel(true);
  };
  return (
    <>
      <div>
        {openPrimaryModel && (
          <PrimaryUserModal
            openPrimaryModel={openPrimaryModel}
            selectedRow={selectedUser}
            isLoading={isLoading}
            handleClosePrimaryModel={handleClosePrimaryModel}
            updateUserHandler={updateCompanyPrimaryUserHandler}
            primaryUser={primaryUser}
            modelType={modelType}
          />
        )}
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "770px",
              height: "500px",
              borderRadius: "4px",
            },
          }}
          fullScreen
          open={open}
          onClose={handleCloseModel}
        >
          <DialogTitle>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                }}
              >
                {title}
              </Typography>

              <IconButton
                autoFocus
                onClick={handleCloseModel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
              }}
              style={{ color: "#495059" }}
            >
              {subTitle}
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <Grid container sx={{ paddingTop: "5px" }}>
                  <Grid item sm={7} md={7} lg={7}>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        color: "#1B2430",
                      }}
                    >
                      {t("User")}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={5} md={5}>
                    <TextField
                      placeholder={t("FilterUser")}
                      sx={{ borderRadius: "800px" }}
                      fullWidth
                      className={classes.root}
                      autoComplete="off"
                      //  id="outlined-name"
                      value={filterWithName}
                      onSubmit={getAllUsers}
                      size="small"
                      InputProps={{
                        className: classes.input,

                        startAdornment: (
                          <IconButton>
                            <SearchOutlinedIcon />
                          </IconButton>
                        ),
                      }}
                      /*  onKeyPress={(event) => {
                        // setError(false);
                        if (event.key==="Enter") {
                          getAllUsers();
                        }
                      }} */
                      onChange={filterByName}
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      gap: "10px",
                      marginTop: "12px",
                      padding: "8px",
                      border: "1px solid #EBEBEC",
                      borderRadius: "8px",
                    }}
                  >
                    {users?.length > 0 ? (
                      users?.map((item) => (
                        <Item
                          onClick={() => ""}
                          style={{
                            padding: "12px 8px 12px 8px",
                            gap: "4px",
                            border:
                              item?.CompanyUserRoles?.length > 0 &&
                              (item?.CompanyUserRoles[0]?.isPrimary === true ||
                                item?.CompanyUserRoles[0]?.linkedUser === true)
                                ? "none"
                                : selectedUser !== null &&
                                  selectedUser?.id === item?.id
                                ? "1px solid #06BDFF"
                                : "1px solid #BBBDC1",
                            borderRadius: "800px",
                            background:
                              item?.CompanyUserRoles?.length > 0 &&
                              (item?.CompanyUserRoles[0]?.isPrimary === true ||
                                item?.CompanyUserRoles[0]?.linkedUser === true)
                                ? "#F0F0F0"
                                : selectedUser !== null &&
                                  selectedUser?.id === item?.id
                                ? "#FFF"
                                : "#FFF",

                            //  height: "45px",
                          }}
                        >
                          <Checkbox
                            checked={
                              selectedUser !== null &&
                              selectedUser?.id === item?.id
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              handleChangeUser(e, item);
                            }}
                            disabled={
                              item?.CompanyUserRoles?.length > 0 &&
                              (item?.CompanyUserRoles[0]?.isPrimary === true ||
                                item?.CompanyUserRoles[0]?.linkedUser === true)
                            }
                            size="small"
                            sx={{
                              paddingLeft: "2px",
                              paddingRight: "2px",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "400",
                              lineHeight: "18px",
                              color:
                                item?.CompanyUserRoles?.length > 0 &&
                                (item?.CompanyUserRoles[0]?.isPrimary ===
                                  true ||
                                  item?.CompanyUserRoles[0]?.linkedUser ===
                                    true)
                                  ? "gray !important"
                                  : selectedUser !== null &&
                                    selectedUser?.id === item?.id
                                  ? "#1B2430"
                                  : "#1B2430",
                            }}
                          >
                            {item?.firstName !== null &&
                              item?.firstName.charAt(0).toUpperCase() +
                                item?.firstName.slice(1) +
                                (item?.lastName !== null &&
                                  item?.lastName !== "" &&
                                  " " +
                                    item?.lastName.charAt(0).toUpperCase() +
                                    item?.lastName.slice(1))}
                          </Typography>

                          {item?.CompanyUserRoles?.length > 0 &&
                            item?.CompanyUserRoles[0]?.isPrimary === true &&
                            item?.CompanyUserRoles[0]?.linkedUser === false &&
                            item?.companyId === parseInt(selectedAccount) && (
                              <span
                                style={{
                                  textAlign: "center",
                                  // marginLeft: "8px",
                                  // marginRight: "18px",
                                  color: "#13CF8F",
                                }}
                              >
                                {"• " + `${t("Primary_text")}`}
                              </span>
                            )}
                          {item?.CompanyUserRoles?.length > 0 &&
                            item?.CompanyUserRoles[0]?.linkedUser && (
                              <BootstrapTooltip title={t("Linked_User")}>
                                <IconButton aria-label="edit" size="large">
                                  <LinkOffIcon />
                                </IconButton>
                              </BootstrapTooltip>
                            )}
                        </Item>
                      ))
                    ) : (
                      <Grid
                        container
                        sx={{
                          gap: "10px",
                          marginTop: "12px",
                          padding: "8px",
                          height: "200px",
                          borderRadius: "8px",
                          textAlign: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography variant="h3" sx={{ textAlign: "center" }}>
                          {t("No_User_Found")}
                        </Typography>
                      </Grid>
                    )}{" "}
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                handleCloseModel();
              }}
              className="delete_button"
              sx={{ color: "#1B2430" }}
            >
              {t("Cancel")}
            </Button>

            <Button
              autoFocus
              disabled={selectedUser === null}
              onClick={() => handleOpenPrimaryModel()}
              className="delete_button"
            >
              {t("Submit")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* </DialogActions> */}
      </div>
    </>
  );
};

export default FetchModel;
