import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Zoom,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import LinearProgress from "@mui/material/LinearProgress";
import { api } from "../../../../../../contexts/JWTContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { Construction } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CloseIcon from "@mui/icons-material/Close";
import { useStyles } from "../../../../UserListing/Styles/style";
import SettingsIcon from "@mui/icons-material/Settings";
import CropImgModal from "./CropImgModal";

const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const CoverImagesCard = ({
  selectedImages,
  img,
  handleDelete,

  handleClose,
  index,
  isUploading,
  setIsUploading,
  handleImageError,
  uploadedImages,
  setUploadedImages,
  setSelectedImages,
  handleOpenOptimizeImageModel,
  closeCropModal,
  openCropModel,
  setOpenCropModel,
  handleOptimize,
  setSelectedFile,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isUploaded, setIsUploaded] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageArea, setImageArea] = useState(null);
  const [imgRatio, setImgRatio] = useState(null);
  const [heightError, setHeightError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [sizeError, setSizeError] = useState(false);
  const [ratioError, setRatioError] = useState(false);
  const [cropImageError, setCropImageError] = useState(false);

  useEffect(async () => {
    if (isUploading) {
      await uploadImages();
    }
  }, [isUploading]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  const uploadImages = async () => {
    try {
      //let tempImages = [];
      let formData = new FormData();

      // selectedImages.forEach((item) => {
      formData.append("logo", img.picture);
      // });

      setIsLoading(true);
      const res = await api.post(`/reportSchedules/customLogo`, formData, {
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50;

          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress =
            50 + (progressEvent.loaded / progressEvent.total) * 50;
          setProgress(progress);
        },
      });

      if (res.status === 200) {
        let tempObj = {
          imagePath: res?.data?.imagePath,
        };
        uploadedImages.push(tempObj);
        setIsUploaded(true);
        setUploadedImages([...uploadedImages]);
        setSelectedFile(img.picture);

        setIsLoading(false);
      }
    } catch (error) {
      setIsUploaded(false);

      setIsLoading(false);
    }
  };
  function getGCD(a, b) {
    return b === 0 ? a : getGCD(b, a % b);
  }
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Item
          sx={{
            gap: "1rem",
          }}
        >
          <Avatar
            variant="square"
            sx={{
              width: "56px",
              height: "56px",
              marginY: "10px",
              borderRadius: "8px",
            }}
            onLoad={(item) => {
              item?.target?.naturalHeight &&
                item?.target?.naturalWidth &&
                setImageArea({
                  height: item?.target?.naturalHeight,
                  width: item?.target?.naturalWidth,
                });
              const gcd = getGCD(
                item?.target?.naturalWidth,
                item?.target?.naturalHeight
              );
              const ratio = `${item?.target?.naturalWidth / gcd}:${
                item?.target?.naturalHeight / gcd
              }`;
              setImgRatio(ratio);

              if (ratio !== "1:1") {
                setRatioError(true);
                if (
                  item?.target?.naturalWidth > 250 &&
                  item?.target?.naturalHeight > 250
                ) {
                  setCropImageError(true);
                } else {
                  setCropImageError(false);
                }
              } else {
                setRatioError(false);
              }

              if (
                item?.target?.naturalWidth > 720 ||
                item?.target?.naturalHeight > 720
              ) {
                handleImageError(index);
                setCropImageError(true);
              } else {
                setCropImageError(false);
              }

              if (
                item?.target?.naturalHeight < 250 ||
                item?.target?.naturalHeight > 780
              ) {
                setHeightError(true);
                handleImageError(index);
              } else {
                setHeightError(false);
              }
              if (
                item?.target?.naturalWidth < 250 ||
                item?.target?.naturalWidth > 780
              ) {
                setWidthError(true);
                handleImageError(index);
              } else {
                setWidthError(false);
              }
              if (img?.picture?.size < 10000 || img?.picture?.size > 5000000) {
                setSizeError(true);
                handleImageError(index);
              } else {
                setSizeError(false);
              }
            }}
            alt="Remy Sharp"
            src={img?.picturePreview}
          />
        </Item>
        <Item
          sx={{
            paddingLeft: "20px",
            flex: "auto",
            gap: "1rem",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#1B2430",
                  fontWeight: "400",
                }}
              >
                {img?.picture?.name.slice(0, 30)}
                {/*  {img?.picture?.name?.length > 30 ? "..." : ""} */}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "gray",
                  fontWeight: "400",
                }}
              >
                {formatBytes(img?.picture?.size)}{" "}
                {imageArea && `(${imageArea?.width}px/${imageArea?.height}px)`}
              </Typography>
            </Box>
          </Box>

          {(heightError || widthError || ratioError || sizeError) && (
            <Grid container className={classes.mainContainerUberAllForCard}>
              <Grid
                item
                xs={1}
                sm={1}
                md={1}
                lg={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <ErrorOutlineIcon className={classes.errorIconUberAll} />
              </Grid>
              <Grid item xs={11} sm={11} md={11} lg={11}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex" }}
                >
                  <Typography className={classes.errorTextUberAll}>
                    {heightError && t("logo_Height_error")}
                    {widthError && t("logo_Width_error")}
                    {ratioError && t("logo_ratio_error")}
                    {sizeError && t("logo_size_error")}
                  </Typography>
                </Grid>
              </Grid>
              {/*  <Grid
              item
              xs={0.8}
              sm={0.8}
              md={0.8}
              lg={0.8}
              sx={{
                textAlign: "end",
              }}
            >
              <CloseIcon
                fontSize="small"
                // onClick={() => handleClickCloseWarning(index)}
                className={classes.closeIconUberAll}
              />
            </Grid> */}
            </Grid>
          )}
        </Item>
        <Item
          sx={{
            width: "180px",
            gap: "1rem",
          }}
        >
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          ) : isUploaded === null ? (
            <Grid>
              {/*   <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FC3652",
                  fontWeight: "400",
                }}
              >
                {imageArea?.width < 250 || imageArea?.height < 250
                  ? "Min: 250px/250px w/h"
                  : ""}
                {imgRatio !== "1:1" ? "Max Ratio: 1:1" : ""}
                {img?.picture?.size < 10000 || img?.picture?.size > 5000000
                  ? "  Min size: 10 KB, Max size: 5MB"
                  : ""}
              </Typography> */}
            </Grid>
          ) : (
            <Typography
              sx={{
                // alignItems: "start",

                fontSize: "14px",
                color: isUploaded ? "#13CF8F" : "#FC3652",
                fontWeight: "400",
              }}
            >
              {isUploaded ? t("Uploaded_successfully") : t("Uploaded_failed")}
            </Typography>
          )}
        </Item>

        <Item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {isUploaded === false ? (
            <CustomWidthTooltipOfDelete title="retry">
              <IconButton
                sx={{
                  color: "#8D9298",
                  width: "20px",
                  height: "18px",
                }}
                disabled={isLoading ? true : false}
                aria-label="retry"
                size="large"
                // color="#8D9298"
                onClick={() => uploadImages()}
              >
                <ReplayIcon />
              </IconButton>
            </CustomWidthTooltipOfDelete>
          ) : (
            <>
              {" "}
              {cropImageError === true && (
                <BootstrapTooltip title={t("edit_image_tooltipText")}>
                  <IconButton
                    onClick={() => handleOpenOptimizeImageModel(index)}
                  >
                    <SettingsIcon />
                  </IconButton>
                </BootstrapTooltip>
              )}
              <CustomWidthTooltipOfDelete
                // color="#8D9298"
                title="Delete"
              >
                <IconButton
                  sx={{
                    color: "#8D9298",
                    width: "20px",
                    height: "18px",
                  }}
                  disabled={isLoading ? true : false}
                  aria-label="delete"
                  size="large"
                  // color="red"
                  onClick={() => handleDelete(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </CustomWidthTooltipOfDelete>
            </>
          )}
        </Item>
      </Stack>
      {openCropModel && (
        <CropImgModal
          open={openCropModel}
          onClose={closeCropModal}
          uploadImgData={selectedImages}
          setUploadImgData={setSelectedImages}
          setOpenCropModal={setOpenCropModel}
          cropModalImage={img}
          setProgress={setProgress}
          progress={progress}
          handleOptimize={handleOptimize}
        />
      )}
    </>
  );
};

export default CoverImagesCard;
