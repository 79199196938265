import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, userRole } = useAuth();

  if (
    // isInitialized &&
    isAuthenticated &&
    userRole
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return <Navigate to="/auth/sign-in" />;
}

export default AuthGuard;
