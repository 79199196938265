import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Divider, Typography } from "@mui/material";
import useAuth from "../../../../hooks/useAuth";
import Details from "../Extras/accountDetails/Details";
import Users from "../users/UserManagement/Users/Users";
import UserRoles from "../users/UserManagement/UserRoles/UserRoles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { api, handleSessionOut } from "../../../../contexts/JWTContext";
import Groups from "../../UserGroups";
import ProductActivation from "../users/UserManagement/ProductActivation/index";
import { checkLocalStorageData } from "../../../../utils/LocalStorageHelpers";
import { toast } from "react-toastify";

const AccountsBanner = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isDataValid = checkLocalStorageData();
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [hour, setHour] = useState(null);
  const { user, permissionsAcess, signOut } = useAuth();
  const [userManagementPermissions, setUserManagementPermissions] =
    useState(null);
  const [viewPermission, setViewPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );
      setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setViewPermission(true);
        }
      });
    }
  }, []);
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    if (isDataValid) {
      getHour();
    }
  });
  useEffect(() => {
    if (isDataValid) {
      getAllCustomer();
      getSelectedCustomer();
      if (location.state !== null) {
        setSelectedTab(location.state.tabIndex);
        setPage(location.state.page);
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);

  const getSelectedCustomer = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/company/me`);
      if (res.data?.status === true) {
        localStorage.setItem("company", JSON.stringify(res?.data.data));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAllCustomer = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/company/${user?.companyId}`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0);
    if (isDataValid == false) {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  };

  const getHour = () => {
    const date = new Date();
    const hours = date.getHours();
    setHour(hours);
  };

  return (
    <>
      <Typography
        fontWeight={700}
        fontSize="24px"
        lineHeight="32px"
        sx={{ textTransform: "capitalize" }}
      >
        {hour < 12 ? `${t("Good_Morning")}` : `${t("Good_Evening")}`}
        {user?.firstName !== null &&
        user?.firstName !== "" &&
        user?.lastName !== null &&
        user?.lastName !== ""
          ? `, ${user?.firstName} ${user?.lastName}`
          : ""}
        👋
      </Typography>
      <Typography
        fontWeight={400}
        fontSize="14px"
        lineHeight="20px"
        color="#495059"
        sx={{ marginBottom: "0.5rem" }}
      >
        {t("sub_title")}
      </Typography>

      <Tabs
        sx={{ textTransform: "none", marginTop: "30px" }}
        value={selectedTab}
        onChange={handleChange}
        variant="scrollable"
      >
        {/*  <Tab label={t("Overview")} /> */}
        <Tab label={t("AccountDetails")} />
        {/*   <Tab label={t("ActiveProducts")} /> */}

        <Tab label={t("Users")} />

        <Tab label={t("roles")} />
        <Tab label={t("groups_label")} />
        {user?.role === "company-admin" && <Tab label={t("Products")} />}
      </Tabs>
      <Divider />
      {/*  {selectedTab === 0 && <Overview allCompanies={allCompanies} />} */}
      {selectedTab === 0 && (
        <Details
          setSelectedTab={setSelectedTab}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
        />
      )}
      {/*   {selectedTab === 2 && <ActiveProducts params={{ id: user?.companyId }} />} */}
      {selectedTab === 1 && (
        <Users
          selectedTab={selectedTab}
          params={{ id: user?.companyId }}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
          page={page}
          setPage={setPage}
        />
      )}
      {selectedTab === 2 && (
        <UserRoles
          selectedTab={selectedTab}
          addPermission={addPermission}
          editPermission={editPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}
        />
      )}
      {selectedTab === 3 && <Groups />}

      {selectedTab === 4 && <ProductActivation />}
    </>
  );
};

export default AccountsBanner;
