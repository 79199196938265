// ** React Imports ================================================================
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchField from "../../../components/CustomComponents/textfields/searchfield/SearchField";
import CommonButton from "../../../components/CustomComponents/Buttons/CommonButton";
import moment from "moment";
import CustomizedTableRow from "../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../components/CustomComponents/table/tableCell";

// ** File Imports ================================================================
import Loader from "../../../components/Loaders/Loader";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import TitleHeader from "../../../components/CustomComponents/title";

import Delete from "../../../components/Models/DeleteModal/Delete";
import LocationNotFound from "../../../components/CustomComponents/Errors/LocationNotFound";
import { checkLocalStorageData } from "../../../utils/LocalStorageHelpers";

// ** Material ui Imports ================================================================
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableSortLabel,
  Typography,
  Tooltip,
  tooltipClasses,
  Menu,
  Box,
  MenuItem,
  Paper,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Edit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components/macro";
import { Dialog } from "@material-ui/core";
import { TablePagination } from "@mui/material";
import { useStyles } from "./styles/style";
import useAuth from "../../../hooks/useAuth";
import useDateFormat from "../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Index = () => {
  // ** Others ================================================================
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const isDataValid = checkLocalStorageData();
  const { signOut, permissionsAcess } = useAuth();
  // ** States ================================================================
  const [loading, setLoading] = useState(false);
  const [allTemplate, setAllTemplate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterWithName, setFilterWithName] = useState("");
  const [open, setOpen] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState("");
  const [orderBy, setOrderBy] = React.useState("usage");
  const [order, setOrder] = React.useState("desc");
  const [viewPermission, setViewPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [editPermission, setEditPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));

  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/review"
      );
      // setUserManagementPermissions(filteredPermissions);

      filteredPermissions?.map((item) => {
        if (item?.permissionName === "create") {
          setAddPermission(true);
        }
        if (item?.permissionName === "edit") {
          setEditPermission(true);
        }
        if (item?.permissionName === "delete") {
          setDeletePermission(true);
        }
        if (item?.permissionName === "read") {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (isDataValid) {
      const delayDebounceFn = setTimeout(() => {
        getAllTemplate();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, [filterWithName]);

  useEffect(() => {
    if (isDataValid) {
      setLoading(true);
      getAllTemplate();
    }
  }, [rowsPerPage, page, order]);

  // ** Handler Functions ================================================================
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDeleteTemplate(id);
  };
  const closeMenu = () => {};
  const handleOpenDeleteModel = () => {
    setOpen(deleteTemplate);
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await signOut();
    /*  i18n.languages = [];
    i18n.language = "";
    i18n.translator.language = ""; */
    navigate("/auth/sign-in");
  };

  const getAllTemplate = async () => {
    setLoading(true);

    try {
      const res = await api.get(
        `/template?limit=${rowsPerPage}&page=${page + 1}&sortBy=${
          orderBy ? orderBy : ""
        }&sortOrder=${order}&searchTerm=${filterWithName}`
      );
      if (res.status === 200) {
        setAllTemplate(res?.data?.data);
        setCount(res?.data?.data?.count);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };

  const deleteTemplates = async (id) => {
    try {
      const res = await api.delete(`/template/${id}`);

      if (res.status === 200) {
        setFilterWithName("");
        setOpen(false);

        toast.success("Template Deleted Successfully");
        getAllTemplate();
      }
    } catch (error) {}
  };

  const handleRequestSort = (event, property) => {
    if (order === "asc") {
      setOrder("desc");
    } else if (order === "desc") {
      setOrder("asc");
    } else {
      setOrder("asc");
    }
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const CreateNavigateButton = () => {
    navigate(`/user/createtemplate`);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };

  const handleDefaultTemplate = async () => {
    try {
      setLoading(true);
      const res = await api.post("template/addDefault");
      if (res.status === 200) {
        getAllTemplate();
        toast.success(res?.data?.message, {});
        setOpenDefaultModel(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setOpenDefaultModel(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const filterByName = (e) => {
    setFilterWithName(e.target.value);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => ({
      el,
      index,
    }));
    stabilizedThis.sort((a, b) => {
      const order = comparator(a.el, b.el);
      if (order !== 0) return order;
      return a.index - b.index;
    });
    return stabilizedThis.map((element) => element.el);
  }
  const headCells = [
    { id: null, alignment: "left", label: t("AccountTableID") },
    { id: "title", alignment: "left", label: t("NAME") },
    { id: "usage", alignment: "center", label: t("USAGE") },
    { id: null, alignment: "center", label: t("CREATEDBY") },
    { id: "updatedAt", alignment: "center", label: t("UPDATEDAT") },
    { id: "actions", alignment: "center", label: t("Action") },
  ];

  const handleDisplayDate = (item) => {
    let displayDate = "";
    if (companyData.dateFormat === "May 19,2022 3:05PM") {
      displayDate = moment(item).format("MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "Monday,May 19,2022 3:05PM") {
      displayDate = moment(item).format("dddd,MMMM DD,YYYY LT");
    }
    if (companyData.dateFormat === "May 19,2022") {
      displayDate = moment(item).format("MMMM DD,YYYY");
    }
    if (companyData.dateFormat === "5/19/2022") {
      displayDate = moment(item).format("M/D/YYYY");
    }
    if (companyData.dateFormat === "05/19/2022") {
      displayDate = moment(item).format("MM/D/YYYY");
    }
    if (companyData.dateFormat === "28/12/2022") {
      displayDate = moment(item).format("D/MM/YYYY");
    } else {
      displayDate = moment(item).format("D/MM/YYYY");
    }
    if (displayDate.length > 0) {
      return displayDate;
    } else return null;
  };

  return (
    <>
      {open && (
        <Dialog
          // fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Template")}
            description={t("del_desc_temp")}
            onConfirm={() => deleteTemplates(deleteTemplate)}
            onCancel={handleClose}
          />
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog
          // fullScreen={fullScreen}
          open={openDefaultModel}
          onClose={handleCloseTemplateMenu}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Import_Default_Template")}
            description={t("default_template_subhead")}
            onConfirm={() => handleDefaultTemplate()}
            onCancel={handleCloseTemplateMenu}
          />
        </Dialog>
      )}
      <TitleHeader
        // showButton={true}
        title={t("Templates_Title")}
        subHeading={t("Sub_Title")}
        extraButton={true}
        extraButtonName={t("Import_Default_Template")}
        name={t("Create_Template")}
        CreateNavigateButton={CreateNavigateButton}
        handleDefaultTemplate={handleOpenTemplateMenu}
        count={count}
        addPermission={addPermission}
        viewPermission={viewPermission}
      />
      <Grid container className="user_template_grid1">
        <Grid className="user_template_grid2" item></Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={8} sm={9} md={10} lg={11}>
          <SearchField
            filterByName={filterByName}
            onSubmit={getAllTemplate}
            placeholder={t("Filter_templates")}
            filterWithName={filterWithName}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          md={2}
          lg={1}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <CommonButton
            // isLoading={isLoading}
            onSubmit={getAllTemplate}
            label={t("Search")}
            alignment={true}
          />
        </Grid>
      </Grid>

      {loading ? (
        <Loader />
      ) : (
        <>
          {allTemplate?.count > 0 ? (
            <>
              <TableContainer
                borderRadius="12"
                component={Paper}
                sx={{ marginTop: "1rem" }}
              >
                <Table
                  className={classes.table_style_index}
                  aria-label="simple table"
                >
                  <CustomizedTableHead variant="h1">
                    <CustomizedTableRow>
                      {headCells?.map((headCell) => (
                        <CustomizedTableCell
                          key={headCell.id}
                          align={headCell.alignment}
                          padding={headCell.disablePadding ? "none" : "normal"}
                          sortDirection={
                            orderBy === headCell.id ? order : false
                          }
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            disabled={headCell.id === null ? true : false}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </CustomizedTableCell>
                      ))}
                    </CustomizedTableRow>
                  </CustomizedTableHead>
                  <TableBody>
                    {allTemplate?.results.length > 0 &&
                      stableSort(
                        allTemplate?.results,
                        getComparator(order, orderBy)
                      ).map((row) => (
                        <CustomizedTableRow>
                          {/* <CheckBox /> */}
                          <CustomizedTableCell sx={{ cursor: "pointer" }}>
                            {row.id}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center" hover>
                            <Typography component="th" scope="row">
                              {" "}
                              {row.title}
                            </Typography>
                            <Typography>{row.text}</Typography>
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {row?.usage || "0"}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {/* {row?.user?.firstName + " " + row?.user?.lastName}   */}
                            {row?.user !== null &&
                            row?.user?.firstName !== null &&
                            row?.user?.lastName !== null
                              ? row?.user?.firstName + " " + row?.user?.lastName
                              : "--"}
                          </CustomizedTableCell>
                          <CustomizedTableCell align="center">
                            {" "}
                            <Box mr={4}>
                              {/* {companyData && companyData.dateFormat
                                ? handleDisplayDate(row?.updatedAt)
                                : moment(row?.updatedAt).format("MMM DD YYYY")} */}
                              {/* {moment(row?.updatedAt).format("MMM DD YYYY")} */}
                              {/* {handleDisplayDate(row?.updatedAt)} */}
                              {formatDate(row?.updatedAt)}
                            </Box>
                          </CustomizedTableCell>

                          <CustomizedTableCell align="center">
                            {/* <IconButton aria-label="details" size="large">
                        <MoreVertIcon />
                      </IconButton> */}
                            <Box mr={2}>
                              {viewPermission && editPermission ? (
                                <BootstrapTooltip title={t("Edit_Rule")}>
                                  <IconButton
                                    className={classes.icon_button}
                                    aria-label="edit"
                                    size="large"
                                    onClick={() =>
                                      navigate(`/user/edittemplate/${row?.id}`)
                                    }
                                  >
                                    <Edit />
                                  </IconButton>
                                </BootstrapTooltip>
                              ) : (
                                <BootstrapTooltip
                                  title={t("authorized_access")}
                                >
                                  <span>
                                    <IconButton variant="outlined" disabled>
                                      <Edit disabled />
                                    </IconButton>
                                  </span>
                                </BootstrapTooltip>
                              )}
                              {/* <CustomWidthTooltipOfDelete
                                title={t("Delete_Template")}
                              >
                                <Edit />
                              </IconButton>
                            </CustomWidthTooltip>
                            <CustomWidthTooltipOfDelete
                              title={t("Delete_Template")}
                            >
                              <IconButton
                                aria-label="delete"
                                size="large"
                                className="Icon-button"
                                // onClick={() => (setOpen(true)  setDeleteId(row.id))}
                                onClick={(e) => {
                                  setOpen(true);

                                    setDeleteTemplate(row?.id);
                                    // EditTemplate(row?.id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </CustomWidthTooltipOfDelete> */}
                              <IconButton
                                onClick={(e) => handleClick(e, row?.id)}
                                variant="outlined"
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                              >
                                {viewPermission && deletePermission ? (
                                  <MenuItem
                                    onClick={() => {
                                      closeMenu();
                                      handleOpenDeleteModel();
                                      // setOpen();
                                      // setDeletePost(item?.id);
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{
                                        color: "#545353",
                                        paddingRight: "5px",
                                      }}
                                    />
                                    <Typography
                                      onClick={(e) => {
                                        setOpen(true);

                                        //  setDeleteTemplate(row?.id);
                                      }}
                                    >
                                      {t("Delete")}
                                    </Typography>
                                  </MenuItem>
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <MenuItem disabled>
                                        <DeleteIcon
                                          disabled
                                          sx={{
                                            color: "#545353",
                                            paddingRight: "5px",
                                          }}
                                        />
                                        <Typography
                                          aria-label="delete"
                                          size="large"
                                        >
                                          {t("Delete")}
                                        </Typography>
                                      </MenuItem>
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </Menu>
                            </Box>
                          </CustomizedTableCell>
                        </CustomizedTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allTemplate?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <>
              <Box className={classes.locationbox}>
                <LocationNotFound
                  text={t("No_Reviews_Template_Added")}
                  subtext={t("Add_Review_Temp_Desc")}
                />
                <CommonButton
                  onSubmit={CreateNavigateButton}
                  label={t("Create_Review_Template")}
                />
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Index;
