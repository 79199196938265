import React from "react";
import KeywordSearch from "./Components/KeywordSearch";
import { useTranslation } from "react-i18next";
import { KeywordSearchProvider } from "./navbar/context/KeywordSearchContext";
import TitleHeader from "../../../components/CustomComponents/title";

function Default() {
  const { t } = useTranslation();
  return (
    <>
      <TitleHeader
        title={t("Keyword_Search")}
        subHeading={t("keywordsearch_subTitle")}
      />
      <KeywordSearchProvider>
        <KeywordSearch></KeywordSearch>
      </KeywordSearchProvider>
    </>
  );
}

export default Default;
