import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Tooltip,
  tooltipClasses,
  Typography,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import CommonButton from "../../CustomComponents/Buttons/CommonButton.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled from "styled-components/macro";
import Collapsible from "../../CustomComponents/Collapsible.js";
import DatePickerComponent from "../../../pages/UserPages/GoogleStats/Components/RangePickerResults.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "dayjs";
import { useStyles } from "../FilterModelEmotionAI/style";
import { languagesData } from "../../../assets/defaultLanguage/index.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useNavigate } from "react-router-dom";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  height: "30px",
  padding: "6px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.typography.body2,
}));
const SmallCheckbox = styled(Checkbox)({
  // padding: "0px 12px 0px 0px",
  width: "12px",
  height: "12px",
  "& .MuiSvgIcon-root": {
    fontSize: "16px", // Adjust the size of the icon inside the checkbox
  },
});

const FilterModal = ({
  locations,
  allLocations,
  handleChangeLocation,
  startDate,
  endDate,
  handleChangeStartDate,
  handleChangeEndDate,
  selectAllCountries,
  setSelectAllCountries,
  selectedCountries,
  openModalFilter,
  handleCloseModalFilter,
  allGroups,
  setSelectedGroups,
  selectedGroups,
  handleChangeGroups,
  selectAllLocations,
  setSelectAllLocations,
  selectAll,
  setCallApi,
  countries,
  resetStart,
  resetEnd,
  locationAddPermission,
  locationViewPermission,
  defaultLocation,
  autoSelectHeader,
  setAutoSelectHeader,
  setSelectAllGroups,
  oldCountries,
  oldGroup,
  getCountryWiseLocations,
  handleChangeCountries,
  setDateRange,
  dateRange,
  setLocation,
  openFrom,
  checkCountriesExistence,
  setEmailBody,
  setSelectedCountries,
  disabledStartDate,
  setDisabledStartDateSchedule,
  subscriptionDate,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorBulk, setAnchorBulk] = useState(null);
  const [isSorted, setIsSorted] = useState("");
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [checkedLocation, setCheckedLocation] = useState(false);
  const [section, setSection] = useState("locations");
  const [autoSelect, setAutoSelect] = useState(autoSelectHeader);

  const [modellocations, setModelLocations] = useState(locations);
  const [startDateModel, setStartDateModel] = useState(startDate);
  const [endDateModel, setEndDateModel] = useState(endDate);
  const [modelCountries, setModelCountries] = useState(selectedCountries);
  const [selectAllModelCountries, setSelectAllModelCountries] =
    useState(selectAllCountries);

  const [selectedGroupsModel, setSelectedGroupsModel] =
    useState(selectedGroups);

  const [oldGroupsModel, setOldGroupsModel] = useState([]);
  const [rangeModel, setRangeModel] = useState({
    startDate: new Date(dateRange?.startDate),
    endDate: new Date(dateRange?.endDate),
    range: dateRange?.range,
  });
  const [loading, setLoading] = useState(false);
  const [expandAllPanels, setExpandAllPanels] = useState(true);
  const [selectAllFeatures, setSelectAllFeatures] =
    useState(selectAllLocations);
  const [selectAllGroupsModel, setSelectAllGroupsModel] = useState(selectAll);
  const [modelOldCountries, setModelOldCountries] = useState(oldCountries);
  const [disableStartDatemodel, setDisabledStartDateModel] =
    useState(disabledStartDate);

  useEffect(() => {
    if (openModalFilter) {
      setModelLocations(locations);
    }
  }, [openModalFilter]);

  useEffect(() => {
    if (selectAllGroupsModel) {
      handleExpandPanel(undefined, true, "groups");
    }
  }, [selectAllGroupsModel]);

  function monthDiff(dateFrom, dateTo) {
    // Convert dayjs objects to native Date objects
    const fromDate = dateFrom instanceof dayjs ? dateFrom.toDate() : dateFrom;
    const toDate = dateTo instanceof dayjs ? dateTo.toDate() : dateTo;

    return (
      toDate.getMonth() -
      fromDate.getMonth() +
      12 * (toDate.getFullYear() - fromDate.getFullYear())
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorBulk(null);
  };
  const handleSorted = (arr, ele) => {
    if (isSorted === "asc") {
      const sortedArr = arr.sort((a, b) => a[ele].localeCompare(b[ele]));
      return sortedArr;
    } else if (isSorted === "desc") {
      const sortedArr = arr.sort((a, b) => b[ele].localeCompare(a[ele]));
      return sortedArr;
    } else {
      return arr;
    }
  };

  const handleClickMenu = (event) => {
    setAnchorBulk(event.currentTarget);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }

    let uniqueArray = [...new Set(_expandedPanels)];
    setExpandedPanels(uniqueArray);
  };

  const resetStates = () => {
    setModelLocations([defaultLocation]);
    setSelectAllGroupsModel(false);
    setSelectAllFeatures(false);
    setSelectAllModelCountries(false);
    setModelCountries([]);
    setSelectedGroupsModel([]);
    setAutoSelect(false);
    setStartDateModel(resetStart);
    setEndDateModel(resetEnd);
    setRangeModel({
      startDate: new Date(resetStart),
      endDate: new Date(resetEnd),
      range: "custom",
    });
    setDisabledStartDateModel(disabledStartDate);

    // resetHandler();
  };
  const handleChangeCountriesForModel = (value) => {
    setModelCountries(value);

    handleChangeLocationsBySelectingCountries(value);
    /*   } else {
      handleChangeLocationsBySelectingCountries(modelOldCountries);
    } */

    if (value?.length === countries?.length) {
      setSelectAllModelCountries(true);
    } else {
      setSelectAllModelCountries(false);
    }
  };
  const handleChangeRangeModel = (value) => {
    setCallApi(false);

    setRangeModel(value);
  };

  const handleSubmitFilters = () => {
    setLoading(true);
    handleChangeStartDate(startDateModel);
    handleChangeEndDate(endDateModel);

    setLocation(modellocations);
    setSelectAllLocations(selectAllFeatures);

    setSelectedGroups(selectedGroupsModel);
    setSelectAllGroups(selectAllGroupsModel);

    setSelectedCountries(modelCountries);
    setSelectAllCountries(selectAllModelCountries);
    setDateRange({
      startDate: rangeModel?.startDate,
      endDate: rangeModel?.endDate,
      range: rangeModel?.range,
    });

    setDisabledStartDateSchedule(disableStartDatemodel);

    setTimeout(() => {
      setLoading(true);
      handleCloseModalFilter();

      setExpandedPanels([]);
      if (openFrom === "filterModel") {
        setCallApi(true);
      }
    }, 2000);
  };

  function handleDisplayDot(countriesArr, locationsArr) {
    let name = countriesArr?.countryName;
    let countriesLocations = countriesArr?.locationsArray;
    const ids1 = new Set(countriesLocations?.map((obj) => obj.id));

    for (let obj of locationsArr) {
      if (ids1.has(obj?.id)) {
        return true;
      }
    }

    return false;
  }

  function filterArrayById(inputArray, targetId) {
    return inputArray.filter((item) =>
      item?.locationsArray?.some((loc) => loc.id === targetId)
    );
  }
  function filterArrayByIdForGroups(inputArray, targetId) {
    return inputArray.filter((item) =>
      item?.groups?.some((loc) => loc.id === targetId)
    );
  }
  const handleChangeGroupsModel = (value, autoSelectValue) => {
    setSelectedGroupsModel(value);
    if (value?.length === allGroups?.rows?.length) {
      setSelectAllGroupsModel(true);
    } else {
      setSelectAllGroupsModel(false);
    }

    let newLocations = [...modellocations];
    const results = oldGroupsModel.filter(
      ({ name: id1 }) => !value.some(({ name: id2 }) => id2 === id1)
    );
    if (value?.length > 0) {
      value?.map((group) => {
        group?.groups?.map((groupItem) => {
          allLocations?.map((location) => {
            if (
              groupItem?.locationId === location?.id &&
              isItemFoundInCountries(location?.id) === false
            ) {
              newLocations?.push(location);
            } else {
              // setSelectAll(false);
              results?.map((group) => {
                group?.groups?.map((groupItem) => {
                  modellocations?.map((location) => {
                    const filteredArray = filterArrayByIdForGroups(
                      value,
                      location?.id
                    );
                    if (
                      groupItem?.locationId === location?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInCountries(location?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== location?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((group) => {
        group?.groups?.map((groupItem) => {
          modellocations?.map((location) => {
            if (
              groupItem?.locationId === location?.id &&
              isItemFoundInCountries(location?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== location?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicates(newLocations);
    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    setModelLocations(googleLinkedLocations);
    handleDisableDates(googleLinkedLocations);
    setOldGroupsModel(value);
  };
  function removeDuplicates(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }
  function checkGroupsExistence(groups, arr) {
    return groups.every((group) =>
      arr.some((elem) => elem.id === group.locationId)
    );
  }

  const handleDisableDates = (locationList) => {
    if (locationList?.length > 0 && locationList?.length === 1) {
      const subscriptionDateLoc = new Date(locationList[0]?.subscriptionDate);

      let subscriptionDate = new Date(locationList[0]?.subscriptionDate);

      const oneYearBeforSubsciptionDate = new Date(
        subscriptionDateLoc.setFullYear(subscriptionDate.getFullYear() - 1)
      );
      oneYearBeforSubsciptionDate.setDate(
        oneYearBeforSubsciptionDate.getDate() - 1
      );

      /*  if (oneYearBeforSubsciptionDate > resetStart) {
        setStartDateModel(oneYearBeforSubsciptionDate);
        setRangeModel({
          startDate: oneYearBeforSubsciptionDate,
          endDate: resetEnd,
        });
      } else {
        // setDisabledStartDateModel(resetStart);
        setRangeModel({
          startDate: resetStart,
          endDate: resetEnd,
        });
      } */
      setDisabledStartDateModel(oneYearBeforSubsciptionDate);

      // setEndDateModel(resetEnd);
    } else if (locationList?.length > 1) {
      let earliestObject = locationList[0];

      locationList.forEach((item) => {
        if (
          new Date(item?.subscriptionDate) <
          new Date(earliestObject?.subscriptionDate)
        ) {
          earliestObject = item;
        }
      });

      const subscriptionDateLoc = new Date(earliestObject?.subscriptionDate);
      const subscriptionDate = new Date(earliestObject?.subscriptionDate);
      const oneYearBeforSubsciptionDate = new Date(
        subscriptionDateLoc.setFullYear(subscriptionDate.getFullYear() - 1)
      );

      setDisabledStartDateModel(oneYearBeforSubsciptionDate);
      /*   if (oneYearBeforSubsciptionDate > resetStart) {
        setStartDateModel(oneYearBeforSubsciptionDate);

        setRangeModel({
          startDate: oneYearBeforSubsciptionDate,
          endDate: resetEnd,
        });
        //   setNextStartDate(previousStartDate);
      } else {
        setRangeModel({
          startDate: resetStart,
          endDate: resetEnd,
        });
        setStartDateModel(resetStart);
      }
      setEndDateModel(resetEnd); */
    }
  };

  const handleChangeGroupsForLocationsModel = (locations) => {
    if (modellocations?.length > 0 || selectedGroupsModel?.length > 0) {
      const filteredArray2 = selectedGroupsModel?.filter((item) =>
        item.groups ? checkGroupsExistence(item.groups, locations) : true
      );

      setSelectedGroupsModel(filteredArray2 || []); // Make sure filteredArray2 has a value or assign an empty array if it's undefined/null
      // setLocationError(false);
    } else {
      //  setSelectAllGroups(false);
      // /  setLocationError(true);
      setSelectedGroupsModel([]);
    }
  };
  const handleChangeCountriesForLocations = (locations) => {
    if (locations?.length > 0 || selectedCountries?.length > 0) {
      const filteredArray2 = selectedCountries?.filter((item) =>
        item?.locationsArray
          ? checkCountriesExistence(item?.locationsArray, locations)
          : true
      );
      setSelectAllModelCountries(false);
      setModelCountries(filteredArray2 || []);
      // setLocationError(false);
    } else {
      setModelCountries([]);
    }
  };

  const handleChangeLocationModel = (loc) => {
    // setCallApi(false);
    setModelLocations(loc);
    handleDisableDates(loc);
  };

  const handleChangeStartDateModel = (e) => {
    setStartDateModel(e);
  };

  const handleChangeEndDateModel = (e) => {
    setEndDateModel(e);
  };

  const handleExpandAllPanels = () => {
    const arr = [...expandedPanels];
    countries?.map((item) => arr.push(item?.countryName));
    const uniqueArray = [...new Set(arr)];
    setExpandedPanels(uniqueArray);

    setExpandAllPanels(false);
  };

  const handleCollapseAllPanelsPanels = () => {
    const filtered = expandedPanels?.filter(
      (item) => item === "groups" || item === "countries"
    );
    setExpandedPanels(filtered);
    setExpandAllPanels(true);
  };
  const handleChangeSelectAllLocations = (e) => {
    setSelectAllFeatures(e.target.checked);
    if (e.target.checked) {
      const googleLinkedLocations = allLocations?.filter(
        (item) =>
          item?.status === "approved" &&
          item.socialLink.length > 0 &&
          item.socialLink.some(
            (link) =>
              link?.type === "google" && link?.statusOfGoogleInsight === "Done"
          )
      );

      setModelLocations(googleLinkedLocations);
      handleDisableDates(googleLinkedLocations);
      handleExpandAllPanels();
    } else {
      setModelLocations([]);
      handleCollapseAllPanelsPanels();
    }
  };
  const handleSelectAllGroups = (e) => {
    setSelectAllGroupsModel(e.target.checked);
    if (e.target.checked) {
      let arr = [];
      allGroups?.rows?.map((item) => {
        arr.push(item);
      });
      handleChangeGroupsModel(arr, autoSelect);
      expandedPanels?.push("groups");
    } else {
      handleChangeGroupsModel([], autoSelect);
    }
  };
  const handleChangeAllCountryLocations = (e, locationsArr, countryName) => {
    if (e.target.checked) {
      let arr = [...modellocations];
      let mergedArr = arr.concat(locationsArr);
      const googleLinkedLocations = mergedArr?.filter(
        (item) =>
          item?.status === "approved" &&
          item.socialLink.length > 0 &&
          item.socialLink.some(
            (link) =>
              link?.type === "google" && link?.statusOfGoogleInsight === "Done"
          )
      );

      const uniqueData = removeDuplicates(googleLinkedLocations);
      setModelLocations(uniqueData);
      handleDisableDates(uniqueData);
    } else {
      const filtered = modellocations?.filter(
        (item) => item?.countryCode !== countryName
      );
      setModelLocations(filtered);
      handleDisableDates(filtered);
    }
  };

  const handleCheckForAllLocations = (countryLocations, selectedLocations) => {
    const googleLinkedLocations = countryLocations?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );
    const array2IdSet = new Set(selectedLocations?.map((obj) => obj?.id));
    if (googleLinkedLocations?.length > 0) {
      return googleLinkedLocations?.every((obj) => array2IdSet.has(obj?.id));
    } else {
      return false;
    }
  };

  function mergeGroups(mainArray) {
    return mainArray.reduce((accumulator, currentObject) => {
      return accumulator.concat(currentObject.groups);
    }, []);
  }

  function removeDuplicateLocaions(arr) {
    const uniqueIds = new Set();
    return arr.filter((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        return true;
      }
      return false;
    });
  }

  const handleNavigationToGroups = () => {
    navigate("/user/accounts-overview", {
      state: { tabIndex: 3, page: 0 },
    });
  };
  const isItemFoundInCountries = (locationId) => {
    let isFound = false;
    if (modelCountries?.length > 0) {
      modelCountries?.map((item) => {
        item?.locationsArray?.map((itemTwo) => {
          if (itemTwo?.id === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };
  const isItemFoundInGroups = (locationId) => {
    let isFound = false;

    if (selectedGroupsModel?.length > 0) {
      selectedGroupsModel?.map((item) => {
        item?.groups?.map((itemTwo) => {
          if (itemTwo?.locationId === locationId) {
            isFound = true;
          }
        });
      });
      return isFound;
    } else {
      return false;
    }
  };

  const handleChangeLocationsBySelectingCountries = (value) => {
    let newLocations = [...modellocations];
    const results = modelOldCountries.filter(
      ({ countryName: id1 }) =>
        !value.some(({ countryName: id2 }) => id2 === id1)
    );

    if (value?.length > 0) {
      value?.map((itemOne) => {
        itemOne?.locationsArray?.map((locationItem) => {
          allLocations?.map((locItem) => {
            if (
              locationItem?.id === locItem?.id &&
              isItemFoundInGroups(locItem?.id) === false
            ) {
              newLocations?.push(locItem);
            } else {
              // setSelectAll(false);
              results?.map((item) => {
                item?.locationsArray?.map((locItemTwo) => {
                  modellocations?.map((locationItem) => {
                    const filteredArray = filterArrayById(
                      value,
                      locationItem?.id
                    );
                    if (
                      locItemTwo?.id === locationItem?.id &&
                      filteredArray?.length === 0 &&
                      isItemFoundInGroups(locationItem?.id) === false
                    ) {
                      newLocations = newLocations?.filter(
                        (item) => item?.id !== locationItem?.id
                      );
                    }
                  });
                });
              });
            }
          });
        });
      });
    } else {
      results?.map((item) => {
        item?.locationsArray?.map((locItem) => {
          modellocations?.map((locationItem) => {
            if (
              locItem?.id === locationItem?.id &&
              isItemFoundInGroups(locationItem?.id) === false
            ) {
              newLocations = newLocations?.filter(
                (item) => item?.id !== locationItem?.id
              );
            }
          });
        });
      });
    }

    const uniqueData = removeDuplicateLocaions(newLocations);

    const googleLinkedLocations = uniqueData?.filter(
      (item) =>
        item?.status === "approved" &&
        item.socialLink.length > 0 &&
        item.socialLink.some(
          (link) =>
            link?.type === "google" && link?.statusOfGoogleInsight === "Done"
        )
    );

    setModelLocations(googleLinkedLocations);
    handleDisableDates(googleLinkedLocations);

    setModelOldCountries(value);
  };

  const handleSelectAllCountries = (e) => {
    setSelectAllModelCountries(e.target.checked);
    if (e.target.checked) {
      const googleLinkedLocations = allLocations?.filter(
        (item) =>
          item?.status === "approved" &&
          item.socialLink.length > 0 &&
          item.socialLink.some(
            (link) =>
              link?.type === "google" && link?.statusOfGoogleInsight === "Done"
          )
      );
      setModelLocations(googleLinkedLocations);
      handleDisableDates(googleLinkedLocations);
      handleChangeLocationsBySelectingCountries(countries);
    } else {
      setModelCountries([]);
      let filteredPanels = expandedPanels?.filter(
        (item) => item !== "countries"
      );
      setExpandedPanels(filteredPanels);

      handleChangeLocationsBySelectingCountries([]);
    }
  };
  return (
    <Modal
      open={openModalFilter}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container>
        <Grid
          item
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box className={classes.styleMainModelContainer}>
            <Box
              sx={{
                pb: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className={classes.mainContainerHeading}>
                {t("Filters")}
              </Typography>

              <IconButton
                autoFocus
                onClick={() => {
                  handleCloseModalFilter();
                  setExpandedPanels([]);
                }}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box className={classes.contentContainer}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("locations")}
                    label={t("Locations")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "locations" ? "false" : "true"}
                  />
                  <CommonButton
                    type="submit"
                    isLoading={false}
                    disabled={false}
                    onSubmit={() => setSection("others")}
                    label={t("others")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={section === "others" ? "false" : "true"}
                  />
                </Box>
                <Box>
                  {section === "locations" && (
                    <>
                      <CommonButton
                        type="submit"
                        isLoading={false}
                        disabled={false}
                        onSubmit={handleClickMenu}
                        label={
                          isSorted === "asc"
                            ? t("sortA-Z")
                            : isSorted === "desc"
                            ? t("sortZ-A")
                            : t("Sort_By")
                        }
                        icon={<KeyboardArrowDownIcon />}
                        leftMargin={true}
                        customHeight={true}
                        displayWhite={"true"}
                        iconLocation={true}
                        aria-owns={anchorBulk ? "simple-menu" : undefined}
                        aria-haspopup="true"
                      />
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorBulk}
                        open={Boolean(anchorBulk)}
                        onClose={handleClose}
                        PaperProps={{
                          sx: {
                            background: "#EBEBEC",
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            color: "#1B2430",
                            fontSize: "14px",
                            lineHeight: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#DBDBDC",
                            },
                          }}
                          onClick={(e) => {
                            setIsSorted("asc");
                            handleClose();
                          }}
                        >
                          {t("sortA-Z")}
                        </MenuItem>
                        <MenuItem
                          sx={{
                            color: "#1B2430",
                            fontSize: "14px",
                            lineHeight: "20px",
                            cursor: "pointer",
                            "&:hover": {
                              background: "#DBDBDC",
                            },
                          }}
                          onClick={(e) => {
                            setIsSorted("desc");
                            handleClose();
                          }}
                        >
                          {t("sortZ-A")}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>
              </Box>

              {section === "locations" && (
                <>
                  <Box>
                    <Collapsible
                      title={t("groups_label")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("groups")}
                      id={"groups"}
                      displayGroupSelectAll={
                        allGroups?.rows?.length > 0 ? true : false
                      }
                      handleSelectAllGroups={handleSelectAllGroups}
                      selectedGroupsModel={selectedGroupsModel}
                      selectAllGroups={selectAllGroupsModel}
                      setExpandedPanels={setExpandedPanels}
                      countryNameStyling={selectedGroupsModel?.length > 0}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {allGroups !== null && allGroups?.rows?.length > 0 ? (
                            handleSorted(allGroups?.rows, "name")?.map(
                              (item) => (
                                <Item
                                  onClick={() => ""}
                                  sx={{
                                    padding: "7px 8px 7px 8px",
                                    gap: "4px",
                                    border: selectedGroupsModel?.find(
                                      (group) => group?.id === item?.id
                                    )
                                      ? "1px solid #06BDFF"
                                      : "1px solid #EBEBEC",
                                    borderRadius: "800px",
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      selectedGroupsModel?.length > 0 &&
                                      selectedGroupsModel?.find(
                                        (group) => group?.id === item?.id
                                      ) !== undefined
                                    }
                                    onChange={(e) => {
                                      let arr = [...selectedGroupsModel];
                                      if (e.target.checked) {
                                        arr.push(item);
                                        handleChangeGroupsModel(
                                          arr,
                                          autoSelect
                                        );
                                      } else {
                                        const filtered = arr.filter(
                                          (group) => group?.id !== item?.id
                                        );
                                        handleChangeGroupsModel(
                                          filtered,
                                          autoSelect
                                        );
                                      }
                                    }}
                                    size="small"
                                    sx={{
                                      paddingLeft: "2px",
                                      paddingRight: "2px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      lineHeight: "18px",
                                      color: "#1B2430",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {item.name}
                                  </Typography>
                                </Item>
                              )
                            )
                          ) : (
                            <Grid
                              container
                              sx={{
                                height: "200px",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                              }}
                            >
                              <Grid item sm={8} md={8} lg={8}>
                                <Typography
                                  sx={{
                                    lineHeight: "28px",
                                    fontWeight: 700,
                                    fontSize: "20px",
                                    color: "#1b2430",
                                    marginTop: "30px",
                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("No_Groups_Found")}
                                </Typography>
                                <Typography
                                  sx={{
                                    lineHeight: "24px",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    color: "#1B2430",

                                    marginBottom: "15px",
                                  }}
                                >
                                  {t("create_groups_text")}
                                </Typography>
                                {locationAddPermission &&
                                locationViewPermission ? (
                                  <CommonButton
                                    onSubmit={handleNavigationToGroups}
                                    customHeight={true}
                                    label={t("create_groups")}
                                    icon={<AddIcon sx={{ color: "#FFFF" }} />}
                                  />
                                ) : (
                                  <BootstrapTooltip
                                    title={t("authorized_access")}
                                  >
                                    <span>
                                      <CommonButton
                                        disabled={true}
                                        onSubmit={handleNavigationToGroups}
                                        customHeight={true}
                                        label={t("create_groups")}
                                        icon={
                                          <AddIcon sx={{ color: "#FFFF" }} />
                                        }
                                      />
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </Box>
                  <Grid>
                    <Collapsible
                      title={t("countries")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("countries")}
                      id={"countries"}
                      countryNameStyling={
                        modelCountries?.length > 0 ? true : false
                      }
                      expandedPanels={expandedPanels}
                      setExpandedPanels={setExpandedPanels}
                      modelCountries={modelCountries}
                      defaultPreventions={true}
                      handleSelectAllGroups={handleSelectAllCountries}
                      selectAllGroups={selectAllModelCountries}
                      displayGroupSelectAll={true}
                      ComponentToRender={
                        <Grid container sx={{ gap: "10px" }}>
                          {countries !== null &&
                            countries?.length > 0 &&
                            handleSorted(countries, "countryName")?.map(
                              (item, i) => (
                                <Item
                                  key={i}
                                  sx={{
                                    border:
                                      modelCountries.find(
                                        (con) =>
                                          con?.countryName === item?.countryName
                                      ) !== undefined
                                        ? "1px solid #06BDFF"
                                        : "1px solid #EBEBEC",
                                  }}
                                  onClick={() => {
                                    const itemFound = modelCountries?.find(
                                      (itemTwo) =>
                                        itemTwo?.countryName ===
                                          item?.countryName ||
                                        itemTwo?.countryCode ===
                                          item?.countryCode
                                    );
                                    let filtered = [...modelCountries];
                                    if (itemFound !== undefined) {
                                      filtered = modelCountries?.filter(
                                        (itemThree) =>
                                          itemThree?.countryName !==
                                          item?.countryName
                                      );
                                      handleChangeCountriesForModel(filtered);
                                    } else {
                                      filtered?.push(item);
                                      handleChangeCountriesForModel(filtered);
                                    }
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      modelCountries.find(
                                        (con) =>
                                          con?.countryName === item?.countryName
                                      ) !== undefined
                                    }
                                    size="small"
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      color: "#1B2430",
                                    }}
                                  >
                                    {item?.countryName}
                                  </Typography>
                                </Item>
                              )
                            )}
                        </Grid>
                      }
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      padding: "8px",
                      border: "1px solid #EBEBEC",
                      borderRadius: "8px",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: 600, fontSize: "1.125rem" }}
                        >
                          {t("Locations")}
                        </Typography>

                        <Box>
                          <SmallCheckbox
                            name="selectAll"
                            color="primary"
                            checked={selectAllFeatures}
                            // disabled={defaultRole}
                            onChange={(e) => handleChangeSelectAllLocations(e)}
                            sx={{ marginRight: "4px" }}
                          />
                          {t("All_locationss")}
                          {expandAllPanels ? (
                            <IconButton onClick={handleExpandAllPanels}>
                              <ExpandMoreIcon />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleCollapseAllPanelsPanels}>
                              <ExpandLessIcon />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid container sx={{ gap: "10px" }}>
                      {countries?.length > 0 &&
                        handleSorted(countries, "countryName")?.map((item) => (
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            {" "}
                            <Collapsible
                              title={item?.countryName}
                              handleChange={handleExpandPanel}
                              expanded={expandedPanels?.includes(
                                item?.countryName
                              )}
                              expandedPanels={expandedPanels}
                              setExpandedPanels={setExpandedPanels}
                              id={item?.countryName}
                              countryNameStyling={handleDisplayDot(
                                item,
                                modellocations
                              )}
                              displaySelectAll={true}
                              itemData={item}
                              handleCheckForAllLocations={
                                handleCheckForAllLocations
                              }
                              handleChangeAllCountryLocations={
                                handleChangeAllCountryLocations
                              }
                              modellocations={modellocations}
                              countriesArr={item}
                              ComponentToRender={
                                <Grid container sx={{ gap: "10px" }}>
                                  {item?.locationsArray?.length > 0 &&
                                    handleSorted(
                                      item?.locationsArray,
                                      "internalName"
                                    )?.map((itemTwo) => (
                                      <Item
                                        onClick={() => ""}
                                        style={{
                                          padding: "12px 8px 12px 8px",
                                          gap: "4px",
                                          border:
                                            itemTwo?.status !== "approved" ||
                                            itemTwo?.socialLink?.length === 0 ||
                                            (itemTwo?.socialLink?.length > 0 &&
                                              itemTwo?.socialLink?.find(
                                                (item) =>
                                                  item?.type === "google" &&
                                                  item?.statusOfGoogleInsight !==
                                                    "Done"
                                              ) !== undefined)
                                              ? "none"
                                              : modellocations?.find(
                                                  (item) =>
                                                    item?.id === itemTwo?.id
                                                )
                                              ? "1px solid #06BDFF"
                                              : "1px solid #BBBDC1",
                                          borderRadius: "800px",
                                          background:
                                            itemTwo?.status !== "approved" ||
                                            itemTwo?.socialLink?.length === 0 ||
                                            (itemTwo?.socialLink?.length > 0 &&
                                              itemTwo?.socialLink?.find(
                                                (item) =>
                                                  item?.type === "google" &&
                                                  item?.statusOfGoogleInsight !==
                                                    "Done"
                                              ) !== undefined)
                                              ? "#F0F0F0"
                                              : modellocations?.find(
                                                  (item) =>
                                                    item?.id === itemTwo?.id
                                                )
                                              ? "#FFF"
                                              : "#FFF",

                                          //  height: "45px",
                                        }}
                                      >
                                        <Checkbox
                                          checked={
                                            modellocations?.find(
                                              (loc) => loc?.id === itemTwo?.id
                                            ) !== undefined
                                              ? true
                                              : false
                                          }
                                          onChange={(e) => {
                                            let arr = [...modellocations];
                                            if (e.target.checked) {
                                              arr.push(itemTwo);
                                              handleChangeLocationModel(arr);

                                              handleChangeGroupsForLocationsModel(
                                                arr
                                              );
                                              handleChangeCountriesForLocations(
                                                arr
                                              );
                                            } else {
                                              const filtered = arr.filter(
                                                (loc) => loc?.id !== itemTwo?.id
                                              );
                                              handleChangeLocationModel(
                                                filtered
                                              );

                                              handleChangeGroupsForLocationsModel(
                                                filtered
                                              );
                                              handleChangeCountriesForLocations(
                                                arr
                                              );
                                            }
                                          }}
                                          size="small"
                                          disabled={
                                            itemTwo?.status !== "approved" ||
                                            itemTwo?.socialLink?.length === 0 ||
                                            (itemTwo?.socialLink?.length > 0 &&
                                              itemTwo?.socialLink?.find(
                                                (item) =>
                                                  item?.type === "google" &&
                                                  item?.statusOfGoogleInsight !==
                                                    "Done"
                                              ) !== undefined)
                                          }
                                          sx={{
                                            paddingLeft: "2px",
                                            paddingRight: "2px",
                                          }}
                                        />

                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "18px",
                                            color: modellocations?.find(
                                              (item) => item?.id === itemTwo?.id
                                            )
                                              ? "#1B2430"
                                              : "#1B2430",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {itemTwo.internalName +
                                            (itemTwo?.city !== null &&
                                              itemTwo?.city !== "" &&
                                              " " + "(" + itemTwo?.city + ")")}
                                        </Typography>
                                        <span
                                          style={{
                                            marginLeft: "10px",
                                            color:
                                              itemTwo?.socialLink?.length ===
                                                0 &&
                                              itemTwo?.status === "approved"
                                                ? "#FC3652"
                                                : itemTwo?.status ===
                                                  "requested"
                                                ? "#99cc33"
                                                : itemTwo?.socialLink?.length >
                                                    0 &&
                                                  itemTwo?.socialLink?.find(
                                                    (item) =>
                                                      item?.type === "google" &&
                                                      item?.statusOfGoogleInsight ===
                                                        "error"
                                                  )
                                                ? "#F86F35"
                                                : itemTwo?.socialLink?.length >
                                                    0 &&
                                                  itemTwo?.socialLink?.find(
                                                    (item) =>
                                                      item?.type === "google" &&
                                                      item?.statusOfGoogleInsight ===
                                                        "inProcess"
                                                  ) !== undefined
                                                ? "#ffcc00"
                                                : itemTwo?.socialLink?.length >
                                                    0 &&
                                                  itemTwo?.socialLink?.find(
                                                    (item) =>
                                                      item?.type === "google" &&
                                                      item?.validToken === false
                                                  ) !== undefined
                                                ? "#cc3300"
                                                : "",
                                          }}
                                        >
                                          {itemTwo?.socialLink?.length === 0 &&
                                          itemTwo?.status === "approved"
                                            ? "• " + `${t("disconnected")}`
                                            : ""}

                                          {itemTwo?.status === "requested" &&
                                          itemTwo?.socialLink?.length === 0
                                            ? "• " + `${t("requested_state")}`
                                            : ""}
                                          {itemTwo?.socialLink?.length > 0 &&
                                          itemTwo?.socialLink?.find(
                                            (item) =>
                                              item?.type === "google" &&
                                              item?.statusOfGoogleInsight ===
                                                "error"
                                          ) !== undefined
                                            ? "• " + `${t("Error")}`
                                            : ""}
                                          {itemTwo?.socialLink?.length > 0 &&
                                          itemTwo?.socialLink?.find(
                                            (item) =>
                                              item?.type === "google" &&
                                              item?.statusOfGoogleInsight ===
                                                "inProcess"
                                          ) !== undefined
                                            ? "• " + `${t("inProgress")}`
                                            : ""}
                                          {itemTwo?.socialLink?.length > 0 &&
                                          itemTwo?.socialLink?.find(
                                            (item) =>
                                              item?.type === "google" &&
                                              item?.validToken === false
                                          ) !== undefined
                                            ? "• " +
                                              `${t("token_expired_text")}`
                                            : ""}
                                        </span>
                                      </Item>
                                    ))}
                                </Grid>
                              }
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                </>
              )}
              {section === "others" && (
                <>
                  <Collapsible
                    title={t("Datee")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("Date")}
                    id={"Date"}
                    setExpandedPanels={setExpandedPanels}
                    ComponentToRender={
                      <Grid container sx={{ gap: "10px" }}>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          sx={{ padding: "0px" }}
                        >
                          <DatePickerComponent
                            handleChangeStartDate={handleChangeStartDateModel}
                            handleChangeEndDate={handleChangeEndDateModel}
                            startDate={startDateModel}
                            endDate={endDateModel}
                            dateRange={rangeModel}
                            setDateRange={setRangeModel}
                            setCallApi={setCallApi}
                            removePadding={true}
                            rangeValue={"between"}
                            setEmailBody={setEmailBody}
                            oneYearAgoDate={disableStartDatemodel}
                            subscriptionDate={subscriptionDate}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </>
              )}
            </Box>
            <Divider />

            <Grid container sx={{ paddingTop: "10px", paddingBottom: "16px" }}>
              <Grid item md={6}></Grid>
              <Grid item md={6}>
                <Box
                  sx={{
                    justifyContent: "flex",
                    float: "right",
                  }}
                >
                  <CommonButton
                    onSubmit={resetStates}
                    label={t("Clear_Filters")}
                    leftMargin={true}
                    customHeight={true}
                    displayWhite={"true"}
                  />
                  &nbsp;&nbsp;
                  <CommonButton
                    type="submit"
                    isLoading={loading}
                    disabled={loading}
                    onSubmit={handleSubmitFilters}
                    label={t("Apply_filters")}
                    leftMargin={true}
                    customHeight={true}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default FilterModal;

/* openModalFilter && (
  <FilterModal
    open={openModalFilter}
    close={setOpenModalFilter}
    allGroups={allGroups}
  />
); */
