import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Modal,
  IconButton,
  TableContainer,
  Table,
  TableBody,
  Paper,
  TablePagination,
  Autocomplete,
  TextField,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CustomizedTableCell from "../../CustomComponents/table/tableCell";
import CustomizedTableHead from "../../CustomComponents/table/tableHead";
import CustomizedTableRow from "../../CustomComponents/table/tableRow";
import Loader from "../../Loaders/Loader";
import { makeStyles } from "@mui/styles";
import ConfirmModal from "../../../../src/components/Models/ConfirmModalLodging";
import CommonButton from "../../CustomComponents/Buttons/CommonButton";
import { api } from "../../../contexts/JWTContext";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  whiteBackground: {
    background: "white",
    width: "100%",
  },
  styleMainModelContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "550px",
    width: "800px",
    background: "#FFFF",
    border: "2px solid gray",
    borderRadius: "8px",
    boxShadow: 24,
    padding: "16px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    height: "400px",
    overflowY: "auto",
  },
  mainContainerHeading: {
    fontWeight: 700,
    fontSize: "20px",
  },
  mainContainerSubHeading: {
    fontWeight: 400,
    fontSize: "16px",
  },
  fieldLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "#1B2430",
    marginLeft: "10px",
    marginTop: "6px",
  },
}));

const EnhancedTableHead = (props) => {
  const { t } = props;

  const headCells = [
    { id: "userId", alignment: "left", label: t("userid") },
    { id: "user", alignment: "left", label: t("User") },
    { id: "companies", alignment: "left", label: t("TaskCompany") },
    { id: "role", alignment: "left", label: t("UserTableRole") },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            sx={{ fontWeight: "700" }}
            key={headCell?.id}
            align={headCell?.alignment}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CompanyLinkedModel = (props) => {
  const { open, close, users, company, handleDeleteAccount, getAllCustomer } =
    props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [roleOptions, setRoleOptions] = useState({});
  const [selectedRoles, setSelectedRoles] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [verifyRows, setVerifyRows] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (users && users.data) {
      const updatedLinkedUsers = users.data.map((user) => ({
        userId: user.id,
        companyId: 0,
        companyUserRoleId: 0,
        roleName: "",
      }));
      setLinkedUsers(updatedLinkedUsers);
    }
  }, [users]);

  const handleCompanyChange = (rowId, newValue) => {
    setSelectedCompanies((prev) => ({
      ...prev,
      [rowId]: newValue,
    }));

    const user = users.data.find((user) => user.id === rowId);
    const company = user.CompanyUserRoles.find(
      (role) => role?.Company !== null && role?.Company?.name === newValue
    );

    const roleData = company.Company.CompanyRoles.find(
      (role) => role?.name === company?.name
    );

    const newRoleOptions = company
      ? company?.Company?.CompanyRoles?.map((role) => role.name)
      : [];
    setRoleOptions((prev) => ({
      ...prev,
      [rowId]: newRoleOptions,
    }));

    if (newRoleOptions.length > 0) {
      setSelectedRoles((prev) => ({
        ...prev,
        [rowId]: roleData.name,
      }));
      setLinkedUsers((prev) =>
        prev.map((user) =>
          user.userId === rowId
            ? {
                ...user,
                companyId: company.Company.id,
                companyUserRoleId: roleData.id,
                roleName: roleData.name,
              }
            : user
        )
      );
    } else {
      setSelectedRoles((prev) => ({
        ...prev,
        [rowId]: "",
      }));
      setLinkedUsers((prev) =>
        prev.map((user) =>
          user.userId === rowId
            ? {
                ...user,
                companyId: company ? company.Company.id : 0,
                companyUserRoleId: company ? roleData.id : 0,
                roleName: "",
              }
            : user
        )
      );
    }
  };

  const handleRoleChange = (rowId, newValue) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [rowId]: newValue,
    }));

    setLinkedUsers((prev) =>
      prev.map((user) =>
        user.userId === rowId
          ? {
              ...user,
              roleName: newValue,
            }
          : user
      )
    );
  };

  const isPageComplete = () => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const currentPageData = users.data.slice(startIndex, endIndex);

    return currentPageData.every((row) => {
      const selectedCompany = selectedCompanies[row.id];
      const selectedRole = selectedRoles[row.id];
      return selectedCompany && selectedRole;
    });
  };

  const checkRowComplete = (data) => {
    const unFilledRows = linkedUsers.find((user) => user.userId === data.id);
    if (
      unFilledRows.companyId === 0 ||
      unFilledRows.companyUserRoleId === 0 ||
      unFilledRows.roleName === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage < page) {
      setPage(newPage);
    } else {
      if (isPageComplete()) {
        setPage(newPage);
        setVerifyRows(false);
      } else {
        setVerifyRows(true);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = users.data.slice(startIndex, endIndex);

  useEffect(() => {
    setIsDisabled(
      linkedUsers.some(
        (item) =>
          item.companyId === 0 &&
          item.companyUserRoleId === 0 &&
          item.roleName === ""
      )
    );
  }, [linkedUsers]);

  const handleCancelButton = () => {
    if (
      linkedUsers.some(
        (item) =>
          item.companyId !== 0 &&
          item.companyUserRoleId !== 0 &&
          item.roleName !== ""
      )
    ) {
      setOpenConfirmModal(true);
    } else {
      close(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await api.patch(`/company/${company.id}/updateLinkedUsers`, {
        data: linkedUsers,
      });
      if (res.status === 200) {
        setIsLoading(false);
        handleDeleteAccount();
        close();
        getAllCustomer(1);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message);
      close();
    }
  };

  return (
    <>
      {openConfirmModal && (
        <Dialog
          open={openConfirmModal}
          onClose={() => setOpenConfirmModal(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={() => close(false)}
            onCancel={() => setOpenConfirmModal(false)}
          />
        </Dialog>
      )}
      <Modal open={open}>
        <Grid container>
          <Grid
            item
            sx={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid className={classes.styleMainModelContainer}>
              <Grid
                sx={{
                  pb: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography className={classes.mainContainerHeading}>
                    {t("parent_company_heading")}
                  </Typography>
                  <Typography className={classes.mainContainerSubHeading}>
                    {t("parent_company_sub_heading")}
                  </Typography>
                </Grid>

                <IconButton
                  autoFocus
                  onClick={() => close(false)}
                  className="delete_button"
                  sx={{ paddingTop: "0px" }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Divider />
              <Grid className={classes.contentContainer}>
                {false ? (
                  <Grid
                    container
                    sx={{
                      height: "350px",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader />
                  </Grid>
                ) : (
                  <Grid
                    container
                    sx={{
                      overflow: "auto",
                    }}
                  >
                    <TableContainer component={Paper}>
                      <Table
                        aria-labelledby="tableTitle"
                        size={"medium"}
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead t={t} />
                        <TableBody>
                          {currentData.length > 0 ? (
                            currentData?.map((row, index) => {
                              const companyOptions = row.CompanyUserRoles.map(
                                (role) =>
                                  role.Company !== null && role.Company.name
                              );
                              return (
                                <CustomizedTableRow
                                  tabIndex={-1}
                                  key={`${row.id}-${index}`}
                                >
                                  <CustomizedTableCell sx={{ width: "100px" }}>
                                    {row.id}
                                  </CustomizedTableCell>
                                  <CustomizedTableCell
                                    align="left"
                                    sx={{ width: "220px" }}
                                  >
                                    <Typography>
                                      {row.firstName} {row.lastName}
                                    </Typography>
                                  </CustomizedTableCell>
                                  <CustomizedTableCell align="left">
                                    <Autocomplete
                                      sx={{
                                        width: "220px",
                                        borderRadius: "6px",
                                        outline:
                                          verifyRows && checkRowComplete(row)
                                            ? "1px solid red"
                                            : "none",
                                      }}
                                      options={companyOptions}
                                      getOptionLabel={(option) => option}
                                      value={selectedCompanies[row.id] || ""}
                                      onChange={(event, newValue) =>
                                        handleCompanyChange(row.id, newValue)
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          type="text"
                                          size="small"
                                          fullWidth
                                          placeholder="Select Company"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </CustomizedTableCell>
                                  <CustomizedTableCell align="left">
                                    <Autocomplete
                                      sx={{
                                        width: "220px",
                                        borderRadius: "6px",
                                        outline:
                                          verifyRows && checkRowComplete(row)
                                            ? "1px solid red"
                                            : "none",
                                      }}
                                      options={roleOptions[row.id] || []}
                                      getOptionLabel={(option) =>
                                        option.replaceAll("-", " ")
                                      }
                                      value={selectedRoles[row.id] || ""}
                                      onChange={(event, newValue) =>
                                        handleRoleChange(row.id, newValue)
                                      }
                                      disabled={!selectedCompanies[row.id]}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          type="text"
                                          size="small"
                                          fullWidth
                                          placeholder="Select Role"
                                          variant="outlined"
                                        />
                                      )}
                                    />
                                  </CustomizedTableCell>
                                </CustomizedTableRow>
                              );
                            })
                          ) : (
                            <Typography variant="h3">
                              {t("data_found")}
                            </Typography>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Grid container sx={{ justifyContent: "end" }}>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={users.count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Divider />
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  pt: "10px",
                }}
              >
                <CommonButton
                  type="submit"
                  isLoading={false}
                  disabled={false}
                  onSubmit={handleCancelButton}
                  label={t("Cancel")}
                  leftMargin={true}
                  customHeight={true}
                  displayWhite={"true"}
                />
                <CommonButton
                  type="submit"
                  isLoading={isLoading}
                  disabled={isDisabled}
                  onSubmit={handleSubmit}
                  label={t("Submit")}
                  leftMargin={true}
                  customHeight={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CompanyLinkedModel;
