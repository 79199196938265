// ** React Imports ================================================================
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components/macro";
import { Alert as MuiAlert } from "@mui/material";
import { spacing } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// ** File Imports ================================================================

import TitleHeader from "../../../../components/CustomComponents/title";
import { api } from "../../../../contexts/JWTContext";
import Loader from "../../../../components/Loaders/Loader";
import Person from "../../../../assets/images/person.svg";
import Brain from "../../../../assets/images/brain.png";
import Skull from "../../../../assets/images/Skull.svg";

// ** Material ui Imports ================================================================
import {
  Autocomplete,
  Checkbox,
  Grid,
  Paper,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  tooltipClasses,
  createFilterOptions,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import { Box } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import CheckIcon from "@mui/icons-material/Check";
import { useStyles } from "../styles/style";
import { handleSessionOut } from "../../../../contexts/JWTContext";
import useAuth from "../../../../hooks/useAuth";
// ** Other Imports ================================================================
// const useStyles = makeStyles((theme) => ({
//   mainContainer: {
//     background: "#FDEDED",
//     padding: "6px 16px",
//     borderRadius: "7px",
//     marginBottom: "10px",
//   },
//   errorText: {
//     fontWeight: 400,
//     fontSize: "0.8125rem",
//     lineHeight: "1.43",
//     color: "#ef5350",
//     padding: "6px 0px",
//   },
//   errorIcon: {
//     color: "#ef5350",
//     marginTop: "5px",
//   },
//   sourceImage: {
//     width: "40px",
//     height: "40px",
//   },
//   sourceName: {
//     //   fontFamily: "Work Sans",
//     fontWeight: 400,
//     fontSize: "16px",
//     lineHeight: "24px",
//   },
//   subscribedText: {
//     background: "#06BDFF",
//     marginRight: "12px",
//     color: "#FFFFFF",
//     fontStyle: "normal",
//     fontWeight: 400,
//     fontSize: "10px",
//     lineHeight: "14px",
//     justifyContent: "center",
//     alignItems: "center",
//     textAlign: "center",
//     padding: "3px",
//     gap: "4px",
//     width: "67px",
//     height: "20px",
//     borderRadius: "800px",
//   },
//   closeIcon: {
//     height: "100%",
//     pointerEvents: "none",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   inputRoot: {
//     color: "inherit",
//   },
// }));
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});
const Alert = styled(MuiAlert)(spacing);

const Ratings = ["one", "two", "three", "four", "five"];

const state = [{ label: "Present" }, { label: "Not Present" }];
const AddRule = () => {
  // ** Others ================================================================
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { signOut } = useAuth();

  const classes = useStyles();
  // ** States ================================================================

  const [dataToEdit, setDataToEdit] = useState(null);
  const [allTemplate, setAllTemplate] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [locationRequired, setLocationRequired] = useState(false);
  const [templateRequired, setTemplateRequired] = useState(false);
  const [reviewRequired, setReviewRequired] = useState(false);
  const [ratingRequired, setRatingRequired] = useState(false);
  const [selectedReviewOption, setSelectedReviewOption] = useState([]);
  const [reviewPresent, setReviewPresent] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companyId, setCompanyId] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [reviewNotPresent, setReviewNotPresent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aiReview, setAIReview] = useState(false);
  const [errors, setErrors] = useState("");
  const [errorRules, setErrorRules] = useState([]);
  const [companyIdRequired, setCompanyIdRequired] = useState(false);
  const [editData, setEditData] = useState(true);
  const [titleError, setTitleError] = useState(false);
  // const [users, setUsers] = useState();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const filter = createFilterOptions();
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  // ** Handler Functions ================================================================

  //  ==================== Edit Ruleee ==================================== //
  // Use effect for APi call
  useEffect(() => {
    if (id !== undefined) {
      getRuleToEdit();
    }
  }, []);

  // UseEffect for Set Title
  useEffect(() => {
    if (dataToEdit !== null) {
      setTitle(dataToEdit?.title);
    }
  }, [dataToEdit]);
  //  useEffect(() => {
  //   if (dataToEdit !== null) {
  //     setSelectedCompany(dataToEdit?.selectedCompany);
  //   }
  // }, [dataToEdit]);
  useEffect(() => {
    if (dataToEdit !== null) {
      setAIReview(dataToEdit?.isSupervised);
    }
  }, [dataToEdit]);
  // UseEffect for Set Ratings
  /*   useEffect(() => {
    if (dataToEdit !== null) {
      setSelectedRating(dataToEdit?.ratingStar);
    }
  }, [dataToEdit]); */

  // UseEffect for Set Reviews
  useEffect(() => {
    if (dataToEdit !== null) {
      setSelectedReviewOption(dataToEdit?.reviewTextPresent === true);
    }
  }, []);
  useEffect(() => {
    if (dataToEdit !== null) {
      let newTempArray = [];
      dataToEdit?.reviewTextPresent === true &&
        newTempArray.push({ label: "Present" });
      dataToEdit?.reviewTextNotPresent === true &&
        newTempArray.push({ label: "Not Present" });

      setSelectedReviewOption(newTempArray);
    }
  }, [dataToEdit]);

  // UseEffect for Set Templates
  useEffect(() => {
    if (dataToEdit !== null) {
      let newTemplate = [];
      dataToEdit?.ruleTemplate?.map((template) => {
        newTemplate.push(template.template);
      });

      setSelectedTemplate(newTemplate);
    }

    // dataToEdit?.ruleTemplate?.map((templateData) => {

    //   newTemplateArray?.push(templateData?.template);
    // });
  }, [dataToEdit]);
  const handleReviewText = (value) => {
    setSelectedReviewOption(value);
  };
  // UseEffect for Set Locations
  useEffect(() => {
    if (dataToEdit !== null) {
      let tempLocationArray = [];

      dataToEdit?.ruleLocation?.map((locationItem) => {
        tempLocationArray.push(locationItem?.location);
      });
      setSelectedLocation(tempLocationArray);
      setSelectedCompany(dataToEdit?.company);
      if (dataToEdit?.company?.locations.length > 0) {
        setAllLocations(dataToEdit?.company?.locations);
      }
    }
  }, [dataToEdit]);
  useEffect(() => {
    // const getAllLocations = async () => {
    //   setIsLoading(true);
    //   try {
    //     const res = await api.get(`/location/search`, {
    //       params: {
    //         // searchTerm: filterWithName,
    //         // subscriptionIds: temp?.length > 0 ? temp.toString() : "",
    //       },
    //     });
    //     if (res.status === 200) {
    //       setIsLoading(true);
    //
    //       setAllLocations(res.data.data);
    //       setIsLoading(false);
    //
    //     }
    //   } catch (error) {
    //     setIsLoading(false);
    //
    //   }
    // };
    // getAllLocations();
  }, []);
  useEffect(async () => {
    getAllCompanies();
  }, [companyId]);
  useEffect(() => {
    getAllTemplate();
    getAllCompanies();
  }, []);

  // Select Reviews
  useEffect(() => {
    let reviewIncludes = "Present";
    let reviewNotIncludes = "Not Present";

    const reviews = selectedReviewOption?.map((item) => item?.label);
    const reviewPresent = reviews?.includes(reviewIncludes);
    const reviewNotPresent = reviews?.includes(reviewNotIncludes);

    setReviewPresent(reviewPresent);
    setReviewNotPresent(reviewNotPresent);
  }, [selectedReviewOption]);

  //  Select Ratings
  useEffect(() => {
    selectedRating?.OneStar === true && setSelectedRating("one");
    selectedRating?.TwoStar === true && setSelectedRating("two");
    selectedRating?.ThreeStar === true && setSelectedRating("three");
    selectedRating?.FourStar === true && setSelectedRating("four");
    selectedRating?.FiveStar === true && setSelectedRating("five");
  }, [selectedRating]);

  // useEffect(() => {
  //   if (location?.state?.sendLocations) {
  //     setlocationData(location?.state?.sendLocations);
  //   }
  // }, [locationData]);
  // Helper Function to get rule data to edit
  const getRuleToEdit = async () => {
    try {
      const res = await api.get(`/rule/${id}`);
      if (res.status === 200) {
        setIsLoading(true);
        setDataToEdit(res?.data?.data);
        setIsLoading(false);
      }
      // setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const handleSuperviseTemplate = () => {
    setAIReview(true);
  };
  const handleUnsuperviseTemplate = () => {
    setAIReview(false);
  };
  // Patch request to update rule
  const updateRule = async () => {
    // Extracting ids from location and template to send to db =====================
    let templateIds = [];
    selectedTemplate?.map((template) => {
      templateIds.push(template?.id);
    });

    let locationIds = [];
    selectedLocation?.map((location) => {
      locationIds.push(location?.id);
    });
    if (title === null) {
      setTitleError(true);
    }
    if (selectedCompany === null) {
      setCompanyIdRequired(true);
    }
    // if (selectedLocation.length < 1) {
    //   setLocationRequired(true);
    // }
    // if (locationId === undefined) {
    //   setLocationRequired(true);
    // }
    if (selectedLocation?.length < 1) {
      setLocationRequired(true);
    }
    if (selectedReviewOption?.length < 1) {
      setReviewRequired(true);
    }
    if (templateIds?.length < 1) {
      setTemplateRequired(true);
    }
    // if (selectedLocation.length < 1) {
    //   setLocationRequired(true);
    // }
    if (!selectedRating) {
      setRatingRequired(true);
    }
    const data = {
      title,
      templateIds: aiReview === true ? templateIds : null,
      companyId: selectedCompany?.id,
      locationIds: locationIds,
      ratingStar: selectedRating,
      reviewTextPresent: reviewPresent,
      reviewTextNotPresent: reviewNotPresent,
      isSupervised: aiReview,
      // title,
      // templateIds: aiReview===true ? templateIds : null ,
      // locationIds: locationIds ,
      // ratingStar: aiReview ===true ? selectedRating : null ,
      // reviewTextPresent: aiReview ===true ? reviewPresent : null ,
      // reviewTextNotPresent: aiReview ===true ? reviewNotPresent : null ,
      // isSupervised: aiReview,
    };
    if (aiReview === true) {
      if (
        data?.title !== "" &&
        data.companyId !== null &&
        data?.templateIds?.length > 0 &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setLoading(true);
          const res = await api.patch(`/rule/${id}`, data);

          /*   if (res.status === 200) {
          setLoading(true);

          navigate("/user/rules");
          setIsLoading(false);
        } */
          if (res.status === 200) {
            setLoading(true);
            toast.success(t("Rule_updated_successfully"));
            navigate("/admin/rules");
            setErrors("");
            //  setMessage(res.data.message);
            setIsLoading(false);
          }

          setIsLoading(false);
        } catch (error) {
          //  toast.error(error?.response?.data?.message);
          setErrors(error?.response?.data?.message);
          setErrorRules(error?.response?.data?.data);
          // toast.error("ratingStar with this locationIds already exist");
        }
      }
    } else {
      if (
        data?.title !== "" &&
        data.companyId !== null &&
        //  data.templateIds.length > 0 &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setLoading(true);
          const res = await api.patch(`/rule/${id}`, data);

          /*   if (res.status === 200) {
          setLoading(true);

          navigate("/user/rules");
          setIsLoading(false);
        } */
          if (res.status === 200) {
            setLoading(true);
            toast.success("Rule updated successfully");
            navigate("/admin/rules");
            setErrors("");
            //  setMessage(res.data.message);
            setIsLoading(false);
          }

          setIsLoading(false);
        } catch (error) {
          //  toast.error(error?.response?.data?.message);
          setErrors(error?.response?.data?.message);
          setErrorRules(error?.response?.data?.data);
          // toast.error("ratingStar with this locationIds already exist");
        }
      }
    }
  };

  //  ==================== Add Ruleee ==================================== //

  const AddRuleNavigateButton = () => {
    navigate(`/admin/addrule`);
  };

  const getAllTemplate = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/template`);

      if (res.status === 200) {
        setLoading(true);
        setAllTemplate(res?.data?.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  // const getAllCompanies = async () => {
  //   try {
  //     const res = await api.get(`/admin/company`, {
  //       params: {
  //         limit: 100,
  //       },
  //     });
  //     if (res.status === 200) {
  //       setAllCompanies(res.data.data);
  //
  //     }
  //   } catch (error) { }
  // };
  const getAllCompanies = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    if (option?.locations?.length > 0) {
      setAllLocations(option?.locations);
    } else {
      setAllLocations([]);
    }
    setSelectedLocation([]);
  };

  const handleLocationChange = (option) => {
    setSelectedLocation(option);
  };

  const createRule = async () => {
    if (title === null) {
      setTitleError(true);
    }
    if (selectedTemplate?.length < 1) {
      setTemplateRequired(true);
    }
    if (selectedCompany === null) {
      setCompanyIdRequired(true);
    }

    if (selectedLocation?.length < 1) {
      // if (selectedLocation.length < 1) {
      //   setLocationRequired(true);
      // }
      setLocationRequired(true);
    }

    if (!selectedRating) {
      setRatingRequired(true);
    }
    if (selectedReviewOption?.length < 1) {
      setReviewRequired(true);
    }

    // if (!selectedTemplate.length || selectedLocation !== undefined) {
    //   toast.warn("please select all the fields");
    //   return;
    // }
    // let temp = [...productFilter];

    // Extracting ids from location and template to send to db =====================
    let templateIds = [];
    selectedTemplate?.map((template) => {
      templateIds.push(template?.id);
    });

    let locationIds = [];
    selectedLocation?.map((location) => {
      locationIds.push(location?.id);
    });
    // ================================================================
    const data = {
      title: title,
      companyId: selectedCompany?.id,
      templateIds: aiReview === true ? templateIds : null,
      locationIds: locationIds,
      ratingStar: selectedRating,
      reviewTextPresent: reviewPresent,
      reviewTextNotPresent: reviewNotPresent,
      isSupervised: aiReview,
    };
    {
    }
    if (aiReview === true) {
      if (
        data?.templateIds?.length > 0 &&
        data?.companyId !== null &&
        data?.title !== "" &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setIsLoading(true);
          const res = await api.post(`/rule`, data);

          if (res.status === 200) {
            setIsLoading(false);
            setErrors("");
            //   setMessage(res.data.message);
            toast.success(t("Rule_added_successfully"));
            navigate("/admin/rules");
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          setErrors(error?.response?.data?.message || "Something went wrong");
          setErrorRules(error?.response?.data?.data);
          //  toast.error(error?.response?.data?.message);
        }
      }
    } else {
      if (
        //  data?.templateIds !== null &&
        data?.title !== "" &&
        data?.companyId !== null &&
        data?.locationIds?.length > 0 &&
        data?.ratingStar?.length > 0 &&
        selectedRating &&
        selectedReviewOption?.length > 0
      ) {
        try {
          setIsLoading(true);
          const res = await api.post(`/rule`, data);

          if (res.status === 200) {
            setIsLoading(false);
            setErrors("");
            //   setMessage(res.data.message);
            toast.success("Rule added successfully");
            navigate("/admin/rules");
          }

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          setErrors(error?.response?.data?.message || "Something went wrong");
          setErrorRules(error?.response?.data?.data);
          //  toast.error(error?.response?.data?.message);
        }
      }
    }

    // if (

    // data.templateIds !== null &&
    // data.title !== null &&
    // data.locationIds.length > 0 &&
    // data.ratingStar.length > 0 &&

    //  selectedRating &&
    // selectedReviewOption.length > 0

    //)
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className="add-rule-box1">
            <TitleHeader
              title={t("AutoRule_Title")}
              showButton={true}
              createButton={false}
              onClick={id !== undefined ? updateRule : createRule}
            />
          </Box>
          {errors && (
            <Grid container className={classes.mainContainer}>
              <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}>
                <ErrorOutlineIcon className={classes.errorIcon} />
              </Grid>
              <Grid item xs={11.5} sm={11.5} md={11.5} lg={11.5}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography className={classes.errorText}>
                    {errors}
                  </Typography>
                  {errorRules?.length > 0 && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      {errorRules?.map((item, index) => (
                        <Typography key={index} className={classes.errorText}>
                          {"•" + " " + item}
                        </Typography>
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {/*  {errors && ( */}
          {/*   <Alert mt={2} mb={2} severity="error">
            {errors}
          </Alert> */}
          {/*   )} */}
          {/*   {message && (
            <Alert mt={2} mb={2} severity="success">
              {message}
            </Alert>
          )} */}
          <Paper borderRadius="12px">
            <Box className={classes.addRuleMainBox}>
              {" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/*  {editData ? (
                  <TextField
                    // disabled={loading}
                    required
                    label="Title"
                    defaultValue={title || `${t("New_Rule")}`}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        setEditData(false);
                      }
                    }}
                    onChange={(event) => {
                      setTitle(event.target.value);
                    }}
                    fullWidth
                    variant="outlined"
                  />
                ) : (
                  <Typography variant="h3">
                    {id !== undefined ? title : <> {t("Reply_Templates")}</>}
                  </Typography>
                )} */}
                {editData ? (
                  <>
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {t("Title")}{" "}
                      </Typography>
                      <Box sx={{ display: "flex" }}>
                        <TextField
                          // disabled={loading}
                          required
                          placeholder={t("Enter_Title")}
                          defaultValue={title}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              setEditData(false);
                            }
                          }}
                          error={titleError}
                          onChange={(event) => {
                            if (event.target.value.length === 0) {
                              setTitleError(true);
                            } else {
                              setTitleError(false);
                            }
                            setTitle(event.target.value);
                          }}
                          fullWidth
                          variant="outlined"
                        />

                        <IconButton
                          aria-label="done"
                          size="large"
                          onClick={() => {
                            setEditData(!editData);
                          }}
                          disabled={!title}
                        >
                          {" "}
                          <CheckIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "14px",
                          color: "#1B2430",
                          marginBottom: "8px",
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {title}
                      </Typography>
                      <CustomWidthTooltip title={t("Edit_Title")}>
                        <IconButton
                          aria-label="edit"
                          sx={{ fontSize: "14px" }}
                          onClick={() => {
                            setEditData(!editData);
                          }}
                        >
                          {" "}
                          <Edit />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Grid>
                  </>
                )}
                {/*
                {id !== undefined && !editData && (
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "14px",
                      color: "#1B2430",
                      marginBottom: "8px",
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    {title}
                  </Typography>


                )}

                {editData && (

                  <TextField
                    // disabled={loading}
                    required
                    label="Title"
                    defaultValue={title}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        setEditData(false);
                      }
                    }}
                    error={titleError}
                    onChange={(event) => {
                      if (event.target.value.length === 0) {
                        setTitleError(true);
                      } else {
                        setTitleError(false);
                      }
                      setTitle(event.target.value);
                    }}
                    fullWidth
                    variant="outlined"
                  />
                )}

                <CustomWidthTooltip title="Edit Title">
                  <IconButton
                    aria-label="edit"
                    size="large"
                    onClick={() =>
                      setEditData(
                        editData===true ? false : false ? true : true
                      )
                    }
                    disabled={!title}
                  >
                    <Edit />
                  </IconButton>
                </CustomWidthTooltip> */}
              </Box>
              <LoadingButton
                name="Save"
                variant="contained"
                color="primary"
                size="large"
                onClick={id !== undefined ? updateRule : createRule}
                loading={isLoading}
              >
                {id !== undefined ? <>{t("Update")} </> : <>{t("Save")}</>}
              </LoadingButton>
            </Box>
            <Box className={classes.addRuleLocation}>
              <Typography variant="h4">{t("Company")}</Typography>
            </Box>

            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Typography sx={{ padding: "8px" }}>
                  {t("Select_Company")}
                </Typography>

                <Autocomplete
                  // multiple
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allCompanies?.results?.length > 0
                      ? allCompanies?.results
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.name
                      ? option?.name +
                        " " +
                        (option?.city !== null ? "(" + option?.city + ")" : "")
                      : ""
                  }
                  className="Autocomplete-field"
                  defaultValue={selectedCompany}
                  // value={companyId}
                  onChange={(e, value) => {
                    handleCompanyChange(value);
                    setCompanyIdRequired(false);
                    setSelectAll(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("Select_Company")}
                      {...params}
                      required
                      name="company"
                      error={companyIdRequired}
                    />
                  )}
                ></Autocomplete>
              </Paper>
            </Box>
            <Box className={classes.addRuleLocation}>
              <Typography variant="h4">{t("Locations")}</Typography>
            </Box>

            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Typography sx={{ padding: "8px" }}>
                  {t("Location_title")}
                </Typography>

                {/* <Autocomplete
                  multiple
                  error={locationRequired}
                  disablePortal
                  id="combo-box-demo"
                  options={
                    allLocations?.results?.length > 0
                      ? allLocations?.results
                      : []
                  }
                  defaultValue={selectedLocation}
                  getOptionLabel={(option) =>
                    option?.internalName ? option?.internalName + "(" + option?.city + ")" : ""
                  }
                  sx={{ minWidth: 300 }}
                  // value={locationId ? locationId : []}
                  onChange={(e, value) => {
                    setLocationRequired(false);

                    if (id !== undefined) {
                      setSelectedLocation(value);
                    } else {
                      setSelectedLocation(value);
                    }
                    //   setSelectedLocation(value.map((item) => item.id));
                    //</Paper>  setSelectedLocation(value);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} (${option.city})`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      name="language"
                      {...params}
                      error={locationRequired}
                      placeholder={t("Select_Location")}
                    //  defaultValue={{ selectedLocation }}
                    />
                  )}
                ></Autocomplete> */}
                {/* <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={allLocation?.length > 0 ? allLocation : []}
                  getOptionLabel={(option) =>
                    option?.internalName ? option?.internalName + "(" + option?.city + ")" : ""
                  }
                  className="Autocomplete-field"
                  value={locationId ? locationId : []}
                  onChange={(e, value) => {


                    handleLocationChange(value);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option?.internalName} (${option.city})`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder={t("SelectLocation")}
                      {...params}
                      required
                      name="language"
                    />
                  )}
                ></Autocomplete> */}
                <Autocomplete
                  multiple
                  error={locationRequired}
                  disablePortal
                  id="combo-box-demo"
                  disableCloseOnSelect
                  options={allLocations?.length > 0 ? allLocations : []}
                  value={selectedLocation}
                  onChange={(event, value) => {
                    // Check if 'All' option is clicked
                    if (value?.length > 0) {
                      setLocationRequired(false);
                    } else {
                      setLocationRequired(true);
                    }

                    if (value.find((option) => option.id === 0)) {
                      // Check if all options are selected
                      if (allLocations?.length === selectedLocation?.length) {
                        setSelectedLocation([]);
                        setSelectAll(false);
                      } else {
                        setSelectAll(true);
                        setSelectedLocation(allLocations);
                        //  setLocationRequired(false);
                      }
                    } else {
                      setSelectedLocation(value);

                      if (value?.length === allLocations?.length) {
                        setSelectAll(true);
                      } else {
                        setSelectAll(false);
                      }
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (allLocations?.length > 0) {
                      return [
                        { id: 0, internalName: "Select All" },
                        ...filtered,
                      ];
                    } else {
                      return [];
                    }
                  }}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option?.internalName +
                        " " +
                        "(" +
                        (option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? option?.addressLine1 + "," + " "
                          : "") +
                        (option?.city ? option?.city : "") +
                        ")"
                      : ""
                  }
                  sx={{ minWidth: 300 }}
                  // value={locationId ? locationId : []}
                  // onChange={(e, value) => {
                  //   setLocationRequired(false);

                  //   setSelectedLocation(value);
                  // }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        id="select-all-checkbox"
                        checked={selectAll || selected}
                        // checked={selected}
                      />
                      {`${option?.internalName} ${
                        option?.addressLine1 !== "" &&
                        option?.addressLine1 !== null &&
                        option?.addressLine1 !== undefined
                          ? "(" + option?.addressLine1 + "," + " "
                          : ""
                      } ${option?.city ? option?.city + ")" : ""}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      name="language"
                      {...params}
                      error={locationRequired}
                      placeholder={t("Select_Location")}
                      //  defaultValue={{ selectedLocation }}
                    />
                  )}
                ></Autocomplete>
              </Paper>
            </Box>
            <Box className={classes.addRuleLocation}>
              <Typography variant="h4">{t("AI_Review_Response")}</Typography>
            </Box>

            <Box className={classes.addRuleLocationTitle}>
              <Paper sx={{ padding: "1rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={6} lg={6}>
                    <Typography sx={{ paddingTop: "30px" }}>
                      {t("Review_subhead")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={6}>
                    <Grid container spacing={2} sx={{ paddingTop: "8px" }}>
                      <Tooltip
                        sx={{ cursor: "pointer" }}
                        title={t("Unsupervised_text")}
                      >
                        <Grid
                          item
                          xs={5.5}
                          sm={5.5}
                          md={5.5}
                          lg={5.5}
                          sx={{
                            backgroundColor:
                              aiReview === false ? "#E0F7FF" : "white",
                            border:
                              aiReview === false
                                ? "1px solid #06BDFF"
                                : "1px solid #E0E0E0",
                            borderRadius: "8px",
                          }}
                          onClick={() => handleUnsuperviseTemplate()}
                        >
                          <Box
                            sx={{
                              alignItem: "center",
                              width: "100%",
                              justifyContent: "center",
                              paddingY: "8px",
                              display: "flex",
                              height: "40px",
                            }}
                          >
                            <img alt="" src={Skull} />
                          </Box>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              cursor: "pointer",
                              fontSize: "1.125rem",
                              justifyContent: "center",
                              display: "flex",
                              paddingBottom: "8px",
                            }}
                          >
                            {t("Unsupervised")}
                          </Typography>
                        </Grid>
                      </Tooltip>
                      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                      <Tooltip
                        sx={{ cursor: "pointer" }}
                        title={t("Supervised_text")}
                      >
                        <Grid
                          item
                          xs={5.5}
                          sm={5.5}
                          md={5.5}
                          lg={5.5}
                          sx={{
                            backgroundColor:
                              aiReview === true ? "#E0F7FF" : "white",
                            border:
                              aiReview === true
                                ? "1px solid #06BDFF"
                                : "1px solid #E0E0E0",
                            borderRadius: "8px",
                          }}
                          onClick={() => handleSuperviseTemplate()}
                        >
                          <Box
                            sx={{
                              alignItem: "center",
                              width: "100%",
                              justifyContent: "center",
                              display: "flex",
                              paddingY: "8px",
                              height: "40px",
                            }}
                          >
                            <img alt="" src={Person} />
                          </Box>
                          <Typography
                            sx={{
                              fontWeight: "500",
                              cursor: "pointer",
                              fontSize: "1.125rem",
                              justifyContent: "center",
                              display: "flex",
                              paddingBottom: "8px",
                            }}
                          >
                            {t("Supervised")}
                          </Typography>
                        </Grid>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Box>

            <Typography variant="h4" padding="1rem" marginLeft="1rem">
              {t("Conditions")}
            </Typography>
            <Box className={classes.Condition} borderColor="primary.main">
              <Typography variant="h6" marginBottom="2rem">
                {/* {t("Contition_1")} */}
              </Typography>
              <Paper padding="3rem">
                <Grid
                  className={classes.Rating}
                  // sx={{ marginBottom: "1rem" }}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={6}
                  background="blue"
                >
                  <Grid item md={2}>
                    <Box marginLeft="3rem">
                      <Typography>{t("Rating")}</Typography>
                    </Box>
                  </Grid>
                  <Grid item md={8}>
                    <Box>
                      <Autocomplete
                        error={ratingRequired}
                        onChange={(e, value) => {
                          setRatingRequired(false);

                          setSelectedRating(value);
                        }}
                        disablePortal
                        id="combo-box-demo"
                        getOptionLabel={(option) =>
                          option.charAt(0).toUpperCase() + option.slice(1)
                        }
                        options={Ratings}
                        sx={{ width: "100%" }}
                        defaultValue={selectedRating && selectedRating}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={ratingRequired}
                            placeholder={t("Select_Rating")}
                            defaultValue={{ selectedRating }}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid it md={2}>
                    {/* <Box>
                    <Typography>Rating text is</Typography>
                  </Box> */}
                  </Grid>
                  <Grid item md={2}>
                    <Box className={classes.Reviews}>
                      <Typography>{t("Review_text")}</Typography>
                    </Box>
                  </Grid>

                  <Grid item md={8}>
                    <Box sx={{ marginBottom: "1rem" }}>
                      <Autocomplete
                        multiple
                        disablePortal
                        defaultValue={
                          selectedReviewOption && selectedReviewOption
                        }
                        disableCloseOnSelect
                        id="combo-box-demo"
                        // onChange={(e, value) => {
                        //   setReviewRequired(false);

                        //   setSelectedReviewOption(value);
                        // }}
                        onChange={(e, valueNew) => {
                          setReviewRequired(false);
                          handleReviewText(valueNew);
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.label === value.label
                        }
                        options={state}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              // onChange={(e) => {}}
                              style={{ marginRight: 8 }}
                              // checked={selected}
                              checked={
                                selectedReviewOption.findIndex(
                                  (item) => item?.label === option?.label
                                ) >= 0
                              }
                            />
                            {option.label}
                          </li>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={reviewRequired}
                            placeholder={t("Select_Review_Text")}
                          />
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid it md={2}>
                    {/* <Box>
                    <Typography>Met all the conditions</Typography>{" "}
                  </Box> */}
                  </Grid>
                </Grid>
              </Paper>
              {aiReview === true ? (
                <>
                  <Paper padding="1rem">
                    <Box className={classes.Auto_Reply}>
                      <Grid item md={3}>
                        <Box>
                          <Typography>{t("Auto_Reply")}</Typography>
                        </Box>
                      </Grid>
                      <Grid item md={8}>
                        <Box>
                          {allTemplate?.results?.length && (
                            <Autocomplete
                              error={templateRequired}
                              multiple
                              disablePortal
                              id="combo-box-demo"
                              options={allTemplate?.results}
                              disableCloseOnSelect
                              onChange={(e, value) => {
                                setTemplateRequired(false);
                                // handleChangeTemplate(value);
                                setSelectedTemplate(value);
                              }}
                              getOptionLabel={(option) => option?.title}
                              defaultValue={
                                selectedTemplate && selectedTemplate
                              }
                              sx={{ minWidth: 300 }}
                              renderOption={(props, option, { selected }) => {
                                return (
                                  <li {...props}>
                                    <Checkbox
                                      icon={icon}
                                      checkedIcon={checkedIcon}
                                      style={{ marginRight: 8 }}
                                      // checked={selected}
                                      checked={
                                        selectedTemplate?.findIndex(
                                          (item) => item?.id === option?.id
                                        ) >= 0
                                      }
                                    />
                                    {option?.title}
                                  </li>
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={templateRequired}
                                  placeholder={t("Select_Template")}
                                />
                              )}
                            />
                          )}
                        </Box>
                      </Grid>
                    </Box>
                  </Paper>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};

export default AddRule;
