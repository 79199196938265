import React from "react";
import LocationForm from "./Components/LocationForm";
import { Helmet } from "react-helmet-async";

import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import TitleHeader from "../../../../components/CustomComponents/title";

function Index() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Profile" />
      <TitleHeader title={t("Ad_Location")} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <LocationForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Index;
