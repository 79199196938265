import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { Alert } from "@mui/material";
import Loader from "../../../../../../../components/Loaders/Loader";
import Add from "../../../../../../../assets/images/Add.svg";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "@material-ui/core";
import LodgingAttributes from "./lodging/index";
import LocationAttributes from "./attributes/index";
import moment from "moment";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const AttributesModal = ({
  onCancel,
  locationDetailsData,
  location,
  getLocationDetails,
  allAttributes,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  getLocationDetailsListing,
  lodgingCategories,
  setIndexValue,
  openDefaultModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [itemsArr, setItemsArr] = useState([]);
  const [allAttributesArr, setAllAttributesArr] = useState([]);
  const [filteredItemsArr, setFilteredItemsArr] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [hasEmpty, setHasEmpty] = useState(false);
  const [enumRequired, setEnumRequired] = useState(false);
  const [urlRequired, setUrlRequired] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);
  const [repeatedEnumRequired, setRepeatedEnumRequired] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [removeState, setRemoveState] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [attributesWithCategories, setAttributesWithCategories] = useState({});
  const [categoriesData, setCategoriesData] = useState(null);
  const [attributesLoading, setAttributesLoading] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [displayChildCategories, setDisplayChildCategories] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [nextCategory, setNextCategory] = useState(null);
  const [integerError, setIntegerError] = useState(false);
  const [locationCategoriesData, setLocationCategoriesData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [selectedLanguagesArr, setSelectedLanguagesArr] = useState([]);

  const gridContainerRef = useRef(null);
  const hotelCategoryArr = [
    "categories/gcid:hotel",
    "categories/gcid:extended_stay_hotel",
    "categories/gcid:resort_hotel",
    "categories/gcid:capsule_hotel",
    "categories/gcid:love_hotel",
    "categories/gcid:hostel",
    "categories/gcid:motel",
    "categories/gcid:resort_hotel",
    "categories/gcid:lodge",
    "categories/gcid:camping_cabin",
    "categories/gcid:inn",
    "categories/gcid:guest_house",
    "categories/gcid:farm",
    "categories/gcid:religious_lodging",
    "categories/gcid:apartment_building",
    "categories/gcid:apartment_complex",
    "categories/gcid:apartment_rental_agency",
    "categories/gcid:chalet",
    "categories/gcid:cottage",
    "categories/gcid:villa",
    "categories/gcid:campground",
  ];
  let url = false;
  let enums = false;

  useEffect(() => {
    if (itemsArr?.length > 0) {
      if (itemsArr?.length === 1) {
        if (
          itemsArr[0]?.name === "" &&
          itemsArr[0]?.valueType === "" &&
          itemsArr[0]?.displayName === ""
        ) {
          setTouchedValue(false);
        }
      }
      if (removeState === true) {
        setDisabled(false);
      }
      /* else if (itemsArr?.length > 1) {
        setTouchedValue(true);
      } */
      setDisabled(false);
    } else if (itemsArr?.length === 0 && removeState === true) {
      setTouchedValue(true);
      setDisabled(false);
    } else if (itemsArr?.length === 0 && removeState === false) {
      setTouchedValue(false);
      setDisabled(true);
    }
  }, [itemsArr]);

  useEffect(async () => {
    if (allAttributes?.length === 0) {
      setIsLoading(true);
    }
  }, []);

  useEffect(async () => {
    if (locationDetailsData !== null && allAttributes?.length > 0) {
      handleDisplayData();
    }
  }, [allAttributes]);

  useEffect(() => {
    const hasEmptyName = itemsArr.some((item) => item.displayName === "");
    setHasEmpty(hasEmptyName);
  }, [itemsArr]);

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  useEffect(() => {
    if (
      (errors.length > 0 || errorMessage.length > 0) &&
      gridContainerRef.current
    ) {
      gridContainerRef.current.scrollTop = 0;
    }
  }, [errors, errorMessage]);
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleUpdateAttributes = async () => {
    let objjj = JSON.stringify(itemsArr);

    const filteredArr = JSON.parse(objjj);

    // let filteredArr = [...itemsArr];
    let arrWithoutEmptyData = filteredArr.filter(
      (item) => item?.displayName !== ""
    );

    //   let repeatedEnum = false;
    // const openedArr = filteredOpenArr.filter((item) => item?.closed===false);

    const errorArr = arrWithoutEmptyData?.filter(
      (item) => item?.valueType !== "BOOL"
    );

    if (itemsArr?.length > 0) {
      itemsArr?.forEach((item) => {
        if (
          item?.valueType === "URL" &&
          item?.uriValues?.length > 0 &&
          item?.uriValues[0]?.uri === ""
        ) {
          item["errorRequired"] = true;
          setUrlRequired(true);
          url = true;
        } else if (
          (item?.valueType === "ENUM" && item?.values === undefined) ||
          (item?.valueType === "ENUM" &&
            item?.values?.length > 0 &&
            item?.values[0] === false)
        ) {
          setEnumRequired(true);
          item["enumRequired"] = true;
          enums = true;
        } /* else if (
          (item?.valueType === "REPEATED_ENUM" && item?.values===undefined) ||
          (item?.values?.length > 0 && item?.values[0]===false)
        ) {
          setRepeatedEnumRequired(true);
          repeatedEnum = true;
        } */
      });
    }

    if (url === false && enums === false) {
      const dummyArr = [...arrWithoutEmptyData];
      dummyArr.forEach((item) => {
        delete item["error"];
        delete item["errorRequired"];
        delete item["enumRequired"];
        if (item?.valueType === "REPEATED_ENUM") {
          let arrSet = [];
          let arrUnset = [];
          item?.repeatedEnumValue?.setValues?.map((item) =>
            arrSet.push(item.value)
          );
          item?.repeatedEnumValue?.unsetValues?.map((item) =>
            arrUnset.push(item.value)
          );
          item.repeatedEnumValue.setValues = arrSet;
          item.repeatedEnumValue.unsetValues = arrUnset;

          // const unsetValues = item?.values;
          //  item["repeatedEnumValue"] = obj;
          //  delete item["values"];
          delete item["displayName"];
          delete item["closed"];
          delete item["showDelete"];
        } else {
          delete item["displayName"];
          delete item["closed"];
          delete item["showDelete"];
        }
        //  if (item?.closed===true) {
        /*    delete item["displayName"];
        delete item["closed"];
        delete item["showDelete"]; */
        //  }
      });

      try {
        setIsLoading(true);
        setDisplayConfirmationBox(false);
        const res = await api.patch(
          `/locationListing/update-attributes/${location?.id}`,
          {
            attributes: dummyArr,
          }
        );
        if (indexValue !== null) {
          let selectedValue = indexValue;

          setSelectedTab(selectedValue);
        }
        if (cancelState === true) {
          onCancel();
        }
        setTouchedValue(false);

        setIsLoading(false);
        toast.success("Updated Successfully");
        getLocationDetailsListing(location);
        setLocationDetailsData(res?.data?.data);
        itemsArr([]);
        handleDisplayData();
        //  setLocationDetailsData(res?.data?.data);

        // onCancel();
        // getLocationDetails(location);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    }
  };

  const handleDisplayData = () => {
    setIsLoading(true);
    let attrArr = [];

    //  let newArray = locationDetailsData?.result?.google?.attributes.slice();
    let newArray = locationDetailsData?.result?.google?.attributes;

    //  let newArray = locationDetailsData?.result?.google?.attributes.concat();
    newArray?.map((item) => {
      const result = allAttributes?.find((data) => data?.parent === item?.name);
      if (result !== undefined) {
        if (item?.valueType === "REPEATED_ENUM") {
          let arrSetValues = [];
          item?.repeatedEnumValue?.setValues?.forEach((item) => {
            result?.valueMetadata?.map((itemOne) => {
              if (item === itemOne?.value) {
                arrSetValues.push(itemOne);
              }
            });
          });
          let arrUnsetValues = [];
          item?.repeatedEnumValue?.unsetValues?.forEach((item) => {
            result?.valueMetadata?.map((itemOne) => {
              if (item === itemOne?.value) {
                arrUnsetValues.push(itemOne);
              }
            });
          });
          let enumValues = {
            unsetValues: arrUnsetValues,
            setValues: arrSetValues,
          };
          let tempObjOne = {
            name: item?.name,
            valueType: item?.valueType,
            repeatedEnumValue: enumValues,
            displayName: result?.displayName,
            closed: false,
          };
          attrArr.push(tempObjOne);
          itemsArr.push(tempObjOne);
        } else if (item?.valueType === "URL") {
          let tempObjj = {
            name: item?.name,
            valueType: item?.valueType,
            uriValues: item?.uriValues,
            displayName: result?.displayName,
            closed: false,
          };
          attrArr.push(tempObjj);
          itemsArr.push(tempObjj);
        } else {
          let tempObjTwo = {
            name: item?.name,
            valueType: item?.valueType,
            values: item?.values,
            displayName: result?.displayName,
            closed: false,
            enumRequired:
              item?.valueType === "ENUM" &&
              (item?.values[0] === false || item?.values[0] === true)
                ? true
                : false,
          };
          attrArr.push(tempObjTwo);
          itemsArr.push(tempObjTwo);
        }
      }
    });

    setItemsArr([...itemsArr]);

    let tempObj = {
      name: "",
      valueType: "",
      values: [],
      displayName: "",
      closed: false,
    };
    let tempArrrr = [];

    const tempArr = allAttributes?.map((item) => {
      tempObj = {
        name: item?.parent,
        valueType: item?.valueType,
        values: [
          item?.valueMetadata?.length > 0 && item?.valueMetadata[0]?.value,
        ],
        displayName: item?.displayName,
        closed: false,
      };
      tempArrrr.push(tempObj);
      allAttributesArr.push(tempObj);
    });
    setAllAttributesArr([...allAttributesArr]);

    const results = tempArrrr.filter(
      ({ name: id1 }) => !attrArr.some(({ name: id2 }) => id2 === id1)
    );

    setFilteredItemsArr(results);

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };
  const handleChangeAttributeValue = (value, index, item, resultValue) => {
    setTouchedValue(true);
    let tempArr = [...itemsArr];

    if (value !== null) {
      if (value?.valueType === "URL") {
        let objOne = {
          closed: value?.closed,
          displayName: value?.displayName,
          name: value?.name,
          valueType: value?.valueType,
          uriValues: [
            {
              uri: "",
            },
          ],
          showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
        };
        tempArr[index] = objOne;
        itemsArr[index] = objOne;
      } else if (value?.valueType === "REPEATED_ENUM") {
        let objTwo = {
          closed: value?.closed,
          displayName: value?.displayName,
          name: value?.name,
          valueType: value?.valueType,
          repeatedEnumValue: {
            setValues: [],
            unsetValues: [],
          },
          showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
        };
        tempArr[index] = objTwo;
        itemsArr[index] = objTwo;
      } else {
        let tempObj = {
          closed: value?.closed,
          displayName: value?.displayName,
          name: value?.name,
          valueType: value?.valueType,
          //   values: [value?.values?.length > 0 && value?.values[0]],
          values: [
            value?.valueMetadata?.length > 0 && value?.valueMetadata[0]?.value,
          ],
          showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
        };
        tempArr[index] = tempObj;
        itemsArr[index] = tempObj;
      }

      //  setSelectedItem(tempObj);
    }
    setItemsArr([...itemsArr]);

    const results = allAttributesArr.filter(
      ({ name: id1 }) => !tempArr.some(({ name: id2 }) => id2 === id1)
    );

    setFilteredItemsArr(results);
  };
  const handleChangeSwitch = (e, index) => {
    setTouchedValue(true);
    if (e.target.checked) {
      itemsArr[index].values[0] = true;
      // setOpen(true);
    } else {
      itemsArr[index].values[0] = false;
      // setOpen(false);
    }
    setItemsArr([...itemsArr]);
  };
  const handleRepeatedEnumSwitch = (e, data, index, ind) => {
    setTouchedValue(true);
    if (e.target.checked) {
      let arr = itemsArr[index].repeatedEnumValue?.setValues;
      arr.push(data);
      itemsArr[index].repeatedEnumValue.setValues = arr;
      const arrIndex = itemsArr[index].repeatedEnumValue?.unsetValues.findIndex(
        (item) => item?.value === data?.value
      );

      if (arrIndex > -1) {
        itemsArr[index].repeatedEnumValue?.unsetValues.splice(arrIndex, 1);
      }
    } else {
      let arr2 = itemsArr[index].repeatedEnumValue?.unsetValues;
      arr2.push(data);
      itemsArr[index].repeatedEnumValue.unsetValues = arr2;
      const arrIndexTwo = itemsArr[
        index
      ].repeatedEnumValue?.setValues.findIndex(
        (item) => item?.value === data?.value
      );
      if (arrIndexTwo > -1) {
        itemsArr[index].repeatedEnumValue?.setValues.splice(arrIndexTwo, 1);
      }
    }
    setItemsArr([...itemsArr]);
  };
  function validURLOther(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

  function validURL(string) {
    if (typeof string !== "string") {
      return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }

    if (
      localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }

    return false;
  }
  const handleChangeURL = (e, item, index) => {
    setTouchedValue(true);
    // itemsArr[index].values[0] = e.target.value;

    if (item?.uriValues?.length > 0) {
      itemsArr[index]["errorRequired"] = false;
      itemsArr[index].uriValues[0].uri = e.target.value;
      const occurrences = (e.target.value.match(/https?/g) || []).length;
      const wwwOccurrences = e.target.value.split("www").length - 1;
      const invalidCharacters = /[<>]/;
      const containsQueryParams = e.target.value.includes("?");
      const validity =
        validURL(e.target.value) || validURLOther(e.target.value);
      // const validityother =  validURLOther(e.target.value);
      if (containsQueryParams) {
        itemsArr[index]["error"] = true;
      } else if (invalidCharacters.test(e.target.value)) {
        itemsArr[index]["error"] = true;
      } else if (occurrences === 2) {
        itemsArr[index]["error"] = true;
      } else if (wwwOccurrences >= 2) {
        itemsArr[index]["error"] = true; // Valid URL with two or more "www" occurrences
      } else if (!validity) {
        itemsArr[index]["error"] = true;
      } else if (invalidCharacters.test(e.target.value)) {
        itemsArr[index]["error"] = true;
      } else {
        itemsArr[index]["error"] = false;
      }
    } else {
      itemsArr[index]["errorRequired"] = true;
    }
    setItemsArr([...itemsArr]);
  };
  const handleChangeEnum = (e, item, index) => {
    setTouchedValue(true);
    if (e === null) {
      itemsArr[index]["enumRequired"] = true;
    } else {
      itemsArr[index].values[0] = e;
      itemsArr[index]["enumRequired"] = false;
    }

    setItemsArr([...itemsArr]);
  };
  const handleChangeRepeatedEnum = (value, item, index) => {
    setTouchedValue(true);
    itemsArr[index].values = value;

    setItemsArr([...itemsArr]);
  };
  const handleAddNewData = () => {
    // setTouchedValue(true);
    let tempObjTwo = {
      name: "",
      valueType: "",
      values: [false],
      displayName: "",
      showDelete: true,
    };

    itemsArr.push(tempObjTwo);
    setItemsArr([...itemsArr]);
  };
  const removeAttribute = (index) => {
    setTouchedValue(true);
    setRemoveState(true);
    // setItemsArr(itemsArr.filter((el, i) => i !== index));

    let tempArr = itemsArr.filter((el, i) => i !== index);
    //  tempArr.filter((el, i) => i !== index);
    const results = allAttributesArr.filter(
      ({ name: id1 }) => !tempArr.some(({ name: id2 }) => id2 === id1)
    );
    setItemsArr(tempArr);
    setFilteredItemsArr(results);
    setUrlRequired(false);
    setEnumRequired(false);
    setUrlValidation(false);
    url = false;
    enums = false;
  };

  //*********************/ Lodging Functions

  const addMultiAttribute = (
    parent,
    subParent,
    grandParent,
    name,
    type,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    let tempObjTwo = { ...locationCategoriesData };
    if (subChild !== undefined) {
      if (type === "boolean") {
        tempObj[subParent][subChild][grandParent][parent][name].value = true;
      } else if (type === "integer" || type === "number") {
        tempObj[subParent][subChild][grandParent][parent][name].value = 0;
      } else {
        tempObj[subParent][subChild][grandParent][parent][name].value = "";
      }
    } else if (subChild === undefined && grandParent !== undefined) {
      if (type === "boolean") {
        if (name === "spoken" || name === "awarded") {
          let index = 0;
          tempObj[subParent][parent][grandParent][index][name].value = true;
        } else {
          tempObj[subParent][parent][grandParent][name].value = true;
        }
      } else if (type === "integer" || type === "number") {
        tempObj[subParent][parent][grandParent][name].value = 0;
      } else {
        if (name === "ecoCertificate") {
          let index = 0;
          tempObj[subParent][parent][grandParent][index][name].value = "";
        } else {
          tempObj[subParent][parent][grandParent][name].value = "";
        }
        // tempObj[subParent][parent][grandParent][name].value = "";
      }
    } else if (
      grandParent === undefined &&
      subParent === undefined &&
      parent === undefined &&
      subChild === undefined
    ) {
      if (type === "boolean") {
        tempObj[name].value = true;
      } else if (type === "integer" || type === "number") {
        tempObj[name].value = 0;
      } else {
        tempObj[name].value = "";
      }
    } else if (subParent !== undefined) {
      if (type === "boolean") {
        if (name === "spoken") {
          let index = 0;
          tempObj[subParent][parent][index][name].value = true;
        } else if (name === "awarded") {
          let index = 0;
          tempObj[subParent][parent][grandParent][index][name].value = true;
        } else {
          tempObj[subParent][parent][name].value = true;
        }
      } else if (type === "integer" || type === "number") {
        tempObj[subParent][parent][name].value = 0;
      } else {
        if (name === "languageCode") {
          let index = 0;
          tempObj[subParent][parent][index][name].value = "";
        } else {
          tempObj[subParent][parent][name].value = "";
        }
      }
    } else {
      if (type === "boolean") {
        tempObj[parent][name].value = true;
      } else if (type === "integer" || type === "number") {
        tempObj[parent][name].value = 0;
      } else {
        tempObj[parent][name].value = "";
      }
    }
    setCategoriesData(tempObj);
  };

  const removeMultiAttribute = (
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value = null;
    } else if (grandParent === undefined && subParent !== undefined) {
      if (name === "languageCode") {
        let index = 0;
        tempObj[subParent][parent][index][name].value = null;
      } else if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][index][name].value = null;
      } else {
        tempObj[subParent][parent][name].value = null;
      }
    } else if (subChild === undefined && grandParent !== undefined) {
      if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = null;
      } else if (name === "awarded") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = null;
      } else if (name === "ecoCertificate") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = null;
      } else if (name === "languageCode") {
        let index = 0;

        tempObj[subParent][parent][index][name].value = "";
      } else {
        tempObj[subParent][parent][grandParent][name].value = null;
      }
    } else if (
      subChild === undefined &&
      subParent === undefined &&
      parent === undefined
    ) {
      tempObj[name].value = null;
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = null;
    } else {
      tempObj[parent][name].value = null;
    }
    setCategoriesData(tempObj);
  };

  const handleChangeChecked = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (
      subParent === undefined &&
      parent === undefined &&
      grandParent === undefined &&
      subChild === undefined
    ) {
      tempObj[name].value = e;
    } else if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value =
        e.target.checked;
    } else if (subChild === undefined && grandParent !== undefined) {
      if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value =
          e.target.checked;
      } else if (name === "awarded") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value =
          e.target.checked;
      } else {
        tempObj[subParent][parent][grandParent][name].value = e.target.checked;
      }
    } else if (
      subChild === undefined &&
      subParent === undefined &&
      parent === undefined
    ) {
      tempObj[name].value = e.target.checked;
    } else if (grandParent === undefined && subParent !== undefined) {
      if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][index][name].value = e.target.checked;
      } else {
        tempObj[subParent][parent][name].value = e.target.checked;
      }
    } else {
      tempObj[parent][name].value = e.target.checked;
    }

    setCategoriesData(tempObj);
  };
  const handleChangeStringValue = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value = e;
    } else if (subParent === undefined && parent === undefined) {
      tempObj[name].value = e;
    } else if (subParent === undefined && parent !== undefined) {
      if (name === "codes") {
        let index = 0;
        tempObj[parent][name][index].value = e;
      } else {
        tempObj[parent][name].value = e;
      }
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = e;
    } else {
      tempObj[parent][name].value = e;
    }
    setCategoriesData(tempObj);
  };

  const handleChangeIntegerValue = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value =
        parseInt(e);
    } else if (subParent === undefined && parent === undefined) {
      tempObj[name].value = parseInt(e);
    } else if (subParent === undefined && parent !== undefined) {
      if (name === "codes") {
        let index = 0;
        tempObj[parent][name][index].value = parseInt(e);
      } else {
        tempObj[parent][name].value = parseInt(e);
      }
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = parseInt(e);
    } else {
      tempObj[parent][name].value = parseInt(e);
    }
    setCategoriesData(tempObj);
  };
  const handleChangeFloatValue = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value =
        parseFloat(e);
    } else if (subParent === undefined && parent === undefined) {
      tempObj[name].value = parseFloat(e);
    } else if (subParent === undefined && parent !== undefined) {
      if (name === "codes") {
        let index = 0;
        tempObj[parent][name][index].value = parseFloat(e);
      } else {
        tempObj[parent][name].value = parseFloat(e);
      }
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = parseFloat(e);
    } else {
      tempObj[parent][name].value = parseFloat(e);
    }
    setCategoriesData(tempObj);
  };
  const handleChangeDateValue = (e, parent, subParent, name) => {
    setTouchedValue(true);
    let tempObj = { ...categoriesData };
    const hourValue = dayjs(e).format("HH");
    const minuteValue = dayjs(e).format("m");
    const seconds = dayjs(e).format("SS");

    if (subParent === undefined && parent === undefined) {
      tempObj[name].value = e;
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = e;
    } else {
      tempObj[parent][name].hours.value = hourValue;
      tempObj[parent][name].minutes.value = minuteValue;
      tempObj[parent][name].seconds.value = seconds;
    }
    setCategoriesData(tempObj);
  };
  const addLanguageAttribute = (index) => {
    let tempObj = { ...categoriesData };

    tempObj.services.languagesSpoken[index].spoken.value = true;
    selectedLanguagesArr[index].spoken.value = true;
    setSelectedLanguagesArr([...selectedLanguagesArr]);
    // setCategoriesData(tempObj);
  };
  const removeLanguageAttribute = (index) => {
    let tempObj = { ...categoriesData };
    tempObj.services.languagesSpoken[index].spoken.value = null;
    selectedLanguagesArr[index].spoken.value = null;
    setCategoriesData(tempObj);
    setSelectedLanguagesArr([...selectedLanguagesArr]);
  };
  const switchLanguageAttribute = (index, e) => {
    let tempObj = { ...categoriesData };
    tempObj.services.languagesSpoken[index].spoken.value = e;
    selectedLanguagesArr[index].spoken.value = e;
    setCategoriesData(tempObj);
    setSelectedLanguagesArr([...selectedLanguagesArr]);
  };
  const handleChangeLodgingEnum = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);
    let tempObj = { ...categoriesData };
    if (
      subParent === undefined &&
      parent === undefined &&
      grandParent === undefined
    ) {
      tempObj[name].value = e;
    } else if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value = e;
    } else if (grandParent !== undefined) {
      if (name === "languageCode" || name === "ecoCertificate") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = e;
      } else {
        tempObj[subParent][parent][name].value = e;
      }
    } else if (grandParent === undefined && subParent !== undefined) {
      if (name === "languageCode") {
        let index = 0;

        tempObj[subParent][parent][index][name].value = e;
      } else {
        tempObj[subParent][parent][name].value = e;
      }
    } else {
      tempObj[parent][name].value = e;
    }
    setCategoriesData(tempObj);
  };
  const handleUpdateAttributesWithCategories = async () => {
    let errArray = [];

    let objjj = JSON.stringify(categoriesData);

    const dummyObj = JSON.parse(objjj);

    Object.entries(dummyObj).map(([keyOne, valueOne]) => {
      delete valueOne.isEditable;
      if (valueOne?.hasOwnProperty("type")) {
        if (
          valueOne?.value !== null &&
          valueOne?.value !== false &&
          valueOne?.hasOwnProperty("depandant")
        ) {
          const approved = valueOne?.depandant?.some(
            (item) => item?.value === null
          );
        }
      } else {
        Object.entries(valueOne).map(([keyTwo, valueTwo]) => {
          if (
            valueTwo?.hasOwnProperty("type") ||
            valueTwo?.hasOwnProperty("hours")
          ) {
            let index = 0;
            if (
              valueTwo?.value !== null &&
              valueTwo?.value !== false &&
              valueTwo?.hasOwnProperty("depandant")
            ) {
              let arr = [];
              const filledValue = valueTwo?.depandant?.forEach((item) => {
                if (dummyObj[`${keyOne}`][`${item?.key}`]?.value === null) {
                  arr.push(item?.key);
                }
              });
              if (arr.length === valueTwo?.depandant?.length) {
                valueTwo?.depandant.map((item) => errArray.push(item?.key));
              }
            } else if (
              valueTwo?.value === null &&
              valueTwo?.hasOwnProperty("depandant")
            ) {
              let arr = [];
              const filledValue = valueTwo?.depandant?.forEach((item) => {
                if (dummyObj[`${keyOne}`][`${item?.key}`]?.value === null) {
                  arr.push(item?.key);
                }
              });
              if (arr.length !== valueTwo?.depandant?.length) {
                errArray.push(keyTwo);
              }
            }
          }
          /*  else {
           
            if (Array.isArray(valueTwo)) {
              if (keyTwo === "codes") {
                let index = 0;
                dummyObj[`${keyOne}`][`${keyTwo}`] =
                  dummyObj[`${keyOne}`][`${keyTwo}`][index]?.value;
              } else if (keyTwo === "languagesSpoken") {
                Object.entries(valueTwo[0])?.map(([keyArr, valueArr]) => {
                  let index = 0;
                  dummyObj[`${keyOne}`][`${keyTwo}`][index][`${keyArr}`] =
                    dummyObj[`${keyOne}`][`${keyTwo}`][index]?.[
                      `${keyArr}`
                    ]?.value;
                });
              }
            } else {
              Object.entries(valueTwo).map(([keyThree, valueThree]) => {
               
                if (valueThree?.hasOwnProperty("type")) {
                  dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`] =
                    dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`]?.value;
                } else {
                  if (Array.isArray(valueThree)) {
                    let index = 0;
                    valueThree?.map((itemFour) => {
                      Object.entries(itemFour).map(([keyFour, valueFour]) => {
                       
                        dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                          index
                        ][`${keyFour}`] =
                          dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                            index
                          ][`${keyFour}`]?.value;
                      });
                    });
                  } else {
                    Object.entries(valueThree)?.map(([keyFour, valueFour]) => {
                      if (valueFour?.hasOwnProperty("type")) {
                        dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                          `${keyFour}`
                        ] =
                          dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                            `${keyFour}`
                          ]?.value;
                      } else {
                        Object.entries(valueFour)?.map(
                          ([keyFive, valueFive]) => {
                            dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                              `${keyFour}`
                            ][`${keyFive}`] =
                              dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                                `${keyFour}`
                              ]?.[`${keyFive}`]?.value;
                          }
                        );
                      }
                    });
                  }
                }
              });
            }
          } */
        });
      }
    });
    // const uniqueErrors = new Set(errArray);
    const uniqueErrors = [...new Set(errArray)];

    setErrors(uniqueErrors);

    if (errArray?.length <= 0) {
      setAttributesLoading(true);
      setDisplayChildCategories(true);
      let tempObj = {};
      Object.entries(dummyObj).map(([keyOne, valueOne]) => {
        delete valueOne.isEditable;
        if (valueOne?.hasOwnProperty("type")) {
          dummyObj[`${keyOne}`] = dummyObj[`${keyOne}`]?.value;
        } else {
          Object.entries(valueOne).map(([keyTwo, valueTwo]) => {
            if (
              valueTwo?.hasOwnProperty("type") ||
              valueTwo?.hasOwnProperty("hours")
            ) {
              if (valueTwo?.hasOwnProperty("hours")) {
                dummyObj[`${keyOne}`][`${keyTwo}`].hours = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.hours?.value
                );
                dummyObj[`${keyOne}`][`${keyTwo}`].minutes = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.minutes?.value
                );
                dummyObj[`${keyOne}`][`${keyTwo}`].nanos = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.nanos?.value
                );
                dummyObj[`${keyOne}`][`${keyTwo}`].seconds = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.seconds?.value
                );
              } else {
                let index = 0;
                dummyObj[`${keyOne}`][`${keyTwo}`] =
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.value;
              }
            } else {
              if (Array.isArray(valueTwo)) {
                if (keyTwo === "codes") {
                  let index = 0;
                  dummyObj[`${keyOne}`][`${keyTwo}`] =
                    dummyObj[`${keyOne}`][`${keyTwo}`][index]?.value;
                } else if (keyTwo === "languagesSpoken") {
                  valueTwo.map((item, index) => {
                    Object.entries(item)?.map(([keyArr, valueArr]) => {
                      dummyObj[`${keyOne}`][`${keyTwo}`][index][`${keyArr}`] =
                        dummyObj[`${keyOne}`][`${keyTwo}`][index]?.[
                          `${keyArr}`
                        ]?.value;
                    });
                  });
                }
              } else {
                Object.entries(valueTwo).map(([keyThree, valueThree]) => {
                  if (valueThree?.hasOwnProperty("type")) {
                    dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`] =
                      dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`]?.value;
                  } else {
                    if (Array.isArray(valueThree)) {
                      let index = 0;
                      valueThree?.map((itemFour) => {
                        Object.entries(itemFour).map(([keyFour, valueFour]) => {
                          dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                            index
                          ][`${keyFour}`] =
                            dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                              index
                            ][`${keyFour}`]?.value;
                        });
                      });
                    } else {
                      valueThree &&
                        Object.entries(valueThree)?.map(
                          ([keyFour, valueFour]) => {
                            if (valueFour?.hasOwnProperty("type")) {
                              dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                                `${keyFour}`
                              ] =
                                dummyObj[`${keyOne}`][`${keyTwo}`][
                                  `${keyThree}`
                                ][`${keyFour}`]?.value;
                            } else {
                              valueFour &&
                                Object.entries(valueFour)?.map(
                                  ([keyFive, valueFive]) => {
                                    dummyObj[`${keyOne}`][`${keyTwo}`][
                                      `${keyThree}`
                                    ][`${keyFour}`][`${keyFive}`] =
                                      dummyObj[`${keyOne}`][`${keyTwo}`][
                                        `${keyThree}`
                                      ][`${keyFour}`]?.[`${keyFive}`]?.value;
                                  }
                                );
                            }
                          }
                        );
                    }
                  }
                });
              }
            }
          });
        }
      });
      try {
        setIsLoading(true);

        const res = await api.patch(
          `/lodging/updatelodgingDetails/${location?.id}`,
          dummyObj
        );
        if (res.status === 200) {
          setDisplayConfirmationBox(false);
          // setSelectedCategoryName(null);
          setErrorMessage([]);
          setErrors([]);
        }

        if (indexValue !== null) {
          let selectedValue = indexValue;

          setSelectedTab(selectedValue);
        }
        if (cancelState === true) {
          onCancel();
        }
        setTouchedValue(false);
        setIsLoading(false);
        toast.success("Updated Successfully");
        getLocationDetailsListing(location);
        setAttributesLoading(false);
        setErrorMessage([]);
        setErrors([]);
        setNextCategory(null);
        setOpenConfirmBox(false);
        // setCategoriesData(null);
        //  setLocationDetailsData(res?.data?.data);

        //  setLocationDetailsData(res?.data?.data);

        // onCancel();
        // getLocationDetails(location);
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setAttributesLoading(false);
        if (error?.response?.status === 404) {
          setErrorMessage(error?.response?.data?.message);
        }
      }
    }
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={
              lodgingCategories?.length > 0 &&
              lodgingCategories.includes(
                locationDetailsData?.result?.google?.categories?.primaryCategory
                  ?.name
              ) &&
              location?.isLodgingEnabled === true
                ? handleUpdateAttributesWithCategories
                : handleUpdateAttributes
            }
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            getLocationDetails={getLocationDetails}
            location={location}
            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={11}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("Attributes")}
          </Typography>
          <BootstrapTooltip title={t("attributes_subhead")}>
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton
            autoFocus
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        className={classes.gridContainertwoBulk}
        ref={gridContainerRef}
      >
        <Grid container sx={{ padding: "18px" }}>
          {lodgingCategories?.length > 0 &&
          lodgingCategories.includes(
            locationDetailsData?.result?.google?.categories?.primaryCategory
              ?.name
          ) &&
          location?.isLodgingEnabled === true ? (
            <LodgingAttributes
              onCancel={onCancel}
              handleUpdateAttributesWithCategories={
                handleUpdateAttributesWithCategories
              }
              errorMessage={errorMessage}
              errors={errors}
              setErrors={setErrors}
              setErrorMessage={setErrorMessage}
              locationDetailsData={locationDetailsData}
              location={location}
              getLocationDetails={getLocationDetails}
              allAttributes={allAttributes}
              touchedValue={touchedValue}
              setTouchedValue={setTouchedValue}
              setDisplayConfirmationBox={setDisplayConfirmationBox}
              displayConfirmationBox={displayConfirmationBox}
              setLocationDetailsData={setLocationDetailsData}
              indexValue={indexValue}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              getLocationDetailsListing={getLocationDetailsListing}
              setAttributesWithCategories={setAttributesWithCategories}
              attributesWithCategories={attributesWithCategories}
              handleChangeStringValue={handleChangeStringValue}
              handleChangeIntegerValue={handleChangeIntegerValue}
              handleChangeFloatValue={handleChangeFloatValue}
              addMultiAttribute={addMultiAttribute}
              removeMultiAttribute={removeMultiAttribute}
              handleChangeChecked={handleChangeChecked}
              setCategoriesData={setCategoriesData}
              categoriesData={categoriesData}
              attributesLoading={attributesLoading}
              setAttributesLoading={setAttributesLoading}
              setSelectedCategoryName={setSelectedCategoryName}
              selectedCategoryName={selectedCategoryName}
              handleChangeDateValue={handleChangeDateValue}
              setDisplayChildCategories={setDisplayChildCategories}
              displayChildCategories={displayChildCategories}
              handleChangeEnum={handleChangeLodgingEnum}
              setIsEditable={setIsEditable}
              isEditable={isEditable}
              setOpenConfirmBox={setOpenConfirmBox}
              openConfirmBox={openConfirmBox}
              nextCategory={nextCategory}
              setNextCategory={setNextCategory}
              setIntegerError={setIntegerError}
              integerError={integerError}
              locationCategoriesData={locationCategoriesData}
              setLocationCategoriesData={setLocationCategoriesData}
              setSelectedLanguagesArr={setSelectedLanguagesArr}
              selectedLanguagesArr={selectedLanguagesArr}
              addLanguageAttribute={addLanguageAttribute}
              removeLanguageAttribute={removeLanguageAttribute}
              switchLanguageAttribute={switchLanguageAttribute}
            />
          ) : (
            <LocationAttributes
              onCancel={onCancel}
              locationDetailsData={locationDetailsData}
              location={location}
              getLocationDetails={getLocationDetails}
              allAttributes={allAttributes}
              touchedValue={touchedValue}
              setTouchedValue={setTouchedValue}
              setDisplayConfirmationBox={setDisplayConfirmationBox}
              displayConfirmationBox={displayConfirmationBox}
              setLocationDetailsData={setLocationDetailsData}
              indexValue={indexValue}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              getLocationDetailsListing={getLocationDetailsListing}
              handleClose={handleClose}
              handleUpdateAttributes={handleUpdateAttributes}
              cancelState={cancelState}
              isLoading={isLoading}
              itemsArr={itemsArr}
              setItemsArr={setItemsArr}
              enumRequired={enumRequired}
              setEnumRequired={setEnumRequired}
              urlRequired={urlRequired}
              setUrlRequired={setUrlRequired}
              repeatedEnumRequired={repeatedEnumRequired}
              setRepeatedEnumRequired={setRepeatedEnumRequired}
              urlValidation={urlValidation}
              setUrlValidation={setUrlValidation}
              filteredItemsArr={filteredItemsArr}
              handleChangeAttributeValue={handleChangeAttributeValue}
              setSelectedItem={setSelectedItem}
              selectedItem={selectedItem}
              handleChangeSwitch={handleChangeSwitch}
              removeAttribute={removeAttribute}
              handleChangeURL={handleChangeURL}
              handleChangeEnum={handleChangeEnum}
              handleChangeRepeatedEnum={handleChangeRepeatedEnum}
              handleRepeatedEnumSwitch={handleRepeatedEnumSwitch}
              handleAddNewData={handleAddNewData}
              hasEmpty={hasEmpty}
              url={url}
              enums={enums}
            />
          )}
        </Grid>
      </Grid>
      <Divider />

      {lodgingCategories?.length > 0 &&
      lodgingCategories.includes(
        locationDetailsData?.result?.google?.categories?.primaryCategory?.name
      ) &&
      location?.isLodgingEnabled === true ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            onSubmit={handleUpdateAttributesWithCategories}
            label={t("Save")}
            disabled={isEditable || location?.isLodgingEnabled === false}
          />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={
              hasEmpty ||
              urlRequired ||
              enumRequired ||
              urlValidation ||
              disabled === true
            }
            onSubmit={handleUpdateAttributes}
            label={t("Save")}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AttributesModal;
