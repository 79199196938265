import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components/macro";
import { spacing } from "@mui/system";
import { Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Alert as MuiAlert, Button, TextField, Checkbox } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import CommonButton from "../Buttons/CommonButton";
import CancelButton from "../Buttons/CancelButton";

const CommonForm = ({
  errors,
  handleBlur,
  handleChange,
  handleSubmit,
  setFieldValue,
  touched,
  values,
  Language,
  Role,
  isLoading,
  onCancel,
  isSubmitting,
  label,
  showPassword,
  locations,
  userLocation,
  selectedLocation,
  setSelectedLocation,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const roles = [
    {
      name: "Account Manager",
      value: "account-manager",
    },
    {
      name: "Location Manager",
      value: "location-manager",
    },
  ];
  //
  const { t } = useTranslation();
  useEffect(() => {
    if (userLocation !== []) {
      let newLocation = [];
      userLocation?.map((locationData) => {
        newLocation.push(locationData?.location);
      });

      setSelectedLocation(newLocation);
    }

    // dataToEdit?.ruleTemplate?.map((templateData) => {

    //   newTemplateArray?.push(templateData?.template);
    // });
  }, []);
  function countInArray(array, what) {
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i]?.id === what) {
        count++;
      }
    }
    return count;
  }
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Box sx={{ overflowY: "scroll", maxHeight: "56vh" }}>
        <Grid container>
          <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "700" }}>
              {t("First_Name")}
            </Typography>
            <TextField
              InputProps={{
                inputProps: {
                  style: { marginLeft: "10px", marginRight: "10px" },
                },
              }}
              type="text"
              name="firstName"
              disabled={isLoading}
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {/* <Typography>{errors.firstName?.message}</Typography> */}
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", marginTop: "8px" }}
            >
              {t("Last_Name")}
            </Typography>
            <TextField
              InputProps={{
                inputProps: {
                  style: { marginLeft: "10px", marginRight: "10px" },
                },
              }}
              type="text"
              name="lastName"
              disabled={isLoading}
              value={values.lastName}
              // error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              // helperText={touched.lastName && errors.lastName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {/* <Typography>{errors.lastName?.message}</Typography> */}
          </Grid>
        </Grid>
        <Grid container>
          {/* <Grid item xs={3} sx={{ bgcolor: "" }}>
          <Typography variant="h4" p={7}>
            Email
          </Typography>
        </Grid> */}
          <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
            {" "}
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", marginTop: "8px" }}
            >
              {t("Email_Address")}
            </Typography>
            <TextField
              InputProps={{
                inputProps: {
                  style: { marginLeft: "10px", marginRight: "10px" },
                },
              }}
              type="email"
              name="email"
              disabled={isLoading}
              value={values.email}
              fullWidth
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {showPassword === "true" && (
              <>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "700", marginTop: "8px" }}
                >
                  {t("Password")}
                </Typography>
                <TextField
                  InputProps={{
                    inputProps: {
                      style: { marginLeft: "10px", marginRight: "10px" },
                    },
                  }}
                  type="password"
                  name="password"
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </>
            )}
            {/* <Typography>{errors.emailName?.message}</Typography> */}
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <Grid container>
          <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
            {/* <Typography variant="h4" p={7}>
            Language
          </Typography> */}
          </Grid>
          {/*   <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "700", marginTop: "8px" }}
            gutterBottom
            component="div"
          >
            {t("Select_language")}
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={Language}
            sx={{ width: "100%" }}
            value={values.language}
            onBlur={handleBlur}
            onChange={(e, value) => {
              setFieldValue("language", value);

            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                name="language"
                disabled={isLoading}
                error={Boolean(touched.language && errors.language)}
                //  helperText={touched.language && errors.language}
              />
            )}
          ></Autocomplete>
          {touched.language && errors.language && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: 400,
                fontSize: "0.6964285714285714rem",
                lineHeight: "1.66",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: 0,
                marginLeft: "14px",
              }}
            >
              Language is required
            </Typography>
          )}

        </Grid> */}
        </Grid>
        <Grid container>
          <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
            {/* <Typography variant="h4" p={7}>
            Role
          </Typography> */}
          </Grid>
          <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", marginTop: "8px" }}
              gutterBottom
              component="div"
            >
              {t("Select_Role")}
            </Typography>

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={roles}
              getOptionLabel={(option) => option?.name}
              sx={{ width: "100%" }}
              value={values?.role}
              onBlur={handleBlur}
              onChange={(e, value) => {
                //
                if (value !== null) {
                  setFieldValue("role", value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  name="role"
                  disabled={isLoading}
                  error={Boolean(touched.role && errors.role)}
                  //   helperText={touched.role && errors.role}
                />
              )}
            ></Autocomplete>
            {/* {touched.role && errors.role && (
            <Typography
              sx={{
                color: "#d32f2f",
                fontWeight: 400,
                fontSize: "0.6964285714285714rem",
                lineHeight: "1.66",
                textAlign: "left",
                marginTop: "3px",
                marginRight: "14px",
                marginBottom: 0,
                marginLeft: "14px",
              }}
            >
              {t("Role_req")}
            </Typography>
          )} */}
          </Grid>

          {locations?.results?.length &&
            values.role.value === "location-manager" &&
            (selectedLocation === [] || selectedLocation !== []) && (
              <Grid item md={12} sm={12} xs={12} lg={12} sx={{ bgcolor: "" }}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "700", marginTop: "8px" }}
                  gutterBottom
                  component="div"
                >
                  {t("Select_a_Location")}
                </Typography>
                <Autocomplete
                  multiple
                  value={selectedLocation}
                  // disablePortal
                  id="tags-standard"
                  options={locations?.results}
                  // disableCloseOnSelect
                  onChange={(e, value) => {
                    const uniqueIds = [];

                    value?.forEach((element, index) => {
                      const isDuplicate = countInArray(value, element?.id);

                      if (isDuplicate < 2) {
                        uniqueIds.push(element);
                      }
                    });

                    // setTemplateRequired(false);
                    // // handleChangeTemplate(value);
                    setSelectedLocation(uniqueIds);
                  }}
                  getOptionLabel={(option) =>
                    option?.internalName
                      ? option.internalName + "(" + option?.city + ")"
                      : ""
                  }
                  // defaultValue={selectedLocation}
                  sx={{ minWidth: 300 }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          // checked={selected}
                          checked={
                            selectedLocation?.findIndex(
                              (item) => item?.id === option?.id
                            ) >= 0
                          }
                        />
                        {option?.internalName
                          ? option.internalName + "(" + option?.city + ")"
                          : ""}
                        {/* {option?.internalName + ", " + option?.city} */}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        values.role === "location-manager" &&
                          selectedLocation?.length === 0
                      )}
                      //  error={Boolean(touched.locations && errors.locations)}

                      // error={templateRequired}
                    />
                  )}
                />
                {/*  {touched.locations && errors.locations && (
                <Typography
                  sx={{
                    color: "#d32f2f",
                    fontWeight: 400,
                    fontSize: "0.6964285714285714rem",
                    lineHeight: "1.66",
                    textAlign: "left",
                    marginTop: "3px",
                    marginRight: "14px",
                    marginBottom: 0,
                    marginLeft: "14px",
                  }}
                >
                  Location is Required
                </Typography>
              )} */}
                {values.role?.value === "location-manager" &&
                  selectedLocation?.length === 0 && (
                    <Typography
                      sx={{
                        color: "#d32f2f",
                        fontWeight: 400,
                        fontSize: "0.6964285714285714rem",
                        lineHeight: "1.66",
                        textAlign: "left",
                        marginTop: "3px",
                        marginRight: "14px",
                        marginBottom: 0,
                        marginLeft: "14px",
                      }}
                    >
                      {t("location_req")}
                    </Typography>
                  )}
              </Grid>
            )}
        </Grid>
      </Box>
      <Divider variant="middle" />

      <Grid
        container
        spacing={3}
        //  direction="row"
        justifyContent="flex-end"
        alignItems="flex-start"
        my={3}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
          sx={{ display: "flex" }}
        ></Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={9}
          lg={9}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <CommonButton
            displayWhite="true"
            onSubmit={onCancel}
            disabled={isSubmitting}
            label={t("Cancel")}
          />
          <CommonButton
            loading={isLoading}
            type="submit"
            disabled={
              isSubmitting ||
              (selectedLocation?.length === 0 &&
                values?.role?.value === "location-manager")
            }
            label={label}
            onSubmit={handleSubmit}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default CommonForm;
