import {
  styled,
  Typography,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  TextField,
  IconButton,
  Tooltip,
  tooltipClasses,
  Grid,
  DialogActions,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Modal,
} from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import LazyLoad from "../../../../components/Loaders/lazyLoadImage";
import React, { useEffect, useState, useRef } from "react";
import PostAIModal from "../../../../components/Models/AIModal/PostAIModal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LoadingButton } from "@mui/lab";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box } from "@mui/system";
import { InputAdornment } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import Postcard from "../styles/Postcard.css";
import { Cancel } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import { api } from "../../../../contexts/JWTContext";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import BulkUpdateModel from "./Components/BulkUpdateModel";
import { ReactComponent as AiWriter } from "../../../../assets/Icons/AIWriter.svg";
import { ReactComponent as AiLogoGrey } from "../../../../assets/images/AIlogogrey.svg";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import { useStyles } from "../styles/style";
const Item = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  color: theme.palette.text.secondary,
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "grey",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "550px",
  // width: "500px",
  bgcolor: "background.paper",
  maxHeight: "87vh",
  width: "500px",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
};

const PostCard = ({
  index,
  data,
  postData,
  handleChangeStartDate,
  handleChangeEndDate,
  handleChangeRepeatPost,
  handleChangeBusinessCta,
  handleChangeButtonUrl,
  handleChangeCheckbox,
  handleChangeDescription,
  handleChangeCompany,
  handleDeletePost,
  showEditAll,
  handleChangeLocation,
  setStartDateRequired,
  handleChangeAIDescription,
  bulkStartDate,
  startDateRequired,
  handleChangeAIDescriptionForBulk,
  checkRequired,
  changeHandlerBulkDateUpdate,
  handleChangeLocationData,
  handleChangeCompanyData,
  companyRequired,
  setCompanyRequired,
  setLocationRequired,
  locationRequired,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const classes = useStyles();
  const [descriptionText, setDescription] = useState(0);

  const [tags, setTags] = useState([]);
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [repeatPost, setRepeatPost] = useState(null);
  const [displayUrlField, setDisplayUrlField] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [tagsRequired, setTagsRequired] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [displayError, setDisplayError] = useState(false);
  const [endDateRequired, setEndDateRequired] = useState(false);
  const [contentRequired, setContentRequired] = useState(false);
  const [checked, setChecked] = useState(true);
  const [isUploadPictureRequired, setIsUploadPictureRequired] = useState(false);
  const [urlRequired, setUrlRequired] = useState(false);
  const [allLocation, setAllLocation] = useState([]);
  const [allLocationSingle, setAllLocationSingle] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [locationId, setLocationId] = useState([]);
  const [showBulkUpdateTime, setShowBulkUpdateTime] = useState(false);
  const textArea = useRef();

  const [url, setUrl] = useState(false);
  const [postType, setPostType] = useState(false);
  useEffect(() => {
    if (data?.companyIdData?.locations?.length > 0) {
      const filtered = data?.companyIdData?.locations.filter(
        (item) => item?.socialLink?.length > 0
      );
      setAllLocationSingle(filtered);
    }
  }, [data?.companyIdData]);
  useEffect(() => {
    if (data?.companyIdData === null) {
      setLocationId([]);
      setCompanyId(null);
    }
  }, [data?.companyIdData]);
  useEffect(() => {
    setLocationId([]);
  }, [data?.companyIdData]);

  // useEffect(() => {
  //   if (data?.companyId?.locations?.length > 0) {
  //     const filtered = data?.companyId?.locations.filter(
  //       (item) => item?.socialLink?.length > 0
  //     );
  //     setAllLocationSingle(filtered);
  //   }
  // }, [data?.companyId]);

  useEffect(() => {
    if (checkRequired === true) {
      /*   if (data?.content?.length < 1) {
        setDescriptionRequired(true);
      } else {
        setDescriptionRequired(false);
      } */
      if (data?.startDate === null) {
        setStartDateRequired(true);
      } else {
        setStartDateRequired(false);
      }
      /*  if (data?.endDate === null) {
        setEndDateRequired(true);
      } else {
        setEndDateRequired(false);
      } */
      if (data?.companyId === "") {
        setCompanyRequired(true);
      } else {
        setCompanyRequired(false);
      }
      if (data?.locationIds.length === 0) {
        setLocationRequired(true);
      } else {
        setLocationRequired(false);
      }
      if (data?.url === "") {
        setUrlRequired(true);
      } else {
        setUrlRequired(false);
      }
      /*   if (data?.content === "") {
        setContentRequired(true);
      } else {
        setContentRequired(false);
      } */
      if (data?.isUploadPicture === "") {
        setIsUploadPictureRequired(true);
      } else {
        setIsUploadPictureRequired(false);
      }
    }
  }, [checkRequired]);

  useEffect(() => {
    getAllCompanies();
    //  getAllLocations();
  }, []);
  useEffect(() => {
    if (companyId !== null) {
      if (companyId?.locations?.length > 0) {
        const googleConnectedLocations = companyId?.locations?.filter(
          (item) => item?.socialLink?.length > 0
        );

        setAllLocation(googleConnectedLocations);
      }
    } else {
      setCompanyId(null);
      setAllLocation([]);
      setLocationId([]);
    }
  }, [companyId]);
  useEffect(() => {
    if (data?.locationData) {
      setLocationId(data?.locationData);
    }
  }, [data?.locationData]);
  useEffect(() => {
    if (data?.companyIdData) {
      setCompanyId(data?.companyIdData);
    }
  }, [data?.companyIdData]);

  const handleUpdateDateAndTime = () => {
    setShowBulkUpdateTime(true);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
  };
  const handleCloseBlukUpdateModel = () => {
    setShowBulkUpdateTime(false);
  };
  const handleOpenTemplateMenu = () => {
    setOpenDefaultModel(true);
  };

  const handleLocationChange = (option) => {
    setLocationId(option);
  };

  /*  const getAllLocations = async () => {
    try {
      // const res = await api.get(`/company/` + user?.companyId, {
      const res = await api.get(`/location/search`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllLocation(res.data.data);

      }
    } catch (error) {}
  }; */
  const getAllCompanies = async () => {
    try {
      //   setIsLoading(true);
      const res = await api.get(`/company`);
      if (res.status === 200) {
        setAllCompanies(res.data.data);

        //   setIsLoading(false);
      }
    } catch (error) {
      //   setIsLoading(false);
    }
  };

  const handleCurrentDateAndTime = () => {
    var currentdate = new Date();

    setStartDate(currentdate);
    data["startDate"] = currentdate;
  };
  const generateContent = async (data) => {
    /*   if (tags?.length===0) {
      setTagsRequired(true);
    } else {
      setTagsRequired(false);
    } */
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);

        const res = await api.post(`/openAi/generateContent`, {
          prompt: data,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = AiContentDescription.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = AiContentDescription.concat(str2);
    }

    generateContent(finalStr);
  };
  const handleSubmit = () => {
    handleCloseTemplateMenu();
    handleChangeAIDescription(generatedContent, index);
    handleChangeAIDescriptionForBulk &&
      handleChangeAIDescriptionForBulk(generatedContent, index);
    setDescription(generatedContent?.length);
  };

  return (
    <>
      {openDefaultModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openDefaultModel}
          //  onClose={handleCloseTemplateMenu}
        >
          <PostAIModal
            title={t("AI Writer")}
            onCancel={handleCloseTemplateMenu}
            tags={tags}
            setTags={setTags}
            displayError={displayError}
            AiContentDescription={AiContentDescription}
            setAiContentDescription={setAiContentDescription}
            generatedContent={generatedContent}
            setGeneratedContent={setGeneratedContent}
            setTagsRequired={setTagsRequired}
            tagsRequired={tagsRequired}
            setContentRequired={setContentRequired}
            contentRequired={contentRequired}
          />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  /*   <CommonButton
                displayWhite="true"
                onSubmit={generateAIContent}
                label={<RefreshIcon />}
                disabled={loading}
                // label={t("Cancel")}
              /> */
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={loading}
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {generatedContent?.length === 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Write For Me")}
                disabled={loading}
                onSubmit={generateAIContent}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Me")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  /*  <CommonButton
                type="submit"
                label={t("Submit")}
                disabled={loading}
                onSubmit={handleSubmit}
              /> */
                  <LoadingButton
                    loading={loading}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <Grid container spacing={4} md={12} className="postcard_grid">
        <Grid item xs={11} sm={11} md={4} spacing={2}>
          {showEditAll ? (
            <>
              <Typography className={classes.bulkEdit}>
                {t("Bulk_Edit")}
              </Typography>
              <Typography className={classes.bulkEditSubHead}>
                All changes done in the form will be updated across all posts
                except images.
              </Typography>
              <Box sx={{ marginTop: "16px" }}>
                <CommonButton
                  onSubmit={handleUpdateDateAndTime}
                  label={t("Bulk_Date_Time")}
                />
              </Box>
              <BulkUpdateModel
                handleUpdateDateAndTime={handleUpdateDateAndTime}
                showBulkUpdateTime={showBulkUpdateTime}
                handleCloseBlukUpdateModel={handleCloseBlukUpdateModel}
                changeHandlerBulkDateUpdate={changeHandlerBulkDateUpdate}
              />
            </>
          ) : (
            <>
              <Avatar
                variant="square"
                src={
                  data?.postPictures[0]?.url ? data?.postPictures[0]?.url : ""
                }
                alt={"userimg"}
                sx={{
                  width: "100%",
                  height: "196px",
                  cursor: "pointer",
                  borderRadius: "8px",
                  marginTop: "8px",
                }}
              />
            </>
          )}
        </Grid>

        <Grid item xs={11} md={4}>
          <Grid item md={12}>
            <Typography className={classes.locationTypo}>
              {t("Select_Company")}
            </Typography>

            <Autocomplete
              // multiple
              disablePortal
              id="combo-box-demo"
              options={
                allCompanies?.results?.length > 0 ? allCompanies?.results : []
              }
              getOptionLabel={(option) =>
                option?.name ? option?.name + "(" + option?.city + ")" : ""
              }
              className="Autocomplete-field"
              value={companyId !== null ? companyId : {}}
              onChange={(e, value) => {
                handleChangeCompany(value, index);
                handleChangeCompanyData && handleChangeCompanyData(value);
                setLocationId([]);
                if (value) {
                  setCompanyRequired(false);
                } else {
                  setCompanyRequired(true);
                }
                if (value !== null) {
                  setCompanyId(value);
                  if (value?.locations?.length > 0) {
                    const googleConnectedLocations = value?.locations?.filter(
                      (item) => item?.socialLink?.length > 0
                    );

                    setAllLocation(googleConnectedLocations);
                  }
                } else {
                  setCompanyId(null);
                  setAllLocation([]);
                  setLocationId([]);
                }
              }}
              /*  renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {`${option?.internalName} (${option.city})`}
              </li>
            )} */
              renderInput={(params) => (
                <TextField
                  placeholder={t("Select_Company")}
                  {...params}
                  required
                  error={data !== undefined && companyRequired === true}
                  name="company"
                />
              )}
            ></Autocomplete>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.locationTypo}>
              {t("Select_Location")}
            </Typography>
            <Autocomplete
              multiple
              disablePortal
              id="combo-box-demo"
              options={
                data === undefined
                  ? allLocation?.length > 0
                    ? allLocation
                    : []
                  : allLocationSingle?.length > 0
                  ? allLocationSingle
                  : []
              }
              getOptionLabel={(option) =>
                option?.internalName
                  ? option?.internalName +
                    " " +
                    "(" +
                    (option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? option?.addressLine1 + "," + " "
                      : "") +
                    (option?.city ? option?.city : "") +
                    ")"
                  : ""
              }
              className="Autocomplete-field"
              value={locationId ? locationId : []}
              onChange={(e, value) => {
                handleChangeLocation(
                  value.map((item) => item.id),
                  index
                );
                handleChangeLocationData &&
                  handleChangeLocationData(value.map((item) => item));

                handleLocationChange(value);
                if (value?.length > 0) {
                  setLocationRequired(false);
                } else {
                  setLocationRequired(true);
                }
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option?.internalName} (${
                    option?.addressLine1 !== "" &&
                    option?.addressLine1 !== null &&
                    option?.addressLine1 !== undefined
                      ? option?.addressLine1 + "," + " "
                      : ""
                  } ${option.city})`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  name="language"
                  {...params}
                  error={data !== undefined && locationRequired === true}
                  label={t("Select_Location")}
                  //  defaultValue={{ selectedLocation }}
                />
              )}
            ></Autocomplete>
          </Grid>

          <Grid
            container
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Grid item md={0.1}></Grid>
          </Grid>
          {data !== undefined && (
            <>
              <Grid
                item
                xs={5}
                sm={6}
                md={12}
                // md={2.75}
                sx={
                  {
                    /* , flexDirection: "column" */
                  }
                }
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  // md={2.75}
                  sx={
                    {
                      /* , flexDirection: "column" */
                    }
                  }
                >
                  <Typography className={classes.dateTimeTypo}>
                    {t("Select_Date_Time")}
                  </Typography>
                </Grid>

                <Grid container>
                  <Grid
                    item
                    xs={10.5}
                    sm={10.5}
                    md={10.5}
                    // md={2.75}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        sx={{
                          // width: "320px",
                          border: "1px solid #E0E0E0",
                          width: "320px",
                          background: "blue",
                        }}
                        // label="Date&Time picker"
                        disablePast
                        value={
                          data !== undefined && data?.startDate !== null
                            ? data?.startDate
                            : bulkStartDate !== null &&
                              bulkStartDate !== undefined
                            ? bulkStartDate
                            : startDate
                        }
                        inputFormat="dd/MM/yyyy, hh:mm aa"
                        onChange={(e) => {
                          setStartDateRequired(false);
                          handleChangeStartDate(e, index);
                          setStartDate(e);
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="off"
                            fullWidth
                            {...params}
                            error={startDateRequired}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={1.5}
                    sm={1.5}
                    md={1.5}
                    // md={2.75}
                  >
                    {postData.length === 1 && (
                      <CustomWidthTooltip
                        // color="red"
                        title={t("Current_date_time")}
                      >
                        <IconButton
                          sx={{
                            marginLeft: "5px",
                            /*   width: "100%",
                        height: "100%", */
                          }}
                          aria-label="delete"
                          size="large"
                          // color="red"
                          onClick={() => handleCurrentDateAndTime()}
                        >
                          <EventAvailableIcon fontSize="large" />
                        </IconButton>
                      </CustomWidthTooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid
            container
            spacing={1}
            sx={{ marginTop: "8px", marginBottom: "14px" }}
          >
            <Grid
              item
              sm={6}
              xs={6}
              // md={2.75}
              md={6}
              lg={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography
                sx={{
                  color: "#1B2430",
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#1B2430",
                  lineHeight: "20px",
                  marginBottom: "8px",
                }}
              >
                {t("Repeat_This_Post")}
              </Typography>

              <FormControl>
                {/* <InputLabel id="demo-simple-select-helper-label">
                Repeat
              </InputLabel> */}

                <Select
                  // defaultValue={data?.repeatPost === "No"}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={data?.repeat}
                  defaultValue={data?.repeat}
                  // label="Repeat"
                  onChange={(e) => {
                    setRepeatPost(e.target.value);
                    handleChangeRepeatPost(e, index);
                  }}
                >
                  <MenuItem value="no">{t("No")}</MenuItem>
                  <MenuItem value="daily">{t("Daily")}</MenuItem>
                  <MenuItem value="weekly">{t("Weekly")}</MenuItem>
                  <MenuItem value="monthly">{t("Monthly")}</MenuItem>
                  <MenuItem value="yearly">{t("Yearly")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {data?.repeat === "no" ||
            (data === undefined &&
              (repeatPost === null || repeatPost === "no")) ? (
              <> </>
            ) : (
              <>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  // md={2.75}
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography
                    sx={{
                      color: "#1B2430",
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "#1B2430",
                      lineHeight: "20px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("End_Date")}
                  </Typography>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      disablePast
                      sx={{
                        // width: "320px",
                        border: "1px solid #E0E0E0",
                        width: "320px",
                        background: "blue",
                      }}
                      // label="Date&Time picker"
                      value={data?.endDate ? data?.endDate : endDate}
                      inputFormat="dd/MM/yyyy, hh:mm aa"
                      onChange={(e) => {
                        setEndDate(e);
                        handleChangeEndDate(e, index);
                        setEndDateRequired(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          style={{ width: "" }}
                          {...params}
                          autoComplete="off"
                          // error={endDateRequired}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}

            <Grid item md={0.5}></Grid>
          </Grid>
          <Box
            sx={{
              bgcolor: "#F6F6F7",
              width: "100%",
              borderRadius: "8px",
              marginY: "8px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                md={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mb: 2,
                  marginX: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "700",
                    color: "#1B2430",
                    lineHeight: "24px",
                    marginBottom: "8px",
                    marginTop: "8px",

                    width: "100%",
                  }}
                >
                  {t("Google_My_CTA")}
                </Typography>
                <Grid container spacing={1}>
                  <Grid
                    md={6}
                    sm={6}
                    lg={6}
                    xs={12}
                    sx={{ marginTop: ".2rem" }}
                  >
                    <FormControl sx={{ width: "100%" }}>
                      {/* <InputLabel id="demo-simple-select-helper-label">Book</InputLabel> */}
                      <Select
                        // labelId="demo-simple-select-helper-label"
                        // id="demo-simple-select-helper"
                        // label="Age"
                        onChange={(e) => {
                          handleChangeBusinessCta(e, index);
                          setDisplayUrlField(e.target.value);
                        }}
                        value={data?.postType}
                      >
                        <MenuItem value="ORDER">{t("OrderOnline")}</MenuItem>
                        <MenuItem value="BOOK">{t("Book")}</MenuItem>
                        <MenuItem value="SHOP">{t("Shop")}</MenuItem>
                        <MenuItem value="LEARN_MORE">
                          {t("Learn_More")}
                        </MenuItem>
                        <MenuItem value="SIGN_UP">{t("Sign_Up")}</MenuItem>
                        <MenuItem value="CALL">{t("CallNow")}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {data?.postType === "CALL" ||
                  (data === undefined &&
                    (displayUrlField === "" || displayUrlField === "CALL")) ? (
                    <> </>
                  ) : (
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <TextField
                        fullWidth
                        // label="URL"
                        error={urlRequired}
                        placeholder={t("Please_enter_Url")}
                        value={data?.url}
                        onChange={(e) => handleChangeButtonUrl(e, index)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {showEditAll ? (
              <FormGroup sx={{ ml: 2 }}>
                {/* <FormControlLabel control={<Checkbox defaultChecked />}label="Also add image to the Photos section of Google My Business?" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        handleChangeCheckbox(e, index);
                        setChecked(e.target.checked);
                      }}
                      checked={checked}
                    />
                  }
                  label={t("img_desc")}
                />
              </FormGroup>
            ) : (
              <FormGroup sx={{ ml: 2 }}>
                {/* <FormControlLabel control={<Checkbox defaultChecked />}label="Also add image to the Photos section of Google My Business?" /> */}
                <FormControlLabel
                  control={
                    <Checkbox
                      onClick={(e) => {
                        setChecked(e?.target?.checked);
                        handleChangeCheckbox(e, index);
                      }}
                      checked={data?.isUploadPicture === true ? true : false}
                      //defaultChecked
                    />
                  }
                  label={t("img_desc")}
                />
              </FormGroup>
            )}
          </Box>
        </Grid>

        <Grid item xs={11} md={3}>
          <Box sx={{ display: "flex" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                color: "#1B2430",
                lineHeight: "20px",
                marginBottom: "8px",
              }}
            >
              {t("Post_Summary")} {""}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#1B2430",
                lineHeight: "20px",
                marginBottom: "8px",
                ml: 2,
              }}
            >
              {" "}
              {data !== undefined ? data?.content?.length : descriptionText}
              /1500{" "}
            </Typography>
          </Box>
          {/*  {contentRequired && (
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#FF0000",
                lineHeight: "15px",
                marginBottom: "8px",
              }}
            >
              {t("req_field")}
            </Typography>
          )} */}
          {data?.content?.length >= 1499 && (
            <Typography className={classes.textLimitTypo}>
              {t("text_limit")}
            </Typography>
          )}
          <TextField
            id="standard-basic"
            // label="Standard"
            rows={18}
            maxRows={50}
            value={
              data !== undefined
                ? data?.content
                : generatedContent?.length > 0
                ? generatedContent
                : ""
            }
            onChange={(e) => {
              if (e.target?.value?.length <= 1500) {
                //  setDescriptionRequired(false);
                handleChangeDescription(e, index);
                setDescription(e.target?.value.length);
                setGeneratedContent(e.target?.value);
              }
              // setContentRequired(false);
            }}
            style={{
              //  border: "1px solid #E0E0E0",
              fontFamily: "Segoe UI",
              fontStyle: "Normal",
              //  padding: "12px 12px 12px 12px",
              width: "100%",
              //  gap: "8px",
              borderRadius: "8px",
              //  minHeight: "364px",
              color: "#1B2430",
              fontWeight: "400",
              fontSize: "16px",
              //   padding: "15px",
            }}
            label={null}
            multiline
            fullWidth
            ref={textArea}
            // error={contentRequired}
            placeholder={t("Enter_Description")}
            /*   InputProps={{
                    disableUnderline: true,
                  }} */
            inputProps={{ minLength: 0, maxLength: 1500 }}
            /*   InputProps={{
                  inputComponent: TextareaAutosize,
                }} */
            InputProps={{
              endAdornment: (
                <Box sx={{ marginRight: "22px" }}>
                  <InputAdornment position="bottom">
                    <Box
                      sx={{
                        bottom: "10px",
                        width: "100%",
                        position: "absolute",
                      }}
                    >
                      <CustomWidthTooltip
                        // color="red"
                        title={t("Post_Ai_Tooltip")}
                      >
                        <IconButton onClick={handleOpenTemplateMenu}>
                          <AiLogoGrey />
                        </IconButton>
                      </CustomWidthTooltip>
                    </Box>
                  </InputAdornment>
                </Box>
              ),
            }}
          />

          {/*   <TextareaAutosize
            id="filled-multiline-static"
            multiline
            style={{
              border: "1px solid #E0E0E0",
              fontFamily: "Segoe UI",
              fontStyle: "Normal",
              padding: "12px 12px 12px 12px",
              width: "100%",
              gap: "8px",
              borderRadius: "8px",
              minHeight: "364px",
              color: "#1B2430",
              fontWeight: "400",
              fontSize: "16px",
              //   padding: "15px",
            }}
            error={contentRequired}
            placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed libero odio, dignissim ut neque et, finibus sollicitudin dolor. Nunc mollis eu magna vitae faucibus. Proin ac pellentesque tortor. Etiam semper, lacus ornare posuere egestas, turpis est suscipit sapien, nec dictum velit tortor at enim."
            value={data?.content}
            onChange={(e) => {
              if (e.target?.value?.length < 1500) {
                setDescriptionRequired(false);
                handleChangeDescription(e, index);
              }
              setContentRequired(false);
            }}
          /> */}
        </Grid>
        <Grid
          item
          md={0.5}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!showEditAll && (
            <Item>
              <CustomWidthTooltipOfDelete
                // color="red"
                title={t("Delete")}
              >
                <IconButton
                  className={classes.IconButtonDeletePost}
                  aria-label="delete"
                  size="large"
                  // color="red"
                  onClick={(e) => {
                    handleDeletePost(e, index);
                    //   setDisplayData(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </CustomWidthTooltipOfDelete>

              {/* <DeleteOutlineOutlinedIcon
                onClick={(e) => handleDeletePost(e, index)}
                sx={{ color: "red", cursor: "pointer" }}
              /> */}
            </Item>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default PostCard;
