import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Button,
  IconButton,
} from "@mui/material";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import { ReactComponent as CopyIcon } from "../../../../../../assets/Icons/Copy.svg";
import Loader from "../../../../../../components/Loaders/Loader";
import { useTranslation } from "react-i18next";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  addedEmails,
  handleCloseNewRequestModel,
  totalModalEmailsArr,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  setSelectAllChecked,
  handleSubmitOk,
  handleCancelEmailsModal,
  isModalEmailsSent,
  setIsModalEmailsSent,
  isLoading,
}) => {
  const { t } = useTranslation();
  // const [isLoading, setIsLoading] = useState(false);
  const [displayText, setDisplayText] = useState(false);

  return (
    <>
      <div>
        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%", height: "400px" } }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  lineHeight: "28px",
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#1b2430",
                }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
              }}
              style={{ color: "#495059" }}
            >
              {subTitle}
            </Typography>
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid
                container
                sx={{
                  overflowY: "auto",
                  //   marginLeft: "-11px",
                }}
              >
                {addedEmails?.map((item, index) => (
                  <Grid item sm={12} md={12} lg={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ color: "rgb(73, 80, 89)" }}>
                          {"•"}
                        </Typography>
                        <Typography sx={{ marginLeft: "8px" }}>
                          {item?.email}
                        </Typography>
                      </Box>
                      <Box>
                        <BootstrapTooltip
                          title={
                            <h1 style={{ fontSize: "12px" }}>
                              {t("copy_invitation_link")}
                            </h1>
                          }
                        >
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${process.env.REACT_APP_LOCAL_URL}/auth/register/${item?.inviteCode}`
                              );

                              setDisplayText(true);
                              setTimeout(() => {
                                setDisplayText(false);
                              }, 2000);
                            }}
                            sx={{ marginLeft: "5px" }}
                          >
                            <CopyIcon />
                          </IconButton>
                        </BootstrapTooltip>
                      </Box>
                    </Box>
                  </Grid>
                ))}
                {displayText && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#495059",
                      lineHeight: "15px",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                      fontWeight: 500,
                      textAlign: "center",
                    }}
                  >
                    {t("Copied")}
                  </Typography>
                )}
              </Grid>
            )}
          </DialogContent>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Box sx={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitOk}
                sx={{ marginLeft: "10px" }}
              >
                {t("Ok")}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </div>
    </>
  );
};

export default FetchModel;
