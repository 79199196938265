import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { Alert } from "@mui/material";
import Loader from "../../../../../../../../components/Loaders/Loader";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AttributesItem from "./attributeItem";
import { api } from "../../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../../Styles/style";
import AddIcon from "@mui/icons-material/Add";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../../components/Models/ConfirmationModal";

const AttributesModal = ({
  isLoading,
  setTouchedValue,
  location,
  attributes,
  handleAddAttribute,
  handleRemoveAttribute,
  handleChangeChecked,
  allAttributes,
  handleAddNewData,
  handleChangeAttributeValue,
  hasEmpty,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Grid container className={classes.customFieldMain}>
        {isLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.customFieldLoad}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.mainGridIndex}
            >
              {attributes.length > 0 &&
                attributes?.map((item, index) => {
                  return (
                    <>
                      <AttributesItem
                        item={item}
                        ind={index}
                        allAttributes={allAttributes}
                        handleAddAttribute={handleAddAttribute}
                        handleRemoveAttribute={handleRemoveAttribute}
                        handleChangeChecked={handleChangeChecked}
                        handleChangeAttributeValue={handleChangeAttributeValue}
                        hasEmpty={hasEmpty}
                      />
                    </>
                  );
                })}

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mb={2}
                className={classes.gridBoxMain}
              >
                <Button
                  autoFocus
                  disabled={hasEmpty}
                  onClick={handleAddNewData}
                  className={
                    hasEmpty || allAttributes?.length === 0
                      ? classes.addButtonDisabled
                      : classes.addButton
                  }
                >
                  <AddIcon
                    fontSize="medium"
                    className={
                      hasEmpty || allAttributes?.length === 0
                        ? classes.addIconDisabled
                        : classes.addIcon
                    }
                  />{" "}
                  <Typography
                    className={
                      hasEmpty || allAttributes?.length === 0
                        ? classes.addButtonDisabled
                        : classes.addButton
                    }
                  >
                    {t("Add_new_attribute")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AttributesModal;
