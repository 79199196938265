import React, { useEffect, useState, useRef } from "react";
import { api } from "../../../../../contexts/JWTContext";
import {
  Grid,
  Typography,
  Divider as MuiDivider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Dialog,
  Checkbox,
  Box,
  styled,
  Tooltip,
  tooltipClasses,
  Button,
  Autocomplete,
  TextField,
  createFilterOptions,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { languagesData } from "../../../../../assets/defaultLanguage";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import Collapsible from "../../../../../components/CustomComponents/Collapsible.js";
import Loader from "../../../../../components/Loaders/Loader";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useLocation } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
// import { useStyles } from "../../../Style/style";
import { useStyles } from "../../Style/style";
import FilterModelReviews from "../Models/ScheduleFiltersModel";
import LogoImageModal from "./UploadModels/CoverImageModal";
import DatePickerComponent from "../../../../../components/CustomComponents/DateRangePicker/RangePicker.js";
import FilterModal from "../../../../../components/Models/FilterModalReviews/index.js";
import moment from "moment";
import useDateFormat from "../../../../../hooks/useDateFormat.js";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const FetchModel = ({
  title,
  subTitle,
  open,
  handleCloseNewRequestModel,
  handleSubmitOk,
  handleCancelEmailsModal,
  companyUser,
  setCompanyUser,
  others,
  setOthers,
  setRecipientError,
  recipientError,
  setRecipientEmailError,
  recipientEmailError,
  recipientEmail,
  setRecipientEmail,
  recipientValue,
  setRecipientValue,
  emailReportRef,
  emotionAIRefs,
  setSelectedCompanyUsers,
  selectedCompanyUsers,
  totalEmailsArr,
  isModalEmailsSent,
  handleAddNewUserEmailsChange,
  addNewUserEmials,
  handleResetOthers,
  handleButtonClick,
  handleFileUpload,
  selectedFile,
  fileInputRef,
  handleReopenModal,
  handleRemoveFile,
  exportReportLoading,
  setEmailSubject,
  emailSubject,
  setEmailBody,
  emailBody,
  handleDownloadXls,
  handleEmailXls,
  loadingXlsReport,
  handleEmailReviewsXlsReport,
  reviewReportLoading,
  setDateRange,
  handleChangeLanguageForScheduler,
  languageSchedular,
  setLanguageSchedular,
  scheduleLocations,
  setScheduleLocations,
  scheduleGroups,
  setScheduleGroups,
  scheduleReviewSource,
  setScheduleReviewSource,
  scheduleTags,
  setScheduleTags,
  scheduleRatingValue,
  setScheduleRatingValue,
  scheduleReviewerName,
  setScheduleReviewerName,
  scheduleReply,
  setScheduleReply,
  allLocations,
  allGroups,
  allReviewSource,
  starRatings,
  allTags,
  handleResetScheduleFiltersModel,
  haventReplied,
  handleChangeDateRange,
  handleChangeRangeEndDateSchedule,
  handleChangeRangeStartDateSchedule,
  setStartDate,
  setEndDate,
  setEmailTitle,
  emailTitle,
  pdfChecked,
  setPdfChecked,
  xlsChecked,
  setXlsChecked,
  dateRange,
  startDate,
  endDate,
  setUploadedImages,
  setImages,
  images,
  uploadedImages,
  modalFilters,
  modalFiltersDate,
  setModalFilters,
  setModalFiltersDate,
  checked,
  dateFormat,
  setDateFormat,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const textArea = useRef();
  const location = useLocation();
  const i18nextLng = localStorage.getItem("i18nextLng");
  const currentPath = location.pathname.replace(/\/+$/, "");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const filter = createFilterOptions();
  const [selectAll, setSelectAll] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState(["configureReport"]);
  const [dateRangeError, setDateRangeError] = useState("");
  const [startDateError, setStartDateError] = useState(false);
  const [openReviewsFilterModel, setOpenReviewsFilterModel] = useState(false);
  const [titleError, setTitleError] = useState("");
  const [openLogoUploadModal, setOpenLogoUploadModel] = useState(false);
  const [selectedFileForImage, setSelectedFileForImage] = useState(null);
  const { formatDate } = useDateFormat();

  const timePeriod = [
    { id: 0, name: "All", value: "All" },
    { id: 1, name: "today", value: "Today" },
    { id: 2, name: "yesterday", value: "Yesterday" },
    { id: 3, name: "2days", value: "2days" },
    { id: 4, name: "7days", value: "7days" },
    { id: 5, name: "week", value: "week" },
    { id: 6, name: "14days", value: "14days" },
    { id: 7, name: "30days", value: "30days" },
    { id: 8, name: "monthValue", value: "month" },
    { id: 9, name: "60days", value: "60days" },
    { id: 10, name: "90days", value: "90days" },
    { id: 11, name: "6months", value: "6months" },
    { id: 12, name: "12months", value: "12months" },
    { id: 12, name: "custom_text", value: "custom" },
  ];

  const dateFormats = [
    { label: "D/M/YYYY" },
    { label: "YYYY-MM-DD" },
    { label: "DD/MM/YYYY" },
    { label: "MM/DD/YYYY" },
    { label: "DD.MM.YYYY" },
  ];

  useEffect(() => {
    getAllUsers();
    const userLang = languagesData?.find((item) => item?.value === i18nextLng);
    setLanguageSchedular(userLang);
  }, [open]);

  // useEffect(() => {
  //   if (modalFiltersDate.startDate === "" && modalFiltersDate.endDate === "") {
  //     const today = new Date();
  //     const previousYear = new Date(
  //       today.getFullYear() - 1,
  //       today.getMonth(),
  //       today.getDate()
  //     );
  //     let start = moment(previousYear)
  //       .startOf("day")
  //       .utc()
  //       .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //     let end = moment()
  //       .endOf("day")
  //       .utc()
  //       .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  //     setModalFiltersDate({ startDate: start, endDate: end });
  //   }
  // }, []);

  const handleChangeCompanyUser = (e) => {
    setCompanyUser(e.target.checked);
    // setOthers(false);
    if (e.target.checked === false) {
      setSelectedCompanyUsers([]);
    }
  };
  const handleChangeOthers = (e) => {
    setOthers(e.target.checked);
    if (e.target.checked === false) {
      handleResetOthers();
    }
  };

  const getAllUsers = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyUserRoles?companyId=${companyData?.id}&pageNumber=1&limit=500`
      );
      if (res.status === 200) {
        const activeUsers = res?.data?.data?.rows?.filter(
          (user) =>
            user?.User?.inActive === false &&
            (user?.User?.status === "active" ||
              user?.User?.status === "pending")
        );

        setUsers(activeUsers);
        setIsLoading(false);
      }
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };
  const handleOpenReviewsFilterModel = () => {
    setOpenReviewsFilterModel(true);
  };
  const handleCloseReviewsFilterModel = () => {
    setOpenReviewsFilterModel(false);
  };

  const handleDateRangChange = (e) => {
    const value = e.target.value;
    setModalFilters((prevFilters) => ({
      ...prevFilters,
      timePeriod: value,
      time: timePeriod.find((item) => item.value === value),
    }));
    setDateRange(value);
    setDateRangeError(value === "");
    const today = new Date();
    let date = dayjs(today);
    let oneDaysBefore = date.subtract(1, "day").endOf("day");
    let oneDaysBeforeDate = oneDaysBefore.toDate();
    if (today !== null && today !== "") {
      if (value === "All") {
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}`
        );
      } else if (value === "Yesterday") {
        let date = dayjs(today);
        let oneDaysBefore = date.subtract(1, "day").startOf("day");
        let oneDaysBeforeDateStart = oneDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(oneDaysBeforeDateStart);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneDaysBeforeDate !== null && endDate !== null
              ? ` from  ${formatDate(oneDaysBeforeDate)}.`
              : ""
          }`
        );
      } else if (value === "Today") {
        let date = dayjs(today);
        let oneDaysBefore = date.startOf("day");
        let oneDaysBeforeDate = oneDaysBefore.toDate();
        setEndDate(today);
        setStartDate(oneDaysBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneDaysBeforeDate !== null && endDate !== null
              ? ` from  ${formatDate(oneDaysBeforeDate)}.`
              : ""
          }`
        );
      } else if (value === "2days") {
        let date = dayjs(today);
        let twoDaysBefore = date.subtract(2, "day").startOf("day");
        let twoDaysBeforeDate = twoDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(twoDaysBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            twoDaysBeforeDate !== null && today !== null
              ? ` from  ${formatDate(twoDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDate
                )}`
              : ""
          }`
        );
      } else if (value === "7days") {
        let date = dayjs(today);
        let sevenDaysBefore = date.subtract(7, "day").startOf("day");
        let sevenDaysBeforeDate = sevenDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(sevenDaysBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            sevenDaysBeforeDate !== null && today !== null
              ? ` from  ${formatDate(sevenDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDate
                )}`
              : ""
          }`
        );
      } else if (value === "week") {
        let date = dayjs(today);

        let weekBeforeSelectedDate = date.subtract(7, "day");
        let previousMonday = weekBeforeSelectedDate
          .subtract(weekBeforeSelectedDate.day() - 1, "day")
          .startOf("day");
        let followingSunday = previousMonday.add(6, "day").endOf("day");
        setEndDate(followingSunday.toDate());
        setStartDate(previousMonday.toDate());
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            previousMonday !== null && today !== null
              ? ` from  ${formatDate(previousMonday)} to ${formatDate(
                  followingSunday
                )}`
              : ""
          }`
        );
      } else if (value === "month") {
        let date = dayjs(today);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate
          .endOf("month")
          .endOf("day");
        setEndDate(endOfMonthBefore.toDate());
        setStartDate(startOfMonthBefore.toDate());
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            startOfMonthBefore !== null && endOfMonthBefore !== null
              ? ` from  ${formatDate(startOfMonthBefore)} to ${formatDate(
                  endOfMonthBefore
                )}`
              : ""
          }`
        );
      } else if (value === "14days") {
        let date = dayjs(today);
        let fourteenDaysBefore = date.subtract(14, "day").startOf("day");
        let fourteenDaysBeforeDate = fourteenDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(fourteenDaysBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            fourteenDaysBeforeDate !== null && today !== null
              ? ` from  ${formatDate(fourteenDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDate
                )}`
              : ""
          }`
        );
      } else if (value === "30days") {
        let date = dayjs(today);
        let oneMonthBefore = date.subtract(30, "day").startOf("day");
        let oneMonthBeforeDate = oneMonthBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(oneMonthBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneMonthBeforeDate !== null && today !== null
              ? ` from  ${formatDate(oneMonthBeforeDate)} to ${formatDate(
                  oneDaysBeforeDate
                )}`
              : ""
          }`
        );
      } else if (value === "60days") {
        let date = dayjs(today);
        let sixtyDaysBefore = date.subtract(60, "day").startOf("day");
        let sixtyDaysBeforeDate = sixtyDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(sixtyDaysBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            sixtyDaysBeforeDate !== null && today !== null
              ? ` from  ${formatDate(sixtyDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDate
                )}`
              : ""
          }`
        );
      } else if (value === "90days") {
        let date = dayjs(today);
        let ninetyDaysBefore = date.subtract(90, "day").startOf("day");
        let ninetyDaysBeforeDate = ninetyDaysBefore.toDate();
        setEndDate(oneDaysBeforeDate);
        setStartDate(ninetyDaysBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            ninetyDaysBeforeDate !== null && today !== null
              ? ` from  ${formatDate(ninetyDaysBeforeDate)} to ${formatDate(
                  oneDaysBeforeDate
                )}`
              : ""
          }`
        );
      } else if (value === "6months") {
        let date = dayjs(today);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore.toDate());
        let sixMonthsBefore = date.subtract(6, "month").startOf("month");
        let sixMonthsBeforeDate = sixMonthsBefore.toDate();
        setStartDate(sixMonthsBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            sixMonthsBeforeDate !== null && sixMonthsBeforeDate !== null
              ? ` from  ${formatDate(sixMonthsBeforeDate)} to ${formatDate(
                  endOfMonthBefore
                )}`
              : ""
          }`
        );
      } else if (value === "12months") {
        let date = dayjs(today);
        let monthBeforeSelectedDate = date.subtract(1, "month");
        let startOfMonthBefore = monthBeforeSelectedDate.startOf("month");
        let endOfMonthBefore = monthBeforeSelectedDate.endOf("month");
        setEndDate(endOfMonthBefore.toDate());
        let oneYearBefore = date.subtract(12, "month").startOf("month");
        let oneYearBeforeDate = oneYearBefore.toDate();
        setStartDate(oneYearBeforeDate);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            oneYearBeforeDate !== null && today !== null
              ? ` from  ${formatDate(oneYearBeforeDate)} to ${formatDate(
                  endOfMonthBefore
                )}`
              : ""
          }`
        );
      } else if (value === "custom") {
        const today = new Date();
        const previousYear = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate()
        );
        setEndDate(today);
        setStartDate(previousYear);
        setEmailBody(
          `Please find attached reviews report of ${companyData?.name}${
            previousYear !== null && today !== null
              ? ` from  ${formatDate(previousYear)} to ${formatDate(today)}`
              : ""
          }`
        );
      }
    }
    if (modalFiltersDate.startDate === "" && modalFiltersDate.endDate === "") {
      const today = new Date();
      const previousYear = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate()
      );
      setModalFiltersDate({
        startDate: previousYear,
        endDate: today,
      });
    }
  };

  useEffect(() => {
    let value = {
      target: {
        value: dateRange,
      },
    };
    handleDateRangChange(value);
  }, []);

  const handleSubmit = (data) => {
    let value = {
      target: {
        value: data.timePeriod,
      },
    };
    handleDateRangChange(value);
    setModalFilters(data);
  };

  const handleChangePdfChecked = (e) => {
    setPdfChecked(e.target.checked);
    // setXlsChecked(false);
  };
  const handleChangeXlsChecked = (e) => {
    setXlsChecked(e.target.checked);
    // setPdfChecked(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmailTitle(value);
    setTitleError(value.trim() === "");
  };

  const handleOpenLogoUploadModal = () => {
    setOpenLogoUploadModel(true);
  };
  const handleCloseLogoUploadModal = () => {
    setOpenLogoUploadModel(false);
  };

  const handleRemoveImageFile = () => {
    setSelectedFileForImage(null);
    setUploadedImages([]);
    setImages([]);
  };

  return (
    <>
      <div>
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              maxWidth: "100%",
              width: "50%",
              height: "550px",
            },
          }}
          open={open}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogTitle className={classes.emailReportTitle}>
            {title}

            {/*   <Typography
              sx={{
                fontWeight: 400,
                fontSize: "14px",
              }}
              style={{ color: "#495059" }}
            >
              {t("reviews_report_subTitle")}
            </Typography> */}
          </DialogTitle>

          <DialogContent dividers>
            {isLoading ? (
              <Loader />
            ) : (
              <Grid container>
                <Box sx={{ marginTop: "12px", width: "100%" }}>
                  <Collapsible
                    title={t("configure_report")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureReport")}
                    id={"configureReport"}
                    ComponentToRender={
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "0px" }}
                          >
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("report_format")}
                              </Typography>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container sx={{ marginLeft: "-10px" }}>
                                    <Grid item xs={3}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          onChange={handleChangePdfChecked}
                                          checked={pdfChecked}
                                        />
                                        <Typography>{"PDF"}</Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Checkbox
                                          onChange={handleChangeXlsChecked}
                                          checked={xlsChecked}
                                        />
                                        <Typography>{"XLS"}</Typography>
                                      </Box>
                                    </Grid>
                                    <Grid item xs={6}></Grid>
                                  </Grid>

                                  {pdfChecked && (
                                    <Grid item xs={12} lg={8}>
                                      <Box
                                        className={classes.reviewsubHeadBox}
                                        sx={
                                          {
                                            //  marginLeft: "12px",
                                            //  marginBottom: "8px",
                                          }
                                        }
                                      >
                                        <Grid container>
                                          <Grid
                                            item
                                            xs={1}
                                            sm={1}
                                            md={0.8}
                                            lg={0.8}
                                          >
                                            <InfoOutlinedIcon
                                              className={
                                                classes.scheduleReviewsIcon
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={11}
                                            sm={11}
                                            md={11.2}
                                            lg={11.2}
                                            className={
                                              classes.scheduleReviewInfoGrid
                                            }
                                          >
                                            <Typography
                                              className={
                                                classes.scheduleReviewInfoTypoEmail
                                              }
                                            >
                                              {t("add_reviews_info")}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </Box>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Typography
                                  className={classes.emailReportLabel}
                                >
                                  {t("upload_logo")}
                                </Typography>

                                <Box mt={3} ml={-3}>
                                  <CommonButton
                                    onSubmit={handleOpenLogoUploadModal}
                                    label={t("Select_logo")}
                                  />
                                </Box>
                                <Box>
                                  {selectedFileForImage ? (
                                    <Box className={classes.fileBoxStyle}>
                                      <Typography
                                        // onClick={handleReopenModal}
                                        className={classes.selectedFileTypo}
                                      >
                                        {selectedFileForImage?.name}
                                      </Typography>
                                      <IconButton
                                        className={classes.removeFileButton}
                                        onClick={() => handleRemoveImageFile()}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          className={classes.deleteIcon}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <p>{t("")}</p>
                                  )}
                                </Box>
                              </Grid>
                            </>
                          </Grid>
                          {/* <Grid
                            item
                            xs={12}
                            sm={4.5}
                            md={4.5}
                            lg={4.5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_name")}
                            </Typography>
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              placeholder={t("enter_title")}
                              value={emailTitle}
                              fullWidth
                              onChange={handleInputChange}
                              error={titleError}
                              helperText={titleError ? t("Field_req") : ""}
                            />
                          </Grid> */}
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_format")}
                            </Typography>
                            <Autocomplete
                              disablePortal
                              id="combo-box-demo"
                              options={dateFormats}
                              value={dateFormat}
                              onChange={(e, value) => {
                                setDateFormat(value);
                              }}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t("date_format")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("date_range")}
                            </Typography>

                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={dateRange}
                              onChange={(e) => {
                                handleDateRangChange(e);
                              }}
                              fullWidth
                              error={dateRangeError}
                              helperText={dateRangeError ? t("Field_req") : ""}
                              //sx={{ marginTop: "3px" }}
                            >
                              <MenuItem value="All">{t("All")}</MenuItem>
                              <MenuItem value="Today">{t("today")}</MenuItem>
                              <MenuItem value="Yesterday">
                                {t("yesterday")}
                              </MenuItem>
                              <MenuItem value="2days">{t("2days")}</MenuItem>
                              <MenuItem value="7days">{t("7days")}</MenuItem>
                              <MenuItem value="week">{t("week")}</MenuItem>

                              <MenuItem value="14days">{t("14days")}</MenuItem>
                              <MenuItem value="30days">{t("30days")}</MenuItem>
                              <MenuItem value="month">
                                {t("monthValue")}
                              </MenuItem>
                              <MenuItem value="60days">{t("60days")}</MenuItem>
                              <MenuItem value="90days">{t("90days")}</MenuItem>
                              <MenuItem value="6months">
                                {t("6months")}
                              </MenuItem>
                              <MenuItem value="12months">
                                {t("12months")}
                              </MenuItem>
                              <MenuItem value="custom">
                                {t("custom_text")}
                              </MenuItem>
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={5}
                            md={5}
                            lg={5}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Typography className={classes.emailReportSubLabel}>
                              {t("report_language")}
                            </Typography>
                            <Select
                              required
                              // defaultValue={"en"}
                              style={{ width: "100%" }}
                              key={languageSchedular?.value}
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={languageSchedular?.value}
                              // label="Select"
                              onChange={(e, value) => {
                                const userLang = languagesData?.find(
                                  (item) => item?.value === e.target.value
                                );
                                handleChangeLanguageForScheduler(userLang);
                              }}
                            >
                              {languagesData.map((item) => (
                                <MenuItem value={item.value}>
                                  {item.language}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={2}
                            md={2}
                            lg={2}
                            sx={{ paddingTop: "0px" }}
                          >
                            <Box sx={{ marginTop: "25px" }}>
                              <CommonButton
                                icon={<FilterAltIcon sx={{ color: "#FFFF" }} />}
                                onSubmit={handleOpenReviewsFilterModel}
                                label={t("Filters")}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                        {dateRange === "custom" && (
                          <Grid container spacing={2} sx={{ marginTop: "8px" }}>
                            {/* <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              sx={{ paddingTop: "0px" }}
                            >
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Fromm")}
                              </Typography>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  value={
                                    startDate !== null ? dayjs(startDate) : null
                                  }
                                  format="DD/MM/YYYY"
                                  disableFuture
                                  // closeOnSelect={false}
                                  onChange={(e) => {
                                    const newDate = e
                                      ? dayjs(e).startOf("day").toDate()
                                      : null;
                                    handleChangeRangeStartDateSchedule(newDate);

                                    //  setStartDateError(e === null || e === "");
                                  }}
                                  className={classes.whiteBackground}
                                  //  format="DD/MM/YYYY hh:mm A" // Display only hours without minutes

                                  minDate={dayjs("2005-01-01")}
                                  maxDate={dayjs().endOf("day")}
                                  renderInput={(params) => (
                                    <TextField
                                      autoComplete="off"
                                      fullWidth
                                      {...params}
                                      sx={{ background: "#FFFF" }}
                                      error={startDateError}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              md={4}
                              lg={4}
                              sx={{ paddingTop: "0px" }}
                            >
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Too")}
                              </Typography>

                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  disableFuture
                                  value={
                                    endDate !== null ? dayjs(endDate) : null
                                  }
                                  format="DD/MM/YYYY"
                                  onChange={(e) => {
                                    handleChangeRangeEndDateSchedule(e);
                                  }}
                                  className={classes.whiteBackground}
                                  minDate={dayjs("2005-01-01")}
                                  // maxDate={dayjs().endOf("day")}
                                  renderInput={(params) => (
                                    <TextField
                                      error
                                      autoComplete="off"
                                      fullWidth
                                      {...params}
                                      sx={{ background: "#FFFF" }}
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                            </Grid> */}

                            <DatePickerComponent
                              handleChangeStartDate={
                                handleChangeRangeStartDateSchedule
                              }
                              handleChangeEndDate={
                                handleChangeRangeEndDateSchedule
                              }
                              startDate={modalFiltersDate.startDate}
                              endDate={modalFiltersDate.endDate}
                              dateRange={dateRange}
                              setDateRange={setDateRange}
                              fromFilters={true}
                              // rangeValue={"between"}
                              fromSchedule={true}
                            />
                          </Grid>
                        )}
                      </Grid>
                    }
                  />
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                  <Collapsible
                    title={t("configure_emaill")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("configureEmail")}
                    id={"configureEmail"}
                    ComponentToRender={
                      <Grid container>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("select_user_type")}
                          </Typography>

                          <Grid container sx={{ marginLeft: "-10px" }}>
                            <Grid item xs={3}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeCompanyUser}
                                  checked={companyUser}
                                />
                                <Typography>{t("company_users")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  onChange={handleChangeOthers}
                                  checked={others}
                                />
                                <Typography>{t("others")}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} mt={1}>
                          {companyUser && users?.length > 0 && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("recipients")}
                              </Typography>
                              <Autocomplete
                                multiple
                                disableCloseOnSelect
                                id="tags-outlined"
                                options={users?.length > 0 ? users : []}
                                getOptionLabel={(option) =>
                                  option?.User?.firstName !== null &&
                                  option?.User?.lastName !== null
                                    ? option?.User?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                      option?.User?.firstName.slice(1) +
                                      " " +
                                      (option?.User?.lastName !== null &&
                                      option?.User?.lastName !== ""
                                        ? option?.User?.lastName
                                            .charAt(0)
                                            .toUpperCase() +
                                          option?.User?.lastName.slice(1) +
                                          " " +
                                          "(" +
                                          option?.User?.email +
                                          ")"
                                        : "")
                                    : option?.User?.email
                                }
                                onChange={(event, value) => {
                                  if (value.find((option) => option.id === 0)) {
                                    // Check if all options are selected
                                    if (
                                      users?.length ===
                                      selectedCompanyUsers?.length
                                    ) {
                                      setSelectedCompanyUsers([]);
                                      setSelectAll(false);
                                    } else {
                                      setSelectAll(true);
                                      setSelectedCompanyUsers(users);
                                      //  setLocationRequired(false);
                                    }
                                  } else {
                                    setSelectedCompanyUsers(value);
                                    if (value?.length === users?.length) {
                                      setSelectAll(true);
                                    } else {
                                      setSelectAll(false);
                                    }
                                  }
                                }}
                                value={selectedCompanyUsers}
                                sx={{ minWidth: 300 }}
                                renderOption={(props, option, { selected }) => {
                                  if (option.id === 0) {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          checked={selectAll}
                                        />
                                        {option?.firstName}
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li {...props}>
                                        <Checkbox
                                          icon={icon}
                                          checkedIcon={checkedIcon}
                                          style={{ marginRight: 8 }}
                                          // checked={selected}
                                          checked={selected || selectAll}
                                        />
                                        {option?.User?.firstName !== null &&
                                        option?.User?.lastName !== null
                                          ? option?.User?.firstName
                                              .charAt(0)
                                              .toUpperCase() +
                                            option?.User?.firstName.slice(1) +
                                            " " +
                                            (option?.User?.lastName !== null &&
                                            option?.User?.lastName !== ""
                                              ? option?.User?.lastName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                option?.User?.lastName.slice(
                                                  1
                                                ) +
                                                " " +
                                                "(" +
                                                option?.User?.email +
                                                ")"
                                              : "")
                                          : option?.User?.email}
                                        {/* {option?.internalName + ", " + option?.city} */}
                                      </li>
                                    );
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);
                                  if (users?.length > 0) {
                                    return [
                                      { id: 0, firstName: "Select All" },
                                      ...filtered,
                                    ];
                                  } else {
                                    return [];
                                  }
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder={t("select_user")}
                                  />
                                )}
                              />
                            </>
                          )}
                          {others && (
                            <>
                              <Typography
                                className={classes.emailReportSubLabel}
                              >
                                {t("Email_Address")}
                              </Typography>
                              <TextField
                                id="outlined-basic"
                                multiline
                                rows={5}
                                maxRows={10}
                                ref={textArea}
                                variant="outlined"
                                placeholder={t("Enter_recipients_email_here")}
                                sx={{ marginBottom: "12px" }}
                                value={addNewUserEmials}
                                fullWidth
                                // error={emailsError}
                                onKeyPress={(event) => {
                                  if (event.key === "Enter") {
                                    handleAddNewUserEmailsChange(
                                      event.target.value + ","
                                    );
                                  }
                                }}
                                onChange={(e) =>
                                  handleAddNewUserEmailsChange(e.target.value)
                                }
                              />
                              <Box className={classes.reviewsubHeadBox}>
                                {totalEmailsArr?.length > 5000 ? (
                                  <Typography
                                    className={classes.reviewsubHeadTextError}
                                  >
                                    {t("max_upload_text")}
                                  </Typography>
                                ) : (
                                  <Typography
                                    className={classes.reviewsubHeadText}
                                  >
                                    {t("send_email_text_emotionAi") +
                                      " " +
                                      totalEmailsArr?.length +
                                      " " +
                                      t("email_addresses")}
                                  </Typography>
                                )}
                              </Box>

                              <Box>
                                <input
                                  type="file"
                                  id="file-email"
                                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  onChange={handleFileUpload}
                                  ref={fileInputRef}
                                  style={{ display: "none" }}
                                />

                                <Box mt={3} ml={-3}>
                                  <CommonButton
                                    onSubmit={handleButtonClick}
                                    label={t("Browse_File")}
                                  />
                                </Box>

                                <Box>
                                  {selectedFile ? (
                                    <Box className={classes.fileBoxStyle}>
                                      <Typography
                                        onClick={handleReopenModal}
                                        className={classes.selectedFileTypo}
                                      >
                                        {selectedFile}
                                      </Typography>
                                      <IconButton
                                        className={classes.removeFileButton}
                                        onClick={() => handleRemoveFile()}
                                      >
                                        <DeleteIcon
                                          fontSize="small"
                                          className={classes.deleteIcon}
                                        />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <p>{t("")}</p>
                                  )}
                                </Box>
                              </Box>
                            </>
                          )}
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("subjectss")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            placeholder={t("enter_subject")}
                            defaultValue={emailSubject}
                            fullWidth
                            // error={headingRequired}
                            onChange={(e) => {
                              setEmailSubject(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} mt={1}>
                          <Typography className={classes.emailReportSubLabel}>
                            {t("body")}
                          </Typography>

                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            rows={5}
                            maxRows={10}
                            placeholder={t("enter_body_text")}
                            value={emailBody}
                            fullWidth
                            // error={headingRequired}
                            onChange={(e) => {
                              setEmailBody(e.target.value);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                </Box>
              </Grid>
            )}
          </DialogContent>

          <DialogActions sx={{ marginRight: "12px" }}>
            <CommonButton
              label={t("Cancel")}
              displayWhite="true"
              onSubmit={handleCloseNewRequestModel}
            />

            {/* {xlsChecked && ( */}
            <CommonButton
              label={t("send")}
              onSubmit={handleEmailReviewsXlsReport}
              disabled={
                pdfChecked === false && xlsChecked === false
                  ? true
                  : companyUser === false && others === false
                  ? true
                  : selectedCompanyUsers?.length === 0 &&
                    totalEmailsArr?.length === 0
                  ? true
                  : reviewReportLoading === true
                  ? true
                  : // : emailTitle === ""
                    // ? true
                    false
              }
              isLoading={reviewReportLoading}
            />
            {/* )} */}
          </DialogActions>
        </Dialog>

        {openReviewsFilterModel && (
          <FilterModal
            open={openReviewsFilterModel}
            close={handleCloseReviewsFilterModel}
            groups={allGroups}
            tags={allTags}
            sources={allReviewSource}
            locations={allLocations}
            setFilterObj={() => {}}
            modalFilters={modalFilters}
            modalFiltersDate={modalFiltersDate}
            setModalFiltersDate={setModalFiltersDate}
            setModalFilters={handleSubmit}
            checked={checked}
            setChecked={() => {}}
            setCallApi={() => {}}
          />
          // <FilterModelReviews
          //   open={openReviewsFilterModel}
          //   handleCloseFilterModel={handleCloseReviewsFilterModel}
          //   scheduleLocations={scheduleLocations}
          //   setScheduleLocations={setScheduleLocations}
          //   scheduleGroups={scheduleGroups}
          //   setScheduleGroups={setScheduleGroups}
          //   scheduleReviewSource={scheduleReviewSource}
          //   setScheduleReviewSource={setScheduleReviewSource}
          //   scheduleTags={scheduleTags}
          //   setScheduleTags={setScheduleTags}
          //   scheduleRatingValue={scheduleRatingValue}
          //   setScheduleRatingValue={setScheduleRatingValue}
          //   scheduleReviewerName={scheduleReviewerName}
          //   setScheduleReviewerName={setScheduleReviewerName}
          //   scheduleReply={scheduleReply}
          //   setScheduleReply={setScheduleReply}
          //   allLocations={allLocations}
          //   allGroups={allGroups}
          //   allReviewSource={allReviewSource}
          //   starRatings={starRatings}
          //   allTags={allTags}
          //   dateRange={dateRange}
          //   setDateRange={setDateRange}
          //   handleResetScheduleFiltersModel={handleResetScheduleFiltersModel}
          //   haventReplied={haventReplied}
          //   handleChangeDateRange={handleDateRangChange}
          //   startDate={startDate}
          //   setStartDate={setStartDate}
          //   setEndDate={setEndDate}
          //   endDate={endDate}
          // />
        )}
        {openLogoUploadModal && (
          <LogoImageModal
            title={t("upload_logo")}
            handleClose={handleCloseLogoUploadModal}
            open={openLogoUploadModal}
            selectedImages={images}
            setSelectedImages={setImages}
            setSelectedFile={setSelectedFileForImage}
            setUploadedImages={setUploadedImages}
            uploadedImages={uploadedImages}
          />
        )}
      </div>
    </>
  );
};

export default FetchModel;
