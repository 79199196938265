import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";

import { Grid, Typography } from "@mui/material";
import { TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import TitleHeader from "../../../components/CustomComponents/title";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import DomainCard from "../../../components/CustomComponents/Cards/DomainCard";
import ProgressCard from "../../../components/CustomComponents/Cards/ProgressCard";
import CommonSearch from "../../../components/CustomComponents/Cards/CommonSearch";
import { useTranslation } from "react-i18next";

const Index = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [backLinksData, setBackLinksData] = useState(location.state);
  const [anchorsDetails, setAnchorsDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(async () => {
    getAnchorsDetails();
  }, []);

  useEffect(() => {
    paginationData();
  }, [rowsPerPage, page]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAnchorsDetails = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/seo/backlink-reports/domains/85142`);
      if (res.status === 200) {
        setAnchorsDetails(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const paginationData = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(
        `/seo/backlink-reports/domains/85142?limit=${rowsPerPage}&page=${page}`
      );
      if (res.status === 200) {
        setAnchorsDetails(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader showButton={true} />
          <CommonSearch />

          {/* <Box sx={{ flexGrow: 1 }}> */}
          <Grid container spacing={2} alignItems="stretch">
            <Grid item md={5}>
              <ProgressCard
                labels={["to only homepage", "to only inside page", "Other"]}
                dataSet={[
                  backLinksData?.domains?.domains_saved -
                    backLinksData?.domains?.domains_to_otherpage,
                  backLinksData?.domains?.domains_saved -
                    backLinksData?.domains?.domains_to_homepage,
                  backLinksData?.domains?.domains_to_otherpage -
                    (backLinksData?.domains?.domains_saved -
                      backLinksData?.domains?.domains_to_homepage),
                ]}
                title={t("Refering_Domains")}
                title1={t("To_only_homepage")}
                title2={t("To_only_inside_pages")}
                title3={t("Other")}
                totalDomains={backLinksData?.domains?.total_domains}
                domainToHomepage={
                  backLinksData?.domains?.domains_saved -
                  backLinksData?.domains?.domains_to_otherpage
                }
                domainToInsidePage={
                  backLinksData?.domains?.domains_saved -
                  backLinksData?.domains?.domains_to_homepage
                }
                domainToOtherPage={backLinksData?.domains?.domains_to_otherpage}
              />
            </Grid>

            <Grid
              item
              md={2}
              alignItems="stretch"
              // spacing={2}
              // sx={{
              //   minHeight: "100%",
              // }}
            >
              <DomainCard
                title={t("REFERRING_IPS")}
                text={backLinksData?.ips?.total_ips}
              />

              <DomainCard
                title={t("REFERRING_BACKLINKS")}
                text={backLinksData?.backlinks?.total_backlinks}
              />
            </Grid>

            <Grid item md={5}>
              <DomainCard
                title={t("Refering_Domains")}
                // progress={anchorsData?.inlink_rank}
                text={backLinksData?.backlinks?.total_backlinks}
              />
            </Grid>
          </Grid>
          {/* </Box> */}
          <Typography
            variant="h4"
            sx={{ marginBottom: "1rem", marginTop: "2rem" }}
          >
            {t("Refering_Domains")}
          </Typography>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t("Domain")}</TableCell>
                  <TableCell align="right"> DT</TableCell>
                  <TableCell align="right">BACKLINKS</TableCell>
                  <TableCell align="right">SEEN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {anchorsDetails?.domains?.map((row) => (
                  <TableRow
                  // onClick={() => navigate(`/admin/backlink/${row?.reportId}`)}
                  >
                    <TableCell component="th" scope="row">
                      {row.domain}
                    </TableCell>

                    <TableCell align="right">
                      {row?.domain_inlink_rank}
                    </TableCell>
                    <TableCell align="right">{row?.backlinks}</TableCell>
                    <TableCell align="right">
                      {" "}
                      {moment(row?.first_seen).format("MM/DD/YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={anchorsDetails?.total_domains}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};

export default Index;
