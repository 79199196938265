import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "./signInForm";
import { useTranslation } from "react-i18next";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import logo from "../../../assets/Logo/logoblack.svg";
import { useStyles } from "../styles/styles";
import NavbarLanguagesDropdown from "../../../components/navbar/NavbarLanguages";
import { languageOptions } from "../../../assets/defaultLanguage";
// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 256.67px;
//   height: 80.81px;
//   margin-bottom: 18px;
//   top: 83.32px;
//   left: 27.68px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 28px;
    height: 28px;
  }
`;

function SignIn({ onDrawerToggle }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [userLanguage, setUserLanguage] = React.useState();
  const { i18n } = useTranslation();
  const stateData = JSON.parse(localStorage.getItem("user"));
  const languageData = localStorage.getItem("i18nextLng");
  let userLang = "";

  /*    useEffect(() => {
    const defaultLanguage = Object.keys(languageOptions).find((item) => {
      if (languageData === "en" ) {
        setUserLanguage(languageOptions["en"]);
        localStorage.setItem("i18nextLng", "en");
        i18n.languages = ["en"];
        i18n.language = "en";
        i18n.translator.language = "en";
      } else {
        if (
          languageOptions[`${item}`]?.name?.toLowerCase() ==
          languageData.toLowerCase()
        ) {
          userLang = item;
          //   i18n.options.fallbackLng[0] = userLang;
        }
        setUserLanguage(languageOptions[userLang]);
        localStorage.setItem("i18nextLng", userLang);
        i18n.languages = [userLang];
        i18n.language = userLang;
        i18n.translator.language = userLang;
      }
    });
  }, []); */

  return (
    <React.Fragment>
      {/* <Typography
        letterSpacing={25}
        fontSize="20px"
        height="29.15px"
        mb="10px"
        fontWeight={400}
      >
        Obenan
      </Typography> */}
      {/* <Brand />
       */}
      <img
        alt=""
        src={logo}
        alt="logo"
        style={{
          width: "256.67px",
          height: "100.81px",
          marginBottom: "18px",
          top: "83.32px",
          left: "27.68px",
        }}
      />
      <Wrapper>
        <Helmet title="Sign In" />
        <Grid container alignItems="center" sx={{ padding: "3px" }}>
          <Grid item sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs />
          <Grid item>
            <NavbarLanguagesDropdown />
          </Grid>
        </Grid>
        <Typography component="h1" gutterBottom className={classes.signInHead}>
          {t("Welcome_back")}
        </Typography>
        <Typography component="h2" className={classes.signInSubhead}>
          {t("Signin_text")}
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
