import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import language from "./i18nTranslation/translation.json";
import { languagesData } from "./assets/defaultLanguage";

const resources = language;
/* const stateData = JSON.parse(localStorage.getItem("user"));
let defaultLanguage = "en";
if (stateData && stateData?.language===null) {
  defaultLanguage = "en";
} else {
  defaultLanguage = languagesData.find(
    (item) => item?.language === stateData?.language
  );
} */

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || "en",
  fallbackLng: localStorage.getItem("i18nextLng"),
  interpolation: {
    escapeValue: false,
  },
});
