import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import logo from "../../../assets/Logo/logoblack.svg";
import { Paper, Typography } from "@mui/material";
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Menu as MenuIcon } from "@mui/icons-material";
// import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignUpComponent from "./signUpForm";
import NavbarLanguagesDropdown from "../../../components/navbar/NavbarLanguages";
import { useStyles } from "../styles/styles";
import { languageOptions } from "../../../assets/defaultLanguage";
// import { ReactComponent as Logo } from "../../assets/Logo/Logo.svg";

// const Brand = styled(Logo)`
//   fill: ${(props) => props.theme.palette.primary.main};
//   width: 256.67px;
//   height: 80.25px;
//   margin-bottom: 18px;
//   // margin-top: 28.87px;
// `;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 28px;
    height: 28px;
  }
`;

function SignUp({ onDrawerToggle }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [userLanguage, setUserLanguage] = React.useState();
  const { i18n } = useTranslation();
  const stateData = JSON.parse(localStorage.getItem("user"));
  const languageData = localStorage.getItem("i18nextLng");
  let userLang = "";

  let selectedLanguage = "";
  selectedLanguage = languageOptions[userLang];

  return (
    <React.Fragment>
      {/* <Brand /> */}
      <img
        alt=""
        src={logo}
        alt="logo"
        style={{
          width: "256.67px",
          height: "100.81px",
          marginBottom: "18px",
          top: "83.32px",
          left: "27.68px",
        }}
      />
      <Wrapper>
        <Helmet title="Sign Up" />

        <Grid container alignItems="center" sx={{ padding: "3px" }}>
          <Grid item sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item xs />
          <Grid item>
            <NavbarLanguagesDropdown />
          </Grid>
        </Grid>

        <Typography component="h1" className={classes.signupHead} gutterBottom>
          {t("Get_started")}
        </Typography>
        <Typography component="h2" className={classes.signupsubHead}>
          {t("Signup_subtext")}
        </Typography>

        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
