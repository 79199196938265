import { useCallback } from "react";
import moment from "moment";
import dayjs from "dayjs";

const useDateFormat = () => {
  const getFormatFromLocalStorage = () => {
    const user = localStorage.getItem("user");
    if (user !== null && user !== undefined) {
      const format = JSON.parse(user)?.dateFormat;
      if (format) return format;
      else return "DD/MM/YYYY";
    } else {
      return "DD/MM/YYYY";
    }
  };

  const formatDate = useCallback((date, handleInDayjs) => {
    const format = getFormatFromLocalStorage();
    return handleInDayjs !== undefined && handleInDayjs === true
      ? dayjs(date).format(format)
      : moment(date).format(format);
  }, []);

  // Return both the format string and the formatting function
  return {
    formatDate,
    format: getFormatFromLocalStorage(),
  };
};

export default useDateFormat;
