import React, { useState, useEffect } from "react";
import { DateRangePicker } from "mui-daterange-picker";
import {
  Dialog,
  Divider,
  Grid,
  TextField,
  IconButton,
  Typography,
  Tooltip,
  tooltipClasses,
  DialogContent,
  DialogTitle,
  DialogActions,
  Box,
} from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import styled from "styled-components/macro";
import dayjs from "dayjs";
import useDateFormat from "../../../../hooks/useDateFormat";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const RangePickerResults = ({
  handleChangeEndDate,
  handleChangeStartDate,
  startDate,
  endDate,
  dateRange,
  setDateRange,
  fromFilters,
  fromGoogleInsight,
  oneYearAgoDate,
  previousYear,
  setCallInsightsApis,
  setEmailBody,
  subscriptionDate,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [endDateModel, setEndDateModel] = useState(new Date());
  const [modelRange, setModelRange] = useState({
    startDate: new Date(dateRange?.startDate),
    endDate: new Date(dateRange?.endDate),
    range: dateRange?.range,
  });

  const { formatDate } = useDateFormat();

  const currentDate = new Date();
  currentDate.setHours(23);
  currentDate.setMinutes(59);
  currentDate.setSeconds(59);
  currentDate.setDate(currentDate.getDate() - 7);

  const OneBeforeCurrentDate = new Date();
  OneBeforeCurrentDate.setHours(23);
  OneBeforeCurrentDate.setMinutes(59);
  OneBeforeCurrentDate.setSeconds(59);

  const dateBeforeSevenDays = new Date(
    OneBeforeCurrentDate.setDate(OneBeforeCurrentDate.getDate() - 8)
  );

  const handleOpen = () => setOpen(true);

  const handleDateChange = (range) => {
    setModelRange({
      startDate: range?.startDate,
      endDate: range?.endDate,
      range:
        range?.label === "Last month"
          ? "month"
          : range?.label === "Last 30 days"
          ? "30days"
          : range?.label === "This month"
          ? "thisMonth"
          : range?.label === "Last year"
          ? "12months"
          : "custom",
    });
    // setModelRange(range);
    if (setCallInsightsApis !== undefined) {
      setCallInsightsApis(false);
    }
    const newEndDate = new Date();
    newEndDate.setDate(newEndDate.getDate() - 7); // Set end date to 7 days before today
    setEndDateModel(newEndDate);

    // handleClose();
  };

  useEffect(() => {
    const yearSelect = document.querySelector(
      ".MuiPicker-yearSelection select"
    );
    if (yearSelect) {
      const yearOptions = yearSelect.options;
      for (let i = 0; i < yearOptions.length; i++) {
        const year = parseInt(yearOptions[i].value);
        if (year > 2024) {
          yearOptions[i].disabled = true;
        }
      }
    }
  }, [open]);

  useEffect(() => {
    setModelRange(dateRange);
  }, [dateRange]);

  const handleSaveDateRange = () => {
    handleChangeStartDate(new Date(modelRange?.startDate.setHours(0, 0, 0, 0)));
    handleChangeEndDate(modelRange?.endDate);
    setOpen(false);
    setDateRange(modelRange);
    if (setEmailBody !== undefined) {
      setEmailBody(
        `Dear customer Please find attached google insights report for the ${formatDate(
          modelRange?.startDate
        )} to ${formatDate(modelRange?.endDate)}`
      );
    }
  };

  const handleClose = () => {
    setModelRange({
      startDate: dateRange?.startDate,
      endDate: dateRange?.endDate,
      range: dateRange?.range,
    });

    setOpen(false);
  };

  const startDateNew = new Date();
  startDateNew.setDate(startDateNew.getDate() - 7);

  // Calculate end date (today)
  const endDateNew = new Date();

  // Calculate today's start and end dates
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Set to midnight
  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999); // Set to end of the day

  // Calculate yesterday's start and end dates
  const yesterdayStart = new Date(todayStart);
  yesterdayStart.setDate(yesterdayStart.getDate() - 1);
  const yesterdayEnd = new Date(todayEnd);
  yesterdayEnd.setDate(yesterdayEnd.getDate() - 1);

  // Calculate start and end dates for this week
  const today = new Date();
  const thisWeekStart = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay() + 1
  ); // Monday of the current week

  // If today is Sunday, adjust to get the previous Monday
  if (today.getDay() === 0) {
    thisWeekStart.setDate(thisWeekStart.getDate() - 7);
  }

  const thisWeekEnd = new Date(
    thisWeekStart.getFullYear(),
    thisWeekStart.getMonth(),
    thisWeekStart.getDate() + 6
  ); // Sunday of the current week

  // Calculate start and end dates for last week (Monday to Sunday)
  const lastWeekStart = new Date(thisWeekStart);
  lastWeekStart.setDate(lastWeekStart.getDate() - 7); // Monday of last week
  const lastWeekEnd = new Date(thisWeekEnd);
  lastWeekEnd.setDate(lastWeekEnd.getDate() - 7); // Sunday of last week
  // Calculate the first and last dates of the previous month
  const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

  const lastYear = new Date()?.getFullYear() - 1; // Last year is one year before the current year
  const oneYearAgoDateYear = oneYearAgoDate?.getFullYear();

  // Determine last year start date based on oneYearAgoDate
  const lastYearStart =
    oneYearAgoDateYear < lastYear
      ? new Date(lastYear, 0, 1) // January 1 of the last year if oneYearAgoDate year is <= lastYear
      : new Date(
          oneYearAgoDate?.getFullYear(),
          oneYearAgoDate?.getMonth(),
          oneYearAgoDate?.getDate() + 1
        ); // Otherwise, add one day to oneYearAgoDate

  // const lastYearStart = new Date(oneYearAgoDate.getFullYear(), oneYearAgoDate.getMonth(), oneYearAgoDate.getDate() + 1);
  const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);

  const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
  const thisMonthEnd = new Date(startDateNew.setDate(startDateNew.getDate()));

  const ranges =
    startDateNew.getFullYear() !== today.getFullYear()
      ? [
          {
            label: t("30days"),
            startDate: new Date(
              dateBeforeSevenDays.getFullYear(),
              dateBeforeSevenDays.getMonth() - 1,
              dateBeforeSevenDays.getDate() + 1
            ),
            endDate: new Date(startDateNew.setDate(startDateNew.getDate() - 1)),
          },
          {
            label: t("monthValue"),
            startDate: lastMonthStart,
            endDate: lastMonthEnd,
          },
          {
            label: t("Last_Year"),
            startDate: lastYearStart,
            endDate: lastYearEnd,
          },
          {
            label: t("last_365_year"),
            startDate: new Date(startDateNew).setDate(
              startDateNew.getDate() - 365
            ),
            endDate: new Date(startDateNew.setDate(startDateNew.getDate())),
          },
        ]
      : startDateNew.getMonth() !== today.getMonth()
      ? [
          {
            label: t("30days"),
            startDate: new Date(
              dateBeforeSevenDays.getFullYear(),
              dateBeforeSevenDays.getMonth() - 1,
              dateBeforeSevenDays.getDate() + 1
            ),
            endDate: new Date(startDateNew.setDate(startDateNew.getDate() - 1)),
          },
          {
            label: t("monthValue"),
            startDate: lastMonthStart,
            endDate: lastMonthEnd,
          },
          {
            label: t("this_year"),
            startDate: new Date(today.getFullYear(), 0, 1),
            endDate: new Date(),
          },
          {
            label: t("Last_Year"),
            startDate: lastYearStart,
            endDate: lastYearEnd,
          },
          {
            label: t("last_365_year"),
            startDate: new Date(dateBeforeSevenDays).setDate(
              dateBeforeSevenDays.getDate() - 365
            ),
            endDate: new Date(startDateNew.setDate(startDateNew.getDate() - 1)),
          },
        ]
      : [
          {
            label: t("30days"),
            startDate: new Date(
              dateBeforeSevenDays.getFullYear(),
              dateBeforeSevenDays.getMonth() - 1,
              dateBeforeSevenDays.getDate() + 1
            ),
            endDate: new Date(startDateNew.setDate(startDateNew.getDate() - 1)),
          },
          {
            label: t("This_Month"),
            startDate: thisMonthStart,
            endDate: thisMonthEnd,
          },
          {
            label: t("monthValue"),
            startDate: lastMonthStart,
            endDate: lastMonthEnd,
          },
          {
            label: t("this_year"),
            startDate: new Date(today.getFullYear(), 0, 1),
            endDate: thisMonthEnd,
          },
          {
            label: t("Last_Year"),
            startDate: lastYearStart,
            endDate: lastYearEnd,
          },
          {
            label: t("last_365_year"),
            startDate: new Date(dateBeforeSevenDays).setDate(
              dateBeforeSevenDays.getDate() - 365
            ),
            endDate: dateBeforeSevenDays,
          },
        ];

  return (
    <>
      {fromGoogleInsight ? (
        <>
          {" "}
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ padding: "8px" }}>
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "14px",
                color: "#1b2430",
              }}
            >
              {t("Fromm")}
            </Typography>
            <TextField
              value={formatDate(modelRange?.startDate)}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Fromm")}
              //  label={t("Fromm")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ padding: "8px" }}>
            <Typography
              sx={{
                lineHeight: "28px",
                fontWeight: 700,
                fontSize: "14px",
                color: "#1b2430",
              }}
            >
              {t("Too")}
            </Typography>
            <TextField
              value={formatDate(modelRange?.endDate)}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Too")}
              // label={t("Too")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
        </>
      ) : (
        <>
          {" "}
          <Grid
            item
            xs={fromGoogleInsight ? 6 : 12}
            sm={fromGoogleInsight ? 6 : 12}
            md={fromGoogleInsight ? 6 : 12}
            lg={fromGoogleInsight ? 6 : 12}
            sx={{ padding: "8px" }}
            mt={fromGoogleInsight ? 3 : 0}
          >
            <TextField
              value={formatDate(modelRange?.startDate)}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Fromm")}
              label={t("Fromm")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
          <Grid
            item
            xs={fromGoogleInsight ? 6 : 12}
            sm={fromGoogleInsight ? 6 : 12}
            md={fromGoogleInsight ? 6 : 12}
            lg={fromGoogleInsight ? 6 : 12}
            sx={{ padding: "8px" }}
            mt={fromGoogleInsight ? 3 : 0}
          >
            <TextField
              value={formatDate(modelRange?.endDate)}
              // onChange={onChange}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton edge="end" onClick={handleOpen} size="large">
                    <InsertInvitationIcon />
                  </IconButton>
                ),
              }}
              fullWidth
              placeholder={t("Too")}
              label={t("Too")}
              sx={{ background: "#FFFF" }}
            />
          </Grid>
        </>
      )}

      {open && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "770px",
              height: "545px",
              borderRadius: "4px",
            },
          }}
          fullScreen
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {" "}
                <Typography
                  sx={{ fontWeight: 700, fontSize: "20px", marginTop: "3px" }}
                >
                  {t("Select_Date_Range")}
                </Typography>
                <BootstrapTooltip
                  title={
                    <h1 style={{ fontSize: "12px" }}>
                      {t("date_range_tooltip")}
                    </h1>
                  }
                >
                  <IconButton
                    sx={{
                      zIndex: "10",
                    }}
                  >
                    <ErrorOutlineIcon
                      sx={{
                        color: "#1B2430",
                      }}
                    />
                  </IconButton>
                </BootstrapTooltip>
              </Box>

              <IconButton
                autoFocus
                onClick={handleClose}
                className="delete_button"
              >
                <CloseIcon sx={{ paddingTop: "0px", cursor: "pointer" }} />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <DateRangePicker
              open={open}
              toggle={handleClose}
              onChange={handleDateChange}
              value={modelRange}
              initialDateRange={modelRange}
              definedRanges={
                subscriptionDate
                  ? [
                      ...ranges,
                      {
                        label: t("Start_date"),
                        startDate: dayjs(subscriptionDate).toDate(),
                        endDate: endDateModel,
                      },
                    ]
                  : ranges
              }
              minDate={new Date(oneYearAgoDate)}
              maxDate={currentDate}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <CommonButton
              label={t("Save")}
              onSubmit={handleSaveDateRange}
              //  loading={isLoading}
            />
          </DialogActions>
        </Dialog>
      )}
      <style>
        {`
       .MuiDialogActions-root {
        display: block;
        text-align:right;
      }
     
    `}
      </style>
      {/*    <style>
        {`
          .MuiDialogActions-root {
            display: block !important;
            text-align: right;
          }
        
            .MuiDivider-fullWidth + .MuiDivider-vertical{
            display: none !important;
          }
        .MuiDivider-vertical{
            display: none !important;
          }
             
        `}
      </style> */}
    </>
  );
};

export default RangePickerResults;
