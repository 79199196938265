import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Avatar,
  Tooltip,
  tooltipClasses,
  CircularProgress,
} from "@mui/material";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { Virtuoso } from "react-virtuoso";
import { Dialog } from "@material-ui/core";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import CoverImageModal from "./SingleImageModal/CoverImageModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import noImageGray from "../../../../../../assets/images/noImageGray.png";
import noImageGray from "../../../../../../../assets/images/noImageGray.png";
import addImage from "../../../../../../../assets/images/download.png";
import { styled } from "@mui/system";
import Compressor from "compressorjs";
import InfiniteScroll from "react-infinite-scroll-component";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CoverImages = ({
  title,
  tooltipText,
  imagesArray,
  handleOpenModal,
  handleDeleteImage,
  alignment,
  locationId,
  handleClose,
  open,
  loading,
  fetchMoreData,
  handleImagesShow,
  setTouchedValue,
  category,
  validation,
  identifier,
  setHasMore,
  hasMore,
  setTitle,
  handleOpenPreviewImage,
  mediaCount,
  scrollContainerRef,
  finalDeleteImages,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputFileRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadImg, setLoadImg] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [compressedFile, setCompressedFile] = useState([]);
  const [images, setImages] = useState([]);
  const [compressedImages, setCompressedImages] = useState([]);

  useEffect(() => {
    setCompressedImages([]);
    setImages([]);
  }, []);

  useEffect(() => {
    if (
      compressedImages?.length > 0 &&
      images?.length > 0 &&
      compressedImages?.length === images?.length
    ) {
      handleOpenModal(compressedImages);
      setTimeout(() => {
        setCompressedImages([]);
        setImages([]);
      }, 500);
    }
  }, [compressedImages]);

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  const onFileChangeCapture = (e) => {
    for (const [key, value] of Object.entries(e?.target?.files)) {
      let tempObj = {
        picture: value,
        picturePreview: URL.createObjectURL(value),
        uploaded: false,
      };
      images.push(tempObj);
      setImages([...images]);

      new Compressor(value, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setCompressedFile(compressedResult);

          compressedImages.push({
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
            uploaded: false,
          });

          setCompressedImages([...compressedImages]);
        },
      });
    }

    //  handleOpenModal(arr);
  };

  /*  const onFileChangeCapture = (e) => {
    let tempImages = [];
    for (const [key, value] of Object.entries(e.target.files)) {
        tempImages.push({
          picture: value,
          picturePreview: URL.createObjectURL(value),
          uploaded: false,
        });
      new Compressor(value, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setCompressedFile(compressedResult);
       
          let tempObj = {
            picture: compressedResult,
            picturePreview: URL.createObjectURL(compressedResult),
            uploaded: false,
          };
          tempImages.push(tempObj);
        },
      });
    }
    
    handleOpenModal(tempImages);
  }; */

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    let tempImages = [];
    for (const [key, value] of Object.entries(e?.dataTransfer?.files)) {
      tempImages.push({
        picture: value,
        picturePreview: URL.createObjectURL(value),
        uploaded: false,
      });
    }
    handleOpenModal(tempImages);
  };

  return (
    <>
      <Grid container sx={{ padding: "18px" }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box sx={{ display: "flex" }}>
            <Typography className={classes.modalHeader}>{title}</Typography>
            <BootstrapTooltip
              title={<h1 style={{ fontSize: "12px" }}>{tooltipText}</h1>}
            >
              <IconButton>
                <InfoIcon
                  sx={{
                    fontSize: "large",
                    paddingBottom: "3px",
                    color: "#8D9298",
                  }}
                />
              </IconButton>
            </BootstrapTooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography className={classes.modalSubheader}>
            {t("image_guideTwo")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ cursor: "pointer" }}>
          {imagesArray?.length === 0 ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                paddingTop: "18px",
                paddingBottom: "18px",
                position: "relative",
                height: "430px",
                maxHeight: "430px",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  width: "258px",
                  // /    border: "2px dashed #E0E0E0", /
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "166px",
                  borderWidth: "2px",
                  borderRadius: "1rem",
                  borderStyle: "dashed",
                  borderColor: "#cbd5e1",

                  // height: "70vh",
                }}
              >
                <form
                  className={classes.formUpload}
                  onDragEnter={handleDrag}
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <label
                    className={classes.lableFileUpload}
                    htmlFor={`file-${identifier}`}
                  >
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <AddAPhotoOutlinedIcon
                            sx={{
                              height: "40px",
                              width: "40px",
                              cursor: "pointer",
                              color: "#8D9298",
                            }}
                          />

                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginTop: "8px",
                              fontWeight: "400",
                              color: "#495059",
                            }}
                          >
                            {t("Uploading_Images_Text")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginTop: "8px",
                              fontWeight: "400",
                              color: "#495059",
                            }}
                          >
                            {t("or_key")}
                          </Typography>

                          <Typography
                            sx={{
                              fontSize: "14px",
                              marginTop: "8px",
                              fontWeight: "400",
                              color: "#495059",
                            }}
                          >
                            {t("browse_file")}
                          </Typography>
                        </Box>
                      </>
                    )}

                    <input
                      id={`file-${identifier}`}
                      type="file"
                      accept=".jpg, .png, .jpeg"
                      multiple={true}
                      ref={inputFileRef}
                      onChange={onFileChangeCapture}
                      style={{ display: "none" }}
                    />
                  </label>
                  {dragActive && (
                    <div
                      className={classes.dragFile}
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    ></div>
                  )}
                </form>
              </Box>
            </Grid>
          ) : (
            <div
              id="scrollableDiv"
              //ref={scrollContainerRef}
              style={{
                maxHeight: "320px",
                overflowY: "auto",
                // flexDirection: "column-reverse",
              }}
            >
              <InfiniteScroll
                dataLength={imagesArray.length}
                next={fetchMoreData}
                hasMore={hasMore}
                // loader={hasMore && <h4>Loading...</h4>}
                style={{
                  overflow: "unset",
                  height: imagesArray?.length === mediaCount ? "320px" : "50vh",
                  width: "100%",
                }}
                scrollableTarget="scrollableDiv"
                preserveScroll={true}
              >
                <Grid container /* style={{ minHeight: "200px" }} */>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    md={1.5}
                    lg={1.5}
                    sx={{ marginTop: "21px", height: "100px" }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        width: "80px",
                        // /    border: "2px dashed #E0E0E0", /
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "80px",
                        borderWidth: "2px",
                        borderRadius: "1rem",
                        borderStyle: "dashed",
                        borderColor: "#cbd5e1",

                        // height: "70vh",
                      }}
                    >
                      <form
                        className={classes.formUpload}
                        onDragEnter={handleDrag}
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <label
                          className={classes.lableFileUpload}
                          htmlFor={`file-${identifier}`}
                        >
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <AddAPhotoOutlinedIcon
                                  sx={{
                                    height: "40px",
                                    width: "40px",
                                    cursor: "pointer",
                                    color: "#8D9298",
                                  }}
                                />
                              </Box>
                            </>
                          )}
                          <input
                            id={`file-${identifier}`}
                            type="file"
                            accept="image/*"
                            multiple={true}
                            ref={inputFileRef}
                            onChange={onFileChangeCapture}
                            style={{ display: "none" }}
                          />
                        </label>
                        {dragActive && (
                          <div
                            className={classes.dragFile}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </form>
                    </Box>
                  </Grid>
                  {imagesArray?.map((item, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={1.5}
                        lg={1.5}
                        key={index}
                        style={{ marginTop: "10px", height: "100px" }}
                      >
                        <div
                          key={index}
                          style={{
                            position: "relative",
                            display: "inline-block",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <Avatar
                            variant="square"
                            className={classes.imageAvatar}
                            onClick={() => {
                              setTitle(title);
                              handleOpenPreviewImage(imagesArray, true, index);
                            }}
                          >
                            <LazyLoadImage
                              loading="lazy"
                              src={loadImg ? noImageGray : item?.sourceUrl}
                              beforeLoad={handleBeforeLOad}
                              afterLoad={handleAfterLOad}
                              style={{
                                height: "80px",
                                width: "80px",
                              }}
                              PlaceholderSrc={noImageGray}
                              alt="Image Alt"
                              effect="blur"
                            />
                          </Avatar>
                          <IconButton
                            className={classes.imageCrossIcon2}
                            onClick={() => handleDeleteImage(item, index)}
                          >
                            <CloseOutlinedIcon
                              fontSize="small"
                              sx={{ color: "#FFFF" }}
                            />
                          </IconButton>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CoverImages;
