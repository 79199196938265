import React, { useState, useEffect } from "react";
import DomainCard from "../../../components/CustomComponents/Cards/DomainCard";
import { Grid } from "@mui/material";
import ProgressCard from "../../../components/CustomComponents/Cards/ProgressCard";
import { api, handleSessionOut } from "../../../contexts/JWTContext";
import Loader from "../../../components/Loaders/Loader";
import AnchorCard from "../../../components/CustomComponents/Cards/AnchorCard";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TitleHeader from "../../../components/CustomComponents/title";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Index = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = params;
  const [domain, setDomain] = useState("");
  const [backLinksData, setBackLinksData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { signOut } = useAuth();

  useEffect(async () => {
    setLoading(true);
    await getBackLinks();
  }, []);

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const getBackLinks = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/seo/backlink-reports/overview/${id}`);
      if (res.status === 200) {
        setBackLinksData(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      handleSessionOut(
        error?.response?.status,
        error?.response?.data?.message,
        handleSignOut
      );
    }
  };

  const backLinksNavigateButton = () => {
    navigate(`/user/backlink/${id}/backlinks`, { state: backLinksData });
  };
  const anchorsNavigateButton = () => {
    navigate(`/user/backlink/${id}/anchors`, { state: backLinksData });
  };

  return (
    <>
      {loading ? (
        <Loader>Loading</Loader>
      ) : (
        <>
          <TitleHeader showButton={true} />

          <Grid container spacing={2}>
            <Grid container md={8} spacing={2}>
              <Grid item md={4}>
                <DomainCard
                  title={t("Domain_Trust")}
                  progress={backLinksData?.domain_inlink_rank}
                />
              </Grid>
              <Grid item md={4}>
                <DomainCard
                  title={t("Page_Trust")}
                  color="success"
                  progress={backLinksData?.inlink_rank}
                />
              </Grid>
              <Grid item md={4}>
                <DomainCard
                  title={t("Anchor_Texts")}
                  text={backLinksData?.anchors?.total_anchors}
                />
              </Grid>
            </Grid>
            <Grid item md={4}>
              <DomainCard
                title={t("EDU")}
                progress={backLinksData?.inlink_rank}
                text={backLinksData?.backlinks?.edu_backlinks}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4}>
              <ProgressCard
                onClick={anchorsNavigateButton}
                labels={["to only homepage", "to only inside page", "Other"]}
                dataSet={[
                  backLinksData?.domains?.domains_saved -
                    backLinksData?.domains?.domains_to_otherpage,
                  backLinksData?.domains?.domains_saved -
                    backLinksData?.domains?.domains_to_homepage,
                  backLinksData?.domains?.domains_to_otherpage -
                    (backLinksData?.domains?.domains_saved -
                      backLinksData?.domains?.domains_to_homepage),
                ]}
                title={t("Refering_Domains")}
                title1={t("To_only_homepage")}
                title2={t("To_only_inside_pages")}
                title3={t("Other")}
                totalDomains={backLinksData?.domains?.total_domains}
                domainToHomepage={
                  backLinksData?.domains?.domains_saved -
                  backLinksData?.domains?.domains_to_otherpage
                }
                domainToInsidePage={
                  backLinksData?.domains?.domains_saved -
                  backLinksData?.domains?.domains_to_homepage
                }
                domainToOtherPage={backLinksData?.domains?.domains_to_otherpage}
              />
            </Grid>
            <Grid item md={4}>
              <ProgressCard
                sx={{ cursor: "pointer" }}
                onClick={backLinksNavigateButton}
                labels={["Links to homepage", "Links to other pages"]}
                dataSet={[
                  backLinksData?.backlinks?.backlinks_to_homepage,
                  backLinksData?.backlinks?.backlinks_saved -
                    backLinksData?.backlinks?.backlinks_to_homepage,
                ]}
                title={t("BACKLINKS")}
                title1={t("Links_to_homepage")}
                title2={t("Links_to_other_pages")}
                hideTitle3={true}
                backLinksSaved={backLinksData?.backlinks?.backlinks_saved}
                totalDomains={backLinksData?.backlinks?.total_backlinks}
                domainToHomepage={
                  backLinksData?.backlinks?.backlinks_to_homepage
                }
                domainToInsidePage={0}
                domainToOtherPage={backLinksData?.domains?.domains_to_otherpage}
              />
            </Grid>

            <Grid item md={2}>
              <DomainCard
                title={t("EDU")}
                text={backLinksData?.backlinks?.edu_backlinks}
              />
              <DomainCard
                title={t("GOV")}
                text={backLinksData?.backlinks?.gov_backlinks}
              />
            </Grid>
            <Grid item md={2}>
              <DomainCard title="IPS" text={backLinksData?.ips?.total_ips} />
              <DomainCard
                title={t("SUBNETS")}
                text={backLinksData?.subnets?.total_subnets}
              />
            </Grid>
          </Grid>
          <AnchorCard backLinks={backLinksData} />
        </>
      )}
    </>
  );
};

export default Index;
