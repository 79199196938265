// ** React Imports ================================================================
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// ** File Imports ================================================================
import { api } from "../../../../contexts/JWTContext";
import { languagesData } from "../../../../assets/defaultLanguage";
import Delete from "../../../../components/Models/DeleteModal/Delete";
// ** Material ui Imports ================================================================
import Loader from "../../../../components/Loaders/Loader";
import { useStyles } from "../styles/style";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components/macro";
import { spacing } from "@mui/system";
import { Dialog, MenuItem } from "@mui/material";

import {
  Box,
  Grid,
  Typography,
  Tooltip,
  tooltipClasses,
  Divider as MuiDivider,
  IconButton,
  FormControl,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// ** Other Imports ================================================================
const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});

const Divider = styled(MuiDivider)(spacing);

const ShowTemplates = ({
  item,
  index,
  deleteTemplate,
  templateId,
  changeHandler,

  dataArray,
}) => {
  // ** States ================================================================
  const [textRequired, setTextRequired] = useState(false);
  const classes = useStyles();
  const [languageRequired, setLanguageRequired] = useState(false);
  const [reviewerName, setReviewerName] = useState(false, "");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [textCount, setTextCount] = useState(0);
  const textArea = useRef();
  const { t } = useTranslation();

  // ** Handler Functions ================================================================
  const handleClose = () => {
    setOpen(false);
  };

  const handleCount = (e) => {
    setTextCount(e.target.value.length);
  };
  const updateTemplateHandler = async () => {
    if (!item?.text) {
      setTextRequired(true);
    } else {
      setTextRequired(false);
    }
    if (!item?.language) {
      setLanguageRequired(true);
    } else {
      setLanguageRequired(false);
    }
    if (item?.text?.length > 0 && item?.language?.length > 0) {
      setIsLoading(true);
      const data = {
        text: item?.text,
        language: item?.language,
      };

      if (item?.id) {
        try {
          const res = await api.patch(`/templateDescription/${item?.id}`, data);

          if (res.status === 200) {
            // navigate("/user/template");
            toast.success(`Successfully updated template ${item?.id}`);
            setIsLoading(false);
          }
        } catch (error) {
          // toast.error(res.status.messaage);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      } else {
        try {
          data["templateId"] = templateId;
          const res = await api.post(`/templateDescription`, data);

          if (res.status === 200) {
            item["id"] = res?.data?.data?.id;
            // navigate("/user/template");
            toast.success(
              `Successfully added template description ${item?.id}`
            );
            setIsLoading(false);
          }
        } catch (error) {
          // toast.error(res.status.messaage);
          toast.error("Something went wrong");
          setIsLoading(false);
        }
      }
    }
  };

  const deleteTemplateApi = async () => {
    try {
      const res = await api.delete(`/templateDescription/${item?.id}`);

      if (res.status === 200) {
        deleteTemplate(index);
        // navigate("/user/template");
        toast.success(`Successfully deleted template description ${item?.id}`);
        setIsLoading(false);
        setOpen(false);
      }
    } catch (error) {
      // toast.error(res.status.messaage);
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Templatee")}
          description={t("template_desc")}
          onConfirm={() =>
            item?.id ? deleteTemplateApi() : deleteTemplate(index)
          }
          onCancel={handleClose}
        />
      </Dialog>
      <Grid container spacing={2}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <FormControl
                // className={classes.formControl}
                fullWidth

                // className="form-style"
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1B2430",
                    marginBottom: "8px",
                    marginTop: "10px",
                  }}
                >
                  {t("Language_review")}
                </Typography>

                <Select
                  error={languageRequired}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item?.language}
                  onChange={(e, value) => {
                    setLanguageRequired(false);
                    changeHandler(e, index, true, false);
                    // setLanguage(e.target.value);
                    // languageChangeHandler(e);
                  }}
                  // className="select-box"
                >
                  {languagesData.map((item) => (
                    <MenuItem value={item.value}>{item.language}</MenuItem>
                  ))}
                  {/* <MenuItem value={"fr"}>French</MenuItem>
                    <MenuItem value={"gr"}>German</MenuItem> */}
                </Select>
              </FormControl>

              <FormControl
                // className={classes.formControl}
                fullWidth

                // className="form-style"
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1B2430",
                    marginBottom: "8px",
                    marginTop: "10px",
                  }}
                >
                  {t("Personalize")}
                </Typography>

                <Select
                  //  error={languageRequired}
                  blurOnSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reviewerName}
                  // label="Personalize"
                  onChange={(e, value) => {
                    setReviewerName(e.target.value);
                    changeHandler(e, index, false, true, textArea);
                  }}
                  // className="select-box"
                >
                  <MenuItem value="{{first_name}}">
                    <div>
                      {t("First_name")} <br />{" "}
                      <span className={classes.menu_item}>ex: john</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{last_name}}">
                    <div>
                      {t("Last_name")} <br />{" "}
                      <span className={classes.menu_item}>ex: alpha</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{business_email}}">
                    <div>
                      {t("Business_email")} <br />{" "}
                      <span className={classes.menu_item}>ex: john@obenan.com</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{business_phone_number}}">
                    <div>
                      {t("Phone_no")} <br />{" "}
                      <span className={classes.menu_item}>ex: +3197010281297</span>
                    </div>
                  </MenuItem>
                  {/*   <MenuItem value="{{reviewer}}">
                    <div>
                      Reviewer Name <br />{" "}
                      <span className="menu-item">ex: john</span>
                    </div>
                  </MenuItem> */}

                  <MenuItem value="{{location}}">
                    <div>
                      {t("Location_Name")} <br />{" "}
                      <span className={classes.menu_item}>ex: Factory Girl</span>
                    </div>
                  </MenuItem>
                  {/* <MenuItem value={"fr"}>French</MenuItem>
                    <MenuItem value={"gr"}>German</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={10.5} sm={11} lg={7.5} md={7}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1B2430",
                    marginBottom: "8px",
                    marginTop: "10px",
                  }}
                >
                  {t("Description")} {index + 1}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#1B2430",
                    lineHeight: "20px",
                    //   marginBottom: "8px",
                    float: "right",
                    paddingTop: "10px",
                    // ml: 2,
                  }}
                >
                  {" "}
                  {item?.text?.length}/4000{" "}
                </Typography>
                {item?.text?.length >= 4000 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#FF0000",
                      lineHeight: "15px",
                      marginTop: "16px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("text_limit_text")}
                  </Typography>
                )}
              </Grid>

              <Box>
                {/*    <TextareaAutosize
                  id="filled-multiline-static"
                  multiline
                  style={{
                    border: "1px solid #E0E0E0",
                    fontFamily: "Segoe UI",
                    fontStyle: "Normal",
                    padding: "12px 12px 12px 12px",
                    width: "100%",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "120px",
                    color: "#1B2430",
                    fontWeight: "400",
                    fontSize: "16px",
                    //   padding: "15px",
                  }}
                  ref={textArea}
                  error={textRequired}
                  aria-label="empty textarea"
                  placeholder="Write down template description ..."
                  value={item?.text}
                  onChange={(e) => {
                    if (item?.text?.length > 5) {
                      setTextRequired(true);
                    }
                    // setTextRequired(false);
                    // setText(e.target.value);
                    changeHandler(e, index);

                    handleCount(e);
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  inputProps={{ maxLength: 1000 }}
                  // className="text-field"
                /> */}
                <textarea
                  id="standard-basic"
                  // label="Standard"
                  rows={5.8}
                  maxRows={10}
                  value={item?.text}
                  style={{
                    border: "1px solid #c4c4c4",
                    fontFamily: "Segoe UI",
                    fontStyle: "Normal",
                    padding: "12px 12px 12px 12px",
                    width: "100%",
                    gap: "8px",
                    borderRadius: "4px",
                    minHeight: "144px",
                    color: "#1B2430",
                    fontWeight: "400",
                    fontSize: "16px",
                    "&:hover ": {
                      border: "1px solid #54d2ff",
                    },
                  }}
                  label={null}
                  onChange={(e) => {
                    if (
                      item?.text?.length > 4000 ||
                      e.target.value < 1 ||
                      e.target.value === ""
                    ) {
                      setTextRequired(true);
                    } else {
                      setTextRequired(false);
                    }
                    // setTextRequired(false);
                    // setText(e.target.value);
                    changeHandler(e, index);

                    handleCount(e);
                  }}
                  multiline
                  fullWidth
                  ref={textArea}
                  error={textRequired}
                  /*   InputProps={{
                    disableUnderline: true,
                  }} */
                  inputProps={{ maxLength: 4000 }}
                  /*   InputProps={{
                inputComponent: TextareaAutosize,
              }} */
                />
                {textRequired && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontWeight: 400,
                      fontSize: "0.6964285714285714rem",
                      lineHeight: "1.66",
                      textAlign: "left",
                      marginTop: "3px",
                      marginRight: "14px",
                      marginBottom: 0,
                      marginLeft: "14px",
                    }}
                  >
                    {t("desc_req")}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={1.5} sm={1} md={1} lg={0.5} className={classes.grid_item_2}>
              {dataArray.length > 1 && (
                <CustomWidthTooltipOfDelete
                  title={t("Delete_Template")}
                  sx={{ marginTop: ".5rem", color: "#FC3652" }}
                >
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      changeHandler(e, index, false, false, textArea, true);
                      // setOpen(true);
                      // item?.id ? deleteTemplateApi() : deleteTemplate(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomWidthTooltipOfDelete>
              )}
            </Grid>
          </>
        )}
      </Grid>
      {/*  <Grid item md={4} sm={4} className="grid-item-2">
        <CustomWidthTooltipOfDelete
          title={t("Delete_Template")}
          sx={{ marginTop: ".5rem" }}
        >
          <IconButton
            aria-label="delete"
            size="large"
            onClick={() => {
              setOpen(true);
              // item?.id ? deleteTemplateApi() : deleteTemplate(index);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </CustomWidthTooltipOfDelete>

        <CustomWidthTooltipOfDelete title="Save" sx={{ marginTop: ".5rem" }}>
          <IconButton
            aria-label="save"
            size="large"
            onClick={() => updateTemplateHandler()}
          >
            <SaveAsIcon />
          </IconButton>
        </CustomWidthTooltipOfDelete>
      </Grid> */}
    </>
  );
};

export default ShowTemplates;
