import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
// import SentimentImage from "../../../assets/images/image.png";
import NoEmotionAIImg from "../../../assets/images/NoEmotionAi.png";
import NoLocation from "../../../assets/images/nolocationfull.svg";
import { makeStyles } from "@mui/styles";
import CommonButton from "../Buttons/CommonButton";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  iconCard: {
    border: "1px solid #E0E0E0",
    backgroundColor: "#FFFFFF",
    justifyContent: "space-around",
    width: "40px",
    height: "40px",
    padding: "8px",
  },
  iconContainer: {
    padding: "20px 0px 10px 0px",
  },
  iconStyle: {
    width: "20px",
    height: "18px",
  },
  locationbox: {
    display: "row",
    alignItems: "center",
    textAlign: "center",
  },
  locationsubhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "16px",
    color: "#495059",
  },
  locationhead: {
    marginTop: "8px",
    marginBottom: "8px",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    color: "#1B2430",
  },
  Imagesection: {
    marginTop: "8px",
    height: "100%",
    width: "100%",
  },
  Imagesection2: {
    marginTop: "8px",
    height: "250px",
  },
}));
const SentimentsNotFound = ({
  text,
  subtext,
  onSubmit,
  handleRequestForEmotionAI,
  loading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.locationbox}>
      {/*   <Typography className={classes.locationhead}>
        {text}

      </Typography>

      <Typography className={classes.locationsubhead}>{subtext}</Typography> */}
      {/*       <Box
        sx={{
          marginTop: "2rem",
          //  marginBottom: "2rem",
          marginInline: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            height: "52px",
            width: "auto",
            fontSize: "14px",
            marginLeft: "10px",
            borderRadius: "8px",
            color: "#FFFFFF",
          }}
          color="primary"
          variant="contained"
          onClick={() => onSubmit()}
       
        >
          {t("Contact_Support")}
        </Button>
       
      </Box> */}
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ width: "90%", margin: "auto" }}>
            <img alt="" className={classes.Imagesection} src={NoEmotionAIImg} />
          </div>

          {/* <SentimentImage /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SentimentsNotFound;
