import React from "react";
import styled from "styled-components/macro";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const ReviewsTable = ({ allReviews }) => {
  return (
    <>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SourceName </TableCell>
              <TableCell>Average Rating</TableCell>
              <TableCell>Reviews Count </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allReviews.reviewSummary?.sources.map((source) => (
              <TableRow>
                <TableCell component="th" scope="row">
                  {source.sourceName}
                </TableCell>
                <TableCell component="th" scope="row">
                  {source.avgRating}
                </TableCell>
                <TableCell component="th" scope="row">
                  {source.reviewCount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </>
  );
};

export default ReviewsTable;
