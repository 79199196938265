import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Select,
  Checkbox,
  MenuItem,
  Button,
  Card,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import Switch from "@mui/material/Switch";
import dayjs from "dayjs";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useStyles } from "../../../../../Styles/style";
import AddIcon from "@mui/icons-material/Add";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { languagesData } from "../../../../../../../../assets/defaultLanguage";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";

const AttributesModal = ({
  displayName,
  parent,
  subParent,
  dataValues,
  grandParent,
  subChild,
  handleChangeChecked,
  addMultiAttribute,
  removeMultiAttribute,
  handleChangeStringValue,
  handleChangeIntegerValue,
  handleChangeFloatValue,
  handleChangeDateValue,
  handleChangeEnum,
  isEditable,
  integerError,
  setIntegerError,
  setSelectedLanguagesArr,
  selectedLanguagesArr,
  addLanguageAttribute,
  removeLanguageAttribute,
  switchLanguageAttribute,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [valueType, setValueType] = useState("");
  const [stringValue, setStringValue] = useState("");
  const [integerValue, setIntegerValue] = useState(0);
  const [enumValue, setEnumValue] = useState("");
  const [enumOptions, setEnumOptions] = useState([]);
  const [dateValue, setDateValue] = useState("");
  const [languageValue, setLanguageValues] = useState([]);

  const min = 0;
  const max = 100000000;

  useEffect(() => {
    let OpenHoursValue;
    if (dataValues?.value !== null) {
      if (dataValues?.type === "boolean") {
        if (dataValues?.value === true) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      } else if (
        dataValues?.type === "integer" ||
        dataValues?.type === "number"
      ) {
        setIntegerValue(dataValues?.value);
      } else if (
        dataValues?.type === "string" ||
        dataValues?.type === "String"
      ) {
        if (displayName === "languageCode") {
          // setEnumValue(dataValues?.value);
          /*   languagesData?.forEach((item) => {
            dataValues?.value?.forEach((itemTwo) => {
              if (itemTwo === item?.code) {
                languageValue.push(item);
              }
            });
          });
          setLanguageValues([...languageValue]); */
        }
        setStringValue(dataValues?.value);
      } else if (dataValues?.type === "enum") {
        setEnumValue(dataValues?.value);
        setEnumOptions(dataValues?.enum);
      } else if (dataValues?.hasOwnProperty("hours")) {
        if (
          parseInt(dataValues?.hours?.value) >= 10 ||
          parseInt(dataValues?.minutes?.value) >= 10
        ) {
          if (parseInt(dataValues?.hours?.value) === 24) {
            OpenHoursValue = "00:00";
          } else {
            OpenHoursValue =
              (dataValues?.hours.value && dataValues?.hours.value !== null
                ? dataValues?.hours?.value
                : "0") +
              ":" +
              (dataValues?.minutes?.value
                ? dataValues?.minutes?.value + ":" + "00"
                : "00");
          }
        } else {
          OpenHoursValue =
            "0" +
            (dataValues?.hours?.value && dataValues?.hours?.value !== null
              ? dataValues?.hours?.value
              : "0") +
            ":" +
            (dataValues?.minutes?.value && dataValues?.minutes?.value !== null
              ? dataValues?.minutes?.value + ":" + "00"
              : "00");
        }
        const finalOpenValue = "Wed Jul 16 1997" + " " + OpenHoursValue;
        setDateValue(finalOpenValue);
      }
    }
    setValueType(dataValues?.value);
    if (displayName === "languagesSpoken") {
      setSelectedLanguagesArr(dataValues);
    }
  }, []);

  useEffect(() => {
    let OpenHoursValue;
    if (dataValues?.value !== null) {
      if (dataValues?.type === "boolean") {
        if (dataValues?.value === true) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      } else if (
        dataValues?.type === "string" ||
        dataValues?.type === "String"
      ) {
        /*  if (displayName === "languageCode") {
          setEnumValue(dataValues?.value);
        } */
        setStringValue(dataValues?.value);
      } else if (dataValues?.type === "enum") {
        setEnumValue(dataValues?.value);
        setEnumOptions(dataValues?.enum);
      } else if (dataValues?.hasOwnProperty("hours")) {
        if (
          parseInt(dataValues?.hours?.value) >= 10 ||
          parseInt(dataValues?.minutes?.value) >= 10
        ) {
          if (parseInt(dataValues?.hours?.value) === 24) {
            OpenHoursValue = "00:00";
          } else {
            OpenHoursValue =
              (dataValues?.hours.value && dataValues?.hours.value !== null
                ? dataValues?.hours?.value
                : "0") +
              ":" +
              (dataValues?.minutes?.value
                ? dataValues?.minutes?.value + ":" + "00"
                : "00");
          }
        } else {
          OpenHoursValue =
            "0" +
            (dataValues?.hours?.value && dataValues?.hours?.value !== null
              ? dataValues?.hours?.value
              : "0") +
            ":" +
            (dataValues?.minutes?.value && dataValues?.minutes?.value !== null
              ? dataValues?.minutes?.value + ":" + "00"
              : "00");
        }
        const finalOpenValue = "Wed Jul 16 1997" + " " + OpenHoursValue;
        setDateValue(finalOpenValue);
      }
    }
    setValueType(dataValues?.value);
  }, [displayName]);

  var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;
  function validURLOther(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }

  function validURL(string) {
    if (typeof string !== "string") {
      return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }

    if (
      localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }

    return false;
  }

  function handleDisplayName(name) {
    let text = "";
    if (name !== "" && name !== undefined) {
      const splittedText = name.split("/");
      const finalText = splittedText[1]?.replaceAll("_", " ");
      text =
        finalText[0]?.toUpperCase() + finalText?.substring(1)?.toLowerCase();
    }

    return text;
  }

  return (
    <>
      {displayName === "languagesSpoken" ? (
        <>
          {selectedLanguagesArr?.map((languageItem, index) => {
            return (
              <Grid container sx={{ paddingTop: "8px", paddingLeft: "20px" }}>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Typography className={classes.lodgingAttributeText}>
                    {selectedLanguagesArr[index]["languageCode"].name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sm={4}
                  md={4}
                  lg={4}
                  sx={{ textAlign: "end" }}
                >
                  {selectedLanguagesArr[index]["spoken"].value !== null && (
                    <Box
                      style={{
                        width: "100%",
                        marginRight: "5px",
                        display: "flex",
                        justifyContent: "flex-end",
                        textAlign: "end",
                      }}
                    >
                      <Switch
                        // className={classes.toggleChecked}
                        className={
                          selectedLanguagesArr[index]["spoken"].value === true
                            ? classes.toggleUnChecked
                            : classes.toggleChecked
                        }
                        checked={
                          selectedLanguagesArr[index]["spoken"].value
                            ? true
                            : false
                        }
                        // disabled={isEditable || dataValues?.isEditable === false}
                        onChange={(e) => {
                          switchLanguageAttribute(index, e.target.checked);
                        }}
                      />
                      <Typography sx={{ paddingTop: "8px" }}>
                        {" "}
                        {selectedLanguagesArr[index]["spoken"].value === true
                          ? t("Yes")
                          : t("No")}
                      </Typography>
                    </Box>
                  )}
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  sx={{ textAlign: "end" }}
                >
                  {selectedLanguagesArr[index]["spoken"].value !== null ? (
                    <IconButton
                      onClick={() => {
                        // setValueType(null);
                        removeLanguageAttribute(index);
                      }}
                      disabled={isEditable}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        addLanguageAttribute(index);
                      }}
                      // disabled={isEditable}
                    >
                      <AddIcon
                        sx={{ color: isEditable === false && "#06BDFF" }}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </>
      ) : (
        <>
          <Grid item xs={7} sm={7} md={7} lg={7}>
            <Typography className={classes.lodgingAttributeText}>
              {displayName
                ?.replace(/([A-Z])/g, " $1")
                .replace(/^./, (str) => str.toUpperCase())
                .trim()}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} sx={{ textAlign: "end" }}>
            {valueType !== null &&
              (dataValues?.type === "boolean" ? (
                <Box
                  style={{
                    width: "100%",
                    marginRight: "5px",
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "end",
                  }}
                >
                  <Switch
                    // className={classes.toggleChecked}
                    className={
                      checked && isEditable === false
                        ? classes.toggleUnChecked
                        : checked && isEditable === true
                        ? classes.toggleDisabled
                        : classes.toggleChecked
                    }
                    checked={checked}
                    disabled={isEditable || dataValues?.isEditable === false}
                    onChange={(e) => {
                      handleChangeChecked(
                        e,
                        parent,
                        subParent,
                        grandParent !== "0" ? grandParent : undefined,
                        displayName,
                        subChild
                      );
                      setChecked(e.target.checked);
                    }}
                  />
                  <Typography sx={{ paddingTop: "8px" }}>
                    {" "}
                    {checked ? t("Yes") : t("No")}
                  </Typography>
                </Box>
              ) : dataValues?.type === "number" ? (
                <TextField
                  InputProps={{
                    inputProps: {
                      style: {
                        width: "80%",
                        marginLeft: "10px",
                        marginRight: "10px",
                        borderRadius: "800px",
                      },
                    },
                  }}
                  set
                  //  inputProps={{ min: 0 }}
                  disabled={isEditable}
                  size="small"
                  type="number"
                  name="firstName"
                  value={integerValue}
                  //   error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  inputProps={{
                    type: "number",
                    min: 0,
                    step: 0.5,
                  }}
                  //  helperText={touched.firstName && errors.firstName}

                  onChange={(e) => {
                    const newValue = Math.max(parseFloat(e.target.value), 0);
                    setIntegerValue(newValue);

                    handleChangeFloatValue(
                      e.target.value,
                      parent,
                      subParent,
                      grandParent !== "0" ? grandParent : undefined,
                      displayName,
                      subChild
                    );
                  }}
                />
              ) : dataValues?.type === "integer" ? (
                <TextField
                  InputProps={{
                    inputProps: {
                      style: {
                        width: "80%",
                        marginLeft: "10px",
                        marginRight: "10px",
                        borderRadius: "800px",
                      },
                    },
                  }}
                  disabled={isEditable}
                  size="small"
                  type="number"
                  inputProps={{ min, max }}
                  value={integerValue}
                  error={integerError}
                  fullWidth
                  helperText={integerError && "Negative values not allowed"}
                  onChange={(e) => {
                    if (e.target.value >= 0 || !e.target.value.includes("-")) {
                      const newValue = Math.max(parseFloat(e.target.value), 0);
                      setIntegerValue(newValue);
                      // setIntegerError(false);
                      handleChangeIntegerValue(
                        e.target.value,
                        parent,
                        subParent,
                        grandParent !== "0" ? grandParent : undefined,
                        displayName,
                        subChild
                      );
                    } else {
                      setIntegerValue("");
                    }
                  }}
                />
              ) : (dataValues?.type === "string" ||
                  dataValues?.type === "String") &&
                displayName !== "languageCode" ? (
                <TextField
                  InputProps={{
                    inputProps: {
                      style: {
                        width: "80%",
                        marginLeft: "10px",
                        marginRight: "10px",
                        borderRadius: "800px",
                      },
                    },
                  }}
                  disabled={isEditable}
                  size="small"
                  type="text"
                  name="firstName"
                  value={stringValue}
                  //   error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  //  helperText={touched.firstName && errors.firstName}

                  onChange={(e) => {
                    setStringValue(e.target.value);
                    handleChangeStringValue(
                      e.target.value,
                      parent,
                      subParent,
                      grandParent !== "0" ? grandParent : undefined,
                      displayName,
                      subChild
                    );
                  }}
                />
              ) : dataValues?.type === "enum" &&
                displayName !== "languageCode" ? (
                <TextField
                  style={{ width: "100%", textAlign: "left" }}
                  disabled={isEditable}
                  onChange={(e, value) => {
                    handleChangeEnum(
                      e.target.value,
                      parent,
                      subParent,
                      grandParent !== "0" ? grandParent : undefined,
                      displayName,
                      subChild
                    );

                    setEnumValue(e.target.value);
                    /*   if (value===null) {
                  setEnumRequired(true);
                } else {
                  setEnumRequired(false);
                } */
                  }}
                  select // tell TextField to render select
                  //   label={`${t("Select")} ${item?.displayName}`}
                  // error={enumRequired}
                  //  helperText={enumRequired && t("Field_is_Required")}
                  value={enumValue}
                >
                  {displayName === "languageCode"
                    ? languagesData.map((item) => (
                        <MenuItem value={item.value}>{item.language}</MenuItem>
                      ))
                    : enumOptions?.length > 0 &&
                      enumOptions?.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item?.replaceAll("_", " ")}
                        </MenuItem>
                      ))}
                </TextField>
              ) : displayName === "languageCode" &&
                dataValues?.type === "string" ? (
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  //  sx={{ height: "100px" }}
                  options={languagesData}
                  getOptionLabel={(option) => option?.language}
                  className="Autocomplete-field"
                  value={languageValue}
                  onChange={(e, value) => {
                    let itemKeys = [];
                    value?.map((item) => {
                      itemKeys?.push(item?.value);
                    });
                    setLanguageValues(value);
                    handleChangeEnum(
                      itemKeys,
                      parent,
                      subParent,
                      grandParent !== "0" ? grandParent : undefined,
                      displayName,
                      subChild
                    );
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.language} `}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      //   placeholder={t("Select_Company")}
                      {...params}
                      //   required
                      // label={t("TaskCompany")}
                      name="language"
                      //   error={companyIdRequired}
                    />
                  )}
                ></Autocomplete>
              ) : dataValues?.hours ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]}>
                    <TimePicker
                      // label="Time"
                      classes={{
                        root: classes.timePickerRoot,
                      }}
                      style={{ width: "100%" }}
                      value={dayjs(dateValue)}
                      // disablePast
                      // use12Hours
                      ampm={false}
                      disabled={isEditable}
                      // minTime={new Date(item?.openTimesValueMerged)}
                      // minTime={new Date(0, 0, 0, 12)}
                      onChange={(e) => {
                        handleChangeDateValue(
                          e,
                          parent,
                          subParent,
                          displayName
                        );
                        setDateValue(e);
                        /*  if (e===null) {
                    setCloseTimesError(true);
                  } else {
                    setCloseTimesError(false);
                  } */
                      }}
                      renderInput={(params) => (
                        <TextField {...params} style={{ width: "100%" }} />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              ) : null)}
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} sx={{ textAlign: "end" }}>
            {valueType !== "" && valueType !== null ? (
              <IconButton
                onClick={() => {
                  setValueType(null);
                  removeMultiAttribute(
                    parent,
                    subParent,
                    grandParent !== "0" ? grandParent : undefined,
                    displayName,
                    subChild
                  );
                }}
                disabled={isEditable}
              >
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setValueType(dataValues?.type);
                  if (dataValues?.type === "enum") {
                    setEnumOptions(dataValues?.enum);
                  }
                  addMultiAttribute(
                    parent,
                    subParent,
                    grandParent !== "0" ? grandParent : undefined,
                    displayName,
                    dataValues?.type,
                    subChild
                  );
                }}
                disabled={isEditable}
              >
                <AddIcon sx={{ color: isEditable === false && "#06BDFF" }} />
              </IconButton>
            )}
          </Grid>
        </>
      )}

      {dataValues?.hasOwnProperty("displayFields") && (
        // <>
        //   {dataValues?.displayFields===true &&
        //     dataValues?.depandant?.map((item, index) => {
        //       return (
        //         <Grid container>
        //           <Grid item xs={7} sm={7} md={7} lg={7}>
        //             <Typography className={classes.lodgingAttributeText}>
        //               {item?.key
        //                 ?.replace(/([A-Z])/g, " $1")
        //                 .replace(/^./, (str) => str.toUpperCase())
        //                 .trim()}
        //             </Typography>
        //           </Grid>
        //           <Grid
        //             item
        //             xs={4}
        //             sm={4}
        //             md={4}
        //             lg={4}
        //             sx={{ textAlign: "end" }}
        //           >
        //             {item?.value !== null &&
        //               (item?.type === "boolean" ? (
        //                 <Box
        //                   style={{
        //                     width: "100%",
        //                     marginRight: "5px",
        //                     display: "flex",
        //                     justifyContent: "flex-end",
        //                     textAlign: "end",
        //                   }}
        //                 >
        //                   <Switch
        //                     // className={classes.toggleChecked}
        //                     className={
        //                       checked && isEditable===false
        //                         ? classes.toggleUnChecked
        //                         : checked && isEditable===true
        //                         ? classes.toggleDisabled
        //                         : classes.toggleChecked
        //                     }
        //                     checked={checked}
        //                     disabled={isEditable}
        //                     onChange={(e) => {
        //                       handleChangeChecked(
        //                         e,
        //                         parent,
        //                         subParent,
        //                         grandParent !== "0" ? grandParent : undefined,
        //                         displayName,
        //                         subChild
        //                       );
        //                       setChecked(e.target.checked);
        //                     }}
        //                   />
        //                   <Typography sx={{ paddingTop: "8px" }}>
        //                     {" "}
        //                     {checked ? t("Yes") : t("No")}
        //                   </Typography>
        //                 </Box>
        //               ) : item?.type === "number" ? (
        //                 <TextField
        //                   InputProps={{
        //                     inputProps: {
        //                       style: {
        //                         width: "80%",
        //                         marginLeft: "10px",
        //                         marginRight: "10px",
        //                         borderRadius: "800px",
        //                       },
        //                     },
        //                   }}
        //                   set
        //                   //  inputProps={{ min: 0 }}
        //                   disabled={isEditable}
        //                   size="small"
        //                   type="number"
        //                   name="firstName"
        //                   value={integerValue}
        //                   //   error={Boolean(touched.firstName && errors.firstName)}
        //                   fullWidth
        //                   inputProps={{
        //                     type: "number",
        //                     min: 0,
        //                     step: 0.5,
        //                   }}
        //                   //  helperText={touched.firstName && errors.firstName}

        //                   onChange={(e) => {
        //                     setIntegerValue(e.target.value);

        //                     handleChangeFloatValue(
        //                       e.target.value,
        //                       parent,
        //                       subParent,
        //                       grandParent !== "0" ? grandParent : undefined,
        //                       displayName,
        //                       subChild
        //                     );
        //                   }}
        //                 />
        //               ) : item?.type === "integer" ? (
        //                 <TextField
        //                   InputProps={{
        //                     inputProps: {
        //                       style: {
        //                         width: "80%",
        //                         marginLeft: "10px",
        //                         marginRight: "10px",
        //                         borderRadius: "800px",
        //                       },
        //                     },
        //                   }}
        //                   disabled={isEditable}
        //                   size="small"
        //                   type="number"
        //                   inputProps={{ min, max }}
        //                   value={integerValue}
        //                   error={integerError}
        //                   fullWidth
        //                   helperText={
        //                     integerError && "Negative values not allowed"
        //                   }
        //                   onChange={(e) => {
        //                     if (
        //                       e.target.value >= 0 ||
        //                       !e.target.value.includes("-")
        //                     ) {
        //                       setIntegerValue(e.target.value);

        //                       // setIntegerError(false);
        //                       handleChangeIntegerValue(
        //                         e.target.value,
        //                         parent,
        //                         subParent,
        //                         grandParent !== "0" ? grandParent : undefined,
        //                         displayName,
        //                         subChild
        //                       );
        //                     } else {
        //                       setIntegerValue("");
        //                     }
        //                   }}
        //                 />
        //               ) : (item?.type === "string" ||
        //                   item?.type === "String") &&
        //                 item.key !== "languageCode" ? (
        //                 <TextField
        //                   InputProps={{
        //                     inputProps: {
        //                       style: {
        //                         width: "80%",
        //                         marginLeft: "10px",
        //                         marginRight: "10px",
        //                         borderRadius: "800px",
        //                       },
        //                     },
        //                   }}
        //                   disabled={isEditable}
        //                   size="small"
        //                   type="text"
        //                   name="firstName"
        //                   value={stringValue}
        //                   //   error={Boolean(touched.firstName && errors.firstName)}
        //                   fullWidth
        //                   //  helperText={touched.firstName && errors.firstName}

        //                   onChange={(e) => {
        //                     setStringValue(e.target.value);
        //                     handleChangeStringValue(
        //                       e.target.value,
        //                       parent,
        //                       subParent,
        //                       grandParent !== "0" ? grandParent : undefined,
        //                       displayName,
        //                       subChild
        //                     );
        //                   }}
        //                 />
        //               ) : item?.type === "enum" ||
        //                 (item?.key==="languageCode" &&
        //                   item?.type === "string") ? (
        //                 <TextField
        //                   style={{ width: "100%", textAlign: "left" }}
        //                   disabled={isEditable}
        //                   onChange={(e, value) => {
        //                     handleChangeEnum(
        //                       e.target.value,
        //                       parent,
        //                       subParent,
        //                       grandParent !== "0" ? grandParent : undefined,
        //                       displayName,
        //                       subChild
        //                     );

        //                     setEnumValue(e.target.value);
        //                     /*   if (value===null) {
        //           setEnumRequired(true);
        //         } else {
        //           setEnumRequired(false);
        //         } */
        //                   }}
        //                   select // tell TextField to render select
        //                   //   label={`${t("Select")} ${item?.displayName}`}
        //                   // error={enumRequired}
        //                   //  helperText={enumRequired && t("Field_is_Required")}
        //                   value={enumValue}
        //                 >
        //                   {item?.key === "languageCode"
        //                     ? languagesData.map((item) => (
        //                         <MenuItem value={item.value}>
        //                           {item.language}
        //                         </MenuItem>
        //                       ))
        //                     : enumOptions?.length > 0 &&
        //                       enumOptions?.map((item, index) => (
        //                         <MenuItem key={index} value={item}>
        //                           {item?.replaceAll("_", " ")}
        //                         </MenuItem>
        //                       ))}
        //                 </TextField>
        //               ) : item?.hours ? (
        //                 <LocalizationProvider dateAdapter={AdapterDateFns}>
        //                   <TimePicker
        //                     // label="Time"
        //                     style={{ width: "100%" }}
        //                     value={dateValue}
        //                     // disablePast
        //                     // use12Hours
        //                     ampm={false}
        //                     disabled={isEditable}
        //                     // minTime={new Date(item?.openTimesValueMerged)}
        //                     // minTime={new Date(0, 0, 0, 12)}
        //                     onChange={(e) => {
        //                       handleChangeDateValue(
        //                         e,
        //                         parent,
        //                         subParent,
        //                         displayName
        //                       );
        //                       setDateValue(e);
        //                       /*  if (e===null) {
        //             setCloseTimesError(true);
        //           } else {
        //             setCloseTimesError(false);
        //           } */
        //                     }}
        //                     renderInput={(params) => (
        //                       <TextField
        //                         {...params}
        //                         style={{ width: "100%" }}
        //                       />
        //                     )}
        //                   />
        //                 </LocalizationProvider>
        //               ) : null)}
        //           </Grid>

        //           <Grid
        //             item
        //             xs={1}
        //             sm={1}
        //             md={1}
        //             lg={1}
        //             sx={{ textAlign: "end" }}
        //           >
        //             {item?.value !== null ? (
        //               <IconButton
        //                 onClick={() => {
        //                   setValueType(null);
        //                   removeMultiAttribute(
        //                     parent,
        //                     subParent,
        //                     grandParent !== "0" ? grandParent : undefined,
        //                     item?.key,
        //                     subChild,
        //                     index
        //                   );
        //                 }}
        //                 disabled={isEditable}
        //               >
        //                 <CloseIcon />
        //               </IconButton>
        //             ) : (
        //               <IconButton
        //                 onClick={() => {
        //                   setValueType(dataValues?.type);
        //                   if (dataValues?.type === "enum") {
        //                     setEnumOptions(dataValues?.enum);
        //                   }
        //                   addMultiAttribute(
        //                     parent,
        //                     subParent,
        //                     grandParent !== "0" ? grandParent : undefined,
        //                     item?.key,
        //                     item?.type,
        //                     subChild,
        //                     index,
        //                     displayName
        //                   );
        //                 }}
        //                 disabled={isEditable}
        //               >
        //                 <AddIcon
        //                   sx={{ color: isEditable===false && "#06BDFF" }}
        //                 />
        //               </IconButton>
        //             )}
        //           </Grid>
        //         </Grid>
        //       );
        //     })}
        // </>
        <></>
      )}
    </>
  );
};

export default AttributesModal;
