import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  Typography,
  Tooltip,
  tooltipClasses,
  Menu,
  MenuItem,
  Divider,
  Chip,
  DialogContent,
} from "@mui/material";
import { TablePagination } from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TitleHeader from "../../../../../../components/CustomComponents/title";
import Loader from "../../../../../../components/Loaders/Loader";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import { api, handleSessionOut } from "../../../../../../contexts/JWTContext";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import CustomizedTableRow from "../../../../../../components/CustomComponents/table/tableRow";
import CustomizedTableHead from "../../../../../../components/CustomComponents/table/tableHead";
import CustomizedTableCell from "../../../../../../components/CustomComponents/table/tableCell";
import useAuth from "../../../../../../hooks/useAuth.js";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotFound from "../../../../../../components/NotFound/NotFound";
import { useStyles } from "../../../../UserReviewRequest/Styles/style";
import moment from "moment";
import CommonButton from "../../../../../../components/CustomComponents/Buttons/CommonButton";
import useDateFormat from "../../../../../../hooks/useDateFormat.js";
import { checkLocalStorageData } from "../../../../../../utils/LocalStorageHelpers.js";
const Paper = styled(MuiPaper)(spacing);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const UserRoles = ({
  selectedTab,
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const isDataValid = checkLocalStorageData();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteRoles, setDeleteRoles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [allRoles, setAllRoles] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [confirmRoleDeleteModal, setConfirmRoleDeleteModal] = useState(false);
  const [linkedUserCount, setLinkedUserCount] = useState("");
  const [deleteRoleConfirm, setDeleteRoleConfirm] = useState("false");
  const params = useParams();
  const navigate = useNavigate();
  const { formatDate } = useDateFormat();

  useEffect(() => {
    if (isDataValid) {
      if (selectedTab === 2) {
        getAllRoles();
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, [page, rowsPerPage, selectedTab]);

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  const getAllRoles = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(
        `companyRoles?pageNumber=${page + 1}&limit=${rowsPerPage}&companyId=${
          companyData?.id
        }`
      );
      if (res.status === 200) {
        setAllRoles(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setAllRoles(null);
      if (
        error?.response?.data?.message ===
          "No Permissions found, You need to log in again" ||
        error?.response?.data?.message ===
          "Your session has expired. Kindly log in again to continue accessing your account."
      ) {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut,
          true
        );
      } else {
        handleSessionOut(
          error?.response?.status,
          error?.response?.data?.message,
          handleSignOut
        );
      }
    }
  };
  const handleDeleteSingleRole = async (deleteState) => {
    try {
      setDeleteLoading(true);
      const res = await api.delete(
        `/companyRoles/${selectedRow}?confirm=${deleteState}`
      );
      if (res.status === 200) {
        if (deleteState === "false") {
          setConfirmRoleDeleteModal(true);
        } else {
          setDeleteLoading(false);
          handleCloseModal();
          setConfirmRoleDeleteModal(false);
          handleCloseConfirmDeleteModel();
          toast.success(res.data.message);

          setDeleteRoles([]);
          setSelectAll(false);
          getAllRoles();
        }
      }
    } catch (error) {
      setDeleteLoading(false);
      if (error?.response?.data?.message === "Effected Users") {
        setLinkedUserCount(error?.response?.data?.data);
        setConfirmRoleDeleteModal(true);
      }
      setDeleteRoles([]);
    }
  };

  const handleCloseConfirmDeleteModel = () => {
    setConfirmRoleDeleteModal(false);
    handleCloseModal();
  };
  // const handleDeleteMultipleRoles = async () => {
  //   setDeleteLoading(true);
  //   try {
  //     const res = await api.delete(
  //       `/companyRoles/${
  //         deleteRoles?.length > 0 ? deleteRoles?.toString() : ""
  //       }`
  //     );
  //     if (res.status === 200) {
  //       handleCloseDeleteModel();
  //       toast.success(res.data.message);
  //       setDeleteLoading(false);
  //       setDeleteRoles([]);
  //       setSelectAll(false);
  //       getAllRoles();
  //     }
  //   } catch (error) {
  //     setDeleteLoading(false);
  //     setDeleteRoles([]);
  //     toast.error(error?.response?.data?.message);
  //   }
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, id, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(id);
    setSelectedData(data);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpen(false);
    setAnchorEl(null);
    setSelectedData(null);

    // setSelectedRow(null);
  };
  const handleNavigationToRole = () => {
    navigate(`/user/accounts-overview/create-role`);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleOpenDeleteModel = () => {
    setAnchorEl(null);
    setOpenModal(true);
  };
  const handleCloseDeleteModel = () => {
    setAnchorEl(null);
    setOpenModal(false);
  };
  const handleOpenModel = () => {
    setAnchorEl(null);
    // setOpen(true);
    handleDeleteSingleRole("false");
  };
  const handleChangeCheckButton = (e) => {
    setSelectAll(e.target.checked);
    let dummyArr = [];
    if (e.target.checked) {
      const filtered = allRoles?.rows?.filter(
        (item) => item?.default === false
      );
      for (var i = 0; i < filtered?.length; i++) {
        deleteRoles.push(filtered[i]?.id);
        dummyArr?.push(filtered[i]?.id);
      }

      const uniqueTasks = new Set(deleteRoles);

      setDeleteRoles([...uniqueTasks]);
    } else {
      setDeleteRoles([]);
    }
  };

  const handleRolesChecked = (e, index, taskId) => {
    const filtered = allRoles?.rows?.filter((item) => item?.default === false);
    if (e.target.checked) {
      deleteRoles.push(taskId);
    } else {
      var taskIndex = deleteRoles.indexOf(taskId);
      deleteRoles.splice(taskIndex, 1);
    }
    setDeleteRoles([...deleteRoles]);
    if (filtered?.length === deleteRoles?.length) {
      // setDeleteRoles([]);
      setSelectAll(true);
    } else {
      setSelectAll(false);
      //  setDeleteRoles(all);
    }
  };
  const handleSubmitDelete = () => {
    // setDeleteRoleConfirm("true");
    handleDeleteSingleRole("true");
  };
  return (
    <>
      {confirmRoleDeleteModal && (
        <Dialog
          sx={{
            "& .MuiDialog-paper": {
              width: "80%",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
            },
          }}
          open={confirmRoleDeleteModal}
          // onClose={handleCloseNewRequestModel}
        >
          <DialogContent>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ textAlign: "center" }}
              >
                <WarningAmberIcon sx={{ fontSize: "70px", color: "#F8A92B" }} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "35px",
                    lineHeight: "60px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {linkedUserCount === 0
                    ? t("no_user_linked")
                    : `${linkedUserCount} ${t("user_linked")}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ paddingTop: "10px" }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "19px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {linkedUserCount === 0
                    ? t("would_you_like")
                    : `${linkedUserCount} ${t("role_delete_confirm1")}`}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "19px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {linkedUserCount > 0
                    ? `${t("all")} ${linkedUserCount} ${t(
                        "role_delete_confirm2"
                      )}`
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: "19px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: "#1B2430",
                  }}
                >
                  {linkedUserCount > 0 ? t("role_delete_confirm3") : ""}
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "14px",
              }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={handleCloseConfirmDeleteModel}
                label={t("No_text")}
              />
              <CommonButton
                label={t("Yes")}
                isLoading={deleteLoading}
                disabled={deleteLoading}
                onSubmit={handleSubmitDelete}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      <Box mt={5}>
        <TitleHeader
          title={t("roles")}
          // extraButton={true}
          createRoleButton={true}
          extraButtonName={
            <>
              <AddIcon /> {t("create_role")}
            </>
          }
          // subHeading={
          //   <>
          //     <Typography>{t("role_subheading1")}</Typography>
          //     <Typography>{t("role_subheading2")}</Typography>
          //   </>
          // }
          handleDefaultTemplate={handleNavigationToRole}
          showDivider={true}
          deleteButton={deleteRoles?.length > 0}
          handleOpenDeleteModel={handleOpenDeleteModel}
          addPermission={addPermission}
          deletePermission={deletePermission}
          viewPermission={viewPermission}

          // onClick={AddRuleNavigateButton}
          // count={count}
        />
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {allRoles !== null && allRoles?.rows?.length > 0 ? (
            <>
              <Grid container spacing={8} sx={{ marginTop: "1rem" }}>
                <TableContainer
                  borderRadius="12"
                  component={Paper}
                  marginLeft="2rem"
                >
                  <Table aria-label="simple table">
                    <CustomizedTableHead variant="h1">
                      <CustomizedTableRow>
                        {/*   <CustomizedTableCell align="start">
                          <Checkbox
                            checked={selectAll}
                            onChange={(e) => handleChangeCheckButton(e)}
                          />
                        </CustomizedTableCell> */}
                        <CustomizedTableCell align="start">
                          {t("IDD")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("role_name")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("managed_by")}
                        </CustomizedTableCell>
                        {/* <CustomizedTableCell>
                          {t("Created_At")}
                        </CustomizedTableCell> */}
                        <CustomizedTableCell>
                          {t("updated_at")}
                        </CustomizedTableCell>
                        <CustomizedTableCell>
                          {t("Actionss")}
                        </CustomizedTableCell>
                      </CustomizedTableRow>
                    </CustomizedTableHead>
                    <TableBody>
                      {allRoles !== null &&
                        allRoles?.rows?.map((row, index) => {
                          return (
                            <CustomizedTableRow>
                              {/*  <CustomizedTableCell hover>
                              <Checkbox
                                disabled={
                                  row?.default === true

                                  // row?.enableUberAll === "rejected"
                                }
                                checked={deleteRoles?.includes(row.id)}
                                onChange={(e) => {
                                  handleRolesChecked(e, index, row.id);
                                }}
                                label=""
                              />
                            </CustomizedTableCell> */}
                              <CustomizedTableCell
                                /*  sx={{
                                  color:
                                    row?.default === true ? "#8D9298" : "#1B2430",
                                }} */
                                hover
                                align="start"
                              >
                                {row?.id}
                              </CustomizedTableCell>
                              <CustomizedTableCell
                              /*  sx={{
                                  color:
                                    row?.default === true ? "#8D9298" : "#1B2430",
                                }} */
                              >
                                {row?.name !== null
                                  ? (
                                      row?.name.charAt(0).toUpperCase() +
                                      row?.name.slice(1)
                                    ).replaceAll("-", " ")
                                  : "--"}
                              </CustomizedTableCell>
                              <CustomizedTableCell>
                                {row?.default === true ? (
                                  <Chip
                                    sx={{
                                      padding: "5px",
                                      margin: "5px",
                                    }}
                                    label={t("TableStatus")}
                                    m={1}
                                  />
                                ) : (
                                  <span>
                                    {row?.User !== null
                                      ? row?.User?.firstName +
                                        " " +
                                        (row?.User?.lastName !== null
                                          ? row?.User?.lastName
                                          : "--")
                                      : row?.createBy !== null
                                      ? row?.createBy?.firstName +
                                        " " +
                                        (row?.createBy?.lastName !== null
                                          ? row?.createBy?.lastName
                                          : "--")
                                      : "--"}
                                  </span>
                                )}
                              </CustomizedTableCell>

                              {/* <CustomizedTableCell
                              sx={{
                                  color:
                                    row?.default === true ? "#8D9298" : "#1B2430",
                                }} 
                                align="left"
                              >
                                {moment(row?.createdAt).format("MMM DD YYYY")}
                              </CustomizedTableCell> */}
                              <CustomizedTableCell
                                /*  sx={{
                                  color:
                                    row?.default === true ? "#8D9298" : "#1B2430",
                                }} */
                                align="left"
                              >
                                {/* {moment(row?.updatedAt).format("MMM DD YYYY")} */}
                                {formatDate(row?.updatedAt)}
                              </CustomizedTableCell>
                              <CustomizedTableCell align="end">
                                <IconButton
                                  onClick={(e) => handleClick(e, row?.id, row)}
                                  // onClick={(e) => handleClick(e, row?.id)}
                                  // disabled={row?.default}
                                  variant="outlined"
                                >
                                  <MoreHorizIcon />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "long-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={Boolean(anchorEl)}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  {selectedData !== null &&
                                    selectedData?.default === false &&
                                    (deletePermission && viewPermission ? (
                                      <MenuItem
                                        onClick={() => {
                                          handleOpenModel();
                                        }}
                                      >
                                        <DeleteIcon
                                          sx={{
                                            color: "#545353",
                                            paddingRight: "5px",
                                          }}
                                        />
                                        <Typography
                                          aria-label="delete"
                                          size="large"
                                        >
                                          {t("Delete")}
                                        </Typography>
                                      </MenuItem>
                                    ) : (
                                      <BootstrapTooltip
                                        title={t("authorized_access")}
                                      >
                                        <span>
                                          <MenuItem
                                            disabled
                                            onClick={() => {
                                              handleOpenModel();
                                            }}
                                          >
                                            <DeleteIcon
                                              disabled
                                              sx={{
                                                // color: "#545353",
                                                paddingRight: "5px",
                                              }}
                                            />
                                            <Typography
                                              aria-label="delete"
                                              size="large"
                                            >
                                              {t("Delete")}
                                            </Typography>
                                          </MenuItem>
                                        </span>
                                      </BootstrapTooltip>
                                    ))}

                                  <Divider />
                                  {editPermission && viewPermission ? (
                                    <MenuItem
                                      onClick={() => {
                                        navigate(
                                          `/user/accounts-overview/create-role/${selectedRow}`
                                        );

                                        // handleOpenModel();
                                      }}
                                    >
                                      <EditIcon
                                        sx={{
                                          color: "#545353",
                                          paddingRight: "5px",
                                        }}
                                      />
                                      <Typography
                                        aria-label="delete"
                                        size="large"
                                      >
                                        {t("Edit")}
                                      </Typography>
                                    </MenuItem>
                                  ) : (
                                    <BootstrapTooltip
                                      title={t("authorized_access")}
                                    >
                                      <span>
                                        <MenuItem
                                          disabled
                                          onClick={() => {
                                            navigate(
                                              `/user/accounts-overview/create-role/${selectedRow}`
                                            );

                                            // handleOpenModel();
                                          }}
                                        >
                                          <EditIcon
                                            sx={{
                                              // color: "#545353",
                                              paddingRight: "5px",
                                            }}
                                            disabled
                                          />
                                          <Typography
                                            aria-label="delete"
                                            size="large"
                                          >
                                            {t("Edit")}
                                          </Typography>
                                        </MenuItem>
                                      </span>
                                    </BootstrapTooltip>
                                  )}
                                </Menu>
                              </CustomizedTableCell>
                            </CustomizedTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={allRoles?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <Box sx={{ marginTop: "3rem", marginInline: "auto" }}>
              <NotFound text={t("no_role_created")} />
            </Box>
          )}
        </>
      )}
      {/* {openModal && (
        <Dialog
          open={openModal}
          onClose={handleCloseDeleteModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Roles")}
            description={t("delete_selected_roles")}
            onCancel={handleCloseDeleteModel}
            onConfirm={handleDeleteMultipleRoles}
            loading={deleteLoading}
          />
        </Dialog>
      )} */}
      {/* {open && (
        <Dialog
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("Delete_Roles")}
            description={t("delete_selected_role")}
            onCancel={handleCloseModal}
            onConfirm={handleDeleteSingleRole}
            loading={deleteLoading}
          />
        </Dialog>
      )} */}
    </>
  );
};

export default UserRoles;
