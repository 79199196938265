// ** React Imports ================================================================
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "../styles/style";
// ** File Imports ================================================================
import { languagesData } from "../../../../assets/defaultLanguage";
import Loader from "../../../../components/Loaders/Loader";
import Delete from "../../../../components/Models/DeleteModal/Delete";
// ** Material ui Imports ================================================================
import { spacing } from "@mui/system";
import styled from "styled-components/macro";

import { Typography, Dialog, MenuItem, TextareaAutosize } from "@mui/material";

import {
  Box,
  Grid,
  Tooltip,
  tooltipClasses,
  Divider as MuiDivider,
  IconButton,
  FormControl,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

// ** Other Imports ================================================================
import "react-toastify/dist/ReactToastify.css";

const CustomWidthTooltipOfDelete = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "red",
  },
});
const Divider = styled(MuiDivider)(spacing);

const ShowTemplates = ({
  item,
  index,
  changeHandler,
  deleteTemplate,
  checkRequired,

  dataArray,
}) => {
  // ** Others ================================================================
  const { t } = useTranslation();
  const classes = useStyles();
  // ** States ================================================================
  const [open, setOpen] = useState(false);
  const [textRequired, setTextRequired] = useState(false);
  const [languageRequired, setLanguageRequired] = useState(false);
  const [reviewerName, setReviewerName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const textArea = useRef();

  // ** Use Effects ================================================================
  useEffect(() => {
    if (checkRequired === true) {
      if (item.text?.length < 1) {
        setTextRequired(true);
      } else {
        setTextRequired(false);
      }
      if (item.language?.length < 1) {
        setLanguageRequired(true);
      } else {
        setLanguageRequired(false);
      }
    }
  }, [checkRequired]);

  // ** Handler Functions ================================================================
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Delete
          title={t("Template_head_modal")}
          description={t("Template_modal_subhead")}
          onConfirm={() => {
            deleteTemplate(index);
            handleClose();
          }}
          onCancel={handleClose}
        />
      </Dialog>

      <Grid container spacing={2}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Grid item xs={12} sm={12} lg={4} md={4}>
              <FormControl
                // className={classes.formControl}
                fullWidth

                // className="form-style"
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1B2430",
                    marginBottom: "8px",
                    marginTop: "10px",
                  }}
                >
                  {t("Language_review")}
                </Typography>
                {/* <InputLabel id="demo-simple-select-label">
                      <Box className="input-box">
                        <LanguageIcon /> {t("Language")}
                      </Box>
                    </InputLabel> */}
                <Select
                  error={languageRequired}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={item?.language}
                  onChange={(e, value) => {
                    setLanguageRequired(false);
                    changeHandler(e, index, true, false);
                    // languageChangeHandler(e);
                  }}
                  // className="select-box"
                >
                  {languagesData?.map((item) => (
                    <MenuItem value={item?.value} name={item?.language}>
                      {item.language}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={"fr"}>French</MenuItem>
                    <MenuItem value={"gr"}>German</MenuItem> */}
                </Select>
              </FormControl>
              <br />
              <FormControl
                // className={classes.formControl}
                // fullWidth
                fullWidth
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1B2430",
                    marginBottom: "8px",
                    marginTop: "10px",
                  }}
                >
                  {t("Personalize")}
                </Typography>

                <Select
                  blurOnSelect
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={reviewerName}
                  onChange={(e, value) => {
                    setReviewerName(e.target.value);
                    changeHandler(e, index, false, true, textArea);
                  }}
                  // className="select-box"
                >
                  <MenuItem value="{{first_name}}">
                    <div>
                      {t("First_name")} <br />{" "}
                      <span className={classes.menu_item}>ex: john</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{last_name}}">
                    <div>
                      {t("Last_name")} <br />{" "}
                      <span className={classes.menu_item}>ex: alpha</span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{business_email}}">
                    <div>
                      {t("Business_email")} <br />{" "}
                      <span className={classes.menu_item}>
                        ex: john@obenan.com
                      </span>
                    </div>
                  </MenuItem>
                  <MenuItem value="{{business_phone_number}}">
                    <div>
                      {t("Phone_no")}
                      <br />{" "}
                      <span className={classes.menu_item}>
                        ex: +3197010281297
                      </span>
                    </div>
                  </MenuItem>
                  {/*   <MenuItem value="{{reviewer}}">
                    <div>
                      Reviewer Name <br />{" "}
                      <span className={classes.menu_item}>ex: john</span>
                    </div>
                  </MenuItem> */}

                  <MenuItem value="{{location}}">
                    <div>
                      {t("Location_Name")}
                      <br />{" "}
                      <span className={classes.menu_item}>
                        ex: Factory Girl
                      </span>
                    </div>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={10.5} sm={11} lg={7.5} md={7}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: "#1B2430",
                    marginBottom: "8px",
                    marginTop: "10px",
                  }}
                >
                  {t("Description")} {index + 1}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#1B2430",
                    lineHeight: "20px",
                    //   marginBottom: "8px",
                    //  float: "right",
                    paddingTop: "10px",
                    // ml: 2,
                  }}
                >
                  {item?.text?.length}/4000{" "}
                </Typography>

                {item?.text?.length >= 4000 && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "400",
                      color: "#FF0000",
                      lineHeight: "15px",
                      marginTop: "16px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("text_limit_text")}
                  </Typography>
                )}
              </Grid>
              <Box>
                {/*   <TextareaAutosize
                  id="filled-multiline-static"
                  multiline
                  style={{
                    border: "1px solid #E0E0E0",
                    fontFamily: "Segoe UI",
                    fontStyle: "Normal",
                    padding: "12px 12px 12px 12px",
                    width: "100%",
                    gap: "8px",
                    borderRadius: "8px",
                    minHeight: "120px",
                    color: "#1B2430",
                    fontWeight: "400",
                    fontSize: "16px",
                    //   padding: "15px",
                  }}
                  ref={textArea}
                  error={textRequired}
                  aria-label="empty textarea"
                  placeholder="Write down template description ..."
                  value={item?.text}
                  onChange={(e) => {
                    if (e.target?.value?.length < 1000) {
                      setTextRequired(false);
                      changeHandler(e, index);
                    }
                  }}
                  InputProps={{
                    disableunderline: true,
                  }}
                  inputProps={{ maxLength: 1000 }}
                /> */}
                <TextareaAutosize
                  id="standard-basic"
                  // label="Standard"
                  rows={5.8}
                  maxRows={10}
                  value={item?.text}
                  style={{
                    border: "1px solid #c4c4c4",
                    fontFamily: "Segoe UI",
                    fontStyle: "Normal",
                    padding: "12px 12px 12px 12px",
                    width: "100%",
                    gap: "8px",
                    borderRadius: "4px",
                    minHeight: "120px",
                    color: "#1B2430",
                    fontWeight: "400",
                    fontSize: "16px",
                    "&:hover ": {
                      border: "1px solid #54d2ff",
                    },

                    //   padding: "15px",
                  }}
                  label={null}
                  placeholder={t("desc_placeholder")}
                  onChange={(e) => {
                    if (item?.text?.length > 4000) {
                      setTextRequired(true);
                    } else {
                      setTextRequired(false);
                    }
                    // setTextRequired(false);
                    // setText(e.target.value);
                    changeHandler(e, index);
                  }}
                  multiline
                  fullWidth
                  ref={textArea}
                  error={textRequired}
                  /*   InputProps={{
                    disableUnderline: true,
                  }} */
                  inputProps={{ maxLength: 4000 }}
                  /*   InputProps={{
                  inputComponent: TextareaAutosize,
                }} */
                />
                {textRequired && (
                  <Typography
                    sx={{
                      color: "#d32f2f",
                      fontWeight: 400,
                      fontSize: "0.6964285714285714rem",
                      lineHeight: "1.66",
                      textAlign: "left",
                      marginTop: "3px",
                      marginRight: "14px",
                      marginBottom: 0,
                      marginLeft: "14px",
                    }}
                  >
                    {t("desc_req")}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sm={1}
              md={1}
              lg={0.5}
              className={classes.grid_item_2}
            >
              {dataArray.length > 1 && (
                <CustomWidthTooltipOfDelete
                  title={t("Delete_Template")}
                  sx={{ marginTop: ".5rem", color: "#FC3652" }}
                >
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CustomWidthTooltipOfDelete>
              )}
            </Grid>

            {/* <FormControl
                    // className={classes.formControl}
                    // fullWidth

                    className="form-style"
                  >
                    <InputLabel id="demo-simple-select-label">
                      <Box className="input-box">
                        <LanguageIcon /> {t("Language")}
                      </Box>
                    </InputLabel>
                    <Select
                      error={languageRequired}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={item?.language}
                      label={"dummy11111111"}
                      onChange={(e, value) => {
                        setLanguageRequired(false);
                        changeHandler(e, index, true, false);
                        // languageChangeHandler(e);
                      }}
                      className="select-box"
                    >
                      {languagesData?.map((item) => (
                        <MenuItem value={item?.value} name={item?.language}>
                          {item.language}
                        </MenuItem>
                      ))}

                    </Select>
                  </FormControl> */}
            {/* <FormControl
                    // className={classes.formControl}
                    // fullWidth
                    className="form-style"
                  >
                    <InputLabel id="demo-multiple-checkbox-label">
                      Personalize
                    </InputLabel>
                    <Select
                      blurOnSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={reviewerName}
                      label="Personalize"
                      onChange={(e, value) => {
                        setReviewerName(null);
                        changeHandler(e, index, false, true, textArea);
                      }}
                      className="select-box"
                    >
                      <MenuItem value="{{reviewer}}">
                        <div>
                          Reviewer Name <br />{" "}
                          <span className="menu-item">ex: john</span>
                        </div>
                      </MenuItem>
                      <MenuItem value="{{location}}">
                        <div>
                          Location Name <br />{" "}
                          <span className="menu-item">ex: Factory Girl</span>
                        </div>
                      </MenuItem>

                    </Select>
                  </FormControl>

                  <Autocomplete
                    id="combo-box-demo"
                    className="select-box"
                    options={
                      defaultTemplate?.results?.length > 0
                        ? defaultTemplate?.results
                        : []
                    }
                    getOptionLabel={(option) =>
                      option?.title ? option.title : ""
                    }
                    sx={{
                      // width: "100%",
                      background: "#f5f5f5",
                    }}
                    // value={locationId}
                    onChange={(e, value) => {
                      if (value) {
                        const result = value?.templateDescription.find(
                          (item) => item.language==="en"
                        );
                        changeHandler(
                          { target: { value: result?.text } },
                          index
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField placeholder="Default templates" {...params} />
                    )}
                  ></Autocomplete> */}
          </>
        )}
      </Grid>
    </>
  );
};

export default ShowTemplates;
