import React, { useState, useEffect } from "react";

import { withTheme } from "styled-components/macro";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import GoogleMapInpuField from "../../../../../components/GoogleMapField/index";
import {
  Grid,
  MenuItem,
  Select,
  Box,
  Tooltip,
  tooltipClasses,
  IconButton,
} from "@mui/material";
import styled from "styled-components/macro";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { api, handleSessionOut } from "../../../../../contexts/JWTContext";
import useAuth from "../../../../../hooks/useAuth";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// Default Langauge
import { languagesData } from "../../../../../assets/defaultLanguage";
import CommonButton from "../../../../../components/CustomComponents/Buttons/CommonButton";
import { checkLocalStorageData } from "../../../../../utils/LocalStorageHelpers";
const useStyles = makeStyles((theme) => ({
  tooltipstyle: {
    fontSize: "large",
    paddingBottom: "3px",
    color: "#8D9298",
  },
  tagItem: {
    margin: "4px",
    marginBottom: "0px",
    backgroundColor: "#ebebec",
    borderRadius: "800px",
    display: "inline-block",
    padding: "4px 8px",
    fontSize: "12px",
    color: "#1B2430",
    fontWeight: "400",
  },
  keywordBox: {
    alignItems: "center",
    display: "flex",
    paddingX: "8px",
    paddingY: "6px",
  },
  keywordTypo: {
    fontSize: "12px",
    paddingX: "4px",
    fontWeight: "400",
    marginX: "4px",
    textTransform: "capitalize",
  },
  keywordIcon: {
    marginLeft: "6px",
    fontSize: "16px",
    color: "#1B2430",
    cursor: "pointer",
    "&:hover ": {
      color: "#06BDFF",
    },
  },
  tagsInput: {
    flexGrow: 1,
    padding: "4.5px 0",
    border: "none",
    outline: "none",
    overflow: "hidden",
  },
  tagsInputContainer: {
    border: "1px solid #c7c7c7",
    borderRadius: "4px",
    padding: "0.5em",
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "4px",
    "&:hover": {
      border: "1px solid #000000",
    },
  },
  limitFieldTextDescription: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#1B2430",
    lineHeight: "20px",
    marginTop: "2px",
    paddingLeft: "3px",
  },
}));
//const Alert = styled(MuiAlert)(spacing);
const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:300px)": {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.2rem",
  },
};
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const LocationForm = ({ theme }) => {
  const classes = useStyles();
  const isDataValid = checkLocalStorageData();
  const [googlePlaceData, setGooglePlaceData] = useState({});
  const [placeId, setPlaceId] = useState("");
  const [setCompanyData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("");
  const [defaultLocation, setDefaultLocation] = useState(false);
  const stateData = JSON.parse(localStorage.getItem("company"));
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [duplicateAccountOwners, setDuplicateKeywords] = useState(false);
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useAuth();

  useEffect(() => {
    if (user?.companyId) {
    }
  }, [user]);
  useEffect(async () => {
    if (placeId) {
      try {
        const res = await api.get(`/location/place-details/${placeId}`);
        if (res.status === 200) {
          setGooglePlaceData(res?.data?.data?.googleData?.result);
        }
      } catch (error) {}
    }
  }, [placeId]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_LOCATION_API_KEY}&libraries=places`;
    script.onload = () => {
      setScriptLoaded(true);
    };
    script.onerror = (error) => {
      console.error("Error loading Google Maps Places script:", error);
      // You might want to handle the error here, such as setting a flag to indicate that loading failed
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(async () => {
    if (isDataValid) {
      try {
        const res = await api.get(`/company`);
        if (res.status === 200) {
          setCompanyData(res.data);
        }
      } catch (error) {
        if (
          error?.response?.data?.message ===
            "No Permissions found, You need to log in again" ||
          error?.response?.data?.message ===
            "Your session has expired. Kindly log in again to continue accessing your account."
        ) {
          handleSessionOut(
            error?.response?.status,
            error?.response?.data?.message,
            handleSignOut,
            true
          );
        } else {
          handleSessionOut(
            error?.response?.status,
            error?.response?.data?.message,
            handleSignOut
          );
        }
      }
    } else {
      handleSignOut();
      toast.error("Unauthorized Session");
    }
  }, []);
  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };
  const [partnerAccount, setPartnerName] = React.useState([]);

  let partnerAccount1 = partnerAccount.toString();
  //Select Partner Field End*******************************************
  const [checked, setChecked] = React.useState(false);

  const setPlaceIdFromGoogle = (place) => {
    setPlaceId(place.value.place_id);
  };

  var companyIds = [];

  const getSelectedCompanyId = companyIds
    .map((i) => i.name)
    .indexOf(partnerAccount1);

  const handleAddKeywords = (e) => {
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(keywords);
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(keywords);
      const mergeResult = [...keywords, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateKeywords(true);
    } else {
      setDuplicateKeywords(false);
    }
  };

  const removeBrandsTag = (index) => {
    setKeywords(keywords.filter((el, i) => i !== index));
  };

  const handleBrandsKeyDown = (e) => {
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...keywords, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...keywords, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setKeywords(uniqueKeywords);
    }
    const lowerCaseBrandTags = keywords.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateKeywords(true);
      setTimeout(() => {
        setDuplicateKeywords(false);
      }, 2000);
    } else {
      for (let i = 0; i < keywords.length; i++) {
        if (filtered.includes(keywords[i])) {
          setDuplicateKeywords(true);
          setTimeout(() => {
            setDuplicateKeywords(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid>
          <Grid sx={{ padding: "10px", bgcolor: "white" }}>
            <Formik
              enableReinitialize={true}
              initialValues={{
                accountName:
                  googlePlaceData && googlePlaceData?.name
                    ? googlePlaceData?.name
                    : "",
                addressOne:
                  googlePlaceData &&
                  googlePlaceData?.vicinity &&
                  googlePlaceData?.vicinity
                    ? googlePlaceData?.vicinity
                    : "",
                addressTwo: "",
                postalCode:
                  googlePlaceData &&
                  googlePlaceData?.address_components &&
                  googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "postal_code"
                  )?.length > 0 &&
                  googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "postal_code"
                  )[0]["long_name"]
                    ? googlePlaceData?.address_components.filter(
                        (address) => address.types[0] === "postal_code"
                      )[0]["long_name"]
                    : "",
                city:
                  googlePlaceData &&
                  googlePlaceData?.address_components &&
                  googlePlaceData?.address_components.filter(
                    (address) =>
                      address.types[0] === "administrative_area_level_2"
                  )?.length > 0 &&
                  googlePlaceData?.address_components.filter(
                    (address) =>
                      address.types[0] === "administrative_area_level_2"
                  )[0]["long_name"]
                    ? googlePlaceData?.address_components.filter(
                        (address) =>
                          address.types[0] === "administrative_area_level_2"
                      )[0]["long_name"]
                    : "",
                countryName:
                  googlePlaceData &&
                  googlePlaceData?.address_components &&
                  googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "country"
                  )?.length > 0 &&
                  googlePlaceData?.address_components.filter(
                    (address) => address.types[0] === "country"
                  )[0]["long_name"]
                    ? googlePlaceData?.address_components.filter(
                        (address) => address.types[0] === "country"
                      )[0]["long_name"]
                    : "",
                telephone:
                  googlePlaceData && googlePlaceData?.international_phone_number
                    ? googlePlaceData?.international_phone_number
                    : "",
                website:
                  googlePlaceData && googlePlaceData?.website
                    ? googlePlaceData?.website
                    : "",
                businessEmail:
                  googlePlaceData && googlePlaceData?.businessEmail
                    ? googlePlaceData?.businessEmail
                    : "",
                businessEmail:
                  stateData !== null && stateData !== undefined
                    ? stateData?.email
                    : "",

                //  accountExternalReference: "",
                defaultLanguage: selectLanguage,
                internalName:
                  googlePlaceData && googlePlaceData?.name
                    ? googlePlaceData?.name
                    : "",
                // soConnectEmail: user.email,
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                accountName: Yup.string()
                  .max(255)
                  .required(`${t("account_req")}`),
                addressOne: Yup.string()
                  .max(255)
                  .required(`${t("address_req")}`),

                city: Yup.string()
                  .max(255)
                  .required(`${t("city_req")}`),
                countryName: Yup.string()
                  .max(255)
                  .required(`${t("country_req")}`),
                postalCode: Yup.string().required(`${t("postal_req")}`),
                internalName: Yup.string().required(
                  `${t("internal_name_req")}`
                ),
                // telephone: Yup.string().required(`${t("telephone_req")}`),
                // .required("Account External Reference is required"),
                // partnerAccount: Yup.string()
                //   .max(255)
                //   .required("Partner Account is required"),
                // firstName: Yup.string()
                //   .max(255)
                //   .required("First name is required"),
                // lastName: Yup.string().max(255).required("Last name is required"),
                businessEmail: Yup.string()
                  .email(`${t("valid_email")}`)
                  .max(255)
                  .required(`${t("email_req")}`),
                // soConnectEmail: Yup.string()
                //   .email(`${t("valid_email")}`)
                //   .max(255)
                //   .required(`${t("list_email_req")}`),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  setIsLoading(true);
                  const res = await api.post("/location", {
                    name: values.accountName,
                    addressLine1: values.addressOne,
                    addressLine2: values.addressTwo,
                    postalCode: values.postalCode,
                    city: values.city,
                    country: values.countryName,
                    telephone: values.telephone,
                    website: values.website,
                    businessEmail: values.businessEmail,
                    internalName: values.internalName,
                    /*  externalReference: checked
                      ? ""
                      : values.accountExternalReference, */
                    // partnerId: user?.companyId,
                    placeId,
                    defaultLanguage: selectLanguage ? selectLanguage : "en",
                    isDefault: defaultLocation ? defaultLocation : false,
                    // soConnectEmail: values.soConnectEmail,
                    lat: googlePlaceData?.geometry?.location?.lat,
                    lng: googlePlaceData?.geometry?.location?.lng,
                    brandNames: keywords.join(","),
                  });

                  if (res.status === 201) {
                    // toast("Location Registered Successfully");
                    setMessage(res.data.message);
                    setIsLoading(false);
                    toast.success(t("Location_Registered_Successfully"), {
                      onClose: () => {
                        navigate(
                          `/user/edit_location?state=${res?.data?.data?.id}/`,
                          {
                            state: {
                              showProduct: true,
                              displayOn: true,
                            },
                          }
                        );
                      },
                    });
                    // setTimeout(() => {
                    //   navigate("/location");
                    // }, 2000);
                  }
                } catch (error) {
                  setIsLoading(false);
                  toast.error(error?.response?.data?.message);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                >
                  {scriptLoaded && (
                    <Grid>
                      <Grid sx={{ bgcolor: "white" }}>
                        <Grid>
                          <Grid
                            container
                            sx={{
                              marginTop: "20px",

                              marginBottom: "20px",
                            }}
                          >
                            <Grid item xs={12} sm={3}>
                              <Typography variant="h3">
                                {t("SetupLocation")}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                              <GoogleMapInpuField
                                placeholder="Select google location"
                                setPlaceIdFromGoogle={setPlaceIdFromGoogle}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  <Divider variant="middle" />

                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">{t("Name")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {" "}
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Location_Name")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="text"
                        name="accountName"
                        value={values.accountName}
                        error={Boolean(
                          touched.accountName && errors.accountName
                        )}
                        fullWidth
                        helperText={touched.accountName && errors.accountName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">
                        {" "}
                        {t("Internal_name")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      {" "}
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          sx={{
                            marginTop: "10px",
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "rgba(0,0,0,0.87)",
                          }}
                        >
                          {t("Internal_name_label")}
                        </Typography>
                        <BootstrapTooltip
                          title={
                            <h1 style={{ fontSize: "12px" }}>
                              {t("internalName_tooltip_text")}
                            </h1>
                          }
                        >
                          <IconButton>
                            <ErrorOutlineIcon
                              sx={{
                                color: "#1B2430",
                              }}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      </Box>
                      <TextField
                        type="text"
                        name="internalName"
                        value={values?.internalName}
                        error={Boolean(
                          touched?.internalName && errors?.internalName
                        )}
                        fullWidth
                        helperText={
                          touched?.internalName && errors?.internalName
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">{t("brand_name")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("brand_name")}
                          </Typography>
                          <BootstrapTooltip
                            title={
                              <h1 style={{ fontSize: "12px" }}>
                                {t("brand_name_tooltip")}
                              </h1>
                            }
                          >
                            <IconButton>
                              <ErrorOutlineIcon
                                sx={{
                                  color: "#1B2430",
                                }}
                              />
                            </IconButton>
                          </BootstrapTooltip>
                        </Box>
                        <Typography
                          className={classes.limitFieldTextDescription}
                        >
                          {keywords.length}/10
                        </Typography>
                      </Box>
                      <Grid className={classes.tagsInputContainer}>
                        <TextField
                          variant="standard"
                          type="text"
                          className={classes.tagsInput}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          placeholder={t("brand_name_input")}
                          autoComplete="none"
                          fullWidth
                          onKeyDown={handleBrandsKeyDown}
                          onChange={(e) => {
                            if (
                              e.target.value === "," ||
                              e.target.value === "." ||
                              e.target.value.trim() === ""
                            ) {
                              e.target.value = "";
                            } else if (
                              (e.target.value !== "" &&
                                e.target.value !== "," &&
                                e.target.value.includes(".")) ||
                              (e.target.value !== "." &&
                                e.target.value !== "" &&
                                e.target.value.includes(","))
                            ) {
                              handleAddKeywords(e);
                            }
                          }}
                          disabled={keywords.length === 10}
                        />
                        {keywords?.length > 0 &&
                          keywords.map((keywords, index) => (
                            <Grid className={classes.tagItem} key={index}>
                              <Box className={classes.keywordBox}>
                                <Typography className={classes.keywordTypo}>
                                  {keywords}
                                </Typography>{" "}
                                <CloseIcon
                                  className={classes.keywordIcon}
                                  onClick={() => removeBrandsTag(index)}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      <Typography
                        sx={{
                          padding: "4px",
                          color: "gray",
                          fontSize: "10px",
                        }}
                      >
                        {t("enter_keyword_limit_10")}
                      </Typography>
                      {duplicateAccountOwners && (
                        <Typography sx={{ color: "red", fontSize: "12px" }}>
                          {t("brand_duplicated_error")}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Divider variant="middle" />
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">{t("Address")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("AddressLineOne")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="text"
                        name="addressOne"
                        value={values.addressOne}
                        error={Boolean(touched.addressOne && errors.addressOne)}
                        fullWidth
                        helperText={touched.addressOne && errors.addressOne}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("AddressLineTwo")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="text"
                        name="addressTwo"
                        value={values.addressTwo}
                        error={Boolean(touched.addressTwo && errors.addressTwo)}
                        fullWidth
                        helperText={touched.addressTwo && errors.addressTwo}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <Grid container>
                        <Grid item xs={5} sx={{ backgroundColor: "" }}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("PostalCode")}
                          </Typography>
                          <TextField
                            disabled={isLoading}
                            name="postalCode"
                            value={values.postalCode}
                            error={Boolean(
                              touched.postalCode && errors.postalCode
                            )}
                            inputProps={{
                              maxLength: 12,
                            }}
                            fullWidth
                            helperText={touched.postalCode && errors.postalCode}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid xs={1}></Grid>
                        <Grid item xs={6} sx={{ backgroundColor: "" }}>
                          <Typography
                            sx={{
                              marginTop: "10px",
                              fontSize: "14px",
                              fontWeight: "700",
                              color: "rgba(0,0,0,0.87)",
                            }}
                          >
                            {t("City")}
                          </Typography>
                          <TextField
                            disabled={isLoading}
                            type="text"
                            name="city"
                            value={values.city}
                            error={Boolean(touched.city && errors.city)}
                            fullWidth
                            helperText={touched.city && errors.city}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Country")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="text"
                        name="countryName"
                        value={values.countryName}
                        error={Boolean(
                          touched.countryName && errors.countryName
                        )}
                        fullWidth
                        helperText={touched.countryName && errors.countryName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  {/* {isLoading && <Loader />} */}
                  <Divider variant="middle" />
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">
                        {t("ContactDetails")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Telephone")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="number"
                        name="telephone"
                        value={values.telephone}
                        // error={Boolean(touched.telephone && errors.telephone)}
                        fullWidth
                        // helperText={touched.telephone && errors.telephone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />

                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Website")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="text"
                        name="website"
                        value={values.website}
                        error={Boolean(touched.website && errors.website)}
                        fullWidth
                        helperText={touched.website && errors.website}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Email_Address_Buisness")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="email"
                        name="businessEmail"
                        placeholder="e.g.  john@obenan.com"
                        value={values.businessEmail}
                        error={Boolean(
                          touched.businessEmail && errors.businessEmail
                        )}
                        fullWidth
                        helperText={
                          touched.businessEmail && errors.businessEmail
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  {/* <Divider variant="middle" />
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">
                        {t("So_Connect_heading")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("So_Connect_email")}
                      </Typography>
                      <TextField
                        disabled={isLoading}
                        type="email"
                        name="soConnectEmail"
                        placeholder="e.g.  john@obenan.com"
                        value={values.soConnectEmail}
                        error={Boolean(
                          touched.soConnectEmail && errors.soConnectEmail
                        )}
                        fullWidth
                        helperText={
                          touched.soConnectEmail && errors.soConnectEmail
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid> */}
                  <Divider variant="middle" />
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">{t("Auto_Replyy")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Default_Review")}
                      </Typography>
                      <Select
                        required
                        defaultValue={"en"}
                        style={{ width: "100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={languagesData?.language}
                        // label="Select"
                        onChange={(e, value) => {
                          setSelectLanguage(e.target.value);
                        }}
                      >
                        {languagesData.map((item) => (
                          <MenuItem value={item.value}>
                            {item.language}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  <Divider variant="middle" />
                  <Grid
                    container
                    sx={{
                      marginTop: "20px",
                      marginLeft: "2px",
                      marginRight: "2px",
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} sm={3}>
                      <Typography variant="h3">
                        {t("Default_Location_lable")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography
                        sx={{
                          marginTop: "10px",
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "rgba(0,0,0,0.87)",
                        }}
                      >
                        {t("Default_Location")}
                      </Typography>
                      <Select
                        required
                        defaultValue={"en"}
                        style={{ width: "100%" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={defaultLocation}
                        // label="Select"
                        onChange={(e) => {
                          setDefaultLocation(e.target.value);
                        }}
                      >
                        <MenuItem value={true}>True</MenuItem>
                        <MenuItem value={false}>False</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>

                  <Divider variant="middle" />
                  <Box sx={{ marginRight: "6px" }}>
                    <Grid
                      spacing={2}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="flex-start"
                      my={3}
                    >
                      <Grid item>
                        <CommonButton
                          displayWhite="true"
                          label={t("Cancel")}
                          onSubmit={() => {
                            navigate("/");
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <CommonButton
                          loading={isLoading}
                          type="submit"
                          disabled={isSubmitting}
                          label={t("Add_Location")}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default withTheme(LocationForm);
