import React, { useState, useEffect } from "react";
import EditLocation from "./Components/EditLocationForm";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Grid, Divider as MuiDivider, Tabs, Tab } from "@mui/material";
import { useLocation } from "react-router-dom";
import { api } from "../../../../contexts/JWTContext";
import { spacing } from "@mui/system";
import TitleHeader from "../../../../components/CustomComponents/title";
import Loader from "../../../../components/Loaders/Loader";
import SocialConnections from "../Components/SocialConnections";
import { useStyles } from "../styles/style";
import useAuth from "../../../../hooks/useAuth";

const Divider = styled(MuiDivider)(spacing);

function Index() {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const { permissionsAcess } = useAuth();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const stateLocationIdArray = params.get("state").split("/");
  const googleScope = params.get("scope");
  const [isLoading, setIsLoading] = useState(false);
  const [singleLocation, setSingleLocation] = React.useState(null);
  const [allSubscriptions, setAllSubscriptions] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [subscriptionArr, setSubscriptionArr] = useState([]);
  const [editPermission, setEditPermission] = useState(false);
  const [addPermission, setAddPermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  useEffect(() => {
    if (permissionsAcess?.length > 0) {
      let filteredPermissions = permissionsAcess?.filter(
        (item) => item?.route === "/location"
      );

      filteredPermissions?.map((item) => {
        if (
          item?.permissionName === "create" ||
          item?.permissionName === "Account Management"
        ) {
          setAddPermission(true);
        }
        if (
          item?.permissionName === "edit" ||
          item?.permissionName === "Account Management"
        ) {
          setEditPermission(true);
        }
        if (
          item?.permissionName === "delete" ||
          item?.permissionName === "Account Management"
        ) {
          setDeletePermission(true);
        }
        if (
          item?.permissionName === "read" ||
          item?.permissionName === "Account Management"
        ) {
          setViewPermission(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (location?.state !== null) {
      if (location?.state?.showProduct === true) {
        setSelectedTab(1);
      }
    } else {
      setSelectedTab(0);
    }
  }, []);

  useEffect(async () => {
    if (search?.length > 0 && googleScope?.length > 0) {
      setSelectedTab(1);
      setIsLoading(true);

      try {
        const res = await api.get(`google/auth-code` + search);
        if (res.data?.status === true) {
          await createSocialLink(res.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);

        await getLocationById(stateLocationIdArray[0]);
      }

      // if (res.status > true) {
      //   getAllLocation()

      // }
    }
  }, [search]);
  useEffect(() => {
    handleSubscriptions();
  }, [singleLocation]);
  useEffect(async () => {
    getLocationById(stateLocationIdArray[0]);
    // getAllSubscriptions();
  }, []);
  useEffect(() => {
    if (
      (location?.state?.showProduct === true &&
        location?.state?.displayOn === true) ||
      (location?.search.length > 0 &&
        singleLocation?.locationSubscription?.length === 0)
    ) {
      allSubscriptions?.results?.map((item) => subscriptionArr.push(item?.id));
      setSubscriptionArr([...subscriptionArr]);
    } else {
      if (singleLocation?.locationSubscription?.length > 0) {
        /*  const activeSubscriptions = singleLocation?.locationSubscription.map(
        (item) =>
          item?.status === "active" &&
          subscriptionArr.push(item?.subscription?.id)
      ); */
        for (var i = 0; i < singleLocation?.locationSubscription?.length; i++) {
          singleLocation?.locationSubscription[i]?.status === "active" &&
            subscriptionArr.push(
              singleLocation?.locationSubscription[i]?.subscription?.id
            );
        }
        const uniquePosts = new Set(subscriptionArr);

        setSubscriptionArr([...uniquePosts]);
      }
    }
    return () => {
      setSubscriptionArr([]);
    };
  }, [allSubscriptions, singleLocation]);
  const createSocialLink = async (data) => {
    try {
      const res = await api.post(`/socialLink`, {
        type: "google",
        refreshToken: data?.refreshToken,
        accountId: data?.accountId,
        locationId: data?.locationId,
        referenceId: data?.referenceId,
      });

      if (res?.data?.status === true) {
        await getLocationById(stateLocationIdArray[0]);
        toast.success("Google account attached successfully");
      }
    } catch (error) {}
  };
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const handleSubscriptions = () => {
    let locationActiveSub = [];
    singleLocation?.locationSubscription?.forEach((element, index) => {
      if (element?.subscription?.status === "active") {
        locationActiveSub?.push(element);
      }
    });
    // setLocationSelectedSubscription(locationActiveSub);
  };

  const getLocationById = async (locationId) => {
    try {
      setIsLoading(true);
      const res = await api.get(`/location/${locationId}`);
      if (res.status === 200) {
        setSingleLocation(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  /*  const getAllSubscriptions = async () => {
    try {
      setIsLoading(true);
      const res = await api.get(`/subscriptions`);
      if (res.status === 200) {
        setAllSubscriptions(res.data.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }; */

  return (
    <React.Fragment>
      <Helmet title="Profile" />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TitleHeader
            showButton={true}
            navigateToLocations={true}
            title={singleLocation?.internalName}
            showDivider={true}
          />

          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
            <Tabs
              sx={{ width: "100%", marginTop: "2px" }}
              className={classes.Tabs}
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
              centered
            >
              <Tab sx={{ width: "10%" }} label={t("Details")} />
              <Tab sx={{ width: "auto" }} label={t("Social_connections")} />
            </Tabs>
            <Divider mb={4} variant="middle" />
            {selectedTab === 0 && (
              <Grid container spacing={6}>
                <EditLocation
                  singleLocation={singleLocation}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  allSubscriptions={allSubscriptions}
                  getLocationById={stateLocationIdArray[0]}
                  getSingleLocation={getLocationById}
                />
              </Grid>
            )}
            {selectedTab === 1 && (
              <>
                <SocialConnections
                  singleLocation={singleLocation}
                  allSubscriptions={allSubscriptions}
                  getLocationById={getLocationById}
                  getLocationId={stateLocationIdArray[0]}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  addPermission={addPermission}
                  editPermission={editPermission}
                  deletePermission={deletePermission}
                  viewPermission={viewPermission}
                  /*  locationSelectedSubscription={locationSelectedSubscription}
                  getAllSubscriptions={getAllSubscriptions}
                  setSubscriptionArr={setSubscriptionArr}
                  subscriptionArr={subscriptionArr} */
                />
              </>
            )}
          </Grid>
        </>
      )}
    </React.Fragment>
  );
}

export default Index;
